import { Chip, Grid, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { validEmail } from "../../../utils/validations";
import { notify } from "../../../utils/notify";
import { v4 as uuidv4 } from "uuid";
import { MultipleEmailsText } from "../styles/ModalPublicationChannelFrame";

import CloseIcon from "@material-ui/icons/Close";

export const ModalMultipleEmails = ({
  setTab,
  startOption,
  emailList,
  setEmailList,
  entityIds,
}) => {
  const [multipleEmails, setMultipleEmails] = React.useState("");
  const [commType] = useState(startOption);

  const EmailChips = ({ objects }) => {
    return objects.map((item) => (
      <Chip
        key={item.id}
        label={item.document}
        onDelete={() => handleDelete(item.id)}
        style={{
          marginRight: "8px",
          background: "#EEEEEE",
          color: "#63708A",
          paddingRight: "3px",
          marginBottom: "8px",
        }}
        deleteIcon={
          <CloseIcon
            style={{ height: "15px", width: "15px", color: "#63708A" }}
          />
        }
      />
    ));
  };

  const handleDelete = (id) => {
    const updatedEmailsList = emailList.filter((email) => email.id !== id);
    setEmailList(updatedEmailsList);
  };

  useEffect(() => {}, [emailList]);

  useEffect(() => {
    if (multipleEmails.includes(";")) {
      const formatedEmail = multipleEmails.replace(";", "");
      if (validEmail(formatedEmail.trim())) {
        setEmailList((currentMultipleEmailsList) => {
          const newMultipleEmailList = [...currentMultipleEmailsList];
          const newEmailListObjects = {
            id: uuidv4(),
            comm_type: commType,
            document: formatedEmail.trim(),
            confirmed: false,
            enabled: false,
            entity_id: entityIds,
          };
          newMultipleEmailList.push(newEmailListObjects);
          setMultipleEmails("");
          return newMultipleEmailList;
        });
      } else {
        notify("Email inválido, tente novamente.", true, "warning");
        setMultipleEmails("");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [multipleEmails]);

  return (
    <>
      <Grid>
        <MultipleEmailsText>
          Separe todos os e-mails por “;” para serem adicionados todos de uma
          vez.
        </MultipleEmailsText>
        <TextField
          value={multipleEmails}
          onChange={(e) => setMultipleEmails(e.target.value)}
          multiline
          minRows={14}
          variant="outlined"
          placeholder="ex: email1@hotmail.com; osmar@qyon.com; sirlene@gmail.com;"
          fullWidth
          InputProps={{
            style: { display: "block", flexDirection: "row" },
            startAdornment: <EmailChips objects={emailList} />,
          }}
        />
      </Grid>
    </>
  );
};
