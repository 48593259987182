import { search } from "../BaseRepositoryComponents";

const PANEL_URL = process.env.REACT_APP_NEW_PAINEL_API_URL;

export const getEsocialMigrationsByAccountantDocument = async (
  document,
  page,
  pageSize,
  clientDocument
) => {
  let url = `${PANEL_URL}/v1/esocial/accountant/${document}?page=${page}&page_size=${pageSize}`;

  if (clientDocument) {
    url += `&document=${clientDocument}`;
  }

  return search({
    path: url,
    method: "GET",
  });
};

export const scheduleNewMigration = async (data) => {
  const url = `${PANEL_URL}/v1/esocial/schedule`;

  const body = {
    document: data.document,
    parent_document: data.parent_document,
    migration_type: data.migration_type,
    procuration: data.procuration,
    certificate_document: data.certificate_document,
  };

  return search({
    path: url,
    method: "POST",
    myBody: body,
  });
};

export const getCanScheduleMigration = async (document, lastId) => {
  let url = `${PANEL_URL}/v1/esocial/can_schedule?document=${document}`;

  if (lastId) {
    url += `&last_id=${lastId}`;
  }

  return search({
    path: url,
    method: "GET",
  });
};
