import React from "react";
import { useHistory } from "react-router";

import "./styles/Attendance.css";

import { notify } from "../../utils/notify";

import { useAttendance } from "./contexts/AttendanceContext";

import AttendanceData from "./childrens/AttendanceData";

export default function EditAttendance(props) {
  const { push } = useHistory();
  const { setCurrentAttendanceState } = useAttendance();

  const idAttendance = window.location.pathname.replace(
    "/attendance/edit/",
    ""
  );

  if (idAttendance) {
    return (
      <>
        <AttendanceData typeScreen={"edit"} attendanceId={idAttendance} />
      </>
    );
  } else {
    setCurrentAttendanceState("");
    push("../search");
    notify("Atendimento não encontrado", true, "error");
    return null;
  }
}
