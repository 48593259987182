import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Zoom,
} from "@material-ui/core";
import { HelpOutline as HelpIcon } from "@material-ui/icons";
import Btn from "../../../components/buttons/Btn";
import ChatController from "../../../components/chat/controllers/ChatController";
import { useAuth } from "../../../contexts/crm/Auth";
import { ContainerIcon, Title } from "../../../styles/GlobalStyle";
import AttendanceController from "../controllers/AttendanceController";
import { DarkTooltip } from "../styles/AttendanceStyle";

const ModalRoomOn = ({
  setOpen,
  open,
  listRoons,
  attendanceId,
  customerId,
}) => {
  const { postCustomerActiveChat } = AttendanceController();
  const { user, company } = useAuth();
  const { subscribeOnCallCustumerToConversation, changeOpen } =
    ChatController();

  const CancelButton = () => (
    <>
      <Btn
        label="Cancelar"
        bg-color="tertiary"
        onClick={() => {
          setOpen(false);
        }}
      />
    </>
  );

  const openConversationInRoom = async ({ roomId }) => {
    subscribeOnCallCustumerToConversation({
      customerId,
      companyId: company?.id,
      roomId,
    });

    postCustomerActiveChat({
      company,
      roomId,
      attendanceId,
      attendantId: user?.id,
    });
    changeOpen({ isChatOpen: true });
    setOpen(false);
  };

  const RowRoom = () => {
    return (
      <>
        <Divider />
        {listRoons.map((room) => {
          return (
            <>
              <Grid container xs={12} spacing={1} style={{ padding: 10 }}>
                <Grid container justifyContent="center" item xs={3}>
                  <Btn
                    label="chamar"
                    style={{ width: "100%" }}
                    onClick={() => {
                      openConversationInRoom({ roomId: room.roomId });
                    }}
                  />
                </Grid>
                <Grid container alignItems="center" item xs={9}>
                  <label style={{ marginLeft: 10 }}>{room.roomName}</label>
                </Grid>
              </Grid>
              <Divider />
            </>
          );
        })}
      </>
    );
  };

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="sm"
      style={{ width: "30%" }}
    >
      <DialogTitle id="alert-dialog-title">
        <Grid container xs={12}>
          <Grid item>
            <Title>Salas disponíveis</Title>
          </Grid>
          <DarkTooltip
            title={`Selecione uma sala para iniciar uma conversa com o cliente do atendimeto ${attendanceId}`}
            placement="right"
            TransitionComponent={Zoom}
            TransitionProps={{ timeout: 100 }}
          >
            <ContainerIcon
              iconColor="#0a73db"
              marginLeft="5px"
              display="flex"
              alignSelf="center"
            >
              <HelpIcon style={{ color: "#8995a6" }} />
            </ContainerIcon>
          </DarkTooltip>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <RowRoom />
      </DialogContent>
      <DialogActions>
        <CancelButton />
      </DialogActions>
    </Dialog>
  );
};

export default ModalRoomOn;
