import { Grid } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import styled from "styled-components";

export const MainText = styled.p`
  color: #162238;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  text-align: center;
`;

export const GreySquare = styled(Grid)`
  width: 100%;
  height: 100%;
  padding: 34px;
  border-radius: 28px;
  background: #d9d9d94d;
`;

export const ExplainTextDiv = styled.div`
  align-self: stretch;
  color: #000;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0.15px;
`;
export const ExplainTextTitle = styled.p`
  color: #0036b3;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0.15px;
`;

export const BackupDatagrid = styled(DataGrid)`
  border: 0px !important;

  .MuiDataGrid-root {
    border: 0px !important;
  }
  .MuiDataGrid-columnsContainer {
    background: #f5f5f5;
    align-items: center;
    padding-top: 10px;
  }
`;
