import React from "react";
import { Helmet } from "react-helmet";
import { ItemGridDragDropTitleStyle } from "../../components/grid-drag-drop/ItemGridDragDropTitleStyle";
import { Grid, IconButton } from "@material-ui/core";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import { LightTooltip, Margin } from "../../styles/GlobalStyle";
import CachedOutlinedIcon from "@material-ui/icons/CachedOutlined";
import CloseIcon from "@material-ui/icons/Close";
import { DividerStyled } from "../../components/chat/styles/GeneralStyle";
import useDashboardViewController from "../../pages/dashboard/controllers/DashboardViewController";

export const WidgetVersion51 = () => {
  const { handleDeleteWidgets } = useDashboardViewController();

  const item = {
    i: "51",
    importScripts: [""],
    stringScripts: [""],
    onRefresh: `
    try {
        const GECS_ENVIRONMENT = localStorage.getItem("environment") ?? "test";
        const GECS_ERP_API_URL = GECS_ENVIRONMENT === "prod" ? "https://qerpapi.qyon.com" :
            GECS_ENVIRONMENT === "beta" ? "https://beta-qerpapi.qyon.com" :
            GECS_ENVIRONMENT === "dev" ? "http://localhost:85" :
            "https://test-qerpapi.qyon.com";
    
        const GECS_ERP_API_TOKEN = GECS_ENVIRONMENT === "prod" ? "eVmDI3J6dCqvxDUyZv16Sr1KBDW0TTNKvZA4LCkY" : "eyJ0ZXN0ZSI6InJhZmFvbGluZG9zejEyMyJ9";

        const documentPanel = localStorage.getItem("documentPanel");
    
        const GECS_getContractStatus = async () => {
            const GECS_contractStatusData = await fetch(
                \`\${GECS_ERP_API_URL}/api/erp/faturamento/contratos/v1/status?\${new URLSearchParams({empresa_cnpj: documentPanel})}\`,
                {
                    method: "GET",
                    headers: new Headers({ Authorization: GECS_ERP_API_TOKEN })
                }
            )
            .then((res) => {
                if (res.ok) {
                    return res.json();
                }
                throw res.statusText;
            })
            .catch((error) => {
                console.error("Widget GE - Contrato, fetch error:", error);
                if (document.getElementById("GECS_fetchError")) {
                    document.getElementById("GECS_fetchError").style.display = "flex";
                    document.getElementById("GECS_loaderAnimation").style.display = "none";
                }
            });
            return GECS_contractStatusData?.success ? GECS_contractStatusData.data : false;
        };
    
        function GECS_numberUStoBR(number) {
            return number.toLocaleString("pt-br", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        }
    
        function GECS_numberUStoBRNoFraction(number) {
            return number.toLocaleString("pt-br", { minimumFractionDigits: 0, maximumFractionDigits: 0 });
        }
    
        const GECS_populateContracts = async () => {
            try {
                let GECS_contractStatusReturn = await GECS_getContractStatus();
                if (GECS_contractStatusReturn && document.getElementById("GECS_content")) {
                    document.getElementById("GECS_totalCustomers").innerHTML = GECS_numberUStoBRNoFraction(GECS_contractStatusReturn.customers.total);
                    document.getElementById("GECS_inMonthAmount").innerHTML = GECS_numberUStoBRNoFraction(GECS_contractStatusReturn.recurringServices.inMonth.amount);
                    document.getElementById("GECS_inMonthGrowthPercent").innerHTML = GECS_numberUStoBR(GECS_contractStatusReturn.recurringServices.inMonth.growthPercent);
                    document.getElementById("GECS_totalCustomersPreviousMonth").innerHTML = GECS_numberUStoBRNoFraction(GECS_contractStatusReturn.customers.totalPreviousMonth);
                    document.getElementById("GECS_previousMonthAmount").innerHTML = GECS_numberUStoBRNoFraction(GECS_contractStatusReturn.recurringServices.previousMonth.amount);
                    document.getElementById("GECS_previousMonthGrowthPercent").innerHTML = GECS_numberUStoBR(GECS_contractStatusReturn.recurringServices.previousMonth.growthPercent);
                    document.getElementById("GECS_totalCustomersPreviousYear").innerHTML = GECS_numberUStoBRNoFraction(GECS_contractStatusReturn.customers.totalPreviousYear);
                    document.getElementById("GECS_previousYearAmount").innerHTML = GECS_numberUStoBRNoFraction(GECS_contractStatusReturn.recurringServices.previousYear.amount);
                    document.getElementById("GECS_previousYearGrowthPercent").innerHTML = GECS_numberUStoBR(GECS_contractStatusReturn.recurringServices.previousYear.growthPercent);
                    document.getElementById("GECS_averageTicketValue").innerHTML = GECS_numberUStoBR(GECS_contractStatusReturn.avaregeTicket.value);
                    document.getElementById("GECS_averageTicketInYear").innerHTML = GECS_numberUStoBR(GECS_contractStatusReturn.avaregeTicket.inYear);
                    document.getElementById("GECS_totalRecurrence").innerHTML = GECS_numberUStoBRNoFraction(GECS_contractStatusReturn.nonPayment.totalRecurrence);
                    document.getElementById("GECS_amountRecieved").innerHTML = GECS_numberUStoBRNoFraction(GECS_contractStatusReturn.nonPayment.amountReceived);
                    document.getElementById("GECS_amountLatevalue").innerHTML = GECS_numberUStoBRNoFraction(GECS_contractStatusReturn.nonPayment.amountLate.amount);
                    document.getElementById("GECS_amountLatePercent").innerHTML = GECS_numberUStoBR(GECS_contractStatusReturn.nonPayment.amountLate.percent);
                    document.getElementById("GECS_lastThreeMonthValue").innerHTML = GECS_numberUStoBRNoFraction(GECS_contractStatusReturn.nonPayment.lastThreeMonth.amount);
                    document.getElementById("GECS_lastThreeMonthPercent").innerHTML = GECS_numberUStoBR(GECS_contractStatusReturn.nonPayment.lastThreeMonth.percent);
                    document.getElementById("GECS_content").style.display = "block";
                    document.getElementById("GECS_content2").style.display = "block";
                }
            } catch (error) {
                console.error("Widget GE - Contrato, populate error:", error);
                document.getElementById("GECS_fetchError").style.display = "flex";
                document.getElementById("GECS_content").style.display = "none";
                document.getElementById("GECS_content2").style.display = "none";
            } finally {
                if (document.getElementById("GECS_loaderAnimation")) {
                    document.getElementById("GECS_loaderAnimation").style.display = "none";
                }
            }
        };
    
        document.getElementById("GECS_loaderAnimation").style.display = "block";
        document.getElementById("GECS_fetchError").style.display = "none";
        document.getElementById("GECS_content").style.display = "none";
        document.getElementById("GECS_content2").style.display = "none";
    
        try {
            GECS_populateContracts();
        } catch (error) {
            console.error("Widget GE - Contrato, error:", error);
            document.getElementById("GECS_fetchError").style.display = "flex";
            document.getElementById("GECS_loaderAnimation").style.display = "none";
        }
    } catch (err) {}
    `,
    refreshOnLoad: true,
  };

  return (
    <>
      {item.importScripts.map((script, index) => {
        return (
          <div key={`import_${index}`}>
            <Helmet>
              <script async src={script}></script>
            </Helmet>
          </div>
        );
      })}

      <ItemGridDragDropTitleStyle customStyle={item.headerStyle ?? {}}>
        <Grid item container justifyContent="space-between" alignItems="center">
          <Grid item flex={1}>
            <IconButton size="small">
              <DragIndicatorIcon style={{ fontSize: 18, marginRight: 5 }} />
            </IconButton>
            Contratos
          </Grid>
          <Grid item flex={1}>
            <Grid item container alignItems="center">
              <Margin top="7" />
              {item.onRefresh &&
                (() => {
                  if (item?.refreshOnLoad) {
                    setTimeout(() => {
                      try {
                        // eslint-disable-next-line no-eval
                        eval(
                          "try{" +
                            item.onRefresh +
                            "}catch(err){console.log(err)}"
                        );
                      } catch (error) {
                        console.error(error);
                      }
                    }, 1500);
                  }

                  return (
                    <Grid item flex={1}>
                      <LightTooltip title="Atualizar">
                        <CachedOutlinedIcon
                          style={{
                            fontSize: 18,
                            marginRight: 10,
                            cursor: "pointer",
                            color: "#636363",
                          }}
                          onClick={() => {
                            // Muito cuidado, o eval é perigoso - https://developer.mozilla.org/pt-BR/docs/Web/JavaScript/Reference/Global_Objects/eval
                            try {
                              // eslint-disable-next-line no-eval
                              eval("try{" + item.onRefresh + "}catch(err){}");
                            } catch (error) {
                              console.error(error);
                            }
                          }}
                        />
                      </LightTooltip>
                    </Grid>
                  );
                })()}
              <Grid item flex={1}>
                <LightTooltip title="Remover">
                  <CloseIcon
                    style={{
                      fontSize: 18,
                      cursor: "pointer",
                      color: "#636363",
                      marginRight: 10,
                    }}
                    onClick={() => handleDeleteWidgets(item)}
                  />
                </LightTooltip>
              </Grid>
            </Grid>
          </Grid>
          <DividerStyled style={{ width: "100%" }} />
        </Grid>
      </ItemGridDragDropTitleStyle>

      <div
        style={{
          height: `${"calc(100% - 65px)"}`,
          width: "100%",
        }}
      >
        <>
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link
            rel="preconnect"
            href="https://fonts.gstatic.com"
            crossOrigin="true"
          />
          <link
            href="https://fonts.googleapis.com/css2?family=Roboto:wght@100;400&display=swap"
            rel="stylesheet"
          />
          <link
            href="https://fonts.googleapis.com/icon?family=Material+Icons+Round"
            rel="stylesheet"
          />

          <style>
            {`@keyframes GECS_spinner-grow {
      0% {
        transform: scale(0);
      }
      50% {
        opacity: 1;
        transform: none;
      }
    }`}
          </style>

          <div
            id="GECS_widget"
            style={{
              height: "100%",
              fontFamily: "'Roboto', sans-serif",
              position: "relative",
            }}
          >
            {/* Animação de Carregamento */}
            <div
              id="GECS_loaderAnimation"
              style={{
                color: "#0d6efd",
                display: "none",
                width: "5rem",
                height: "5rem",
                verticalAlign: "-0.125em",
                backgroundColor: "currentColor",
                borderRadius: "50%",
                opacity: 0,
                animation: "1.5s linear infinite GECS_spinner-grow",
                position: "absolute",
                top: "calc(50% - 2.5rem)",
                left: "calc(50% - 2.5rem)",
              }}
            ></div>

            {/* Erro de Carregamento */}
            <div
              id="GECS_fetchError"
              style={{
                position: "absolute",
                width: "calc(100% - 20px)",
                height: "100%",
                display: "none",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span
                className="material-icons-round"
                style={{ fontSize: "3em", margin: "8px" }}
              >
                error_outline
              </span>
              <p>
                Não foi possível carregar os dados
                <br />
                Tente novamente
              </p>
            </div>

            {/* Conteúdo Principal */}
            <div
              id="GECS_content"
              style={{
                height: "calc(60% - 5px)",
                marginBottom: "10px",
              }}
            >
              <h3 style={{ margin: 0, padding: "5px 0", fontSize: "15px" }}>
                Serviço Recorrente
              </h3>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  height: "calc(100% - 31.45px)",
                }}
              >
                {/* Bloco 1 */}
                <div
                  style={{
                    width: "calc(25% - 10px)",
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    padding: "0 6px",
                    borderLeft: "4px solid #0a73db",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      height: "50%",
                    }}
                  >
                    <span
                      id="GECS_totalCustomers"
                      style={{ fontSize: "1.2em", fontWeight: "bold" }}
                    >
                      -
                    </span>
                    <span style={{ fontSize: "0.8em", color: "#777777" }}>
                      Total Clientes
                    </span>
                  </div>
                  <hr
                    style={{
                      width: "80%",
                      border: "none",
                      height: "1px",
                      backgroundColor: "#ddd",
                    }}
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      height: "50%",
                      justifyContent: "flex-end",
                    }}
                  >
                    <div style={{ fontSize: "1.2em", fontWeight: "bold" }}>
                      R$ <span id="GECS_inMonthAmount">-</span>
                    </div>
                    <div style={{ fontSize: "0.8em", color: "#777777" }}>
                      <span>No mês</span>{" "}
                      <span id="GECS_inMonthGrowthPercent">-</span>%
                    </div>
                  </div>
                </div>

                {/* Bloco 2 */}
                <div
                  style={{
                    width: "calc(25% - 10px)",
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    padding: "0 6px",
                    borderLeft: "4px solid #0a73db",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      height: "50%",
                    }}
                  >
                    <span
                      id="GECS_totalCustomersPreviousMonth"
                      style={{ fontSize: "1.2em", fontWeight: "bold" }}
                    >
                      -
                    </span>
                    <span style={{ fontSize: "0.8em", color: "#777777" }}>
                      Total mês anterior
                    </span>
                  </div>
                  <hr
                    style={{
                      width: "80%",
                      border: "none",
                      height: "1px",
                      backgroundColor: "#ddd",
                    }}
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      height: "50%",
                      justifyContent: "flex-end",
                    }}
                  >
                    <div style={{ fontSize: "1.2em", fontWeight: "bold" }}>
                      R$ <span id="GECS_previousMonthAmount">-</span>
                    </div>
                    <div style={{ fontSize: "0.8em", color: "#777777" }}>
                      <span>Mês anterior</span>{" "}
                      <span id="GECS_previousMonthGrowthPercent">-</span>%
                    </div>
                  </div>
                </div>

                {/* Bloco 3 */}
                <div
                  style={{
                    width: "calc(25% - 10px)",
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    padding: "0 6px",
                    borderLeft: "4px solid #0a73db",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      height: "50%",
                    }}
                  >
                    <span
                      id="GECS_totalCustomersPreviousYear"
                      style={{ fontSize: "1.2em", fontWeight: "bold" }}
                    >
                      -
                    </span>
                    <span style={{ fontSize: "0.8em", color: "#777777" }}>
                      Total ano anterior
                    </span>
                  </div>
                  <hr
                    style={{
                      width: "80%",
                      border: "none",
                      height: "1px",
                      backgroundColor: "#ddd",
                    }}
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      height: "50%",
                      justifyContent: "flex-end",
                    }}
                  >
                    <div style={{ fontSize: "1.2em", fontWeight: "bold" }}>
                      R$ <span id="GECS_previousYearAmount">-</span>
                    </div>
                    <div style={{ fontSize: "0.8em", color: "#777777" }}>
                      <span>Ano anterior</span>{" "}
                      <span id="GECS_previousYearGrowthPercent">-</span>%
                    </div>
                  </div>
                </div>

                {/* Bloco 4 */}
                <div
                  style={{
                    width: "25%",
                    height: "100%",
                    backgroundColor: "#f1f1f1",
                    borderRadius: "5px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-around",
                    alignItems: "center",
                    paddingTop: "5px 0",
                  }}
                >
                  <h4
                    style={{
                      width: "100%",
                      textAlign: "center",
                      color: "#0a73db",
                      margin: 0,
                    }}
                  >
                    Ticket médio
                  </h4>
                  <div
                    style={{
                      fontSize: "1.4em",
                      fontWeight: "bold",
                      color: "#0a73db",
                    }}
                  >
                    R$ <span id="GECS_averageTicketValue">-</span>
                  </div>
                  <div style={{ fontSize: "0.8em", color: "#777777" }}>
                    <span>No ano</span>{" "}
                    <span id="GECS_averageTicketInYear">-</span>%
                  </div>
                </div>
              </div>
            </div>

            {/* Conteúdo 2: Inadimplência */}
            <div
              id="GECS_content2"
              style={{
                width: "100%",
                height: "calc(40% - 5px)",
              }}
            >
              <h3 style={{ margin: 0, padding: "5px 0", fontSize: "15px" }}>
                Inadimplência
              </h3>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  height: "calc(100% - 31.45px)",
                }}
              >
                <div
                  style={{
                    width: "25%",
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    justifyContent: "center",
                    borderLeft: "4px solid #fa3a3a",
                    padding: "0 6px",
                  }}
                >
                  <div style={{ fontSize: "1.2em", fontWeight: "bold" }}>
                    {" "}
                    R$ <span id="GECS_totalRecurrence">-</span>
                  </div>
                  <hr
                    style={{
                      width: "80%",
                      border: "none",
                      height: "1px",
                      color: "#ddd",
                      backgroundColor: "#ddd",
                      marginLeft: 0,
                    }}
                  />
                  <span style={{ fontSize: "0.8em", color: "#777777" }}>
                    Recorrência Total
                  </span>
                </div>
                <div
                  style={{
                    width: "25%",
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    justifyContent: "center",
                    borderLeft: "4px solid #fa3a3a",
                    padding: "0 6px",
                  }}
                >
                  <div style={{ fontSize: "1.2em", fontWeight: "bold" }}>
                    {" "}
                    R$ <span id="GECS_amountRecieved">-</span>
                  </div>
                  <hr
                    style={{
                      width: "80%",
                      border: "none",
                      height: "1px",
                      color: "#ddd",
                      backgroundColor: "#ddd",
                      marginLeft: 0,
                    }}
                  />
                  <span style={{ fontSize: "0.8em", color: "#777777" }}>
                    Valor Recebido
                  </span>
                </div>
                <div
                  style={{
                    width: "25%",
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    justifyContent: "center",
                    borderLeft: "4px solid #fa3a3a",
                    padding: "0 6px",
                  }}
                >
                  <div style={{ fontSize: "1.2em", fontWeight: "bold" }}>
                    {" "}
                    R$ <span id="GECS_amountLatevalue">-</span>
                  </div>
                  <hr
                    style={{
                      width: "80%",
                      border: "none",
                      height: "1px",
                      color: "#ddd",
                      backgroundColor: "#ddd",
                      marginLeft: 0,
                    }}
                  />
                  <div style={{ fontSize: "0.8em", color: "#777777" }}>
                    <span>Valor atrasado</span> (
                    <span id="GECS_amountLatePercent">-</span>%){" "}
                  </div>
                </div>
                <div
                  style={{
                    width: "25%",
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    justifyContent: "center",
                    borderLeft: "4px solid #fa3a3a",
                    padding: "0 6px",
                  }}
                >
                  <div style={{ fontSize: "1.2em", fontWeight: "bold" }}>
                    {" "}
                    R$ <span id="GECS_lastThreeMonthValue">-</span>
                  </div>
                  <hr
                    style={{
                      width: "80%",
                      border: "none",
                      height: "1px",
                      color: "#ddd",
                      backgroundColor: "#ddd",
                      marginLeft: 0,
                    }}
                  />
                  <div style={{ fontSize: "0.8em", color: "#777777" }}>
                    <span>Ult. 3 meses</span> (
                    <span id="GECS_lastThreeMonthPercent">-</span>%){" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      </div>

      {item.stringScripts &&
        item.stringScripts.map((script, index) => {
          return (
            <div key={`script_${index}`}>
              <Helmet>
                <script async>{script}</script>
              </Helmet>
            </div>
          );
        })}
    </>
  );
};
