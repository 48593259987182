import { createContext, useState } from "react";

const NewBotContext = createContext();

const NewBotContextProvider = ({ children }) => {
  const [botMode, setBotMode] = useState("");
  const [botText, setBotText] = useState("");
  const [firstBotMessageText, setFirstBotMessageText] = useState("");
  const [botTextContext, setBotTextContext] = useState([]);
  const [botCurrentContext, setBotCurrentContext] = useState(null);
  const [clientsNFSE, setClientsNFSE] = useState([]);
  const [dataRPS, setDataRPS] = useState([]);
  const [certificateList, setCertificateList] = useState([]);
  const [openCertificateModal, setOpenCertificateModal] = useState(false);
  const [openCompanyModal, setOpenCompanyModal] = useState(false);
  const [loadingCompany, setLoadingCompany] = useState(false);
  const [companyErrorList, setCompanyErrorList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [keyCompany, setKeyCompany] = useState(Math.random());
  const [loadingMigration, setLoadingMigration] = useState(false);
  const [openMigrationModal, setOpenMigrationModal] = useState(false);
  const [keyMigration, setKeyMigration] = useState(Math.random());
  const [loadingXml, setLoadingXml] = useState(false);
  const [openXmlModal, setOpenXmlModal] = useState(false);
  const [keyXml, setKeyXml] = useState(Math.random());
  const [xmlList, setXmlList] = useState([]);
  const [clients, setClients] = useState(null);
  const [openImageModal, setOpenImageModal] = useState(false);
  const [logoImage, setLogoImage] = useState(null);
  const [openDocumentModal, setOpenDocumentModal] = useState(false);
  const [documentList, setDocumentList] = useState([]);
  const [documentModalLoading, setDocumentModalLoading] = useState(false);
  const [keyDocument, setKeyDocument] = useState(Math.random());
  const [loadingZip, setLoadingZip] = useState(false);
  const [openZipModal, setOpenZipModal] = useState(false);
  const [zipList, setZipList] = useState([]);
  const [openFileNotRecognizedModal, setOpenFileNotRecognizedModal] =
    useState(false);
  const [clientBillingData, setClientBillingData] = useState({
    loading: true,
    base64: "",
    name: "",
    dueDate: "",
    total: null,
    emittedDate: "",
    enableBoleto: false,
    typeableLine: "",
  });
  const [animation, setAnimation] = useState("");
  const [isBotPresentationRunning, setBotPresentationRunning] = useState(null);
  const [showDialogUploadDocument, setShowDialogUploadDocument] =
    useState(false);
  const [batteryStatus, setBatteryStatus] = useState({
    onError: false,
    image: null,
    status: "",
    diff: null,
  });
  const [openBotChatModal, setOpenBotChatModal] = useState(false);
  const [openMetrics, setOpenMetrics] = useState(false);
  const [openPanelVideo, setOpenPanelVideo] = useState(false);
  const [openShowVideoList, setOpenShowVideoList] = useState(false);
  const [videoList, setVideoList] = useState([]);
  const [keyZip, setKeyZip] = useState(Math.random());
  const [openClientBillModal, setOpenClientBillModal] = useState(false);
  const [openBotTrainingConfiguration, setOpenBotTrainingConfiguration] =
    useState(false);
  const [messageBattery, setMessageBattery] = useState("");

  const [openNotFoundCompanyModal, setOpenNotFoundCompanyModal] =
    useState(false);
  const [openCompanyHasAccountantModal, setOpenCompanyHasAccountantModal] =
    useState(false);

  return (
    <NewBotContext.Provider
      value={{
        clientsNFSE,
        setClientsNFSE,
        dataRPS,
        setDataRPS,
        certificateList,
        setCertificateList,
        openCertificateModal,
        setOpenCertificateModal,
        openCompanyModal,
        setOpenCompanyModal,
        loadingCompany,
        setLoadingCompany,
        companyErrorList,
        setCompanyErrorList,
        companyList,
        setCompanyList,
        keyCompany,
        setKeyCompany,
        loadingMigration,
        setLoadingMigration,
        openMigrationModal,
        setOpenMigrationModal,
        keyMigration,
        setKeyMigration,
        loadingXml,
        setLoadingXml,
        openXmlModal,
        setOpenXmlModal,
        keyXml,
        setKeyXml,
        xmlList,
        setXmlList,
        clients,
        setClients,
        openImageModal,
        setOpenImageModal,
        logoImage,
        setLogoImage,
        openDocumentModal,
        setOpenDocumentModal,
        documentList,
        setDocumentList,
        documentModalLoading,
        setDocumentModalLoading,
        keyDocument,
        setKeyDocument,
        loadingZip,
        setLoadingZip,
        openZipModal,
        setOpenZipModal,
        zipList,
        setZipList,
        openFileNotRecognizedModal,
        setOpenFileNotRecognizedModal,
        clientBillingData,
        setClientBillingData,
        animation,
        setAnimation,
        isBotPresentationRunning,
        setBotPresentationRunning,
        showDialogUploadDocument,
        setShowDialogUploadDocument,
        batteryStatus,
        setBatteryStatus,
        openBotChatModal,
        setOpenBotChatModal,
        openMetrics,
        setOpenMetrics,
        openPanelVideo,
        setOpenPanelVideo,
        openShowVideoList,
        setOpenShowVideoList,
        videoList,
        setVideoList,
        keyZip,
        setKeyZip,
        openClientBillModal,
        setOpenClientBillModal,
        openBotTrainingConfiguration,
        setOpenBotTrainingConfiguration,
        messageBattery,
        setMessageBattery,
        botText,
        setBotText,
        botTextContext,
        setBotTextContext,
        setFirstBotMessageText,
        firstBotMessageText,
        botCurrentContext,
        setBotCurrentContext,
        botMode,
        setBotMode,
        openNotFoundCompanyModal,
        setOpenNotFoundCompanyModal,
        openCompanyHasAccountantModal,
        setOpenCompanyHasAccountantModal,
      }}
    >
      {children}
    </NewBotContext.Provider>
  );
};

export { NewBotContextProvider, NewBotContext };
