import { Switch } from "react-router-dom";

import MiddlewareRoute from "../Middleware";

import { OfxConversor } from "../../pages/ofxConversor/OfxConversor";
import { GlobalVariables } from "../../GlobalVariables";

export default function OfxConversorModule() {
  return (
    <Switch>
      <MiddlewareRoute
        path="/ofx-conversor"
        sidebar
        component={OfxConversor}
        noPaddingSidebar
        routePermissionId={GlobalVariables.PERMISSIONS.OFX}
      />
    </Switch>
  );
}
