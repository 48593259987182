import { search } from "../../BaseRepositoryComponents";
import axios from "axios";

const newPath = `${process.env.REACT_APP_NEW_PAINEL_API_URL}`;

export async function auth({ email, password }) {
  return search({
    path: `${newPath}/v1/auth/login`,
    method: "POST",
    myBody: { email, password },
    logoutOnUnauthorized: false,
  });
}

export async function getEntities(id) {
  let idSearch = id;
  if (typeof id === "object") {
    idSearch = id.id;
  }

  return search({
    path: `${newPath}/v1/entity_user/${idSearch}`,
    method: "GET",
  });
}

export async function userLogin({ id }) {
  return search({
    path: `${newPath}/v1/entity_user/${id}`,
    method: "GET",
  });
}

export async function showUserVideoIntro({ body }) {
  return search({
    path: `${newPath}/v1/user/show-video-first-access`,
    method: "PUT",
    myBody: body,
  });
}

export async function changePasswordFirstTime({ body }) {
  try {
    const response = await axios.post(
      `${newPath}/v1/auth/change-password-first-time`,
      body
    );
    return response?.data || null;
  } catch (error) {
    return error?.response?.data || null;
  }
}

export async function resendConfirmationCode(email_to_send) {
  try {
    const response = await axios.post(
      `${newPath}/v1/auth/resend-confirmation-code`,
      email_to_send
    );
    return response?.data || null;
  } catch (error) {
    return error?.response?.data || null;
  }
}
