import { Switch } from "react-router-dom";
import { GlobalVariables } from "../../GlobalVariables";

import { UseGuideReportProvider } from "../../pages/GuideReports/contexts/GuideReportContext";
import GuideReportList from "../../pages/GuideReports/GuideReportList";
import MiddlewareRoute from "../Middleware";
import { DocumentGuideContextProvider } from "../../pages/GuideReports/contexts/DocumentGuideContext";

export default function Guide() {
  return (
    <UseGuideReportProvider>
      <DocumentGuideContextProvider>
        <Switch>
          <MiddlewareRoute
            routePermissionId={GlobalVariables.PERMISSIONS.DOCUMENTS_AND_GUIDES}
            path="/guide/:id?"
            sidebar
            type="guide"
            component={GuideReportList}
            noPaddingSidebar
          />
        </Switch>
      </DocumentGuideContextProvider>
    </UseGuideReportProvider>
  );
}
