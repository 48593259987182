import React, { useState, useEffect } from "react";

import { Grid, CircularProgress, Checkbox } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import BusinessIcon from "@material-ui/icons/Business";

import { migrationCompany } from "../../../repositories/MigrationEsocial";
import { notify } from "../../../utils/notify";
import { getMP3Audio } from "../../../repositories/AudioHandler";
import CloseIcon from "@material-ui/icons/Close";
import * as c from "./styles/MigrationModal";

export const MigrationModal = (props) => {
  const [disabled, setDisabled] = useState(false);

  const getTitle = () => {
    return props.companyList.length === 1
      ? "Detectei um CNPJ neste arquivo, posso iniciar a migração pelo eSocial dessa empresa para você?"
      : `Detectei uma lista de ${props.companyList.length} CNPJs neste arquivo, posso iniciar a migração pelo eSocial dessas empresas para você?`;
  };

  useEffect(() => {
    if (props.companyList.length) {
      let title = getTitle();

      for (let company of props.companyList) {
        company.checked = hasCertificate(company);
      }

      getMP3Audio(title).then((result) => {
        var snd = new Audio("data:audio/wav;base64," + result);
        snd.play();
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function hasCertificate(company) {
    return company && company.success && company.data.CaminhoCertificado;
  }

  async function startMigration() {
    props.loading(true);
    setDisabled(true);
    let checked = false;
    let success = true;
    let companyWithError = null;
    for (let company of props.companyList) {
      if (hasCertificate(company) && company.checked) {
        checked = true;
        let response = await migrationCompany(company.data.CNPJ);
        if (response == null || !response.success) {
          companyWithError = company;
          success = false;
          break;
        }
      }
    }

    if (!checked) {
      notify("Nenhuma empresa selecionada!");
      props.loading(false);
      setDisabled(false);
    } else if (success) {
      notify(
        "Estamos iniciando o processo de migração das empresas selecionadas!",
        true,
        "success"
      );
      props.closeMigrationModal();
      setDisabled(false);
    } else {
      notify(
        `Ocorreu um problema ao iniciar a migração da empresa ${companyWithError.data.Razao}.`
      );
      props.loading(false);
      setDisabled(false);
    }
  }

  return (
    <Modal
      open={props.openModal}
      onClose={() => {
        props.closeMigrationModal();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <c.StyledBox>
        {!props.loadingMigration && (
          <c.Title>
            <c.Row>
              <div style={{ display: "flex", flexDirection: "column" }}>
                {getTitle()}
              </div>
              <c.CloseBoxIcon>
                <CloseIcon
                  onClick={() => {
                    props.closeMigrationModal();
                  }}
                />
              </c.CloseBoxIcon>
            </c.Row>
          </c.Title>
        )}
        {props.loadingMigration && <c.Loading />}
        {!props.loadingMigration && !props.companyList.length && (
          <c.BodyBox>
            <c.Subtitle>
              O arquivo deve possuir CNPJs com o seguinte padrão: INICIAR
              MIGRAÇÃO ESOCIAL CNPJ1;CNPJ2;CNPJ3;
            </c.Subtitle>
          </c.BodyBox>
        )}
        {!props.loadingMigration && props.companyList.length && (
          <>
            <c.BodyBox>
              <b>
                Nota: o processo de migração pode ser acompanhado pelo módulo
                Qyon Folha
              </b>
            </c.BodyBox>

            <c.BodyBox>
              {props.companyList.map((company, index) => {
                return (
                  <Card style={{ marginBottom: 10 }} key={"COMPANY" + index}>
                    <Grid container spacing={2}>
                      <Grid item xs={2}>
                        <c.CompanyBox>
                          <BusinessIcon
                            style={{
                              fontSize: 36,
                              color: hasCertificate(company)
                                ? "green"
                                : company && company.success
                                ? "orange"
                                : "red",
                            }}
                          />
                        </c.CompanyBox>
                      </Grid>
                      <Grid item xs={10}>
                        <CardContent>
                          <c.CompanyName gutterBottom>
                            {company.data ? company.data.Razao : ""}
                          </c.CompanyName>
                          <c.GovId gutterBottom>CNPJ: {company.cnpj}</c.GovId>
                          <c.MigrateEsocial
                            style={{ fontSize: 14, fontWeight: "bold" }}
                          >
                            {hasCertificate(company) ? (
                              <>
                                <Checkbox
                                  defaultChecked={company.checked}
                                  onChange={() =>
                                    (company.checked = !company.checked)
                                  }
                                  style={{ padding: "0 5px 0 0" }}
                                />
                                <span style={{ color: "green" }}>
                                  Iniciar migração eSocial
                                </span>
                              </>
                            ) : company && company.success ? (
                              <span style={{ color: "orange" }}>
                                Atenção! É necessário o envio do certificado
                                antes de iniciar
                              </span>
                            ) : (
                              <span style={{ color: "red" }}>
                                Empresa não cadastrada
                              </span>
                            )}
                          </c.MigrateEsocial>
                        </CardContent>
                      </Grid>
                    </Grid>
                  </Card>
                );
              })}
            </c.BodyBox>

            {!props.loadingMigration && !disabled && props.companyList.length && (
              <c.RowButton>
                <c.NoButton onClick={() => props.closeMigrationModal()}>
                  Não
                </c.NoButton>
                &nbsp;
                <c.YesButton onClick={() => startMigration()}>Sim</c.YesButton>
              </c.RowButton>
            )}

            {disabled && (
              <div
                style={{
                  position: "relative",
                  right: 10,
                  bottom: 10,
                  float: "right",
                  marginTop: 20,
                }}
              >
                <CircularProgress style={{ width: 25, height: 25 }} />
              </div>
            )}
          </>
        )}
      </c.StyledBox>
    </Modal>
  );
};
