import React, { useContext, useEffect, useState } from "react";
import { DefaultPageContainer } from "../../components/layoutContainer/styles/LayoutContainer";
import { GlobalSubheader } from "../../components/subHeader/GlobalSubheader";
import { GlobalContext } from "../../contexts/GlobalContext";
import { ofxAuth } from "../../repositories/OfxConversor";
import { notify } from "../../utils/notify";
import { getAccountantClients } from "../../repositories/portal_api/AccountantClient";
import { CircularProgress } from "@material-ui/core";

export const OfxConversor = () => {
  const { companyUser, userLogged, setBreadcrumbs } = useContext(GlobalContext);
  const [loading, setLoading] = useState(false);
  const [clientList, setClientList] = useState([]);

  async function authOfx() {
    const response = await ofxAuth(userLogged?.userId);
    return response;
  }

  useEffect(() => {
    getAccountantClientsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (clientList.length !== 0) {
      setBreadcrumbs([]);
      authOfx().then((response) => {
        if (response?.success) {
          renderComponent(response?.data?.access_token);
        } else {
          notify("Ocorreu um erro na autenticação do OFX!");
        }
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientList]);

  const getAccountantClientsList = async () => {
    setLoading(true);
    const accountantClientsResponse = await getAccountantClients();
    const mappedClients = accountantClientsResponse.data.map((client) => ({
      name: client.company_name,
      document: client.document,
    }));
    setClientList(mappedClients);
    setLoading(false);
  };

  const renderComponent = (authToken) => {
    let referenceId = "";

    if (companyUser.isAccountant) {
      referenceId = companyUser?.document;
    } else {
      referenceId = companyUser?.accountantCnpj;
    }

    if (companyUser) {
      const instance = window.QyonPdf2Ofx({
        access_token: authToken,
        user_id: userLogged?.userId,
        reference_id: referenceId,
        target_list: clientList,
        primary_color: "#0036b3",
      });

      instance.showFileExplorer();
    }
  };

  return (
    <>
      <GlobalSubheader title={"Conversor OFX"} />
      <DefaultPageContainer
        style={{
          height: "calc(100% - 60px)",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            background: "#fff",
            border: "1px solid #E0E0E0",
            borderRadius: 15,
            padding: 25,
          }}
        >
          {loading ? (
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
              }}
            >
              {" "}
              <CircularProgress />
            </div>
          ) : (
            <div id="qyon-pdf2ofx"></div>
          )}
        </div>
      </DefaultPageContainer>
    </>
  );
};
