import * as React from "react";

function BackupCheckIcon(props) {
  return (
    <svg
      width={20}
      height={16}
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.741 1.402a1.667 1.667 0 012.351 2.363L8.207 14.598a1.667 1.667 0 01-2.351 0L.908 9.674A1.667 1.667 0 013.259 7.31l3.772 3.754 9.71-9.663z"
        fill="#43D25A"
      />
    </svg>
  );
}

export default BackupCheckIcon;
