import React from "react";
import { GlobalSubheader } from "../../components/subHeader/GlobalSubheader";
import { DefaultPageContainer } from "../../components/layoutContainer/styles/LayoutContainer";
import { PermissionsAndUsers } from "./components/PermissionsAndUsers";

export const UserAccess = ({ location }) => {
  return (
    <>
      <GlobalSubheader
        title={"Usuários e Acesso"}
        titleLocation={"/useraccess/management"}
      ></GlobalSubheader>
      <DefaultPageContainer>
        <div
          style={{
            background: "#fff",
            border: "1px solid #E0E0E0",
            borderRadius: 15,
            padding: 15,
          }}
        >
          <PermissionsAndUsers location={location} />
        </div>
      </DefaultPageContainer>
    </>
  );
};
