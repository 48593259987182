import { Redirect, Route as RouteDom } from "react-router-dom";
import { usePermissions } from "../utils/permissionsHook";

export default function MiddlewareRoute({
  path,
  component: Component,
  sidebar,
  partialLogin, //Fez login, mas não selecionou a empresa
  routePermissionId, //usuário precisa de permissão para acessar a rota
  exact = false,
  noPaddingSidebar = false,
}) {
  const { checkIfUserHasPartialPermission } = usePermissions();

  if (!path.includes("/public") && !path.includes("/versoes")) {
    if (
      !localStorage.tokenPanel ||
      (!partialLogin && !localStorage.companyIdPanel)
    ) {
      sessionStorage.setItem(
        "lastUriPanel",
        `${window.location.pathname}${window.location.search}`
      );
      return <Redirect to="/login?logout=true" />;
    }
  }

  if (
    routePermissionId &&
    !checkIfUserHasPartialPermission(routePermissionId)
  ) {
    return (
      <Redirect
        to={{
          pathname: "/dashboard/view",
          state: { restrictPageRedirect: true },
        }}
      />
    );
  }

  const Container = sidebar
    ? (props) => <Component {...props} />
    : (props) => <Component {...props} />;

  return <RouteDom exact={exact} path={path} component={Container} />;
}
