import styled from "styled-components";

export const SuccessColumn = styled.div`
  color: #1b5e20;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20.02px;
  letter-spacing: 0.15px;
  display: flex;
  height: 32px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  background: var(--Green-50, #e8f5e9);
`;

export const ProcurationExpiredColumn = styled.div`
  color: var(--Red-900, #b71c1c);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20.02px;
  letter-spacing: 0.15px;
  display: flex;
  height: 32px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  background: var(--Red-50, #ffebee);
`;

export const ErrorColumn = styled.div`
  color: var(--Orange-900, #e65100);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20.02px; /* 143% */
  letter-spacing: 0.15px;
  display: flex;
  height: 32px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  background: var(--Orange-50, #fff3e0);
`;

export const ScheduledColumn = styled.div`
  color: var(--Orange-900, #e65100);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20.02px; /* 143% */
  letter-spacing: 0.15px;
  display: flex;
  height: 32px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  background: var(--Orange-50, #fff3e0);
`;

export const PendingColumn = styled.div`
  color: var(--Orange-900, #e65100);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20.02px; /* 143% */
  letter-spacing: 0.15px;
  display: flex;
  height: 32px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  background: var(--Orange-50, #fff3e0);
`;

export const LastCheckDate = styled.p`
  color: #616161;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.92px;
  letter-spacing: 0.4px;
`;
