import * as React from "react";

function DashboardIcon(props) {
  return (
    <svg
      width={props?.width || 22}
      height={props?.height || 22}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 1.5a1 1 0 011-1h20a1 1 0 011 1v18.586c0 .07-.007.14-.021.207A1 1 0 0121 21.5H5.508a1 1 0 110-2H20v-11H2v5.586a1 1 0 11-2 0V1.5zm2 5v-4h18v4H2zm1-2.002a1 1 0 011-1h.5a1 1 0 010 2H4a1 1 0 01-1-1zm3.5.002a1 1 0 011-1H18a1 1 0 110 2H7.5a1 1 0 01-1-1zm7.193 7.292a1 1 0 011.404-.008l2.24 2.184a1 1 0 11-1.396 1.432l-1.534-1.495-3.323 3.318a1 1 0 01-1.398.014l-2.58-2.47-5.395 5.451a1 1 0 01-1.422-1.406l6.088-6.15a1 1 0 011.402-.02l2.584 2.475 3.33-3.325z"
        fill={props?.fill || "#0036B3"}
      />
    </svg>
  );
}

export default DashboardIcon;
