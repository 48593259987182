import { Grid, Tooltip } from "@material-ui/core";
import React, { useContext, useState, useEffect } from "react";
import {
  CompanyLogo,
  CompanyLogoDefault,
  HeaderOption,
  IconButtonHeader,
  StyledAppBar,
  StyledBar,
  StyledHeader,
  TitleSystem,
} from "./styles/Header";

import IconsHeader from "./IconsHeader";
import { GlobalContext } from "../../contexts/GlobalContext";
import { userLogin } from "../../repositories/panel/v1/UserRepository";
import { notify } from "../../utils/notify";
import { DialogUsersLogin } from "../sidebar/children/DialogLoginUsers";
import useAuthenticationTranslate from "../../translations/authentications/Authentication";
import {
  isAccountant,
  isAccountantClient,
  isCompany,
} from "../../utils/panelType";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { BotNotificationModal } from "../sidebar/children/BotNotificationModal";
import NotificationBadge, { Effect } from "react-notification-badge";
import Pusher from "pusher-js";
import LogoPortalHeader from "../logos/LogoPortalHeader";
import useLoginController from "../../pages/authentications/controllers/LoginController";
import { LoginContext } from "../../pages/authentications/contexts/LoginContext";

import "@qyon-brazil/chat/dist/style.css";
import { ChatProvider } from "@qyon-brazil/chat";

export const Header = ({
  type,
  customKey,
  setKey,
  setActionTotal,
  actionTotal,
  setActions,
  actions,
  refresh,
  ...props
}) => {
  const [openUser, setOpenUser] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [entities, setEntities] = useState([]);
  const [filteredRows, setFilteredRows] = useState(entities);
  const [companyChecked, setCompanyChecked] = useState("");
  const [openActionModal, setOpenActionModal] = useState(false);
  const [actionModalProduct, setActionModalProduct] = useState(null);
  const [eventGrid, setEventGrid] = useState(new Map());
  const isInClientView = sessionStorage.getItem("isInClientView");

  const loginContext = useContext(LoginContext);

  const { handleSelectEntity } = useLoginController();

  let clientViewed = null;
  if (isInClientView) {
    clientViewed = JSON.parse(sessionStorage.getItem("client"));
  }

  const { companyUser, userLogged, setParamsQyonBot } =
    useContext(GlobalContext);

  const [accountantAbbreviation, setAccountantAbbreviation] = useState("");

  useEffect(() => {
    var pusher = new Pusher("74f53ce76aadb042a826", {
      cluster: "mt1",
    });
    let ch = "qyon.dash." + localStorage.getItem("documentPanel");
    let ev = localStorage.getItem("documentPanel");

    var channel = pusher.subscribe(ch);
    channel.bind(ev, async (e) => {
      let json = e;

      if (typeof json === "string") {
        json = JSON.parse(e);
      }

      if (json.from === "QYON_DASH") {
        refresh();
      }

      if (json.from === "QYON_XML") {
        refresh();
      }

      if (json.event === "A1_UPLOAD") {
        setTimeout(() => {
          setParamsQyonBot((current) => ({ ...current, find: false }));
        }, [10000]);
      }

      let total = eventGrid.get(json.event);
      if (!total) {
        total = 1;
        eventGrid.set(json.event, 1);
      } else {
        eventGrid.set(json.event, ++total);
      }

      setEventGrid(eventGrid);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let abbreviation = companyUser.accountantName.split(" ");
    let output = "";
    if (abbreviation.length > 1) {
      output = abbreviation[0].charAt(0) + abbreviation[1].charAt(0);
    } else {
      output = companyUser.accountantName.charAt(0);
    }

    setAccountantAbbreviation(output);
  }, [companyUser.accountantName]);

  const translate = useAuthenticationTranslate();

  const { push } = useHistory();

  const OpenModalUsers = async () => {
    setOpenUser(true);
    setLoading(true);

    try {
      let response = await userLogin({
        id: localStorage.getItem("userIdPanel"),
      });
      const userEntities = response?.data?.entities
        ? [
          ...new Map(
            response.data.entities.map((entity) => [entity["id"], entity])
          ).values(),
        ]
        : [];
      setEntities(userEntities);
      setFilteredRows(userEntities);

      const companyIdPanel = localStorage.getItem("companyIdPanel");
      setCompanyChecked(companyIdPanel.toString());
    } catch (error) {
      notify("Algo deu errado", true, "warning");
    } finally {
      setLoading(false);
    }
  };

  const handleRadioChange = (event) => {
    setCompanyChecked(event.target.value);
  };

  const selectEntityLogin = async () => {
    const companyId = companyChecked;
    const userId = userLogged.userId;

    await handleSelectEntity({
      list: filteredRows,
      companyIdParam: companyId,
      userIdParam: userId,
    });
  };

  const CloseModalLogin = () => {
    setOpenUser(false);
  };

  function handleFilterChange(event) {
    const value = event.target.value.toLowerCase();
    const filteredData = entities.filter(
      (row) =>
        row.trading_name.toLowerCase().includes(value) ||
        row.document.toLowerCase().includes(value)
    );
    setFilteredRows(filteredData);
  }

  const goToGenial4 = () => {
    let type = "";
    if (isAccountant()) {
      type = "ACCOUNTANT";
    }

    if (isAccountantClient()) {
      type = "ACCOUNTANT_CLIENT";
    }

    if (isCompany()) {
      type = "COMPANY";
    }

    let relationships = "";
    if (
      companyUser.systemsPermissions !== null &&
      companyUser.systemsPermissions !== undefined
    ) {
      if (companyUser.systemsPermissions.length > 0) {
        let systemsActive = companyUser.systemsPermissions.filter((system) => {
          return system.status_product === "A";
        });

        systemsActive.forEach((system, index) => {
          switch (system.id) {
            case 1:
              relationships += "erp";
              break;
            case 2:
              relationships += "folha";
              break;
            case 3:
              relationships += "xml";
              break;
            case 4:
              relationships += "crm";
              break;
            case 5:
              relationships += "contabil";
              break;
            case 6:
              relationships += "fiscal";
              break;
            default:
              break;
          }
          if (index !== systemsActive.length - 1) {
            relationships += ",";
          }
        });
      }
    }
    window.open(
      `https://qyon.genial4.com?token=${localStorage.getItem(
        "tokenCognitoPanel"
      )}&email=${localStorage.getItem("emailPanel")}&first_name=${userLogged.firstName
      }&last_name=${userLogged.lastName}&govid=${localStorage.getItem(
        "documentPanel"
      )}&type=${type}${relationships !== "" ? "&relationships_user=" + relationships : ""
      }`,
      "_blank"
    );
  };

  const onDecreaseBadge = async () => {
    await refresh();

    setKey(Math.random());
  };

  const onCloseActionModal = () => {
    setOpenActionModal(false);
  };

  useEffect(() => {
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const exibitionTitle = {
    accountant: "Plataforma Contábil",
    standalone: "Plataforma Empresarial",
    client: "Portal do Cliente",
  };

  return (
    // zIndex 1201 devido a classe MuiPaper de dentro do componente MuiDrawer

    <StyledAppBar position="fixed" elevation={0}>
      {/* <Loading open={loginContext?.loading} style={{ zIndex: 1202 }} /> */}

      <StyledHeader container direction="row">
        <Grid
          item
          xs={12}
          sm={7}
          md={6}
          container
          alignItems="center"
          alignContent="center"
          direction="row"
        >
          {type === "client" && (
            <Grid
              item
              container
              direction="row"
              alignItems="center"
              spacing={3}
            >
              {(companyUser.accountantLogoUrl === undefined ||
                companyUser.accountantLogoUrl === "" ||
                companyUser.accountantLogoUrl === null) && (
                  <CompanyLogoDefault
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      push("/dashboard/view");
                    }}
                  >
                    {accountantAbbreviation}
                  </CompanyLogoDefault>
                )}

              {companyUser.accountantLogoUrl !== undefined &&
                companyUser.accountantLogoUrl !== "" &&
                companyUser.accountantLogoUrl !== null && (
                  <CompanyLogo
                    onClick={() => {
                      push("/dashboard/view");
                    }}
                    style={{ cursor: "pointer" }}
                    src={companyUser.accountantLogoUrl}
                    alt="Logo Empresa"
                  />
                )}

              <Grid
                item
                xs={8}
                direction="column"
                onClick={() => {
                  push("/dashboard/view");
                }}
                style={{ cursor: "pointer" }}
              >
                <TitleSystem bold={true}>
                  {companyUser.accountantName}
                </TitleSystem>
                <TitleSystem>
                  {type === "client" && !isInClientView && (
                    <Grid
                      item
                      xs={12}
                      style={{
                        textTransform: "capitalize",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                      }}
                    >
                      {exibitionTitle[type] +
                        " | " +
                        localStorage.getItem("companyNamePanel").toLowerCase()}
                    </Grid>
                  )}
                  {type === "client" && isInClientView && (
                    <Grid
                      item
                      xs={12}
                      style={{
                        textTransform: "capitalize",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                      }}
                    >
                      {clientViewed.clientCompanyName.toLowerCase()}
                    </Grid>
                  )}
                </TitleSystem>
              </Grid>
            </Grid>
          )}
          {type !== "client" && (
            <>
              <Grid
                item
                container
                xs={6}
                direction="column"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  push("/dashboard/view");
                }}
              >
                <LogoPortalHeader height={32} width={100} />
                {/* <img src={LogoHeader} alt="QYON" width={100} /> */}
                {/* <TitleSystem>
                  {type === "client" ? companyUser.accountantName + " | " : ""}
                  {exibitionTitle[type]}
                </TitleSystem> */}
              </Grid>
            </>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          sm={5}
          md={6}
          container
          direction="row-reverse"
          alignItems="center"
          spacing={2}
        >
          {type === "client" && (
            <>
              <LogoPortalHeader />
              <StyledBar type={type} width={3} height={30} />
            </>
          )}

          <HeaderOption>
            <Grid item>
              <div>
                <NotificationBadge
                  count={actionTotal}
                  effect={Effect.SCALE}
                  key={customKey}
                />
                <Tooltip title="Central de mensagens">
                  <IconButtonHeader
                    onClick={() => {
                      setActionModalProduct("ALL");
                      setOpenActionModal(true);
                    }}
                  >
                    <IconsHeader icon="Notification" color="#FFF" />
                  </IconButtonHeader>
                </Tooltip>
              </div>
            </Grid>
            <Grid item>
              <ChatProvider environment={'PANEL'} />
            </Grid>
            <Grid item>
              <Tooltip title="Configurações">
                <IconButtonHeader
                  onClick={() => {
                    push("/setting");
                  }}
                >
                  <IconsHeader icon="Config" color="#FFF" />
                </IconButtonHeader>
              </Tooltip>
            </Grid>

            <Grid item>
              <Tooltip title="QYON Academy">
                <IconButtonHeader onClick={goToGenial4}>
                  <IconsHeader icon="Genial" color="#FFF" />
                </IconButtonHeader>
              </Tooltip>
            </Grid>

            <Grid item>
              <Tooltip title="Feedback">
                <IconButtonHeader
                  onClick={() => {
                    const feedback = new window.QyonFeedback({
                      key: "8E20CAC85C784B9DB06C911013ACB027",
                      user: {
                        id: userLogged.userId,
                        name: userLogged.firstName.concat(
                          " ",
                          userLogged.lastName
                        ),
                        email: userLogged.email,
                        role: "CLIENT",
                      },
                    });
                    setTimeout(() => {
                      feedback.showFeedbackDialog();
                    }, 100);
                  }}
                >
                  <IconsHeader icon="FeedbackIcon" color="#FFF" />
                </IconButtonHeader>
              </Tooltip>
            </Grid>
            {!isInClientView && (
              <Grid item>
                <Tooltip title="Trocar de empresa">
                  <IconButtonHeader onClick={OpenModalUsers}>
                    <IconsHeader icon="SwitchUser" color="#FFF" />
                  </IconButtonHeader>
                </Tooltip>
              </Grid>
            )}
          </HeaderOption>
        </Grid>
      </StyledHeader>
      {openUser === true && (
        <DialogUsersLogin
          openModal={openUser}
          closeModal={CloseModalLogin}
          title={translate.selectCompany}
          searchItens={handleFilterChange}
          loading={loading || loginContext.loading}
          valueChecked={companyChecked}
          handleRadioChange={handleRadioChange}
          filteredRows={filteredRows}
          buttonConfirm={selectEntityLogin}
        />
      )}

      {openActionModal && (
        <BotNotificationModal
          open={openActionModal}
          product={actionModalProduct}
          onDecreaseBadge={onDecreaseBadge}
          onCloseActionModal={onCloseActionModal}
        />
      )}
    </StyledAppBar>
  );
};
