/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useMemo, useState } from "react";
import { GlobalContext } from "../../contexts/GlobalContext";
import useMenuDataOfficeController from "./MenuDataOfficeController";

import { ListMenuDataOffice } from "../sidebar/styles/Sidebar";

import { getSystemsInPreviewFromEntity } from "../../repositories/panel/v1/EntityRepository";
import { MenuItemPortal } from "./MenuItemPortal";
import { getEntitySystems } from "../../repositories/portal_api/Systems";
import { GlobalVariables } from "../../GlobalVariables";

export default function MenuPortal(props) {
  const {
    sidebar,
    companyUser,
    userLogged,
    animation,
    setSidebar,
    paramsQyonBot,
  } = useContext(GlobalContext);
  const [menusAccess, setMenusAccess] = useState([]);
  const [menusAccessTmp, setMenusAccessTmp] = useState([]);
  const [menuLoading, setMenuLoading] = useState(true);
  const {
    userPermissons,
    isAccountant,
    isClient,
    isStandaloneClient,
    isInClientView,
    optionsMenu,
    optionSubMenus,
    push,
    handleProductMenuHide,
    validateProductStatus,
    disconnect,
  } = useMenuDataOfficeController();

  const optionsMenus = useMemo(() => {
    return optionsMenu;
  }, [
    isClient,
    isStandaloneClient,
    userPermissons.customerManagement,
    userPermissons.gestaoFacil,
    userPermissons.contabil,
    userPermissons.fiscal,
    userPermissons.folha,
    userPermissons.cnd,
    userPermissons.crm,
    userPermissons.xml,
    companyUser.isAccountant,
    companyUser.document,
    companyUser.accountantCnpj,
    companyUser.accountantId,
    userLogged.token,
    userLogged.tokenCognito,
    handleProductMenuHide,
    validateProductStatus,
    isAccountant,
    isInClientView,
    push,
    disconnect,
    paramsQyonBot,
  ]);

  useEffect(() => {
    const start = async () => {
      const systems = await getSystemsInPreviewFromEntity();

      const entitySystems = await getEntitySystems();

      let inPreview = {
        CONTABIL: false,
        FOLHA: false,
        FISCAL: false,
      };
      const baseUrls = {
        ACCOUNTING: "https://ct.qyon.com",
        PAYROLL: "https://fp.qyon.com",
        TAX_WRITING: "https://ef.qyon.com",
      };

      if (systems?.data?.length > 0) {
        for (let system of systems?.data) {
          inPreview[system?.system] = system?.beta_enabled;
        }

        for (let menu of optionSubMenus["ACCOUNTING"]) {
          if (menu.value === "PAYROLL") {
            if (inPreview.FOLHA === true) {
              menu.baseUrl = baseUrls[menu.value];
            }
          }

          if (menu.value === "ACCOUNTING") {
            if (inPreview.CONTABIL === true) {
              menu.baseUrl = baseUrls[menu.value];
            }
          }

          if (menu.value === "TAX_WRITING") {
            if (inPreview.FISCAL === true) {
              menu.baseUrl = baseUrls[menu.value];
            }
          }
        }
      }

      //GAMBIARRA PRO NOVO PRODUTO AUTOMAÇÃO CONTABIL

      let hasFiscalAutomation = false;
      let hasGf = false;
      let hasBackup = false;
      let hasOfx = false;

      if (entitySystems?.success) {
        if (entitySystems?.data?.length > 0) {
          entitySystems.data.forEach((system) => {
            if (
              system.system_id === GlobalVariables.SYSTEMS.Fiscal &&
              system.system_version_id ===
                GlobalVariables.SYSTEM_VERSION_CODE.AUTOMACAO_FISCAL
            ) {
              hasFiscalAutomation = true;
            }

            if (system.system_id === GlobalVariables.SYSTEMS.ERP) {
              hasGf = true;
            }

            if (system.system_id === GlobalVariables.SYSTEMS.Fiscal) {
              hasGf = true;
            }

            if (system.system_id === GlobalVariables.SYSTEMS.BACKUP) {
              hasBackup = true;
            }

            if (
              system.system_id === GlobalVariables.SYSTEMS.OFX &&
              companyUser.isAccountant
            ) {
              hasOfx = true;
            }
          });
        }
      }

      optionsMenus.map((menu) => {
        if (hasFiscalAutomation) {
          if (menu.value === "PAYROLL" || menu.value === "ACCOUNTING") {
            menu.hide = true;
          }

          if (menu.value === "TAX_WRITING" && !isClient) {
            menu.hide = false;
          }

          if (!hasGf) {
            if (
              menu.value === "ERP" ||
              menu.value === "BANK" ||
              menu.value === "BILL_TO_PAY"
            ) {
              menu.hide = true;
            }
          }
        }

        if (hasBackup) {
          if (menu.value === "BACKUP") {
            menu.hide = false;
          }
        }

        if (hasOfx) {
          if (menu.value === "OFX") {
            menu.hide = false;
          }
        }
        return menu;
      });

      // FIM DA GAMBIARRA
    };

    try {
      start().then(() => {
        const objectMenus = {};
        if (!Array.isArray(companyUser?.systemsPermissions)) {
          return;
        }

        if (companyUser) {
          for (let menu of companyUser?.systemsPermissions) {
            objectMenus[menu.id] = { url: menu.url };
          }
        }

        const arrMenus = [];

        const hasPermissionForAccounting =
          companyUser?.systemsPermissions.filter(
            (e) => [5, 6, 2].includes(e.id) && e.status_product === "A"
          ).length > 0;

        const hasPermissionForGF =
          companyUser?.systemsPermissions.filter(
            (e) => [1].includes(e.id) && e.status_product === "A"
          ).length > 0;

        const hasPermissionForXML =
          companyUser?.systemsPermissions.filter(
            (e) => [3].includes(e.id) && e.status_product === "A"
          ).length > 0;

        if (optionsMenus?.length > 0) {
          optionsMenus.forEach((menu, i) => {
            if (menu.hide) return;

            if (menu.value === "XML" && !objectMenus[menu.systemId])
              menu.blur = true;

            let companyAccess = objectMenus[menu.systemId] ? true : false;

            if (menu.value === "ACCOUNTING") {
              companyAccess = hasPermissionForAccounting;
            }

            if (menu.value === "RADAR") {
              companyAccess =
                (hasPermissionForGF || hasPermissionForXML) &&
                userPermissons.cnd;
            }

            if (menu.value === "CND") {
              companyAccess =
                (hasPermissionForGF || hasPermissionForXML) &&
                userPermissons.cnd;
            }

            arrMenus.push({
              ...menu,
              showOrder: menu.order ?? i,
              companyAccess: companyAccess,
              baseUrl: objectMenus[menu.systemId]?.url,
            });
          });
        }

        try {
          for (let subitem of optionSubMenus["ACCOUNTING"]) {
            const systemId = subitem?.systemId;
            if (objectMenus[systemId]) {
              subitem.baseUrl = objectMenus[systemId]?.url;
              subitem.companyAccess = true;
            }
          }

          for (let subitem of optionSubMenus["RADAR"]) {
            subitem.companyAccess = hasPermissionForGF || hasPermissionForXML;
          }
        } catch (err) {}

        const ordenate = (a, b) => a.showOrder - b.showOrder;

        setMenusAccess(arrMenus.sort(ordenate));
        setMenusAccessTmp(arrMenus.sort(ordenate));
        setMenuLoading(false);
      });
    } catch (err) {
      console.error(err);
    }
  }, [companyUser, optionsMenus]);

  useEffect(() => {
    if (menuLoading) return;

    if (props.search === "") {
      setMenusAccessTmp(menusAccess);
      return;
    }

    // Find by menus
    let filterMenus = menusAccess.filter((menu) => {
      const label = menu.label.toLowerCase();
      const searchText = props.search.toLowerCase();

      return label.includes(searchText);
    });

    // Find by submenus
    let subItems = [];
    for (const key in optionSubMenus) {
      if (optionSubMenus.hasOwnProperty(key)) {
        for (let item of optionSubMenus[key]) {
          const label = item.label.toLowerCase();
          const searchText = props.search.toLowerCase();
          if (label.includes(searchText)) {
            const relatedParentElements = menusAccess.filter(
              (e) => e.value === key
            );
            if (relatedParentElements.length !== 0) {
              subItems = subItems.concat(relatedParentElements);
            }
          }
        }
      }
    }

    // Remove duplicated items
    subItems = subItems.filter(
      (item, index, self) =>
        index === self.findIndex((t) => t.value === item.value)
    );

    filterMenus = filterMenus.concat(subItems);

    // Remove duplicated items
    filterMenus = filterMenus.filter(
      (item, index, self) =>
        index === self.findIndex((t) => t.value === item.value)
    );

    setMenusAccessTmp(filterMenus);
  }, [props.search]);

  return (
    <>
      <ListMenuDataOffice style={{ color: "white", "overflow-x": "hidden" }}>
        {menusAccessTmp.map(({ ...props }) =>
          props.value !== "PRODUCTS" ? (
            <MenuItemPortal
              optionSubMenus={optionSubMenus}
              sidebar={sidebar}
              setSidebar={setSidebar}
              animation={animation}
              {...props}
            />
          ) : (
            <div style={{ padding: 10 }}>
              <div
                style={{
                  justifyContent: "start",
                  alignItems: "center",
                  borderBottom: "1px solid #212121",
                  color: "#212121",
                  paddingBottom: 10,
                  fontFamily: "Roboto",
                  fontSize: 12,
                  fontWeight: 500,
                }}
              >
                Apps
              </div>
            </div>
          )
        )}
      </ListMenuDataOffice>
    </>
  );
}
