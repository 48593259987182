import React from "react";
import { Helmet } from "react-helmet";
import { ItemGridDragDropTitleStyle } from "../../components/grid-drag-drop/ItemGridDragDropTitleStyle";
import { Grid, IconButton } from "@material-ui/core";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import { LightTooltip, Margin } from "../../styles/GlobalStyle";
import CachedOutlinedIcon from "@material-ui/icons/CachedOutlined";
import CloseIcon from "@material-ui/icons/Close";
import { DividerStyled } from "../../components/chat/styles/GeneralStyle";
import useDashboardViewController from "../../pages/dashboard/controllers/DashboardViewController";

export const WidgetVersion74 = () => {
  const { handleDeleteWidgets } = useDashboardViewController();

  const item = {
    i: "74",
    importScripts: [""],
    stringScripts: [
      `try {
        let QRSQStoken = localStorage.getItem("tokenCognitoPanel"),
            QRSQSdocument = localStorage.getItem("accountantCnpjPanel");
  
        const GRSQSShowError = () => {
          const errorElement = document.getElementById("GRSQSfetchError");
          if (errorElement) {
            errorElement.style.display = "flex";
            document.getElementById("GRSQSloaderAnimation").style.display = "none";
            document.getElementById("GRSQSwidGet").style.display = "none";
          }
        };
  
        const GRSQSgetUserByToken = async () => {
          const GRSQSUser = await fetch("https://crmcont.qyon.com/api-be/v1/auth", {
            headers: {
              "qyon-customer-cnpj": QRSQSdocument,
              Authorization: QRSQStoken,
            },
          })
            .then((res) => res.json())
            .catch((error) => {
              console.log("[error][widget][service-quantity-by-situation][GR] get user", error);
              GRSQSShowError();
            });
  
          return {
            companyId: GRSQSUser.company.id,
            attendantId: GRSQSUser.identifiers.userId,
            customerId: 88000000,
            customerContactId: GRSQSUser.identifiers.customerContactId,
          };
        };
  
        const GRSQSgetTickets = async () => {
          let GRSQSuser = await GRSQSgetUserByToken().catch((error) => {
            console.log("[error][widget][service-quantity-by-situation][GR] get user", error);
            GRSQSShowError();
          });
  
          let GRSQSapiCrmTickets = await fetch(
            \`https://crmcont.qyon.com/api-be/v1/ticket?customerId=\${GRSQSuser.customerId}\`,
            {
              headers: {
                "qyon-customer-cnpj": QRSQSdocument,
                Authorization: QRSQStoken,
              },
            }
          )
            .then((res) => res.json())
            .catch((error) => {
              console.log("[error][widget][service-quantity-by-situation][GR] get tickets", error);
              GRSQSShowError();
            });
  
          GRSQSReturnFormatedArray(GRSQSapiCrmTickets.items);
        };
  
        var GRSQSgroupByValues = [];
  
        const GRSQSrenderChart = (GRSQSTicketsGroupBySituation) => {
          var chart = new CanvasJS.Chart("GRSQSwidget", {
            subtitles: [
              {
                text: "Meus em Aberto x Situação Atendimento",
                fontColor: "gray",
                fontSize: 15,
              },
            ],
            axisX: {},
            legend: { fontSize: 12 },
            animationEnabled: true,
            width: 440,
            height: 320,
            data: [
              {
                click: function (e) {},
                type: "pie",
                startAngle: 10,
                toolTipContent: "{legendText}:{y} - <strong>#percent% </strong>",
                indexLabelFontSize: 12,
                showInLegend: false,
                dataPoints: GRSQSTicketsGroupBySituation.map((GRSQSticketBySituation) => ({
                  y: GRSQSticketBySituation.items.length,
                  indexLabel: \`\${GRSQSticketBySituation.situation} #percent%\`,
                  legendText: GRSQSticketBySituation.situation,
                  cursor: "pointer",
                  id: GRSQSticketBySituation.situationId,
                })),
              },
            ],
          });
          chart.render();
        };
  
        const GRSQSReturnFormatedArray = (json) => {
          let GRSQSGroupedTickets = [];
          json.map((GRSQSitem) => {
            let GRSQSfindItemIndex = GRSQSGroupedTickets.findIndex(
              (tmp) => tmp.situationId == GRSQSitem.situationId
            );
            if (GRSQSfindItemIndex == -1) {
              GRSQSGroupedTickets.push({
                situationId: GRSQSitem.situationId,
                situation: GRSQSitem.situation ? GRSQSitem.situation : "Sem Situação",
                items: [GRSQSitem],
              });
            } else {
              GRSQSGroupedTickets[GRSQSfindItemIndex].items.push(GRSQSitem);
            }
          });
          GRSQSrenderChart(GRSQSGroupedTickets);
          document.getElementsByClassName("canvasjs-chart-credit")[0].remove();
        };
  
        try {
          (async () => {
            await GRSQSgetTickets().catch((error) => {
              console.log("[error][widget][service-quantity-by-situation][GR] main function", error);
              GRSQSShowError();
            });
            document.getElementById("GRSQSloaderAnimation").style.display = "none";
          })();
        } catch (error) {
          console.log("[error][widget][service-quantity-by-situation][GR] main function", error);
          GRSQSShowError();
        }
      } catch (err) {}`,
    ],
    onRefresh: "",
    refreshOnLoad: true,
  };

  return (
    <>
      {item.importScripts.map((script, index) => {
        return (
          <div key={`import_${index}`}>
            <Helmet>
              <script async src={script}></script>
            </Helmet>
          </div>
        );
      })}

      <ItemGridDragDropTitleStyle customStyle={item.headerStyle ?? {}}>
        <Grid item container justifyContent="space-between" alignItems="center">
          <Grid item flex={1}>
            <IconButton size="small">
              <DragIndicatorIcon style={{ fontSize: 18, marginRight: 5 }} />
            </IconButton>
            Relatório (Tickets x Situação)
          </Grid>
          <Grid item flex={1}>
            <Grid item container alignItems="center">
              <Margin top="7" />
              {item.onRefresh &&
                (() => {
                  if (item?.refreshOnLoad) {
                    setTimeout(() => {
                      try {
                        // eslint-disable-next-line no-eval
                        eval("try{" + item.onRefresh + "}catch(err){}");
                      } catch (error) {
                        console.error(error);
                      }
                    }, 1500);
                  }

                  return (
                    <Grid item flex={1}>
                      <LightTooltip title="Atualizar">
                        <CachedOutlinedIcon
                          style={{
                            fontSize: 18,
                            marginRight: 10,
                            cursor: "pointer",
                            color: "#636363",
                          }}
                          onClick={() => {
                            // Muito cuidado, o eval é perigoso - https://developer.mozilla.org/pt-BR/docs/Web/JavaScript/Reference/Global_Objects/eval
                            try {
                              // eslint-disable-next-line no-eval
                              eval("try{" + item.onRefresh + "}catch(err){}");
                            } catch (error) {
                              console.error(error);
                            }
                          }}
                        />
                      </LightTooltip>
                    </Grid>
                  );
                })()}
              <Grid item flex={1}>
                <LightTooltip title="Remover">
                  <CloseIcon
                    style={{
                      fontSize: 18,
                      cursor: "pointer",
                      color: "#636363",
                      marginRight: 10,
                    }}
                    onClick={() => handleDeleteWidgets(item)}
                  />
                </LightTooltip>
              </Grid>
            </Grid>
          </Grid>
          <DividerStyled style={{ width: "100%" }} />
        </Grid>
      </ItemGridDragDropTitleStyle>

      <div
        style={{
          height: `${"calc(100% - 65px)"}`,
          width: "100%",
        }}
      >
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link
          rel="preconnect"
          href="https://fonts.gstatic.com"
          crossOrigin="true"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Ubuntu&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/icon?family=Material+Icons+Round"
          rel="stylesheet"
        />

        <style>
          {`@keyframes GRSQSspinner-grow {
          0% { transform: scale(0); }
          50% { opacity: 1; transform: none; }
        }`}
        </style>

        <div
          id="GRSQS_widget"
          style={{
            height: "calc(100% - 58.33px)",
            fontFamily: "'Ubuntu', sans-serif",
            padding: "0 10px 10px 10px",
            position: "relative",
          }}
        >
          <div
            id="GRSQSloaderAnimation"
            style={{
              color: "#0d6efd",
              display: "block",
              width: "5rem",
              height: "5rem",
              verticalAlign: "-0.125em",
              backgroundColor: "currentColor",
              borderRadius: "50%",
              opacity: 0,
              WebkitAnimation: "0.75s linear infinite GRSQSspinner-grow",
              animation: "0.75s linear infinite GRSQSspinner-grow",
              animationDuration: "1.5s",
              position: "absolute",
              top: "calc(50% - 2.5rem)",
              left: "calc(50% - 2.5rem)",
            }}
          ></div>

          <div
            id="GRSQSfetchError"
            style={{
              position: "absolute",
              width: "calc(100% - 20px)",
              height: "100%",
              display: "none",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <span
              className="material-icons-round"
              style={{ fontSize: "3em", margin: "8px" }}
            >
              error_outline
            </span>
            <p>
              Não foi possível carregar os dados
              <br />
              Tente novamente
            </p>
          </div>

          <div
            id="GRSQSwidget"
            style={{
              width: "100%",
              height: "100%",
            }}
          ></div>
        </div>
      </div>

      {item.stringScripts &&
        item.stringScripts.map((script, index) => {
          return (
            <div key={`script_${index}`}>
              <Helmet>
                <script async>{script}</script>
              </Helmet>
            </div>
          );
        })}
    </>
  );
};
