/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef, useState } from "react";

import { Grid, LinearProgress } from "@material-ui/core";
import GridDragDrop from "../../components/grid-drag-drop/GridDragDrop";
import TabCustom from "../../components/tabs/TabCustom";
import { GlobalContext } from "../../contexts/GlobalContext";
import { Pulse } from "../../styles/GlobalStyle";
import { notify } from "../../utils/notify";
import { usePortalAsClient } from "../../utils/viewPortalAsClientHook";
import ModalNews from "./children/ModalNews";
import VideoFirstAccess from "./children/VideoFirstAccess";
import { DashboardViewContext } from "./contexts/DashboardViewContext";
import useDashboardViewController from "./controllers/DashboardViewController";
import { Subheader } from "./components/SubHeader";
import { AlertBillExpiration } from "../../components/layoutContainer/components/AlertBillExpiration";
import { getWidgets } from "../../repositories/portal_api/Widgets";
import { getHomePageWidgets } from "../../repositories/portal_api/PanelWidgets";
import { getPanelsUser } from "../../repositories/portal_api/Panel";
import { ModalGFTrialFirstAccess } from "./children/ModalGFTrialFirstAccess";
import { getEntitySystems } from "../../repositories/portal_api/Systems";
import useVideoFirstAccessController from "./controllers/VideoFirstAccessController";

const DashboardView = ({ location }) => {
  const containerWidgetsRef = useRef(null);

  const [sizeDragDrop, setSizeDragDrop] = useState(0);
  const { layoutDashboard, setLayoutDashboard } =
    useContext(DashboardViewContext);
  const { sidebar, companyUser, userLogged, animation, reloadWidgets } =
    useContext(GlobalContext);
  const {
    panelSelected,
    setPanelSelected,
    setPanelsList,

    setLoading,
    loading,
  } = useContext(DashboardViewContext);
  const { handleSavePositions } = useDashboardViewController();
  const { isInClientView, clientViewed } = usePortalAsClient();
  const [openModalGfFirstAccess, setOpenModalGfFirstAccess] = useState(false);
  const { handleSubmitVideo } = useVideoFirstAccessController();

  const [hasFiscalAutomation, setHasFiscalAutomation] = useState(false);

  useEffect(() => {
    if (reloadWidgets === null) {
      return;
    }

    loadWidgets();
  }, [reloadWidgets]);

  useEffect(() => {
    if (location?.state?.restrictPageRedirect)
      notify("Você foi redirecionado! Página com acesso restrito");
  }, [location]);

  useEffect(() => {
    if (companyUser?.isGfTrialFirstAccess && userLogged?.showVideoIntro) {
      getEntitySystems().then((response) => {
        let hasGfTrial = false;

        if (response?.success) {
          if (response?.data) {
            response.data.forEach((system) => {
              if (system.system_id === 1 && system.trial) {
                hasGfTrial = true;
              }
            });
          }
        }
        if (hasGfTrial) {
          setOpenModalGfFirstAccess(true);
        }
      });
    }
  }, [companyUser?.isGfTrialFirstAccess]);

  const [modalNewsOpen, setModalNewsOpen] = useState(false);

  useEffect(() => {
    setPanelSelected(null);
  }, [setPanelSelected]);

  useEffect(() => {
    (async () => {
      try {
        setLoading((current) => ({ ...current, panelsList: true }));
        const panelsResp = await getPanelsUser({
          id: isInClientView ? clientViewed?.id : userLogged?.userId,
          ownerentity: isInClientView && clientViewed?.company,
        });

        if (panelsResp?.success && Array.isArray(panelsResp?.data)) {
          setPanelsList(panelsResp?.data);
          setPanelSelected(
            panelsResp?.data.find((p) => p.favorited) ||
              panelsResp?.data[0] ||
              null
          );
          return;
        }

        setPanelSelected(null);
        setPanelsList([]);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading((current) => ({ ...current, panelsList: false }));
      }
    })();
  }, [
    userLogged?.userId,
    setLoading,
    setPanelSelected,
    setPanelsList,
    isInClientView,
    clientViewed,
  ]);

  useEffect(() => {
    if (!panelSelected?.id) {
      setLayoutDashboard([]);
      return;
    }

    loadWidgets();
  }, [panelSelected, setLoading, isInClientView, setLayoutDashboard]);

  function debounce(fn, ms) {
    let timer;
    return (_) => {
      clearTimeout(timer);
      timer = setTimeout((_) => {
        timer = null;
        fn.apply(this, arguments);
      }, ms);
    };
  }

  const loadWidgets = async () => {
    try {
      setLoading((current) => ({ ...current, widgets: true }));

      const respWidgets = await getHomePageWidgets(panelSelected?.id);

      let widgetsDisplay = [];
      const itemsNotDuplicated = [];

      for (let item of respWidgets.data) {
        if (!item.widget_version) {
          continue;
        }
        let itemId = item.widget_version.id;

        if (itemsNotDuplicated.includes(itemId)) {
          continue;
        }

        widgetsDisplay.push({
          id: item.id,
          i: item.widget_version.id.toString(),
          x: item.postion_x,
          y: item.position_y,
          w: item.width || item.widget_version.minimum_width || 1,
          h: item.height || item.widget_version.minimum_height || 1,
          minW: item.widget_version.minimum_width || 1,
          minH: item.widget_version.minimum_height || 1,
          maxW: item.widget_version.maximum_width || 12,
          maxH: item.widget_version.maximum_height || 12,
          titulo: item.widget_version.widget.title,
          hideHeader: item.widget_version.hide_header,
          stringChildren: item.widget_version.widget_body,
          importScripts: (item.widget_version?.external_scripts || "").split(
            ","
          ),
          stringScripts: [item.widget_version.internal_scripts],
          onRefresh: item.widget_version.refresh_function,
          refreshInterval: item.widget_version.refresh_interval
            ? parseInt(item.widget_version.refresh_interval)
            : 0,
          refreshOnLoad: true,
          visualizar: item.widget_version.active,
        });
        itemsNotDuplicated.push(item.widget_version.id);
      }

      if (widgetsDisplay.length === 0) {
        let response = await getWidgets(
          userLogged.userId,
          companyUser.companyId
        );

        if (response.code === 200) {
          let widgetIds = [];
          let widgetPosition = [];

          //? INFO: TRATATIVA WIDGETS AUTOMAÇÃO FISCAL
          if (hasFiscalAutomation) {
            widgetIds = [181, 182, 183, 184, 185];

            widgetPosition = [
              {
                id: 181,
                x: 0,
                y: 0,
                w: 12,
                h: 6,
                minW: 3,
                minH: 4,
                maxW: 12,
                maxH: 12,
              },
              {
                id: 182,
                x: 0,
                y: 6,
                w: 3,
                h: 11,
                minW: 0,
                minH: 0,
                maxW: 0,
                maxH: 0,
              },
              {
                id: 183,
                x: 3,
                y: 6,
                w: 3,
                h: 11,
                minW: 0,
                minH: 0,
                maxW: 0,
                maxH: 0,
              },
              {
                id: 184,
                x: 6,
                y: 6,
                w: 3,
                h: 11,
                minW: 0,
                minH: 0,
                maxW: 0,
                maxH: 0,
              },
              {
                id: 185,
                x: 9,
                y: 6,
                w: 3,
                h: 11,
                minW: 0,
                minH: 0,
                maxW: 0,
                maxH: 0,
              },
            ];
          } else {
            widgetIds = [181, 153, 155, 156, 187];
            widgetPosition = [
              {
                id: 153,
                x: 0,
                y: 13,
                w: 12,
                h: 4,
                minW: 12,
                minH: 4,
                maxW: 12,
                maxH: 4,
              },
              {
                id: 155,
                x: 3,
                y: 6,
                w: 4,
                h: 7,
                minW: 4,
                minH: 6,
                maxW: 12,
                maxH: 7,
              },
              {
                id: 156,
                x: 0,
                y: 6,
                w: 3,
                h: 7,
                minW: 3,
                minH: 6,
                maxW: 12,
                maxH: 12,
              },
              {
                id: 181,
                x: 0,
                y: 0,
                w: 12,
                h: 6,
                minW: 3,
                minH: 4,
                maxW: 12,
                maxH: 12,
              },
              {
                id: 187,
                x: 7,
                y: 6,
                w: 5,
                h: 7,
                minW: 1,
                minH: 1,
                maxW: 12,
                maxH: 12,
              },
            ];
          }

          response.data.forEach((item) => {
            if (
              widgetIds.includes(item.id) &&
              item.widget_versions.length !== 0 &&
              item.access_type === "public"
            ) {
              const { id, x, y, w, h, minW, minH, maxW, maxH } =
                widgetPosition.filter((e) => e.id === item.id)[0];
              widgetsDisplay.push({
                id: id,
                i: item.widget_versions[0].id.toString(),
                x: x,
                y: y,
                w: w,
                h: h,
                minW: minW,
                minH: minH,
                maxW: maxW,
                maxH: maxH,
                titulo: item.title,
                hideHeader: item.widget_versions[0].hide_header,
                stringChildren: item.widget_versions[0].widget_body,
                importScripts: (
                  item.widget_versions[0]?.external_scripts || ""
                ).split(","),
                stringScripts: [item.widget_versions[0].internal_scripts],
                onRefresh: item.widget_versions[0].refresh_function,
                refreshInterval: item.widget_versions[0].refresh_interval
                  ? parseInt(item.widget_versions[0].refresh_interval)
                  : 0,
                refreshOnLoad: true,
                visualizar: item.widget_versions[0].active,
              });

              itemsNotDuplicated.push(item.widget_versions[0].id);
            }
          });
        }
        setLayoutDashboard(widgetsDisplay);
        localStorage.setItem(
          `widgesPositionsPanel_${panelSelected?.id}`,
          widgetsDisplay
        );
      } else {
        setLayoutDashboard(widgetsDisplay);
        localStorage.setItem(
          `widgesPositionsPanel_${panelSelected?.id}`,
          widgetsDisplay
        );
      }
    } catch (error) {
      console.log(error);

      notify("Não foi possível obter os dados.");
    } finally {
      setLoading((current) => ({ ...current, widgets: false }));
    }
  };

  // useEffect que roda ao renderizar, para setar o width inicial do container
  useEffect(() => {
    setSizeDragDrop(containerWidgetsRef?.current?.offsetWidth - 50 || 0);

    getEntitySystems().then((response) => {
      if (response?.success) {
        if (response?.data) {
          response.data.forEach((system) => {
            if (system.system_id === 6 && system.system_version_id === 15) {
              setHasFiscalAutomation(true);
            }
          });
        }
      }
    });
  }, []);

  // useEffect que roda sempre que a tela sofrer um "resize" para setar
  // a largura do container.
  useEffect(() => {
    const debouncedHandleContainer = debounce(function handleResize() {
      setSizeDragDrop(containerWidgetsRef?.current?.offsetWidth - 50 || 0);
    }, 200);

    window.addEventListener("resize", debouncedHandleContainer);

    return () => window.removeEventListener("resize", debouncedHandleContainer);
  }, [
    setSizeDragDrop,
    sidebar.open,
    containerWidgetsRef?.current?.offsetWidth,
  ]);

  const onLayoutChange = (layouts) => {
    try {
      localStorage.setItem(
        `widgesPositionsPanel_${panelSelected?.id}`,
        JSON.stringify(layouts)
      );
    } catch (error) {
      console.error(error);
    }
  };

  const children = [
    loading.panelsList || loading.widgets ? (
      <LinearProgress key={0} />
    ) : (
      <>
        {
          <GridDragDrop
            key={1}
            width={sizeDragDrop}
            onLayoutChange={(layout) => {
              onLayoutChange(layout, 0);
              handleSavePositions();
            }}
            layout={layoutDashboard}
          />
        }
      </>
    ),
  ];

  return (
    <div
      style={{
        paddingLeft: 30,
        paddingTop: 20,
        paddingRight: 30,
        paddingBottom: 20,
      }}
    >
      <Subheader />
      <div>
        {!!(
          userLogged.showVideoIntro === "true" &&
          companyUser.isAccountant === "true"
        ) && <VideoFirstAccess />}

        <ModalGFTrialFirstAccess
          open={openModalGfFirstAccess}
          handleClose={() => {
            handleSubmitVideo();
            setOpenModalGfFirstAccess(false);
          }}
        />

        {modalNewsOpen && (
          <ModalNews open={modalNewsOpen} setOpen={setModalNewsOpen} />
        )}

        <Grid container>
          <Grid item xs={12}>
            <AlertBillExpiration />

            <Grid
              item
              container
              justifyContent="center"
              style={{ width: "100%" }}
              ref={containerWidgetsRef}
            >
              <Pulse active={animation === 2} style={{ width: "100%" }}>
                <TabCustom children={children} tabIndex={0} />
              </Pulse>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default DashboardView;
