import React, { useContext } from "react";
import { ButtonTogleStyle } from "../../../components/modal/Modal";
import { TemplateTypeChips } from "../styles/GuideReport";
import { Grid } from "@material-ui/core";
import { DocumentGuideContext } from "../contexts/DocumentGuideContext";

const DocumentFolderTabsCustomerManagement = ({
  folders,
  handleTabChange,
  handleChipChange,
  ...props
}) => {
  const { tabValue, chipValue } = useContext(DocumentGuideContext);

  return (
    <>
      <Grid
        container
        justifyContent="flex-start"
        xs={12}
        style={{
          marginBottom: 16,
          marginTop: 0,
        }}
        spacing={1}
      >
        {folders.current.map((item) => {
          if (item.parent_id === null) {
            return (
              <Grid item>
                <TemplateTypeChips
                  style={{ marginTop: 0 }}
                  key={item.folder_id}
                  active={tabValue === item.folder_id}
                  onClick={() => {
                    handleTabChange(item.folder_id);
                  }}
                >
                  {item.name}
                </TemplateTypeChips>
              </Grid>
            );
          } else {
            return null;
          }
        })}
      </Grid>

      <Grid
        container
        xs={12}
        style={{ marginTop: 16, marginBottom: 8 }}
        spacing={2}
      >
        <>
          {folders.current.map((item) => {
            if (item.parent_id === tabValue) {
              return (
                <ButtonTogleStyle
                  key={item.folder_id}
                  onClick={() => {
                    handleChipChange(item.folder_id);
                  }}
                  active={chipValue === item.folder_id}
                >
                  {item.name}
                </ButtonTogleStyle>
              );
            } else {
              return null;
            }
          })}
        </>
      </Grid>
    </>
  );
};

export { DocumentFolderTabsCustomerManagement };
