import React, { useContext, useEffect, useState } from "react";
import {
  ButtonCancel,
  ButtonSave,
  CloseIconStyle,
  DialogHeader,
  DialogStyle,
} from "../../../components/modal/Modal";
import {
  CircularProgress,
  DialogActions,
  DialogContent,
  Grid,
  Modal,
} from "@material-ui/core";
import SubdirectoryArrowLeftIcon from "@material-ui/icons/SubdirectoryArrowLeft";
import CloudDoneOutlinedIcon from "@material-ui/icons/CloudDoneOutlined";
import { RecursivePermissionList } from "./RecursivePermissionList";
import {
  postPermissionGroup,
  putPermissionGroup,
} from "../../../repositories/panel/v1/EntityRepository";
import { GlobalContext } from "../../../contexts/GlobalContext";
import { useManagementController } from "../../accessProfile/controllers/ManagementController";
import { notify } from "../../../utils/notify";
import { ManagementContext } from "../../accessProfile/contexts/ManagementContext";
import { PermissionNameDescription } from "./PermissionNameDescription";
import { CustumerManagementContext } from "../../customerManagements/contexts/CustomerManagementContext";

export const ModalAddPermissionGroup = ({
  open,
  handleClose,
  permissionsTree,
  refreshPage,
  permissionToEdit,
  location,
}) => {
  const [loading, setLoading] = useState(false);

  const { userLogged } = useContext(GlobalContext);
  const { setPermissionsList, permissionName, permissionDescription } =
    useContext(ManagementContext);

  const { filterPermissionList } = useManagementController();
  const { customerId } = useContext(CustumerManagementContext);
  const [permissionNameError, setPermissionNameError] = useState(false);

  useEffect(() => {
    if (permissionToEdit?.id) {
      setPermissionsList(permissionToEdit?.permissions);
    } else if (permissionToEdit?.permissionList) {
      setPermissionsList(permissionToEdit?.permissionList);
    } else {
      setPermissionsList([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissionToEdit]);

  const handleSavePermissionGroup = async () => {
    setLoading(true);
    let response = {};

    if (permissionToEdit?.id) {
      response = await putPermissionGroup({
        userId: userLogged.userId,
        groupId: permissionToEdit?.id,
        body: {
          permissions: {
            method: "sync",
            permissions: filterPermissionList(),
          },
          name: permissionName,
          description: permissionDescription,
        },
      });
    } else {
      const postObject = {
        userId: userLogged.userId,
        body: {
          name: permissionName,
          description: permissionDescription,
          permissions: filterPermissionList(),
        },
      };

      if (location.pathname === "/customermanagement/edit") {
        postObject.body.entity_id = customerId;
      }

      response = await postPermissionGroup(postObject);
    }

    if (response.success) {
      notify(
        `Grupo de Permissão ${
          permissionToEdit?.id ? "Editado" : "Adicionado"
        } com Sucesso!`,
        true,
        "success"
      );
      handleClose();
      refreshPage();
    } else {
      notify(
        `Erro ao ${
          permissionToEdit?.id ? "Editar" : "Adicionar"
        } o Grupo de Permissão!`,
        true,
        "success"
      );
    }
    setLoading(false);
  };

  const validateFields = () => {
    if (!permissionName) {
      notify("O Nome da permissão é obrigatório");
      setPermissionNameError(true);
      return false;
    }
    return true;
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogStyle style={{ width: "932px" }}>
        {loading ? (
          <div
            style={{
              width: "100%",
              height: "100px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <>
            <DialogHeader id="alert-dialog-title">
              <div className="row">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: 10,
                  }}
                >
                  {permissionToEdit?.id ? "Editar" : "Incluir"} Grupo de
                  Permissão
                </div>
                <CloseIconStyle
                  style={{ marginRight: "6px" }}
                  onClick={() => {
                    handleClose();
                  }}
                />
              </div>
            </DialogHeader>
            <DialogContent style={{ maxHeight: "70vh" }}>
              <Grid
                container
                direction="row"
                spacing={2}
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "16px",
                  gap: "18px",
                }}
              >
                <PermissionNameDescription
                  permissionToEdit={permissionToEdit}
                  permissionNameError={permissionNameError}
                  setPermissionNameError={setPermissionNameError}
                />

                <RecursivePermissionList permissionsTree={permissionsTree} />
              </Grid>
            </DialogContent>
            <DialogActions style={{ paddingTop: 24, paddingBottom: 24 }}>
              <ButtonCancel
                style={{
                  marginRight: 24,
                  float: "right",
                }}
                onClick={handleClose}
                startIcon={<SubdirectoryArrowLeftIcon />}
              >
                {"Cancelar"}
              </ButtonCancel>
              <ButtonSave
                style={{
                  float: "right",
                  right: 14,
                }}
                onClick={() => {
                  if (validateFields()) {
                    handleSavePermissionGroup();
                  }
                }}
              >
                <CloudDoneOutlinedIcon style={{ marginRight: "8px" }} />
                {"Salvar"}
              </ButtonSave>
            </DialogActions>
          </>
        )}
      </DialogStyle>
    </Modal>
  );
};
