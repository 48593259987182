import React from "react";
import { Helmet } from "react-helmet";
import { ItemGridDragDropTitleStyle } from "../../components/grid-drag-drop/ItemGridDragDropTitleStyle";
import { Grid, IconButton } from "@material-ui/core";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import { LightTooltip, Margin } from "../../styles/GlobalStyle";
import CachedOutlinedIcon from "@material-ui/icons/CachedOutlined";
import CloseIcon from "@material-ui/icons/Close";
import { DividerStyled } from "../../components/chat/styles/GeneralStyle";
import useDashboardViewController from "../../pages/dashboard/controllers/DashboardViewController";

export const WidgetVersion99 = () => {
  const { handleDeleteWidgets } = useDashboardViewController();

  const item = {
    i: "99",
    importScripts: [""],
    stringScripts: [
      `try {
        const GECQ_ENVIRONMENT = localStorage.getItem("environment") ?? "test";
        const GECQ_ERP_API_URL = GECQ_ENVIRONMENT === "prod" ? "https://qerpapi.qyon.com"
            : GECQ_ENVIRONMENT === "beta" ? "https://beta-qerpapi.qyon.com"
            : GECQ_ENVIRONMENT === "dev" ? "http://localhost:85"
            : "https://test-qerpapi.qyon.com";
    
        const GECQ_ERP_API_TOKEN = GECQ_ENVIRONMENT === "prod" || GECQ_ENVIRONMENT === "beta"
            ? "eVmDI3J6dCqvxDUyZv16Sr1KBDW0TTNKvZA4LCkY"
            : "eyJ0ZXN0ZSI6InJhZmFvbGluZG9zejEyMyJ9";
    
        const GECQ_documentPanel = localStorage.getItem("documentPanel");
    
        const GECQ_downloadBoleto = async (e) => {
          document.getElementById("GECQ_loaderAnimation").style.display = "block";
          document.getElementById("GECQ_fetchError").style.display = "none";
          document.getElementById("GECQ_container_accordion").style.display = "none";
    
          const t = await fetch(\`\${GECQ_ERP_API_URL}/api/erp/financeiro/contasreceber/v1/downloadboleto/\${e}?empresa_cnpj=38469354000152\`, {
            method: "GET",
            headers: new Headers({ Authorization: GECQ_ERP_API_TOKEN })
          })
          .then((e) => {
            if (e.ok) return e.json();
            throw e.statusText;
          })
          .catch((e) => {
            console.error("Widget GECQ, GECQ_downloadNfse error:", e);
            if (document.getElementById("GECQ_fetchError")) {
              document.getElementById("GECQ_fetchError").style.display = "flex";
              document.getElementById("GECQ_loaderAnimation").style.display = "none";
            }
          })
          .finally(() => {
            if (document.getElementById("GECQ_container_accordion")) {
              document.getElementById("GECQ_loaderAnimation").style.display = "none";
              document.getElementById("GECQ_fetchError").style.display = "none";
              document.getElementById("GECQ_container_accordion").style.display = "block";
            }
          });
    
          if (!document.getElementById("GECQ_container_accordion")) return;
          if (!t.success) return;
    
          const n = t.data.archive_base64;
          const o = document.createElement("a");
          o.setAttribute("href", "data:application/octet-stream;base64," + encodeURIComponent(n.substring(n.split("").findIndex((e) => "," === e) + 1)));
          o.setAttribute("download", \`Boleto-\${e}.pdf\`);
          o.style.display = "none";
          document.body.appendChild(o);
          o.click();
          document.body.removeChild(o);
        };
    
        const GECQ_downloadNfse = async (e, t) => {
          document.getElementById("GECQ_loaderAnimation").style.display = "block";
          document.getElementById("GECQ_fetchError").style.display = "none";
          document.getElementById("GECQ_container_accordion").style.display = "none";
    
          const n = await fetch(\`\${GECQ_ERP_API_URL}/api/erp/nf/notasfiscaisarchives/v1/download/\${t}?empresa_cnpj=38469354000152\`, {
            method: "GET",
            headers: new Headers({ Authorization: GECQ_ERP_API_TOKEN })
          })
          .then((e) => {
            if (e.ok) return e.json();
            throw e.statusText;
          })
          .catch((e) => {
            console.error("Widget GECQ, GECQ_downloadNfse error:", e);
            if (document.getElementById("GECQ_fetchError")) {
              document.getElementById("GECQ_fetchError").style.display = "flex";
              document.getElementById("GECQ_loaderAnimation").style.display = "none";
            }
          })
          .finally(() => {
            if (document.getElementById("GECQ_container_accordion")) {
              document.getElementById("GECQ_loaderAnimation").style.display = "none";
              document.getElementById("GECQ_fetchError").style.display = "none";
              document.getElementById("GECQ_container_accordion").style.display = "block";
            }
          });
    
          if (!document.getElementById("GECQ_container_accordion")) return;
          if (!n.success) return;
    
          const o = n.data.archive_base64;
          const d = document.createElement("a");
          d.setAttribute("href", "data:application/octet-stream;base64," + encodeURIComponent(o.substring(o.split("").findIndex((e) => "," === e) + 1)));
          d.setAttribute("download", \`NFSe-\${e}.pdf\`);
          d.style.display = "none";
          document.body.appendChild(d);
          d.click();
          document.body.removeChild(d);
        };
      } catch (err) {}`,
    ],
    onRefresh: `
    try {
      window.GECQ_downloadBoleto = async function(e) {
        window.open("https://gf1-client-area.qyon.com?tokenCognito=" + localStorage.getItem("tokenCognitoPanel") + "&document=" + e, "_blank");
      };
    
      window.GECQ_downloadNfse = async function(e, t) {
        document.getElementById("GECQ_loaderAnimation").style.display = "block";
        document.getElementById("GECQ_fetchError").style.display = "none";
        document.getElementById("GECQ_container_accordion").style.display = "none";
    
        const a = await fetch(\`\${n}/api/erp/nf/notasfiscaisarchives/v1/download/\${t}?empresa_cnpj=38469354000152\`, {
          method: "GET",
          headers: new Headers({ Authorization: o })
        })
        .then((e) => {
          if (e.ok) return e.json();
          throw e.statusText;
        })
        .catch((e) => {
          console.error("Widget GECQ, GECQ_downloadNfse error:", e);
          if (document.getElementById("GECQ_fetchError")) {
            document.getElementById("GECQ_fetchError").style.display = "flex";
            document.getElementById("GECQ_loaderAnimation").style.display = "none";
          }
        })
        .finally(() => {
          if (document.getElementById("GECQ_container_accordion")) {
            document.getElementById("GECQ_loaderAnimation").style.display = "none";
            document.getElementById("GECQ_fetchError").style.display = "none";
            document.getElementById("GECQ_container_accordion").style.display = "block";
          }
        });
    
        if (!document.getElementById("GECQ_container_accordion")) return;
        if (!a.success) return;
    
        const d = a.data.archive_base64;
        const i = document.createElement("a");
        i.setAttribute("href", "data:application/octet-stream;base64," + encodeURIComponent(d.substring(d.split("").findIndex((e) => "," === e) + 1)));
        i.setAttribute("download", "NFSe-" + e + ".pdf");
        i.style.display = "none";
        document.body.appendChild(i);
        i.click();
        document.body.removeChild(i);
      };
    
      const e = '<div style="overflow-y: auto; max-height: 100%; margin-bottom: 20px"> <button class="GECQ_accordion" style=" width: 100%; display: flex; justify-content: space-between; align-items: center; padding: 5px; background-color: #0365a6; " > <div style="display: flex; flex-wrap: wrap; color: white"> <span style="width: 100%; font-size:10px;font-weight: 700;">Número do Contrato</span> <span style="width: 100%; font-size:14px;font-weight: 700;">{{CONTRACT_NUMBER}}</span> </div><div style="background-color: white; padding: 5px; width: 80px"> Vig&nbsp{{CONTRACT_EXPIRED}}</div></button> <fragment class="GECQ_panel"> <div style=" display: flex; justify-content: space-between; align-items: center; flex-wrap: wrap; background: #f3f3f3; padding: 8px; border: solid 1px #dedede; " id="GECQ_container_itens_{{CONTRACT_ID}}" > </div></fragment> </div>';
      
      const t = localStorage.getItem("environment") ?? "test";
      const n = t === "prod" ? "https://qerpapi.qyon.com"
          : t === "beta" ? "https://beta-qerpapi.qyon.com"
          : t === "dev" ? "http://localhost:85"
          : "https://test-qerpapi.qyon.com";
    
      const o = t === "prod" || t === "beta"
          ? "eVmDI3J6dCqvxDUyZv16Sr1KBDW0TTNKvZA4LCkY"
          : "eyJ0ZXN0ZSI6InJhZmFvbGluZG9zejEyMyJ9";
    
      const a = localStorage.getItem("documentPanel");
    
      const d = async () => await fetch(\`\${n}/api/erp/faturamento/contratos/v1/contasreceber-abertos?empresa_cnpj=38469354000152&cliente_cpfcnpj=\${a}\`, {
        method: "GET",
        headers: new Headers({ Authorization: o })
      })
      .then((e) => {
        if (e.ok) return e.json();
        throw e.statusText;
      })
      .catch((e) => {
        console.error("Widget GECQ, fetch error:", e);
        if (document.getElementById("GECQ_fetchError")) {
          document.getElementById("GECQ_fetchError").style.display = "flex";
          document.getElementById("GECQ_loaderAnimation").style.display = "none";
        }
      });
    
      function GECQ_numberUStoBR(e) {
        return e.toLocaleString("pt-br", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
      }
    
      const i = async () => {
        try {
          const t = await d();
          if (t.success && document.getElementById("GECQ_container_accordion")) {
            const n = ["JAN", "FEV", "MAR", "ABR", "MAI", "JUN", "JUL", "AGO", "SET", "OUT", "NOV", "DEC"];
            const o = { "00": "#d8d8d8", 10: "#d6eeff", 71: "#c4c5f6", expired: "#f6c4c4" };
    
            t.data.forEach((t, a) => {
              const d = document.createElement("div");
              d.setAttribute("id", \`GECQ_content_item_\${t.id}\`);
              document.getElementById("GECQ_container_accordion").appendChild(d);
              document.getElementById(\`GECQ_content_item_\${t.id}\`).innerHTML = e.replaceAll("{{CONTRACT_ID}}", t.id)
                .replaceAll("{{CONTRACT_NUMBER}}", t.numero)
                .replaceAll("{{CONTRACT_EXPIRED}}", t.vigencia_renovacao);
    
              t.contas_receber.sort((e, t) => Date.parse(e.datavencto) - Date.parse(t.datavencto)).forEach((e, a) => {
                const d = e.datavencto.split("-");
                const i = Date.parse(e.datavencto) < Date.parse(new Date());
                const l = document.createElement("div");
                l.setAttribute("id", \`GECQ_line_\${e.id}\`);
                l.setAttribute("style", "width: 100%; margin-top: 12px; padding: 0 7px 0 0");
                document.getElementById(\`GECQ_container_itens_\${t.id}\`).appendChild(l);
                document.getElementById(\`GECQ_line_\${e.id}\`).innerHTML = \`
                  <div style=" width: 100%; display: flex; justify-content: space-between; align-items: center; padding: 5px; border-radius: 5px; background-color: \${o[i ? "expired" : e.situacaodocto]}; " >
                    <label>\${e.numerodocto}</label>
                    <div style="display: flex; flex-wrap: wrap; align-items: center">
                      <label style="width: 100%">\${d[2]}&nbsp\${n[parseInt(d[1]) - 1]}</label>
                      <label>\${d[0]}</label>
                    </div>
                    \${e.keyboletoqyon || e.keyboletopix || e.keyboleto || e.keyboletohibrido ? \`<button style=" border-radius: 5px; background-color: #068ae1; color: white; padding: 8px; border: none; cursor: pointer; " onClick="GECQ_downloadBoleto(\${e.id})" > <label style="font-size: 9px;font-weight: 700;cursor: pointer;"> 2ª VIA BOLETO </label> </button>\` : ""}
                    \${e?.nfse?.nfse_archive?.length ? \`<button style=" border-radius: 5px; background-color: #068ae1; color: white; padding: 8px; border: none; cursor: pointer; " onClick="GECQ_downloadNfse(\${e?.nfse.id}, \${e?.nfse.nfse_archive[0].id})" > <label style="font-size: 9px;font-weight: 700;cursor: pointer;"> NFS-e </label> </button>\` : ""}
                    <label style="font-weight: 700;">R$ \${GECQ_numberUStoBR(e.valordocto)}</label>
                  </div>\`;
              });
            });
    
            const a = document.getElementsByClassName("GECQ_accordion");
            for (let e = 0; e < a.length; e++) {
              a[e].addEventListener("click", function() {
                this.classList.toggle("GECQ_active");
                const e = this.nextElementSibling;
                e.style.display = e.style.display === "block" ? "none" : "block";
              });
            }
    
            if (!a.length) a[0]?.click();
            document.getElementById("GECQ_container_accordion").style.display = "";
          }
        } catch (e) {
          console.error("Widget GECQ, populate error:", e);
          if (!document.getElementById("GECQ_container_accordion")) return;
          document.getElementById("GECQ_fetchError").style.display = "flex";
          document.getElementById("GECQ_container_accordion").style.display = "none";
        } finally {
          if (document.getElementById("GECQ_loaderAnimation")) {
            document.getElementById("GECQ_loaderAnimation").style.display = "none";
          }
        }
      };
    
      document.getElementById("GECQ_loaderAnimation").style.display = "block";
      document.getElementById("GECQ_fetchError").style.display = "none";
      document.getElementById("GECQ_container_accordion").style.display = "none";
    
      try {
        i();
      } catch (l) {
        console.error("Widget GECQ, error:", l);
        document.getElementById("GECQ_fetchError").style.display = "flex";
        document.getElementById("GECQ_loaderAnimation").style.display = "none";
      }
    } catch (r) {
      console.log(r);
    }
    `,
    refreshOnLoad: true,
  };

  return (
    <>
      {item.importScripts.map((script, index) => {
        return (
          <div key={`import_${index}`}>
            <Helmet>
              <script async src={script}></script>
            </Helmet>
          </div>
        );
      })}

      <ItemGridDragDropTitleStyle customStyle={item.headerStyle ?? {}}>
        <Grid item container justifyContent="space-between" alignItems="center">
          <Grid item flex={1}>
            <IconButton size="small">
              <DragIndicatorIcon style={{ fontSize: 18, marginRight: 5 }} />
            </IconButton>
            Boletos Qyon - 2ª Via
          </Grid>
          <Grid item flex={1}>
            <Grid item container alignItems="center">
              <Margin top="7" />
              {item.onRefresh &&
                (() => {
                  if (item?.refreshOnLoad) {
                    setTimeout(() => {
                      try {
                        // eslint-disable-next-line no-eval
                        eval("try{" + item.onRefresh + "}catch(err){}");
                      } catch (error) {
                        console.error(error);
                      }
                    }, 1500);
                  }

                  return (
                    <Grid item flex={1}>
                      <LightTooltip title="Atualizar">
                        <CachedOutlinedIcon
                          style={{
                            fontSize: 18,
                            marginRight: 10,
                            cursor: "pointer",
                            color: "#636363",
                          }}
                          onClick={() => {
                            // Muito cuidado, o eval é perigoso - https://developer.mozilla.org/pt-BR/docs/Web/JavaScript/Reference/Global_Objects/eval
                            try {
                              // eslint-disable-next-line no-eval
                              eval("try{" + item.onRefresh + "}catch(err){}");
                            } catch (error) {
                              console.error(error);
                            }
                          }}
                        />
                      </LightTooltip>
                    </Grid>
                  );
                })()}
              <Grid item flex={1}>
                <LightTooltip title="Remover">
                  <CloseIcon
                    style={{
                      fontSize: 18,
                      cursor: "pointer",
                      color: "#636363",
                      marginRight: 10,
                    }}
                    onClick={() => handleDeleteWidgets(item)}
                  />
                </LightTooltip>
              </Grid>
            </Grid>
          </Grid>
          <DividerStyled style={{ width: "100%" }} />
        </Grid>
      </ItemGridDragDropTitleStyle>

      <div
        style={{
          height: `${"calc(100% - 65px)"}`,
          width: "100%",
        }}
      >
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link
          rel="preconnect"
          href="https://fonts.gstatic.com"
          crossOrigin="true"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Roboto:wght@100;400&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/icon?family=Material+Icons+Round"
          rel="stylesheet"
        />

        <style>
          {`@keyframes GECQ_spinner-grow {
      0% {
        transform: scale(0);
      }
      50% {
        opacity: 1;
        transform: none;
      }
    }
    ::-webkit-scrollbar {
      width: 8px;
    }
    ::-webkit-scrollbar-track {
      background: #e0e0e0;
      border-radius: 2px;
    }
    ::-webkit-scrollbar-thumb {
      background: #8f8f8f;
      border-radius: 2px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: rgb(88, 88, 88);
    }
    .GECQ_accordion {
      cursor: pointer;
      text-align: left;
      border: none;
      outline: none;
      transition: 0.4s;
    }
    .GECQ_active,
    .GECQ_accordion:hover {
      background-color: #ccc;
    }
    .GECQ_panel {
      background-color: white;
      display: none;
      overflow: hidden;
    }`}
        </style>

        <div
          id="GECQ_widget"
          style={{
            height: "100%",
            fontFamily: "'Roboto', sans-serif",
            position: "relative",
          }}
        >
          {/* Animação de Carregamento */}
          <div
            id="GECQ_loaderAnimation"
            style={{
              color: "#0d6efd",
              display: "none",
              width: "5rem",
              height: "5rem",
              verticalAlign: "-0.125em",
              backgroundColor: "currentColor",
              borderRadius: "50%",
              opacity: 0,
              animation: "1.5s linear infinite GECQ_spinner-grow",
              position: "absolute",
              top: "calc(50% - 2.5rem)",
              left: "calc(50% - 2.5rem)",
            }}
          ></div>

          {/* Erro de Carregamento */}
          <div
            id="GECQ_fetchError"
            style={{
              position: "absolute",
              width: "calc(100% - 20px)",
              height: "100%",
              display: "none",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <span
              className="material-icons-round"
              style={{ fontSize: "3em", margin: "8px" }}
            >
              error_outline
            </span>
            <p>
              Não foi possível carregar os dados
              <br />
              Tente novamente
            </p>
          </div>

          {/* Contêiner Accordion */}
          <div
            id="GECQ_container_accordion"
            style={{
              overflowY: "auto",
              maxHeight: "82%",
            }}
          ></div>

          {/* Legenda */}
          <div
            style={{
              position: "absolute",
              bottom: 0,
              display: "flex",
            }}
          >
            <h4 style={{ marginRight: "10px", color: "#adadad" }}>
              <span>&#8226;</span> Aberto
            </h4>
            <h4 style={{ marginRight: "10px", color: "#73bbed" }}>
              <span>&#8226;</span> Em carteira
            </h4>
            <h4 style={{ marginRight: "10px", color: "#e77d7d" }}>
              <span>&#8226;</span> Atrasado
            </h4>
            <h4 style={{ marginRight: "10px", color: "#9b9de5" }}>
              <span>&#8226;</span> Suspenso
            </h4>
          </div>
        </div>
      </div>

      {item.stringScripts &&
        item.stringScripts.map((script, index) => {
          return (
            <div key={`script_${index}`}>
              <Helmet>
                <script async>{script}</script>
              </Helmet>
            </div>
          );
        })}
    </>
  );
};
