import React, { useEffect, useState } from "react";
import {
  ButtonCancel,
  ButtonSave,
  CloseIconStyle,
  DialogHeader,
  DialogStyle,
} from "../../../components/modal/Modal";
import LogoQyonInteligence from "../../../components/logos/LogoQyonInteligence";
import {
  DialogActions,
  DialogContent,
  Grid,
  MenuItem,
} from "@material-ui/core";
import {
  listCompaniesNotCreatedCertificateSystemActions,
  listCompaniesCreatedCertificateSystemActions,
} from "../../../utils/CertificateSystemActionsList";
import CertificateActionItem from "./CertificateActionItem";
import SubdirectoryArrowLeftIcon from "@material-ui/icons/SubdirectoryArrowLeft";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { ChipStyled } from "./styles/CertificateModal";
import { SelectFilterStyled } from "../../customerManagements/styles/CustomerManagementStyle";

const CertificateSystemActionsBody = ({
  closeModal,
  saveUpdateCertificate,
  disabled,
  listCompaniesCreated,
  listCompaniesNotCreated,
  ...props
}) => {
  const [selectedTab, setSelectedTab] = useState("");
  const [selectedCompaniesCreated, setSelectedCompaniesCreated] = useState([]);
  const [selectedCompaniesNotCreated, setSelectedCompaniesNotCreated] =
    useState([]);

  useEffect(() => {
    if (listCompaniesNotCreated.length > 0) {
      setSelectedTab("NEW_CLIENT");
    } else {
      setSelectedTab("UPDATE_CERTIFICATE");
    }

    setSelectedCompaniesCreated(listCompaniesCreated);
    setSelectedCompaniesNotCreated(listCompaniesNotCreated);
  }, [listCompaniesCreated, listCompaniesNotCreated]);

  return (
    <DialogStyle>
      <DialogHeader style={{ paddingBottom: 0 }}>
        <div className="row">
          <LogoQyonInteligence></LogoQyonInteligence>

          <CloseIconStyle
            onClick={() => {
              closeModal();
            }}
          />
        </div>
      </DialogHeader>

      <DialogContent style={{ maxHeight: 450 }}>
        <Grid container style={{ gap: 5 }}>
          {listCompaniesNotCreated.length > 0 && (
            <ChipStyled
              clickable
              onClick={() => setSelectedTab("NEW_CLIENT")}
              selected={selectedTab === "NEW_CLIENT"}
              label="Novo Cliente"
            ></ChipStyled>
          )}

          {listCompaniesCreated.length > 0 && (
            <ChipStyled
              clickable
              selected={selectedTab === "UPDATE_CERTIFICATE"}
              onClick={() => setSelectedTab("UPDATE_CERTIFICATE")}
              // style={{}}
              label="Atualização de Certificado"
            ></ChipStyled>
          )}
        </Grid>

        {listCompaniesNotCreated.length > 0 && selectedTab === "NEW_CLIENT" && (
          <div style={{ marginBottom: 8, marginTop: 8 }}>
            <SelectFilterStyled
              variant="outlined"
              multiple
              fullWidth
              value={selectedCompaniesNotCreated}
            >
              {listCompaniesNotCreated.map((item) => (
                <MenuItem
                  key={item.clientCnpj}
                  value={item}
                  label={item.companyName}
                >
                  {item.companyName}
                </MenuItem>
              ))}
            </SelectFilterStyled>
          </div>
        )}

        {listCompaniesCreated.length > 0 &&
          selectedTab === "UPDATE_CERTIFICATE" && (
            <div style={{ marginTop: 8, marginBottom: 8 }}>
              <SelectFilterStyled
                variant="outlined"
                multiple
                fullWidth
                value={selectedCompaniesCreated}
              >
                {listCompaniesCreated.map((item) => (
                  <MenuItem
                    key={item.clientCnpj}
                    value={item}
                    label={item.companyName}
                  >
                    {item.companyName}
                  </MenuItem>
                ))}
              </SelectFilterStyled>
            </div>
          )}
        {listCompaniesNotCreated.length > 0 && selectedTab === "NEW_CLIENT" && (
          <Grid container style={{ gap: 5, paddingLeft: 4, paddingRight: 4 }}>
            {listCompaniesNotCreatedCertificateSystemActions.map((item) => {
              return <CertificateActionItem item={item} />;
            })}
          </Grid>
        )}

        {listCompaniesCreated.length > 0 &&
          selectedTab === "UPDATE_CERTIFICATE" && (
            <Grid container style={{ gap: 5, paddingLeft: 4, paddingRight: 4 }}>
              {listCompaniesCreatedCertificateSystemActions.map((item) => {
                return <CertificateActionItem item={item} />;
              })}
            </Grid>
          )}
      </DialogContent>

      <DialogActions>
        <ButtonCancel
          style={{ marginRight: 16 }}
          onClick={() => {
            if (disabled === false) {
              closeModal();
            }
          }}
          disabled={disabled}
          startIcon={<SubdirectoryArrowLeftIcon />}
        >
          Cancelar
        </ButtonCancel>

        <ButtonSave
          background={disabled}
          endIcon={<ArrowForwardIcon />}
          onClick={async () => {
            saveUpdateCertificate();
          }}
        >
          {disabled ? "Carregando..." : "Inserir Certificado"}
        </ButtonSave>
      </DialogActions>
    </DialogStyle>
  );
};

export default CertificateSystemActionsBody;
