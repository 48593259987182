import { v4 as uuidv4 } from "uuid";
import { isCompany } from "../../../../utils/panelType";

const BOT_API = process.env.REACT_APP_BOT_API_URL;

export const UploadZip = async (base64, name) => {
  let accountantCnpj =
    localStorage.getItem("isAccountantPanel") === "true"
      ? localStorage.getItem("documentPanel")
      : localStorage.getItem("accountantCnpjPanel");

  if (isCompany() === true) {
    accountantCnpj = localStorage.getItem("documentPanel");
  }

  let uploadJson = {
    id: uuidv4(),
    version: "v1.0.0",
    from: "QYON_DASH",
    to: "ALL",
    timestamp: new Date().getTime(),
    uiMessage: `Upload de arquivos do eSocial ${name} sendo realizado!`,
    cnpj: localStorage.getItem("documentPanel"),
    parentCnpj: accountantCnpj,
    sub: localStorage.getItem("subCognitoPanel"),
    event: "ESOCIAL_UPLOAD",
    priority: "NOT_CRITICAL",
    base64: base64,
    fileName: name,
    createAccess: isCompany() === true ? false : true,
  };

  let myHeaders = new Headers();
  myHeaders.append("Authorization", "c799c146-6264-4bc6-94d7-56b4cac8deb0");
  myHeaders.append("Content-Type", "application/json");

  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(uploadJson),
    redirect: "follow",
  };

  let response = await new Promise((resolve, reject) => {
    fetch(BOT_API + "/v1/bot/save/zip", requestOptions)
      .then((response) => resolve(response))
      .catch((error) => resolve(null));
  });

  return response;
};
