import { DialogActions, DialogContent, Modal } from "@material-ui/core";
import React from "react";
import {
  DialogHeader,
  DialogStyle,
  CloseIconStyle,
  ButtonCancel,
  ButtonSave,
} from "../../../components/modal/Modal";
import ArchiveIcon from "../../../components/icons/ArchiveIcon";
import SubdirectoryArrowLeftIcon from "@material-ui/icons/SubdirectoryArrowLeft";
import UnarchiveIcon from "../../../components/icons/UnarchiveIcon";

const ModalArchive = ({
  open,
  handleClose,
  handleSave,
  document,
  ...props
}) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <DialogStyle style={{ maxHeight: "650px" }}>
        <DialogHeader>
          <div className="row">
            <div style={{ display: "flex", flexDirection: "column" }}>
              {document?.archived ? "Desarquivar" : "Arquivar"}
            </div>

            <CloseIconStyle
              onClick={() => {
                handleClose();
              }}
            />
          </div>
        </DialogHeader>

        <DialogContent
          style={{
            width: "100%",
            maxHeight: "500px",
            color: "#364461",
          }}
        >
          <div style={{ fontSize: 18, fontWeight: 400 }}>
            Deseja {document?.archived ? "desarquivar" : "arquivar"} o documento
            '{document?.formatedName}'?
          </div>

          {!document?.archived && (
            <div style={{ fontSize: 12, fontWeight: 400 }}>
              *Você ainda poderá visualizá-lo no filtro: Status de Mensagem:
              Arquivado
            </div>
          )}
        </DialogContent>

        <DialogActions>
          <ButtonCancel startIcon={<SubdirectoryArrowLeftIcon />}>
            Cancelar
          </ButtonCancel>
          <ButtonSave
            onClick={async () => {
              await handleSave();
            }}
            startIcon={
              document?.archived ? (
                <UnarchiveIcon fill="#FFFFFF" />
              ) : (
                <ArchiveIcon fill="#FFFFFF" />
              )
            }
          >
            {document?.archived ? "Desarquivar" : "Arquivar"}
          </ButtonSave>
        </DialogActions>
      </DialogStyle>
    </Modal>
  );
};

export default ModalArchive;
