import React from "react";
import { Helmet } from "react-helmet";
import { ItemGridDragDropTitleStyle } from "../../components/grid-drag-drop/ItemGridDragDropTitleStyle";
import { Grid, IconButton } from "@material-ui/core";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import { LightTooltip, Margin } from "../../styles/GlobalStyle";
import CachedOutlinedIcon from "@material-ui/icons/CachedOutlined";
import CloseIcon from "@material-ui/icons/Close";
import { DividerStyled } from "../../components/chat/styles/GeneralStyle";
import useDashboardViewController from "../../pages/dashboard/controllers/DashboardViewController";

export const WidgetVersion54 = () => {
  const { handleDeleteWidgets } = useDashboardViewController();

  const item = {
    i: "54",
    importScripts: [""],
    stringScripts: [""],
    onRefresh: `
try {
    const GEVC_ENVIRONMENT = localStorage.getItem("environment") ?? "test";
    const GEVC_ERP_API_URL = GEVC_ENVIRONMENT === "prod" ? "https://qerpapi.qyon.com" :
        GEVC_ENVIRONMENT === "beta" ? "https://beta-qerpapi.qyon.com" :
        GEVC_ENVIRONMENT === "dev" ? "http://localhost:85" :
        "https://test-qerpapi.qyon.com";

    const GEVC_ERP_API_TOKEN = GEVC_ENVIRONMENT === "prod" ? "eVmDI3J6dCqvxDUyZv16Sr1KBDW0TTNKvZA4LCkY" : "eyJ0ZXN0ZSI6InJhZmFvbGluZG9zejEyMyJ9";

    const GEVC_documentPanel = localStorage.getItem("documentPanel");

    const GEVC_getCertificateValidity = async () => {
        const GEVC_apiCertificateReturn = await fetch(
            \`\${GEVC_ERP_API_URL}/api/erp/prouser/empresas/v2/validadecertificado?\${new URLSearchParams({empresa_cnpj: GEVC_documentPanel})}\`,
            {
                method: "GET",
                headers: new Headers({ Authorization: GEVC_ERP_API_TOKEN })
            }
        )
        .then((res) => {
            if (res.ok) {
                return res.json();
            }
            throw res.statusText;
        })
        .catch((error) => {
            console.error("Widget GE - Validade Certificado, fetch error:", error);
            if (document.getElementById("GEVC_fetchError")) {
                document.getElementById("GEVC_fetchError").style.display = "flex";
                document.getElementById("GEVC_loaderAnimation").style.display = "none";
            }
        });
        return GEVC_apiCertificateReturn?.success ? GEVC_apiCertificateReturn.data : GEVC_apiCertificateReturn?.success === false ? false : null;
    };

    const GEVC_populateCertificateValidity = async () => {
        try {
            let GEVC_certificateValidityData = await GEVC_getCertificateValidity();
            if (document.getElementById("GEVC_content")) {
                let GEVC_certificateData = document.getElementById("GEVC_certificateData");
                let GEVC_certificateNotFound = document.getElementById("GEVC_certificateNotFound");
                let GEVC_hourglassIcon = document.getElementById("GEVC_hourglassIcon");
                if (GEVC_certificateValidityData) {
                    GEVC_certificateData.style.display = "block";
                    GEVC_certificateNotFound.style.display = "none";
                    let GEVC_daysRemaining = document.getElementById("GEVC_daysRemaining");
                    let GEVC_validadeDate = document.getElementById("GEVC_validadeDate");
                    GEVC_validadeDate.innerHTML = GEVC_certificateValidityData.formatedDate;
                    GEVC_daysRemaining.innerHTML = \`Certificado Digital válido por mais <strong>\${GEVC_certificateValidityData.daysRemaining}&nbsp;dias</strong>\`;
                    if (GEVC_certificateValidityData.daysRemaining > 30) {
                        GEVC_hourglassIcon.innerHTML = "hourglass_top";
                        GEVC_hourglassIcon.style.color = "#00B955";
                        GEVC_validadeDate.style.color = "#00B955";
                    } else if (GEVC_certificateValidityData.daysRemaining > 0) {
                        GEVC_hourglassIcon.innerHTML = "hourglass_full";
                        GEVC_hourglassIcon.style.color = "#FFB100";
                        GEVC_validadeDate.style.color = "#FFB100";
                    } else {
                        GEVC_daysRemaining.innerHTML = "Certificado digital Vencido";
                        GEVC_hourglassIcon.innerHTML = "hourglass_bottom";
                        GEVC_hourglassIcon.style.color = "#FA3A3A";
                        GEVC_validadeDate.style.color = "#FA3A3A";
                    }
                    document.getElementById("GEVC_content").style.display = "flex";
                } else if (GEVC_certificateValidityData === false) {
                    GEVC_certificateData.style.display = "none";
                    GEVC_certificateNotFound.style.display = "block";
                    GEVC_hourglassIcon.innerHTML = "report_gmailerrorred";
                    GEVC_hourglassIcon.style.color = "#FA3A3A";
                    document.getElementById("GEVC_content").style.display = "flex";
                }
            }
        } catch (error) {
            console.error("Widget GE - Validade Certificado, populate error:", error);
            document.getElementById("GEVC_fetchError").style.display = "flex";
            document.getElementById("GEVC_content").style.display = "none";
        } finally {
            if (document.getElementById("GEVC_loaderAnimation")) {
                document.getElementById("GEVC_loaderAnimation").style.display = "none";
            }
        }
    };

    document.getElementById("GEVC_loaderAnimation").style.display = "block";
    document.getElementById("GEVC_fetchError").style.display = "none";
    document.getElementById("GEVC_content").style.display = "none";

    try {
        GEVC_populateCertificateValidity();
    } catch (error) {
        console.error("Widget GE - Contas Receber, error:", error);
        document.getElementById("GEVC_fetchError").style.display = "flex";
        document.getElementById("GEVC_loaderAnimation").style.display = "none";
    }
} catch (err) {}
`,
    refreshOnLoad: true,
  };

  return (
    <>
      {item.importScripts.map((script, index) => {
        return (
          <div key={`import_${index}`}>
            <Helmet>
              <script async src={script}></script>
            </Helmet>
          </div>
        );
      })}

      <ItemGridDragDropTitleStyle customStyle={item.headerStyle ?? {}}>
        <Grid item container justifyContent="space-between" alignItems="center">
          <Grid item flex={1}>
            <IconButton size="small">
              <DragIndicatorIcon style={{ fontSize: 18, marginRight: 5 }} />
            </IconButton>
            Certificado Digital
          </Grid>
          <Grid item flex={1}>
            <Grid item container alignItems="center">
              <Margin top="7" />
              {item.onRefresh &&
                (() => {
                  if (item?.refreshOnLoad) {
                    setTimeout(() => {
                      try {
                        // eslint-disable-next-line no-eval
                        eval("try{" + item.onRefresh + "}catch(err){}");
                      } catch (error) {
                        console.error(error);
                      }
                    }, 1500);
                  }

                  return (
                    <Grid item flex={1}>
                      <LightTooltip title="Atualizar">
                        <CachedOutlinedIcon
                          style={{
                            fontSize: 18,
                            marginRight: 10,
                            cursor: "pointer",
                            color: "#636363",
                          }}
                          onClick={() => {
                            // Muito cuidado, o eval é perigoso - https://developer.mozilla.org/pt-BR/docs/Web/JavaScript/Reference/Global_Objects/eval
                            try {
                              // eslint-disable-next-line no-eval
                              eval("try{" + item.onRefresh + "}catch(err){}");
                            } catch (error) {
                              console.error(error);
                            }
                          }}
                        />
                      </LightTooltip>
                    </Grid>
                  );
                })()}
              <Grid item flex={1}>
                <LightTooltip title="Remover">
                  <CloseIcon
                    style={{
                      fontSize: 18,
                      cursor: "pointer",
                      color: "#636363",
                      marginRight: 10,
                    }}
                    onClick={() => handleDeleteWidgets(item)}
                  />
                </LightTooltip>
              </Grid>
            </Grid>
          </Grid>
          <DividerStyled style={{ width: "100%" }} />
        </Grid>
      </ItemGridDragDropTitleStyle>

      <div
        style={{
          height: `${"calc(100% - 65px)"}`,
          width: "100%",
        }}
      >
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link
          rel="preconnect"
          href="https://fonts.gstatic.com"
          crossOrigin="true"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Ubuntu&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/icon?family=Material+Icons+Round"
          rel="stylesheet"
        />
        <style>
          {`
          @keyframes GEVC_spinner-grow {
            0% {
              transform: scale(0);
            }
            50% {
              opacity: 1;
              transform: none;
            }
          }
        `}
        </style>
        <div
          id="GEVC_widget"
          style={{
            height: "100%",
            fontFamily: "'Ubuntu', sans-serif",
            position: "relative",
          }}
        >
          <div
            id="GEVC_loaderAnimation"
            style={{
              color: "#0d6efd !important",
              display: "none",
              width: "5rem",
              height: "5rem",
              verticalAlign: "-0.125em",
              backgroundColor: "currentColor",
              borderRadius: "50%",
              opacity: 0,
              WebkitAnimation: "0.75s linear infinite GEVC_spinner-grow",
              animation: "0.75s linear infinite GEVC_spinner-grow",
              animationDuration: "0.75s",
              position: "absolute",
              top: "calc(50% - 2.5rem)",
              left: "calc(50% - 2.5rem)",
            }}
          ></div>
          <div
            id="GEVC_fetchError"
            style={{
              position: "absolute",
              width: "calc(100% - 20px)",
              height: "100%",
              display: "none",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <span
              className="material-icons-round"
              style={{ fontSize: "3em", margin: "8px" }}
            >
              error_outline
            </span>
            <p>
              Não foi possível carregar os dados
              <br />
              Tente novamente
            </p>
          </div>
          <div
            id="GEVC_content"
            style={{
              display: "flex",
              alignItems: "center",
              height: "100%",
            }}
          >
            <div>
              <span
                id="GEVC_hourglassIcon"
                className="material-icons-round"
                style={{ marginTop: "3px", fontSize: "42px" }}
              >
                hourglass_bottom
              </span>
            </div>
            <div id="GEVC_certificateData">
              <p id="GEVC_daysRemaining">-</p>
              <p>
                Vencimento: <strong id="GEVC_validadeDate"> - </strong>
              </p>
            </div>
            <div id="GEVC_certificateNotFound" style={{ display: "none" }}>
              <p>Certificado Digital não encontrado</p>
            </div>
          </div>
        </div>
      </div>

      {item.stringScripts &&
        item.stringScripts.map((script, index) => {
          return (
            <div key={`script_${index}`}>
              <Helmet>
                <script async>{script}</script>
              </Helmet>
            </div>
          );
        })}
    </>
  );
};
