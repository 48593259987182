import React from "react";
import { Helmet } from "react-helmet";
import { ItemGridDragDropTitleStyle } from "../../components/grid-drag-drop/ItemGridDragDropTitleStyle";
import { Grid, IconButton } from "@material-ui/core";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import { LightTooltip, Margin } from "../../styles/GlobalStyle";
import CachedOutlinedIcon from "@material-ui/icons/CachedOutlined";
import CloseIcon from "@material-ui/icons/Close";
import { DividerStyled } from "../../components/chat/styles/GeneralStyle";
import useDashboardViewController from "../../pages/dashboard/controllers/DashboardViewController";

export const WidgetVersion56 = () => {
  const { handleDeleteWidgets } = useDashboardViewController();

  const item = {
    i: "56",
    importScripts: [""],
    stringScripts: [""],
    onRefresh: `
try {
    const GECND_ENVIRONMENT = localStorage.getItem("environment") ?? "test";
    const GECND_ERP_API_URL = GECND_ENVIRONMENT === "prod" || GECND_ENVIRONMENT === "beta" 
        ? "https://api-cnd.qyon.info" 
        : GECND_ENVIRONMENT === "dev" 
        ? "http://localhost:89" 
        : "https://api-cnd-hml.qyon.info";

    const GECND_IS_ACCOUNTANT = localStorage.getItem("isAccountantPanel") && "true" === localStorage.getItem("isAccountantPanel");
    const GECND_DOCUMENT = localStorage.getItem("documentPanel");
    const GECND_DOCUMENT_ACCOUNTANT = GECND_IS_ACCOUNTANT 
        ? localStorage.getItem("documentPanel") 
        : localStorage.getItem("accountantCnpjPanel");

    const GECND_getCND = async () => {
        const e = await fetch(
            \`\${GECND_ERP_API_URL}/v1/entity/\${GECND_DOCUMENT_ACCOUNTANT}/cnd\${GECND_IS_ACCOUNTANT ? "/my" : "/" + GECND_DOCUMENT + "/my"}\`,
            {
                method: "GET",
                headers: new Headers({
                    Authorization: "Bearer " + localStorage.getItem("tokenPanel"),
                    "Entity-Id": localStorage.getItem("companyIdPanel"),
                    "Content-Type": "application/json",
                    "Content-Language": "pt-BR"
                })
            }
        )
        .then((e) => {
            if (e.ok) return e.json();
            throw e.statusText;
        })
        .catch((e) => {
            console.error("Widget GE - CND, fetch error:", e);
            if (document.getElementById("GECND_fetchError")) {
                document.getElementById("GECND_fetchError").style.display = "flex";
                document.getElementById("GECND_loaderAnimation").style.display = "none";
            }
        });
        return !!e?.success && e.data;
    };

    function GECND_numberUStoBR(e) {
        return e.toLocaleString("pt-br", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    }

    const GECND_populateCND = async () => {
        try {
            let e = await GECND_getCND();
            if (e && document.getElementById("GECND_content")) {
                const t = '<span style="display: inline-block; height: 8px; width: 25px; border-radius: 4px; background-color: #fa3a3a; margin-right: 4px;"></span>';
                let n = 0, a = 0, o = 0, l = 0, i = 0, s = 0;
                e.forEach((e) => {
                    if ("Negativa" === e.status || "Negativa Positiva" === e.status) {
                        if ("Municipal" === e.scope.scope) n++;
                        else if ("Estadual" === e.scope.scope) o++;
                        else if ("Federal" === e.scope.scope) s++;
                    } else if ("Positiva" === e.status) {
                        if ("Municipal" === e.scope.scope) a++;
                        else if ("Estadual" === e.scope.scope) l++;
                        else if ("Federal" === e.scope.scope) i++;
                    }
                });
                let c = {
                    municipal: { positive: a, negative: n },
                    estadual: { positive: l, negative: o },
                    federal: { positive: i, negative: s }
                };
                document.getElementById("GECND_municipalPositive").innerHTML = c.municipal.positive ? \`\${t}<span style="color: #fa3a3a;"> \${c.municipal.positive}</span>\` : " - ";
                document.getElementById("GECND_estadualPositive").innerHTML = c.estadual.positive ? \`\${t}<span style="color: #fa3a3a;"> \${c.estadual.positive}</span>\` : " - ";
                document.getElementById("GECND_federalPositive").innerHTML = c.federal.positive ? \`\${t}<span style="color: #fa3a3a;"> \${c.federal.positive}</span>\` : " - ";
                const r = '<span style="display: inline-block; height: 8px; width: 25px; border-radius: 4px; background-color: #00B955; margin-right: 4px;"></span>';
                document.getElementById("GECND_municipalNegative").innerHTML = c.municipal.negative ? \`\${r}<span style="color: #00B955;"> \${c.municipal.negative}</span>\` : " - ";
                document.getElementById("GECND_estadualNegative").innerHTML = c.estadual.negative ? \`\${r}<span style="color: #00B955;"> \${c.estadual.negative}</span>\` : " - ";
                document.getElementById("GECND_federalNegative").innerHTML = c.federal.negative ? \`\${r}<span style="color: #00B955;"> \${c.federal.negative}</span>\` : " - ";
                document.getElementById("GECND_content").style.display = "table";
            }
        } catch (e) {
            console.error("Widget GE - CND, populate error:", e);
            document.getElementById("GECND_fetchError").style.display = "flex";
            document.getElementById("GECND_content").style.display = "none";
        } finally {
            if (document.getElementById("GECND_loaderAnimation")) {
                document.getElementById("GECND_loaderAnimation").style.display = "none";
            }
        }
    };

    document.getElementById("GECND_loaderAnimation").style.display = "block";
    document.getElementById("GECND_fetchError").style.display = "none";
    document.getElementById("GECND_content").style.display = "none";

    try {
        GECND_populateCND();
    } catch (e) {
        console.error("Widget GE - CND, error:", e);
        document.getElementById("GECND_fetchError").style.display = "flex";
        document.getElementById("GECND_loaderAnimation").style.display = "none";
    }
} catch (err) {}
`,
    refreshOnLoad: true,
  };

  return (
    <>
      {item.importScripts.map((script, index) => {
        return (
          <div key={`import_${index}`}>
            <Helmet>
              <script async src={script}></script>
            </Helmet>
          </div>
        );
      })}

      <ItemGridDragDropTitleStyle customStyle={item.headerStyle ?? {}}>
        <Grid item container justifyContent="space-between" alignItems="center">
          <Grid item flex={1}>
            <IconButton size="small">
              <DragIndicatorIcon style={{ fontSize: 18, marginRight: 5 }} />
            </IconButton>
            CND
          </Grid>
          <Grid item flex={1}>
            <Grid item container alignItems="center">
              <Margin top="7" />
              {item.onRefresh &&
                (() => {
                  if (item?.refreshOnLoad) {
                    setTimeout(() => {
                      try {
                        // eslint-disable-next-line no-eval
                        eval("try{" + item.onRefresh + "}catch(err){}");
                      } catch (error) {
                        console.error(error);
                      }
                    }, 1500);
                  }

                  return (
                    <Grid item flex={1}>
                      <LightTooltip title="Atualizar">
                        <CachedOutlinedIcon
                          style={{
                            fontSize: 18,
                            marginRight: 10,
                            cursor: "pointer",
                            color: "#636363",
                          }}
                          onClick={() => {
                            // Muito cuidado, o eval é perigoso - https://developer.mozilla.org/pt-BR/docs/Web/JavaScript/Reference/Global_Objects/eval
                            try {
                              // eslint-disable-next-line no-eval
                              eval("try{" + item.onRefresh + "}catch(err){}");
                            } catch (error) {
                              console.error(error);
                            }
                          }}
                        />
                      </LightTooltip>
                    </Grid>
                  );
                })()}
              <Grid item flex={1}>
                <LightTooltip title="Remover">
                  <CloseIcon
                    style={{
                      fontSize: 18,
                      cursor: "pointer",
                      color: "#636363",
                      marginRight: 10,
                    }}
                    onClick={() => handleDeleteWidgets(item)}
                  />
                </LightTooltip>
              </Grid>
            </Grid>
          </Grid>
          <DividerStyled style={{ width: "100%" }} />
        </Grid>
      </ItemGridDragDropTitleStyle>

      <div
        style={{
          height: `${"calc(100% - 65px)"}`,
          width: "100%",
        }}
      >
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link
          rel="preconnect"
          href="https://fonts.gstatic.com"
          crossOrigin="true"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Roboto:wght@100;400&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/icon?family=Material+Icons+Round"
          rel="stylesheet"
        />
        <style>
          {`
          @keyframes GECND_spinner-grow {
            0% {
              transform: scale(0);
            }
            50% {
              opacity: 1;
              transform: none;
            }
          }
        `}
        </style>
        <div
          id="GECND_widget"
          style={{
            height: "100%",
            fontFamily: "'Roboto', sans-serif",
            position: "relative",
          }}
        >
          <div
            id="GECND_loaderAnimation"
            style={{
              color: "#0d6efd !important",
              display: "none",
              width: "5rem",
              height: "5rem",
              verticalAlign: "-0.125em",
              backgroundColor: "currentColor",
              borderRadius: "50%",
              opacity: 0,
              WebkitAnimation: "0.75s linear infinite GECND_spinner-grow",
              animation: "0.75s linear infinite GECND_spinner-grow",
              animationDuration: "0.75s",
              position: "absolute",
              top: "calc(50% - 2.5rem)",
              left: "calc(50% - 2.5rem)",
            }}
          ></div>
          <div
            id="GECND_fetchError"
            style={{
              position: "absolute",
              width: "calc(100% - 20px)",
              height: "100%",
              display: "none",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <span
              className="material-icons-round"
              style={{ fontSize: "3em", margin: "8px" }}
            >
              error_outline
            </span>
            <p>
              Não foi possível carregar os dados
              <br />
              Tente novamente
            </p>
          </div>
          <table
            id="GECND_content"
            style={{
              height: "100%",
              borderCollapse: "collapse",
              width: "100%",
            }}
          >
            <thead>
              <tr>
                <th
                  style={{
                    boxSizing: "border-box",
                    borderBottom: "1px solid #1477D8",
                    padding: "0 8px",
                  }}
                ></th>
                <th
                  style={{
                    boxSizing: "border-box",
                    borderBottom: "1px solid #1477D8",
                    padding: "0 8px",
                    textAlign: "center",
                    fontSize: "1.1em",
                    color: "#7b7b7b",
                    fontWeight: 400,
                  }}
                >
                  Positivas
                </th>
                <th
                  style={{
                    boxSizing: "border-box",
                    borderBottom: "1px solid #1477D8",
                    padding: "0 8px",
                    textAlign: "center",
                    fontSize: "1.1em",
                    color: "#7b7b7b",
                    fontWeight: 400,
                  }}
                >
                  Negativas
                </th>
              </tr>
            </thead>
            <tbody style={{ fontSize: "1.2em" }}>
              <tr
                onMouseOver={(e) =>
                  (e.currentTarget.style.backgroundColor =
                    "rgba(0, 0, 0, 0.075)")
                }
                onMouseOut={(e) =>
                  (e.currentTarget.style.backgroundColor = "transparent")
                }
              >
                <th
                  scope="row"
                  style={{
                    padding: "0 8px",
                    boxSizing: "border-box",
                    borderBottom: "1px solid rgba(128, 128, 128, 0.5)",
                    textAlign: "left",
                  }}
                >
                  Municipal
                </th>
                <td
                  id="GECND_municipalPositive"
                  title="Total de CNDs Municipais Positivas"
                  style={{
                    padding: "0 8px",
                    boxSizing: "border-box",
                    borderBottom: "1px solid rgba(128, 128, 128, 0.5)",
                    textAlign: "center",
                    fontSize: "1.2em",
                    fontWeight: "bold",
                  }}
                >
                  -
                </td>
                <td
                  id="GECND_municipalNegative"
                  title="Total de CNDs Municipais Negativas"
                  style={{
                    padding: "0 8px",
                    boxSizing: "border-box",
                    borderBottom: "1px solid rgba(128, 128, 128, 0.5)",
                    textAlign: "center",
                    fontSize: "1.2em",
                    fontWeight: "bold",
                  }}
                >
                  -
                </td>
              </tr>
              <tr
                onMouseOver={(e) =>
                  (e.currentTarget.style.backgroundColor =
                    "rgba(0, 0, 0, 0.075)")
                }
                onMouseOut={(e) =>
                  (e.currentTarget.style.backgroundColor = "transparent")
                }
              >
                <th
                  scope="row"
                  style={{
                    padding: "0 8px",
                    boxSizing: "border-box",
                    borderBottom: "1px solid rgba(128, 128, 128, 0.5)",
                    textAlign: "left",
                  }}
                >
                  Estadual
                </th>
                <td
                  id="GECND_estadualPositive"
                  title="Total de CNDs Estaduais Positivas"
                  style={{
                    padding: "0 8px",
                    boxSizing: "border-box",
                    borderBottom: "1px solid rgba(128, 128, 128, 0.5)",
                    textAlign: "center",
                    fontSize: "1.2em",
                    fontWeight: "bold",
                  }}
                >
                  -
                </td>
                <td
                  id="GECND_estadualNegative"
                  title="Total de CNDs Estaduais Negativas"
                  style={{
                    padding: "0 8px",
                    boxSizing: "border-box",
                    borderBottom: "1px solid rgba(128, 128, 128, 0.5)",
                    textAlign: "center",
                    fontSize: "1.2em",
                    fontWeight: "bold",
                  }}
                >
                  -
                </td>
              </tr>
              <tr
                onMouseOver={(e) =>
                  (e.currentTarget.style.backgroundColor =
                    "rgba(0, 0, 0, 0.075)")
                }
                onMouseOut={(e) =>
                  (e.currentTarget.style.backgroundColor = "transparent")
                }
              >
                <th
                  scope="row"
                  style={{
                    padding: "0 8px",
                    boxSizing: "border-box",
                    borderBottom: "1px solid rgba(128, 128, 128, 0.5)",
                    textAlign: "left",
                  }}
                >
                  Federal
                </th>
                <td
                  id="GECND_federalPositive"
                  title="Total de CNDs Federais Positivas"
                  style={{
                    padding: "0 8px",
                    boxSizing: "border-box",
                    borderBottom: "1px solid rgba(128, 128, 128, 0.5)",
                    textAlign: "center",
                    fontSize: "1.2em",
                    fontWeight: "bold",
                  }}
                >
                  -
                </td>
                <td
                  id="GECND_federalNegative"
                  title="Total de CNDs Federais Negativas"
                  style={{
                    padding: "0 8px",
                    boxSizing: "border-box",
                    borderBottom: "1px solid rgba(128, 128, 128, 0.5)",
                    textAlign: "center",
                    fontSize: "1.2em",
                    fontWeight: "bold",
                  }}
                >
                  -
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      {item.stringScripts &&
        item.stringScripts.map((script, index) => {
          return (
            <div key={`script_${index}`}>
              <Helmet>
                <script async>{script}</script>
              </Helmet>
            </div>
          );
        })}
    </>
  );
};
