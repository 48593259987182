import { search } from "../../../src/repositories/BaseRepositoryComponents";

const baseUrl = process.env.REACT_APP_NEW_PAINEL_API_URL;

export function getUsersByEntityId(entityId) {
  return search({
    path: `${baseUrl}/v1/entity/${entityId}/users`,
    method: "GET",
  });
}

export function saveEntityUsers(accountantClient, users) {
  return search({
    path: `${baseUrl}/v1/entity/${accountantClient}/users`,
    method: "POST",
    myBody: users,
  });
}

export function syncEntityUser(entityId) {
  return search({
    path: `${baseUrl}/v1/user/sync`,
    method: "POST",
    myBody: {
      entity_id: entityId,
    },
  });
}

export function updateUserName(userId, firstName, lastName) {
  return search({
    path: `${baseUrl}/v1/user/${userId}/change-user-name`,
    method: "PUT",
    myBody: {
      first_name: firstName,
      last_name: lastName,
    },
  });
}
