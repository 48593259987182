import { toPng } from "html-to-image";
import { sendTemplateFiles } from "../../../repositories/EmailTemplate";
import { notify } from "../../../utils/notify";

export async function exportEmailTemplateHtml(
  editorHtmlRef,
  id,
  companyDocument,
  nameTemplate,
  tagTemplate,
  type,
  editorType,
  notificationType
) {
  let newDataTemplate = {
    id: id,
    document: companyDocument,
    name: nameTemplate,
    tag: tagTemplate,
    type: type,
    html64Template: "",
    jsonTemplate: "",
    editorType: editorType,
    type_notification: notificationType,
  };

  let htmlString = await new Promise((resolve, reject) => {
    editorHtmlRef.current.editor.exportHtml((data) => {
      resolve(data.html);
    });
  });

  newDataTemplate.html64Template = Buffer.from(htmlString).toString("base64");

  newDataTemplate.pngBase64 = await generateTemplatePreviewImageBase64(
    htmlString
  );

  newDataTemplate.jsonTemplate = await new Promise((resolve, reject) => {
    editorHtmlRef.current.editor.saveDesign((data) => {
      resolve(data);
    });
  });

  let response = await sendTemplateFiles(newDataTemplate);

  if (response?.success) {
    if (editorType === "Edit") {
      notify("Template editado com sucesso!", true, "success");
    } else {
      notify("Template criado com sucesso!", true, "success");
    }
  } else {
    notify("Ocorreu um erro ao salvar o template!");
  }

  return newDataTemplate;
}

async function generateTemplatePreviewImageBase64(htmlString) {
  const divRenderImage = document.createElement("div");

  divRenderImage.innerHTML = htmlString;
  divRenderImage.id = "EMAIL_EDITOR_IMG";
  divRenderImage.style.width = "600px";
  document.body.appendChild(divRenderImage);

  const dataUrl = await toPng(divRenderImage);

  const base64String = dataUrl.split(",")[1];

  document.body.removeChild(divRenderImage);

  return base64String;
}
