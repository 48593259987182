import * as React from "react";

function QyonBankIcon(props) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20.93 2.61H6.151l2.095 2.084h2.55V4.69h8.564v11.06l1.748 1.74.037.036c.19.19.307.45.307.738V3.131a.523.523 0 00-.524-.522zM15.765 19.317l-10.07.003c-.58 0-1.052-.458-1.052-1.022L4.64 8.25 2.905 6.523l.002-.001a1.032 1.032 0 01-.36-.762v12.538c.007.86.362 1.635.93 2.196.57.56 1.352.907 2.217.907H17.86l-2.095-2.084z"
        fill="#0036B3"
      />
      <path
        d="M21.453 18.265v.054a1.047 1.047 0 01-1.791.684L2.905 2.333l.002-.001A1.041 1.041 0 012.854.806 1.054 1.054 0 014.388.86L4.39.857l14.971 14.894 1.748 1.74.037.036c.19.19.307.45.307.738zM21.453 22.455a1.048 1.048 0 01-1.792.738l-.036-.036-1.765-1.756-2.095-2.084L4.64 8.249 2.905 6.523l.002-.001a1.032 1.032 0 01-.36-.762V5.74v-.055a1.039 1.039 0 01.305-.69 1.056 1.056 0 011.534.052l.003-.003 16.72 16.636a1.04 1.04 0 01.344.774z"
        fill="#0036B3"
      />
    </svg>
  );
}

export default QyonBankIcon;
