import {
  Button,
  CircularProgress,
  Grid,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Radio,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import BorderColorOutlinedIcon from "@material-ui/icons/BorderColorOutlined";
import { useContext } from "react";
import { EmailTemplateContext } from "../../contexts/EmailTemplateContext";

const TemplateComponent = ({
  listTemplates,
  isLoadingImage,
  companyUser,
  selectedTemplateId,
  handleChangeTemplate,
  handleOpenEditTemplate,
  handleOpenModalSendTestEmailTemplate,
  handleOpenModalDeleteTemplate,
  ...props
}) => {
  const TEMPLATES_PER_PAGE = 5;

  const [filteredList, setFilteredList] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [templateIdOpenMenu, setTemplateIdOpenMenu] = useState(null);

  const { pageTemplateList } = useContext(EmailTemplateContext);

  useEffect(() => {
    if (listTemplates.length > 5) {
      let finalList = [];

      let pageOffset = pageTemplateList * TEMPLATES_PER_PAGE;

      finalList = listTemplates.slice(
        pageOffset,
        TEMPLATES_PER_PAGE * (pageTemplateList + 1)
      );

      setFilteredList(finalList);
    } else {
      setFilteredList(listTemplates);
    }
  }, [pageTemplateList, listTemplates]);

  function handleCloseMenu() {
    setAnchorEl(null);
  }

  return filteredList.length > 0 && filteredList ? (
    filteredList?.map((element) => (
      <Grid item xs={6} key={element.id}>
        <Grid
          container
          justifyContent="space-between"
          style={{
            border: "1px solid #cecfd0",
            borderRadius: "8px",
            background: "#f5f5f5",
          }}
          onClick={() => {
            handleChangeTemplate(element.id);
          }}
        >
          <Grid item xs={2}>
            <Radio
              checked={selectedTemplateId === element.id}
              value={String(element?.id)}
              color="primary"
            />
          </Grid>

          <Grid item container xs={8} justifyContent="center">
            {isLoadingImage ? (
              <div style={{ marginRight: "110px", marginTop: "80px" }}>
                <CircularProgress />
              </div>
            ) : (
              <img
                alt=""
                style={{
                  height: "200px",
                  width: "100%",
                  objectFit: "contain",
                }}
                src={`${process.env.REACT_APP_NEW_PAINEL_API_URL}/v1/email-template/get-template-image/${companyUser.document}/${element.type}/${element?.id}`}
              />
            )}
          </Grid>

          <Grid item xs={2}>
            <Button
              aria-controls={element?.templateName + "-simple-menu"}
              aria-haspopup="true"
              onClick={(event) => {
                setTemplateIdOpenMenu(element.id);
                setAnchorEl(event.currentTarget);
              }}
            >
              <MoreHorizIcon
                style={{
                  flexShrink: 0,
                  height: "24px",
                  width: "24px",
                }}
              />
            </Button>
          </Grid>

          <Menu
            id={element?.templateName + "-simple-menu"}
            key={element?.templateName}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            open={Boolean(anchorEl) && templateIdOpenMenu === element.id}
            onClose={handleCloseMenu}
            PaperProps={{
              style: {
                boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.02)",
              },
            }}
            style={{ marginTop: "4px" }}
          >
            {element?.type !== "standard" && (
              <MenuItem
                onClick={() => {
                  handleOpenEditTemplate(element);
                }}
              >
                <ListItemIcon style={{ marginRight: "-24px", color: "black" }}>
                  <BorderColorOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Editar" />
              </MenuItem>
            )}

            {element?.type !== "standard" && (
              <MenuItem
                onClick={() => {
                  handleOpenModalDeleteTemplate(element);
                }}
              >
                <ListItemIcon style={{ marginRight: "-24px", color: "black" }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M10 9C10.5523 9 11 9.44771 11 10V16.5C11 17.0523 10.5523 17.5 10 17.5C9.44771 17.5 9 17.0523 9 16.5V10C9 9.44771 9.44771 9 10 9Z"
                      fill="#162238"
                    />
                    <path
                      d="M15 10C15 9.44771 14.5523 9 14 9C13.4477 9 13 9.44771 13 10V16.5C13 17.0523 13.4477 17.5 14 17.5C14.5523 17.5 15 17.0523 15 16.5V10Z"
                      fill="#162238"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M9.6445 1C9.2793 1 8.94315 1.19908 8.76761 1.51932L7.40778 4H2C1.44772 4 1 4.44772 1 5C1 5.55228 1.44772 6 2 6H3.5V22C3.5 22.5523 3.94772 23 4.5 23H19.5C20.0523 23 20.5 22.5523 20.5 22V6H22C22.5523 6 23 5.55228 23 5C23 4.44772 22.5523 4 22 4H16.598L15.2695 1.5268C15.0952 1.20239 14.7568 1 14.3885 1H9.6445ZM14.3277 4L13.7906 3H10.2367L9.68856 4H14.3277ZM5.5 6V21H18.5V6H5.5Z"
                      fill="#162238"
                    />
                  </svg>
                </ListItemIcon>
                <ListItemText primary="Deletar" />
              </MenuItem>
            )}

            <MenuItem
              onClick={() => {
                handleOpenModalSendTestEmailTemplate();
              }}
            >
              <ListItemIcon style={{ marginRight: "-24px", color: "black" }}>
                <EmailOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Enviar Email de Teste" />
            </MenuItem>
          </Menu>
          <Grid
            item
            xs={12}
            style={{
              borderRadius: "0px 0px 8px 8px",
              background: "rgba(0, 0, 0, 0.54)",
              color: "#FFF",
              textAlign: "center",
              fontFamily: "Roboto",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: "400",
            }}
          >
            <div>{element?.name}</div>
          </Grid>
        </Grid>
      </Grid>
    ))
  ) : (
    <></>
  );
};

export default TemplateComponent;
