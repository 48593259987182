import { useContext } from "react";

import Grid from "@material-ui/core/Grid";
import {
  Section,
  AboutUsContainer,
  AboutUsContent,
  AboutTextContent,
  Text,
  Title,
  TextAreaEdit,
} from "../styles/Home";
import useHomeController from "../controllers/HomeController";

import { HomeContext } from "../contexts/HomeContext";
import UploadAboutUsImage from "./UploadAboutUsImage";

const SectionAbout = ({ ...props }) => {
  const { landTheme, aboutUsImage, aboutUsText, editEnable, setAboutUsText } =
    useContext(HomeContext);
  const { saveWebsiteChanges } = useHomeController();
  const handleBlur = () => {
    saveWebsiteChanges();
  };
  return (
    <Section $backgroundColor={landTheme.color_pallete.white}>
      <AboutUsContainer maxWidth="lg" id="section-about">
        <AboutUsContent container justifyContent="center" alignItems="center">
          <Grid container spacing={4} className="aboutUsCenter">
            {(aboutUsImage || editEnable) && (
              <Grid
                item
                md={4}
                className="aboutUsImageBox"
                container
                justifyContent="center"
                alignItems="center"
              >
                <UploadAboutUsImage />
              </Grid>
            )}

            <Grid
              item
              md={aboutUsImage || editEnable ? 8 : 12}
              className="aboutUsTextBox"
              justifyContent="center"
              alignItems="center"
              container
              direction="column"
            >
              <Grid item container alignItems="flex-start">
                <Title $color={landTheme.color_pallete.primary}>
                  <h2>Sobre nós</h2>
                </Title>
              </Grid>
              <AboutTextContent
                $editable={editEnable}
                item
                container
                alignItems="flex-start"
                $borderColor={landTheme.color_pallete.primary}
              >
                {editEnable ? (
                  <TextAreaEdit
                    value={aboutUsText}
                    type="text"
                    $color="black"
                    $fontSize={1.275}
                    $textAlign="unset"
                    onChange={(e) => {
                      setAboutUsText(e.target.value);
                    }}
                    onBlur={handleBlur}
                  />
                ) : (
                  <Text>{aboutUsText}</Text>
                )}
              </AboutTextContent>
            </Grid>
          </Grid>
        </AboutUsContent>
      </AboutUsContainer>
    </Section>
  );
};

export default SectionAbout;
