import { Box, Button, TextField } from "@material-ui/core";
import styled, { css } from "styled-components";
import CloseIcon from "@material-ui/icons/Close";

export const DialogStyle = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  background-color: #fff;
  box-shadow: 24px;
  border-radius: 10px;
  outline: none;
`;

export const DialogHeader = styled.div`
  font-weight: bold;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 20px;
  color: rgba(22, 34, 56, 1);
  font-size: 22px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  background-color: ${(props) => (props.loading ? "rgba(0,0,0,0.5)" : "#fff")};

  .row {
    display: flex;
    flex-direction: row;
    position: relative;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(207, 216, 220, 1);
  }
`;

export const CloseIconStyle = styled(CloseIcon)`
  position: absolute;
  right: 0;
  cursor: pointer;
  color: rgba(126, 137, 158, 1);
`;

export const ButtonTogleStyle = styled(Button)`
  color: ${(props) => (props.active ? "#0036B3" : "#424242")};
  font-weight: ${(props) => (props.active ? "700" : "400")};
  text-transform: capitalize;
  border-bottom: ${(props) => (props.active ? "2px solid #0036b3" : "none")};
  border-radius: 0px;
  display: flex;
  height: 48px;
  padding: 0px 32px;
  justify-content: center;
  align-items: center;
`;

export const DivStyle = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 2px solid #f1f1f1;
`;

export const BodyStyle = styled.div`
  padding: 24px 24px 16px 24px;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "#fff"};
  border: 1px solid #cfd8dc;
  border-radius: 8px;
  margin-bottom: 40px;
`;

export const BorderlessBodyStyle = styled.div`
  padding: 0px 24px 16px 24px;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "#fff"};
  max-height: 500px;
  border-radius: 8px;
  margin-bottom: 40px;
`;

export const CardMeterStyle = styled.div`
  border-radius: 8px;
  justify: space-between;
  display: flex;
  flex-direction: row;
  background-color: #ffffff;
  margin-bottom: 5px;
  margin-top: 10px;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);
`;

export const EventStyle = styled.div`
  display: flex;
  padding: 8px 16px;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
`;

export const ValueStyle = styled.div`
  display: flex;
  width: 40px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px 4px 4px 1px;
  color: #0036b3;
  background-color: #e7eefe;
  font-weight: 700;
`;

export const FooterStyle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 22px;
  width: 100%;
  padding-right: 60px;
  padding-left: 20px;
  z-index: 2;
`;

export const ButtonFottterStyle = styled(Button)`
  color: ${(props) => (props.active ? "#0036B3" : "#63708A")};
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  text-transform: capitalize;
`;

export const TextFieldStyle = styled(TextField)`
  margin-top: 3px;
  border-radius: 8px;
  border: 1px solid var(--quasar-grey-06, #9da4b2);
  color: var(--grey-09, #364461);
  height: 40px;
`;

export const ButtonSave = styled(Button)`
  height: 40px;
  border-radius: 8px;
  background-color: ${(props) => (props.background ? "#f0f1f3" : "#0036B3")};
  padding: 8px 24px;
  text-transform: capitalize;
  color: ${(props) => (props.background ? "#364461" : "#fff")};
  &:hover {
    background-color: ${(props) => (props.background ? "#cfd4dc" : "#002a8c")};
    color: ${(props) => (props.background ? "#364461" : "#fff")};
  }

  ${(props) =>
    props.disabled &&
    css`
      background-color: ${(props) => (props.background = "#d3d3d3")} !important;
    `}
`;

export const ButtonCancel = styled(Button)`
  height: 40px;
  border-radius: 8px;
  background-color: #f0f1f3;
  padding: 8px 24px;
  text-transform: capitalize;
  color: #364461;
  &:hover {
    background-color: #cfd4dc;
    color: #364461;
  }
`;

export const ButtonText = styled(Button)`
  height: 40px;
  position: relative;
  border-radius: 8px;
  padding: 8px 24px;
  text-transform: capitalize;
  margin-left: 18px;
  color: #0036b3;
`;

export const DivFooter = styled.div`
  display: flex;
  padding-right: 24px;
  padding-left: 24px;
  padding-bottom: 24px;
  justify-content: space-between;
`;

export const CircleIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border-radius: 32px;
  ${(props) =>
    props.backgroundColor
      ? `background-color: ${props.backgroundColor};`
      : `background-color: #e7eefe;`}
`;
