import { search } from "./BaseRepositoryComponents";

const path = `${process.env.REACT_APP_NEW_PAINEL_API_URL}`;

export async function ofxAuth(userId) {
  const url = path + "/v1/ofx/auth";

  return search({
    path: url,
    method: "POST",
    myBody: {
      user_id: userId,
    },
  });
}
