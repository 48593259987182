import { Zoom } from "@material-ui/core";
import { DefaultTooltip } from "../../styles/GlobalStyle";

export const TooltipContainer = ({
  title,
  children,
  sidebar,
  customTooltip,
  beta,
  ...props
}) =>
  sidebar?.open && !customTooltip ? (
    <>{children}</>
  ) : (
    <DefaultTooltip
      title={title}
      placement="right"
      TransitionComponent={Zoom}
      TransitionProps={{ timeout: 400 }}
      {...props}
    >
      {children}
    </DefaultTooltip>
  );
