export const EmailTemplate = ({ disabled }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1 2.00006C1 1.44778 1.44772 1.00006 2 1.00006H11.5C12.0523 1.00006 12.5 1.44778 12.5 2.00006V13.0001C12.5 13.5523 12.0523 14.0001 11.5 14.0001H2C1.44772 14.0001 1 13.5523 1 13.0001V2.00006ZM3 3.00006V12.0001H10.5V3.00006H3ZM14.5 2.00006C14.5 1.44778 14.9477 1.00006 15.5 1.00006H22C22.5523 1.00006 23 1.44778 23 2.00006V6.00005C23 6.55234 22.5523 7.00005 22 7.00005H15.5C14.9477 7.00005 14.5 6.55234 14.5 6.00005V2.00006ZM16.5 3.00006V5.00005H21V3.00006H16.5ZM14.5 9.00005C14.5 8.44777 14.9477 8.00005 15.5 8.00005H22C22.5523 8.00005 23 8.44777 23 9.00005V13.0001C23 13.5523 22.5523 14.0001 22 14.0001H15.5C14.9477 14.0001 14.5 13.5523 14.5 13.0001V9.00005ZM16.5 10.0001V12.0001H21V10.0001H16.5ZM1 17.0001C1 16.4478 1.44772 16.0001 2 16.0001H22C22.5523 16.0001 23 16.4478 23 17.0001V21.5C23 22.0523 22.5523 22.5 22 22.5H2C1.44772 22.5 1 22.0523 1 21.5V17.0001ZM3 18V20.5H21V18H3Z"
        fill={disabled ? "#bdbdbd" : "#757575"}
      />
    </svg>
  );
};
