export const listCompaniesNotCreatedCertificateSystemActions = [
  {
    title: "Cadastro Gestão de Clientes",
    type: "STANDARD",
    subItems: [],
  },
  {
    title: "Armazenamento do Certificado Digital",
    type: "STANDARD",
    subItems: [],
  },
  {
    title: "Agendamento de verificação de procurações",
    type: "STANDARD",
    subItems: [],
  },
  {
    title: "Criação da base de dados",
    type: "ROW",
    subItems: [
      {
        title: "Folha de Pagamento",
      },
      {
        title: "Contabilidade",
      },
      {
        title: "Escrita Fiscal",
      },
    ],
  },
  {
    title: "Migração do eSocial",
    type: "COLUMN",
    subItems: [
      {
        title: "Cadastro de empresas",
      },
      {
        title: "Estabelecimentos",
      },
      {
        title: "Lotação tributária",
      },
      {
        title: "Rúbricas",
      },
      {
        title: "Colaboradores",
      },
      {
        title: "Férias",
      },
      {
        title: "Rescisão",
      },
      {
        title: "Afastamentos",
      },
      {
        title: "Holerites",
      },
    ],
  },
  {
    title: "XML: Inicia Baixa de Notas Fiscais",
    type: "COLUMN",
    subItems: [
      {
        title: "Busca automática de NF-e",
      },
      {
        title: "Busca automática de CT-e",
      },
      {
        title: "Busca autpomática de NFS-e Tomados",
      },
    ],
  },
];

export const listCompaniesCreatedCertificateSystemActions = [
  {
    title: "Atualização do Certificado",
  },
];
