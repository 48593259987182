import {
  CircularProgress,
  DialogActions,
  DialogContent,
  Modal,
} from "@material-ui/core";
import React, { useContext } from "react";
import {
  ButtonCancel,
  CloseIconStyle,
  DialogHeader,
  DialogStyle,
} from "../../../components/modal/Modal";
import { DataGrid } from "@material-ui/data-grid";

import { DocumentGuideContext } from "../contexts/DocumentGuideContext";

const ModalDocumentHistory = ({ handleClose, open, ...props }) => {
  const { documentHistoryList, loadingDocumentHistory } =
    useContext(DocumentGuideContext);

  const columns = [
    {
      field: "formatedDate",
      headerName: "Data",
      flex: 1,
    },
    {
      field: "formatedStatusMessage",
      headerName: "Status",
      flex: 1,
    },
    {
      field: "email",
      headerName: "Usuário",
      flex: 2,
    },
  ];

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <DialogStyle>
        <DialogHeader>
          <div className="row">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "baseline",
              }}
            >
              Histórico do documento
            </div>

            <CloseIconStyle
              onClick={() => {
                handleClose();
              }}
            />
          </div>
        </DialogHeader>

        <DialogContent>
          {loadingDocumentHistory ? (
            <CircularProgress />
          ) : (
            <DataGrid
              disableColumnMenu
              disableSelectionOnClick
              disableColumnSelector
              rows={documentHistoryList}
              columns={columns}
              pageSize={5}
              rowsPerPageOptions={[5]}
              autoHeight
            />
          )}
        </DialogContent>

        <DialogActions>
          <ButtonCancel onClick={handleClose}>Fechar</ButtonCancel>
        </DialogActions>
      </DialogStyle>
    </Modal>
  );
};

export default ModalDocumentHistory;
