import { Grid, Button, Checkbox, IconButton } from "@material-ui/core";
import styled from "styled-components";

export const ConfigTitleGrid = styled(Grid)`
  display: flex;
  align-items: baseline;
  align-self: stretch;
  justify-content: space-between;
  border-bottom: 1px solid #cfd8dc;
  padding-bottom: 6px;
`;

export const ConfigTitle = styled.div({
  color: "#162238",
  fontFamily: "Roboto",
  fontSize: "24px",
  fontStyle: "normal",
  fontWeight: "700",
  display: "flex",
  alignItems: "center",
});

export const ActiveCheckbox = styled(Checkbox)`
  margin-right: 12px;
  display: flex;
  width: 40px;
  height: 40px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
`;

export const SingleFrequencyGrid = styled(Grid)`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  border-bottom: 1px solid #e0e0e0;
  padding-left: 105px;
  border-top: 1px solid #e0e0e0;
`;

export const FrequencyGrid = styled(Grid)`
  display: flex;
  align-items: center;
  gap: 8px;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 5px;
  padding-top: 5px;
  padding-left: 105px;
`;

export const FrequencyTitle = styled.p({
  color: "0, 0, 0, 0.87",
  fontFamily: "Roboto",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "20.02px",
  letterSpacing: "0.15px",
});

export const CancelButton = styled(Button)`
  display: flex;
  height: 40px;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  padding: 8px 24px;
  justify-content: center;
  flex-shrink: 0;
  align-self: stretch;
  border-radius: 8px;
  background: #f0f1f3;
  margin-top: 24px;
  text-transform: none;
`;

export const DateConfirmed = styled.div({
  color: "#BDBDBD", // Fallback caso a variável não esteja definida
  fontFamily: "Roboto",
  fontSize: "16px",
  fontStyle: "italic",
  fontWeight: "400",
  lineHeight: "28px", // 175%
  letterSpacing: "0.15px",
});

export const IconButtonTemplate = styled(IconButton)`
  position: absolute;
  right: 0;
  align-content: end;
  margin-right: 10px;
`;
