import { useEffect, useState } from "react";
import HelpIcon from "@material-ui/icons/Help";
import { Search } from "@material-ui/icons";
import { DialoginfoDataGrid } from "./legendInfo";
import {
  Astyled,
  DivDataGrid,
  Headerstyled,
  Listyled,
  ULtyled,
} from "../styles/cssProducts";
import { getListPrefectureNFSe } from "./apiGetPrefectureNFSE";
import { legendAuthentication, OptionsIcons } from "./optionsXMLIcons";
import { DataGridDefault, GridStyled } from "../../../styles/GlobalStyle";
import QyonSidebar from "../../../assets/images/QYON-Sistemas-Inteligentes_Black.png";
import {
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Tooltip,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useHistory } from "react-router";

export const TableXML = () => {
  const [loading, setLoading] = useState(false);
  const [openModalLegend, setOpenModalLegend] = useState(false);
  const [prefecturelist, setPrefecturelist] = useState([]);
  const [filteredRows, setFilteredRows] = useState(prefecturelist);
  const { push } = useHistory();

  const legendXML = () => {
    setOpenModalLegend(true);
  };

  const closeDialogInfoDataGrid = () => {
    setOpenModalLegend(false);
  };

  const columns = [
    {
      field: "ibge",
      headerName: "Código IBGE",
      width: 157,
      renderCell: ({ row }) => (
        <div className="MuiDataGrid-columnHeaderTitle">{row?.ibge}</div>
      ),
    },
    {
      field: "uf",
      headerName: "UF",
      width: 90,
      renderCell: ({ row }) => (
        <div className="MuiDataGrid-columnHeaderTitle">{row.uf}</div>
      ),
    },
    {
      field: "city",
      headerName: "Cidade",
      width: 165,
      renderCell: ({ row }) => (
        <Tooltip title={row.city}>
          <div className="MuiDataGrid-columnHeaderTitle">
            {row.city.length > 18
              ? `${row.city.substring(0, 18)}...`
              : row.city}
          </div>
        </Tooltip>
      ),
    },
    {
      field: "importXML",
      headerName: "Importação XML Manual",
      width: 190,
      align: "center",
      renderCell: ({ row }) => (
        <GridStyled container alignItems="center" justifyContent="center">
          <Grid item flex={1}>
            {OptionsIcons(row?.importXML)}
          </Grid>
        </GridStyled>
      ),
    },
    {
      field: "importPDF",
      headerName: "Importação PDF Manual",
      width: 190,
      align: "center",
      renderCell: ({ row }) => (
        <GridStyled container alignItems="center" justifyContent="center">
          <Grid item flex={1}>
            {OptionsIcons(row?.importPDF)}
          </Grid>
        </GridStyled>
      ),
    },
    {
      field: "providedAutomatico",
      headerName: "Prestados Automático",
      align: "center",
      width: 160,
      renderCell: ({ row }) => (
        <GridStyled container alignItems="center" justifyContent="center">
          <Grid item flex={1}>
            {OptionsIcons(row.providedAutomatico)}
          </Grid>
        </GridStyled>
      ),
    },
    {
      field: "takenAutomatico",
      headerName: "Tomados Automático",
      align: "center",
      width: 160,
      renderCell: ({ row }) => (
        <GridStyled container alignItems="center" justifyContent="center">
          <Grid item flex={1}>
            {OptionsIcons(row.takenAutomatico)}
          </Grid>
        </GridStyled>
      ),
    },
    {
      field: "takenOutAutomatico",
      headerName: "Tomadas de Fora",
      width: 160,
      align: "center",
      renderCell: ({ row }) => (
        <GridStyled container alignItems="center" justifyContent="center">
          <Grid item flex={1}>
            {OptionsIcons(row?.takenOutAutomatico)}
          </Grid>
        </GridStyled>
      ),
    },
    {
      field: "authentication",
      headerName: "Autenticação requerida",
      width: 174,
      renderCell: ({ row }) => (
        <div className="MuiDataGrid-columnHeaderTitle">
          {legendAuthentication(row.authentication)}
        </div>
      ),
    },
  ];

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        let response = await getListPrefectureNFSe();
        if (response.status === 200) {
          setLoading(false);
        }
        setPrefecturelist(response.data.list);
        setFilteredRows(response.data.list);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  function handleFilterChange(event) {
    const value = event.target.value.toLowerCase();
    const filteredData = prefecturelist.filter(
      (row) =>
        row.city.toLowerCase().includes(value) ||
        row.ibge.toLowerCase().includes(value) ||
        row.uf.toLowerCase().includes(value)
    );
    setFilteredRows(filteredData);
  }
  return (
    <>
      <Headerstyled>
        <Grid container alignItems="flex-start" justifyContent="flex-start">
          <Grid item xs={6} style={{ display: "flex", alignItems: "center" }}>
            <div>
              <IconButton onClick={() => push("/versoes")}>
                <ArrowBackIcon />
              </IconButton>
            </div>

            <img src={QyonSidebar} style={{ width: "150px" }} alt="Logo" />
          </Grid>
          <Grid
            item
            xs={6}
            style={{
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "flex-end",
            }}
          >
            <nav>
              <ULtyled>
                <Listyled>
                  <Astyled
                    href="https://portal.qyon.com/login?logout=true"
                    data-item="Qyon"
                  >
                    Qyon
                  </Astyled>
                </Listyled>
                <Listyled>
                  <Astyled href="https://www.qyon.com/" data-item="Sobre Nós">
                    Sobre Nós
                  </Astyled>
                </Listyled>
                <Listyled>
                  <Astyled href="https://qyon.com/contato/" data-item="Contato">
                    Contato
                  </Astyled>
                </Listyled>
              </ULtyled>
            </nav>
          </Grid>
        </Grid>
      </Headerstyled>
      <Grid container>
        <Grid item xs={6}>
          <Tooltip title="Pesquise através da cidade, UF ou código IBGE">
            <FormControl fullWidth sx={{ m: 1 }} variant="outlined">
              <InputLabel style={{ marginTop: "25px", marginLeft: "55px" }}>
                Pesquisar
              </InputLabel>
              <OutlinedInput
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton edge="end">{<Search />}</IconButton>
                  </InputAdornment>
                }
                label="Pesquisar"
                onChange={handleFilterChange}
                style={{ marginTop: "25px", marginLeft: "55px" }}
              />
            </FormControl>
          </Tooltip>
        </Grid>

        <Grid item xs={2}>
          <Tooltip title="Informações">
            <IconButton
              onClick={legendXML}
              style={{
                marginTop: "25px",
                marginLeft: "60px",
                marginBottom: "10px",
              }}
            >
              <HelpIcon fontSize="inherit" style={{ color: "#1976d2" }} />
            </IconButton>
          </Tooltip>
        </Grid>

        <DivDataGrid>
          <DataGridDefault
            style={{ marginLeft: "5px" }}
            fullWidth
            autoHeight
            loading={loading}
            getRowId={(row) => row.ibge}
            rows={filteredRows}
            columns={columns}
            pageSize={10}
            rowHeight={prefecturelist.length === 0 ? 15 : 40}
          />
        </DivDataGrid>

        {openModalLegend && (
          <DialoginfoDataGrid
            openModal={openModalLegend}
            closeModal={closeDialogInfoDataGrid}
          />
        )}
      </Grid>
    </>
  );
};
