import * as React from "react";

function ViewedIcon(props) {
  return (
    <svg
      width={22}
      height={13}
      viewBox="0 0 22 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.7 12.197L.05 6.547l1.425-1.4 5.65 5.65-1.425 1.4zm5.65 0L5.7 6.547l1.4-1.425 4.25 4.25 9.2-9.2 1.4 1.425-10.6 10.6zm0-5.65l-1.425-1.4 4.95-4.95 1.425 1.4-4.95 4.95z"
        fill="#0036B3"
      />
    </svg>
  );
}

export default ViewedIcon;
