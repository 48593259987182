import { isCompany } from "../../../utils/panelType";

export const createDocumentEmitted = (
  body,
  cnpj,
  generateTicket,
  email = ""
) => {
  let accountantCnpj =
    localStorage.getItem("isAccountantPanel") === "true"
      ? localStorage.getItem("documentPanel")
      : localStorage.getItem("accountantCnpjPanel");

  if (isCompany() === true) {
    accountantCnpj = localStorage.getItem("documentPanel");
  }

  return {
    email: {
      to: email !== "" ? email : localStorage.getItem("emailPanel"),
      cc: "",
      body: "",
    },
    documents: [
      {
        ...body,
      },
    ],
    cnpj: cnpj,
    accountantCnpj: accountantCnpj,
    generateTicket: generateTicket,
    sub: localStorage.getItem("subCognitoPanel"),
  };
};
