import { debuggerErrors } from "../utils/debuggerErrors";
import { notify } from "../utils/notify";

export function search({
  path,
  method = "GET",
  myHeaders = new Headers({
    Authorization: "Bearer " + localStorage.getItem("tokenPanel"),
    "Entity-Id": localStorage.getItem("companyIdPanel"),
    "Content-Type": "application/json",
    "Content-Language": "pt-BR",
  }),
  myBody = null,
  showMessage = true,
  logoutOnUnauthorized = true,
}) {
  let myInit = {
    method: method,
    headers: myHeaders,
    mode: "cors",
    body: myBody ? JSON.stringify(myBody) : myBody,
  };

  return fetch(path, myInit)
    .then((response) => {
      const promise = response.json();

      if (logoutOnUnauthorized) debuggerErrors(promise);

      return promise;
    })
    .catch((error) => {
      console.error(error);
      console.debug(error);
      if (showMessage)
        notify(error || "Não foi possível realizar a ação, tente novamente.");

      return { success: false, mensagem: error };
    });
}

export function searchBlob({
  path,
  method = "GET",
  myHeaders = new Headers({
    Authorization: "Bearer " + localStorage.getItem("tokenPanel"),
    "Entity-Id": localStorage.getItem("companyIdPanel"),
    "Content-Type": "application/json",
    "Content-Language": "pt-BR",
  }),
  myBody = null,
}) {
  let myInit = {
    method: method,
    headers: myHeaders,
    mode: "cors",
    body: myBody ? JSON.stringify(myBody) : myBody,
  };

  return fetch(path, myInit)
    .then((response) => {
      const promise = response.blob();

      debuggerErrors(promise);

      return promise;
    })
    .catch((error) => {
      console.error(error);
      console.debug(error);
      notify(error || "Não foi possível realizar a ação, tente novamente.");

      return { success: false, mensagem: error };
    });
}
