import React, { useContext, useEffect } from "react";
import { GlobalSubheader } from "../../components/subHeader/GlobalSubheader";
import { GlobalContext } from "../../contexts/GlobalContext";
import FilterTypeContent from "./components/FilterTypeContent";
import { Grid } from "@material-ui/core";
import { KnowledgeBaseContext } from "./context/KnowledgeBaseContext";
import ContentComponent from "./components/ContentComponent";

const KnowledgeBase = (typeRender = "page") => {
  const { setBreadcrumbs } = useContext(GlobalContext);
  const {
    contentList,
    filterContentType,
    filterSearch,
    setContentList,
    filterContentTypeFile,
  } = useContext(KnowledgeBaseContext);

  const LIST_CONTENT = [
    {
      id: 1,
      url: "https://qyon-portal-videos.s3.sa-east-1.amazonaws.com/XML/INTEGRACAO_QYON_XML_DOMINIO_V3_BAIXA.mp4",
      thumbnail:
        "https://qyon-portal-videos.s3.sa-east-1.amazonaws.com/XML/INTEGRACAO_QYON_XML_DOMINIO_V3_BAIXA.jpeg",
      duration: "07:29",
      title: "Integração QYON XML e Domínio",
      description:
        "Neste vídeo, você aprenderá a realizar a integração do Qyon XML e Domínio.",
      dateEntered: "2024-09-09",
      type: "xml",
      file: "mp4",
      related: [2],
    },
    {
      id: 2,
      url: "https://qyon-portal-videos.s3.sa-east-1.amazonaws.com/XML/Integra%C3%A7%C3%A3o+de+Download+de+Arquivos+App+entre+Qyon+XML+e+Dom%C3%ADnio.pdf",
      title: "Manual de Integração QYON XML e Domínio",
      description:
        "Manual em PDF para realizar a integração do Qyon XML e Domínio.",
      dateEntered: "2024-09-12",
      type: "xml",
      file: "pdf",
      related: [1],
    },
    //...
  ];

  useEffect(() => {
    setBreadcrumbs([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const filteredList = LIST_CONTENT.filter((item) => {
      if (filterContentType === "all" && filterContentTypeFile === "all") {
        return item.title.toLowerCase().includes(filterSearch.toLowerCase());
      } else if (
        filterContentType === "all" &&
        filterContentTypeFile !== "all"
      ) {
        return (
          item.title.toLowerCase().includes(filterSearch.toLowerCase()) &&
          item.file.toLowerCase().includes(filterContentTypeFile)
        );
      } else if (
        filterContentType !== "all" &&
        filterContentTypeFile === "all"
      ) {
        return (
          item.title.toLowerCase().includes(filterSearch.toLowerCase()) &&
          item.type.toLowerCase().includes(filterContentType)
        );
      } else {
        return (
          item.title.toLowerCase().includes(filterSearch.toLowerCase()) &&
          item.type.toLowerCase().includes(filterContentType) &&
          item.file.toLowerCase().includes(filterContentTypeFile)
        );
      }
    });
    setContentList(filteredList);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterContentType, filterSearch, filterContentTypeFile]);

  return (
    <>
      {typeRender === "page" && (
        <GlobalSubheader title={"Rotinas Operacionais"} />
      )}

      <div
        style={
          typeRender === "page"
            ? {
                padding: 20,
                height: "calc(100% - 60px)",
                display: "flex",
                flexDirection: "column",
              }
            : {}
        }
      >
        <div
          container
          style={
            typeRender === "page"
              ? {
                  background: "#fff",
                  border: "1px solid #E0E0E0",
                  borderRadius: 15,
                  padding: 15,
                }
              : {}
          }
        >
          <FilterTypeContent listTotalVideos={LIST_CONTENT} />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: 20,
          }}
        >
          <Grid container spacing={2}>
            {contentList.map((item) => (
              <ContentComponent
                url={item.url}
                thumbnail={item.thumbnail}
                duration={item.duration}
                title={item.title}
                description={item.description}
                dateEntered={item.dateEntered}
                typeFile={item.file}
                listRelatedContent={item.related}
                listContent={LIST_CONTENT}
                typeRender={typeRender}
              />
            ))}

            {contentList.length === 0 && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: "100%",
                }}
              >
                <h3>Nenhum conteúdo encontrado</h3>
              </div>
            )}
          </Grid>
        </div>
      </div>
    </>
  );
};

export default KnowledgeBase;
