import React from "react";
import { Helmet } from "react-helmet";
import { ItemGridDragDropTitleStyle } from "../../components/grid-drag-drop/ItemGridDragDropTitleStyle";
import { Grid, IconButton } from "@material-ui/core";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import { LightTooltip, Margin } from "../../styles/GlobalStyle";
import CachedOutlinedIcon from "@material-ui/icons/CachedOutlined";
import CloseIcon from "@material-ui/icons/Close";
import { DividerStyled } from "../../components/chat/styles/GeneralStyle";
import useDashboardViewController from "../../pages/dashboard/controllers/DashboardViewController";

export const WidgetVersion77 = () => {
  const { handleDeleteWidgets } = useDashboardViewController();

  const item = {
    i: "77",
    importScripts: [""],
    stringScripts: [
      `try {
        const GRTShowError = () => {
          const errorElement = document.getElementById("GRTfetchError");
          if (errorElement) {
            errorElement.style.display = "flex";
            document.getElementById("GRTloaderAnimation").style.display = "none";
            document.getElementById("GRTwidGet").style.display = "none";
          }
        };
  
        let GRTtoken = localStorage.getItem("tokenCognitoPanel"),
            GRTdocument = localStorage.getItem("accountantCnpjPanel") && localStorage.getItem("accountantCnpjPanel") !== "" 
              ? localStorage.getItem("accountantCnpjPanel") 
              : localStorage.getItem("documentPanel");
  
        const modelSystem = localStorage.getItem("accountantCnpjPanel") && localStorage.getItem("accountantCnpjPanel") !== "" 
              ? "areacliente" 
              : "crm";
  
        let partnerLogin = \`https://crmcont.qyon.com/\${modelSystem}/PartnerLogin.asp\`;
  
        document.getElementById("GRTOpenTck").addEventListener("click", (e) => {
          e.preventDefault();
          location.href = \`\${partnerLogin}?token=\${localStorage.getItem("tokenCognitoPanel")}&cnpj=\${GRTdocument}&redirect=\${encodeURI("https://crmcont.qyon.com/" + modelSystem + "/ticket.asp?id=234")}\`;
        });
  
        document.getElementById("GRTViewMore").addEventListener("click", (e) => {
          e.preventDefault();
          location.href = \`\${partnerLogin}?token=\${localStorage.getItem("tokenCognitoPanel")}&cnpj=\${GRTdocument}&redirect=\${encodeURI("https://crmcont.qyon.com/" + modelSystem + "/historico.asp?")}\`;
        });
  
        const onClickViewTck = (cdTck, customerId, accEml) => {
          location.href = \`\${partnerLogin}?token=\${localStorage.getItem("tokenCognitoPanel")}&cnpj=\${GRTdocument}&action=editTck&c=\${customerId}&contEml=\${accEml}&k=\${(customerId + cdTck) * 1531}&redirect=\${encodeURI("https://crmcont.qyon.com/" + modelSystem + "/chamada_view3.asp?n=" + cdTck)}\`;
        };
  
        const GRTgetUserByToken = async () => {
          const GRTUser = await fetch("https://crmcont.qyon.com/api-be/v1/auth", {
            headers: new Headers({
              "qyon-customer-cnpj": GRTdocument,
              Authorization: GRTtoken,
            }),
          })
            .then((res) => res.json())
            .catch((error) => console.log("[error][widget][ticket][GR] getting user", error));
  
          return {
            companyId: GRTUser.company.id,
            email: GRTUser.email,
            attendantId: GRTUser.identifiers.userId,
            customerId: GRTUser.identifiers.customerId,
            customerContactId: GRTUser.identifiers.customerContactId,
          };
        };
  
        const GRTgetTickets = async () => {
          let GRTuser = await GRTgetUserByToken().catch((error) => {
            console.log("[error][widget][GR][ticket] getting user by token", error);
            GRTShowError();
          });
  
          let GRTapiCrmTickets = await fetch(
            \`https://crmcont.qyon.com/api-be/v1/ticket?\${GRTuser.attendantId ? \`attendantId=\${GRTuser.attendantId}\` : \`customerEmail=\${GRTuser.email}\`}\`,
            {
              headers: {
                "qyon-customer-cnpj": GRTdocument,
                Authorization: GRTtoken,
              },
            }
          )
            .then((res) => res.json())
            .catch((error) => console.log("[error][widget][GR][ticket] getting tickets", error));
  
          document.getElementById("GRTwidGet").innerHTML = GRTrenderContent(GRTapiCrmTickets.items);
        };
  
        const GRTrenderContent = (GRTjson) => {
          const GRTrenderIconOrigin = (GRTorigin) => {
            const images = {
              ACL: "data:image/png;base64,...",
              EML: "data:image/png;base64,...",
              ONL: "data:image/png;base64,...",
              TEL: "data:image/png;base64,...",
            };
            return \`<img src='\${images[GRTorigin]}' width='30px'/>\`;
          };
  
          const GRTrenderList = (GRTtickets) => {
            return GRTtickets
              .map(
                (GRTvalue) => \`<li style='display: flex; width: 90%; justify-content: start; margin: 20px 10px'>
                  <div style='width: 10%; flex-direction: column; justify-content: center; align-self: center;'>
                    <div title='\${GRTvalue.situation ? GRTvalue.situation : "Sem situação"}' style='background-color: \${GRTvalue.color ? GRTvalue.color : "#000"}; border-radius: 50%; width: 25px; height: 25px; text-align: center; position: relative; transform: translateX(-50%); left: 50%;'></div>
                  </div>
                  <div style='width: 70%;'>
                    <div><a style="cursor: pointer" onClick="onClickViewTck(\${GRTvalue.id}, \${GRTvalue.customerId}, '\${GRTvalue.email}')" id="GRTViewTck"><strong>#\${GRTvalue.id}</strong></a></div>
                    <div>\${GRTvalue.flagInterationEmail || !GRTvalue.dateUpdateTicket ? \`<b>\${GRTvalue.title || GRTvalue.customerName}</b>\` : GRTvalue.title || GRTvalue.customerName}</div>
                  </div>
                  <div style='width: 20%'>\${GRTrenderIconOrigin(GRTvalue.origin)}</div>
                </li>\`
              )
              .join("");
          };
  
          return \`<div id='tabTickets'><ul style='padding: 0 !important;'>\${GRTrenderList(GRTjson)}</ul></div>\`;
        };
  
        try {
          (async () => {
            await GRTgetTickets();
            document.getElementById("GRTloaderAnimation").style.display = "none";
          })();
        } catch (error) {
          console.log("[error][widget][GR][ticket] main function ", error);
          GRTShowError();
        }
      } catch (err) {}`,
    ],
    onRefresh: "",
    refreshOnLoad: true,
  };

  return (
    <>
      {item.importScripts.map((script, index) => {
        return (
          <div key={`import_${index}`}>
            <Helmet>
              <script async src={script}></script>
            </Helmet>
          </div>
        );
      })}

      <ItemGridDragDropTitleStyle customStyle={item.headerStyle ?? {}}>
        <Grid item container justifyContent="space-between" alignItems="center">
          <Grid item flex={1}>
            <IconButton size="small">
              <DragIndicatorIcon style={{ fontSize: 18, marginRight: 5 }} />
            </IconButton>
            Atendimentos
          </Grid>
          <Grid item flex={1}>
            <Grid item container alignItems="center">
              <Margin top="7" />
              {item.onRefresh &&
                (() => {
                  if (item?.refreshOnLoad) {
                    setTimeout(() => {
                      try {
                        // eslint-disable-next-line no-eval
                        eval("try{" + item.onRefresh + "}catch(err){}");
                      } catch (error) {
                        console.error(error);
                      }
                    }, 1500);
                  }

                  return (
                    <Grid item flex={1}>
                      <LightTooltip title="Atualizar">
                        <CachedOutlinedIcon
                          style={{
                            fontSize: 18,
                            marginRight: 10,
                            cursor: "pointer",
                            color: "#636363",
                          }}
                          onClick={() => {
                            // Muito cuidado, o eval é perigoso - https://developer.mozilla.org/pt-BR/docs/Web/JavaScript/Reference/Global_Objects/eval
                            try {
                              // eslint-disable-next-line no-eval
                              eval("try{" + item.onRefresh + "}catch(err){}");
                            } catch (error) {
                              console.error(error);
                            }
                          }}
                        />
                      </LightTooltip>
                    </Grid>
                  );
                })()}
              <Grid item flex={1}>
                <LightTooltip title="Remover">
                  <CloseIcon
                    style={{
                      fontSize: 18,
                      cursor: "pointer",
                      color: "#636363",
                      marginRight: 10,
                    }}
                    onClick={() => handleDeleteWidgets(item)}
                  />
                </LightTooltip>
              </Grid>
            </Grid>
          </Grid>
          <DividerStyled style={{ width: "100%" }} />
        </Grid>
      </ItemGridDragDropTitleStyle>

      <div
        style={{
          height: `${"calc(100% - 65px)"}`,
          width: "100%",
        }}
      >
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link
          rel="preconnect"
          href="https://fonts.gstatic.com"
          crossOrigin="true"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Ubuntu&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/icon?family=Material+Icons+Round"
          rel="stylesheet"
        />

        <style>
          {`@keyframes GRTspinner-grow {
          0% { transform: scale(0); }
          50% { opacity: 1; transform: none; }
        }`}
        </style>

        <div
          id="GRT_widget"
          style={{
            height: "calc(100% - 58.33px)",
            fontFamily: "'Ubuntu', sans-serif",
            padding: "0 10px 10px 10px",
            position: "relative",
            overflowY: "scroll",
            marginBottom: "10px",
          }}
        >
          <div
            id="GRTloaderAnimation"
            style={{
              color: "#0d6efd",
              display: "block",
              width: "5rem",
              height: "5rem",
              verticalAlign: "-0.125em",
              backgroundColor: "currentColor",
              borderRadius: "50%",
              opacity: 0,
              WebkitAnimation: "0.75s linear infinite GRTspinner-grow",
              animation: "0.75s linear infinite GRTspinner-grow",
              animationDuration: "1.5s",
              position: "absolute",
              top: "calc(50% - 2.5rem)",
              left: "calc(50% - 2.5rem)",
            }}
          ></div>

          <div
            id="GRTfetchError"
            style={{
              position: "absolute",
              width: "calc(100% - 20px)",
              height: "100%",
              display: "none",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <span
              className="material-icons-round"
              style={{ fontSize: "3em", margin: "8px" }}
            >
              error_outline
            </span>
            <p>
              Não foi possível carregar os dados
              <br />
              Tente novamente
            </p>
          </div>

          <button
            id="GRTOpenTck"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              float: "right",
              marginRight: "10%",
              marginTop: "10px",
              marginBottom: "20px",
              backgroundColor: "#0a73db",
              border: "none",
              borderRadius: "5px",
              padding: "10px",
              color: "white",
            }}
            onMouseOut={(e) =>
              (e.currentTarget.style.backgroundColor = "#0A73DB")
            }
            onMouseOver={(e) =>
              (e.currentTarget.style.backgroundColor = "#085db3")
            }
            onClick={(e) => (e.currentTarget.style.backgroundColor = "#0f87ff")}
          >
            Abrir um novo ticket
          </button>

          <button
            id="GRTViewMore"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              float: "left",
              marginLeft: "10%",
              marginTop: "10px",
              marginBottom: "20px",
              backgroundColor: "#0a73db",
              border: "none",
              borderRadius: "5px",
              padding: "10px",
              color: "white",
            }}
            onMouseOut={(e) =>
              (e.currentTarget.style.backgroundColor = "#0A73DB")
            }
            onMouseOver={(e) =>
              (e.currentTarget.style.backgroundColor = "#085db3")
            }
            onClick={(e) => (e.currentTarget.style.backgroundColor = "#0f87ff")}
          >
            Ver mais
          </button>

          <div id="GRTwidGet"></div>
        </div>
      </div>

      {item.stringScripts &&
        item.stringScripts.map((script, index) => {
          return (
            <div key={`script_${index}`}>
              <Helmet>
                <script async>{script}</script>
              </Helmet>
            </div>
          );
        })}
    </>
  );
};
