import styled from "styled-components";

import {
  IconButton,
  DialogActions,
  Divider,
  Button,
  Grid,
} from "@material-ui/core";

export const DialogLetterHeader = styled(Grid)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 2em 2em 1em 2em;
`;

export const ButtonIcon = styled(IconButton)`
  margin-left: 15vw;
`;

export const DividerDialog = styled(Divider)`
  margin: 0 2em;
`;

export const DialogLetterActions = styled(DialogActions)`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 1em 1.5em;
`;

export const DownloadButton = styled(Button)`
  background-color: #0036b3;
  color: white;
  padding: 0.8em 1.5em;
  border-radius: 10px;

  &:hover {
    background-color: #0036b3;
    color: white;
    opacity: 0.8;
  }
`;

export const CloseButton = styled(Button)`
  background-color: #f0f1f2;
  color: black;
  padding: 0.8em 1.5em;
  border-radius: 10px;

  &:hover {
    background-color: #f0f1f2;
    color: black;
    opacity: 0.8;
  }
`;

export const PdfIframe = styled.iframe`
  overflow: hidden;
  border: none;
  width: 100%;
  height: 47vh;
  margin-top: 1em;
  background: white;
`;
