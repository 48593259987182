import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import { removeNotify } from "../../utils/notify";
import { useHistory } from "react-router-dom";
import { getLogoBySubdomain } from "../../repositories/panel/v1/EntityRepository";
import { GridStyled, Margin } from "../../styles/GlobalStyle";

import { usePortalAsClient } from "../../utils/viewPortalAsClientHook";
import { LoginContext } from "./contexts/LoginContext";
import useLoginController from "./controllers/LoginController";
import { PasswordIcon, QyonLogo } from "./LoginIcon";
import {
  FormLogin,
  LoginButton,
  LoginHeader,
  LoginHeaderSecondary,
  LoginLabel,
  LoginTextField,
} from "./styles/Login";
import LoginLayout from "./LoginLayout";

const allowedHosts = [
  "portal",
  "portal1",
  "portal2",
  "portal3",
  "localhost:3000",
];

export default function Login({ location }) {
  const {
    handleSubmit,
    handleChangeEmail,
    handleClearContextAuth,
    handleLogout,
    isGfTrialFirstAccess,
  } = useLoginController();

  const { email, setEmail, password, setPassword, loading, setLoading } =
    useContext(LoginContext);
  const { push } = useHistory();
  const { search } = useLocation();
  const { backToAccountantView } = usePortalAsClient();
  const [logoUrl, setLogoUrl] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (location.state && location.state.email) setEmail(location.state.email);
  }, [location, setEmail]);

  useEffect(() => {
    let data = localStorage.getItem("LoginWebsite");

    handleClearContextAuth();

    const logout = new URLSearchParams(search).get("logout") || false;
    const usernameUrl = new URLSearchParams(search).get("username");
    const gfTrial = new URLSearchParams(search).get("gfTrial");

    if (gfTrial) {
      isGfTrialFirstAccess.current = true;
    }

    if (usernameUrl) setEmail(usernameUrl);

    if (
      localStorage.getItem("tokenPanel") &&
      !location?.state?.logout &&
      !logout
    ) {
      push("/dashboard/view");
    } else {
      removeNotify();
      backToAccountantView();
      handleLogout();
    }

    if (data) {
      setLoading(true);
      data = JSON.parse(data);
      setEmail(data.email);
      setPassword(data.password);
      setTimeout(() => {
        try {
          document.getElementById("loginForm").requestSubmit();
        } catch (err) {
        } finally {
          localStorage.removeItem("LoginWebsite");
        }
      }, 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    handleClearContextAuth,
    handleLogout,
    location?.state,
    push,
    search,
    backToAccountantView,
  ]);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);

        const subdomain =
          sessionStorage.getItem("hostPanel") ||
          window.location.host.substring(
            0,
            window.location.host.indexOf(".qyon.")
          ) ||
          window.location.host;

        if (
          sessionStorage.getItem("logoLogin") ||
          allowedHosts.includes(subdomain)
        ) {
          setLogoUrl(sessionStorage.getItem("logoLogin") || "");
          return;
        }

        const logoCallback = await getLogoBySubdomain(subdomain);
        if (logoCallback?.success) {
          let logoUrlCallback = logoCallback?.data?.logo_url || "";
          setLogoUrl(logoUrlCallback);
          sessionStorage.setItem("logoLogin", logoUrlCallback);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    })();
  }, [setLoading]);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <LoginLayout>
      <FormLogin
        onSubmit={handleSubmit}
        id="loginForm"
        style={{ maxHeight: "100vh" }}
      >
        <GridStyled
          item
          container
          style={{
            justifyContent: "center",
            marginBottom: 16,
            marginTop: 16,
          }}
        >
          {logoUrl && (
            <img
              style={{ maxWidth: "100%", maxHeight: 90 }}
              src={`${logoUrl}`}
              alt="Logotipo do escritório"
            />
          )}

          {!logoUrl && <QyonLogo />}
        </GridStyled>

        <LoginHeader>Seja bem-vindo!</LoginHeader>
        <LoginHeaderSecondary>Faça seu Login</LoginHeaderSecondary>

        <GridStyled justifyContent="space-between" container>
          <LoginLabel>E-mail</LoginLabel>
          <LoginTextField
            disabled={loading}
            placeholder="nomeusuario@exemplo.com"
            fullWidth
            size="medium"
            variant="outlined"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={handleChangeEmail}
          />
        </GridStyled>
        <GridStyled
          justifyContent="space-between"
          container
          style={{ marginTop: 8 }}
        >
          <LoginLabel>Senha</LoginLabel>
          <LoginTextField
            variant="outlined"
            fullWidth
            disabled={loading}
            placeholder="Senha"
            autoComplete="current-password"
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    <PasswordIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </GridStyled>

        <Margin top="20" />
        <Grid item>
          <LoginButton type="submit" full variant="contained" color="primary">
            Login
          </LoginButton>
        </Grid>

        <Margin top="8" />

        <Grid container justifyContent="flex-end">
          <Grid item flex={1}>
            <Link
              variant="body2"
              onClick={() => push("/recoverpassword", { email })}
              style={{
                cursor: "pointer",
                fontSize: "12px",
                fontWeight: 500,
                color: "#7b8b9c",
              }}
            >
              Esqueci minha senha
            </Link>
          </Grid>
        </Grid>
        <Margin bottom="16" />
      </FormLogin>
    </LoginLayout>
  );
}
