import { search } from "../BaseRepositoryComponents";

const newPath = `${process.env.REACT_APP_NEW_PAINEL_API_URL}`;

export async function getHomePageWidgets(idPanel) {
  return search({
    path: `${newPath}/v1/panel_widgets/${idPanel}`,
    method: "GET",
  });
}

export async function putWidgets({ body, idpanel }) {
  return search({
    path: `${newPath}/v1/panel_widgets/${idpanel}`,
    method: "POST",
    myBody: body,
  });
}

export async function deletePanelWidget(idPanel, idVersionWidget) {
  return search({
    path: `
      ${newPath}/v1/panel_widgets/panel/${idPanel}/widget/${idVersionWidget}`,
    method: "DELETE",
  });
}

export async function putPanelPositions({ body }) {
  return search({
    path: `${newPath}/v1/panel_widgets/update_position`,
    method: "PUT",
    myBody: body,
  });
}
