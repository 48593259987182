import React, { useContext } from "react";

import { Grid, IconButton, Dialog, DialogContent } from "@material-ui/core";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";

import { GlobalContext } from "../../../contexts/GlobalContext";
import { GridStyled, LabelStyled } from "../../../styles/GlobalStyle";
import { DialogTitleBanner } from "./styles/CodeCardLiberation";
import useVideoFirstAccessController from "../controllers/VideoFirstAccessController";

export default function VideoFirstAccess() {
  const { userLogged } = useContext(GlobalContext);
  const { handleSubmitVideo } = useVideoFirstAccessController();

  return (
    <>
      <Dialog
        open={userLogged?.showVideoIntro === "true"}
        maxWidth="sm"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableScrollLock
      >
        <DialogTitleBanner>
          <GridStyled
            container
            justifyContent="flex-end"
            position="absolute"
            top="0px"
          >
            <IconButton onClick={handleSubmitVideo}>
              <CloseOutlinedIcon />
            </IconButton>
          </GridStyled>
        </DialogTitleBanner>

        <DialogContent>
          <GridStyled margin="0 0 10px 0" container justifyContent="center">
            <GridStyled margin="10px 0 15px 0" item flex={1}>
              <LabelStyled color="blue" fontWeight="500" fontSize="20">
                Bem Vindo(a) à Qyon
              </LabelStyled>
            </GridStyled>
            <Grid item container justifyContent="center">
              <iframe
                width="560"
                height="310"
                src="https://www.youtube.com/embed/3dvnanqumRg"
                title="Bem Vindo à Qyon"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </Grid>
          </GridStyled>
        </DialogContent>
      </Dialog>
    </>
  );
}
