export function generateHoursMenuItems() {
  const hours = [];
  const initialHour = 0;
  const initialMinute = 0;
  const gap = 30;

  for (let hour = initialHour; hour < 24; hour++) {
    for (let minute = initialMinute; minute < 60; minute += gap) {
      const formattedHour = hour.toString().padStart(2, "0");
      const formattedMinute = minute.toString().padStart(2, "0");
      const finalHour = `${formattedHour}:${formattedMinute}`;
      hours.push(finalHour);
    }
  }

  return hours;
}

export function generateMonthDaysMenuItems() {
  const daysOfMonth = [];
  const initialDay = 1;
  const FinalDay = 28;

  for (let day = initialDay; day <= FinalDay; day++) {
    daysOfMonth.push(day);
  }

  return daysOfMonth;
}
