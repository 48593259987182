import { Switch } from "react-router-dom";

import MiddlewareRoute from "../Middleware";
import KnowledgeBase from "../../pages/KnowledgeBase/KnowledgeBase";
import { KnowledgeBaseContextProvider } from "../../pages/KnowledgeBase/context/KnowledgeBaseContext";

export default function KnowledgeBaseModule() {
  return (
    <KnowledgeBaseContextProvider>
      <Switch>
        <MiddlewareRoute
          path="/operational-guides"
          sidebar
          component={KnowledgeBase}
          noPaddingSidebar
        />
      </Switch>
    </KnowledgeBaseContextProvider>
  );
}
