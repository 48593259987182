import { DialogActions, DialogContent, Grid, Modal } from "@material-ui/core";
import React from "react";
import {
  CloseIconStyle,
  DialogHeader,
  DialogStyle,
} from "../../../components/modal/Modal";
import gfGif from "../../../assets/gifs/seta_gf.gif";

export const ModalGFTrialFirstAccess = ({ open, handleClose, ...props }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogStyle>
        <DialogHeader id="alert-dialog-title">
          <div className="row">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginBottom: 20,
              }}
            >
              Bem vindo à Plataforma QYON!
            </div>
            <CloseIconStyle
              style={{ marginRight: "6px" }}
              onClick={() => {
                handleClose();
              }}
            />
          </div>
        </DialogHeader>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={6} style={{ fontSize: 15 }}>
              Aqui você encontra um ambiente centralizado que integra todos os
              seus sistemas e aplicativos QYON em um único lugar. Com uma
              interface intuitiva e organizada, a plataforma facilita a
              navegação e o acesso rápido às ferramentas necessárias para a
              gestão do seu negócio. <br />
              <br />
              Para acessar o produto Gestão Financeira,{" "}
              <b>
                clique no botão "Gestão Financeira" localizado no menu lateral à
                esquerda
              </b>
              .
            </Grid>
            <Grid item container xs={6} justifyContent="center">
              <img
                style={{ objectFit: "scale-down", maxHeight: 270 }}
                src={gfGif}
                alt="Clique em Gestão Financeira para acessar o sistema"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          style={{ paddingTop: 24, paddingBottom: 24 }}
        ></DialogActions>
      </DialogStyle>
    </Modal>
  );
};
