import { Chip } from "@material-ui/core";
import styled from "styled-components";

export const CustomChip = styled(Chip)`
  background: ${(props) => (props.isActive ? "#0036B3" : "#F0F1F3")};
  color: ${(props) => (props.isActive ? "#FBFBFD" : "#364461")};
  margin: 5px;
  cursor: pointer;
  margin: 0;
  ${(props) =>
    !props.isActive
      ? `&:hover {
    background: #e7eefe;
    color: #0036b3;
  }`
      : ""};
`;

export const TitleVideo = styled.p`
  color: #364461;
  font-size: 16px;
  font-weight: 700;
`;

export const DescriptionVideo = styled.div`
  color: #7e899e;
  font-size: 12px;
  font-weight: 400;
`;
