import { useContext, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Chip,
  Button,
  Grid,
} from "@material-ui/core";

import InstagramIcon from "@material-ui/icons/Instagram";
import FacebookIcon from "@material-ui/icons/Facebook";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import HelpIcon from "@material-ui/icons/Help";
import LocationOnIcon from "@material-ui/icons/LocationOn";

import useHomeController from "../controllers/HomeController";
import { HeaderDialog, TextLink, GridLink, ButtonHelper } from "../styles/Home";
import { HomeContext } from "../contexts/HomeContext";
import DialogHelperMap from "./DialogHelperMap";
import MaskService from "../../../utils/mask/maskService";

const DialogSocialMedia = ({ open, setOpen }) => {
  const {
    landTheme,
    facebook,
    setFacebook,
    instagram,
    setInstagram,
    linkedin,
    setLinkedin,
    whatsapp,
    setWhatsapp,
    googleMaps,
    setGoogleMaps,
  } = useContext(HomeContext);
  const { saveWebsiteChanges } = useHomeController();

  const handleClose = (save) => {
    if (save) {
      saveWebsiteChanges();
    }
    setOpen(false);
  };

  const [helperOpen, setHelperOpen] = useState(false);

  return (
    <>
      {helperOpen && (
        <DialogHelperMap open={helperOpen} setOpen={setHelperOpen} />
      )}
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={open}
        onClose={handleClose}
        aria-labelledby="dialog-social-media"
      >
        <HeaderDialog
          $backgroundColor={landTheme.color_pallete.primary}
          $color={landTheme.color_pallete.secondary}
        >
          <DialogTitle id="dialog-social-media">
            <Chip label="Mídias Sociais" />
          </DialogTitle>
        </HeaderDialog>
        <DialogContent>
          <GridLink container spacing={1} alignItems="flex-end">
            <Grid item>
              <InstagramIcon />
            </Grid>
            <Grid item xs>
              <TextLink
                value={instagram}
                label="Instagram"
                onChange={(e) => setInstagram(e.target.value)}
              />
            </Grid>
          </GridLink>
          <GridLink container spacing={1} alignItems="flex-end">
            <Grid item>
              <FacebookIcon />
            </Grid>
            <Grid item xs>
              <TextLink
                value={facebook}
                label="Facebook"
                onChange={(e) => setFacebook(e.target.value)}
              />
            </Grid>
          </GridLink>
          <GridLink container spacing={1} alignItems="flex-end">
            <Grid item>
              <LinkedInIcon />
            </Grid>
            <Grid item xs>
              <TextLink
                value={linkedin}
                label="LinkedIn"
                onChange={(e) => setLinkedin(e.target.value)}
              />
            </Grid>
          </GridLink>
          <GridLink container spacing={1} alignItems="flex-end">
            <Grid item>
              <WhatsAppIcon />
            </Grid>
            <Grid item xs>
              <TextLink
                value={whatsapp}
                label="Telefone WhatsApp"
                onChange={(e) =>
                  setWhatsapp(MaskService.toMask("cel-phone", e.target.value))
                }
              />
            </Grid>
          </GridLink>
          <GridLink container spacing={1} alignItems="flex-end">
            <Grid item>
              <LocationOnIcon />
            </Grid>
            <Grid item xs>
              <TextLink
                value={googleMaps}
                label="Url Mapa"
                onChange={(e) => setGoogleMaps(e.target.value)}
              />
            </Grid>
            <Grid item>
              <ButtonHelper onClick={() => setHelperOpen(true)}>
                <HelpIcon />
              </ButtonHelper>
            </Grid>
          </GridLink>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleClose(true)}
          >
            Salvar
          </Button>
          <Button variant="contained" onClick={() => handleClose(false)}>
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DialogSocialMedia;
