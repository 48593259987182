import React, { useContext, useEffect } from "react";
import { DefaultPageContainer } from "../../components/layoutContainer/styles/LayoutContainer";
import { GlobalSubheader } from "../../components/subHeader/GlobalSubheader";
import { GlobalContext } from "../../contexts/GlobalContext";

export const AppStore = () => {
  const { companyUser, setBreadcrumbs } = useContext(GlobalContext);

  useEffect(() => {
    try {
      setBreadcrumbs([]);

      renderComponent();
    } catch (e) {
      setTimeout(() => {
        renderComponent();
      }, 2000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderComponent = () => {
    if (companyUser) {
      const instance = window.QyonStore({
        cnpj: companyUser.document,
      });
      instance.render();
    }
  };

  return (
    <>
      <GlobalSubheader title={"Loja de Aplicativos"} />
      <DefaultPageContainer
        style={{
          height: "calc(100% - 60px)",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div id="qyon-store"></div>
      </DefaultPageContainer>
    </>
  );
};
