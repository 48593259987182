import {
  Chip,
  Collapse,
  IconButton,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  StepIcon,
  Tooltip,
} from "@material-ui/core";
import React from "react";
import { formatCNPJorCPF } from "../../certificate/util/Formatter";
import {
  Autorenew,
  KeyboardArrowDown,
  KeyboardArrowUp,
  Warning,
} from "@material-ui/icons";

import { PulseIcon } from "../../../styles/GlobalStyle";
import { DialogConfirm } from "../../customerManagements/children/DialogConfirm";
import Loading from "../../../components/basics/Loading";
import {
  getCanScheduleMigration,
  scheduleNewMigration,
} from "../../../repositories/portal_api/ESocial";
import { notify } from "../../../utils/notify";

function CustomStepIcon(props) {
  const { active, error, icon } = props;
  if (error) {
    return (
      <Warning
        style={{
          color: "#FA2D1E",
        }}
      ></Warning>
    );
  }

  if (icon === 6) {
    return <StepIcon icon={icon} completed={true} active={active} />;
  }

  return (
    <PulseIcon active={active} style={{ margin: 0 }}>
      <StepIcon {...props} />
    </PulseIcon>
  );
}

const ESocialTable = ({
  rows,
  lastMigrationsByDocument,
  onRescheduleMigration,
  ...props
}) => {
  const [expandedRow, setExpandedRow] = React.useState(null);
  const [openConfirmModal, setOpenConfirmModal] = React.useState(false);
  const [actualRow, setActualRow] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const migrationStatusesFolha = [
    {
      name: null,
      value: "Não Iniciado",
      backgorundColor: "#ECEFF1",
    },
    {
      name: "PENDING",
      value: "Processando",
      backgorundColor: "#FFF3E0",
      color: "#F57C00",
    },
    {
      name: "ERROR",
      value: "Erro",
      backgorundColor: "#FFEAE8",
      color: "#FA2D1E",
    },
    {
      name: "SUCCESS",
      value: "Concluido",
      backgorundColor: "#E8F5E9",
      color: "#2E7D32",
    },
  ];

  const migrationStatusesRpa = [
    {
      name: null,
      value: "Não Iniciado",
      backgorundColor: "#ECEFF1",
    },
    {
      name: "PENDING",
      value: "Solicitada",
      backgorundColor: "#FFF3E0",
      color: "#F57C00",
    },
    {
      name: "STARTED",
      value: "Iniciada",
      backgorundColor: "#FFF3E0",
      color: "#F57C00",
    },
    {
      name: "WAITING FILES",
      value: "Aguardando Arquivos",
      backgorundColor: "#FFF3E0",
      color: "#F57C00",
    },
    {
      name: "ERROR",
      value: "Erro",
      backgorundColor: "#FFEAE8",
      color: "#FA2D1E",
    },
    {
      name: "SUCCESS",
      value: "Concluida",
      backgorundColor: "#E8F5E9",
      color: "#2E7D32",
    },
  ];

  const steps = [
    {
      name: "Migração do eSocial na Fila de Processamento",
      content:
        "A Migração do eSocial desta empresa está na fila de processamento e será iniciada em breve.",
    },
    {
      name: "Migração do eSocial em Processamento",
      content: "A Migração do eSocial desta empresa está em processamento.",
    },
    {
      name: "Aguardando Arquivos do eSocial",
      content:
        "Estamos aguardando a liberação dos arquivos desta empresa na plataforma do eSocial para continuar o processo.",
    },
    {
      name: "Migração do eSocial Concluída",
      content:
        "A migração dos dados do eSocial desta empresa foi concluída com sucesso, em breve o processamento dos arquivos será iniciado no Folha de Pagamento.",
    },
    {
      name: "Processamento Iniciado no Folha de Pagamento",
      content:
        "Os arquivos do eSocial desta empresa estão sendo processados no Folha de Pagamento.",
    },
    {
      name: "Processamento Concluído no Folha de Pagamento",
      content:
        "Os arquivos do eSocial desta empresa foram processados com sucesso no Folha de Pagamento.",
    },
  ];

  const getActiveStep = (row) => {
    if (row?.status_migration_folha) {
      switch (row?.status_migration_folha) {
        case "PENDING":
          return { name: "Processamento Iniciado no Folha de Pagamento" };

        case "SUCCESS":
          return { name: "Processamento Concluído no Folha de Pagamento" };

        case "ERROR":
          return {
            name: "Processamento Concluído no Folha de Pagamento",
            error: true,
            errorMessage: row?.message_error,
            errorInstruction: "",
          };

        default:
          return row?.status_migration_folha;
      }
    }

    if (row?.status_migration_rpa) {
      switch (row?.status_migration_rpa) {
        case "PENDING":
          return { name: "Migração do eSocial na Fila de Processamento" };

        case "STARTED":
          return { name: "Migração do eSocial em Processamento" };

        case "WAITING FILES":
          return {
            name: "Aguardando Arquivos do eSocial",
          };

        case "SUCCESS":
          return { name: "Migração do eSocial Concluída" };

        case "ERROR":
          return {
            name: "Migração do eSocial Concluída",
            error: true,
            errorMessage: row?.message_error,
            errorInstruction: "",
          };

        default:
          return row?.status_migration;
      }
    }
  };

  const isOlderThan7Days = (date) => {
    const now = new Date();
    const createdAt = new Date(date);
    const diffTime = Math.abs(now - createdAt);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    return diffDays >= 7;
  };

  const handleDisableScheduleMigration = (row) => {
    if (lastMigrationsByDocument) {
      if (lastMigrationsByDocument[row?.document]?.id !== row.id) {
        return {
          disableButton: true,
          message: "Solicitação de Migração mais recente já realizada",
        };
      }
    }

    if (!row.is_client) {
      return {
        disableButton: true,
        message: "Empresa não encontrada no Gestão de Clientes",
      };
    }

    if (
      row?.status_migration_rpa === "ERROR" ||
      row?.status_migration_folha === "ERROR"
    ) {
      return { disableButton: false };
    }

    if (isOlderThan7Days(row.created_at)) {
      return { disableButton: false };
    }

    return {
      disableButton: true,
      message: "Solicitação de Migração deve ter no mínimo 7 dias",
    };
  };

  const handleScheduleMigration = async (row) => {
    setLoading(true);

    try {
      const response = await getCanScheduleMigration(row.document, row.id);
      let canContinueWithMigration = true;

      if (response.success) {
        if (response.data.can_schedule_migration) {
          setActualRow(row);
          setOpenConfirmModal(true);
        } else if (response?.data?.reason) {
          notify(response?.data?.reason);
          canContinueWithMigration = false;
        } else {
          notify("Não foi possível solicitar a migração");
          canContinueWithMigration = false;
        }
      } else {
        canContinueWithMigration = false;
      }

      if (canContinueWithMigration) {
        setActualRow(row);
        setOpenConfirmModal(true);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
      notify("Não foi possível solicitar a migração");
    }
  };

  const handleMigrateConfirm = async () => {
    setLoading(true);

    try {
      const data = {
        document: actualRow.document,
        parent_document: actualRow.accountant_document,
        procuration: actualRow.is_procuration,
        certificate_document: actualRow.certificate_document,
      };

      const response = await scheduleNewMigration(data);

      if (response?.success) {
        notify(
          "Migração solicitada com sucesso, em breve você poderá acompanhar o andamento da migração",
          true,
          "success"
        );
        setOpenConfirmModal(false);
        setActualRow(null);

        onRescheduleMigration(actualRow);
      } else {
        notify("Não foi possível solicitar a migração");
      }

      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
      notify("Não foi possível solicitar a migração");
    }
  };

  return (
    <>
      <Loading open={loading} />
      <DialogConfirm
        open={openConfirmModal}
        title="Solicitar Migração"
        description={
          <>
            Deseja realmente solicitar uma nova migração para a empresa{" "}
            <b> {actualRow?.company_name}</b>?
          </>
        }
        handleClose={() => {
          setOpenConfirmModal(false);
          setActualRow(null);
        }}
        handleConfirm={() => {
          handleMigrateConfirm();
        }}
      />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell width="5%" size="small"></TableCell>
            <TableCell variant="head" width="25%" size="small">
              Empresa
            </TableCell>
            <TableCell variant="head" width="20%" size="small">
              CPF/CNPJ
            </TableCell>
            <TableCell variant="head" width="9%" size="small">
              Início
            </TableCell>
            <TableCell variant="head" width="18%" align="center" size="small">
              Migração eSocial
            </TableCell>
            <TableCell variant="head" width="18%" align="center" size="small">
              Processamento Folha
            </TableCell>
            <TableCell width="5%" size="small"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.length > 0 ? (
            <>
              {rows.map((row) => {
                const formatetdDocument = formatCNPJorCPF(row.document);

                const formattedDate = new Date(
                  row.created_at
                ).toLocaleDateString();

                const statusRpa = migrationStatusesRpa.find(
                  (status) => status.name === row?.status_migration_rpa
                );

                const statusFolha = migrationStatusesFolha.find(
                  (status) => status.name === row?.status_migration_folha
                );

                const activeStep = getActiveStep(row);

                let activeStepNumber = 0;

                steps.forEach((step, index) => {
                  if (step.name === activeStep.name) {
                    activeStepNumber = index;
                  }
                });

                const disabledScheduleMigration =
                  handleDisableScheduleMigration(row);

                return (
                  <>
                    <TableRow key={row.id} size="small">
                      <TableCell width="5%" size="small">
                        <IconButton
                          onClick={() => {
                            if (expandedRow === row.id) {
                              setExpandedRow(null);
                            } else {
                              setExpandedRow(row.id);
                            }
                          }}
                        >
                          {row.id === expandedRow ? (
                            <KeyboardArrowUp />
                          ) : (
                            <KeyboardArrowDown />
                          )}
                        </IconButton>
                      </TableCell>
                      <TableCell
                        style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                        size="small"
                      >
                        {row.company_name}
                      </TableCell>
                      <TableCell width={150} size="small">
                        {formatetdDocument}
                      </TableCell>
                      <TableCell size="small">{formattedDate}</TableCell>
                      <TableCell size="small" align="center">
                        <Chip
                          label={statusRpa?.value}
                          onClick={() => {
                            if (expandedRow === row.id) {
                              setExpandedRow(null);
                            } else {
                              setExpandedRow(row.id);
                            }
                          }}
                          style={{
                            background: statusRpa?.backgorundColor,
                            color: statusRpa?.color,
                          }}
                        />
                      </TableCell>
                      <TableCell size="small" align="center">
                        <Chip
                          label={statusFolha?.value}
                          onClick={() => {
                            if (expandedRow === row.id) {
                              setExpandedRow(null);
                            } else {
                              setExpandedRow(row.id);
                            }
                          }}
                          style={{
                            background: statusFolha?.backgorundColor,
                            color: statusFolha?.color,
                          }}
                        />
                      </TableCell>
                      <TableCell size="small">
                        <Tooltip
                          title={
                            disabledScheduleMigration?.message ||
                            "Solicitar nova Migração"
                          }
                        >
                          <div>
                            <IconButton
                              size="small"
                              disabled={disabledScheduleMigration.disableButton}
                              onClick={() => {
                                handleScheduleMigration(row);
                              }}
                            >
                              <Autorenew
                                fill={
                                  disabledScheduleMigration.disableButton
                                    ? "#cccccc"
                                    : "#616161"
                                }
                              />
                            </IconButton>
                          </div>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                    {/* {expandedRow === row.id && ( */}
                    <TableRow>
                      <TableCell
                        colSpan={12}
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                      >
                        <Collapse in={row.id === expandedRow}>
                          <Stepper
                            activeStep={activeStepNumber}
                            orientation="vertical"
                          >
                            {steps.map((step) => (
                              <Step key={step.name} title={step.name}>
                                {activeStep.name === step.name ? (
                                  <StepLabel
                                    error={activeStep?.error}
                                    StepIconComponent={CustomStepIcon}
                                  >
                                    {step.name}{" "}
                                    {activeStep?.error && " com Erro"}
                                  </StepLabel>
                                ) : (
                                  <StepLabel>{step.name}</StepLabel>
                                )}

                                {activeStep?.error ? (
                                  <StepContent style={{ color: "FA2D1E" }}>
                                    {activeStep.errorMessage}
                                  </StepContent>
                                ) : (
                                  <StepContent>{step.content}</StepContent>
                                )}
                              </Step>
                            ))}
                          </Stepper>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                    {/* )} */}
                  </>
                );
              })}
            </>
          ) : (
            <TableRow>
              <TableCell colSpan={12} style={{ textAlign: "center" }}>
                Nenhuma Migração Encontrada
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </>
  );
};

export default ESocialTable;
