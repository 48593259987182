import { Grid } from "@material-ui/core";
import React from "react";
import ViewedIcon from "../../../components/icons/ViewedIcon";

const CertificateActionItem = ({ item, ...props }) => {
  const firstList = () => {
    const subItemsLength = item.subItems.length;
    let firstColumn = [];
    let secondColumn = [];

    if (subItemsLength > 5) {
      firstColumn = item.subItems.slice(0, 5);
      secondColumn = item.subItems.slice(5, subItemsLength);
    } else {
      firstColumn = item.subItems;
    }

    return (
      <Grid container style={{ paddingLeft: 24 }}>
        <Grid item xs={6}>
          <ul>
            {firstColumn.map((subItem) => (
              <li>{subItem.title}</li>
            ))}
          </ul>
        </Grid>
        <Grid item xs={6}>
          <ul>
            {secondColumn.map((subItem) => (
              <li>{subItem.title}</li>
            ))}
          </ul>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid
      item
      xs={12}
      style={{
        background: "#EEEEEE80",
        borderLeft: "2px solid #0A73DB",
        padding: 8,
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "5px",
        }}
      >
        <div style={{ fontSize: 14, fontWeight: 600 }}>{item.title}</div>
        <ViewedIcon />
      </div>

      {item?.subItems && item.type === "ROW" ? (
        <Grid container>
          <ul
            style={{ width: "100%", display: "flex", gap: 35, paddingLeft: 24 }}
          >
            {item.subItems.map((subItem) => (
              <Grid item>
                <li>{subItem.title}</li>
              </Grid>
            ))}
          </ul>
        </Grid>
      ) : item?.subItems && item.type === "COLUMN" ? (
        firstList()
      ) : (
        <></>
      )}
    </Grid>
  );
};

export default CertificateActionItem;
