import React, { createContext, useState } from "react";

const DocumentGuideContext = createContext();

const DocumentGuideContextProvider = ({ children }) => {
  const [showFavorites, setShowFavorites] = useState(false);
  const [clientList, setClientList] = useState([]);
  const [documentList, setDocumentList] = useState([]);
  const [totalDocs, setTotalDocs] = useState(0);
  const [searchField, setSearchField] = useState("");
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const [clickedRow, setClickedRow] = useState({});
  const [pdf, setPdf] = useState("");
  const [openModalSendMail, setOpenModalSendMail] = useState(false);
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [chipValue, setChipValue] = useState("");
  const [tabValue, setTabValue] = useState("");
  const [openModalPreviewDoc, setOpenModalPreviewDoc] = useState(false);
  const [searchClientList, setSearchClientList] = useState("");
  const [isLoadingDocuments, setIsLoadingDocuments] = useState(false);
  const [multipleEmails, setMultipleEmails] = useState("");
  const [multipleEmailsList, setMultipleEmailsList] = useState([]);
  const [isLoadingModalSendEmail, setIsLoadingModalSendEmail] = useState(false);
  const [openModalDocumentHistory, setOpenModalDocumentHistory] =
    useState(false);
  const [loadingDocumentHistory, setLoadingDocumentHistory] = useState(false);
  const [documentHistoryList, setDocumentHistoryList] = useState([]);

  const [currentDocumentInboxStatusId, setCurrentDocumentInboxStatusId] =
    useState(1);

  const [openModalArchive, setOpenModalArchive] = useState(false);

  return (
    <DocumentGuideContext.Provider
      value={{
        showFavorites,
        setShowFavorites,
        clientList,
        setClientList,
        documentList,
        setDocumentList,
        totalDocs,
        setTotalDocs,
        searchField,
        setSearchField,
        page,
        setPage,
        pageSize,
        setPageSize,
        clickedRow,
        setClickedRow,
        pdf,
        setPdf,
        openModalSendMail,
        setOpenModalSendMail,
        selectedStartDate,
        setSelectedStartDate,
        selectedEndDate,
        setSelectedEndDate,
        chipValue,
        setChipValue,
        tabValue,
        setTabValue,
        openModalPreviewDoc,
        setOpenModalPreviewDoc,
        searchClientList,
        setSearchClientList,
        isLoadingDocuments,
        setIsLoadingDocuments,
        multipleEmails,
        setMultipleEmails,
        multipleEmailsList,
        setMultipleEmailsList,
        isLoadingModalSendEmail,
        setIsLoadingModalSendEmail,
        openModalDocumentHistory,
        setOpenModalDocumentHistory,
        loadingDocumentHistory,
        setLoadingDocumentHistory,
        documentHistoryList,
        setDocumentHistoryList,
        currentDocumentInboxStatusId,
        setCurrentDocumentInboxStatusId,
        openModalArchive,
        setOpenModalArchive,
      }}
    >
      {children}
    </DocumentGuideContext.Provider>
  );
};

export { DocumentGuideContext, DocumentGuideContextProvider };
