import { Switch } from "react-router-dom";
import { GlobalVariables } from "../../GlobalVariables";
import AvaliationAttendance from "../../pages/attendance/Avaliation";
import EditAttendance from "../../pages/attendance/EditAttendance";
import EditTicketWrapper from "../../pages/attendance/EditTicket";
import InsertAttendance from "../../pages/attendance/InsertAttendance";
import ReportAttendance from "../../pages/attendance/ReportAttendance";
import SearchAttendance from "../../pages/attendance/SearchAttendance";
import MiddlewareRoute from "../Middleware";

export default function Attendances() {
  return (
    <>
      <Switch>
        <MiddlewareRoute
          routePermissionId={GlobalVariables.PERMISSIONS.ATTENDANCE}
          path="/attendance/search"
          component={SearchAttendance}
          sidebar
        />
        <MiddlewareRoute
          routePermissionId={GlobalVariables.PERMISSIONS.ATTENDANCE}
          path="/attendance/insert"
          component={InsertAttendance}
          sidebar
        />
        <MiddlewareRoute
          routePermissionId={GlobalVariables.PERMISSIONS.ATTENDANCE}
          path="/attendance/report"
          component={ReportAttendance}
          sidebar
        />
        <MiddlewareRoute
          routePermissionId={GlobalVariables.PERMISSIONS.ATTENDANCE}
          path="/attendance/edit/:attendanceId?"
          component={EditAttendance}
          sidebar
        />
        <MiddlewareRoute
          routePermissionId={GlobalVariables.PERMISSIONS.ATTENDANCE}
          path="/attendance/avaliation/:attendanceId?"
          component={AvaliationAttendance}
          sidebar
        />
        <MiddlewareRoute
          routePermissionId={GlobalVariables.PERMISSIONS.ATTENDANCE}
          path="/ticket/edit/:ticketId?"
          component={EditTicketWrapper}
          sidebar
        />
      </Switch>
    </>
  );
}
