export default function useChatAssistantController() {
  const AumentarChat = (valor) => {
    document.getElementById("chatGeiko").style.display = "block";
    const GetXMLHttp = () => {
      if (navigator.appName === "Microsoft Internet Explorer") {
        return new window.ActiveXObject("Microsoft.XMLHTTP");
      } else {
        return new XMLHttpRequest();
      }
    };

    var mod = GetXMLHttp();
    mod.open(
      "GET",
      "https://atendimento.geiko.com.br/chat/01/status_chat_newChat.asp?cdE=1660&s=" +
        valor +
        "&empN=QYON",
      !0
    );
    mod.onreadystatechange = function () {
      if (mod.readyState === 4) {
        document
          .getElementById("chatGeiko")
          .classList.add("topcornerGeikoActive");
        document.getElementById("chatGeiko").innerHTML = mod.responseText;
        if (mod.responseText.indexOf("chatAbtGeiko") !== -1) {
          document.getElementById(
            "divChat"
          ).innerHTML = `<iframe id='IframeChatNovo' class='IframeChatNovo' src='https://atendimento.geiko.com.br/chat/01/index_siteOnFloat.asp?s=1&PsiteOnFloat=s&Ppro=s&Pcor=0097df&cdE=1660&username=${localStorage.firstNamePanel} ${localStorage.lastNamePanel}&email=${localStorage.emailPanel}' marginheight='0' frameborder='0' marginwidth='0' border='0'></iframe>`;
        } else {
          Captcha();
        }
      }
    };
    mod.send(null);
  };

  const Captcha = () => {
    var alpha = [
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
    ];
    var i;
    for (i = 0; i < 6; i++) {
      var a = alpha[Math.floor(Math.random() * alpha.length)];
      var b = alpha[Math.floor(Math.random() * alpha.length)];
      var c = alpha[Math.floor(Math.random() * alpha.length)];
      var d = alpha[Math.floor(Math.random() * alpha.length)];
      var e = alpha[Math.floor(Math.random() * alpha.length)];
      var f = alpha[Math.floor(Math.random() * alpha.length)];
      var g = alpha[Math.floor(Math.random() * alpha.length)];
    }
    //INFO ADDED ONE SPACE BETWEEN B AND C TO REPLACE (b +" " + " " + c)
    var code = a + " " + b + "  " + c + " " + d + " " + e + " " + f + " " + g;
    document.getElementById("mainCaptcha").innerHTML = code;
    document.getElementById("mainCaptcha").value = code;
  };

  return { AumentarChat };
}
