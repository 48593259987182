import React, { useState } from "react";

import { CircularProgress, IconButton, Tab, Tooltip } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import { CustomBillTabPanel, CustomBillTabs } from "./styles/ClientBillModal";
import { useEffect } from "react";
import { getMP3Audio } from "../../../repositories/AudioHandler";
import CloseIcon from "@material-ui/icons/Close";
import {
  BodyStyle,
  BorderlessBodyStyle,
  ButtonCancel,
  ButtonSave,
  DialogHeader,
  DialogStyle,
} from "../../../components/modal/Modal";
import { TabContext } from "@material-ui/lab";
import SubdirectoryArrowLeftIcon from "@material-ui/icons/SubdirectoryArrowLeft";
import { notify } from "../../../utils/notify";

export const ClientBillModal = ({ openModal, closeModal, data }) => {
  const {
    loading,
    dueDate,
    base64,
    name,
    total,
    emittedDate,
    enableBoleto,
    typeableLine,
  } = data;

  const [tab, setTab] = useState(0);

  useEffect(() => {
    if (loading === false) {
      const message = base64
        ? "Aqui está seu boleto."
        : "Não foi possível encontrar o boleto.";

      getMP3Audio(message).then((result) => {
        var snd = new Audio("data:audio/wav;base64," + result);
        snd.play();
      });
    }
  }, [loading, base64]);

  const handleOpenBoleto = () => {
    if (enableBoleto) {
      const downloadLink = document.createElement("a");

      downloadLink.href = `data:application/pdf;base64,${base64}`;
      downloadLink.download = name;
      downloadLink.click();
    }
  };

  return (
    <Modal
      open={openModal}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <DialogStyle style={{ width: 700 }}>
        {loading ? (
          <BorderlessBodyStyle
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: 250,
              margin: 0,
            }}
          >
            <CircularProgress color="white" size={80} />
          </BorderlessBodyStyle>
        ) : (
          <>
            <DialogHeader>
              <div className="row">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginRight: 20,
                    marginBottom: 10,
                  }}
                >
                  {base64
                    ? "Detalhes do Seu Boleto"
                    : "Não foi possível encontrar o boleto"}
                </div>

                <CloseIcon
                  style={{
                    position: "absolute",
                    right: 0,
                    cursor: "pointer",
                    color: "rgba(126, 137, 158, 1)",
                  }}
                  onClick={closeModal}
                />
              </div>
            </DialogHeader>

            <BorderlessBodyStyle>
              <TabContext value={tab}>
                <CustomBillTabs
                  variant="fullWidth"
                  value={tab}
                  onChange={(e, index) => {
                    setTab(index);
                  }}
                  textColor="primary"
                  style={{
                    borderBottom: "solid 1px rgba(0, 0, 0, 0.54)",
                    textTransform: "none",
                  }}
                >
                  <Tab
                    label="Detalhes"
                    value={0}
                    style={{ textTransform: "none" }}
                  />

                  {base64 && (
                    <Tab
                      label="Visualizar"
                      value={2}
                      style={{ textTransform: "none" }}
                    />
                  )}
                </CustomBillTabs>

                <CustomBillTabPanel value={0}>
                  <BodyStyle
                    style={{
                      marginTop: 15,
                      overflow: "hidden",
                      marginBottom: 0,
                    }}
                  >
                    {total && (
                      <Typography>
                        <strong>Valor: </strong>

                        {Intl.NumberFormat("pt-br", {
                          style: "currency",
                          currency: "BRL",
                        }).format(total)}
                      </Typography>
                    )}

                    <Typography>
                      <strong>Data de vencimento: </strong>
                      {moment(dueDate).format("DD/MM/YYYY")}
                    </Typography>

                    {emittedDate && (
                      <Typography>
                        <strong>Data da Emissão: </strong>
                        {moment(emittedDate).format("DD/MM/YYYY")}
                      </Typography>
                    )}

                    {typeableLine && (
                      <Typography>
                        <strong>Linha Digitável: </strong>
                        {typeableLine}
                        <Tooltip title="Copiar Linha Digitável">
                          <IconButton
                            size="small"
                            onClick={() => {
                              navigator.clipboard.writeText(typeableLine);
                              notify(
                                "Linha digitável copiada para área de transferência!",
                                true,
                                "success"
                              );
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              fill="none"
                              viewBox="0 0 24 25"
                            >
                              <path
                                fill="#364461"
                                fill-rule="evenodd"
                                d="M7.5 3.043a.5.5 0 0 0-.5.5v1.5h9.5a2.5 2.5 0 0 1 2.5 2.5v9.5h1.5a.5.5 0 0 0 .5-.5v-13a.5.5 0 0 0-.5-.5h-13Zm11.5 16h1.5a2.5 2.5 0 0 0 2.5-2.5v-13a2.5 2.5 0 0 0-2.5-2.5h-13a2.5 2.5 0 0 0-2.5 2.5v1.5H3.5a2.5 2.5 0 0 0-2.5 2.5v13a2.5 2.5 0 0 0 2.5 2.5h13a2.5 2.5 0 0 0 2.5-2.5v-1.5Zm-15.5-12a.5.5 0 0 0-.5.5v13a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-13a.5.5 0 0 0-.5-.5h-13Z"
                                clip-rule="evenodd"
                              />
                            </svg>
                          </IconButton>
                        </Tooltip>
                      </Typography>
                    )}
                  </BodyStyle>
                </CustomBillTabPanel>

                <CustomBillTabPanel value={2}>
                  <iframe
                    title={`client_bill`}
                    src={
                      "data:application/pdf;base64," +
                      base64 +
                      "#toolbar=0&navpanes=0#zoom=10?id=" +
                      Math.random()
                    }
                    style={{
                      marginTop: 15,
                      width: "100%",
                      border: "none",
                      height: 400,
                      overflow: "hidden",
                      borderRadius: 8,
                    }}
                  />
                </CustomBillTabPanel>
              </TabContext>
              <Typography
                variant="body1"
                color="#364461"
                style={{ marginTop: 15 }}
              >
                Caso tenha dificuldades para baixar o boleto, por favor, envie
                uma mensagem ao nosso departamento financeiro. Se preferir,
                entre em contato pelo e-mail: financeiro@qyon.com
              </Typography>
            </BorderlessBodyStyle>

            <div
              style={{
                display: "flex",
                justifyContent: "end",
                marginTop: 20,
                marginBottom: 20,
                marginRight: 20,
              }}
            >
              <ButtonCancel
                style={{ marginRight: 16 }}
                onClick={closeModal}
                startIcon={<SubdirectoryArrowLeftIcon />}
              >
                Fechar
              </ButtonCancel>
              &nbsp;
              <ButtonSave
                onClick={handleOpenBoleto}
                disabled={!enableBoleto && base64}
                style={{ cursor: "" }}
              >
                {loading
                  ? "Carregando..."
                  : enableBoleto
                  ? "Download do Boleto"
                  : "Boleto não registrado"}
              </ButtonSave>
            </div>
          </>
        )}
      </DialogStyle>
    </Modal>
  );
};
