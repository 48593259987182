import React from "react";
import { useEffect } from "react";

import Modal from "@material-ui/core/Modal";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";

const dialogStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  height: 450,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 10,
};

export const BotPanelModal = (props) => {
  useEffect(() => {}, []);

  return (
    <Modal
      open={props.openModal}
      onClose={() => {
        props.closeBotPanelVideo();
      }}
    >
      <Box sx={dialogStyle}>
        <Typography
          variant="h6"
          component="h2"
          style={{
            fontWeight: "bold",
            background: "rgb(19, 142, 247)",
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
            padding: 20,
            color: "white",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              position: "relative",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              Painel do cliente
            </div>
            <CloseIcon
              style={{ position: "absolute", right: 0, cursor: "pointer" }}
              onClick={() => {
                props.closeBotPanelVideo();
              }}
            />
          </div>
        </Typography>

        <div>
          <iframe
            width="100%"
            height="400"
            src="https://www.youtube.com/embed/3dvnanqumRg"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </Box>
    </Modal>
  );
};
