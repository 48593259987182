import { search } from "./BaseRepositoryComponents";

const BOT_API = process.env.REACT_APP_BOT_API;
const path = BOT_API + "/v1/bot";

export const migrationCompany = async (cnpj) => {
  let uploadJson = {
    cnpj: cnpj,
    parentCnpj:
      localStorage.getItem("isAccountantPanel") === "true"
        ? localStorage.getItem("documentPanel")
        : localStorage.getItem("accountantCnpjPanel"),
    sub: localStorage.getItem("subCognitoPanel"),
  };

  return search({
    path: path + "/rpa/esocial",
    method: "POST",
    myHeaders: new Headers({
      Authorization: "c799c146-6264-4bc6-94d7-56b4cac8deb0",
      "Content-Type": "application/json",
    }),
    myBody: uploadJson,
  });
};
