import React, { useContext, useEffect } from "react";
import { GlobalContext } from "../../contexts/GlobalContext";
import { GlobalSubheader } from "../../components/subHeader/GlobalSubheader";
import {
  CircularProgress,
  Grid,
  InputAdornment,
  TablePagination,
  TextField,
} from "@material-ui/core";
import { DefaultPageContainer } from "../../components/layoutContainer/styles/LayoutContainer";
import { getEsocialMigrationsByAccountantDocument } from "../../repositories/portal_api/ESocial";
import { getAccountantClients } from "../../repositories/portal_api/AccountantClient";
import { formatCNPJorCPF } from "../certificate/util/Formatter";
import esocialLogo from "../../assets/images/esocial_logo.png";
import ESocialTable from "./components/ESocialTable";
import { SearchLabel } from "../customerManagements/styles/CustomerManagementStyle";
import useDebounce from "../../utils/debouncer";
import { SearchIcon } from "../../components/icons/SearchIcon";

const ESocialMigrations = ({ location }) => {
  const { companyUser } = useContext(GlobalContext);
  const [loading, setLoading] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const PAGE_SIZE = 10;
  const [listMigrations, setListMigrations] = React.useState([]);
  const [totalItems, setTotalItems] = React.useState(0);
  const [lastMigrationsByDocument, setLastMigrationsByDocument] =
    React.useState({});

  const [searchText, setSearchText] = React.useState("");

  const debounceSearchText = useDebounce(searchText, 500);

  useEffect(() => {
    getAccountantMigrations(debounceSearchText.replace(/\D/g, ""));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceSearchText]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    getAccountantMigrations();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const handleRescheduleMigration = (row) => {
    const data = {
      id: "new_" + row.id,
      document: row.document,
      company_name: row.company_name,
      status_migration_rpa: "PENDING",
      status_migration_folha: null,
      created_at: new Date().toISOString(),
    };

    if (listMigrations.length === 10) {
      let newList = [...listMigrations];
      newList.pop();
      newList = [data, ...newList];

      setListMigrations(newList);
    } else {
      setListMigrations([data, ...listMigrations]);
    }

    let newLastMigrations = {
      ...lastMigrationsByDocument,
      [row.document]: data,
    };

    setLastMigrationsByDocument(newLastMigrations);
  };

  const getAccountantMigrations = async (documentFilter) => {
    setLoading(true);
    const response = await getEsocialMigrationsByAccountantDocument(
      companyUser?.document,
      page + 1,
      PAGE_SIZE,
      documentFilter
    );

    if (response?.success) {
      let migrations = response?.data?.migrations;

      setTotalItems(response?.data?.total_count);
      const responseClients = await getAccountantClients();

      if (responseClients?.success) {
        const clients = responseClients?.data;

        let lastMigrations = { ...lastMigrationsByDocument };

        migrations = migrations.map((migration) => {
          const client = clients.find(
            (client) => client.document === migration.document
          );

          const accountantMigrations = {
            ...migration,
            company_name:
              client?.company_name || formatCNPJorCPF(migration?.document),
            is_client: client?.company_name ? true : false,
          };

          if (!lastMigrations[migration.document]) {
            lastMigrations = {
              ...lastMigrations,
              [migration.document]: accountantMigrations,
            };
          }

          return accountantMigrations;
        });

        setLastMigrationsByDocument(lastMigrations);
      }

      setListMigrations(migrations);
    }
    setLoading(false);
  };

  return (
    <>
      <GlobalSubheader
        title="Migrações eSocial"
        titleLocation="/esocial/migrations"
      ></GlobalSubheader>

      <DefaultPageContainer>
        <div
          style={{
            background: "#fff",
            border: "1px solid #E0E0E0",
            borderRadius: 15,
            padding: 16,
          }}
        >
          <Grid container xs={12}>
            <Grid item xs={6}>
              <h2 style={{ marginTop: "16px" }}>Migrações eSocial</h2>

              <p>
                Acompanhe o status das migrações de eSocial dos seus clientes.
              </p>
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                paddingRight: 16,
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "end",
                  justifyContent: "flex-end",
                  height: "100%",
                }}
              >
                <img
                  src={esocialLogo}
                  alt="eSocial"
                  style={{ width: "100%", maxWidth: "100px" }}
                />
              </div>
            </Grid>
          </Grid>
          <Grid container xs={12} style={{ marginTop: "16px" }} spacing={2}>
            <Grid item xs={12}>
              <SearchLabel>Pesquisar</SearchLabel>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                placeholder="Pesquisar por CPF/CNPJ"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              {loading ? (
                <div
                  style={{
                    minHeight: "80%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress />
                </div>
              ) : (
                <>
                  <ESocialTable
                    rows={listMigrations}
                    lastMigrationsByDocument={lastMigrationsByDocument}
                    onRescheduleMigration={handleRescheduleMigration}
                  ></ESocialTable>
                  <TablePagination
                    rowsPerPageOptions={[PAGE_SIZE]}
                    component="div"
                    count={totalItems}
                    rowsPerPage={PAGE_SIZE}
                    page={page}
                    onPageChange={handleChangePage}
                  ></TablePagination>
                </>
              )}
            </Grid>
          </Grid>
        </div>
      </DefaultPageContainer>
    </>
  );
};

export default ESocialMigrations;
