import React from "react";

export const TooltipShortcutBody = ({ title, shortcut, ...props }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        fontSize: 13,
        gap: 4,
      }}
    >
      <div>{title}</div>
      <div>({shortcut})</div>
    </div>
  );
};
