import {
  FormControlLabel,
  Grid,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
} from "@material-ui/core";
import React, { useContext } from "react";
import { KnowledgeBaseContext } from "../context/KnowledgeBaseContext";
import { CustomChip } from "../styles/KnowledgeBase";
import { SearchLabel } from "../../customerManagements/styles/CustomerManagementStyle";
import { SearchIcon } from "../../../components/icons/SearchIcon";
import PdfKnowledgeBaseIcon from "../../../components/icons/PdfKnowledgeBaseIcon";
import VideoKnowledgeBaseIcon from "../../../components/icons/VideoKnowledgeBaseIcon";

const FilterTypeContent = ({ listTotalVideos, ...props }) => {
  const {
    filterContentType,
    setFilterContentType,
    filterSearch,
    setFilterSearch,
    filterContentTypeFile,
    setFilterContentTypeFile,
  } = useContext(KnowledgeBaseContext);

  const filterOptions = [
    {
      key: "all",
      value: "Todos",
    },
    // {
    //   key: "payroll",
    //   value: "Folha de Pagamento",
    // },
    // {
    //   key: "fiscal",
    //   value: "Escrita Fiscal",
    // },
    // {
    //   key: "accounting",
    //   value: "Contabilidade",
    // },
    {
      key: "xml",
      value: "XML",
    },
  ];

  const handleChangeFilter = (textValue, tagValue, typeFileFilter) => {
    setFilterContentType(tagValue);
    setFilterSearch(textValue);
    setFilterContentTypeFile(typeFileFilter);
  };

  return (
    <>
      <Grid container direction="row" spacing={1} alignItems="flex-end">
        <Grid item xs={8}>
          <SearchLabel>Pesquisar</SearchLabel>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            placeholder="Pesquisar por nome"
            value={filterSearch}
            onChange={(e) =>
              handleChangeFilter(
                e.target.value,
                filterContentType,
                filterContentTypeFile
              )
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xs={4}>
          <SearchLabel>Tipo de Arquivo</SearchLabel>
          <RadioGroup
            style={{ display: "flex", flexDirection: "row" }}
            value={filterContentTypeFile}
            onChange={(event) => {
              handleChangeFilter(
                filterSearch,
                filterContentType,
                event.target.value
              );
            }}
          >
            <FormControlLabel
              value="all"
              control={<Radio size="small" color="primary" />}
              label={<div style={{ fontSize: 12 }}> Todos</div>}
            />
            <FormControlLabel
              value="pdf"
              control={<Radio act size="small" color="primary" />}
              label={<PdfKnowledgeBaseIcon />}
            />
            <FormControlLabel
              value="mp4"
              control={<Radio size="small" color="primary" />}
              label={<VideoKnowledgeBaseIcon />}
            />
          </RadioGroup>
        </Grid>
      </Grid>

      <Grid item container xs={12} style={{ gap: 8, marginTop: 16 }}>
        {filterOptions.map((item) => {
          let totalVideos = listTotalVideos.filter(
            (video) => video.type === item.key || item.key === "all"
          ).length;

          return (
            <CustomChip
              key={item.key}
              label={item.value + ` (${totalVideos})`}
              onClick={() =>
                handleChangeFilter(
                  filterSearch,
                  item.key,
                  filterContentTypeFile
                )
              }
              isActive={item.key === filterContentType}
              clickable={false}
            />
          );
        })}
      </Grid>
    </>
  );
};

export default FilterTypeContent;
