import React, { useState } from "react";

import { ExpandMore } from "@material-ui/icons";

import { AccordionSummary, AccordionDetails } from "@material-ui/core";

import {
  AccordionStyled,
  GridStyled,
  ContainerIcon,
  Title,
} from "../../styles/GlobalStyle";

export default function AccordionCard({
  title,
  children,
  setValue,
  defaultExpanded = false,
  ...props
}) {
  const [expanded, setExpanded] = useState(defaultExpanded);
  return (
    <AccordionStyled
      color="black"
      expanded={props.hasOwnProperty("value") ? props.value : expanded}
      style={{ marginBottom: "25px" }}
      bg-color="gray"
    >
      <AccordionSummary
        onClick={(e) => !props?.icon && setExpanded(!expanded)}
        expandIcon={
          <ContainerIcon iconColor="white">
            <ExpandMore />
          </ContainerIcon>
        }
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Title subtitle color="white" cursor="pointer">
          {title}
        </Title>
      </AccordionSummary>
      <AccordionDetails>
        <GridStyled
          container
          spacing={2}
          bg-color="white"
          border-radius={5}
          padding={5}
          color="#000"
        >
          {children}
        </GridStyled>
      </AccordionDetails>
    </AccordionStyled>
  );
}
