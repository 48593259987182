import { Button, Tooltip } from "@material-ui/core";
import styled from "styled-components";

export const AddBlueButton = styled(Button)`
  display: flex;
  width: 39px;
  height: 39px;
  justify-content: center;
  align-items: center;
  border-radius: 80px;
  background: #0036b3;
  min-width: 35px;
`;
export const PermissionDetail = styled.div`
  display: flex;
  height: 40px;
  padding: 0px 4px 0px 16px;
  align-items: center;
  flex: 1 0 0;
  border-bottom: 1px solid #e0e0e0;
  background: #f5f5f5;
  color: rgba(0, 0, 0, 0.87);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.15px;
`;

export const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))`
  & .MuiTooltip-tooltip {
    background-color: #63708a;
    color: white;
    max-width: 500px;
    font-size: 12px;
    border: 1px solid #dadde9;
  }
`;
