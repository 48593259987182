import { useContext, useEffect, useRef } from "react";
import { useAuth } from "../../../contexts/crm/Auth";
import { GlobalContext } from "../../../contexts/GlobalContext";
import { notify } from "../../../utils/notify";
import { useChatContext } from "../contexts/ChatContext";
import {
  getAllCustomersInQueue,
  pullCustomerFromQueue,
} from "../services/queue";
import { productsOfAttendance } from "../services/settings";
import { calcWaitingTime } from "../utils";
import useWebSocketClient from "./WebSocketClient";

export default function ContactController() {
  const webSocketClient = useWebSocketClient();
  const { SCREEN_ENUM, chat, settings, socket, setChat, setSettings } =
    useChatContext();
  const { user, company } = useAuth();
  const { userLogged } = useContext(GlobalContext);

  const settingsRef = useRef(settings);
  const userRef = useRef(user);
  const companyRef = useRef(company);
  const chatRef = useRef(chat);

  useEffect(() => {
    settingsRef.current = settings;
  }, [settings]);

  useEffect(() => {
    userRef.current = user;
  }, [user]);

  useEffect(() => {
    companyRef.current = company;
  }, [company]);

  useEffect(() => {
    chatRef.current = chat;
  }, [chat]);

  const subscribeOnQueueList = async ({ roomId }) => {
    const subscribe = (queueId) => {
      const topic =
        webSocketClient.TOPICS.subscriptions.chatQueueAttendant(queueId);
      if (!webSocketClient.isSubscribedOn(topic)) {
        webSocketClient.subscribe(topic, (data) => {
          const {
            created_at,
            session_id,
            name_customer,
            username,
            product_Name,
            action,
            origin,
            integrationType,
          } = data;

          if (action === "newQueue") {
            const queueItem = [...chatRef.current.queue];
            queueItem.push({
              name: username,
              companyName: name_customer,
              waitingTime: calcWaitingTime(created_at),
              productName: product_Name,
              created_at,
              sessionId: session_id,
              origin,
              integrationType,
            });

            setChat({ queue: queueItem });
          } else {
            let newQueueList = chatRef.current.queue.filter((elem) => {
              return elem.sessionId !== session_id;
            });

            setChat({ queue: newQueueList });
          }
        });
      }
    };

    let departments = [];
    if (settings.departments.length <= 0) {
      departments = await productsOfAttendance({
        companyId: companyRef.current.id || company.id,
        tokenCognito: localStorage.getItem("tokenCognitoPanel"),
        userId: userRef.current.id || user.id,
        refreshToken: userLogged.cognito.RefreshToken,
      });
      if (departments) {
        setSettings({ departments });
      }
    } else {
      departments = settings.departments;
    }
    if (chatRef.current.distributionProduct || chat.distributionProduct) {
      departments
        .filter((department) => department.selected)
        .forEach((element) => {
          const queueId = `${companyRef.current.id || company.id}-${
            roomId ? roomId : chat.roomId
          }-${element.id}`;
          subscribe(queueId);
        });
    } else {
      subscribe(`${company.id}-${roomId ? roomId : chat.roomId}`);
    }
  };

  const pullCustomer = async ({ sessionId }) => {
    setChat({
      currentScreen: SCREEN_ENUM.loading,
      isLoading: true,
      loadingMessage: "Iniciando atendimento...",
    });
    const response = await pullCustomerFromQueue({
      webSocketClient,
      userId: user.id,
      roomId: chat.roomId,
      companyId: company.id,
      sessionId,
      socketId: socket.clientId,
      attendantName: `${userLogged.firstName} ${userLogged.lastName}`,
    });
    if (response?.hasError || response?.status !== 200) {
      return notify("Não foi possível atender esse cliente");
    }
  };

  const fetchQueue = async () => {
    const responseQueue = await getAllCustomersInQueue({
      webSocketClient,
    }).fromAttendantId({
      userId: user.id,
      roomId: chat.roomId,
      companyId: company.id,
      sessionId: "",
      socketId: socket.clientId,
    });
    if (responseQueue) {
      const queueItems = responseQueue.map((item) => {
        return {
          name: item.username,
          companyName: item.name_customer,
          waitingTime: calcWaitingTime(item.created_at),
          productName: item.product_Name,
          created_at: item.created_at,
          sessionId: item.session_id,
          origin: item?.origin,
          integrationType: item?.integrationType,
        };
      });
      setChat({ queue: queueItems });
    }
  };

  return {
    subscribeOnQueueList,
    fetchQueue,
    pullCustomer,
  };
}
