import { useContext, useEffect, useRef } from "react";
import { GlobalContext } from "../../../contexts/GlobalContext";
import { useChatContext } from "../contexts/ChatContext";
import useWebSocketClient from "./WebSocketClient";

import { useAuth } from "../../../contexts/crm/Auth";
import { fetchConversationMessages } from "../services/messages";
import { getHandler, getProductsAttendantHandler } from "../services/rate";
import { convertToTimeLocale } from "../utils";

export default function ConversationController() {
  const webSocketClient = useWebSocketClient();
  const {
    setSocket,
    chat,
    setChat,
    messages,
    setMessages,
    SCREEN_ENUM,
    setRateQuestions,
    settings,
  } = useChatContext();
  const { user, company } = useAuth();
  const { companyUser, userLogged } = useContext(GlobalContext);

  const chatRef = useRef(chat);
  const userRef = useRef(user);
  const companyRef = useRef(company);

  useEffect(() => {
    chatRef.current = chat;
  }, [chat]);

  useEffect(() => {
    userRef.current = user;
  }, [user]);

  useEffect(() => {
    companyRef.current = company;
  }, [company]);

  const messagesRef = useRef(messages);
  useEffect(() => {
    messagesRef.current = messages;
  }, [messages]);

  const openConversation = (sessionId, typeDistribuit = null) => {
    const conversation = chatRef.current.conversations.find(
      (c) => c.sessionId === sessionId
    );
    let attendantName = null;
    if (companyUser.isAccountant === "true") {
      attendantName = `${userLogged.firstName} ${userLogged.lastName}`;
      if (chatRef.current.currentConversation === sessionId) {
        conversation.countMessages = 0;
        setChat({ conversations: chatRef.current.conversations });
      }
    }

    conversation.countMessages = 0;

    const topic = webSocketClient.TOPICS.emit.attendanceStart;
    setChat({
      currentConversation: sessionId,
      conversations: chatRef.current.conversations,
    });
    setMessages([]);
    if (conversation?.status === "aguardando_atendimento") {
      webSocketClient.send({
        topic,
        message: {
          identifiers: {
            sessionId: sessionId,
            attendantSocketClientId: webSocketClient.socket.id,
          },
          company_code: companyRef?.current?.id || company.id,
          company_alias: companyRef?.current?.alias || company.alias,
          sessionId: sessionId,
          room_code: chat.roomId,
          codeAttendant: conversation.attendant_code
            ? conversation.attendant_code
            : userRef.current.id,
          username: userRef.current.name,
          messageFile: "",
          attendantName,
          typeDistribuit,
        },
      });
      conversation.status = "atendimento_iniciado";
      setChat({ conversations: chatRef.current.conversations });
    }

    if (!sessionId) return;
    fetchConversationMessages(
      { company: companyRef.current, webSocketClient, sessionId },
      (data) => {
        let newMessages = data?.messages?.length > 0 ? data.messages : [];

        if (conversation?.status === "atendimento_encerrado")
          newMessages.push("atendimento_encerrado");
        newMessages.reverse();
        setMessages([]);
        setMessages(newMessages);
      }
    );
  };

  const sendMessageSocket = ({
    message,
    sessionId,
    isAttendant,
    messageId,
  }) => {
    webSocketClient.send({
      topic: webSocketClient.TOPICS.emit.message,
      message: {
        identifiers: {
          sessionId,
          customerSocketClientId: webSocketClient.socket.id,
        },
        company_code: company.id,
        company_alias: company.alias,
        sessionId,
        room_code: chat.roomId,
        message,
        codeAttendant: isAttendant ? user.id : "",
        username: user.name,
        messageFile: "",
        message_id: `${messageId.toString()}`,
      },
    });
  };

  const subscribeOnConversationList = ({
    company: companyParam,
    user: userParam,
  } = {}) => {
    const companyId = companyRef?.current?.id || companyParam?.id || company.id;
    const userId = userParam?.id || user.id;
    const topic = webSocketClient.TOPICS.subscriptions.chatAttendant({
      attendantId: userId,
      companyId: companyId,
    });
    if (!webSocketClient.isSubscribedOn(topic))
      webSocketClient.onConnect(() => {
        webSocketClient.subscribe(topic, (data) => {
          const { conversations } = chatRef.current;
          const sessionId = data.sessionId || data.session_id;
          if (data.action === "newAttendance") {
            let newQueueArray = [];

            newQueueArray = chatRef.current.queue.filter((current) => {
              return current.sessionId !== sessionId;
            });
            if (companyUser.isAccountant === "true") {
              conversations.push({
                sessionId: data.session_id,
                ticketId: data.ticket_code || "",
                status: "aguardando_atendimento",
                name: data.username || "",
                countMessages: 1,
                companyName: data.customer || "",
                departmentName: data.product_name || "",
                origin: data.origin,
                integrationType: data?.integrationType,
              });
            }
            setChat({ conversations, queue: newQueueArray });

            addConversations(conversations, onReceiveMessage);

            if (
              (data.typeDistribuit === "pullQueue" ||
                data.typeDistribuit === "callCustomerToAttendance") &&
              companyUser.isAccountant === "true"
            ) {
              openConversation(data.session_id, data.typeDistribuit);
              setChat({ currentScreen: SCREEN_ENUM.conversation });
            }
          }
        });
      });
  };

  const onReceiveMessage = (data) => {
    const {
      time,
      username,
      codeAttendant,
      messageId,
      messageFile,
      message,
      sessionId,
      messages_before_attendance,
    } = data;

    if (
      chatRef.current.open &&
      chatRef.current.currentConversation === sessionId
    ) {
      if (messages_before_attendance) {
        messages_before_attendance.forEach((messageItem) => {
          setMessages([
            {
              id: messageItem.messageId,
              sessionId: messageItem.sessionId,
              text: messageItem.message,
              time: convertToTimeLocale(messageItem?.time),
              typeUser: "customer",
              username: messageItem?.username,
            },
          ]);
        });
      }

      let messageChat = "";
      const timeLocale = convertToTimeLocale(time);
      messageChat = message !== "" && message ? message : messageFile;
      const typeUser = codeAttendant ? "attendant" : "customer";
      if (messages.findIndex((m) => m.messageId === messageId) < 0) {
        setMessages([
          {
            id: messageId,
            sessionId: sessionId,
            text: messageChat,
            time: timeLocale,
            typeUser,
            username,
          },
        ]);
      }
    } else {
      const conversation = chatRef.current.conversations.find(
        (item) => item.sessionId === sessionId
      );
      if (userRef.current.name !== username) conversation.countMessages += 1;

      if (
        Notification.permission === "granted" &&
        !document.hasFocus() &&
        settings.notificationState
      ) {
        new Notification("Nova mensagem: ", {
          body: conversation.name,
        });
      }

      setChat({ conversations: [...chatRef.current.conversations] });
    }
  };

  const subscribeOnAttendanceStart = ({ sessionId }, callback) => {
    const topic = webSocketClient.TOPICS.subscriptions.attendanceStartResponse(
      chat.roomId,
      sessionId
    );

    if (!webSocketClient.isSubscribedOn(topic)) {
      webSocketClient.onConnect(() => {
        webSocketClient.subscribe(topic, async (data) => {
          let { messages_before_attendance, ticket_code, sessionId } = data;
          if (messages_before_attendance) {
            messages_before_attendance.sort(
              (a, b) => new Date(a.time) - new Date(b.time)
            );
          }
          const { conversations } = chatRef.current;
          if (conversations.length > 0) {
            const idxConversation = conversations.findIndex(
              (elem) =>
                Number(elem.sessionId) === Number(sessionId) &&
                Number(elem.ticketId) !== Number(ticket_code)
            );
            if (idxConversation >= 0) {
              conversations[idxConversation].ticketId = ticket_code;
              setChat({ conversations, currentTicketId: ticket_code });
            }
          }
          const conversation = chatRef.current.conversations.find(
            (item) => item.sessionId === sessionId
          );

          if (user.type === "customer") {
            setChat({ currentConversation: sessionId });
          }

          if (
            conversation?.sessionId === sessionId &&
            conversation.ticketId !== ticket_code
          ) {
            conversation.ticketId = ticket_code;
            setChat({
              conversations,
              currentTicketId: ticket_code,
            });
          }

          if (callback) callback(data);
        });
      });
    }
  };

  const subscribeOnNewsInteration = ({ sessionId, roomId }, callback) => {
    subscribeOnDisconnectChat({ sessionId });

    const topic = webSocketClient.TOPICS.subscriptions.message(
      roomId,
      sessionId
    );
    if (!webSocketClient.isSubscribedOn(topic)) {
      webSocketClient.onConnect(() => {
        webSocketClient.subscribe(topic, (data) => {
          if (callback) callback(data);
        });
      });
    }
  };

  const subscribeOnDisconnectChat = ({ sessionId }) => {
    webSocketClient.onConnect((data) => {
      const topic = webSocketClient.TOPICS.subscriptions.disconnect(
        chatRef.current.roomId,
        sessionId
      );
      if (!webSocketClient.isSubscribedOn(topic)) {
        webSocketClient.subscribe(topic, async (data) => {
          if (data) {
            const isAttendant = companyUser?.isAccountant === "true";
            const { emitter, ticketId, companyId } = data;

            if (!isAttendant) {
              const response = await getHandler({
                webSocketClient,
                companyId,
                ticketId,
              });
              if (response.data?.questions) {
                setSocket({ startedTopics: [] });

                let conversation = chatRef.current.conversations[0];
                if (conversation) conversation.ticketId = ticketId;

                setRateQuestions([...response.data.questions]);
                setChat({
                  currentScreen: SCREEN_ENUM.avaliation,
                  conversations: [...chatRef.current.conversations],
                });
                setMessages([]);
              } else {
                const pulse = await setTimeout(() => {
                  setChat({
                    currentScreen: SCREEN_ENUM.loading,
                    isLoading: true,
                    loadingMessage: "O atendente finalizou o atendimento",
                  });
                  setMessages([]);
                }, 2000);
                clearTimeout(pulse);

                setChat({
                  currentScreen: SCREEN_ENUM.customerLobby,
                  conversations: [...chatRef.current.conversations],
                });
              }

              sessionStorage.removeItem("SESSION_ID");
            } else {
              if (emitter === "attendant") {
                const newConversations = chatRef.current.conversations.filter(
                  ({ sessionId }) => {
                    return sessionId !== chatRef.current.currentConversation;
                  }
                );

                setChat({
                  conversations: newConversations,
                  currentScreen: SCREEN_ENUM.conversation,
                  currentConversation: "",
                });
                setMessages([]);
              } else {
                let conversations = chatRef.current.conversations.map(
                  (item) => {
                    if (item.sessionId === sessionId) {
                      item.status = "atendimento_encerrado";
                    }
                    if (
                      chatRef.current.open &&
                      sessionId === chatRef.current.currentConversation
                    ) {
                      setMessages(["atendimento_encerrado"]);
                    } else if (item.sessionId === sessionId) {
                      item.countMessages++;
                    }

                    return item;
                  }
                );

                setChat({ conversations: [...conversations] });
              }
            }
          }
        });
      }
    });
  };

  const fetchType = async ({ company_code }) => {
    const types = [];
    try {
      await fetch(
        `${
          webSocketClient.urlWebSocketClient || localStorage.getItem("URL_WS")
        }/type/?company_code=${company_code}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => response.json())
        .then((response) => types.push(...response.types));
    } catch (error) {
      console.error(error);
    }
    return types;
  };

  const fetchClassification = async ({ company_code }) => {
    const classifications = [];
    try {
      await fetch(
        `${
          webSocketClient.urlWebSocketClient || localStorage.getItem("URL_WS")
        }/classification/?company_code=${company_code}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => response.json())
        .then((response) => classifications.push(...response.classifications));
      return classifications;
    } catch (error) {
      console.error(error);
    }
  };

  const fetchCurrentConversations = async (
    { company: companyParam, user: userParam },
    callback
  ) => {
    const roomId = chat.roomId;
    const companyId = company.id ? company.id : companyParam.id;
    const attendantId = user.id ? user.id : userParam.id;
    try {
      const response = await fetch(
        `${
          webSocketClient.urlWebSocketClient || localStorage.getItem("URL_WS")
        }/attendances?attendant_code=${attendantId}&company_code=${companyId}&room_code=${roomId}`,
        {
          headers: {
            "Content-Type": "application/json",
            attendantSocketClientId: webSocketClient.socket.id,
          },
        }
      );
      let jsonResponse = await response.json();
      let attendances =
        jsonResponse && jsonResponse.attendances
          ? jsonResponse.attendances
          : [];
      attendances = attendances
        .reverse()
        .map((a) => {
          if (a?.session_id)
            return {
              sessionId: a.session_id,
              ticketId: a.ticket_code || "",
              status: a.status_attendance,
              name: a.username || "",
              countMessages: 0,
              origin: a.origin,
              integrationType: a?.integrationType,
              companyName: a.customer,
              departmentName: a.product_name,
            };
          return null;
        })
        .filter(
          (a) =>
            !chatRef.current.conversations.some(
              (ctxa) => ctxa.sessionId === a.sessionId
            )
        );
      if (callback) callback({ conversations: attendances });
    } catch (error) {
      if (callback) callback({ conversations: [] });
      console.error("Erro ao recuperar os atendimentos do atendente");
    }
  };

  const addConversations = (conversations, newMessageCallback) => {
    conversations.forEach((c) => {
      subscribeOnAttendanceStart({ sessionId: c.sessionId }, (data) => {
        if (data && newMessageCallback) {
          newMessageCallback(data);
        }
      });
      subscribeOnNewsInteration(
        { roomId: chat.roomId, sessionId: c.sessionId },
        (data) => {
          if (data && newMessageCallback) newMessageCallback(data);
        }
      );
    });

    setChat({ conversations: [...conversations] });
  };

  const openScreenOptions = async (element, index) => {
    if (index === 0) {
      const types = await fetchType({ company_code: company.id });
      const classification = await fetchClassification({
        company_code: company.id,
      });
      setChat({
        typeAttendace: types,
        classificationAttendance: classification,
        currentScreen: SCREEN_ENUM.closingConversation,
      });
    }
  };

  const emitQuitAttendance = ({ type, classification, subject }) => {
    setChat({
      currentScreen: SCREEN_ENUM.loading,
      isLoading: true,
      loadingMessage: "Encerrando atendimento",
    });
    webSocketClient.onConnect((data) => {
      webSocketClient.send({
        topic: webSocketClient.TOPICS.emit.quit,
        message: {
          identifiers: {
            sessionId: chat.currentConversation,
            customerSocketClientId: webSocketClient.socket.id,
          },
          company_code: company.id,
          company_alias: company.alias,
          sessionId: chat.currentConversation,
          room_code: chat.roomId,
          attendantCode: user.id,
          action: "removeQueue",
          emitter:
            companyUser?.isAccountant === "true" ? "attendant" : "client",
          quitforInactivity: null,
          isAttendantCallingClient: null,
          type,
          classification,
          subject,
        },
      });
    });
  };

  const handleSendRate = async ({ questions, observation }) => {
    const response = await getProductsAttendantHandler({
      questions,
      observation,
      ticketId:
        chatRef.current.conversations[0].ticketId ||
        chatRef.current.currentTicketId,
      companyId: company.id,
      webSocketClient,
    });

    if (response) {
      const { error, message } = response.data;

      if (error) return { message, success: error };
      return { message, success: true };
    }
  };

  return {
    subscribeOnNewsInteration,
    subscribeOnConversationList,
    openConversation,
    onReceiveMessage,
    fetchCurrentConversations,
    addConversations,
    subscribeOnAttendanceStart,
    sendMessageSocket,
    fetchType,
    fetchClassification,
    openScreenOptions,
    emitQuitAttendance,
    handleSendRate,
  };
}
