import { useEffect, useState } from "react";
import {
  Grid,
  IconButton,
  LinearProgress,
  makeStyles,
  Tooltip,
} from "@material-ui/core";
import RefreshIcon from "@material-ui/icons/Refresh";
import moment from "moment/moment";
import {
  CertificateDataGrid,
  DateColumn,
  DocumentColumn,
  EnterpriseColumn,
} from "./styles/Certificate";
import {
  getLastProcuration,
  getRPAExecutions,
  startRPAExecution,
} from "../../repositories/panel/v1/ECACRPARepository";
import {
  GridOverlay,
  GridPagination,
  GridToolbarContainer,
} from "@material-ui/data-grid";
import { formatCNPJorCPF } from "./util/Formatter";
import {
  ErrorColumn,
  LastCheckDate,
  PendingColumn,
  ScheduledColumn,
  SuccessColumn,
} from "./styles/CertificateECAC";
import { notify } from "../../utils/notify";

export default function CertificateECACView({ companyDocument }) {
  const [loading, setLoading] = useState(true);
  const [tmpRows, setTmpRows] = useState([]);
  const [lastProcuration, setLastProcuration] = useState();
  const [pageSize, setPageSize] = useState(10);

  const customLocaleText = {
    noRowsLabel: "Sem Registros",
  };

  const customPagination = ({ pagination }) => {
    return (
      <GridPagination
        nextIconButtonText="Próxima página"
        backIconButtonText="Página anterior"
        rowsPerPageOptions={[10, 15, 20]}
        labelRowsPerPage="Linhas por página"
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to} de ${count} `
        }
      />
    );
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      "& .MuiDataGrid-columnsContainer": {
        // Cor de fundo do cabeçalho
        background: "#F5F5F5",
        alignItems: "center",
        paddingTop: "10px",
      },

      "& .MuiDataGrid-columnSeparator": {
        display: "none !important",
      },
    },
  }));

  const classes = useStyles();

  let columns = [
    {
      field: "currentTime",
      headerName: "Data",
      flex: 0.3,
      sortable: false,
      renderCell: ({ row }) => {
        return <DateColumn>{formatDate(row.currentTime)}</DateColumn>;
      },
    },
    {
      field: "certificateCnpj",
      headerName: "CPF/CNPJ",
      flex: 0.5,
      sortable: false,
      renderCell: ({ row }) => {
        return (
          <EnterpriseColumn>
            <DocumentColumn style={{ fontSize: 14 }}>
              {formatCNPJorCPF(row.certificateCnpj)}
            </DocumentColumn>
          </EnterpriseColumn>
        );
      },
    },

    {
      field: "status",
      headerName: "Status",
      flex: 1.5,
      sortable: false,

      renderCell: ({ row }) => {
        if (!row.status) {
          return "";
        }

        if (row.status === "SUCCESS") {
          return <SuccessColumn>Sucesso</SuccessColumn>;
        }

        if (row.status === "ERROR") {
          return <ErrorColumn>Erro</ErrorColumn>;
        }

        if (row.status === "SCHEDULED") {
          return <ScheduledColumn>Agendado</ScheduledColumn>;
        }

        if (row.status === "PENDING") {
          return <PendingColumn>Processando</PendingColumn>;
        }

        return <div>Desconhecido</div>;
      },
    },
  ];

  useEffect(() => {
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refresh = async () => {
    setLoading(true);
    await getRPAExecutions({
      document: companyDocument,
    })
      .then((response) => {
        if (response?.success) {
          let executions = response?.data?.executions || [];
          executions.sort(sortByTimestamp);
          setTmpRows(executions);
        } else {
          setTmpRows([]);
        }
      })
      .catch((err) => {
        setTmpRows([]);
      });
    const responseProcuration = await getLastProcuration(companyDocument);

    if (responseProcuration?.success) {
      setLastProcuration(responseProcuration?.data);
    } else {
      notify("Erro ao buscar última checagem");
      setLastProcuration(null);
    }

    setLoading(false);
  };

  const sortByTimestamp = (a, b) => {
    const timestampA = a.currentTime;
    const timestampB = b.currentTime;

    return timestampB - timestampA;
  };

  const formatDate = (date) => {
    const timestamp = parseInt(date);
    const formattedDate = moment(timestamp).format("DD/MM/YYYY");
    return formattedDate;
  };

  const formatDateWithHours = (date) => {
    const formattedDate = moment(date).format("DD/MM/YYYY - HH[h]mm");
    return formattedDate;
  };

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer
        style={{
          display: "flex",
          justifyContent: "flex-end",
          borderRadius: 5,
          padding: 5,
          gap: 5,
        }}
      >
        <Grid
          item
          container
          direction="col"
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: "6px",
          }}
        >
          {loading ? null : (
            <>
              <LastCheckDate>
                Última Checagem{" "}
                {formatDateWithHours(lastProcuration?.date_entered)}
              </LastCheckDate>
              <IconButton
                size="small"
                onClick={() => {
                  startRPA();
                }}
              >
                <Tooltip title={"Iniciar checagem das procurações"}>
                  <RefreshIcon height={20} />
                </Tooltip>
              </IconButton>
            </>
          )}
        </Grid>
      </GridToolbarContainer>
    );
  };

  const startRPA = async () => {
    const response = await startRPAExecution({
      document: companyDocument,
    });

    let status = response?.code || 500;

    if (status === 201) {
      refresh();

      notify(
        "Sua checagem será iniciada em breve! Isso pode levar um tempo, porém tudo já está agendado para iniciar!",
        true,
        "success"
      );
      return;
    }

    if (status === 403) {
      notify(
        "Você só pode realizar uma checagem das procurações a cada cinco dias! Aguarde esse período e faça uma nova tentativa",
        true,
        "error"
      );
      return;
    }

    notify(
      "Ocorreu um erro ao iniciar a checagem das procurações. Tente novamente mais tarde ou entre em contato com o suporte!",
      true,
      "error"
    );
  };

  return (
    <>
      <CertificateDataGrid
        className={classes.root}
        disableColumnFilter
        disableColumnMenu
        style={{ marginTop: 0 }}
        autoHeight
        rows={loading ? [] : tmpRows}
        columns={columns}
        slots={{
          loadingOverlay: LinearProgress,
        }}
        loading={loading}
        disableSelectionOnClick
        components={{
          Toolbar: CustomToolbar,
          NoRowsOverlay: () =>
            !loading && (
              <GridOverlay>
                <h4>Nenhum agendamento encontrado no momento!</h4>
              </GridOverlay>
            ),
          Pagination: customPagination,
        }}
        localeText={customLocaleText}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
      />
    </>
  );
}
