import { DataGrid } from "@material-ui/data-grid";
import { Grid, IconButton } from "@material-ui/core";
import styled from "styled-components";

const roboto = (props) => props.theme.fonts.roboto;

export const IconButtonAddStyled = styled(IconButton)`
  background-color: #0036b3;
  transition: 0.2s ease-in-out;
  color: #fff;
  &:hover {
    background-color: #002b8f;
  }
`;

export const EnterpriseColumn = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 18px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const CompanyColumn = styled.div`
  color: rgba(0, 0, 0, 0.87);
  text-transform: capitalize;
  text-overflow: ellipsis;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.15px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const DocumentColumn = styled.div`
  color: #616161;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0.4px;
`;

export const CertificateColumn = styled.div`
  border-radius: 32px;
  background: #1e1efb;
  display: flex;
  height: 32px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: white;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20.02px; /* 143% */
  letter-spacing: 0.15px;
`;

export const DateColumn = styled.span`
  font-size: 14px;
  display: flex;
  text-align: center;
  align-items: center;
  width: 200px;
`;

export const DateColumnExpired = styled.span`
  font-size: 14px;
  display: flex;
  text-align: center;
  align-items: center;
  color: white;
  font-weight: bold;
  height: 20px;
  width: 88px !important;
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 2px;
  background-color: #c10015;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 200px;
`;

export const CertificateDataGrid = styled(DataGrid)`
  border: 0px !important;

  .MuiDataGrid-window {
    ::-webkit-scrollbar {
      height: 13px !important;
    }

    ::-webkit-scrollbar-track {
      background-color: #efefef;
    }

    ::-webkit-scrollbar-thumb {
      background-color: lightgrey;
    }
  }

  .MuiDataGrid-columnHeaderWrapper {
    font-family: ${roboto}, sans-serif !important;
    font-weight: 500;
    white-space: normal;
  }

  .MuiDataGrid-columnsContainer {
    min-height: 40px !important;
    line-height: 20px !important;
  }

  .MuiDataGrid-columnHeaderTitle {
    white-space: normal !important;
    text-overflow: unset !important;
  }

  .MuiDataGrid-root {
    border: 5px solid #000 !important;
  }

  div .MuiTablePagination-toolbar {
    .MuiTablePagination-caption:first-of-type {
      display: none;
    }
  }

  .MuiDataGrid-columnSeparator {
    display: none !important;
  }
`;

export const ProcurationActiveColumn = styled.div`
  color: #1b5e20;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20.02px;
  letter-spacing: 0.15px;
  display: flex;
  height: 32px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  background: var(--Green-50, #e8f5e9);
`;

export const ProcurationExpiredColumn = styled.div`
  color: var(--Red-900, #b71c1c);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20.02px;
  letter-spacing: 0.15px;
  display: flex;
  height: 32px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  background: var(--Red-50, #ffebee);
`;

export const ProcurationCanceledColumn = styled.div`
  color: var(--Orange-900, #e65100);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20.02px; /* 143% */
  letter-spacing: 0.15px;
  display: flex;
  height: 32px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  background: var(--Orange-50, #fff3e0);
`;

export const ProcurationRejectedColumn = styled.div`
  color: var(--Orange-900, #e65100);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20.02px; /* 143% */
  letter-spacing: 0.15px;
  display: flex;
  height: 32px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  background: var(--Orange-50, #fff3e0);
`;

export const ExpiredCertificate = styled.div`
  color: #b71c1c;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20.02px;
  letter-spacing: 0.15px;
  border-radius: 4px;
  background: #ffebee;
  display: flex;
  height: 32px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

export const CertificateWillExpire = styled.div`
  color: #e65100;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20.02px;
  letter-spacing: 0.15px;
  display: flex;
  height: 32px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  background: #fff3e0;
`;

export const CertificateNormal = styled.div`
  color: #1b5e20;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20.02px;
  letter-spacing: 0.15px;
  display: flex;
  height: 32px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  background: #e8f5e9;
`;

export const SearchLabel = styled.div`
  color: #364461;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
`;

export const CertificateDetailsGrid = styled(Grid)`
  display: flex;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #9e9e9e;
  background: #f5f5f5;
`;

export const CertificateDetailsText = styled.p`
  color: rgba(0, 0, 0, 0.87);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.15px;
`;

export const CertificateDetailsDotSeparator = styled.p`
  color: rgba(0, 0, 0, 0.87);
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 900;
  line-height: 18px;
  letter-spacing: 0.15px;
`;
