import { useContext, useEffect, useState } from "react";
import { usePermissions } from "../../../utils/permissionsHook";

import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  Tooltip,
  makeStyles,
} from "@material-ui/core";

import { Add } from "@material-ui/icons";
import { useMemo } from "react";
import { useHistory } from "react-router-dom";
import { GlobalContext } from "../../../contexts/GlobalContext";
import { GlobalVariables } from "../../../GlobalVariables";
import {
  GridStyled,
  IconButtonStyled,
  LabelStyled,
  LightTooltip,
} from "../../../styles/GlobalStyle";
import { filter } from "../../../utils/filter";
import MaskService from "../../../utils/mask/maskService";
import { notify } from "../../../utils/notify";
import { orderBy } from "../../../utils/orderBy";
import { usePortalAsClient } from "../../../utils/viewPortalAsClientHook";
import { CustumerManagementContext } from "../contexts/CustomerManagementContext";
import {
  CertificateStatus,
  ClientDataGrid,
  CustomToolgridButtonExport,
  SearchLabel,
  SelectFilterStyled,
} from "../styles/CustomerManagementStyle";
import { ReactComponent as EditIcon } from "../../../assets/icons/edit.svg";
import {
  getAccountantClients,
  updateBpoActive,
  verifyUpdateBpo,
} from "../../../repositories/portal_api/AccountantClient";
import { ptBR } from "@material-ui/data-grid";
import { SearchIcon } from "../../../components/icons/SearchIcon";
import IconRedirect from "./IconRedirect";

export default function CustomerManagementWithBPO({ location }) {
  const { setCustomerId, setDocumentCustomer, setCompanyName } = useContext(
    CustumerManagementContext
  );

  const { checkIfUserHasPermission } = usePermissions();
  const [page, setPage] = useState(0);
  const [textFilter, setTextFilter] = useState("");
  const [isFilterBpoActive, setIsFilterBpoActive] = useState("all");
  const [dataTableFilter, setDataTableFilter] = useState([]);
  const [columns, setColumns] = useState([]);
  const { push } = useHistory();
  const { isInClientView, clientViewed } = usePortalAsClient();

  const { setLoading, customersBpo, setCustomersBpo } = useContext(
    CustumerManagementContext
  );
  const { companyUser, userLogged } = useContext(GlobalContext);
  const [loadingTab, setLoadingTab] = useState(false);
  const [pageSize, setPageSize] = useState(50);
  const { setPortalAsClientView } = usePortalAsClient();
  const [planGfList, setPlanGfList] = useState([]);

  const bpoDropdown = [
    { name: "Todos", value: "all" },
    { name: "Ativo", value: true },
    { name: "Inativo", value: false },
    //O valor de não configurado não pode ser null, porque se não, não aparece no select quando selecionado
    { name: "Não Configurado", value: 0 },
  ];

  const useStyles = makeStyles((theme) => ({
    root: {
      "& .MuiDataGrid-columnsContainer": {
        background: "#F5F5F5",
        alignItems: "center",
        paddingTop: "8px",
      },
    },
  }));

  const classes = useStyles();

  const optionsColumns = useMemo(
    () => [
      {
        id: 1,
        field: "company_name",
        headerName: "Empresa",
        flex: 2,
        renderCell: ({ row }) => {
          return (
            <>
              <GridStyled container height={25}>
                <LabelStyled
                  fontSize={14}
                  style={{
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}
                >
                  {row.company_name}
                </LabelStyled>
              </GridStyled>
            </>
          );
        },
      },
      {
        id: 2,
        field: "document",
        headerName: "CPF/CNPJ",
        flex: 2,
        renderCell: ({ row }) => {
          return (
            <>
              <GridStyled container height={25}>
                <LabelStyled fontSize={14}>
                  {MaskService.toMask(
                    row.document.replace(/[^0-9]+/g, "").length <= 11
                      ? "cpf"
                      : "cnpj",
                    row.document || ""
                  )}
                </LabelStyled>
              </GridStyled>
            </>
          );
        },
      },

      {
        // TODO: mostrar tag com quantos dias faltam para expirar o certificado de acordo com as cores abaixo:
        // Laranja: a partir de 40 dias pra menos
        // Vermelho: expirado
        // Verde: 41 dias ou mais.
        id: 3,
        field: "certificate_valid_to",
        headerName: "Status do Certificado",
        flex: 3,
        renderCell: ({ row }) => {
          const certificateValidityDate = row?.certificate_valid_to;
          if (row.certificate_valid_to) {
            let date_certificate = Date.parse(certificateValidityDate);
            let date_now = Date.now();
            let difference = date_certificate - date_now;
            let days = Math.ceil(difference / (1000 * 60 * 60 * 24));
            if (days <= 0) {
              return (
                <Tooltip
                  title={
                    <div>
                      <div>Expirado</div>
                      {row.certificate_granted_document !== row.document && (
                        <>
                          <div>Outorgante: {row.company_name}</div>
                          <div>Outorgado: {row.certificate_granted_name}</div>
                        </>
                      )}
                    </div>
                  }
                >
                  <div style={{ display: "flex" }}>
                    <CertificateStatus status="expired">
                      Expirado
                    </CertificateStatus>
                    {row.certificate_granted_document !== row.document && (
                      <CertificateStatus status="procuration">
                        P.P.
                      </CertificateStatus>
                    )}
                  </div>
                </Tooltip>
              );
            } else if (days <= 7) {
              return (
                <Tooltip
                  title={
                    <div>
                      <div>Expira em {days} dias</div>
                      {row.certificate_granted_document !== row.document && (
                        <>
                          <div>Outorgante: {row.company_name}</div>
                          <div>Outorgado: {row.certificate_granted_name}</div>
                        </>
                      )}
                    </div>
                  }
                >
                  <div style={{ display: "flex" }}>
                    <CertificateStatus status="expiring">
                      Expira em {days} dias
                    </CertificateStatus>
                    {row.certificate_granted_document !== row.document && (
                      <CertificateStatus status="procuration">
                        P.P.
                      </CertificateStatus>
                    )}
                  </div>
                </Tooltip>
              );
            } else {
              return (
                <>
                  <div style={{ display: "flex" }}>
                    <Tooltip
                      arrow
                      title={
                        <div>
                          <div>Expira em {days} dias</div>
                        </div>
                      }
                    >
                      <CertificateStatus status="active">
                        Expira em {days} dias
                      </CertificateStatus>
                    </Tooltip>
                    {row.certificate_granted_document !== row.document && (
                      <Tooltip
                        arrow
                        title={
                          <div>
                            <div>Por Procuração:</div>
                            <div>Outorgante: {row.company_name}</div>
                            <div>Outorgado: {row.certificate_granted_name}</div>
                          </div>
                        }
                      >
                        <CertificateStatus
                          CertificateStatus
                          status="procuration"
                        >
                          P.P.
                        </CertificateStatus>
                      </Tooltip>
                    )}
                  </div>
                </>
              );
            }
          }

          return (
            <Tooltip title="Para importar um Certificado Digital para seu cliente, basta arrastar o arquivo do Certificado Digital de seu cliente no Qyon Bot à direita de sua tela, ou clicar no ícone de núvem na barra de ações abaixo do Qyon Bot.">
              <CertificateStatus status="expired">
                Nenhum certificado ativo
              </CertificateStatus>
            </Tooltip>
          );
        },
      },
      {
        id: 4,
        field: "bpo_active",
        headerName: "Acesso Gestão Financeira",
        flex: 1,
        sortable: false,
        align: "center",
        headerAlign: "center",

        renderHeader: () => (
          <center>
            <center>Acesso</center>
            <center>Gestão</center>
            <center>Financeira</center>
          </center>
        ),
        renderCell: ({ row }) => (
          <Grid container justifyContent="center">
            {row?.gf_plan && (
              <LightTooltip
                title={
                  row?.gf_payer === "accountant"
                    ? "Acessar Gestão Financeira"
                    : row?.bpo_active
                    ? "Acessar Gestão Financeira"
                    : "Necessário que o cliente libere seu acesso através do Portal do Cliente"
                }
                placement="top"
              >
                <IconButtonStyled
                  bg-color="transparent"
                  bghovercolor="grayTertiary"
                  iconColor={
                    row.portal_invite_sended
                      ? "orange"
                      : row.customer_using_portal
                      ? "green"
                      : "gray"
                  }
                  padding="0.2rem"
                  onClick={() => {
                    if (!row.bpo_active && row?.gf_payer === "customer")
                      return notify(
                        "Necessário que o cliente libere seu acesso através do Portal do Cliente"
                      );

                    if (
                      !checkIfUserHasPermission(
                        GlobalVariables.PERMISSIONS.CLIENTES_BPO
                      )
                    ) {
                      return notify(
                        `Acesso negado! Permissão 'Menus - Gestão de clientes - Acesso BPO' necessária`
                      );
                    }

                    const productErp = (
                      companyUser?.systemsPermissions || []
                    ).find(
                      (system) =>
                        system.id === GlobalVariables.ID_PRODUCT_ERP_PANEL
                    );

                    if (!productErp?.url) {
                      return notify(
                        "Não foi possível redirecionar para o sistema."
                      );
                    }

                    setLoading(true);

                    verifyUpdateBpo(row.id)
                      .then((resp) => {
                        if (resp?.success) {
                          if (resp?.data?.need_update) {
                            updateBpoActive(
                              row.id,
                              resp?.data?.bpo_should_be
                            ).then((respUpdate) => {
                              if (respUpdate?.success) {
                              }
                            });

                            window.open(
                              `${
                                productErp?.url
                              }/zf/login/loginpanel?master=true&tokenCognito=${
                                userLogged.token
                              }&document=${(row?.document || "").replace(
                                /[^0-9]+/g,
                                ""
                              )}`,
                              "_blank"
                            );
                          } else {
                            window.open(
                              `${
                                productErp?.url
                              }/zf/login/loginpanel?master=true&tokenCognito=${
                                userLogged.token
                              }&document=${(row?.document || "").replace(
                                /[^0-9]+/g,
                                ""
                              )}`,
                              "_blank"
                            );
                          }
                        }
                      })
                      .catch((ex) => {
                        console.error(ex);
                        window.open(
                          `${
                            productErp?.url
                          }/zf/login/loginpanel?master=true&tokenCognito=${
                            userLogged.token
                          }&document=${(row?.document || "").replace(
                            /[^0-9]+/g,
                            ""
                          )}`,
                          "_blank"
                        );
                      })
                      .finally(() => {
                        setLoading(false);
                      });
                  }}
                >
                  <IconRedirect
                    fill={
                      row?.gf_payer === "accountant"
                        ? "#616161"
                        : row?.bpo_active
                        ? "#616161"
                        : "#b1b1b1"
                    }
                  />
                </IconButtonStyled>
              </LightTooltip>
            )}
            {!row?.configured && (
              <Button
                variant="text"
                style={{
                  background: "#E7EEFE",
                  color: "#0036B3",
                  textTransform: "none",
                }}
                onClick={() => {
                  setCustomerId(row.id);
                  setDocumentCustomer(row.document);
                  setCompanyName(row.company_name);

                  checkIfUserHasPermission(
                    GlobalVariables.PERMISSIONS.CLIENTES_MANAGEMENT
                  )
                    ? push("/customermanagement/edit", {
                        customerId: row.id,
                        initalTab: 2,
                        initalTabName: "Gestão Financeira",
                        companyName: row.company_name,
                      })
                    : notify(
                        `Acesso negado! Permissão 'Menus - Gestão de clientes - Gerenciamento' necessária`
                      );
                }}
              >
                Configurar
              </Button>
            )}
          </Grid>
        ),
      },
      {
        id: 5,
        field: "Ações",
        sortable: false,
        flex: 1,
        renderCell: ({ row }) => (
          <>
            <Grid container direction="row" justifyContent="flex-end">
              <Grid item>
                <LightTooltip size="small" title="Editar" placement="top">
                  <IconButton
                    onClick={() => {
                      setCustomerId(row.id);
                      setDocumentCustomer(row.document);
                      setCompanyName(row.company_name);

                      checkIfUserHasPermission(
                        GlobalVariables.PERMISSIONS.CLIENTES_MANAGEMENT
                      )
                        ? push("/customermanagement/edit", {
                            customerId: row.id,
                            initalTab: 1,
                            initalTabName: "Dados da Empresa",
                            companyName: row.company_name,
                          })
                        : notify(
                            `Acesso negado! Permissão 'Menus - Gestão de clientes - Gerenciamento' necessária`
                          );
                    }}
                  >
                    <EditIcon color="#616161" />
                  </IconButton>
                </LightTooltip>
              </Grid>
            </Grid>
          </>
        ),
        renderHeader: () => (
          <Grid container justifyContent="flex-end">
            <Grid item>
              <CustomToolgridButtonExport
                id="original_button_exports"
                style={{}}
              />
              <Tooltip title="Download Excel">
                <IconButton
                  size="small"
                  onClick={() => {
                    document.getElementById("original_button_exports").click();
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M12.9958 3C12.9958 2.44772 12.5481 2 11.9958 2C11.4436 2 10.9958 2.44772 10.9958 3V13.5816L8.20711 10.7929C7.81658 10.4024 7.18342 10.4024 6.79289 10.7929C6.40237 11.1834 6.40237 11.8166 6.79289 12.2071L11.2365 16.6507C11.4199 16.8645 11.6921 17 11.9958 17L11.9979 17C12.2545 17.0005 12.5113 16.9029 12.7071 16.7071L17.2071 12.2071C17.5976 11.8166 17.5976 11.1834 17.2071 10.7929C16.8166 10.4024 16.1834 10.4024 15.7929 10.7929L12.9958 13.5899V3Z"
                      fill="#616161"
                    />
                    <path
                      d="M21 11C21.5523 11 22 11.4477 22 12V21C22 21.5523 21.5523 22 21 22H3C2.44772 22 2 21.5523 2 21V12.0042C2 11.4519 2.44772 11.0042 3 11.0042C3.55228 11.0042 4 11.4519 4 12.0042V20H20V12C20 11.4477 20.4477 11 21 11Z"
                      fill="#616161"
                    />
                  </svg>
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      checkIfUserHasPermission,
      companyUser?.systemsPermissions,
      userLogged.token,
      setPortalAsClientView,
      push,
      setLoading,
    ]
  );

  useEffect(() => {
    (async () => {
      try {
        setLoadingTab(true);
        const respBpo = await getAccountantClients();

        if (respBpo?.success && Array.isArray(respBpo?.data)) {
          const newArr = orderBy(respBpo?.data, "company_name");
          setCustomersBpo(newArr);
          setDataTableFilter(newArr);
          setPage(0);

          let copyPlans = [...planGfList];

          newArr.forEach((item) => {
            if (
              !copyPlans.includes(item?.gf_plan) &&
              item?.gf_plan !== "" &&
              item?.gf_plan !== null &&
              item?.gf_plan !== undefined
            ) {
              copyPlans.push(item?.gf_plan);
            }
          });

          setPlanGfList(copyPlans);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingTab(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setCustomersBpo, companyUser, isInClientView, clientViewed?.company]);

  useEffect(() => {
    setColumns(optionsColumns);
  }, [setColumns, optionsColumns]);

  useEffect(() => {
    let rowsFilter = [...customersBpo];

    if (textFilter) {
      rowsFilter = filter({
        data: rowsFilter,
        text: textFilter,
        columns: ["company_name", "document", "certificateValidityDate"],
      });
    }

    if (isFilterBpoActive !== "all") {
      rowsFilter = rowsFilter.filter((row) => {
        if (isFilterBpoActive === 0) {
          return row.gf_plan === null || row.bpo_active === null;
        } else {
          return row.bpo_active === isFilterBpoActive && row.gf_plan !== null;
        }
      });
    }

    setDataTableFilter(orderBy(rowsFilter, "company_name"));
    setPage(0);
  }, [
    textFilter,
    customersBpo,
    isFilterBpoActive,
    setDataTableFilter,
    setPage,
  ]);

  return (
    <>
      <div
        container
        style={{
          background: "#fff",
          border: "1px solid #E0E0E0",
          borderRadius: 15,
          padding: 15,
          height: "100%",
          flex: 1,
        }}
        className={classes.root}
      >
        <Grid
          container
          direction="row"
          spacing={1}
          style={{ display: "flex", alignItems: "center" }}
        >
          <Grid item xs={9}>
            <SearchLabel>Pesquisar</SearchLabel>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              placeholder="Pesquisar por nome ou CNPJ"
              value={textFilter}
              onChange={(e) => setTextFilter(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={2}>
            <div>BPO</div>
            <SelectFilterStyled
              variant="outlined"
              size="small"
              fullWidth
              defaultValue="all"
              value={isFilterBpoActive}
              onChange={(e) => setIsFilterBpoActive(e.target.value)}
            >
              {bpoDropdown.map((plan) => (
                <MenuItem value={plan.value}>{plan.name}</MenuItem>
              ))}
            </SelectFilterStyled>
          </Grid>

          <Grid
            item
            xs={1}
            style={{ display: "flex", justifyContent: "right" }}
          >
            <Button
              onClick={() => {
                push("/customermanagement/edit", {
                  initalTab: 1,
                  customerId: undefined,
                });
              }}
              style={{
                marginTop: "15px",
                display: "flex",
                width: "35px",
                height: "35px",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "80px",
                background: "#0036B3",
                minWidth: "35px",
              }}
            >
              <Add style={{ color: "#FFFFFF" }} />
            </Button>
          </Grid>
        </Grid>
        <ClientDataGrid
          id="CLIENTS_DATA_GRID"
          localeText={ptBR.props.MuiDataGrid.localeText}
          loading={loadingTab}
          disableColumnMenu
          rows={loadingTab ? [] : dataTableFilter}
          columns={columns}
          style={{ marginTop: 20, height: "calc(100% - 65px)" }}
          pageSize={pageSize}
          onPageSizeChange={(size) => setPageSize(size)}
          rowsPerPageOptions={[10, 25, 50, 100]}
          disableSelectionOnClick
          scrollbarSize={13}
          page={page}
          onPageChange={(p) => setPage(p)}
        />
      </div>
    </>
  );
}
