import {
  Box,
  CardContent,
  Chip,
  CircularProgress,
  Grid,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import BusinessIcon from "@material-ui/icons/Business";
import WarningIcon from "@material-ui/icons/Warning";
import styled from "styled-components";

export const BodyStyle = styled(Box)`
  max-height: 600px;
  padding-right: 20px;
  padding-left: 20px;
  overflow-y: auto;
  margin-bottom: 10px;
`;

export const DivStyle = styled.div`
  width: 100%;
  height: 1px;
  margin-bottom: 10px;
`;

export const CircularProgressStyled = styled(CircularProgress)`
  margin-left: 47%;
  width: 100px;
  height: 100px;
  margin-top: 74px;
  margin-bottom: 57px;
`;

export const DivNew = styled.div`
  position: relative;
  right: 10px;
  bottom: 10px;
  float: right;
  margin-top: 20px;

  .Button {
    font-weight: 500;
    background: rgba(240, 241, 243, 1);
    color: rgba(54, 68, 97, 1);
    width: 150px;
    right: 10px;
    text-transform: capitalize;
  }

  .ButtonSecondary {
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;

    background: rgba(0, 54, 179, 1);
    color: white;
    width: 150px;
    right: 10px;
    float: right;
    text-transform: capitalize;
  }
`;

export const CardContentStyled = styled(CardContent)`
  border: 1px solid rgba(207, 216, 220, 1);
  border-radius: 8px
  gap: 8px;
  padding: 0px;
`;

export const TypographyStyled = styled(Typography)`
  font-size: 14px;
  margin-top: 10px;
  padding-left: 15px;
`;

export const TypographyOBS = styled(Typography)`
  font-size: 14px;
  margin-top: 10px;
  padding-left: 15px;
`;

export const GridContainer = styled(Grid).attrs((props) => ({
  spacing: 1,
  xs: 12,
}))`
  margin-top: 5px;
  padding-left: 15px;
`;

export const GridSecondary = styled(Grid).attrs((props) => ({
  spacing: 1,
  xs: 12,
}))`
  padding-left: 15px;
`;

export const GridEvents = styled(Grid).attrs((props) => ({
  spacing: 1,
  xs: 12,
}))`
  margin-top: 5px;
`;

export const TypographyEvents = styled(Typography)`
  font-size: 14px;
  padding-left: 15px;
`;

export const BusinessIconStyled = styled(BusinessIcon)`
  font-size: 30px;
`;

export const WarningIconStyled = styled(WarningIcon)`
  margin-top: 10px;
  font-size: 30px;
`;

export const TypographyFontSize = styled(Typography)`
  margin-top: 5px;
  font-size: 14px;
`;

export const SnackbarNFE = styled.div`
  background: #1e60fb;
  width: 70px;
  height: 40px;
  padding: 8px, 24px, 8px, 24px;
  border-radius: 40px;
  gap: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  color: white;
  margin-bottom: 10px;
`;

export const SnackbarNFSE = styled.div`
  background-color: #ba68c8;
  width: 70px;
  height: 40px;
  padding: 8px, 24px, 8px, 24px;
  border-radius: 40px;
  gap: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  color: white;
  margin-bottom: 10px;
`;

export const SnackbarCTE = styled.div`
  background-color: #009688;
  width: 70px;
  height: 40px;
  padding: 8px, 24px, 8px, 24px;
  border-radius: 40px;
  gap: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  color: white;
  margin-bottom: 10px;
`;
export const SnackbarMDFE = styled.div`
  background-color: #fe7200;
  width: 70px;
  height: 40px;
  padding: 8px, 24px, 8px, 24px;
  border-radius: 40px;
  gap: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  color: white;
  margin-bottom: 10px;
`;
export const SnackbarCFE = styled.div`
  background-color: #7e57c2;
  width: 70px;
  height: 40px;
  padding: 8px, 24px, 8px, 24px;
  border-radius: 40px;
  gap: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  color: white;
  margin-bottom: 10px;
`;
export const SnackbarEvents = styled.div`
  background-color: #000000;
  width: 100px;
  height: 40px;
  padding: 8px, 24px, 8px, 24px;
  border-radius: 40px;
  gap: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  color: white;
  margin-bottom: 10px;
`;

export const SnackbarXMLNotIdentified = styled.div`
  background-color: #c92917;
  width: 100px;
  height: 40px;
  padding: 8px, 24px, 8px, 24px;
  border-radius: 40px;
  gap: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  color: white;
  margin-bottom: 10px;
`;

export const SnackbarDoucmentCNPJError = styled.div`
  background-color: #6f737e;
  width: 120px;
  height: 40px;
  padding: 8px, 24px, 8px, 24px;
  border-radius: 40px;
  gap: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  color: white;
  margin-bottom: 10px;
`;

export const TextContainer = styled.div`
  display: flex;
  margin-bottom: 5px;
`;

export const TextKey = styled.div`
  color: rgba(54, 68, 97, 1);
  font-weight: 700;
  text-align: left;
  margin-right: 5px;
`;

export const TextValue = styled.div`
  font-weight: 400;
  text-align: left;
`;

export const TextCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  border: 1px solid rgba(207, 216, 220, 1);
  margin-left: 15px;
  margin-right: 15px;
  background: linear-gradient(0deg, #f5f5f5, #f5f5f5),
    linear-gradient(0deg, #cfd8dc, #cfd8dc);
  padding: 16px;
  border-radius: 8px;
  border: 1px;
  gap: 8px;
`;

export const TypographyQTD = styled(Typography)`
  margin-top: 15px;
  font-size: 14px;
  color: #ff03e4;
`;

export const ChipStyle = styled(Chip)`
  color: grey-9;
  position: relative;
  bottom: 10px;
  float: right;
  margin-top: 10px;
  font-size: 12px;
  margin-right: 20px;
  font-weight: bold;
`;

export const DivCnpjNotIdentified = styled.div`
  font-weight: bold;
  margin-left: 5px;
  margin-bottom: 20px;
  color: red;
  font-size: 14px;
  margin-top: 15px;
`;

export const QuestionBotButton = styled.div`
  display: flex;
  align-items: center;
  margin-left: 16px;
  border-radius: 10px;
  cursor: pointer;
  border: 1px solid grey;
  padding: 5px;
  padding-left: 16px;
  padding-right: 16px;
  width: 250px;
  background: linear-gradient(
    315deg,
    rgba(8, 57, 169, 1) 0%,
    rgba(10, 115, 219, 1) 100%
  );
  text-transform: none;
  color: #fff;
  font-weight: bold;
`;
