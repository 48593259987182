import { DialogActions, DialogContent, Grid, Modal } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import {
  DialogStyle,
  DialogHeader,
  CloseIconStyle,
  ButtonCancel,
} from "../../../components/modal/Modal";
import { DescriptionVideo } from "../styles/KnowledgeBase";
import ContentComponent from "./ContentComponent";

const ContentModal = ({
  open,
  handleClose,
  title,
  description,
  url,
  typeFile,
  formattedDate,
  listRelatedContent = [],
  listContent = [],
  ...props
}) => {
  const [openRelatedContent, setOpenRelatedContent] = useState(false);

  return (
    <Modal open={open} style={{}}>
      <DialogStyle
        style={{
          width: "95vw",
          backgroundColor: "#FBFBFD",
        }}
      >
        <DialogHeader>
          <div className="row">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              {title}
            </div>
            <CloseIconStyle
              onClick={() => {
                handleClose();
              }}
            />
          </div>
        </DialogHeader>

        <DialogContent
          style={{
            minHeight: "75vh",
            maxHeight: "75vh",
          }}
        >
          <div style={{ height: "100%" }}>
            <div
              style={{
                minHeight: typeFile === "pdf" ? "70vh" : "40VH",
                display: "flex",
              }}
            >
              {!openRelatedContent && (
                <iframe
                  width="100%"
                  style={{ flex: 1 }}
                  src={url}
                  title={title}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              )}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
                padding: 0,
                margin: 0,
              }}
            >
              <DescriptionVideo>{formattedDate}</DescriptionVideo>
            </div>

            <div
              style={{
                minHeight: "calc(100% - 65px)",
                display: "flex",
                gap: 8,
              }}
            >
              <div
                style={{
                  background: "#fff",
                  flex: 1,
                  padding: 16,
                  borderRadius: 12,
                  boxShadow: "0px 2px 2px 0px #00000014",
                }}
              >
                <div
                  style={{ fontWeight: 700, fontSize: 24, color: "#364461" }}
                >
                  {title}
                </div>
                <div
                  style={{ fontWeight: 300, fontSize: 14, color: "#364461" }}
                >
                  {description}
                </div>
              </div>
              <div style={{ flex: 1, padding: 16 }}>
                <div
                  style={{
                    color: "#424242",
                    fontSize: 16,
                    fontWeight: 400,
                    marginBottom: 16,
                  }}
                >
                  Conteúdos Relacionados
                </div>
                <Grid container spacing={1}>
                  {listContent
                    .filter((item) => listRelatedContent.includes(item.id))
                    .map((item) => (
                      <ContentComponent
                        size={12}
                        formattedDate={item.formattedDate}
                        description={item.description}
                        title={item.title}
                        url={item.url}
                        typeFile={item.file}
                        duration={item?.duration}
                        thumbnail={item?.thumbnail}
                        dateEntered={item.dateEntered}
                        listRelatedContent={item?.related}
                        handleOpenRelatedContent={() => {
                          setOpenRelatedContent(true);
                        }}
                        handleCloseRelatedContent={() => {
                          setOpenRelatedContent(false);
                        }}
                        listContent={listContent}
                      ></ContentComponent>
                    ))}
                </Grid>
              </div>
            </div>
          </div>
        </DialogContent>

        <DialogActions>
          <ButtonCancel
            onClick={() => {
              handleClose();
            }}
          >
            Fechar
          </ButtonCancel>
        </DialogActions>
      </DialogStyle>
    </Modal>
  );
};

export default ContentModal;
