import * as React from "react";

function ArchiveIcon(props) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="a"
        style={{
          maskType: "alpha",
        }}
        maskUnits="userSpaceOnUse"
        x={-1}
        y={0}
        width={25}
        height={25}
      >
        <path
          fill={props?.fill || "#D9D9D9"}
          d="M-0.00488281 0.00500488H23.99511719V24.00500488H-0.00488281z"
        />
      </mask>
      <g mask="url(#a)">
        <path
          d="M11.995 18.005l4-4-1.4-1.4-1.6 1.6v-4.2h-2v4.2l-1.6-1.6-1.4 1.4 4 4zm-7-10v11h14v-11h-14zm0 13c-.55 0-1.02-.196-1.412-.587a1.926 1.926 0 01-.588-1.413V6.53c0-.233.038-.458.113-.675.075-.217.187-.417.337-.6l1.25-1.525c.183-.233.413-.413.688-.538.275-.125.562-.187.862-.187h11.5c.3 0 .588.063.863.188.275.124.504.304.687.537l1.25 1.525c.15.183.263.383.338.6.075.217.112.442.112.675v12.475c0 .55-.196 1.02-.587 1.413a1.926 1.926 0 01-1.413.587h-14zm.4-15h13.2l-.85-1h-11.5l-.85 1z"
          fill={props?.fill || "#616161"}
        />
      </g>
    </svg>
  );
}

export default ArchiveIcon;
