import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";

import "./styles/Attendance.css";

import {
  Box,
  CircularProgress,
  Divider,
  TextareaAutosize,
} from "@material-ui/core";
import Btn from "../../components/buttons/Btn";
import { Margin, Title } from "../../styles/GlobalStyle";
import { notify } from "../../utils/notify";

import { Grid } from "@material-ui/core";
import {
  getAnswerRate,
  getAttendance,
  getRate,
  postAnswerRate,
} from "../../repositories/integrations/crm/AttendanceRepository";
import { RatingRow } from "./childrens/RatingRow";

export default function AvaliationAttendance() {
  const { push } = useHistory();
  const [message, setMessage] = useState("");
  const [rates, setRates] = useState([]);
  const [attendance, setAttendance] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const attendanceId = window.location.search.replace("?attendanceId=", "");
    setAttendance(attendanceId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    try {
      if (attendance) {
        setLoading(true);
        const loadQuestions = async () => {
          const resultRate = await getRate();
          const rateData = resultRate.items.map((rate) => {
            return {
              id: rate.questionId,
              title: rate.description,
              value: 0,
              setValue: (newValue) => (rate.value = newValue),
            };
          });
          setRates(rateData);
        };

        const loadData = async () => {
          if (attendance.length > 0) {
            const resultAnswer = await getAnswerRate({
              attendanceId: attendance,
            });
            if (resultAnswer?.items?.length > 0 && resultAnswer?.items) {
              notify("Atendimento já avaliado", true);
              push("/dashboard/view");
            } else {
              const resultAttendance = await getAttendance({
                attendanceId: attendance,
              });
              if (resultAttendance?.items) {
                if (!resultAttendance?.items[0]?.solucionDate) {
                  notify(
                    "Não é possível avaliar um atendimento não finalizado",
                    true
                  );
                  push("/dashboard/view");
                } else {
                  setLoading(false);
                  loadQuestions();
                }
              } else {
                notify("Atendimento não encontrado", true);
                push("/dashboard/view");
              }
            }
          }
        };

        loadData();
      } else {
        notify("Atendimento não encontrado", true, "error");
        push("/dashboard/view");
      }
    } catch (error) {
      console.error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attendance]);

  function handleSetValue(value, id) {
    const newValues = rates.map((item) => {
      if (item.id === id) {
        item.value = value;
      }
      return item;
    });
    setRates(newValues);
  }

  const handleSave = async () => {
    const isNullRate = rates.find((item) => item.value === 0);

    if (isNullRate) return notify("Preencha as notas", true);
    const dataRate = {
      questions: rates,
      attendanceId: +attendance,
      observation: message,
      feedBackObservation: null,
    };

    const resultPost = await postAnswerRate({
      body: dataRate,
    });

    if (resultPost) {
      notify("Avaliação enviada com sucesso", true, "success");
      push("/dashboard/view");
    } else {
      notify("Não foi possível salvar a avaliação", true, "error");
    }
  };

  return (
    <>
      {
        <>
          <Grid item flex={1}>
            <Title> Avalie o atendimento #{attendance} </Title>
          </Grid>
          <Grid item xs={12}>
            <Margin top="8" bottom="22">
              <Divider />
            </Margin>
          </Grid>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: 20,
              paddingTop: 0,
              textAlign: "center",
              width: "100%",
            }}
          >
            {loading ? (
              <CircularProgress />
            ) : (
              <Box
                style={{
                  alignItems: "left",
                  padding: 20,
                  paddingTop: 0,
                  textAlign: "left",
                  width: "80%",
                }}
              >
                {rates.map(({ id, title, value, setValue }, index) => {
                  return (
                    <>
                      <RatingRow
                        key={id}
                        value={value}
                        setValue={(newValue) => handleSetValue(newValue, id)}
                        title={title}
                      />
                      {index + 1 !== rates.length && (
                        <Divider
                          component="hr"
                          style={{ marginTop: 16, marginBottom: 24 }}
                        />
                      )}
                    </>
                  );
                })}

                <Divider
                  component="hr"
                  style={{ marginTop: 16, marginBottom: 24 }}
                />

                <Box textAlign="start">Conte-nos como podemos melhorar!</Box>

                <TextareaAutosize
                  minRows={3}
                  style={{
                    width: "100%",
                    marginTop: 4,
                    borderRadius: 5,
                    padding: 8,
                    borderColor: "#999",
                    resize: "vertical",
                    outline: "none",
                  }}
                  placeholder="Digite aqui"
                  value={message}
                  onChange={(event) => setMessage(event.target.value)}
                />

                <Btn
                  label="Enviar"
                  style={{ width: "100%" }}
                  onClick={handleSave}
                />
              </Box>
            )}
          </Box>
        </>
      }
    </>
  );
}
