import { search } from "../BaseRepositoryComponents";

const baseUrl = process.env.REACT_APP_NEW_PAINEL_API_URL;

export function getCompanyDataInBigDataByDocument(document) {
  return search({
    path: `${baseUrl}/v1/big_data/company?document=${document}`,
    method: "GET",
  });
}

export function getAddressDataInBigDataByCEP(postalCode) {
  return search({
    path: `${baseUrl}/v1/big_data/company/address?postal-code=${postalCode}`,
    method: "GET",
  });
}
