import { Container } from "@material-ui/core";
import styled from "styled-components";

export const StyledContainer = styled(Container)`
  padding: 0;
`;

export const StyledLayoutContainerBase = styled.div`
  display: flex;
  max-height: 100vh;
`;

export const DefaultPageContainer = styled.div`
  padding: 20px;
`;
