import React from "react";

import { Box } from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";

export function RatingRow({ title, value, setValue }) {
  return (
    <>
      <Box style={{ textAlign: "left", marginBottom: 8, fontSize: "20px" }}>
        {title}
      </Box>
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <Box
          style={{
            width: "20%",
            textAlign: "right",
            fontSize: 11,
            color: "#828282",
            paddingTop: "10px",
            marginRight: "10px",
          }}
        >
          Ruim
        </Box>
        <Rating
          style={{ justifyContent: "center", alignItems: "center" }}
          size="large"
          max={10}
          value={value}
          defaultValue={0}
          onChange={(event, newValue) => setValue(newValue)}
        />
        <Box
          style={{
            width: "20%",
            textAlign: "left",
            fontSize: 11,
            color: "#828282",
            paddingTop: "10px",
            marginLeft: "10px",
          }}
        >
          Excelente
        </Box>
      </Box>
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <Box style={{ width: "60%" }}>{value || 0} estrelas</Box>
      </Box>
    </>
  );
}
