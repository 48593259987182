import { Switch } from "react-router-dom";

import MiddlewareRoute from "../Middleware";
import { Feed } from "../../pages/feed/Feed";
import { useContext, useEffect } from "react";
import { GlobalContext } from "../../contexts/GlobalContext";

export default function FeedModule() {
  const { setBreadcrumbs } = useContext(GlobalContext);

  useEffect(() => {
    setBreadcrumbs([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Switch>
      <MiddlewareRoute path="/feed" sidebar component={Feed} />
    </Switch>
  );
}
