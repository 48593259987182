import axios from "axios";
import {
  isAccountant,
  isAccountantClient,
  isCompany,
} from "../utils/panelType";
import { notify } from "../utils/notify";

const BOT_PANEL_API = process.env.REACT_APP_NEW_PAINEL_API_URL;

const BOT_API = process.env.REACT_APP_BOT_API_URL;

const BOT_LAMBDA_API = process.env.REACT_APP_BOT_API_LAMBDA_URL;

export const getQuestions = async () => {
  return await axios
    .get(
      BOT_PANEL_API +
        "/v1/bot/question/" +
        localStorage.getItem("documentPanel")
    )
    .then((response) => {
      if (response.status !== 200) {
        return [];
      } else {
        const data = response.data;
        let generalQuestions = data.filter((e) => e.origin === null);
        let myQuestions = data.filter(
          (e) => e.origin !== null && e.origin !== "SUPPORT"
        );
        let knowledgeBaseQuestions = data.filter((e) => e.origin === "SUPPORT");

        generalQuestions = generalQuestions.map((e) => {
          return {
            text: e.question,
          };
        });

        myQuestions = myQuestions.map((e) => {
          return {
            text: e.question,
          };
        });

        knowledgeBaseQuestions = knowledgeBaseQuestions.map((e) => {
          return {
            text: e.question,
          };
        });

        return {
          generalQuestions: generalQuestions,
          myQuestions: myQuestions,
          knowledgeBaseQuestions: knowledgeBaseQuestions,
        };
      }
    })
    .catch((err) => {
      return [];
    });
};

export const trainBot = async (question, answer = "", govid) => {
  const body = {
    product: "PANEL",
    question: question,
    answer: answer === "" ? "EMPTY" : answer,
    status: answer === "" ? "PENDING" : "DONE",
    status_description: answer === "" ? "Pending" : "Done",
  };

  if (govid !== "") {
    body["govid"] = govid;
    body["email"] = localStorage.getItem("emailPanel");
  }

  return await axios
    .post(BOT_PANEL_API + "/v1/bot/train", body)
    .then((response) => {
      if (response.status !== 201) {
        return null;
      } else {
        return response;
      }
    })
    .catch((err) => {
      return null;
    });
};

export const getBotMessage = async (text, mode, botActionId = null) => {
  const body = {
    text: text,
    name: localStorage.getItem("companyNamePanel"),
    govid: localStorage.getItem("documentPanel"),
    product: "PANEL",
    bot_question_id: botActionId,
    mode: mode,
  };

  const token = JSON.parse(localStorage.getItem("cognitoPanel"));

  const headers = {
    "Content-Type": "application/json",
    Authorization: token && token.AccessToken,
  };

  return await axios
    .post(BOT_PANEL_API + "/v1/bot/message", body, { headers: headers })
    .then((response) => {
      if (response.status !== 200) {
        return null;
      } else {
        return response;
      }
    })
    .catch((err) => {
      if (err.response) {
        return err.response;
      }
      return null;
    });
};

export const getBotTips = async () => {
  const token = JSON.parse(localStorage.getItem("cognitoPanel"));

  const headers = {
    "Content-Type": "application/json",
    Authorization: token && token.AccessToken,
  };

  let panel = "ALL";

  if (isAccountant() === true) {
    panel = "ACCOUNTANT";
  }

  if (isAccountantClient() === true) {
    panel = "ACCOUNTANT_CLIENT";
  }

  if (isCompany() === true) {
    panel = "COMPANY";
  }
  return await axios
    .get(BOT_LAMBDA_API + "/v1/tip?panel=" + panel, { headers: headers })
    .then((response) => {
      if (response.status !== 200) {
        return null;
      } else {
        return response;
      }
    })
    .catch((err) => {
      return null;
    });
};

export const markAsSeen = async (botActionId) => {
  const body = {
    bot_action_id: botActionId,
  };

  const headers = {
    "Content-Type": "application/json",
  };

  return await axios
    .post(BOT_LAMBDA_API + "/v1/action/seen", body, { headers: headers })
    .then((response) => {
      if (response.status !== 200) {
        return null;
      } else {
        return response;
      }
    })
    .catch((err) => {
      if (err.response) {
        return err.response;
      }
      return null;
    });
};

export const getActions = async (product) => {
  const headers = {
    "Content-Type": "application/json",
  };

  return await axios
    .get(
      `${BOT_LAMBDA_API}/v1/action?govid=${localStorage.getItem(
        "documentPanel"
      )}&product=${product}`,
      { headers: headers }
    )
    .then((response) => {
      if (response.status !== 200) {
        return null;
      } else {
        return response;
      }
    })
    .catch((err) => {
      if (err.response) {
        return err.response;
      }
      return null;
    });
};

export const getAllActions = async () => {
  const headers = {
    "Content-Type": "application/json",
  };

  return await axios
    .get(
      `${BOT_LAMBDA_API}/v1/action?govid=${localStorage.getItem(
        "documentPanel"
      )}`,
      { headers: headers }
    )
    .then((response) => {
      if (response.status !== 200) {
        return null;
      } else {
        return response;
      }
    })
    .catch((err) => {
      if (err.response) {
        return err.response;
      }
      return null;
    });
};

export const deleteAction = async (action, botActionId) => {
  const headers = {
    "Content-Type": "application/json",
  };

  return await axios
    .delete(
      `${BOT_LAMBDA_API}/v1/action?action=${action}&govid=${localStorage.getItem(
        "documentPanel"
      )}&bot_action_id=${botActionId}`,
      { headers: headers }
    )
    .then((response) => {
      if (response.status !== 200) {
        return null;
      } else {
        return response;
      }
    })
    .catch((err) => {
      if (err.response) {
        return err.response;
      }
      return null;
    });
};

export const createBoletoRegister = async (body) => {
  const headers = {
    "Content-Type": "application/json",
  };

  return await axios.post(BOT_LAMBDA_API + "/v1/boleto", body, {
    headers: headers,
  });
};

export const boletoExists = async (typeableLine) => {
  const headers = {
    "Content-Type": "application/json",
  };

  return await axios
    .get(
      `${BOT_LAMBDA_API}/v1/boleto?gov_id=${localStorage.getItem(
        "documentPanel"
      )}&typeable_line=${typeableLine}`,
      { headers: headers }
    )
    .then((response) => {
      return true;
    })
    .catch((err) => {
      if (err.response && err.response.status === 404) {
        return false;
      }
      return false;
    });
};

export const readCertificate = async (certificate) => {
  let responseCompany = await new Promise((resolve, reject) => {
    fetch(BOT_API + "/v1/bot/certificate/read", {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: "c799c146-6264-4bc6-94d7-56b4cac8deb0",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        pfx: certificate.base64,
        password: certificate.password,
      }),
    })
      .then((response) => resolve(response.json()))
      .catch((err) => resolve(null));
  });

  return responseCompany;
};

export const getBotImageGif = async () => {
  try {
    const response = await fetch(BOT_PANEL_API + "/v1/health", {
      method: "GET",
      redirect: "follow",
    });

    let data = await response.json();
    data = data.data;

    let image = "";
    image += data.sefaz ? "1" : "0";
    image += data.esocial ? "1" : "0";
    image += data.cnd ? "1" : "0";

    return image;
  } catch (err) {
    return "000";
  }
};

export const getBotInteration = async () => {
  try {
    const response01 = await fetch(
      BOT_PANEL_API +
        `/v1/bot/interation/${localStorage.getItem("documentPanel")}`,
      {
        method: "GET",
        redirect: "follow",
      }
    );

    const response02 = await fetch(
      BOT_PANEL_API +
        `/v1/bot/interation/${localStorage.getItem("emailPanel")}`,
      {
        method: "GET",
        redirect: "follow",
      }
    );

    let data01 = await response01.json();
    let data02 = await response02.json();

    if (data01.text) {
      return data01;
    }

    return data02;
  } catch (err) {
    return null;
  }
};

export const markInterationAsVisualized = async (id) => {
  try {
    const response = await fetch(BOT_PANEL_API + `/v1/bot/interation/${id}`, {
      method: "PUT",
      redirect: "follow",
    });

    let data = await response.json();

    return data;
  } catch (err) {
    return null;
  }
};

export async function searchBoleto(body) {
  return axios
    .post(`${BOT_PANEL_API}/v1/bot/boleto/search`, body, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("tokenPanel"),
        "Content-Type": "application/json",
        "Content-Language": "pt-BR",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      notify("Ocorreu um erro buscar o boleto!");
      return err;
    });
}
