import { search } from "../BaseRepositoryComponents";

const newPath = `${process.env.REACT_APP_NEW_PAINEL_API_URL}`;

export async function postPanel({ body }) {
  return search({
    path: `${newPath}/v1/panel`,
    method: "POST",
    myBody: body,
  });
}

export async function getPanelsUser({ id, ownerentity }) {
  return search({
    path: `${newPath}/v1/panel/user/${id}${
      ownerentity ? `?ownerentity=${ownerentity}` : ""
    }`,
    method: "GET",
  });
}
