import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";

import { GlobalContext } from "../../../contexts/GlobalContext";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Grid,
} from "@material-ui/core";

import { Title } from "../../../styles/GlobalStyle";

import Loading from "../../../components/basics/Loading";
import { notify } from "../../../utils/notify";
import Btn from "../../../components/buttons/Btn";
import { getWidgets } from "../../../repositories/portal_api/Widgets";

export default function ModalNews({ open, setOpen }) {
  const { push } = useHistory();
  const { userLogged, companyUser } = useContext(GlobalContext);

  const [card, setCard] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);

        const mainResponse = await getWidgets(
          userLogged.userId,
          companyUser.companyId
        );

        if (!mainResponse?.success || !Array.isArray(mainResponse?.data)) {
          return;
        }

        // contratos qyon
        const cardNews =
          mainResponse.data.find((card) => card.id === 189) || {};

        setCard(cardNews);
      } catch (error) {
        console.error(error);

        notify("Não foi possível obter os dados.");
      } finally {
        setLoading(false);
      }
    })();
  }, [companyUser, userLogged]);

  useEffect(() => {
    if (card?.widget_versions) {
      setTimeout(() => {
        try {
          // eslint-disable-next-line no-eval
          eval(card.widget_versions[0].refresh_function);
        } catch (error) {
          console.error(error);
        }
      }, 1500);
    }
  }, [card]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Loading open={loading} />
      <Dialog
        open={open}
        maxWidth="md"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onClose={handleClose}
      >
        <DialogTitle id="alert-dialog-title">
          <Title subtitle>NOVIDADE: Contratos Qyon - 2ª via Boleto</Title>
        </DialogTitle>
        <DialogContent>
          {card?.widget_versions && (
            <div
              dangerouslySetInnerHTML={{
                __html: card.widget_versions[0].widget_body,
              }}
              style={{
                height: "350px",
                width: "550px",
              }}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Grid container direction="column">
            <Typography>
              Este novo CARD foi adicionado ao nosso catálogo!
            </Typography>
            <Typography>
              Você pode ativá-lo nas{" "}
              <Btn
                onClick={() => push("/dashboard/setting")}
                label="configurações do seu painel"
                bg-color="tertiary"
              />
            </Typography>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
}
