import { useContext } from "react";

import { showUserVideoIntro } from "../../../repositories/panel/v1/UserRepository";
import { GlobalContext } from "../../../contexts/GlobalContext";

export default function useVideoFirstAccessController() {
  const { setUserLogged } = useContext(GlobalContext);

  const handleSubmitVideo = async () => {
    try {
      setUserLogged({ showVideoIntro: "false" });
      initialAnimation();
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  const initialAnimation = async () => {
    await showUserVideoIntro({
      body: {
        show_video_intro: false,
      },
    });
  };

  return {
    handleSubmitVideo,
  };
}
