import React, { useContext, useEffect } from "react";
import { GlobalContext } from "../../contexts/GlobalContext";
import useChatAssistantController from "../sidebar/controllers/ChatAssistantController";
import { GlobalVariables } from "../../GlobalVariables";
import { Grid, IconButton, Tooltip, makeStyles } from "@material-ui/core";
import NotificationBadge from "react-notification-badge/lib/components/NotificationBadge";
import { Pulse } from "../../styles/GlobalStyle";
import { Effect } from "react-notification-badge";
import IconsMenu from "../sidebar/IconsMenu";
import {
  StyledBotActionBar,
  StyledBotActionButton,
} from "./styles/BotActionBar";
import { useState } from "react";

import { useAuth } from "../../contexts/crm/Auth";
import InterrogationBot from "../sidebar/children/InterrogationBot";
import AttendanceController from "../../pages/attendance/controllers/AttendanceController";
import { BotNotificationModal } from "../sidebar/children/BotNotificationModal";
import { getAllActions } from "../../repositories/Bot";
import { NewBotContext } from "../newBot/context/NewBotContext";
import {
  ArrowBackIosOutlined,
  ArrowForwardIosOutlined,
} from "@material-ui/icons";

export const BotActionBar = ({
  customKey,
  setCustomKey,
  actions,
  setActions,
  actionTotal,
  setActionTotal,
  botRef,
  handleCloseDrawer,
  handleOpenDrawer,
  ...props
}) => {
  const { companyUser, animation, sidebaraction } = useContext(GlobalContext);
  const { AumentarChat } = useChatAssistantController();

  const [openOptionModal, setOpenOptionModal] = useState("");
  const [openActionModal, setOpenActionModal] = useState(false);
  const [actionModalProduct, setActionModalProduct] = useState(null);

  const botContext = useContext(NewBotContext);

  const { company } = useAuth();

  let emailCheckCrm;

  const useAttendanceController = AttendanceController();

  const buttonSyles = makeStyles({
    squareButtonStyle: {
      width: "10px",
      height: "10px",
      marginTop: "14px",
      marginBottom: "20px",
      marginLeft: "5px",
      border: "2px solid #ebecee",
      backgroundColor: "#ffffff",
      borderRadius: "4px",
      "&:hover": {
        backgroundColor: "#0338b4",
        color: "white",
      },
    },
    IconStyle: {
      fontSize: 12,
      "&:hover": {
        backgroundColor: "#0338b4",
        color: "white",
      },
    },
  });

  const classButton = buttonSyles();

  const standaloneUser =
    companyUser.isAccountant !== "true" && !companyUser.accountantId
      ? {
          filter: "grayscale(1)",
          cursor: "not-allowed",
        }
      : null;

  const hasFolhaAccess = !(
    standaloneUser &&
    !companyUser.systemsPermissions.find(
      (s) => s.id === GlobalVariables.SYSTEMS.Folha
    )?.configured
  );

  const hasContabilAccess = !(
    standaloneUser &&
    !companyUser.systemsPermissions.find(
      (s) => s.id === GlobalVariables.SYSTEMS.Contabil
    )?.configured
  );

  const hasFiscalAccess = !(
    standaloneUser &&
    !companyUser.systemsPermissions.find(
      (s) => s.id === GlobalVariables.SYSTEMS.Fiscal
    )?.configured
  );

  const isInClientView = sessionStorage.getItem("isInClientView");

  const refresh = async () => {
    async function fetchAPI() {
      let response = await getAllActions();

      setActionTotal(response.headers.count);

      if (
        response != null &&
        response.status === 200 &&
        response.data != null
      ) {
        let QYON_DASH = response.data.filter(
          (e) => e.product === "QYON_DASH" && e.seen === 0
        );
        let QYON_XML = response.data.filter(
          (e) => e.product === "QYON_XML" && e.seen === 0
        );
        let QYON_ERP = response.data.filter(
          (e) => e.product === "QYON_ERP" && e.seen === 0
        );
        let QYON_ERP_ADM = response.data.filter(
          (e) => e.product === "QYON_ERP_ADM" && e.seen === 0
        );
        let QYON_ACCOUNTANT = response.data.filter(
          (e) => e.product === "QYON_ACCOUNTANT" && e.seen === 0
        );
        let QYON_CRM = response.data.filter(
          (e) => e.product === "QYON_CRM" && e.seen === 0
        );
        let QYON_BANK = response.data.filter(
          (e) => e.product === "QYON_BANK" && e.seen === 0
        );

        let statistics = [
          {
            product: "QYON_DASH",
            total: QYON_DASH.length,
          },
          {
            product: "QYON_XML",
            total: QYON_XML.length,
          },
          {
            product: "QYON_ERP",
            total: QYON_ERP.length,
          },
          {
            product: "QYON_ERP_ADM",
            total: QYON_ERP_ADM.length,
          },
          {
            product: "QYON_ACCOUNTANT",
            total: QYON_ACCOUNTANT.length,
          },
          {
            product: "QYON_CRM",
            total: QYON_CRM.length,
          },
          {
            product: "QYON_BANK",
            total: QYON_BANK.length,
          },
        ];

        setActions(statistics);
      }
    }

    fetchAPI();
  };

  const onDecreaseBadge = async () => {
    await refresh();

    setCustomKey(Math.random());
  };

  const onCloseActionModal = () => {
    setOpenActionModal(false);
  };

  useEffect(() => {
    clearInterval(emailCheckCrm);
    if (companyUser.isAccountant === "true") {
      if (company.id) {
        if (company?.hasSMTPConfiguration) {
          // eslint-disable-next-line react-hooks/exhaustive-deps
          emailCheckCrm = setInterval(async () => {
            await useAttendanceController.checkEmailToCreatAtendance();
          }, 1000 * 60 * 5);
        }
      } else {
        clearInterval(emailCheckCrm);
      }
    }
  }, [company.id, company?.hasSMTPConfiguration]);

  return (
    <StyledBotActionBar>
      <Grid
        container
        style={{
          display: "flex",
          justifyContent: "flex-start",
        }}
      >
        <IconButton
          className={classButton.squareButtonStyle}
          onClick={sidebaraction.open ? handleCloseDrawer : handleOpenDrawer}
        >
          {sidebaraction.open ? (
            <ArrowForwardIosOutlined className={classButton.IconStyle} />
          ) : (
            <ArrowBackIosOutlined className={classButton.IconStyle} />
          )}
        </IconButton>
      </Grid>

      <Tooltip title="Qyon Bot">
        <StyledBotActionButton
          fullWidth
          color="primary"
          aria-label="add"
          hasAccess={true}
          onClick={() => {
            handleOpenDrawer();

            botContext.setBotMode("CHAT");

            botContext.setBotPresentationRunning(null);
            if (botContext.isBotPresentationRunning === true) {
              botRef.current.stop_bot_presentation_to_open_chat();
            }
            botContext.setOpenBotChatModal(true);
          }}
        >
          <Pulse active={animation === 6}>
            <Grid alignItems="center" container justifyContent="center">
              <NotificationBadge
                count={0}
                effect={Effect.SCALE}
                key={customKey}
              />
              <IconsMenu icon="BotIcon" color="#0a73db" />
            </Grid>
          </Pulse>
        </StyledBotActionButton>
      </Tooltip>

      <Tooltip
        title={
          !hasFiscalAccess
            ? "Para que essa função seja ativada, você necessita estar conectado à um escritório que seja cliente Qyon."
            : "Notificações da escrita fiscal"
        }
      >
        <StyledBotActionButton
          fullWidth
          color="primary"
          aria-label="add"
          hasAccess={hasFiscalAccess}
          onClick={
            hasFiscalAccess
              ? () => {
                  setOpenActionModal(true);
                  setActionModalProduct("QYON_WRITING_TAX");
                }
              : () => {}
          }
        >
          <Pulse active={animation === 6}>
            <Grid alignItems="center" container justifyContent="center">
              <NotificationBadge
                count={0}
                effect={Effect.SCALE}
                key={customKey}
              />
              <IconsMenu icon="NewEscritaIcon" color="#66bb6a" />
            </Grid>
          </Pulse>
        </StyledBotActionButton>
      </Tooltip>
      <Tooltip
        title={
          !hasFolhaAccess
            ? "Para que essa função seja ativada, você necessita estar conectado à um escritório que seja cliente Qyon."
            : "Notificações da folha de pagamento"
        }
      >
        <StyledBotActionButton
          color="primary"
          aria-label="add"
          hasAccess={hasFolhaAccess}
          onClick={
            hasFolhaAccess
              ? () => {
                  setOpenActionModal(true);
                  setActionModalProduct("QYON_ACCOUNTANT");
                }
              : () => {}
          }
        >
          <Pulse active={animation === 6}>
            <Grid container alignItems="center" justifyContent="center">
              <div
                style={{
                  position: "absolute",
                  top: "0px",
                  right: "0px",
                }}
              >
                <NotificationBadge
                  count={
                    actions.filter((e) => e.product === "QYON_ACCOUNTANT")[0]
                      .total
                  }
                  effect={Effect.SCALE}
                  key={customKey}
                />
              </div>
              <IconsMenu icon="NewFolhaIcon" color="#1ed2fb" />
            </Grid>
          </Pulse>
        </StyledBotActionButton>
      </Tooltip>
      <Tooltip
        title={
          !hasContabilAccess
            ? "Para que essa função seja ativada, você necessita estar conectado à um escritório que seja cliente Qyon."
            : "Notificações do contábil"
        }
      >
        <StyledBotActionButton
          color="primary"
          aria-label="add"
          size="medium"
          top={"48"}
          right="210"
          hasAccess={hasContabilAccess}
          onClick={
            hasContabilAccess
              ? () => {
                  setOpenActionModal(true);
                  setActionModalProduct("QYON_CONTABIL");
                }
              : () => {}
          }
        >
          <Pulse active={animation === 6}>
            <Grid alignItems="center" container justifyContent="center">
              <NotificationBadge
                count={0}
                effect={Effect.SCALE}
                key={customKey}
              />
              <IconsMenu icon="NewContabilIcon" color="#f8c880" />
              {/* ADD ICON */}
            </Grid>
          </Pulse>
        </StyledBotActionButton>
      </Tooltip>
      <Tooltip
        title={
          isInClientView
            ? "Para que essa função seja ativada, você necessita sair da visão de cliente e retornar à visão de contador."
            : "Atendimento e Suporte Técnico dos Produtos Qyon"
        }
      >
        <StyledBotActionButton
          color="primary"
          aria-label="add"
          size="medium"
          hasAccess={!isInClientView}
          onClick={() => {
            if (isInClientView) return;
            AumentarChat("1");
          }}
        >
          <div
            id="chatGeiko"
            className="topcornerGeiko"
            style={{ display: "none", marginRight: 318 }}
          ></div>

          <IconsMenu icon="SupportIcon" color="#0a73db"></IconsMenu>
        </StyledBotActionButton>
      </Tooltip>

      {openOptionModal.includes("interrogationBot") && (
        <InterrogationBot open={openOptionModal} setOpen={setOpenOptionModal} />
      )}

      {openActionModal && (
        <BotNotificationModal
          open={openActionModal}
          product={actionModalProduct}
          onDecreaseBadge={onDecreaseBadge}
          onCloseActionModal={onCloseActionModal}
        />
      )}
    </StyledBotActionBar>
  );
};
