import React, { createContext, useRef, useState } from "react";

const EmailTemplateContext = createContext();

const EmailTemplateContextProvider = ({ children }) => {
  const emailEditorRef = useRef(null);
  const [templateName, setTemplateName] = useState("");
  const [templateId, setTemplateId] = useState("");
  const [templateType, setTemplateType] = useState("");
  const [templateTag, setTemplateTag] = useState("geral");
  const [editorTag, setEditorTag] = useState("");
  const [editorType, setEditorType] = useState("");
  const [typeNotification, setTypeNotification] = useState("");

  const [openEditorTemplate, setOpenEditorTemplate] = useState(false);

  const [pageTemplateList, setPageTemplateList] = useState(0);
  const [listTemplates, setListTemplates] = useState([]);

  return (
    <EmailTemplateContext.Provider
      value={{
        emailEditorRef,
        templateName,
        setTemplateName,
        templateId,
        setTemplateId,
        templateType,
        setTemplateType,
        templateTag,
        setTemplateTag,
        editorTag,
        setEditorTag,
        editorType,
        setEditorType,
        typeNotification,
        setTypeNotification,
        pageTemplateList,
        setPageTemplateList,
        listTemplates,
        setListTemplates,
        openEditorTemplate,
        setOpenEditorTemplate,
      }}
    >
      {children}
    </EmailTemplateContext.Provider>
  );
};

export { EmailTemplateContext, EmailTemplateContextProvider };
