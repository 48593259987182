import * as React from "react";

function PdfKnowledgeBaseIcon(props) {
  return (
    <svg
      width={props?.width || 24}
      height={props?.height || 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.5 3a2 2 0 012-2h5.672a2 2 0 011.414.586l5.328 5.328a2 2 0 01.586 1.414V21a2 2 0 01-2 2h-11a2 2 0 01-2-2V3z"
        fill="#E5252A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5 1.027a2 2 0 011.086.559l5.328 5.328A2 2 0 0119.473 8H13.5a1 1 0 01-1-1V1.027z"
        fill="#ED676A"
      />
      <path
        d="M6.867 16v-4.223h1.666c.32 0 .593.062.819.184.225.12.397.29.515.505.12.214.18.462.18.742 0 .28-.06.528-.182.743a1.251 1.251 0 01-.526.5c-.228.12-.504.18-.829.18H7.45v-.716h.917a.9.9 0 00.425-.088.587.587 0 00.251-.25.782.782 0 00.085-.369.761.761 0 00-.085-.367.564.564 0 00-.251-.245.92.92 0 00-.429-.089H7.76V16h-.893zM12.123 16h-1.496v-4.223h1.509c.425 0 .79.085 1.097.254.306.168.542.409.707.724.166.314.25.691.25 1.13 0 .44-.084.818-.25 1.134a1.714 1.714 0 01-.711.727c-.308.17-.677.254-1.106.254zm-.604-.765h.567c.264 0 .486-.047.666-.14a.902.902 0 00.409-.44c.092-.199.138-.456.138-.77 0-.313-.046-.567-.138-.765a.895.895 0 00-.407-.438 1.434 1.434 0 00-.666-.14h-.569v2.693zM14.85 16v-4.223h2.797v.736h-1.903v1.007h1.717v.736h-1.717V16h-.893z"
        fill="#fff"
      />
    </svg>
  );
}

export default PdfKnowledgeBaseIcon;
