import { search } from "./BaseRepositoryComponents";

const newPath = `${process.env.REACT_APP_NEW_PAINEL_API_URL}`;

export async function getNotificationTree() {
  const url = `${newPath}/v1/comms-config/get-notification-tree`;

  return search({
    path: url,
    method: "GET",
  });
}

export async function getNotificationServices() {
  const url = `${newPath}/v1/comms-config/get-notification-services`;

  return search({
    path: url,
    method: "GET",
  });
}

// FIXME not used
// export async function getSystemsServices() {
//   const url = `${newPath}/v1/comms-config/get-notification-system-services`;

//   return search({
//     path: url,
//     method: "GET",
//   });
// }

export async function getSystemsServicesBySystemId(systemId, entityId) {
  const url = `${newPath}/v1/comms-config/get-notification-system-services/${systemId}/${entityId}`;

  return search({
    path: url,
    method: "GET",
  });
}

export async function getSystemsServicesBySystemIdAccountant(systemId) {
  const url = `${newPath}/v1/comms-config/get-notification-system-services/${systemId}`;

  return search({
    path: url,
    method: "GET",
  });
}

export async function postConfiguration(body) {
  const url = `${newPath}/v1/comms-config/post-configuration`;

  return search({
    path: url,
    method: "POST",
    myBody: body,
  });
}
