import { CircularProgress, Grid } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import {
  CreateTemplateButton,
  CreateTemplateButtonFrame,
  CreateTemplateFrame,
  TemplateTypeChips,
} from "../../styles/ModalEmailTemplates";
import { ModalDeleteTemplate } from "../../ModalTemplates/ModalDeleteTemplate";
import { ModalSendTestEmailTemplate } from "../../ModalTemplates/ModalSendTestEmailTemplate";
import { notify } from "../../../../utils/notify";
import {
  deleteTemplate,
  getTemplateFiles,
  getTemplateImage,
} from "../../../../repositories/EmailTemplate";
import { GlobalContext } from "../../../../contexts/GlobalContext";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import TemplateComponent from "./TemplateComponent";
import { EmailTemplateContext } from "../../contexts/EmailTemplateContext";
import { ModalEditTemplate } from "../../ModalTemplates/ModalEditTemplate";

const ModalCommsConfigTemplateBody = ({
  serviceId,
  selectedServices,
  setSelectedServices,
  handleCreateNewTemplateClick,
  DEFAULT_INSTANT_TEMPLATE,
  DEFAULT_SCHEDULED_TEMPLATE,
  ...props
}) => {
  const [loading, setLoading] = useState(false);
  const [loadingImage, setLoadingImage] = useState(false);
  const [openDeleteTemplateModal, setOpenDeleteTemplateModal] = useState(false);
  const [openSendTestEmailModal, setOpenSendTestEmailModal] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  const { companyUser } = useContext(GlobalContext);

  const {
    listTemplates,
    setListTemplates,
    setEditorType,
    openEditorTemplate,
    setOpenEditorTemplate,
    setTemplateName,
    setTemplateType,
    setTypeNotification,
  } = useContext(EmailTemplateContext);

  useEffect(() => {
    if (!openEditorTemplate) {
      setSelectedTemplate(null);
      setListTemplates([]);
    } else {
      setTemplateType("portal");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openEditorTemplate]);

  useEffect(() => {
    let currentTemplate = listTemplates.filter(
      (template) => template.id === selectedServices[serviceId].template_id
    );

    if (currentTemplate.length > 0) {
      setSelectedTemplate(currentTemplate[0]);
    }
  }, [selectedServices, listTemplates, serviceId]);

  useEffect(() => {
    if (!openDeleteTemplateModal) {
      setLoading(true);
      let newTypeNotification =
        selectedServices[serviceId].type_notification === 0
          ? "instant"
          : "scheduled";

      setTypeNotification(newTypeNotification);

      getTemplateFiles(companyUser.document, newTypeNotification)
        .then((response) => {
          if (response?.success) {
            if (response?.data?.length > 0) {
              let responseFilter = response.data.filter(
                (template) => template.tag === "geral"
              );

              setListTemplates(responseFilter);
            } else {
              setListTemplates([]);
            }
          } else {
            notify("Ocorreu um erro ao carregar os templates!");
            setListTemplates([]);
          }
        })
        .catch((err) => {
          console.error(err);
          notify("Ocorreu um erro ao carregar os templates!");
        });
      setLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    serviceId,
    companyUser.document,
    openEditorTemplate,
    openDeleteTemplateModal,
  ]);

  function handleOpenEditTemplate(template) {
    setSelectedTemplate(template);
    setEditorType("Edit");
    setOpenEditorTemplate(true);
  }

  function handleOpenNewTemplate() {
    setEditorType("New");
    setOpenEditorTemplate(true);
  }

  function handleOpenModalSendTestEmailTemplate() {
    setOpenSendTestEmailModal(true);
  }
  function handleOpenModalDeleteTemplate(template) {
    setSelectedTemplate(template);
    setOpenDeleteTemplateModal(true);
  }

  function setDefaultTemplate() {
    if (selectedServices[serviceId].type_notification === 0) {
      let newServices = { ...selectedServices };
      newServices[serviceId].template_id = DEFAULT_INSTANT_TEMPLATE;
      setSelectedServices(newServices);
    } else {
      let newServices = { ...selectedServices };
      newServices[serviceId].template_id = DEFAULT_SCHEDULED_TEMPLATE;
      setSelectedServices(newServices);
    }
  }

  async function handleConfirmDeleteTemplate() {
    let response = await deleteTemplate(
      selectedTemplate?.id,
      companyUser.document,
      selectedTemplate?.type
    );

    if (response?.success) {
      notify("Template deletado com sucesso!", true, "success");
    } else {
      notify("Ocorreu um erro ao deletar o template!");
    }

    setDefaultTemplate();
  }

  return (
    <>
      {loading ? (
        <div
          style={{ display: "flex", justifyContent: "center", padding: "30px" }}
        >
          <CircularProgress />
        </div>
      ) : openEditorTemplate ? (
        <ModalEditTemplate
          clickedTemplate={selectedTemplate}
          templateTag={"geral"}
          totalFilteredTemplates={listTemplates}
          selectedService={selectedServices[serviceId]}
          style={{ maxHeight: "80vh" }}
          type={"portal"}
        />
      ) : (
        <>
          <Grid
            container
            justifyContent="space-between"
            xs={12}
            style={{ marginBottom: "14px", gap: "2px" }}
          >
            <TemplateTypeChips active={true}>Geral</TemplateTypeChips>
          </Grid>
          <Grid container spacing={2}>
            <CreateTemplateFrame
              item
              xs={6}
              onClick={() => {
                if (listTemplates.length < 10) {
                  // handleCreateNewTemplateClick();
                  setTemplateName("");
                  handleOpenNewTemplate();
                } else {
                  notify(
                    "Limite Máximo de Templates Para a Categoria Atingido!",
                    true,
                    "warning"
                  );
                }
              }}
            >
              <CreateTemplateButtonFrame>
                <CreateTemplateButton>
                  <AddCircleOutlineIcon style={{ marginRight: "8px" }} />
                  Criar Template
                </CreateTemplateButton>
              </CreateTemplateButtonFrame>
            </CreateTemplateFrame>

            {companyUser.isAccountant && listTemplates ? (
              <TemplateComponent
                listTemplates={listTemplates}
                companyUser={companyUser}
                isLoadingImage={loadingImage}
                selectedTemplateId={selectedServices[serviceId].template_id}
                handleChangeTemplate={(id) => {
                  let newServices = { ...selectedServices };
                  newServices[serviceId].template_id = id;
                  setSelectedServices(newServices);
                }}
                handleOpenEditTemplate={handleOpenEditTemplate}
                handleOpenModalSendTestEmailTemplate={
                  handleOpenModalSendTestEmailTemplate
                }
                handleOpenModalDeleteTemplate={handleOpenModalDeleteTemplate}
              />
            ) : null}
          </Grid>
          <ModalDeleteTemplate
            open={openDeleteTemplateModal}
            handleClose={() => setOpenDeleteTemplateModal(false)}
            handleConfirmDeleteTemplate={handleConfirmDeleteTemplate}
            selectedTemplate={selectedTemplate}
            document={companyUser.document}
          />
          <ModalSendTestEmailTemplate
            open={openSendTestEmailModal}
            clickedTemplate={selectedTemplate}
            handleClose={() => setOpenSendTestEmailModal(false)}
          />
        </>
      )}
    </>
  );
};

export default ModalCommsConfigTemplateBody;
