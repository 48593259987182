import { createContext, useState } from "react";

const KnowledgeBaseContext = createContext();

const KnowledgeBaseContextProvider = ({ children }) => {
  const [filterContentType, setFilterContentType] = useState("all");
  const [filterSearch, setFilterSearch] = useState("");
  const [contentList, setContentList] = useState([]);
  const [filterContentTypeFile, setFilterContentTypeFile] = useState("all");

  return (
    <KnowledgeBaseContext.Provider
      value={{
        filterContentType,
        setFilterContentType,
        filterSearch,
        setFilterSearch,
        contentList,
        setContentList,
        filterContentTypeFile,
        setFilterContentTypeFile,
      }}
    >
      {children}
    </KnowledgeBaseContext.Provider>
  );
};

export { KnowledgeBaseContext, KnowledgeBaseContextProvider };
