import React, { useEffect, useState, useContext } from "react";

import {
  Grid,
  Modal,
  DialogContent,
  MenuItem,
  CircularProgress,
} from "@material-ui/core";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { getMP3Audio } from "../../repositories/AudioHandler";
import DescriptionRoundedIcon from "@material-ui/icons/DescriptionRounded";
import { getDescription } from "../../utils/DocumentUtil";
import { DocumentDasTab } from "./children/DocumentDasTab";
import { DocumentDarfTab } from "./children/DocumentDarfTab";
import { DocumentBoletoTab } from "./children/DocumentBoletoTab";
import { DocumentFgtsTab } from "./children/DocumentFgtsTab";
import useDocumentModalController from "./controllers/DocumentModalController";
import { DocumentDctfReturnTab } from "./children/DocumentDctfReturnTab";
import { ModalCheckboxes } from "./children/ModalCheckboxes";
import {
  dialogTitleChildrenRow,
  dialogTitleChildrenColumn,
  dialogTitleClose,
  documentContainer,
  tabPanelStyle,
  nameDocumentStyle,
  PdfIframe,
  DivTextLeft,
  DivSecondTitle,
  NotIdentifiedDocumentName,
  PdfImgWrapperDiv,
  DocumentModalFooterWrapper,
  pdfImg,
  floatAlign,
  TextFieldDocumentName,
} from "./styles/DocumentModal.js";
import { GlobalContext } from "../../contexts/GlobalContext";
import {
  ButtonCancel,
  ButtonSave,
  CloseIconStyle,
  DialogHeader,
  DialogStyle,
} from "../modal/Modal.js";
import {
  getEntityDataById,
  getAccountantClients,
} from "../../repositories/portal_api/AccountantClient";
import { notify } from "../../utils/notify.js";
import { SelectFilterStyled } from "../../pages/customerManagements/styles/CustomerManagementStyle.js";
import { DarfObject } from "./utils/DarfObject.js";
import { DasObject } from "./utils/DasObject.js";
import { BoletoObject } from "./utils/BoletoObject.js";
import { FgtsObject } from "./utils/FgtsObject.js";
import { DctfObject } from "./utils/DctfObject.js";
import { NotIdentifiedObject } from "./utils/NotIdentifiedObject.js";
export const DocumentModal = (props) => {
  const { companyUser } = useContext(GlobalContext);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [disabled, setDisabled] = useState(false);
  const [screen] = useState("DOCUMENT_DATA");
  const [, setValue] = useState([0]);
  const [users, setUsers] = useState([]);
  const [key, setKey] = useState(Math.random());
  const [cpfCnpjFocus, setCpfCnpjFocus] = useState(false);
  const [cpfCnpjFocus1, setCpfCnpjFocus1] = useState(false);
  const [govIdKey] = useState(1);
  const [typeFileState, setTypeFileState] = useState("guide");
  const [openTicketInCRM, setOpenTicketInCrm] = useState(false);

  const [fullList, setFullList] = useState([]);
  const [isLoadingfullList, setIsLoadingSetFullList] = useState(false);
  const [docTypeSelected, setDocTypeSelected] = useState();
  const [docTypeSelectedChanged, setDocTypeSelectedChanged] = useState(false);
  const [changeDocTypeLoading, setChangeDocTypeLoading] = useState(false);
  const [documentList, setDocumentList] = useState([]);
  const [blockSend, setBlockSend] = useState(false);

  const {
    handleUploadDocument,
    handleSetupDocument,
    getDocumentTypeExibition,
  } = useDocumentModalController();

  const getTitle = () => {
    if (props.documentModalLoading === false) {
      const recognized = props.documentList.filter(
        (e) => e.data.document_type !== "NOT_IDENTIFIED"
      );

      const unrecognized = props.documentList.filter(
        (e) => e.data.document_type === "NOT_IDENTIFIED"
      );
      const unique = [
        ...new Set(props.documentList.map((item) => item.data.document_type)),
      ].filter((e) => e !== "NOT_IDENTIFIED");
      let letter =
        props.documentList.length - unrecognized.length > 1 ? "s" : "";

      if (recognized.length === 0) {
        let lter = unrecognized.length > 1 ? "s" : "";

        return (
          "Identifiquei " +
          unrecognized.length +
          " documento" +
          lter +
          " não conhecido" +
          lter
        );
      }

      let msg =
        "Detectei um total de " +
        (props.documentList.length - unrecognized.length) +
        " documento" +
        letter;
      msg +=
        ", sendo este" +
        letter +
        " do tipo: " +
        getDocumentTypeExibition(unique.toString());

      return msg;
    }

    let letter = props.documentList.length > 1 ? "s" : "";

    return (
      "Estou analisando o" +
      letter +
      " documento" +
      letter +
      " enviado" +
      letter
    );
  };

  const getSecondTitle = () => {
    if (props.documentModalLoading === false) {
      const unique = props.documentList.filter(
        (e) => e.data.document_type === "NOT_IDENTIFIED"
      );

      if (unique.length === 0) {
        return "";
      }

      let letter = unique.length > 1 ? "s" : "";
      let was = unique.length > 1 ? "Foram" : "Foi";

      let msg =
        was +
        " encontrado" +
        letter +
        " " +
        unique.length +
        " documento" +
        letter +
        " não identificado" +
        letter;

      return msg;
    }
    return "Aguarde um instante...";
  };

  useEffect(() => {
    if (props.documentModalLoading === false) {
      handleSetupDocument(
        props.documentList,
        setValue,
        setUsers,
        companyUser,
        setTypeFileState
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.documentModalLoading]);

  useEffect(
    () => initialSetup(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const initialSetup = async () => {
    setDocTypeSelected(props.documentList[currentIndex]?.data?.document_type);
    let title = getTitle();
    getMP3Audio(title).then((result) => {
      var snd = new Audio("data:audio/wav;base64," + result);
      snd.play();
    });

    setIsLoadingSetFullList(true);
    const response = await getAccountantClients();
    const currentCompanyResponse = await getEntityDataById(
      companyUser.companyId
    );

    if (response?.data) {
      setFullList([currentCompanyResponse.data, ...response.data]);
    } else {
      setFullList([currentCompanyResponse.data]);
    }

    setIsLoadingSetFullList(false);
  };
  const verifyCheckBoxes = (index) => {
    if (props?.documentList !== null && props?.documentList?.length === 0) {
      return;
    }
    if (
      props?.documentList[index]?.outros === false &&
      props?.documentList[index]?.fiscal === false &&
      props?.documentList[index]?.contabil === false &&
      props?.documentList[index]?.folha === false
    ) {
      props.documentList[index].openTicketInCRM = false;
      setOpenTicketInCrm(false);
    }

    if (props?.documentList[index]?.doc_type !== typeFileState) {
      setTypeFileState(props?.documentList[index]?.doc_type);
    }
  };

  const validateDestinyDocument = (document) => {
    if (document.doc_type === "guide" || document.doc_type === "report") {
      return (
        document.outros ||
        document.fiscal ||
        document.folha ||
        document.contabil
      );
    } else if (document.doc_type === "doc") {
      return (
        document.outros || document.contratoTrabalho || document.contratoSocial
      );
    } else {
      return false;
    }
  };

  const doc_type_list = [
    { name: "DARF", docType: "DARF" },
    { name: "DAS", docType: "DAS" },
    { name: "Boleto", docType: "BOLETO" },
    { name: "FGTS", docType: "FGTS" },
    { name: "Retorno DCTF", docType: "DCTF_RETURN" },
    { name: "Outros", docType: "NOT_IDENTIFIED" },
  ];

  useEffect(
    () => {
      setDocTypeSelected(props.documentList[currentIndex]?.data?.document_type);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentIndex]
  );

  useEffect(() => {
    let copyDocumentList = [...props.documentList];
    setDocumentList(copyDocumentList);
    // eslint-disable-line react-hooks/exhaustive-deps
  }, [props.documentList]);

  useEffect(() => {
    setChangeDocTypeLoading(true);

    if (docTypeSelectedChanged) {
      let updatedList = [...documentList];

      switch (docTypeSelected) {
        case "DARF":
          updatedList[currentIndex] = DarfObject(updatedList[currentIndex]);
          break;

        case "DAS":
          updatedList[currentIndex] = DasObject(updatedList[currentIndex]);
          break;

        case "BOLETO":
          updatedList[currentIndex] = BoletoObject(updatedList[currentIndex]);
          break;

        case "FGTS":
          updatedList[currentIndex] = FgtsObject(updatedList[currentIndex]);
          break;

        case "DCTF_RETURN":
          updatedList[currentIndex] = DctfObject(updatedList[currentIndex]);
          break;

        case "NOT_IDENTIFIED":
          updatedList[currentIndex] = NotIdentifiedObject(
            updatedList[currentIndex]
          );
          break;
        default:
          return "";
      }
      setDocumentList(updatedList);
      setChangeDocTypeLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docTypeSelected]);

  const handleChange = (event) => {
    setDocTypeSelected(event.target.value);
    let eventValue = event.target.value;

    if (props.documentList[currentIndex]?.data) {
      props.documentList[currentIndex].data.document_type = eventValue;
    }

    setDocTypeSelectedChanged(true);
  };

  return (
    <Modal
      open={props.openModal}
      onClose={() => {
        props.closeDocumentModal();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <>
        {(!screen || screen === "DOCUMENT_DATA") && (
          <DialogStyle style={{ width: "1200px", maxHeight: "100%" }}>
            <DialogHeader style={{ paddingBottom: 0 }}>
              <div style={dialogTitleChildrenRow}>
                <div style={dialogTitleChildrenColumn}>
                  {getTitle()}

                  <DivSecondTitle>{getSecondTitle()}</DivSecondTitle>
                </div>

                <CloseIconStyle
                  style={dialogTitleClose}
                  onClick={() => {
                    props.closeDocumentModal();
                  }}
                />
              </div>
              <div
                style={{
                  paddingBottom: "10px",
                  paddingTop: "20px",
                  borderBottom: "1px solid rgba(207, 216, 220, 1)",
                }}
              />
            </DialogHeader>

            {props.documentModalLoading === true ? (
              <div
                style={{
                  height: "400px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <DialogContent style={{ height: 480 }}>
                <Carousel
                  showStatus={false}
                  showThumbs={false}
                  key={key}
                  animationHandler="slide"
                  showIndicators={false}
                  onChange={(index, item) => {
                    setCurrentIndex(index);
                  }}
                >
                  {documentList.map((document, index) => {
                    return (
                      <Grid key={"DOCUMENT" + index}>
                        <Grid
                          container
                          spacing={2}
                          style={{
                            padding: 2,
                          }}
                        >
                          <Grid item xs={12} style={{ maxHeight: "80%" }}>
                            <div
                              style={documentContainer}
                              key={document.tab + "_" + document.tab}
                            >
                              <span>
                                <Grid
                                  item
                                  container
                                  xs={12}
                                  style={{ maxHeight: 400 }}
                                >
                                  <Grid xs={6}>
                                    <DialogContent
                                      style={{
                                        borderRight: "1px solid #CFD8DC",
                                        height: 400,
                                      }}
                                    >
                                      <DivTextLeft
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          alignItems: "center",
                                        }}
                                      >
                                        <span>
                                          <DescriptionRoundedIcon
                                            style={{
                                              color:
                                                props.documentModalLoading ===
                                                true
                                                  ? "grey"
                                                  : document.data
                                                      .document_type ===
                                                    "NOT_IDENTIFIED"
                                                  ? "#F44336"
                                                  : "#4CAF50",
                                            }}
                                          />
                                          <span
                                            style={nameDocumentStyle(
                                              props.documentModalLoading,
                                              document?.data?.document_type
                                            )}
                                          >
                                            {props.documentModalLoading === true
                                              ? "Carregando..."
                                              : document.data.document_type ===
                                                "NOT_IDENTIFIED"
                                              ? "Não identificado"
                                              : document.data
                                                  .document_type}{" "}
                                            - {props.documentList[index]?.name}
                                          </span>
                                        </span>
                                        <SelectFilterStyled
                                          style={{ marginBottom: "12px" }}
                                          labelId="demo-simple-select-label"
                                          id="demo-simple-select"
                                          value={docTypeSelected}
                                          onChange={(event) => {
                                            handleChange(event);
                                          }}
                                          variant="outlined"
                                        >
                                          {doc_type_list.map((item) => (
                                            <MenuItem
                                              key={item.docType}
                                              value={item.docType}
                                            >
                                              {item.name}
                                            </MenuItem>
                                          ))}
                                        </SelectFilterStyled>
                                      </DivTextLeft>

                                      {props.documentModalLoading === true && (
                                        <PdfIframe
                                          title={`document_${key}`}
                                          src={
                                            "data:application/pdf;base64," +
                                            document.base64 +
                                            "#toolbar=0&navpanes=0#zoom=10?id=" +
                                            Math.random()
                                          }
                                          scrolling="no"
                                        />
                                      )}

                                      {props.documentModalLoading === false && (
                                        <PdfImgWrapperDiv>
                                          <iframe
                                            height="350"
                                            id="iframepdf"
                                            src={
                                              "data:application/pdf;base64," +
                                              document.base64
                                            }
                                            title="iframePdfDocument"
                                            style={pdfImg()}
                                          />
                                        </PdfImgWrapperDiv>
                                      )}
                                    </DialogContent>
                                  </Grid>

                                  <Grid xs={6}>
                                    {props.documentModalLoading ? null : (
                                      <DialogContent style={tabPanelStyle}>
                                        {changeDocTypeLoading &&
                                        docTypeSelectedChanged ? (
                                          <CircularProgress />
                                        ) : (
                                          <>
                                            {props.documentModalLoading ===
                                              false &&
                                              document.data.document_type ===
                                                "NOT_IDENTIFIED" && (
                                                <DivTextLeft>
                                                  <NotIdentifiedDocumentName>
                                                    <TextFieldDocumentName
                                                      label={
                                                        "Nome do documento (original)"
                                                      }
                                                      variant="outlined"
                                                      key={
                                                        "DOCUMENT_NAME" +
                                                        index +
                                                        " - " +
                                                        key
                                                      }
                                                      defaultValue={
                                                        props.documentList[
                                                          index
                                                        ]?.name
                                                          ? props.documentList[
                                                              index
                                                            ]?.name
                                                          : props.documentList[
                                                              index
                                                            ]?.name_suggestion
                                                      }
                                                      onChange={(ev) => {
                                                        if (
                                                          props.documentList[
                                                            index
                                                          ]?.name
                                                        )
                                                          props.documentList[
                                                            index
                                                          ].name =
                                                            ev.target.value;
                                                        else
                                                          props.documentList[
                                                            index
                                                          ].name_suggestion =
                                                            ev.target.value;
                                                      }}
                                                    />
                                                  </NotIdentifiedDocumentName>
                                                </DivTextLeft>
                                              )}

                                            {props.documentModalLoading ===
                                              false &&
                                              document.data.document_type !==
                                                "NOT_IDENTIFIED" &&
                                              document.data.document_type ===
                                                "DARF" && (
                                                <>
                                                  <DocumentDarfTab
                                                    document={
                                                      documentList[index]
                                                    }
                                                    index={index}
                                                    govIdKey={govIdKey}
                                                    setCpfCnpjFocus={
                                                      setCpfCnpjFocus
                                                    }
                                                    cpfCnpjFocus={cpfCnpjFocus}
                                                    documentModalLoading={
                                                      props.documentModalLoading
                                                    }
                                                    documentKey={key}
                                                    getDescription={
                                                      getDescription
                                                    }
                                                  />
                                                </>
                                              )}

                                            {props.documentModalLoading ===
                                              false &&
                                              document.data.document_type !==
                                                "NOT_IDENTIFIED" &&
                                              document.data.document_type ===
                                                "DAS" && (
                                                <>
                                                  <DocumentDasTab
                                                    document={
                                                      documentList[index]
                                                    }
                                                    index={index}
                                                    govIdKey={govIdKey}
                                                    setCpfCnpjFocus={
                                                      setCpfCnpjFocus
                                                    }
                                                    cpfCnpjFocus={cpfCnpjFocus}
                                                    documentModalLoading={
                                                      props.documentModalLoading
                                                    }
                                                    documentKey={key}
                                                  />
                                                </>
                                              )}

                                            {props.documentModalLoading ===
                                              false &&
                                              document.data.document_type !==
                                                "NOT_IDENTIFIED" &&
                                              document.data.document_type ===
                                                "BOLETO" && (
                                                <>
                                                  <DocumentBoletoTab
                                                    document={
                                                      documentList[index]
                                                    }
                                                    index={index}
                                                    govIdKey={govIdKey}
                                                    setCpfCnpjFocus={
                                                      setCpfCnpjFocus
                                                    }
                                                    cpfCnpjFocus={cpfCnpjFocus}
                                                    documentModalLoading={
                                                      props.documentModalLoading
                                                    }
                                                    cpfCnpjFocus1={
                                                      cpfCnpjFocus1
                                                    }
                                                    documentKey={key}
                                                    setCpfCnpjFocus1={
                                                      setCpfCnpjFocus1
                                                    }
                                                    docTypeSelectedChanged={
                                                      docTypeSelectedChanged
                                                    }
                                                    setBlockSend={setBlockSend}
                                                  />
                                                </>
                                              )}

                                            {props.documentModalLoading ===
                                              false &&
                                              document.data.document_type !==
                                                "NOT_IDENTIFIED" &&
                                              document.data.document_type ===
                                                "FGTS" && (
                                                <>
                                                  <DocumentFgtsTab
                                                    document={
                                                      documentList[index]
                                                    }
                                                    index={index}
                                                    govIdKey={govIdKey}
                                                    setCpfCnpjFocus={
                                                      setCpfCnpjFocus
                                                    }
                                                    cpfCnpjFocus={cpfCnpjFocus}
                                                    documentModalLoading={
                                                      props.documentModalLoading
                                                    }
                                                    documentKey={key}
                                                  />
                                                </>
                                              )}
                                            {props.documentModalLoading ===
                                              false &&
                                              document.data.document_type !==
                                                "NOT_IDENTIFIED" &&
                                              document.data.document_type ===
                                                "DCTF_RETURN" && (
                                                <>
                                                  <DocumentDctfReturnTab
                                                    document={
                                                      documentList[index]
                                                    }
                                                    index={index}
                                                    govIdKey={govIdKey}
                                                    setCpfCnpjFocus={
                                                      setCpfCnpjFocus
                                                    }
                                                    cpfCnpjFocus={cpfCnpjFocus}
                                                    documentModalLoading={
                                                      props.documentModalLoading
                                                    }
                                                    documentKey={key}
                                                  />
                                                </>
                                              )}
                                          </>
                                        )}

                                        <ModalCheckboxes
                                          document={documentList[index]}
                                          index={index}
                                          typeFileState={typeFileState}
                                          setTypeFileState={setTypeFileState}
                                          verifyCheckBoxes={verifyCheckBoxes}
                                          users={users}
                                          key={key}
                                          setOpenTicketInCrm={
                                            setOpenTicketInCrm
                                          }
                                          openTicketInCRM={openTicketInCRM}
                                          fullList={fullList}
                                          isLoadingfullList={isLoadingfullList}
                                          docTypeSelected={docTypeSelected}
                                        />
                                      </DialogContent>
                                    )}
                                  </Grid>
                                </Grid>

                                <DocumentModalFooterWrapper>
                                  {currentIndex !== 0 &&
                                    props.documentList.length !== 1 && (
                                      <ButtonCancel
                                        loading={
                                          disabled || props.documentModalLoading
                                        }
                                        color="variant"
                                        aria-label="add"
                                        style={floatAlign("left")}
                                        onClick={() => {
                                          window.document
                                            .getElementsByClassName(
                                              "control-arrow control-prev"
                                            )[0]
                                            .click();
                                        }}
                                      >
                                        Voltar
                                      </ButtonCancel>
                                    )}

                                  <div style={floatAlign("right")}>
                                    <ButtonSave
                                      isSend
                                      loading={
                                        disabled || props.documentModalLoading
                                      }
                                      disabled={disabled || blockSend}
                                      onClick={async () => {
                                        if (!document?.data?.govid) {
                                          notify(
                                            "Selecione uma empresa para enviar o documento!",
                                            true,
                                            "warning"
                                          );
                                        } else {
                                          if (
                                            validateDestinyDocument(document)
                                          ) {
                                            if (
                                              document.data.document_type ===
                                              "NOT_IDENTIFIED"
                                            ) {
                                              document.client = {
                                                document: document.data.govid,
                                              };
                                            }
                                            await handleUploadDocument(
                                              document,
                                              props,
                                              index,
                                              setDisabled,
                                              setKey
                                            );
                                            verifyCheckBoxes(index);

                                            setDocumentList(props.documentList);
                                          } else {
                                            notify(
                                              "Destino inválido, verifique se um cliente foi selecionado e se uma pasta foi selecionada para o envio do documento",
                                              true,
                                              "warning"
                                            );
                                          }
                                        }
                                      }}
                                    >
                                      Enviar{" "}
                                      {props.documentModalLoading === true
                                        ? ""
                                        : document.data.document_type ===
                                            "NOT_IDENTIFIED" ||
                                          document.data.document_type ===
                                            "DCTF_RETURN" ||
                                          document.data.document_type ===
                                            "DCTF_RECEIPT"
                                        ? ""
                                        : getDocumentTypeExibition(
                                            document.data.document_type
                                          )}
                                    </ButtonSave>

                                    {currentIndex !==
                                      props.documentList.length - 1 &&
                                      props.documentList.length !== 1 && (
                                        <ButtonSave
                                          style={{ marginLeft: "12px" }}
                                          loading={
                                            disabled ||
                                            props.documentModalLoading
                                          }
                                          onClick={() => {
                                            window.document
                                              .getElementsByClassName(
                                                "control-arrow control-next"
                                              )[0]
                                              .click();
                                          }}
                                        >
                                          Próximo
                                        </ButtonSave>
                                      )}
                                  </div>
                                </DocumentModalFooterWrapper>
                              </span>
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                    );
                  })}
                </Carousel>
              </DialogContent>
            )}
          </DialogStyle>
        )}
      </>
    </Modal>
  );
};
