import React, { useContext } from "react";
import {
  StyledSubheader,
  TextBreadCrumb,
  TitleBreadCrumb,
} from "./styles/Subheader";
import { Grid } from "@material-ui/core";
import { ReactComponent as RightIcon } from "../../assets/icons/right.svg";
import { GlobalContext } from "../../contexts/GlobalContext";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

export const GlobalSubheader = ({
  title,
  titleLocation,
  children,
  ...props
}) => {
  const { breadcrumbs } = useContext(GlobalContext);
  const { push } = useHistory();

  return (
    <StyledSubheader
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      style={{ paddingTop: "16px", paddingBottom: "16px" }}
    >
      <Grid item xs={12} sm={12}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <TitleBreadCrumb
            onClick={(e) => {
              if (titleLocation) {
                push(titleLocation);
              }
            }}
          >
            {title}
          </TitleBreadCrumb>

          {breadcrumbs.map((breadcrumb) => {
            return (
              <>
                <RightIcon />
                <TextBreadCrumb>
                  {breadcrumb.name.substring(0, 30)}
                  {(breadcrumb.name.length > 30 && "...") || ""}
                </TextBreadCrumb>
              </>
            );
          })}
        </div>
      </Grid>
      <Grid item xs={12} sm={4}>
        {children}
      </Grid>
    </StyledSubheader>
  );
};
