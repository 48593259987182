import { search, searchBlob } from "./BaseRepositoryComponents";

const newPath = `${process.env.REACT_APP_NEW_PAINEL_API_URL}`;

export async function getFolders() {
  const url = `${newPath}/v1/folder`;

  return search({
    path: url,
    method: "GET",
  });
}

export async function getDocuments({
  page_size,
  start_date,
  end_date,
  page,
  folder_id,
  search_field,
  favorite,
  do_count,
  search_id,
  archived,
}) {
  let url = `${newPath}/v1/document`;
  if (page_size) url += `?page_size=${page_size}`;
  if (start_date) url += `&start_date=${start_date}`;
  if (end_date) url += `&end_date=${end_date}`;
  if (page) url += `&page=${page}`;
  if (folder_id) url += `&folder_id=${folder_id}`;
  if (search_field) url += `&search=${search_field}`;
  if (favorite) url += `&favorite=${favorite}`;
  if (do_count) url += `&do_count=${do_count}`;
  if (search_id) url += `&search_id=${search_id}`;
  if (archived) url += `&archived=${archived}`;

  return search({
    path: url,
    method: "GET",
  });
}

export async function favoriteDocument(document_id, body) {
  const url = `${newPath}/v1/document/${document_id}/favorite`;

  return search({
    path: url,
    method: "PUT",
    myBody: body,
  });
}

export async function downloadDocument(document_id) {
  const url = `${newPath}/v1/document/${document_id}/download`;

  return searchBlob({
    path: url,
    method: "GET",
  });
}

export async function sendEmailDocumentById(document_id, emailList) {
  const body = {
    emailList: emailList,
    document_id: document_id,
  };

  return search({
    method: "POST",
    path: `${newPath}/v1/document/send-email`,
    myBody: body,
  });
}

export const getDocumentHistory = async (document_id) => {
  return search({
    method: "GET",
    path: `${newPath}/v1/document/${document_id}/history`,
  });
};

export const archiveDocument = async (document_id, archived = true) => {
  return search({
    method: "PUT",
    path: `${newPath}/v1/document/${document_id}/archive`,
    myBody: { archived: archived },
  });
};
