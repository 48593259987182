import axios from "axios";
import { search } from "./BaseRepositoryComponents";

const newPath = `${process.env.REACT_APP_NEW_PAINEL_API_URL}`;
const BOT_API = process.env.REACT_APP_BOT_API_URL;
export const getDocumentData = async (base64) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "c799c146-6264-4bc6-94d7-56b4cac8deb0",
  };

  let response = await axios.post(
    BOT_API + "/v1/bot/scan/document",
    {
      base64,
    },
    {
      headers: headers,
    }
  );
  return response.status === 200 ? response.data : null;
};

export async function getDocumentsById({
  scheduled_id,
  companyId,
  service,
  confirmed,
}) {
  let url = `${newPath}/v1/document_client/get-documents-by-id/${companyId}`;

  if (service) url += `?comm_type=${service}`;

  if (confirmed) url += `&confirmed=${confirmed}`;

  if (scheduled_id) url += `&scheduled_id=${scheduled_id}`;

  return search({
    path: url,
    method: "GET",
  });
}

export async function postDocuments(body) {
  const url = `${newPath}/v1/document_client/post-documents-list`;

  return search({
    path: url,
    method: "POST",
    myBody: body,
  });
}

export async function postDocument(body) {
  const url = `${newPath}/v1/document_client/post-document`;

  return search({
    path: url,
    method: "POST",
    myBody: body,
  });
}

export async function deleteDocument(document_id) {
  const url = `${newPath}/v1/document_client/delete-document/${document_id}`;

  return search({
    path: url,
    method: "DELETE",
  });
}

export async function sendVerifyEmail(email, body) {
  const url = `${newPath}/v1/document_client/send-verify-email/${email}`;

  return search({
    path: url,
    method: "POST",
    myBody: body,
  });
}
