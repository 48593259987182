import React, { useEffect } from "react";
import ReactDOM from "react-dom";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from "@material-ui/core";

const DialogConfirm = ({ closeModal }) => {
  useEffect(() => {
    if (sessionStorage.getItem("blockUserConfirmation")) {
      closeModal(true);

      sessionStorage.setItem("blockUserConfirmation", "");
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Dialog
      open={sessionStorage.getItem("blockUserConfirmation") ? false : true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        OS DADOS NÃO FORAM SALVOS
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Tem certeza que deseja continuar sem salvar o registro?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent="space-between">
          <Button onClick={() => closeModal(false)} color="primary" autoFocus>
            Cancelar
          </Button>
          <Button onClick={() => closeModal(true)} color="primary">
            Sim
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

const UserConfirmation = (message, callback) => {
  const container = document.createElement("div");
  container.setAttribute("custom-confirmation-navigation", "");
  document.body.appendChild(container);

  const closeModal = (callbackState) => {
    ReactDOM.unmountComponentAtNode(container);
    document.body.removeChild(container);

    callback(callbackState);
  };

  ReactDOM.render(<DialogConfirm closeModal={closeModal} />, container);
};
export default UserConfirmation;
