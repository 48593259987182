import { Grid } from "@material-ui/core";
import { useContext } from "react";
import { useHistory } from "react-router-dom";
import QyonSidebar from "../../assets/images/QYON-Sistemas-Inteligentes_Black.png";
import { HomeContext } from "../default/contexts/HomeContext";
import {
  Astyled,
  Circle,
  DivText,
  H1Styled,
  HeaderButtons,
  Headerstyled,
  LiSecondary,
  Listyled,
  NavSecondary,
  Navstyled,
  ULtyled,
} from "./styles/cssProducts";

export const ComercialQyon = () => {
  function Header() {
    return (
      <Headerstyled>
        <img src={QyonSidebar} style={{ width: 150 }} alt="Logo" />
        <Navstyled>
          <ULtyled>
            <Listyled>
              <Astyled
                href="https://portal.qyon.com/login?logout=true"
                data-item="Qyon"
              >
                Qyon
              </Astyled>
            </Listyled>
            <Listyled>
              <Astyled href="https://www.qyon.com/" data-item="Sobre Nós">
                Sobre Nós
              </Astyled>
            </Listyled>
            <Listyled>
              <Astyled href="https://qyon.com/contato/" data-item="Contato">
                Contato
              </Astyled>
            </Listyled>
          </ULtyled>
        </Navstyled>
      </Headerstyled>
    );
  }
  const { landTheme } = useContext(HomeContext);
  const { push } = useHistory();

  return (
    <div>
      <Header />
      <Circle />

      <H1Styled>QYON Comercial</H1Styled>
      <DivText>
        A Qyon é uma empresa com conhecimento avançado em tecnologias de
        softwares com aplicação de inteligência artificial na gestão
        empresarial, desenvolvida para gerenciar e integrar todos os processos e
        setores que fazem parte da sua empresa. <br /> <br />
        Clique nos botões abaixo para verificar as integrações disponíveis:
      </DivText>

      <Grid container>
        <HeaderButtons item container xs>
          <NavSecondary>
            <ul>
              <LiSecondary
                style={{ backgroundColor: "#999999" }}
                $color={landTheme.color_pallete.secondary}
                onClick={() => push("/versoes/xml")}
              >
                NFSE
              </LiSecondary>
              <LiSecondary
                style={{ backgroundColor: "#999999" }}
                $color={landTheme.color_pallete.secondary}
                onClick={() => push("/versoes/cnd")}
              >
                CND
              </LiSecondary>
            </ul>
          </NavSecondary>
        </HeaderButtons>
      </Grid>
    </div>
  );
};
