import { Grid, AccordionSummary, Button } from "@material-ui/core";
import styled from "styled-components";

export const VersionButtonsGrid = styled(Grid)`
  display: flex;
  align-items: flex-start;
  column-gap: 16px;
  align-self: stretch;
`;

export const VersionButton = styled.p((props) => ({
  display: "flex",
  padding: "8px 24px",
  justifyContent: "center",
  alignItems: "center",
  marginTop: "14px",
  gap: "8px",
  borderRadius: "24px",
  background: "#F0F1F3",
  cursor: "pointer",

  ...(props?.active && {
    display: "flex",
    padding: "8px 24px",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",

    borderRadius: "24px",
    background: "var(--Primary-Blue-08, #0036b3)",
    color: "var(--Grey-01, #fbfbfd)",
    cursor: "pointer",
    fontFamily: "Roboto",
    fontWeight: "700",
  }),

  "&:hover": {
    display: "flex",
    padding: "8px 24px",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    borderRadius: "24px",
    background: "#0036b3",
    color: "#fbfbfd ",
    cursor: "pointer",
    fontFamily: "Roboto",
  },
}));

export const LatestOlderVersionsGrid = styled(Grid)`
  display: flex;
  padding: 8px 0px;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  color: #424242;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0.15px;
`;

export const ArrowGrid = styled(Grid)`
  display: flex;
  width: 40px;
  height: 40px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  opacity: 0.99;
  cursor: pointer;
`;

export const VersionGrid = styled(Grid)`
  display: flex;
  flex-direction: column;
  padding: 16px 32px;
  background: #fbfbfd;
  margin-bottom: 24px;
  border-radius: 16px;

  &:hover {
    background: #e7eefe;
  }
`;

export const VersionHeader = styled(Grid)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #cecfd0;
  margin-bottom: 24px;
`;

export const VersionRow = styled(Grid)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

export const VersionContainer = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const LabelVersion = styled.p({
  color: "#424242",
  fontFamily: "Roboto",
  fontSize: "48px",
  fontStyle: "normal",
  fontWeight: "800",
  margin: "0px",
});

export const Labeltitle = styled.p({
  color: "#424242",
  fontFamily: "Roboto",
  fontSize: "18px",
  fontStyle: "bold",
  fontWeight: "800",
  display: "flex",
  alignItems: "center",
  marginLeft: "1em",
});

export const LabelVersionDate = styled.p({
  color: "#424242",
  fontFamily: "Roboto",
  fontSize: "18px",
  fontStyle: "normal",
  fontWeight: "400",
  margin: "0px",
  marginBottom: "8px 0px 0px 0px",
  textAlign: "right",
});

export const LabelcurrentVersion = styled.p({
  color: "#fff",
  fontSize: "12px",
  backgroundColor: "#0036b3",
  padding: "4px 8px",
  borderRadius: "25px",
  textAlign: "center",
  margin: "0px",
});

export const DescriptionVersionGrid = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
`;

export const IntroVersion = styled.p({
  alignSelf: "stretch",
  color: " #424242",
  fontFamily: "Roboto",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: "500",
  lineHeight: "24px",
  letterSpacing: "0.15px",
});

export const IntroTextVersion = styled.p({
  color: " #424242",
  fontFamily: "Roboto",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "24px",
  letterSpacing: "0.15px",
  whiteSpace: "pre-line",
});

export const ShowMoreButton = styled(Button)`
  display: flex;
  width: 300px;
  height: 40px;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  display: flex;
  height: 40px;
  padding: 8px 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
  align-self: stretch;
  border-radius: 8px;
  background: #0036b3;

  &:hover {
    background: #0036b3;
    opacity: 0.8;
  }
`;

export const TextShowMoreLessButton = styled.p({
  color: "#fff",
  fontFamily: "Roboto",
  fontSize: "15px",
  fontStyle: "normal",
  fontWeight: "500",
  lineHeight: "24px",
});

export const ArrowShowMoreButton = styled.p({
  width: "24px",
  height: "24px",
});

export const ContentVersionGrid = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
`;

export const AccordionSummary2 = styled(AccordionSummary)`
  display: flex;
  padding: 8px 0px;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  border-bottom: 1px solid #cecfd0;

  color: #424242;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0.15px;
  margin-bottom: 24px;
`;
