import {
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import useDocumentModalController from "../controllers/DocumentModalController";
import MaskService from "../../../utils/mask/maskService";

export const ModalCheckboxes = (props) => {
  const { cleanCheckBoxesArea, hasAreaChecked } = useDocumentModalController();
  const [docType, setDocType] = useState(props.document.doc_type);
  const [contabil, setContabil] = useState(props.document.contabil);
  const [fiscal, setFiscal] = useState(props.document.fiscal);
  const [folha, setFolha] = useState(props.document.folha);
  const [outros, setOutros] = useState(props.document.outros);
  const [contratoSocial, setContratoSocial] = useState(
    props.document.contratoSocial
  );
  const [contratoTrabalho, setContratoTrabalho] = useState(
    props.document.contratoTrabalho
  );
  const [openTicketInCRM, setOpenTicketInCrm] = useState(
    props.document.openTicketInCRM
  );
  const [generateTicketEmail, setGenerateTicketEmail] = useState("");

  const [inputValue, setInputValue] = useState("");

  const [sendBillToPay, setSendBillToPay] = useState(props.document.sendToPay);

  const verifyCheckBoxes = () => {
    if (!outros && !fiscal && !contabil && !folha) {
      props.document.openTicketInCRM = false;
      setOpenTicketInCrm(false);
    }
  };

  const findObject = props?.fullList.find(
    (objeto) => objeto?.document === props?.document.data.govid
  );

  useEffect(() => {
    if (!props.isLoadingfullList) {
      if (findObject) {
        setInputValue(findObject);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isLoadingfullList]);

  useEffect(() => {
    setDocType(props.document.doc_type);
    props.setTypeFileState(props.document.doc_type);
    setContabil(props.document.contabil);
    setFiscal(props.document.fiscal);
    setFolha(props.document.folha);
    setOutros(props.document.outros);
    setContratoSocial(props.document.contratoSocial);
    setContratoTrabalho(props.document.contratoTrabalho);
    setOpenTicketInCrm(props.document.openTicketInCRM);
    setGenerateTicketEmail("");
    setInputValue("");
    setSendBillToPay(props.document.sendToPay);

    verifyCheckBoxes();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.document]);

  return (
    <>
      <div style={{ textAlign: "left" }}>
        <div id="demo-simple-select-standard-label">
          Selecione para onde o documento deve ser enviado
        </div>

        <FormControl component="fieldset">
          <RadioGroup
            color="primary"
            value={docType}
            name="radio-buttons-group"
            row
          >
            <FormControlLabel
              value="guide"
              control={<Radio color="primary" />}
              onClick={(e) => {
                setDocType(e.target.value);
                props.document.doc_type = e.target.value;
                props.setTypeFileState(e.target.value);
                cleanCheckBoxesArea(props.document);
                setContabil(false);
                setFiscal(false);
                setFolha(false);
                setOutros(false);
                setContratoSocial(false);
                setContratoTrabalho(false);
              }}
              label="Guias"
            />
            <FormControlLabel
              value="report"
              control={<Radio color="primary" />}
              onClick={(e) => {
                setDocType(e.target.value);
                props.document.doc_type = e.target.value;
                props.setTypeFileState(e.target.value);
                cleanCheckBoxesArea(props.document);
                setContabil(false);
                setFiscal(false);
                setFolha(false);
                setOutros(false);
                setContratoSocial(false);
                setContratoTrabalho(false);
              }}
              label="Relatórios"
            />
            <FormControlLabel
              value="doc"
              control={<Radio color="primary" />}
              onClick={(e) => {
                setDocType(e.target.value);
                props.document.doc_type = e.target.value;
                props.setTypeFileState(e.target.value);
                cleanCheckBoxesArea(props.document);
                setContabil(false);
                setFiscal(false);
                setFolha(false);
                setOutros(false);
                setContratoSocial(false);
                setContratoTrabalho(false);
              }}
              label="Documentos"
            />
          </RadioGroup>
        </FormControl>
      </div>
      <div style={{ textAlign: "left" }}>
        <div>
          <div>
            Selecione a pasta para envio{" "}
            {localStorage.getItem("isAccountantPanel") === "true"
              ? "no Portal do Cliente"
              : ""}
          </div>
          <Grid style={{ width: "100%" }}>
            <FormControl component="fieldset" row>
              <FormGroup row>
                {props.typeFileState !== "doc" && (
                  <FormControlLabel
                    label={<Typography variant="body2">Contábil</Typography>}
                    control={
                      <Checkbox
                        color="primary"
                        checked={contabil}
                        onChange={(ev) => {
                          props.document.contabil = ev.target.checked;
                          setContabil(ev.target.checked);

                          verifyCheckBoxes();
                        }}
                      />
                    }
                  />
                )}
                {props.typeFileState !== "doc" && (
                  <FormControlLabel
                    label={<Typography variant="body2">Fiscal</Typography>}
                    control={
                      <Checkbox
                        color="primary"
                        checked={fiscal}
                        onChange={(ev) => {
                          props.document.fiscal = ev.target.checked;
                          setFiscal(ev.target.checked);

                          verifyCheckBoxes();
                        }}
                      />
                    }
                  />
                )}
                {props.typeFileState !== "doc" && (
                  <FormControlLabel
                    label={<Typography variant="body2">Folha</Typography>}
                    control={
                      <Checkbox
                        color="primary"
                        checked={folha}
                        onChange={(ev) => {
                          props.document.folha = ev.target.checked;
                          setFolha(ev.target.checked);

                          verifyCheckBoxes();
                        }}
                      />
                    }
                  />
                )}
                {props.typeFileState !== "doc" && (
                  <FormControlLabel
                    label={<Typography variant="body2">Outros</Typography>}
                    control={
                      <Checkbox
                        color="primary"
                        checked={outros}
                        onChange={(ev) => {
                          props.document.outros = ev.target.checked;
                          setOutros(ev.target.checked);

                          verifyCheckBoxes();
                        }}
                      />
                    }
                  />
                )}
                {props.typeFileState === "doc" && (
                  <FormControlLabel
                    label={
                      <Typography variant="body2">
                        Contrato de Trabalho
                      </Typography>
                    }
                    control={
                      <Checkbox
                        color="primary"
                        checked={contratoTrabalho}
                        onChange={(ev) => {
                          props.document.contratoTrabalho = ev.target.checked;
                          setContratoTrabalho(ev.target.checked);
                        }}
                      />
                    }
                  />
                )}
                {props.typeFileState === "doc" && (
                  <FormControlLabel
                    label={
                      <Typography variant="body2">Contrato Social</Typography>
                    }
                    control={
                      <Checkbox
                        color="primary"
                        checked={contratoSocial}
                        onChange={(ev) => {
                          props.document.contratoSocial = ev.target.checked;
                          setContratoSocial(ev.target.checked);
                        }}
                      />
                    }
                  />
                )}
                {props.typeFileState === "doc" && (
                  <FormControlLabel
                    label={<Typography variant="body2">Outros</Typography>}
                    control={
                      <Checkbox
                        color="primary"
                        checked={outros}
                        onChange={(ev) => {
                          props.document.outros = ev.target.checked;
                          setOutros(ev.target.checked);

                          verifyCheckBoxes();
                        }}
                      />
                    }
                  />
                )}
                {props.isLoadingfullList ? (
                  <CircularProgress />
                ) : (
                  <Grid>
                    <Autocomplete
                      style={{ marginTop: "12px", width: "260px" }}
                      id="company"
                      value={inputValue || null}
                      options={props.fullList}
                      getOptionLabel={(option) => {
                        let maskedDocument = "";
                        if (option.document.length === 14) {
                          maskedDocument = MaskService.toMask(
                            "cnpj",
                            option.document
                          );
                        } else {
                          maskedDocument = MaskService.toMask(
                            "cpf",
                            option.document
                          );
                        }

                        return `${option.company_name} - ${maskedDocument}`;
                      }}
                      onChange={(event, newValue) => {
                        setInputValue(newValue);
                        props.document.data.govid = newValue?.document?.replace(
                          /[^\d]/g,
                          ""
                        );

                        if (props.document.data.document_type === "BOLETO") {
                          props.document.data.data.drawee.taxIdentifier.taxId =
                            newValue?.document?.replace(/[^\d]/g, "");
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Enviar Para..."
                          InputLabelProps={{ style: { marginTop: "-8px" } }}
                        />
                      )}
                    />
                    <Typography variant="subtitle2" color="textSecondary">
                      {inputValue === "" || inputValue === null ? (
                        <>Selecione Uma Empresa Para Enviar...</>
                      ) : (
                        <>
                          {inputValue?.document?.length === 14
                            ? `CNPJ: ${MaskService.toMask(
                                "cnpj",
                                inputValue?.document
                              )}`
                            : `CPF: ${MaskService.toMask(
                                "cpf",
                                inputValue?.document
                              )}`}
                        </>
                      )}
                    </Typography>
                  </Grid>
                )}
              </FormGroup>
            </FormControl>
          </Grid>
        </div>
      </div>

      {localStorage.getItem("isAccountantPanel") === "true" && (
        <div style={{ textAlign: "left" }}>
          <Grid container style={{ marginTop: "16px" }}>
            <Grid item xs={6}>
              {hasAreaChecked(props.document) && (
                <FormControlLabel
                  style={{ fontSize: "10px" }}
                  control={
                    <Checkbox
                      style={{ marginTop: 0 }}
                      color="primary"
                      key={"OPEN_TICKET_CRM_" + props.index + " - " + props.key}
                      disabled={
                        outros === false &&
                        fiscal === false &&
                        contabil === false &&
                        folha === false &&
                        contratoSocial === false &&
                        contratoTrabalho === false
                      }
                      checked={openTicketInCRM}
                      onChange={(ev) => {
                        props.document.openTicketInCRM = ev.target.checked;
                        setOpenTicketInCrm(ev.target.checked);
                      }}
                    />
                  }
                  label={
                    <Typography variant="body2">
                      Abrir atendimento no Gestão Relacionamento
                    </Typography>
                  }
                />
              )}

              {props.docTypeSelected !== "FGTS" &&
                props.docTypeSelected !== "DCTF_RETURN" &&
                props.docTypeSelected !== "NOT_IDENTIFIED" && (
                  <FormControlLabel
                    style={{ fontSize: "10px" }}
                    control={
                      <Checkbox
                        style={{ marginTop: 0 }}
                        color="primary"
                        key={
                          "SEND_BILL_TO_PAY" + props.index + " - " + props.key
                        }
                        checked={sendBillToPay}
                        onChange={(ev) => {
                          setSendBillToPay(ev.target.checked);
                          props.document.sendToPay = ev.target.checked;
                        }}
                      />
                    }
                    label={
                      <Typography variant="body2">
                        Enviar para o Contas a Pagar
                      </Typography>
                    }
                  />
                )}
            </Grid>
            {openTicketInCRM && hasAreaChecked(props.document) && (
              <Grid item xs={6} key={props.key}>
                <Autocomplete
                  fullWidth
                  disabled={
                    outros === false &&
                    fiscal === false &&
                    contabil === false &&
                    folha === false &&
                    contratoSocial === false &&
                    contratoTrabalho === false
                  }
                  options={props.users.map((client) => {
                    return {
                      label: client.email,
                      email: client.email,
                    };
                  })}
                  getOptionLabel={(option) => {
                    return option.label;
                  }}
                  value={generateTicketEmail}
                  onChange={(event, newValue) => {
                    setGenerateTicketEmail(newValue);
                    props.document.generateTicketEmail = newValue;
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      InputLabelProps={{ style: { marginTop: "-8px" } }}
                      label="Selecione o email para atendimento"
                    />
                  )}
                ></Autocomplete>
              </Grid>
            )}
          </Grid>
        </div>
      )}
    </>
  );
};
