import {
  Backdrop,
  CircularProgress,
  Fade,
  Grid,
  Modal,
  TextField,
  makeStyles,
} from "@material-ui/core";
import React from "react";
import {
  ConfigDocText,
  HeaderGrid,
} from "../styles/ModalPublicationChannelFrame";
import { NameTxtFieldTemplate } from "../styles/ModalEmailTemplates";
import { CancelButton } from "../styles/ModalServicesConfig";
import KeyboardReturnIcon from "@material-ui/icons/KeyboardReturn";
import { BlueButton } from "../styles/ModalPublicationChannelFrame";
import CloudDoneOutlinedIcon from "@material-ui/icons/CloudDoneOutlined";
import { sendPreviewEmail } from "../../../repositories/EmailTemplate";
import { GlobalContext } from "../../../contexts/GlobalContext";
import { notify } from "../../../utils/notify";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "24px",
    flexDirection: "column",
    gap: "24px",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    width: "800px",
    borderRadius: "16px",
  },
  MuiAccordionroot: {
    "&.MuiAccordion-root:before": {},
  },
}));

export const ModalSendTestEmailTemplate = ({
  open,
  handleClose,
  clickedTemplate,
}) => {
  const classes = useStyles();
  const [value, setValue] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const { companyUser } = React.useContext(GlobalContext);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <Modal
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          {isLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                padding: "30px",
              }}
            >
              {" "}
              <CircularProgress />
            </div>
          ) : (
            <>
              <HeaderGrid container justify="space-between">
                <ConfigDocText style={{ marginBottom: "16px" }}>
                  Enviar Email de Teste
                </ConfigDocText>

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  onClick={handleClose}
                  style={{ width: "24px", height: "24px", cursor: "pointer" }}
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3.29289 3.29289C3.68342 2.90237 4.31658 2.90237 4.70711 3.29289L12 10.5858L19.2929 3.29289C19.6834 2.90237 20.3166 2.90237 20.7071 3.29289C21.0976 3.68342 21.0976 4.31658 20.7071 4.70711L13.4142 12L20.7071 19.2929C21.0976 19.6834 21.0976 20.3166 20.7071 20.7071C20.3166 21.0976 19.6834 21.0976 19.2929 20.7071L12 13.4142L4.70711 20.7071C4.31658 21.0976 3.68342 21.0976 3.29289 20.7071C2.90237 20.3166 2.90237 19.6834 3.29289 19.2929L10.5858 12L3.29289 4.70711C2.90237 4.31658 2.90237 3.68342 3.29289 3.29289Z"
                    fill="#7E899E"
                  />
                </svg>
              </HeaderGrid>

              <NameTxtFieldTemplate>
                <TextField
                  id="outlined-basic"
                  fullWidth
                  placeholder="Digite um email"
                  variant="outlined"
                  size="small"
                  value={value}
                  onChange={handleChange}
                  style={{ borderRadius: "8px" }}
                />
              </NameTxtFieldTemplate>
              <Grid
                container
                xs={12}
                style={{ justifyContent: "end", gap: "10px" }}
              >
                <CancelButton onClick={() => handleClose()} item>
                  <KeyboardReturnIcon style={{ marginRight: "4px" }} />
                  Cancelar
                </CancelButton>

                <BlueButton
                  onClick={async () => {
                    setIsLoading(true);

                    let response = await sendPreviewEmail(
                      value,
                      companyUser.document,
                      clickedTemplate.type,
                      clickedTemplate.id
                    );

                    if (response?.success) {
                      notify("Prévia enviada com sucesso!", true, "success");
                    } else {
                      notify(
                        "Ocorreu um erro ao enviar a prévia!",
                        true,
                        "error"
                      );
                    }

                    setIsLoading(false);
                    setValue("");
                    handleClose();
                  }}
                  item
                  style={{
                    background: "#0036B3",
                    marginTop: "24px",
                    display: "flex",
                    justifyContent: "normal",
                  }}
                >
                  <CloudDoneOutlinedIcon style={{ marginRight: "8px" }} />
                  Enviar
                </BlueButton>
              </Grid>
            </>
          )}
        </div>
      </Fade>
    </Modal>
  );
};
