import React from "react";
import { useEffect, useState } from "react";

import Modal from "@material-ui/core/Modal";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import { Grid } from "@material-ui/core";

const dialogStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  height: 450,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 10,
};

export const BotVideoListModal = (props) => {
  const [videos, setVideos] = useState(null);

  useEffect(() => {
    let data = {
      success: true,
      msg: "A requisição foi finalizada com sucesso.",
      data: [
        {
          Id: 28,
          IdLocalizacaoPontoAcesso: 24,
          IdProduto: 1,
          Ativo: true,
          Titulo: "Orçamentos - Novo Orçamento",
          Url: "https://iendo.b-cdn.net/uploads/protected/61f/d41/1b8/61fd411b8967f856407648.mp4",
          createdAt: "2022-02-04T18:56:34.157Z",
          updatedAt: "2022-02-04T18:56:34.157Z",
          deletedAt: null,
        },
        {
          Id: 29,
          IdLocalizacaoPontoAcesso: 25,
          IdProduto: 1,
          Ativo: true,
          Titulo: "Orçamentos - Controle de Orçamentos",
          Url: "https://iendo.b-cdn.net/uploads/protected/61f/d41/348/61fd413486dd5232636839.mp4",
          createdAt: "2022-02-04T18:57:17.353Z",
          updatedAt: "2022-02-04T18:57:17.353Z",
          deletedAt: null,
        },
        {
          Id: 30,
          IdLocalizacaoPontoAcesso: 26,
          IdProduto: 1,
          Ativo: true,
          Titulo: "Pedidos - Novo Pedido",
          Url: "https://iendo.b-cdn.net/uploads/protected/61f/d41/59c/61fd4159c6e40819188520.mp4",
          createdAt: "2022-02-04T18:57:58.598Z",
          updatedAt: "2022-02-04T18:57:58.598Z",
          deletedAt: null,
        },
        {
          Id: 31,
          IdLocalizacaoPontoAcesso: 27,
          IdProduto: 1,
          Ativo: true,
          Titulo: "Pedidos - Controle de Pedidos",
          Url: "https://iendo.b-cdn.net/uploads/protected/61f/d41/76d/61fd4176df322139635960.mp4",
          createdAt: "2022-02-04T19:00:06.242Z",
          updatedAt: "2022-02-04T19:00:06.242Z",
          deletedAt: null,
        },
        {
          Id: 32,
          IdLocalizacaoPontoAcesso: 28,
          IdProduto: 1,
          Ativo: true,
          Titulo: "Nota Fiscal - Emissão Nfe",
          Url: "https://iendo.b-cdn.net/uploads/protected/61f/d41/bb6/61fd41bb6b91d655683769.mp4",
          createdAt: "2022-02-04T19:01:05.087Z",
          updatedAt: "2022-02-04T19:01:05.087Z",
          deletedAt: null,
        },
        {
          Id: 33,
          IdLocalizacaoPontoAcesso: 28,
          IdProduto: 1,
          Ativo: true,
          Titulo: "Nota Fiscal - Montagem Automática",
          Url: "https://iendo.b-cdn.net/uploads/protected/61f/d41/d1d/61fd41d1d8392052283678.mp4",
          createdAt: "2022-02-04T19:01:31.608Z",
          updatedAt: "2022-02-04T19:01:31.608Z",
          deletedAt: null,
        },
        {
          Id: 6,
          IdLocalizacaoPontoAcesso: 8,
          IdProduto: 1,
          Ativo: true,
          Titulo: "Capacitando o Robô - Dados da Empresa_Logotipo_Certificado",
          Url: "https://iendo.b-cdn.net/uploads/protected/61f/d3e/388/61fd3e38807fa043220864.mp4",
          createdAt: "2022-02-04T17:55:31.042Z",
          updatedAt: "2022-02-04T17:55:31.042Z",
          deletedAt: null,
        },
        {
          Id: 7,
          IdLocalizacaoPontoAcesso: 8,
          IdProduto: 1,
          Ativo: true,
          Titulo: "Capacitando o Robô - Dados Contabilista",
          Url: "https://iendo.b-cdn.net/uploads/protected/61f/d3e/4c8/61fd3e4c8d25b447785790.mp4",
          createdAt: "2022-02-04T17:55:53.964Z",
          updatedAt: "2022-02-04T17:56:08.191Z",
          deletedAt: null,
        },
        {
          Id: 8,
          IdLocalizacaoPontoAcesso: 8,
          IdProduto: 1,
          Ativo: true,
          Titulo: "Capacitando o Robô - Importação de XML",
          Url: "https://iendo.b-cdn.net/uploads/protected/61f/d3e/65b/61fd3e65b8d19860583559.mp4",
          createdAt: "2022-02-04T17:56:31.802Z",
          updatedAt: "2022-02-04T17:56:31.802Z",
          deletedAt: null,
        },
        {
          Id: 9,
          IdLocalizacaoPontoAcesso: 8,
          IdProduto: 1,
          Ativo: true,
          Titulo: "Capacitando o Robô - Documentos Fiscais",
          Url: "https://iendo.b-cdn.net/uploads/protected/61f/d3e/799/61fd3e79941a8280538777.mp4",
          createdAt: "2022-02-04T17:56:54.137Z",
          updatedAt: "2022-02-04T17:56:54.137Z",
          deletedAt: null,
        },
        {
          Id: 10,
          IdLocalizacaoPontoAcesso: 8,
          IdProduto: 1,
          Ativo: true,
          Titulo: "Usuário - Logout",
          Url: "https://iendo.b-cdn.net/uploads/protected/61f/d3e/831/61fd3e831e777315174010.mp4",
          createdAt: "2022-02-04T17:57:12.510Z",
          updatedAt: "2022-02-04T17:57:12.510Z",
          deletedAt: null,
        },
        {
          Id: 11,
          IdLocalizacaoPontoAcesso: 8,
          IdProduto: 1,
          Ativo: true,
          Titulo: "Dashboard - Fluxo",
          Url: "https://iendo.b-cdn.net/uploads/protected/61f/d3e/92e/61fd3e92e4a51777996545.mp4",
          createdAt: "2022-02-04T17:57:46.461Z",
          updatedAt: "2022-02-04T17:57:46.461Z",
          deletedAt: null,
        },
        {
          Id: 34,
          IdLocalizacaoPontoAcesso: 29,
          IdProduto: 1,
          Ativo: true,
          Titulo: "Nota Fiscal - Controle Nfe",
          Url: "https://iendo.b-cdn.net/uploads/protected/61f/d41/f16/61fd41f1634da327411307.mp4",
          createdAt: "2022-02-04T19:02:04.007Z",
          updatedAt: "2022-02-04T19:02:04.007Z",
          deletedAt: null,
        },
        {
          Id: 3,
          IdLocalizacaoPontoAcesso: 3,
          IdProduto: 1,
          Ativo: true,
          Titulo: "Clientes",
          Url: "https://iendo.b-cdn.net/uploads/protected/61f/d3f/559/61fd3f5594817969871669.mp4",
          createdAt: "2021-10-22T18:02:00.177Z",
          updatedAt: "2022-02-04T18:01:49.145Z",
          deletedAt: null,
        },
        {
          Id: 13,
          IdLocalizacaoPontoAcesso: 9,
          IdProduto: 1,
          Ativo: true,
          Titulo: "Fornecedores",
          Url: "https://iendo.b-cdn.net/uploads/protected/61f/d3f/6bf/61fd3f6bf1c7c479601445.mp4",
          createdAt: "2022-02-04T18:03:11.136Z",
          updatedAt: "2022-02-04T18:03:11.136Z",
          deletedAt: null,
        },
        {
          Id: 14,
          IdLocalizacaoPontoAcesso: 10,
          IdProduto: 1,
          Ativo: true,
          Titulo: "Produtos",
          Url: "https://iendo.b-cdn.net/uploads/protected/61f/d3f/8be/61fd3f8be0917284066723.mp4",
          createdAt: "2022-02-04T18:08:25.238Z",
          updatedAt: "2022-02-04T18:08:25.238Z",
          deletedAt: null,
        },
        {
          Id: 15,
          IdLocalizacaoPontoAcesso: 11,
          IdProduto: 1,
          Ativo: true,
          Titulo: "Transportadora",
          Url: "https://iendo.b-cdn.net/uploads/protected/61f/d3f/a16/61fd3fa16527c310704460.mp4",
          createdAt: "2022-02-04T18:08:54.236Z",
          updatedAt: "2022-02-04T18:08:54.236Z",
          deletedAt: null,
        },
        {
          Id: 4,
          IdLocalizacaoPontoAcesso: 5,
          IdProduto: 1,
          Ativo: true,
          Titulo: "Produtos",
          Url: "https://iendo.b-cdn.net/uploads/protected/61f/d3f/8be/61fd3f8be0917284066723.mp4",
          createdAt: "2021-10-28T21:42:50.184Z",
          updatedAt: "2022-02-04T18:16:09.955Z",
          deletedAt: null,
        },
        {
          Id: 16,
          IdLocalizacaoPontoAcesso: 12,
          IdProduto: 1,
          Ativo: true,
          Titulo: "Vendas - Terminais Caixa",
          Url: "https://iendo.b-cdn.net/uploads/protected/61f/d3f/b03/61fd3fb032f0b695568316.mp4",
          createdAt: "2022-02-04T18:17:16.101Z",
          updatedAt: "2022-02-04T18:17:16.101Z",
          deletedAt: null,
        },
        {
          Id: 17,
          IdLocalizacaoPontoAcesso: 13,
          IdProduto: 1,
          Ativo: true,
          Titulo: "Serviços - Serviço",
          Url: "https://iendo.b-cdn.net/uploads/protected/61f/d3f/bbc/61fd3fbbc4688018085944.mp4",
          createdAt: "2022-02-04T18:19:24.220Z",
          updatedAt: "2022-02-04T18:19:24.220Z",
          deletedAt: null,
        },
        {
          Id: 18,
          IdLocalizacaoPontoAcesso: 14,
          IdProduto: 1,
          Ativo: true,
          Titulo: "Serviços - Classificação de Serviços",
          Url: "https://iendo.b-cdn.net/uploads/protected/61f/d3f/c99/61fd3fc99c9d8851183603.mp4",
          createdAt: "2022-02-04T18:20:01.674Z",
          updatedAt: "2022-02-04T18:20:01.674Z",
          deletedAt: null,
        },
        {
          Id: 19,
          IdLocalizacaoPontoAcesso: 15,
          IdProduto: 1,
          Ativo: true,
          Titulo: "Financeiro - Contas Bancárias",
          Url: "https://iendo.b-cdn.net/uploads/protected/61f/d3f/d95/61fd3fd95b2fe972331610.mp4",
          createdAt: "2022-02-04T18:21:02.992Z",
          updatedAt: "2022-02-04T18:21:02.992Z",
          deletedAt: null,
        },
        {
          Id: 20,
          IdLocalizacaoPontoAcesso: 16,
          IdProduto: 1,
          Ativo: true,
          Titulo: "Financeiro - Tipo de Pagamento",
          Url: "https://iendo.b-cdn.net/uploads/protected/61f/d3f/e76/61fd3fe7625b6378058381.mp4",
          createdAt: "2022-02-04T18:21:50.163Z",
          updatedAt: "2022-02-04T18:21:50.163Z",
          deletedAt: null,
        },
        {
          Id: 21,
          IdLocalizacaoPontoAcesso: 17,
          IdProduto: 1,
          Ativo: true,
          Titulo: "Financeiro - Forma de Pagamento",
          Url: "https://iendo.b-cdn.net/uploads/protected/61f/d3f/f7c/61fd3ff7c1f63538349622.mp4",
          createdAt: "2022-02-04T18:22:29.978Z",
          updatedAt: "2022-02-04T18:22:29.978Z",
          deletedAt: null,
        },
        {
          Id: 22,
          IdLocalizacaoPontoAcesso: 18,
          IdProduto: 1,
          Ativo: true,
          Titulo: "Financeiro - Operadoras de Cartão",
          Url: "https://iendo.b-cdn.net/uploads/protected/61f/d40/066/61fd40066e6b7188247259.mp4",
          createdAt: "2022-02-04T18:23:02.268Z",
          updatedAt: "2022-02-04T18:23:02.268Z",
          deletedAt: null,
        },
        {
          Id: 35,
          IdLocalizacaoPontoAcesso: 30,
          IdProduto: 1,
          Ativo: true,
          Titulo: "Gestão de Pagamento - Contas a Pagar",
          Url: "https://iendo.b-cdn.net/uploads/protected/61f/d42/869/61fd428691e9a552142008.mp4",
          createdAt: "2022-02-04T19:05:24.896Z",
          updatedAt: "2022-02-04T19:05:24.896Z",
          deletedAt: null,
        },
        {
          Id: 12,
          IdLocalizacaoPontoAcesso: 8,
          IdProduto: 1,
          Ativo: true,
          Titulo: "Dashboard - Geral",
          Url: "https://iendo.b-cdn.net/uploads/protected/61f/d3e/a0b/61fd3ea0b22b9780453869.mp4",
          createdAt: "2022-02-04T17:58:38.223Z",
          updatedAt: "2022-02-04T18:25:31.842Z",
          deletedAt: null,
        },
        {
          Id: 23,
          IdLocalizacaoPontoAcesso: 19,
          IdProduto: 1,
          Ativo: true,
          Titulo: "Estoque - Grupo",
          Url: "https://iendo.b-cdn.net/uploads/protected/61f/d40/0fa/61fd400fa4350735489219.mp4",
          createdAt: "2022-02-04T18:45:36.825Z",
          updatedAt: "2022-02-04T18:45:36.825Z",
          deletedAt: null,
        },
        {
          Id: 24,
          IdLocalizacaoPontoAcesso: 20,
          IdProduto: 1,
          Ativo: true,
          Titulo: "Estoque - Subgrupo",
          Url: "https://iendo.b-cdn.net/uploads/protected/61f/d40/1ac/61fd401ac1819157677545.mp4",
          createdAt: "2022-02-04T18:46:12.532Z",
          updatedAt: "2022-02-04T18:46:12.532Z",
          deletedAt: null,
        },
        {
          Id: 25,
          IdLocalizacaoPontoAcesso: 21,
          IdProduto: 1,
          Ativo: true,
          Titulo: "Fiscais - CFOP",
          Url: "https://iendo.b-cdn.net/uploads/protected/61f/d40/363/61fd40363ae09889781533.mp4",
          createdAt: "2022-02-04T18:53:20.464Z",
          updatedAt: "2022-02-04T18:53:20.464Z",
          deletedAt: null,
        },
        {
          Id: 26,
          IdLocalizacaoPontoAcesso: 22,
          IdProduto: 1,
          Ativo: true,
          Titulo: "Fiscais - NCM",
          Url: "https://iendo.b-cdn.net/uploads/protected/61f/d40/5b5/61fd405b50e90297234518.mp4",
          createdAt: "2022-02-04T18:53:56.471Z",
          updatedAt: "2022-02-04T18:53:56.471Z",
          deletedAt: null,
        },
        {
          Id: 27,
          IdLocalizacaoPontoAcesso: 23,
          IdProduto: 1,
          Ativo: true,
          Titulo: "Fiscais - Tributação",
          Url: "https://iendo.b-cdn.net/uploads/protected/61f/d40/702/61fd40702cefc771647389.mp4",
          createdAt: "2022-02-04T18:54:31.777Z",
          updatedAt: "2022-02-04T18:54:31.777Z",
          deletedAt: null,
        },
        {
          Id: 5,
          IdLocalizacaoPontoAcesso: 7,
          IdProduto: 1,
          Ativo: true,
          Titulo: "Pedido Rápido",
          Url: "https://iendo.b-cdn.net/uploads/protected/61f/d41/002/61fd41002bf03736581316.mp4",
          createdAt: "2021-10-28T21:45:30.668Z",
          updatedAt: "2022-02-04T18:55:56.899Z",
          deletedAt: null,
        },
        {
          Id: 36,
          IdLocalizacaoPontoAcesso: 31,
          IdProduto: 1,
          Ativo: true,
          Titulo: "Gestão de Pagamento - Lançamento de Pagamentos",
          Url: "https://iendo.b-cdn.net/uploads/protected/61f/d42/a13/61fd42a13506f536633353.mp4",
          createdAt: "2022-02-04T19:06:00.176Z",
          updatedAt: "2022-02-04T19:06:00.176Z",
          deletedAt: null,
        },
        {
          Id: 37,
          IdLocalizacaoPontoAcesso: 32,
          IdProduto: 1,
          Ativo: true,
          Titulo: "Gestão de Pagament - Histórico de Pagamentos",
          Url: "https://iendo.b-cdn.net/uploads/protected/61f/d42/b17/61fd42b17ed8f177511497.mp4",
          createdAt: "2022-02-04T19:06:38.776Z",
          updatedAt: "2022-02-04T19:06:38.776Z",
          deletedAt: null,
        },
        {
          Id: 38,
          IdLocalizacaoPontoAcesso: 33,
          IdProduto: 1,
          Ativo: true,
          Titulo: "Gestão de Recebimento - Contas a Receber",
          Url: "https://iendo.b-cdn.net/uploads/protected/61f/d43/0c2/61fd430c2e0ce643024544.mp4",
          createdAt: "2022-02-04T19:07:16.008Z",
          updatedAt: "2022-02-04T19:07:16.008Z",
          deletedAt: null,
        },
        {
          Id: 39,
          IdLocalizacaoPontoAcesso: 34,
          IdProduto: 1,
          Ativo: true,
          Titulo: "Gestão de Recebimento - Lançamento de Recebimento",
          Url: "https://iendo.b-cdn.net/uploads/protected/61f/d43/214/61fd43214b8ed212901453.mp4",
          createdAt: "2022-02-04T19:07:47.232Z",
          updatedAt: "2022-02-04T19:07:47.232Z",
          deletedAt: null,
        },
        {
          Id: 40,
          IdLocalizacaoPontoAcesso: 35,
          IdProduto: 1,
          Ativo: true,
          Titulo: "Gestão de Recebimento - Histórico de Recebimentos",
          Url: "https://iendo.b-cdn.net/uploads/protected/61f/d43/306/61fd433068a85702737230.mp4",
          createdAt: "2022-02-04T19:08:20.724Z",
          updatedAt: "2022-02-04T19:08:20.724Z",
          deletedAt: null,
        },
        {
          Id: 41,
          IdLocalizacaoPontoAcesso: 36,
          IdProduto: 1,
          Ativo: true,
          Titulo: "Gestão de Recebimento - Histórico de Abatimentos",
          Url: "https://iendo.b-cdn.net/uploads/protected/61f/d43/3d4/61fd433d485f0260664774.mp4",
          createdAt: "2022-02-04T19:08:48.984Z",
          updatedAt: "2022-02-04T19:08:48.984Z",
          deletedAt: null,
        },
        {
          Id: 42,
          IdLocalizacaoPontoAcesso: 37,
          IdProduto: 1,
          Ativo: true,
          Titulo: "Manut Manual Estoque - Lançamento de Entrada",
          Url: "https://iendo.b-cdn.net/uploads/protected/61f/d45/026/61fd45026d51e586692427.mp4",
          createdAt: "2022-02-04T19:09:50.527Z",
          updatedAt: "2022-02-04T19:09:50.527Z",
          deletedAt: null,
        },
        {
          Id: 43,
          IdLocalizacaoPontoAcesso: 38,
          IdProduto: 1,
          Ativo: true,
          Titulo: "Manut Manual Estoque - Lançamento de Saída",
          Url: "https://iendo.b-cdn.net/uploads/protected/61f/d45/0f6/61fd450f6180f294523327.mp4",
          createdAt: "2022-02-04T19:10:21.952Z",
          updatedAt: "2022-02-04T19:10:21.952Z",
          deletedAt: null,
        },
        {
          Id: 44,
          IdLocalizacaoPontoAcesso: 39,
          IdProduto: 1,
          Ativo: true,
          Titulo: "Gestão de Preços - Reajuste de Preço",
          Url: "https://iendo.b-cdn.net/uploads/protected/61f/d45/1c8/61fd451c84199432978065.mp4",
          createdAt: "2022-02-04T19:10:57.299Z",
          updatedAt: "2022-02-04T19:10:57.299Z",
          deletedAt: null,
        },
        {
          Id: 45,
          IdLocalizacaoPontoAcesso: 40,
          IdProduto: 1,
          Ativo: true,
          Titulo: "Inventário - Acerto de Estoque (item)",
          Url: "https://iendo.b-cdn.net/uploads/protected/61f/d45/29c/61fd4529cd590866840974.mp4",
          createdAt: "2022-02-04T19:11:27.088Z",
          updatedAt: "2022-02-04T19:11:27.088Z",
          deletedAt: null,
        },
        {
          Id: 47,
          IdLocalizacaoPontoAcesso: 42,
          IdProduto: 1,
          Ativo: true,
          Titulo: "Exportação Contábil - Plano de Contas",
          Url: "https://iendo.b-cdn.net/uploads/protected/620/119/8b8/6201198b8b4df027321166.mp4",
          createdAt: "2022-02-07T13:09:23.932Z",
          updatedAt: "2022-02-07T13:09:23.932Z",
          deletedAt: null,
        },
        {
          Id: 48,
          IdLocalizacaoPontoAcesso: 43,
          IdProduto: 1,
          Ativo: true,
          Titulo: "Exportação Contábil - Exportação Contábil",
          Url: "https://iendo.b-cdn.net/uploads/protected/620/119/9b7/6201199b7f275175795852.mp4",
          createdAt: "2022-02-07T13:10:06.350Z",
          updatedAt: "2022-02-07T13:10:06.350Z",
          deletedAt: null,
        },
        {
          Id: 49,
          IdLocalizacaoPontoAcesso: 44,
          IdProduto: 1,
          Ativo: true,
          Titulo: "Gestão de Usuários - Cadastro de Usuários",
          Url: "https://iendo.b-cdn.net/uploads/protected/620/11a/fcd/62011afcdc7c4698063305.mp4",
          createdAt: "2022-02-07T13:18:45.759Z",
          updatedAt: "2022-02-07T13:18:45.759Z",
          deletedAt: null,
        },
        {
          Id: 50,
          IdLocalizacaoPontoAcesso: 45,
          IdProduto: 1,
          Ativo: true,
          Titulo: "Gestão de Usuários - Liberar Módulos Usuário",
          Url: "https://iendo.b-cdn.net/uploads/protected/620/11b/099/62011b0993b57191848982.mp4",
          createdAt: "2022-02-07T13:21:11.785Z",
          updatedAt: "2022-02-07T13:21:11.785Z",
          deletedAt: null,
        },
        {
          Id: 51,
          IdLocalizacaoPontoAcesso: 46,
          IdProduto: 1,
          Ativo: true,
          Titulo: "Gestão de Usuários - Liberar Menus Usuário",
          Url: "https://iendo.b-cdn.net/uploads/protected/620/11b/134/62011b134426e282962032.mp4",
          createdAt: "2022-02-07T13:23:05.623Z",
          updatedAt: "2022-02-07T13:23:05.623Z",
          deletedAt: null,
        },
        {
          Id: 52,
          IdLocalizacaoPontoAcesso: 47,
          IdProduto: 1,
          Ativo: true,
          Titulo: "Gestão de Usuários - Liberar Permissões Usuário",
          Url: "https://iendo.b-cdn.net/uploads/protected/620/11b/22d/62011b22d84e2558350690.mp4",
          createdAt: "2022-02-07T13:24:54.090Z",
          updatedAt: "2022-02-07T13:24:54.090Z",
          deletedAt: null,
        },
        {
          Id: 53,
          IdLocalizacaoPontoAcesso: 48,
          IdProduto: 1,
          Ativo: true,
          Titulo: "Gestão de Grupos Usuário - Cadastro de Grupos de Usuários",
          Url: "https://iendo.b-cdn.net/uploads/protected/620/11b/2d8/62011b2d82175711103143.mp4",
          createdAt: "2022-02-07T13:31:28.883Z",
          updatedAt: "2022-02-07T13:31:28.883Z",
          deletedAt: null,
        },
        {
          Id: 54,
          IdLocalizacaoPontoAcesso: 49,
          IdProduto: 1,
          Ativo: true,
          Titulo: "Gestão de Grupos Usuário - Liberar Módulos Grupo",
          Url: "https://iendo.b-cdn.net/uploads/protected/620/11b/350/62011b35099ae248430780.mp4",
          createdAt: "2022-02-07T13:33:17.314Z",
          updatedAt: "2022-02-07T13:33:17.314Z",
          deletedAt: null,
        },
        {
          Id: 55,
          IdLocalizacaoPontoAcesso: 50,
          IdProduto: 1,
          Ativo: true,
          Titulo: "Gestão de Grupos Usuário - Liberar Menus Grupo",
          Url: "https://iendo.b-cdn.net/uploads/protected/620/11b/3ed/62011b3eda2f2170260554.mp4",
          createdAt: "2022-02-07T13:35:00.049Z",
          updatedAt: "2022-02-07T13:35:00.049Z",
          deletedAt: null,
        },
        {
          Id: 56,
          IdLocalizacaoPontoAcesso: 51,
          IdProduto: 1,
          Ativo: true,
          Titulo: "Gestão de Grupos Usuário - Liberar Permissões Grupo",
          Url: "https://iendo.b-cdn.net/uploads/protected/620/11b/46a/62011b46a9498036460741.mp4",
          createdAt: "2022-02-07T13:36:40.669Z",
          updatedAt: "2022-02-07T13:36:40.669Z",
          deletedAt: null,
        },
        {
          Id: 57,
          IdLocalizacaoPontoAcesso: 1,
          IdProduto: 1,
          Ativo: true,
          Titulo: "Movimentação Bancária\t- Extrato de Movimentações",
          Url: "https://iendo.b-cdn.net/uploads/protected/620/121/fb6/620121fb6c24c743504025.mp4",
          createdAt: "2022-02-07T13:45:09.059Z",
          updatedAt: "2022-02-07T13:45:09.059Z",
          deletedAt: null,
        },
      ],
      warnings: [],
      internalCode: null,
      extraData: null,
    };

    setVideos(data.data);
  }, []);

  return (
    <Modal
      open={props.openModal}
      onClose={() => {
        props.closeVideoList();
      }}
    >
      <Box sx={dialogStyle}>
        <Typography
          variant="h6"
          component="h2"
          style={{
            fontWeight: "bold",
            background: "rgb(19, 142, 247)",
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
            padding: 20,
            color: "white",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              position: "relative",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              Lista de videos
            </div>
            <CloseIcon
              style={{ position: "absolute", right: 0, cursor: "pointer" }}
              onClick={() => {
                props.closeVideoList();
              }}
            />
          </div>
        </Typography>

        {videos != null && (
          <div
            style={{
              height: 380,
              overflowY: "auto",
              overflowX: "hidden",
              padding: 20,
            }}
          >
            <div style={{ fontSize: 18, marginBottom: 10 }}>
              QYON GESTÃO CONTÁBIL - TESTE A VERSÃO BETA AGORA! (CORTES)
            </div>

            <iframe
              style={{ marginBottom: 10 }}
              width="100%"
              height="400"
              src="https://www.youtube.com/embed/3dvnanqumRg"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>

            {videos.map((video) => (
              <Grid container spacing={6}>
                <Grid item xs={12} style={{ paddingBottom: 0 }}>
                  <div style={{ fontSize: 18 }}>{video.Titulo}</div>
                </Grid>
                <Grid item xs={12}>
                  <video width="100%" height="310" controls>
                    <source src={video.Url} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </Grid>
              </Grid>
            ))}
          </div>
        )}
      </Box>
    </Modal>
  );
};
