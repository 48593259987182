import styled, { css } from "styled-components";

import {
  Fab,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  Box,
} from "@material-ui/core";

import Badge from "@material-ui/core/Badge";
import ListItemText from "@material-ui/core/ListItemText";
import { LabelStyled } from "../../../styles/GlobalStyle";

const secondary = (props) => props.theme.palette.secondary;
const primary = (props) => props.theme.palette.primary;

export const IconContainer = styled(Grid)`
  position: relative;
`;

export const BetaBadge = styled.span`
  color: ${primary};
  background: ${secondary};
  font-size: 0.4rem;
  font-weight: bold;
  padding: 1px 2px;
  border-radius: 5px;
  user-select: none;

  position: absolute;
  right: 0;
  top: 0;
  transform: translate(-3px, -5px);
`;

export const LabelStyledSubMenu = styled.label`
  ${(props) =>
    props.fontSize &&
    css`
      font-size: ${(props) => props.fontSize}px;
    `};

  ${(props) =>
    props.fontFamily &&
    css`
      font-family: ${(props) => props.fontFamily};
    `};

  ${(props) =>
    props.margin &&
    css`
      margin: ${(props) => props.margin}px;
    `};

  color: ${(props) => props.color};

  font-weight: 500;

  ${(props) =>
    props.cursor &&
    css`
      cursor: ${(props) => props.cursor};
    `};

  ${(props) =>
    props["text-align"] &&
    css`
      text-align: ${(props) => props["text-align"]};
    `};
`;

// TODO ADD SMALLER HEIGHT TO SUBITEMS
export const ListItemCustom = styled(ListItem)`
  padding: 1px 0px 1px 0px !important;
  margin-bottom: 5px !important;
  border-radius: 8px;
  &:hover {
    background-color: #e7eefe;
  }

  ${LabelStyled} {
    font-size: 13px;
    font-weight: 500;
  }

  ${(props) =>
    props.subitem &&
    css`
      height: 24px;
    `}
  }

  .MuiListItemText-root {
    margin-top: 3px;
    margin-bottom: 3px;
  }
`;

export const ListItemIconCustom = styled(ListItemIcon)`
  margin-left: 0px;
  padding: 0px;
`;

export const PulseIcon = styled.div`
  ${(props) =>
    props.pulseIcon &&
    css`
      border-radius: 50%;
      width: 16px;
      height: 16px;
      margin: 0px;
      position: absolute;
      bottom: 0px !important;
      right: 0px;
      transform: scale(1);
      animation: pulse 1.2s infinite;
      box-shadow: 0 0 0 0 ${props.pulseIcon || "rgb(212, 19, 13, 1)"};
      @keyframes pulse {
        0% {
          transform: scale(1);
        }

        70% {
          transform: scale(1.5);
          box-shadow: 0 0 0 15px rgba(52, 172, 224, 0);
        }

        100% {
          transform: scale(1);
          box-shadow: 0 0 0 0 rgba(52, 172, 224, 0);
        }
      }
    `}
`;

export const ContainerDrawer = styled.div`
  display: flex;
`;

export const Toolbar = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.open ? "flex-end" : "center")};
`;

export const LogoDrawer = styled.img`
  background-repeat: no-repeat;
  background-size: cover;
  width: 150px;
  margin-top: 15px;
`;

export const InfoUserContainer = styled(Grid)`
  height: 40px;
  transition: all 0.24s ease;
  overflow: hidden;
  padding: ${(props) => (props.open ? "2px 10px" : "10px 0px 0px 0px")};
  width: ${(props) => (props.open ? "265px" : "73px")};
`;

export const InfoUser = styled.div`
  margin-top: 1px;
  margin-left: 2px;
  font-size: 10px;
  font-weight: 410;
  color: #424c5d;
  white-space: nowrap;
  width: 1px;
`;

export const FloatAction = styled(Fab)`
  ${(props) =>
    props.width &&
    css`
      width: ${(props) => props.width}px;
    `}

  ${(props) =>
    props.top &&
    css`
      top: ${(props) => props.top}vh;
    `}

  ${(props) =>
    props.right &&
    css`
      right: ${(props) => props.right}px;
    `}

  ${(props) =>
    props["border-radius"] &&
    css`
      border-radius: ${(props) => props["border-radius"]}px;
    `}

  text-transform: none;
  position: fixed !important;
  border: solid 3px white !important;
  z-index: 1201;
  box-shadow: none;
  transition: ease 0.4s;

  @media (max-width: 960px) {
    display: none;
  }
`;

export const Beta = styled(Badge)`
  span {
    font-size: 0.4rem;
    padding: 2.5px;
    min-width: 3.25px;
    height: 9px;
    font-family: "Roboto", sans-serif !important;

    transition-duration: 500ms;
    transition-property: font-size, transform;
    &:hover {
      transform: scale(1) translate(10%, -50%);
      padding: 8px;
      font-size: 0.6rem;
    }
  }
`;

export const SideLabel = styled(ListItemText)`
  label {
    font-family: "Roboto", sans-serif;
  }
`;

export const ListMenuDataOffice = styled(List)`
  ::-webkit-scrollbar {
    width: 6px !important;
    height: 12px !important;
  }
`;

export const DialogGenial4Body = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  box-shadow: 24px;
  border-radius: 10px;
`;

export const SideBarAction = styled.div`
  background-color: #006fdd;
  height: 390px;
  width: 60px;
  position: fixed;
  right: ${(props) => (props.open ? "238px" : "0px")};
  transition: ease 0.6s;
  top: 140px;
  z-index: 10;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  -webkit-box-shadow: -5px 5px 12px -6px rgba(0, 0, 0, 0.64);
  -moz-box-shadow: -5px 5px 12px -6px rgba(0, 0, 0, 0.64);
  box-shadow: -5px 5px 12px -6px rgba(0, 0, 0, 0.64);

  @media (max-width: 600px) {
    display: none;
  }
`;

export const SideBarBot = styled.div`
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  width: 35px;
  right: ${(props) => (props.open ? "250px" : "10px")};
  transition: ease 0.6s;
  z-index: 13;
  top: 115px;
  position: fixed;
  border-radius: 20px;
  -webkit-box-shadow: -5px 5px 12px -6px rgba(0, 0, 0, 0.64);
  -moz-box-shadow: -5px 5px 12px -6px rgba(0, 0, 0, 0.64);
  box-shadow: -5px 5px 12px -6px rgba(0, 0, 0, 0.64);

  @media (max-width: 600px) {
    display: none;
  }
`;
