import { getDocuments } from "../../../repositories/GuideReportList";
import { v4 as uuidv4 } from "uuid";

export const getDocumentList = async ({
  startDate,
  endDate,
  pageSize = 50,
  page = 0,
  folderId,
  search,
  doCount = true,
  searchId,
  inboxStatusId = 1,
} = {}) => {
  const response = await getDocuments({
    page_size: pageSize,
    page: page,
    start_date: startDate,
    end_date: endDate,
    folder_id: folderId,
    search_field: search,
    favorite: inboxStatusId === 2,
    do_count: doCount,
    search_id: searchId,
    archived: inboxStatusId === 4,
  });

  if (response?.success) {
    let documents = response?.data?.documents || [];

    let listaDeObjetosComID = documents.map((object) => ({
      ...object,
      id: uuidv4(),
      formatedEnteredDate: object.date_entered
        ? formatDate(object.date_entered)
        : "",
      formatedExpireDate: object.expire_at ? formatDate(object.expire_at) : "",
      formatedName: formatDocumentName(object.name),
      formatedStatusDate: object.history_status_date
        ? formatDateTime(object.history_status_date)
        : "",
      formatedStatusMessage: formatStatusMessage(object.history_status_message),
      formatedStatusDocument: formatStatusDocument(object.status_document),
    }));

    if (inboxStatusId === 3) {
      listaDeObjetosComID = listaDeObjetosComID.filter((document) => {
        return document.history_status_message === "VIEWED";
      });
    }

    return {
      documentList: listaDeObjetosComID || [],
      totalDocs: response?.data?.total_documents || 0,
    };
  } else {
    return {
      documentList: [],
      totalDocs: 0,
    };
  }
};

export const formatStatusMessage = (message) => {
  switch (message) {
    case "ARCHIVED":
      return "Arquivado";
    case "VIEWED":
      return "Visualizado";
    default:
      return "Não Visualizado";
  }
};

export const formatStatusDocument = (status) => {
  if (!status) {
    return "";
  }

  switch (status) {
    case "N":
      return "Negativa";
    case "P":
      return "Positiva";
    case "I":
      return "Inconsistente";
    case "PN":
      return "Positiva com efeito Negativa";
    case "E":
      return "Erro";
    case "NSA":
      return "Não se Aplica";
    default:
      return status;
  }
};

export const formatDate = (date) => {
  const moment = require("moment");
  const originalDate = date;
  const dates = moment(originalDate);
  const formatedDate = dates.format("DD/MM/YYYY");
  return formatedDate;
};

export const formatDateTime = (date) => {
  const moment = require("moment");
  const originalDate = date;
  const dates = moment(originalDate);
  const formatedDate = dates.format("DD/MM/YYYY HH:mm");
  return formatedDate;
};

export const formatDocumentName = (string) => {
  let regex = /^\d{2}\/\d{2}\/\d{4}\s-\s/;
  let stringFormated = string.replace(regex, "");
  stringFormated = stringFormated[0]?.toUpperCase() + stringFormated?.slice(1);

  return stringFormated;
};
