import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import React, { useEffect } from "react";
import { getDocumentsById } from "../../../../repositories/Document";
import { SearchIcon } from "../../../icons/SearchIcon";
import { notify } from "../../../../utils/notify";


const ModalCommsConfigChannelBody = ({
  selectedServices,
  setSelectedServices,
  serviceId,
  entityId,
  listServices,
}) => {
  const [documentList, setDocumentList] = React.useState([]);
  const [companyName, setCompanyName] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    setLoading(true);

    let params = {
      scheduled_id: null,
      companyId: entityId,
      service: listServices.find((item) => item.id === serviceId).service,
      confirmed: null,
    };

    getDocumentsById(params).then((response) => {
      let data = [];

      if (response?.success) {
        if (response?.data?.length > 0) {
          data = response.data.map((item) => {
            return {
              ...item,
              scheduled_id: selectedServices[serviceId]?.scheduled_id,
            };
          });
        }
      } else {
        notify("Ocorreu um erro ao buscar os documentos!");
      }
      setDocumentList(data);
      setLoading(false);
    });
    // eslint-disable-next-line
  }, [listServices, entityId, serviceId]);

  const isChecked = (document) => {
    return !!selectedServices[serviceId]?.documents?.find(
      (item) => item?.id === document?.id
    );
  };

  const handleChangeCheckbox = (document) => {
    let newServices = { ...selectedServices };

    if (!isChecked(document)) {
      newServices[serviceId].documents.push(document);
    } else {
      newServices[serviceId].documents = newServices[
        serviceId
      ].documents.filter((item) => item.id !== document.id);
    }

    setSelectedServices(newServices);
  };

  return (
    <>
      <div style={{ marginBottom: "8px" }}>
        <TextField
          id="outlined-controlled"
          placeholder="Buscar emails confirmados"
          value={companyName}
          autoComplete="off"
          onChange={(event) => {
            setCompanyName(event.target.value);
          }}
          fullWidth
          variant="outlined"
          sx={{
            "& fieldset": {
              borderRadius: "8px",
            },
          }}
          slotProps={{
            input: {
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon color="#BFC2C7" />
                </InputAdornment>
              ),
            },
          }}
        />
      </div>

      {loading ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </div>
      ) : (
        <div style={{ maxHeight: "350px", overflowY: "auto" }}>
          <FormGroup>
            {documentList.map(
              (document) =>
                document.document
                  .toLowerCase()
                  .includes(companyName.toLowerCase()) && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isChecked(document)}
                        color="primary"
                        onChange={() => handleChangeCheckbox(document)}
                      />
                    }
                    label={document.document}
                  />
                )
            )}
          </FormGroup>
        </div>
      )}
    </>
  );
};

export default ModalCommsConfigChannelBody;
