import React, { useContext, useEffect, useState } from "react";
import { GlobalSubheader } from "../../components/subHeader/GlobalSubheader";
import { DefaultPageContainer } from "../../components/layoutContainer/styles/LayoutContainer";
import { CircularProgress, Grid, makeStyles } from "@material-ui/core";
import { BackupDatagrid, MainText } from "./styles/BackupPage";
// import { ModalBackupWorks } from "./components/ModalBackupWorks";
// import { ModalBackupInfo } from "./components/ModalBackupInfo";
// import BackupMap from "../../assets/images/BackupMap.png";
import { getEntityBackups } from "../../repositories/portal_api/Backup";
import { GlobalContext } from "../../contexts/GlobalContext";
import USAFlag from "../../components/icons/USAFlag";
import BrazilFlag from "../../components/icons/BrazilFlag";
import BackupCheckIcon from "../../components/icons/BackupCheckIcon";
import { getEntitySystems } from "../../repositories/portal_api/Systems";

const useStyles = makeStyles((theme) => {
  return {
    root: {
      "& .super-app-theme--usa-row": {
        backgroundColor: "#F3F3F3",
      },
    },
  };
});

export const BackupPage = () => {
  const { companyUser, setBreadcrumbs } = useContext(GlobalContext);
  // const [openBackupWorksModal, setOpenBackupWorksModal] = useState(false);
  // const [openBackupInfoModal, setOpenBackupInfoModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [backupList, setBackupList] = useState([]);
  // const [hasBackupPlus, setHasBackupPlus] = useState(false);

  const classes = useStyles();

  // const handleOpenopenBackupWorksModal = () => {
  //   setOpenBackupWorksModal(true);
  // };

  // const handleCloseopenBackupWorksModal = () => {
  //   setOpenBackupWorksModal(false);
  // };

  // const handleOpenopenBackupInfoModal = () => {
  //   setOpenBackupInfoModal(true);
  // };

  // const handleCloseopenBackupInfoModal = () => {
  //   setOpenBackupInfoModal(false);
  // };

  let columns = [
    {
      field: "date",
      headerName: "Data",
      flex: 0.6,
      sortable: false,
      renderCell: (params) => formatDate(params.row?.date),
    },
    {
      field: "size",
      headerName: "Tamanho",
      flex: 1,
      sortable: false,
      renderCell: (params) => formatBytes(params.row.size) + " Bytes",
    },
    {
      field: "country",
      headerName: "",
      renderHeader: (params) => <></>,
      flex: 0.5,
      sortable: false,
      renderCell: (params) => {
        return (
          <Grid container justifyContent="center">
            {params.row?.country === "USA" ? <USAFlag /> : <BrazilFlag />}
            {/* <CheckIcon /> */}
          </Grid>
        );
      },
    },
    {
      field: "server1",
      headerName: "Server 1",
      flex: 0.5,
      sortable: false,
      headerAlign: "center",
      align: "center",
      // renderHeader: () => <center>Server 1</center>,
      renderCell: (params) => {
        return (
          <Grid container justifyContent="center">
            <BackupCheckIcon />
          </Grid>
        );
      },
    },
    {
      field: "server2",
      headerName: "Server 2",
      flex: 0.5,
      sortable: false,
      headerAlign: "center",

      renderCell: (params) => {
        return (
          <Grid container justifyContent="center">
            <BackupCheckIcon />
          </Grid>
        );
      },
    },
    {
      field: "server3",
      headerName: "Server 3",
      flex: 0.5,
      sortable: false,
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Grid container justifyContent="center">
            <BackupCheckIcon />
          </Grid>
        );
      },
    },
  ];

  const formatDate = (number) => {
    try {
      if (number.length < 8) {
        const year = number.substring(0, 4);
        const month = number.substring(4, 6);

        return `${month}/${year}`;
      }

      const numStr = number.toString();
      const year = numStr.substring(0, 4);
      const month = numStr.substring(4, 6);
      const day = numStr.substring(6, 8);
      return `${day}/${month}/${year}`;
    } catch (e) {
      return number;
    }
  };

  const formatBytes = (bytes) => {
    const number = parseFloat(bytes);
    const formattedNumber = number.toLocaleString("pt-BR", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
    return formattedNumber;
  };

  const customLocaleText = {
    noRowsLabel: "Sem Registros",
  };

  useEffect(() => {
    setBreadcrumbs([]);

    async function fetchData() {
      setLoading(true);
      await getUserBackupList();
      await getHasBackupPlus();
      setLoading(false);
    }
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUserBackupList = async () => {
    const response = await getEntityBackups(companyUser.document);
    if (response?.success) {
      setBackupList(response.data);
    } else {
      setBackupList([]);
    }

    setLoading(false);
  };

  const getHasBackupPlus = async () => {
    const entitySystems = await getEntitySystems();
    if (entitySystems?.success) {
      entitySystems.data.forEach((system) => {
        if (system.system_id === 17 && system.system_version_id === 56) {
          if (
            !system.block_date &&
            !system.cancellation_date &&
            !system.expiration_date
          ) {
            console.log("");
            // setHasBackupPlus(true);
          }
        }
      });
    }
    // const response = await getHasBackupPlus(companyUser.document);
    // if (response?.success) {
    //   setHasBackupPlus(response.data);
    // } else {
    //   setHasBackupPlus(false);
    // }
  };

  return (
    <div style={{ position: "relative", width: "100%", height: "100%" }}>
      <GlobalSubheader title={"Backup Seguro"} titleLocation={"/backup"} />
      <DefaultPageContainer>
        <div
          style={{
            background: "#fff",
            border: "1px solid #E0E0E0",
            borderRadius: 15,
            padding: 16,
          }}
        >
          <Grid container xs={12}>
            <MainText>
              Proteção de seus dados com a <b>máxima segurança</b> e{" "}
              <span style={{ color: "#0036B3" }}>
                {" "}
                <b>alcance global!</b>
              </span>
            </MainText>
          </Grid>

          <Grid container xs={12} style={{ marginTop: "16px" }} spacing={2}>
            <Grid item xs={7}>
              {loading ? (
                <div
                  style={{
                    minHeight: "80%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress />
                </div>
              ) : (
                <BackupDatagrid
                  disableColumnMenu
                  autoHeight
                  rows={backupList}
                  columns={columns}
                  initialState={{
                    pagination: {
                      paginationModel: { pageSize: 5 },
                    },
                  }}
                  disableSelectionOnClick
                  localeText={customLocaleText}
                  hideFooter
                  style={{ minHeight: "80%", border: "0px !important" }}
                  getRowClassName={(params) =>
                    params.row?.country === "USA"
                      ? "super-app-theme--usa-row"
                      : ""
                  }
                  className={classes.root}
                />
              )}

              {/* Por enquanto retirar os banners de propaganda */}

              {/* <Grid
                item
                spacing={2}
                style={{
                  marginTop: "16px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
                xs={12}
              >
                <Grid item style={{ marginRight: "5px" }}>
                  <img
                    alt="Como Funciona o Backup"
                    src={HowFunctionBackup}
                    onClick={handleOpenopenBackupWorksModal}
                    style={{
                      cursor: "pointer",
                      width: "100%",
                      height: "auto",
                      maxWidth: "400px",
                    }}
                  />
                </Grid>
                <Grid item>
                  <img
                    alt="Conheça o Backup Seguro PLUS"
                    src={KnowBackup}
                    onClick={handleOpenopenBackupInfoModal}
                    style={{
                      cursor: "pointer",
                      width: "100%",
                      height: "auto",
                      maxWidth: "400px",
                    }}
                  />
                </Grid>
              </Grid> */}
            </Grid>
            <Grid item xs={5}>
              <Grid
                item
                xs={12}
                justifyContent="center"
                style={{ position: "relative" }}
              >
                <video
                  width="100%"
                  autoPlay
                  muted
                  loop
                  style={{ borderRadius: 15 }}
                >
                  <source src="https://qyon-portal-videos.s3.sa-east-1.amazonaws.com/BACKUP/BACKUP_SEGURO_VIDEO_SISTEMA_BAIXA_V2.mp4" />
                  Your browser does not support the video tag.
                </video>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            style={{ color: "#7e899e", fontSize: 12, fontWeight: 400 }}
          >
            * Este produto já está incluso em seu plano.
          </Grid>
        </div>
      </DefaultPageContainer>

      {/* <ModalBackupWorks
        open={openBackupWorksModal}
        handleClose={handleCloseopenBackupWorksModal}
        handleOpenInfoModal={handleOpenopenBackupInfoModal}
      />

      <ModalBackupInfo
        open={openBackupInfoModal}
        handleClose={handleCloseopenBackupInfoModal}
      /> */}
    </div>
  );
};
