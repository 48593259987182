import { useContext, useEffect } from "react";
import { useHistory } from "react-router";

import {
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { GridStyled, Margin } from "../../styles/GlobalStyle";
import useLoginController from "./controllers/LoginController";

import { LoginContext } from "./contexts/LoginContext";
import { Search } from "@material-ui/icons";
import { useState } from "react";
import {
  LoginButton,
  LoginHeader,
  LoginLabel,
  LoginTextField,
  SelectCompanyLogin,
} from "./styles/Login";

import { GlobalContext } from "../../contexts/GlobalContext";
import LoginLayout from "./LoginLayout";

export default function CompanySelection({ location }) {
  const { handleRadioChange, handleSelectEntity, isGfTrialFirstAccess } =
    useLoginController();

  const { loading, companyChecked, setCompanyChecked, listCompanies } =
    useContext(LoginContext);

  const { userLogged } = useContext(GlobalContext);

  const { push } = useHistory();

  const [filteredRows, setFilteredRows] = useState(listCompanies);

  useEffect(() => {
    if (filteredRows.length === 0) {
      push("/login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (location?.state?.isGfTrialFirstAccess) {
      isGfTrialFirstAccess.current = location.state.isGfTrialFirstAccess;
    }
  }, [location.state.isGfTrialFirstAccess, isGfTrialFirstAccess]);

  useEffect(() => {
    const companyInitial = listCompanies.find(
      (company) => company?.accountant_id || company?.is_accountant
    );
    setCompanyChecked((companyInitial?.id || "").toString());
  }, [listCompanies, setCompanyChecked]);

  function handleFilterChange(event) {
    let value = event.target.value.toLowerCase();
    const filteredData = listCompanies.filter((row) => {
      if (row.trading_name.toLowerCase().includes(value.toLowerCase())) {
        return true;
      }

      if (
        value.replace(/\D/g, "") !== "" &&
        row.document.replace(/\D/g, "").includes(value.replace(/\D/g, ""))
      ) {
        return true;
      }

      return false;
    });

    setFilteredRows(filteredData);
  }

  function formatCNPJorCPF(document) {
    return document.length === 14
      ? document.replace(
          /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
          "$1.$2.$3/$4-$5"
        )
      : document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  }

  return (
    <LoginLayout>
      <SelectCompanyLogin>
        <GridStyled item spacing={2}>
          <Grid
            item
            xs={6}
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "flex-start",
            }}
          >
            <LoginHeader>Selecione a empresa</LoginHeader>
          </Grid>
          <FormControl
            fullWidth
            variant="outlined"
            size="small"
            style={{
              marginRight: "20px",
              marginBottom: "15px",
            }}
          >
            <GridStyled justifyContent="space-between" container>
              <LoginLabel>Pesquisar</LoginLabel>
              <LoginTextField
                placeholder="Pesquisar"
                fullWidth
                size="medium"
                variant="outlined"
                autoComplete="email"
                autoFocus
                onChange={handleFilterChange}
                endAdornment={
                  <InputAdornment position="end">
                    <Search style={{ color: "grey" }} />
                  </InputAdornment>
                }
              />
            </GridStyled>
          </FormControl>

          <FormControl
            component="fieldset"
            style={{
              minHeight: "50vh",
              maxHeight: "50vh",
              overflow: "auto",
              width: "100%",
            }}
          >
            <RadioGroup value={companyChecked} onChange={handleRadioChange}>
              {filteredRows
                .sort((a, b) => a.trading_name.localeCompare(b.trading_name))
                .map((company) => (
                  <FormControlLabel
                    key={company.id}
                    value={company.id}
                    control={
                      <Radio
                        disabled={loading}
                        checked={parseInt(companyChecked) === company.id}
                        color="primary"
                      />
                    }
                    label={
                      <div>
                        <span
                          style={{
                            fontFamily: "Roboto",
                            fontSize: "14px",
                            marginTop: "20px",
                          }}
                        >
                          {company.trading_name}
                        </span>
                        <br />
                        <span
                          style={{
                            fontSize: "12px",
                            color: "grey",
                            fontFamily: "Roboto",
                          }}
                        >
                          {formatCNPJorCPF(company.document)}
                        </span>
                      </div>
                    }
                  />
                ))}
              {filteredRows.length === 0 && (
                <div
                  style={{
                    marginTop: "50px",
                    marginBottom: "50px",
                    marginLeft: "170px",
                  }}
                >
                  Nenhuma empresa encontrada
                </div>
              )}
            </RadioGroup>
            <Margin top="10" />
          </FormControl>

          <LoginButton
            style={{ marginTop: 10 }}
            type="submit"
            full
            variant="contained"
            color="primary"
            onClick={() => {
              handleSelectEntity({
                list: listCompanies,
                companyIdParam: companyChecked,
                redirectedFromLogin: true,
                userIdParam: userLogged.userId,
                isGfTrialFirstAccess: isGfTrialFirstAccess?.current,
              });
            }}
          >
            Confirmar
          </LoginButton>
        </GridStyled>
      </SelectCompanyLogin>
    </LoginLayout>
  );
}
