import { Switch } from "react-router-dom";
import MiddlewareRoute from "../Middleware";
import { GlobalVariables } from "../../GlobalVariables";
import { UserAccess } from "../../pages/userAccess/UserAccess";
import { ManagementContextProvider } from "../../pages/accessProfile/contexts/ManagementContext";
import { management } from "../../pages/accessProfile/management";
import { CustumerManagementContextProvider } from "../../pages/customerManagements/contexts/CustomerManagementContext";

export default function AccessProfile() {
  return (
    <Switch>
      <CustumerManagementContextProvider>
        <ManagementContextProvider>
          <MiddlewareRoute
            routePermissionId={GlobalVariables.PERMISSIONS.SETTINGS_USER}
            path="/useraccess/management"
            sidebar
            component={UserAccess}
            noPaddingSidebar
          />
          <MiddlewareRoute
            routePermissionId={GlobalVariables.PERMISSIONS.SETTINGS_USER}
            path="/useraccess/management2"
            sidebar
            component={management}
            noPaddingSidebar
          />
        </ManagementContextProvider>
      </CustumerManagementContextProvider>
    </Switch>
  );
}
