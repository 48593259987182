import React, { useContext, useEffect } from "react";
import {
  DuplicateDeleteIcons,
  FieldPlaceholdersDiv,
  FieldPlaceholdersText,
  NameTxtFieldTemplate,
  TemplateHeaderDiv,
  TemplateNameText,
  TooltipText,
} from "../styles/ModalEmailTemplates";
import { CircularProgress, Grid, TextField, Tooltip } from "@material-ui/core";
import EmailEditor from "react-email-editor";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import {
  getEmailPlaceholdersByTypeAndSystem,
  getTemplateJson,
} from "../../../repositories/EmailTemplate";
import { withStyles } from "@material-ui/core/styles";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import { v4 as uuidv4 } from "uuid";
import { notify } from "../../../utils/notify";
import { GlobalContext } from "../../../contexts/GlobalContext";
import { ModalDeleteTemplate } from "./ModalDeleteTemplate";
import { EmailTemplateContext } from "../contexts/EmailTemplateContext";

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
    maxWidth: 800,
  },
}))(Tooltip);
export const ModalEditTemplate = ({
  clickedTemplate,
  tabTemplate,
  totalFilteredTemplates,
  selectedService,
  type,
  ...props
}) => {
  const { companyUser } = React.useContext(GlobalContext);

  const [isLoaded, setIsLoaded] = React.useState(false);
  const [template, setTemplate] = React.useState("");
  const [templateCopy, setTemplateCopy] = React.useState("");
  const [listPlaceholders, setListPlaceholders] = React.useState([]);

  const [modalDeleteTemplate, setModalDeleteTemplate] = React.useState({
    open: false,
  });

  const {
    emailEditorRef,
    setTemplateId,
    templateName,
    setTemplateName,
    editorType,
    setEditorType,
  } = useContext(EmailTemplateContext);

  useEffect(() => {}, [selectedService]);

  const handleOpenModalDeleteTemplate = () => {
    setModalDeleteTemplate({
      open: true,
    });
  };

  const handleCloseModalDeleteTemplate = () => {
    setModalDeleteTemplate({
      open: false,
    });
  };

  useEffect(() => {
    const typeNotification =
      selectedService.type_notification === 0 ? "instant" : "scheduled";
    getEmailPlaceholdersByTypeAndSystem(type, typeNotification)
      .then((response) => {
        if (response.data) {
          setListPlaceholders(response.data);
        } else {
          setListPlaceholders([]);
        }
      })
      .catch((e) => {
        console.log(e);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (editorType === "Edit") {
      setTemplateName(clickedTemplate.name);
      setTemplateId(clickedTemplate.id);
    } else if (editorType === "Duplicate") {
      setTemplateName(`${clickedTemplate.name} Cópia`);
      setTemplateId(uuidv4());
    } else {
      setTemplateId(uuidv4());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorType]);

  useEffect(() => {
    if (editorType === "Edit") {
      getTemplateJsonEdit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clickedTemplate, selectedService]);

  const getTemplateJsonEdit = async () => {
    const response = await getTemplateJson(
      companyUser.document,
      type,
      clickedTemplate.id
    );

    if (response?.success) {
      setTemplate(response?.data);
    } else {
      notify("Ocorreu um erro ao carregar o template!", true, "error");
    }
  };

  const handleChange = (event) => {
    setTemplateName(event.target.value);
  };

  const onLoad = async () => {
    setTimeout(() => {
      if (isLoaded === false) {
        emailEditorRef.current.editor.loadDesign(template);
        setIsLoaded(true);
      }
    }, 0);
  };

  const onLoadCopy = async () => {
    setTimeout(() => {
      if (isLoaded === false) {
        emailEditorRef.current.editor.loadDesign(templateCopy);
        setIsLoaded(true);
      }
    }, 0);
  };

  return (
    <div style={{ maxHeight: "70vh" }}>
      <TemplateHeaderDiv>
        <NameTxtFieldTemplate>
          {editorType === "Duplicate" ? (
            <TemplateNameText>Nome da Cópia do Template</TemplateNameText>
          ) : (
            <TemplateNameText>Nome do Template</TemplateNameText>
          )}
          <TextField
            id="outlined-basic"
            fullWidth
            placeholder="Escreva um Nome"
            variant="outlined"
            size="small"
            value={templateName}
            onChange={handleChange}
            style={{ borderRadius: "8px" }}
          />
        </NameTxtFieldTemplate>
        {editorType === "Edit" ? (
          <>
            <Tooltip title="Duplicar Template">
              <DuplicateDeleteIcons
                onClick={() => {
                  if (totalFilteredTemplates.current.length < 10) {
                    setEditorType("Duplicate");
                    setTemplateCopy(template);
                  } else {
                    notify(
                      "Limite Máximo de Templates Para a Categoria Atingido!",
                      true,
                      "warning"
                    );
                  }
                }}
              >
                <FileCopyOutlinedIcon style={{ color: "#616161" }} />
              </DuplicateDeleteIcons>
            </Tooltip>
            <Tooltip title="Deletar Template">
              <DuplicateDeleteIcons
                onClick={() => {
                  handleOpenModalDeleteTemplate();
                }}
              >
                <DeleteOutlineOutlinedIcon style={{ color: "#616161" }} />
              </DuplicateDeleteIcons>
            </Tooltip>
          </>
        ) : null}
      </TemplateHeaderDiv>

      <Grid container xs={12}>
        <LightTooltip
          placement="bottom-start"
          title={
            <React.Fragment>
              <TooltipText>
                Os campos entre colchetes [ ] neste template serão
                automaticamente preenchidos com dados específicos pelo sistema.
              </TooltipText>

              {listPlaceholders.map((placeholder) => (
                <TooltipText key={placeholder.id}>
                  <b>[{placeholder.placeholder}]</b> - {placeholder.description}
                </TooltipText>
              ))}
            </React.Fragment>
          }
        >
          <FieldPlaceholdersDiv>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <HelpOutlineIcon />
              <FieldPlaceholdersText>
                Campos de Preenchimento Automático
              </FieldPlaceholdersText>
            </div>
          </FieldPlaceholdersDiv>
        </LightTooltip>
      </Grid>

      <div
        id="EMAIL_EDITOR_WRAPPER"
        style={{ display: "flex", justifyContent: "center" }}
      >
        {editorType === "New" ? (
          <EmailEditor ref={emailEditorRef} />
        ) : template && editorType === "Edit" ? (
          <EmailEditor ref={emailEditorRef} onReady={onLoad} />
        ) : editorType === "Duplicate" ? (
          <EmailEditor ref={emailEditorRef} onReady={onLoadCopy} />
        ) : (
          <CircularProgress />
        )}
      </div>

      {/* Buttons */}

      <ModalDeleteTemplate
        open={modalDeleteTemplate.open}
        handleClose={handleCloseModalDeleteTemplate}
        templateId={clickedTemplate.id}
        nameTemplate={clickedTemplate.name}
        document={companyUser.document}
        type={type}
      />
    </div>
  );
};
