import { useContext, useEffect, useRef, useState } from "react";
import { DefaultPageContainer } from "../../components/layoutContainer/styles/LayoutContainer";
import { CommsConfig } from "./CommsConfig";
import { FoldersIds } from "./utils/FolderTypes";
import { getFolders } from "../../repositories/GuideReportList";
import { CircularProgress } from "@material-ui/core";
import { GlobalContext } from "../../contexts/GlobalContext";
import { getAccountantClients } from "../../repositories/portal_api/AccountantClient";
import { GlobalSubheader } from "../../components/subHeader/GlobalSubheader";
import { EmailTemplateContextProvider } from "../../components/modal/contexts/EmailTemplateContext";
import DocumentFolderTabsPage from "./components/DocumentFolderTabsPage";
import { getDocumentList } from "./controller/GuideReportController";
import { DocumentGuideContext } from "./contexts/DocumentGuideContext";
import DocumentGuideReportBody from "./components/DocumentGuideReportBody";
import ModalPreviewPdf from "./components/ModalPreviewPdf";
import ModalDocumentHistory from "./components/ModalDocumentHistory";
import { notify } from "../../utils/notify";

var currentDate;
var oneMonthBeforeDate;

const setCurrentDateAndOneMonthBefore = () => {
  var actualDate = new Date();

  var actualYear = actualDate.getFullYear();
  var actualMonth = ("0" + (actualDate.getMonth() + 1)).slice(-2);
  var actualDay = ("0" + actualDate.getDate()).slice(-2);
  currentDate = actualYear + "-" + actualMonth + "-" + actualDay;

  var oneMonthBefore = new Date(actualDate);
  oneMonthBefore.setMonth(oneMonthBefore.getMonth() - 1);
  var yearBefore = oneMonthBefore.getFullYear();
  var monthBefore = ("0" + (oneMonthBefore.getMonth() + 1)).slice(-2);
  var dayBefore = ("0" + oneMonthBefore.getDate()).slice(-2);
  oneMonthBeforeDate = yearBefore + "-" + monthBefore + "-" + dayBefore;
};

setCurrentDateAndOneMonthBefore();

export default function GuideReportList(props) {
  const folders = useRef([""]);
  const [isLoading, setIsLoading] = useState(false);
  const { companyUser, setBreadcrumbs } = useContext(GlobalContext);
  const firstLoad = useRef(true);

  const isInClientView = sessionStorage.getItem("isInClientView") === "true";
  const clientId = JSON.parse(sessionStorage.getItem("client"))?.company;

  const {
    setClientList,
    setDocumentList,
    setTotalDocs,
    searchField,
    page,
    pageSize,

    selectedStartDate,
    selectedEndDate,
    setSelectedStartDate,
    setSelectedEndDate,
    setPdf,
    searchClientList,
    setIsLoadingDocuments,
    chipValue,
    tabValue,
    setChipValue,
    setTabValue,
    openModalPreviewDoc,
    setOpenModalPreviewDoc,
    openModalDocumentHistory,
    setOpenModalDocumentHistory,
    setDocumentHistoryList,
    currentDocumentInboxStatusId,
  } = useContext(DocumentGuideContext);

  useEffect(() => {
    initialSetup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialSetup = async () => {
    setSelectedStartDate(oneMonthBeforeDate);
    setSelectedEndDate(currentDate);
    setBreadcrumbs([]);
    setIsLoading(true);
    await getFoldersList();
    if (!tabValue) {
      setTabValue(FoldersIds.Guias);
    }
    setInitialChip();
    firstLoad.current = false;
    const response = await getAccountantClients();
    setClientList(response.data);
    setIsLoading(false);
  };

  const handleTabChange = async (folderId) => {
    setTabValue(folderId);

    if (isFolderIdOtherOrCnd(folderId)) {
      setIsLoadingDocuments(true);
      let data = await getDocumentList({
        startDate: oneMonthBeforeDate,
        endDate: currentDate,
        folderId: folderId,
        search: searchField,
        searchId: searchClientList?.id
          ? searchClientList?.id
          : isInClientView
          ? clientId
          : null,
        page: page,
        pageSize: pageSize,
        inboxStatusId: currentDocumentInboxStatusId,
      });
      setIsLoadingDocuments(false);

      setDocumentList(data.documentList);
      setTotalDocs(data.totalDocs);
    }
  };

  const handleChipChange = async (folderId) => {
    setChipValue(folderId);
    setIsLoadingDocuments(true);
    let data = await getDocumentList({
      startDate: selectedStartDate,
      endDate: selectedEndDate,
      folderId: folderId,
      search: searchField,
      searchId: searchClientList?.id
        ? searchClientList?.id
        : isInClientView
        ? clientId
        : null,
      page: page,
      pageSize: pageSize,
      inboxStatusId: currentDocumentInboxStatusId,
    });
    setIsLoadingDocuments(false);

    setDocumentList(data.documentList);
    setTotalDocs(data.totalDocs);
  };
  const isFolderIdOtherOrCnd = (folderId) => {
    return folderId === FoldersIds.CND || folderId === FoldersIds.Outros;
  };

  useEffect(() => {
    setInitialChip();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabValue]);

  useEffect(() => {
    if (!firstLoad.current) {
      executeSearch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchField]);

  const getFoldersList = async () => {
    const response = await getFolders();
    if (response?.success) {
      if (companyUser.isAccountant) {
        folders.current = [
          ...response?.data,

          {
            date_entered: null,
            folder_id: "COMMS_CONFIG",
            key: "comms_config",
            name: "Automação de Notificações",
            parent_id: null,
          },
        ];
      } else {
        folders.current = [...response?.data];
      }
    } else {
      notify("Ocorreu um erro ao buscar as pastas!");
    }
  };

  const setInitialChip = () => {
    let count = 0;
    folders.current.forEach(async (item) => {
      if (item.parent_id === tabValue && count < 1) {
        setChipValue(item.folder_id);
        count++;
        if (firstLoad.current) {
          setIsLoadingDocuments(true);
          let data = await getDocumentList({
            startDate: selectedStartDate || oneMonthBeforeDate,
            endDate: selectedEndDate || currentDate,
            folderId: "00000000-0000-0000-0001-000000000000",
            search: searchField,
            searchId: searchClientList?.id
              ? searchClientList?.id
              : isInClientView
              ? clientId
              : null,
            page: page,
            pageSize: pageSize,
            inboxStatusId: currentDocumentInboxStatusId,
          });
          setIsLoadingDocuments(false);
          setDocumentList(data.documentList);
          setTotalDocs(data.totalDocs);
        } else {
          setIsLoadingDocuments(true);
          let data = await getDocumentList({
            startDate: selectedStartDate,
            endDate: selectedEndDate,
            folderId: item.folder_id,
            search: searchField,
            searchId: searchClientList?.id
              ? searchClientList?.id
              : isInClientView
              ? clientId
              : null,
            page: page,
            pageSize: pageSize,
            inboxStatusId: currentDocumentInboxStatusId,
          });
          setIsLoadingDocuments(false);
          setDocumentList(data.documentList);
          setTotalDocs(data.totalDocs);
        }
      }
    });
  };

  const executeSearch = async () => {
    setIsLoadingDocuments(true);
    let data = await getDocumentList({
      pageSize: pageSize,
      startDate: selectedStartDate,
      endDate: selectedEndDate,
      folderId: chipValue,
      page: page,
      search: searchField,
      searchId: searchClientList?.id
        ? searchClientList?.id
        : isInClientView
        ? clientId
        : null,
      inboxStatusId: currentDocumentInboxStatusId,
    });
    setIsLoadingDocuments(false);

    setDocumentList(data.documentList);

    setTotalDocs(data.totalDocs);
  };

  return (
    <div style={{ position: "relative", width: "100%", height: "100%" }}>
      <GlobalSubheader
        title={"Guias, documentos e relatórios"}
        titleLocation={"/guide"}
      />
      <DefaultPageContainer>
        <div
          style={{
            background: "#fff",
            border: "1px solid #E0E0E0",
            borderRadius: 15,
            padding: 5,
          }}
        >
          {isLoading ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress />
            </div>
          ) : (
            <>
              <DocumentFolderTabsPage
                folders={folders}
                handleChipChange={handleChipChange}
                handleTabChange={handleTabChange}
              />

              {tabValue !== "COMMS_CONFIG" ? (
                <>
                  <DocumentGuideReportBody />
                </>
              ) : (
                <EmailTemplateContextProvider>
                  <CommsConfig />
                </EmailTemplateContextProvider>
              )}

              <ModalPreviewPdf
                handleClose={() => {
                  setOpenModalPreviewDoc(false);
                  setPdf("");
                }}
                open={openModalPreviewDoc}
              />

              <ModalDocumentHistory
                handleClose={() => {
                  setOpenModalDocumentHistory(false);
                  setDocumentHistoryList([]);
                }}
                open={openModalDocumentHistory}
              />
            </>
          )}
        </div>
      </DefaultPageContainer>
    </div>
  );
}
