import { v4 as uuidv4 } from "uuid";

import { isCompany } from "../../../../utils/panelType";
import { getEnvironment } from "../../../../utils/environment";
import listStates from "../../../../utils/states.json";
import {
  registerAccountantClient,
  updateAccountantClientData,
} from "../../../../repositories/portal_api/AccountantClient";
import listCities from "../../../../utils/cities.json";
import { getAddressDataInBigDataByCEP } from "../../../../repositories/portal_api/BigData";

export const createCompanyAutomaticallyFilled = async (
  body,
  token,
  userDocument,
  listEntitiesExistent = [],
  certificateDocument = null,
  isProcuration = null
) => {
  const forbiddenCnpjs = ["38469354000152"];
  let addressesList = [];

  let hasForbiddenCnpj = false;

  const entityExists = listEntitiesExistent?.find(
    (entity) => entity.document === body.clientCnpj
  );

  forbiddenCnpjs.forEach((cnpj) => {
    if (cnpj === body.clientCnpj) {
      hasForbiddenCnpj = true;
    }
  });

  if (hasForbiddenCnpj) {
    return null;
  }

  if (body.addresses[0]) {
    body.addresses[0].cep = body.addresses[0].cep
      ? body.addresses[0].cep.replace(/\D/g, "")
      : "";

    if (body.addresses[0].cep) {
      const addressData = await getAddressDataInBigDataByCEP(
        body.addresses[0].cep
      );

      if (addressData?.success) {
        body.addresses[0].ibgeCode = addressData?.data?.ibgeCode || "";
      }
    }
  }

  if (body.companyName === "") {
    body.companyName = body.clientCnpj;
    body.tradingName = body.clientCnpj;
  }

  body.phone = body.phone ? body.phone.replace(/\D/g, "") : "";

  let accountantCnpj =
    localStorage.getItem("isAccountantPanel") === "true"
      ? localStorage.getItem("documentPanel")
      : localStorage.getItem("accountantCnpjPanel");

  if (isCompany() === true) {
    accountantCnpj = localStorage.getItem("documentPanel");
  }

  if (body.clientCnpj === accountantCnpj) {
    body.isAccountant = true;
  } else {
    body.isAccountant = false;
  }

  const formatAddressess = () => {
    for (let address of body.addresses) {
      const foundedStateId = listStates.find(
        (state) => state.uf === address.state
      )?.id;

      const foundedCityId = listCities.find(
        (city) => city.state_id === foundedStateId
      )?.id;

      let addressObject = {
        postal_code: address.cep,
        address_complement: address.complement || "",
        address_number: address.number,
        city_id: address.ibgeCode || foundedCityId,
        country_id: 1058,
        district: address.neighborhood,
        main_address: true,
        state_id: foundedStateId,
        street: address.street,
      };

      addressesList.push(addressObject);
    }

    return addressesList;
  };

  const entityObject = {
    master_email: body.email,
    company_name: body.companyName,
    trading_name: body.tradingName,
    document: body.clientCnpj,
    email: body.email,
    phone: body.phone || "",
    entity_type:
      body.clientCnpj.replace(/[^0-9]+/g, "").length <= 11
        ? "natural"
        : "legal",
    tax_regime_id: body?.tax_regime?.id || "",
    addresses: formatAddressess(),
    subdomain: "",
    im: "",
    ie: "",
    ccm: "",
    responsable_person_document: "",
    users: [
      {
        first_name: "Usuário",
        last_name: "Principal",
        email: body.email,
        edit: true,
        permission_group_id: null,
      },
    ],
    certificate_document: certificateDocument,
    is_procuration: isProcuration,
  };

  try {
    if (entityExists === undefined) {
      const responseResgiterAccountantClient = await registerAccountantClient(
        entityObject,
        true
      );
      if (responseResgiterAccountantClient?.success) {
        return responseResgiterAccountantClient;
      } else {
        throw new Error("Error While inserting entity");
      }
    } else {
      const responseUpdateAccountantClient = await updateAccountantClientData(
        entityExists?.id,
        entityObject
      );
      return responseUpdateAccountantClient;
    }
  } catch (err) {
    return null;
  }
};

export const createCompanyV2 = async (
  body,
  cnpjProcuration,
  isProcuration,
  token,
  userDocument
) => {
  const forbiddenCnpjs = ["38469354000152"];

  let hasForbiddenCnpj = false;

  forbiddenCnpjs.forEach((cnpj) => {
    if (cnpj === body.clientCnpj) {
      hasForbiddenCnpj = true;
    }
  });

  if (hasForbiddenCnpj) {
    return null;
  }

  if (body.addresses[0]) {
    body.addresses[0].cep = body.addresses[0].cep
      ? body.addresses[0].cep.replace(/\D/g, "")
      : "";

    if (body.addresses[0].cep) {
      const addressData = await getAddressDataInBigDataByCEP(
        body.addresses[0].cep
      );

      if (addressData?.success) {
        body.addresses[0].ibgeCode = addressData?.data?.ibgeCode || "";
      }
    }
  }

  if (body.companyName === "") {
    body.companyName = body.clientCnpj;
    body.tradingName = body.clientCnpj;
  }

  body.phone = body.phone ? body.phone.replace(/\D/g, "") : "";

  let accountantCnpj =
    localStorage.getItem("isAccountantPanel") === "true"
      ? localStorage.getItem("documentPanel")
      : localStorage.getItem("accountantCnpjPanel");

  if (isCompany() === true) {
    accountantCnpj = localStorage.getItem("documentPanel");
  }

  if (body.clientCnpj === accountantCnpj) {
    body.isAccountant = true;
  } else {
    body.isAccountant = false;
  }

  let environment = getEnvironment();

  let createCompanyJson = {
    id: uuidv4(),
    version: "v1.0.1",
    from: "QYON_DASH",
    to: "ALL",
    timestamp: new Date().getTime(),
    payload: {
      ...body,
    },
    extraData: {
      uiMessage:
        "Solicitado criação de conta da empresa CNPJ: " + body.clientCnpj,
      mainCompanyCnpj: localStorage.getItem("mainEntityCnpjPanel") || "",
      accountantCnpj: accountantCnpj,
      sub: localStorage.getItem("subCognitoPanel"),
      createAccess: isCompany() === true ? false : true,
      env: environment,
      isProcuration: isProcuration || false,
      certificateCnpj: cnpjProcuration
        ? cnpjProcuration.replace(/[^0-9]/g, "")
        : "",
    },
    event: "CREATE_ACCOUNTANT_CLIENT",
    priority: "CRITICAL",
  };

  let myHeaders = new Headers();
  myHeaders.append("Authorization", "18309cd6-30ca-4647-b289-fb2ae3e1d2f6");
  myHeaders.append("Content-Type", "application/json");

  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(createCompanyJson),
    redirect: "follow",
  };

  let response = await new Promise((resolve, reject) => {
    fetch("https://hub.qyon.info/v1/hub/message", requestOptions)
      .then((response) => resolve(response))
      .catch((error) => resolve(null));
  });

  return response;
};
