import { useContext } from "react";
import { useHistory } from "react-router";

import { Grid } from "@material-ui/core";

import { HomeContext } from "../contexts/HomeContext";

import WebsiteTypeSelector from "./WebsiteTypeSelector";

import {
  Content,
  Li,
  Nav,
  Section,
  HeaderButtons,
  LogoImage,
  MainTextArea,
  Slogan,
  SubSlogan,
  TextEdit,
} from "../styles/Home";
import useHomeController from "../controllers/HomeController";

const SectionHeader = ({ ...props }) => {
  const { push } = useHistory();

  // const [email, setEmail] = useState("");
  // const [password, setPassword] = useState("");
  // const [showPassword, setShowPassword] = useState(false);

  const { landTheme, title, subtitle, setTitle, setSubtitle, editEnable } =
    useContext(HomeContext);

  const { scrollTo, saveWebsiteChanges } = useHomeController();

  const handleBlur = () => {
    saveWebsiteChanges();
  };

  // const handleClickShowPassword = () => setShowPassword((show) => !show);
  // const handleMouseDownPassword = (event) => {
  //   event.preventDefault();
  // };

  return (
    <Section $backgroundImage={landTheme.images.header}>
      {editEnable && <WebsiteTypeSelector />}
      {/*<LoginContainer>
        <TextFieldLogin
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          placeholder="E-mail"
          variant="outlined"
        />
        <TextFieldLogin
          value={password}
          type={showPassword ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  <PasswordIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          placeholder="Senha"
          variant="outlined"
        />
        <ButtonLogin
          onClick={async () => {
            if (!email) {
              notify("Informe seu e-mail");
              return;
            }

            if (!password) {
              notify("Informe sua senha");
              return;
            }

            const body = {
              email: email,
              password: password,
            };

            localStorage.setItem("LoginWebsite", JSON.stringify(body));
            push("/login");
          }}
        >
          Login
        </ButtonLogin>
      </LoginContainer>
        */}
      <Content maxWidth="lg">
        <Grid container spacing={4}>
          <Grid
            item
            xs={12}
            md={4}
            container
            justifyContent="center"
            alignItems="center"
          >
            <LogoImage src={sessionStorage.getItem("logoLogin")}></LogoImage>
          </Grid>
          <HeaderButtons item container xs>
            <Nav>
              <ul>
                <Li
                  $hoverBackgroundColor={landTheme.color_pallete.secondary}
                  $color={landTheme.color_pallete.secondary}
                  onClick={() => scrollTo("section-about")}
                >
                  Sobre nós
                </Li>
                <Li
                  $hoverBackgroundColor={landTheme.color_pallete.secondary}
                  $color={landTheme.color_pallete.secondary}
                  onClick={() => scrollTo("section-services")}
                >
                  Serviços
                </Li>
                <Li
                  $hoverBackgroundColor={landTheme.color_pallete.secondary}
                  $color={landTheme.color_pallete.secondary}
                  onClick={() => scrollTo("section-footer")}
                >
                  Contato
                </Li>
                <Li
                  $backgroundColor={landTheme.color_pallete.secondary}
                  $color={landTheme.primary}
                  $hoverBackgroundColor={landTheme.color_pallete.tertiary}
                  $hoverColor={landTheme.color_pallete.secondary}
                  $border="none"
                  onClick={() =>
                    push(editEnable ? "/dashboard/view" : "/login")
                  }
                >
                  {editEnable ? "Retornar à sua Visão" : "Área do Cliente"}
                </Li>
              </ul>
            </Nav>
          </HeaderButtons>
        </Grid>
        {!!(title || subtitle || editEnable) && (
          <MainTextArea>
            {!!(title || editEnable) && (
              <Slogan
                $editable={editEnable}
                $color={landTheme.color_pallete.white}
              >
                {editEnable ? (
                  <TextEdit
                    type="text"
                    value={title}
                    $color={landTheme.color_pallete.white}
                    $fontSize={3.475}
                    onChange={(e) => {
                      setTitle(e.target.value);
                    }}
                    onBlur={handleBlur}
                  />
                ) : (
                  <h1>{title}</h1>
                )}
              </Slogan>
            )}
            {!!(subtitle || editEnable) && (
              <SubSlogan
                $editable={editEnable}
                $color={landTheme.color_pallete.secondary}
              >
                {editEnable ? (
                  <TextEdit
                    type="text"
                    value={subtitle}
                    $color={landTheme.color_pallete.secondary}
                    $fontSize={1.575}
                    onChange={(e) => {
                      setSubtitle(e.target.value);
                    }}
                    onBlur={handleBlur}
                  />
                ) : (
                  <h2>{subtitle}</h2>
                )}
              </SubSlogan>
            )}
          </MainTextArea>
        )}
      </Content>
    </Section>
  );
};

export default SectionHeader;
