import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { GlobalContext } from "../../../contexts/GlobalContext";
import { DataGrid, GridPagination } from "@material-ui/data-grid";
import {
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  ListItemSecondaryAction,
  makeStyles,
  MenuItem,
  TextField,
} from "@material-ui/core";
import { LightTooltip } from "../../../styles/GlobalStyle";
import { postInvite } from "../../../repositories/panel/v1/integrations/EmailRepository";
import { notify } from "../../../utils/notify";
import SendIcon from "../../../components/icons/SendIcon";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/delete.svg";
import { ReactComponent as EditIcon } from "../../../assets/icons/edit.svg";
import { ReactComponent as CopyIcon } from "../../../assets/icons/copy.svg";
import {
  getDataClientView,
  postAccountantClientUsers,
} from "../../../repositories/portal_api/AccountantClient";
import { DialogConfirm } from "../../customerManagements/children/DialogConfirm";
import { Add, CallMade } from "@material-ui/icons";
import { DialogAddUser } from "../../customerManagements/children/DialogAddUser";
import {
  SearchLabel,
  SelectFilterStyled,
  SendToClientPortal,
  SendToClientPortalText,
} from "../../customerManagements/styles/CustomerManagementStyle";
import { SearchIcon } from "../../../components/icons/SearchIcon";
import { AddBlueButton, CustomTooltip } from "../styles/UserAccess";
import { ModalAddPermissionGroup } from "../components/ModalAddPermissionGroup";
import { getUsersByEntityId } from "../../../repositories/portal_api/Users";
import { filter } from "../../../utils/filter";
import { orderBy } from "../../../utils/orderBy";
import {
  deletePermissionGroup,
  getPermissionGroup,
  getPermissionTree,
  updateOrLinkUserPermissionGroup,
} from "../../../repositories/panel/v1/EntityRepository";
import { ManagementContext } from "../../accessProfile/contexts/ManagementContext";
import { ModalDeletePermissionGroup } from "../components/ModalDeletePermissionGroup";
import { usePortalAsClient } from "../../../utils/viewPortalAsClientHook";
import { CustumerManagementContext } from "../../customerManagements/contexts/CustomerManagementContext";
import Loading from "../../../components/basics/Loading";
import { deleteEntityUser } from "../../../repositories/portal_api/EntityUser";
import { useManagementController } from "../../accessProfile/controllers/ManagementController";

export const PermissionsAndUsers = ({ location }) => {
  const { push } = useHistory();
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [currentUser, setCurrentUser] = useState({});
  const [openUserDialog, setOpenUserDialog] = useState(false);
  const [openConfirmDeleteUser, setOpenConfirmDeleteUser] = useState(false);
  const [userSearch, setUserSearch] = useState("");
  const [permissionList, setPermissionList] = useState([]);
  const [permission, setPermission] = useState(0);
  const [openModalAddPermissionGroup, setOpenModalAddPermissionGroup] =
    useState(false);
  const [openModalDeletePermissionGroup, setOpenModalDeletePermissionGroup] =
    useState(false);
  const [dataTableFilter, setDataTableFilter] = useState([]);
  const [permissionsTree, setPermissionTree] = useState([]);

  const [pageSize, setPageSize] = useState(10);
  const { setBreadcrumbs, companyUser, userLogged } = useContext(GlobalContext);
  const { setPermissionsList, setDeletedPermissionsListChildrens } =
    useContext(ManagementContext);

  const [clickedPermission, setClickedPermission] = useState({});
  const [loadingMessage, setLoadingMessage] = useState("");
  const [logout, setLogout] = useState(false);
  const [loadingUser, setLoadingUser] = useState(false);

  const { setPortalAsClientView } = usePortalAsClient();

  const { documentCustomer, companyName, customerId } = useContext(
    CustumerManagementContext
  );

  const { haveMasterPermission } = useManagementController();

  const customLocaleText = {
    noRowsLabel: "Sem Registros",
  };

  useEffect(() => {
    if (location?.pathname === "/setting") {
      setLoading(true);
      Object.assign(location, {
        state: { customerId: localStorage.getItem("companyIdPanel") },
      });
      setLoading(false);

      setBreadcrumbs([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (logout) {
      return push("/login", { logout: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logout]);

  const customPagination = ({ pagination }) => {
    return (
      <GridPagination
        nextIconButtonText="Próxima página"
        backIconButtonText="Página anterior"
        rowsPerPageOptions={[10, 15, 20]}
        labelRowsPerPage="Linhas por página"
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to} de ${count} `
        }
      />
    );
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      "& .MuiDataGrid-columnsContainer": {
        // Cor de fundo do cabeçalho
        background: "#F5F5F5",
        alignItems: "center",
      },
    },
    paper: {
      "& .MuiMenu-paper": {
        padding: "none",
        border: "none",
      },
    },
  }));

  const handleDeletePermission = async (permissionId) => {
    setLoading(true);

    const response = await deletePermissionGroup({
      userId: userLogged.userId,
      groupId: permissionId,
      entityId: location?.state?.customerId,
    });
    setOpenModalDeletePermissionGroup(false);

    if (response.success) {
      setPermissionList((prev) =>
        prev.filter((group) => group.id !== permissionId)
      );
      notify("Grupo de permissões excluído!", true, "success");
      initialSetup();
    }
  };

  const handleChangePermissionGroup = async (row, permissionGroupId) => {
    let userRows = [...dataTableFilter];

    userRows = userRows.map((user) => {
      if (user.id === row.id) {
        user.isLoadingGroup = true;
      }
      return user;
    });

    setDataTableFilter(userRows);

    const permissionFounded = permissionList?.find(
      (permission) => permission.id === permissionGroupId
    );

    const response = await updateOrLinkUserPermissionGroup({
      entityId: customerId || companyUser.accountantId,
      userId: userLogged.userId,
      body: {
        user_id: row.id,
        permission_group_id: permissionGroupId,
      },
    });

    if (response?.success) {
      row.permission_group_id = permissionGroupId;
      row.permission_group_name = permissionFounded.name;
    } else {
      if (response?.code === 403) {
        notify(
          "Você não tem permissão para alterar o grupo de permissão desse usuário!",
          true
        );
      } else {
        notify("Ocorreu um erro ao alterar o grupo de permissão!", true);
      }
    }

    userRows = userRows.map((user) => {
      if (user.id === row.id) {
        user.isLoadingGroup = false;
      }
      return user;
    });

    setDataTableFilter(userRows);
  };

  const classes = useStyles();

  let columns = [
    {
      field: "formated_name",
      headerName: "Nome",
      flex: 0.3,
      sortable: false,
      renderCell: ({ row }) => {
        return (
          <p>
            {row.first_name} {row.last_name}
          </p>
        );
      },
    },
    {
      field: "permission_group_name",
      headerName: "Grupo de Permissão",
      flex: 0.4,
      minWidth: 150,
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            {params.row.is_master ? (
              <p style={{ fontSize: "16px" }}>Administrador Principal</p>
            ) : params.row?.isLoadingGroup ? (
              <CircularProgress />
            ) : (
              <SelectFilterStyled
                key={1}
                size="small"
                fullWidth
                value={params.row.permission_group_id}
                onChange={(e) => {
                  handleChangePermissionGroup(params.row, e.target.value);
                }}
                disableUnderline
                disabled={
                  (userLogged.userId === params?.row?.id?.toString() &&
                    location?.pathname !== "/customermanagement/edit") ||
                  (!haveMasterPermission() &&
                    params?.row?.permission_group_name === "Master" &&
                    location?.pathname !== "/customermanagement/edit")
                }
              >
                {permissionList.map((permission) => (
                  <MenuItem
                    value={permission.id}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>
                      {permission.name === "Master"
                        ? "Administrador"
                        : (permission.name === "Padrão" ||
                            permission.name === "Default") &&
                          params.row.customized
                        ? "Customizado"
                        : permission.name === "Padrão" ||
                          permission.name === "Default"
                        ? "Acesso Básico"
                        : permission.name}
                    </div>
                  </MenuItem>
                ))}
              </SelectFilterStyled>
            )}
          </>
        );
      },
    },

    {
      field: "email",
      headerName: "E-mail",
      flex: 0.3,
      sortable: false,
    },

    {
      field: "actions",
      headerName: "",
      align: "right",
      sortable: false,
      flex: 0.2,
      renderHeader: () => <></>,
      renderCell: (params) => (
        <div>
          <LightTooltip
            title="Enviar Convite "
            placement="top"
            style={{ marginRight: "4px" }}
          >
            <IconButton
              size="small"
              onClick={() => {
                setLoading(true);
                postInvite({
                  client_id: location?.state?.customerId,
                  user_id: params?.row?.id,
                  accountant_id: localStorage.getItem("companyIdPanel"),
                })
                  .then((response) => {
                    if (response?.success) {
                      params.row.portal_invite_sended = true;
                      notify("Convite enviado com sucesso!", true, "success");
                    } else {
                      notify(response?.message);
                    }
                  })
                  .catch((error) => {
                    console.error(error);
                  })
                  .finally(() => {
                    setLoading(false);
                  });
              }}
            >
              <SendIcon
                fill={params?.row?.portal_invite_sended ? "#ff9800" : "#616161"}
              />
            </IconButton>
          </LightTooltip>

          <LightTooltip title="Editar" placement="top">
            <IconButton
              style={{ marginRight: "4px" }}
              size="small"
              onClick={() => {
                if (
                  !haveMasterPermission() &&
                  params?.row?.permission_group_name === "Master" &&
                  location?.pathname !== "/customermanagement/edit"
                ) {
                  return notify(
                    "Não é possível editar usuários administradores sem a permissão de administrador!"
                  );
                } else {
                  setCurrentUser(params?.row);
                  setOpenUserDialog(true);
                }
              }}
            >
              <EditIcon />
            </IconButton>
          </LightTooltip>

          <LightTooltip title="Excluir" placement="top">
            <IconButton
              style={{ marginRight: "4px" }}
              size="small"
              onClick={(e) => {
                if (params?.row?.is_master) {
                  return notify(
                    "Não é possível deletar o usuário master principal, por favor realize a troca para a deleção!"
                  );
                } else if (userLogged.userId === params?.row?.id?.toString()) {
                  return notify("Não é possível deletar o própio usuário!");
                } else if (
                  !haveMasterPermission() &&
                  params?.row?.permission_group_name === "Master" &&
                  location?.pathname !== "/customermanagement/edit"
                ) {
                  return notify(
                    "Não é possível deletar usuários administradores sem a permissão de administrador!"
                  );
                } else {
                  setCurrentUser(params?.row);
                  setOpenConfirmDeleteUser(true);
                }
              }}
            >
              <DeleteIcon />
            </IconButton>
          </LightTooltip>
        </div>
      ),
    },
  ];

  const initialSetup = () => {
    if (location?.state?.customerId) {
      setLoading(true);
      // TODO: GET USERS OF COMPANY
      getUsersByEntityId(location?.state?.customerId)
        .then((response) => {
          if (response?.success) {
            setUsers(response?.data);
          } else {
            notify(response?.message);
          }
        })
        .catch((error) => {
          console.error(error);
          push("customermanagement/list");
        });

      getPermissionGroup({ entityId: location?.state?.customerId }).then(
        (response) => {
          if (response.success) {
            setPermissionList(response?.data);
          } else {
            notify(response?.message);
          }
        }
      );

      getPermissionTree({ document: companyUser.document })
        .then((response) => {
          if (response.success) {
            response.data.children_recursive =
              response.data.children_recursive.filter(
                (element) => element.description !== "Relatórios"
              );
            setPermissionTree([response.data]);
          } else {
            notify(response?.message);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    initialSetup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.state?.customerId]);

  const handleSaveUsers = (users) => {
    users.map((u) => {
      if (typeof u?.id !== "number" && u?.id !== null && u?.id !== undefined) {
        if (u.id.includes("NEW")) delete u.id;
      }
      return u;
    });

    setLoading(true);

    postAccountantClientUsers(location?.state?.customerId, users)
      .then((response) => {
        if (response?.success) {
          notify("Usuários atualizados com sucesso!", true, "success");
          setUsers(response?.data);
        } else {
          notify(response?.message);
        }
      })
      .catch((error) => {
        console.error(error);
        notify("Erro ao atualizar usuários.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    let rowsFilter = [...users];

    rowsFilter = rowsFilter.map((row) => {
      row.isLoadingGroup = false;
      return row;
    });

    if (userSearch) {
      rowsFilter = filter({
        data: rowsFilter,
        text: userSearch,
        columns: ["first_name", "last_name", "permission_group_id"],
      });
    }

    if (permission === 1) {
      rowsFilter = rowsFilter.filter(
        (row) =>
          (row.permission_group_name === "Padrão" ||
            row.permission_group_name === "Default") &&
          row.customized
      );
    }

    if (permission !== 0 && permission !== 1) {
      rowsFilter = rowsFilter.filter(
        (row) =>
          row.permission_group_id === permission &&
          !(
            row.permission_group_name === "Padrão" &&
            row.permission_group_name === "Default"
          ) &&
          !row.customized
      );
    }

    setDataTableFilter(orderBy(rowsFilter, "formated_name"));
  }, [userSearch, users, permission]);

  return (
    <>
      {loading ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </div>
      ) : (
        <>
          <Loading open={loadingUser} message={loadingMessage} />

          <ModalAddPermissionGroup
            open={openModalAddPermissionGroup}
            handleClose={() => {
              setOpenModalAddPermissionGroup(false);
              setPermissionsList([]);
              setDeletedPermissionsListChildrens([]);
            }}
            companyDocument={companyUser.document}
            permissionsTree={permissionsTree}
            refreshPage={initialSetup}
            permissionToEdit={clickedPermission}
            location={location}
          />

          <ModalDeletePermissionGroup
            open={openModalDeletePermissionGroup}
            handleClose={() => {
              setOpenModalDeletePermissionGroup(false);
            }}
            permission={clickedPermission}
            handleConfirm={() => handleDeletePermission(clickedPermission.id)}
          />

          <DialogConfirm
            open={openConfirmDeleteUser}
            description={
              <>
                Deseja realmente <b style={{ color: "red" }}>excluir</b> o
                usuário <b>{currentUser.email}</b>?
              </>
            }
            handleClose={() => {
              setOpenConfirmDeleteUser(false);
            }}
            handleConfirm={() => {
              deleteEntityUser(location?.state?.customerId, currentUser?.id);
              setOpenConfirmDeleteUser(false);
              initialSetup();
            }}
            title="Excluir Usuário"
          />

          <DialogAddUser
            open={openUserDialog}
            user={currentUser}
            users={users}
            setUsers={setUsers}
            setLogout={setLogout}
            permissionsTree={permissionsTree}
            permissionGroupsList={permissionList}
            entityId={location?.state?.customerId}
            location={location}
            handleClose={() => {
              setCurrentUser({});
              setOpenUserDialog(false);
            }}
            handleSave={(user) => {
              if (user.id) {
                let newUserList = users.map((u) => {
                  if (u.id === user.id) {
                    return user;
                  }
                  return u;
                });

                setUsers(newUserList);
                handleSaveUsers(newUserList);
              } else {
                user.id = "NEW_" + Math.random();
                handleSaveUsers([...users, user]);
              }

              setCurrentUser({});
              setOpenUserDialog(false);
            }}
            firstUser={false}
            initialSetup={() => initialSetup()}
          />

          <Grid
            container
            direction="row"
            spacing={2}
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "16px",
            }}
          >
            <Grid item xs={6}>
              <SearchLabel>Nome do Usuário</SearchLabel>
              <div style={{ display: "flex", gap: "5px" }}>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  placeholder="Pesquisar por nome do usuário"
                  value={userSearch}
                  onChange={(e) => setUserSearch(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                <CustomTooltip title="Incluir Usuário">
                  <AddBlueButton
                    style={{ background: "#0036B3" }}
                    onClick={() => {
                      setCurrentUser({});
                      setOpenUserDialog(true);
                    }}
                  >
                    {" "}
                    <Add style={{ color: "white" }} />
                  </AddBlueButton>
                </CustomTooltip>
              </div>
            </Grid>

            <Grid item xs={6}>
              <SearchLabel>Filtrar por Grupos de Permissão</SearchLabel>
              <div style={{ display: "flex", gap: "5px" }}>
                <SelectFilterStyled
                  variant="outlined"
                  size="small"
                  fullWidth
                  defaultValue="all"
                  value={permission}
                  onChange={(e) => {
                    setPermission(e.target.value);
                  }}
                >
                  <MenuItem value={0}>Todos</MenuItem>
                  <MenuItem value={1}>Customizado</MenuItem>

                  {permissionList.map((userPermission) => {
                    return (
                      <MenuItem
                        key={userPermission.id}
                        value={userPermission.id}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        {userPermission?.description != null ? (
                          <CustomTooltip title={userPermission.description}>
                            <div style={{ width: "100%" }}>
                              {userPermission.name === "Master"
                                ? "Administrador"
                                : userPermission.name === "Padrão" ||
                                  userPermission.name === "Default"
                                ? "Acesso Básico"
                                : userPermission.name}
                            </div>
                          </CustomTooltip>
                        ) : (
                          <div style={{ width: "100%" }}>
                            {userPermission.name === "Master"
                              ? "Administrador"
                              : userPermission.name === "Padrão" ||
                                userPermission.name === "Default"
                              ? "Acesso Básico"
                              : userPermission.name}
                          </div>
                        )}

                        <ListItemSecondaryAction>
                          {permission !== userPermission.id &&
                            userPermission.name !== "Master" &&
                            userPermission.name !== "Padrão" &&
                            userPermission.name !== "Default" && (
                              <>
                                <CustomTooltip title="Editar Permissão">
                                  <IconButton
                                    edge="end"
                                    aria-label="editar"
                                    onClick={() => {
                                      setClickedPermission(userPermission);
                                      setOpenModalAddPermissionGroup(true);
                                    }}
                                  >
                                    <EditIcon color="#616161" />
                                  </IconButton>
                                </CustomTooltip>

                                <CustomTooltip title="Copiar Permissão">
                                  <IconButton
                                    edge="end"
                                    aria-label="copy"
                                    onClick={() => {
                                      setClickedPermission({
                                        permissionList:
                                          userPermission?.permissions,
                                      });
                                      setOpenModalAddPermissionGroup(true);
                                    }}
                                  >
                                    <CopyIcon color="#616161" />
                                  </IconButton>
                                </CustomTooltip>

                                <CustomTooltip title="Deletar Permissão">
                                  <IconButton
                                    edge="end"
                                    aria-label="copy"
                                    onClick={() => {
                                      setClickedPermission(userPermission);
                                      setOpenModalDeletePermissionGroup(true);
                                    }}
                                  >
                                    <DeleteIcon color="#616161" />
                                  </IconButton>
                                </CustomTooltip>
                              </>
                            )}
                        </ListItemSecondaryAction>
                      </MenuItem>
                    );
                  })}
                </SelectFilterStyled>
                <CustomTooltip title="Incluir Grupo de Permissão">
                  <AddBlueButton
                    style={{ background: "#0036B3" }}
                    onClick={() => {
                      setClickedPermission({});
                      setOpenModalAddPermissionGroup(true);
                    }}
                  >
                    {" "}
                    <Add style={{ color: "white" }} />
                  </AddBlueButton>
                </CustomTooltip>
              </div>
            </Grid>
          </Grid>

          <DataGrid
            className={classes.root}
            autoHeight
            rows={dataTableFilter}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
            }}
            isRowSelectable={(params) => {
              return !params.row.is_master;
            }}
            disableSelectionOnClick
            disableColumnMenu
            localeText={customLocaleText}
            components={{
              Pagination: customPagination,
            }}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          />

          {location?.pathname !== "/setting" && (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "16px",
              }}
            >
              <SendToClientPortal
                startIcon={
                  <CallMade
                    style={{ color: "#0036B3", width: "24px", height: "24px" }}
                  />
                }
                onClick={async () => {
                  setLoadingMessage(
                    "Acessando o Portal com a visão do cliente..."
                  );

                  await getDataClientView(location?.state?.customerId).then(
                    async (resp) => {
                      if (resp?.success) {
                        const data = resp?.data;
                        await setPortalAsClientView(
                          documentCustomer,
                          data?.master_user_id,
                          location?.state?.customerId,
                          `${data?.master_first_name} ${data?.master_last_name}`,
                          companyName,
                          data?.logo_url,
                          data?.master_user_email,
                          setLoadingUser
                        );
                        push("/dashboard/view");
                      }
                    }
                  );
                }}
              >
                <SendToClientPortalText>
                  Acessar Portal do Cliente
                </SendToClientPortalText>
              </SendToClientPortal>
            </div>
          )}
        </>
      )}
    </>
  );
};
