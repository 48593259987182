import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import {
  TabTextField,
  valueFormStyle,
  WarningBoletoExists,
} from "../styles/DocumentModal";
import IntlCurrencyInput from "react-intl-currency-input";
import { ButtonSave } from "../../modal/Modal";
import { searchBoleto } from "../../../repositories/Bot";

export const DocumentBoletoTab = (props) => {
  const [typeableLine, setTypeableLine] = useState(
    props?.document?.data?.typeable_line
  );
  const [draweeName, setDraweeName] = useState(
    props?.document?.data?.data?.drawee?.name
  );

  const [beneficiaryName, setBeneficiaryName] = useState(
    props?.document?.data?.data?.beneficiary?.name
  );

  const [totalAmount, setTotalAmount] = useState(
    props?.document?.data?.data?.paymentDetails?.totalAmount
  );
  const [documentName, setDocumentName] = useState(
    props?.document?.use_suggestion
      ? props?.document?.name_suggestion
      : props?.document?.name
  );

  const [boletoSearchField, setBoletoSearchField] = useState();

  const handleSearchClick = async () => {
    props.setBlockSend(true);
    const responseBoleto = await searchBoleto({ block: boletoSearchField });

    setDraweeName(responseBoleto?.data?.drawee?.name);
    setBeneficiaryName(responseBoleto?.data?.beneficiary?.name);
    if (responseBoleto?.data?.paymentDetails?.totalAmount) {
      setTotalAmount(
        responseBoleto?.data?.paymentDetails?.totalAmount.toLocaleString(
          "pt-BR",
          { style: "currency", currency: "BRL" }
        )
      );
    }

    if (!responseBoleto?.data?.drawee) {
      responseBoleto.data["drawee"] = {
        name: "",
      };
    }

    if (!responseBoleto.data.drawee.taxIdentifier) {
      responseBoleto.data.drawee["taxIdentifier"] = {
        taxId: "",
      };
    }

    if (!responseBoleto?.data?.beneficiary) {
      responseBoleto.data["beneficiary"] = {
        name: "",
      };
    }

    if (!responseBoleto.data.beneficiary.taxIdentifier) {
      responseBoleto.data.beneficiary["taxIdentifier"] = {
        taxId: "",
      };
    }

    Object.assign(props.document.data, responseBoleto);

    props.setBlockSend(false);
  };

  useEffect(() => {
    if (props.docTypeSelectedChanged) {
      props.setBlockSend(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {props.document.data.error && (
          <WarningBoletoExists>
            AVISO: Boleto não encontrado na base de compensação. Pode ser que
            este boleto já tenha sido pago.
          </WarningBoletoExists>
        )}
      </Grid>
      <Grid item xs={12}>
        {props?.document?.boletoExists && (
          <WarningBoletoExists>
            AVISO: Você já realizou o envio deste boleto.
          </WarningBoletoExists>
        )}
      </Grid>{" "}
      {props.docTypeSelectedChanged ? (
        <Grid item container xs={12}>
          <Grid item xs={10}>
            <TabTextField
              label="Digite o código de barras"
              variant="outlined"
              key={"TYPEABLE_LINE" + props?.index}
              value={boletoSearchField}
              onChange={(ev) => {
                setBoletoSearchField(ev.target.value);
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <ButtonSave onClick={handleSearchClick}>Buscar </ButtonSave>
          </Grid>
        </Grid>
      ) : (
        <Grid item xs={12}>
          <TabTextField
            label="Linha digitável"
            variant="outlined"
            key={"TYPEABLE_LINE" + props?.index}
            value={typeableLine}
            onChange={(ev) => {
              setTypeableLine(ev.target.value);
              if (props.document.data.typeable_line) {
                props.document.data.typeable_line = ev.target.value;
              }
            }}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <TabTextField
          label="Pagador"
          variant="outlined"
          key={"PAYER_CODE" + props?.index}
          value={draweeName}
          onChange={(ev) => {
            setDraweeName(ev.target.value);
            if (props.document.data.data.drawee.name) {
              props.document.data.data.drawee.name = ev.target.value;
            }
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TabTextField
          label="Beneficiario"
          variant="outlined"
          key={"RECEIVER_CODE" + props.index}
          value={beneficiaryName}
          onChange={(ev) => {
            setBeneficiaryName(ev.target.value);
            if (props.document.data.data.beneficiary.name) {
              props.document.data.data.beneficiary.name = ev.target.value;
            }
          }}
        />
      </Grid>
      <Grid item xs={3}>
        <div
          class="MuiFormControl-root MuiTextField-root"
          style={valueFormStyle}
        >
          <label
            style={{ background: "#f2f2f2" }}
            class="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-marginDense MuiInputLabel-outlined MuiFormLabel-filled"
            data-shrink="true"
          >
            Valor total
          </label>

          <div class="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-formControl MuiInputBase-marginDense MuiOutlinedInput-marginDense">
            <IntlCurrencyInput
              currency="BRL"
              defaultValue={totalAmount}
              class="MuiInputBase-input MuiOutlinedInput-input MuiInputBase-inputMarginDense MuiOutlinedInput-inputMarginDense"
              config={{
                locale: "pt-BR",
                formats: {
                  number: {
                    BRL: {
                      style: "currency",
                      currency: "BRL",
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    },
                  },
                },
              }}
              onChange={(event, value, maskedValue) => {
                event.preventDefault();
                setTotalAmount(maskedValue);
                if (props.document.data.data.paymentDetails.totalAmount) {
                  props.document.data.data.paymentDetails.totalAmount =
                    maskedValue;
                }
              }}
            />
          </div>
        </div>
      </Grid>
      {props?.documentModalLoading === false && (
        <>
          <Grid item xs={9}>
            <TabTextField
              final
              label={
                props?.document?.use_suggestion
                  ? "Nome do documento (sugestão)"
                  : "Nome do documento (original)"
              }
              variant="outlined"
              key={"DOCUMENT_NAME" + props?.index + " - " + props?.documentKey}
              value={documentName}
              onChange={(ev) => {
                setDocumentName(ev.target.value);
                if (props.document.name_suggestion) {
                  props.document.name_suggestion = ev.target.value;
                }
              }}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};
