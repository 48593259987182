import axios from "axios";

const BOT_API = process.env.REACT_APP_BOT_API_URL;
const url = BOT_API + "/v1/bot-show/audio";

export const getMP3Audio = async (message) => {
  navigator.mediaDevices
    .getUserMedia({ video: false, audio: true })
    .then((stream) => {})
    .catch((err) => {
      console.error("u got an error:" + err);
    });

  let response = await axios.post(url, {
    message,
  });

  return response.data;
};

export const getMP3AudioAsync = async (message) => {
  await new Promise((resolve, reject) => {
    navigator.mediaDevices
      .getUserMedia({ video: false, audio: true })
      .then((stream) => {
        resolve(stream);
      })
      .catch((err) => {
        reject(message);
      });
  });

  let response = await axios.post(url, {
    message,
  });

  const snd = new Audio("data:audio/wav;base64," + response.data);
  return snd;
};
