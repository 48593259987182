import React, { useContext, useEffect, useRef, useState } from "react";
import { GlobalContext } from "../../contexts/GlobalContext";
import { feedUserPortalAuth } from "../../repositories/FeedAuth";
import { CircularProgress } from "@material-ui/core";
import { notify } from "../../utils/notify";

export const Feed = () => {
  const { userLogged, companyUser } = useContext(GlobalContext);
  const [feedsToFollow, setFeedsToFollow] = useState(["product:Portal"]);
  const [showFeed, setShowFeed] = useState(false);
  const permission = useRef("");
  const feedGroup = useRef("");

  useEffect(() => {
    initialSetup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLogged, companyUser]);

  const initialSetup = async () => {
    setShowFeed(false);
    if (userLogged && companyUser) {
      if (companyUser.isAccountant) {
        permission.current = "write";
        feedGroup.current = "accountant";
      } else {
        permission.current = "read";
        feedGroup.current = "user";
        setFeedsToFollow((prevFileList) => [
          ...prevFileList,
          `accountant:${companyUser.accountantId}`,
        ]);
      }
      await authUserFeedPortalAndSetLocalStorage();

      setShowFeed(true);
    }
  };

  useEffect(() => {
    if (userLogged && companyUser) {
      if (window.QyonFeed && localStorage.getItem("qyonFeed_authToken")) {
        const instance = new window.QyonFeed({
          accessToken: localStorage.getItem("qyonFeed_authToken"),
          follow: feedsToFollow,
          primaryColor: "#0036B3",
          permission: permission.current,
          publisherId: userLogged.userId,
          user: {
            id: companyUser.companyId,
            name: companyUser.companyName,
            email: companyUser.masterUserEmail,
            picture_url: companyUser.logoUrl,
            background_image_url: "",
            about: "",
          },
        });

        if (showFeed) {
          instance.render();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem("qyonFeed_authToken")]);

  const authUserFeedPortalAndSetLocalStorage = async () => {
    const response = await feedUserPortalAuth({
      product: "Portal",
      feedGroup: feedGroup.current,
      userId: companyUser.companyId,
    });

    if (response?.success) {
      localStorage.setItem("qyonFeed_authToken", response?.data?.accessToken);
    } else {
      notify("Ocorreu um erro na autenticação do feed!");
    }
  };

  return (
    <div style={{ padding: "20px" }}>
      {!showFeed ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <div id="qyon-feed" />
      )}
    </div>
  );
};
