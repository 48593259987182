import { Grid, TextField } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { SearchLabel } from "../../customerManagements/styles/CustomerManagementStyle";
import { ManagementContext } from "../../accessProfile/contexts/ManagementContext";

export const PermissionNameDescription = ({
  permissionToEdit,
  permissionNameError,
  setPermissionNameError,
}) => {
  const { setPermissionName, setPermissionDescription } =
    useContext(ManagementContext);

  const [permissionNameInternal, setPermissionNameInternal] = useState("");
  const [permissionDescriptionInternal, setPermissionDescriptionInternal] =
    useState("");

  useEffect(() => {
    if (permissionToEdit?.id) {
      setPermissionNameInternal(permissionToEdit?.name);
      setPermissionDescriptionInternal(permissionToEdit?.description);
    } else if (permissionToEdit?.permissionList) {
      setPermissionNameInternal("");
      setPermissionDescriptionInternal("");
    } else {
      setPermissionNameInternal("");
      setPermissionDescriptionInternal("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissionToEdit]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      setPermissionName(permissionNameInternal);
      setPermissionDescription(permissionDescriptionInternal);
    }, 300);

    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissionNameInternal, permissionDescriptionInternal]);

  return (
    <>
      <Grid item xs={12}>
        <SearchLabel>Nome do Grupo</SearchLabel>
        <div style={{ display: "flex", gap: "5px" }}>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            value={permissionNameInternal}
            onChange={(e) => {
              setPermissionNameInternal(e.target.value);
            }}
            onFocus={() => {
              setPermissionNameError(false);
            }}
            error={permissionNameError}
          />
        </div>
      </Grid>
      <Grid item xs={12}>
        <TextField
          variant="outlined"
          size="small"
          fullWidth
          value={permissionDescriptionInternal}
          onChange={(e) => setPermissionDescriptionInternal(e.target.value)}
          multiline
          minRows={5}
          style={{
            display: "flex",
          }}
          InputProps={{
            startAdornment: (
              <p
                style={{
                  display: "flex",
                  position: "absolute",
                  left: 14,
                  top: 14,
                  color: "#364461",
                  fontFamily: "Roboto",
                  fontSize: "12px",
                  fontStyle: "normal",
                  fontWeight: "300",
                  lineHeight: "10px",
                }}
              >
                Descrição da Permissão
              </p>
            ),
            style: {
              paddingTop: "32px",
              alignself: "stretch",
              color: "#364461",
              fontFamily: "Roboto",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "14px",
            },
          }}
        />
      </Grid>
    </>
  );
};
