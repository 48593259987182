import {
  Checkbox,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Grid,
  Modal,
} from "@material-ui/core";

import Loading from "../../../components/basics/Loading";
import { PdfIframe } from "./styles/ModalNewLetterVersion";
import { downloadPdfById } from "../../../repositories/panel/v1/integrations/VersionPortal";
import { useState } from "react";
import {
  ButtonCancel,
  ButtonSave,
  CloseIconStyle,
  DialogHeader,
  DialogStyle,
} from "../../../components/modal/Modal";
import {
  VersionContainer,
  IntroTextVersion,
  LabelcurrentVersion,
  Labeltitle,
  LabelVersion,
  LabelVersionDate,
  VersionRow,
  IntroVersion,
} from "../../../components/settings/styles/ReleaseVersion";

export default function ModalNewLetterVersion({ open, setOpen, newLetter }) {
  const [loading, setLoading] = useState(false);
  const [dontShowNewLetters, setDontShowNewLetters] = useState(
    localStorage.getItem("dontShowNewLetters")
  );

  async function downloadPdf() {
    setLoading(true);

    const responsePdf = await downloadPdfById(newLetter.id);
    const data = window.URL.createObjectURL(responsePdf);

    const link = document.createElement("a");
    link.href = data;
    link.download = (newLetter.name || "default") + ".pdf";
    link.dispatchEvent(
      new MouseEvent("click", {
        bubbles: true,
        cancelable: true,
        view: window,
      })
    );

    setTimeout(() => {
      window.URL.revokeObjectURL(data);
      link.remove();
      setLoading(false);
    }, 100);
  }

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Loading open={loading} />

      <Modal
        open={open}
        maxWidth="lg"
        style={{ height: "90vh" }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onClose={handleClose}
      >
        <DialogStyle>
          <DialogHeader>
            <div className="row">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                Atualização de Versão
              </div>
              <CloseIconStyle onClick={handleClose} />
            </div>
          </DialogHeader>
          <DialogContent>
            {newLetter.pdf ? (
              <PdfIframe
                style={{ backgroundColor: "white" }}
                title={`document_${newLetter.name}`}
                src={
                  "data:application/pdf;base64," +
                  newLetter.pdf +
                  "#zoom=60?id=" +
                  Math.random()
                }
              />
            ) : (
              <Grid container direction="row" justifyContent="space-between">
                <VersionRow>
                  <LabelVersion>{newLetter.version}</LabelVersion>
                  <Labeltitle>{newLetter.name}</Labeltitle>
                </VersionRow>
                <VersionContainer>
                  <LabelcurrentVersion>Mais Recente</LabelcurrentVersion>
                  <LabelVersionDate>{newLetter.release_date}</LabelVersionDate>
                </VersionContainer>

                <IntroVersion>
                  Nova versão recém-lançada do sistema, acompanhada por novos
                  recursos e melhorias significativas:{" "}
                </IntroVersion>
                <IntroTextVersion>{newLetter.resume}</IntroTextVersion>
              </Grid>
            )}
          </DialogContent>
          <DialogActions style={{ padding: 24 }}>
            <Grid container direction="row" justifyContent="space-between">
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={dontShowNewLetters}
                      onChange={(e) => {
                        setDontShowNewLetters(e.target.checked);
                        localStorage.setItem(
                          "dontShowNewLetters",
                          e.target.checked
                        );
                      }}
                    />
                  }
                  label="Não mostrar mais"
                />
              </Grid>
              <Grid item cointainer spacing={5}>
                <ButtonCancel onClick={handleClose}>Fechar</ButtonCancel>
                {newLetter.pdf && (
                  <ButtonSave style={{ marginLeft: 8 }} onClick={downloadPdf}>
                    Download
                  </ButtonSave>
                )}
              </Grid>
            </Grid>
          </DialogActions>
        </DialogStyle>
      </Modal>
    </>
  );
}
