import { BaseObjectDocument } from "./BaseObjectDocument";

export const DarfObject = (props) => {
  let mainObject = BaseObjectDocument();

  if (props) {
    mainObject.base64 = props.base64;
    mainObject.name = props.name;

    mainObject.data = {
      base64: props.data.base64,
      document_type: "DARF",
      period: "",
      govid: props.data.govid || "",
      code_receita: "",
      name: "",
      ref_number: "",
      expire_date: "",
      principal_amount: "0,00",
      penalty_amount: "0,00",
      tax_amount: "0,00",
      total_amount: "0,00",
    };

    mainObject.sendToPay = true;
    mainObject.fiscal = true;
    mainObject.doc_type = "guide";
    mainObject.use_suggestion = true;
    mainObject.generateTicketEmail = props.generateTicketEmail;
  }
  return mainObject;
};
