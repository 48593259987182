import * as React from "react";

function ConfigIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2879 1.29778C11.6784 0.907252 12.3116 0.907252 12.7021 1.29778L15.4092 4.00488H18.995C19.5473 4.00488 19.995 4.4526 19.995 5.00488V8.59067L22.7021 11.2978C23.0926 11.6883 23.0926 12.3215 22.7021 12.712L19.995 15.4191V19.0049C19.995 19.5572 19.5473 20.0049 18.995 20.0049H15.4092L12.7021 22.712C12.3116 23.1025 11.6784 23.1025 11.2879 22.712L8.58079 20.0049H4.995C4.44272 20.0049 3.995 19.5572 3.995 19.0049V15.4191L1.2879 12.712C0.897372 12.3215 0.897372 11.6883 1.2879 11.2978L3.995 8.59067V5.00488C3.995 4.4526 4.44272 4.00488 4.995 4.00488H8.58079L11.2879 1.29778ZM11.995 3.4191L9.70211 5.71199C9.51457 5.89953 9.26022 6.00488 8.995 6.00488H5.995V9.00488C5.995 9.2701 5.88965 9.52445 5.70211 9.71199L3.40922 12.0049L5.70211 14.2978C5.88965 14.4853 5.995 14.7397 5.995 15.0049V18.0049H8.995C9.26022 18.0049 9.51457 18.1102 9.70211 18.2978L11.995 20.5907L14.2879 18.2978C14.4754 18.1102 14.7298 18.0049 14.995 18.0049H17.995V15.0049C17.995 14.7397 18.1004 14.4853 18.2879 14.2978L20.5808 12.0049L18.2879 9.71199C18.1004 9.52445 17.995 9.2701 17.995 9.00488V6.00488H14.995C14.7298 6.00488 14.4754 5.89953 14.2879 5.71199L11.995 3.4191ZM11.995 10.0049C10.8904 10.0049 9.995 10.9003 9.995 12.0049C9.995 13.1095 10.8904 14.0049 11.995 14.0049C13.0996 14.0049 13.995 13.1095 13.995 12.0049C13.995 10.9003 13.0996 10.0049 11.995 10.0049ZM7.995 12.0049C7.995 9.79574 9.78586 8.00488 11.995 8.00488C14.2041 8.00488 15.995 9.79574 15.995 12.0049C15.995 14.214 14.2041 16.0049 11.995 16.0049C9.78586 16.0049 7.995 14.214 7.995 12.0049Z"
      />
    </svg>
  );
}

export default ConfigIcon;
