import * as React from "react";

function RadarIcon(props) {
  return (
    <svg
      width={23}
      height={24}
      viewBox="0 0 23 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.262 10.976c-.547 0-.912.365-1.003 1.004v.183c0 .73-.092 1.551-.366 2.19-1.368 4.928-6.387 7.757-11.315 6.388C3.65 19.37.82 14.353 2.19 9.425c1.278-4.563 5.932-7.483 10.586-6.57a8.518 8.518 0 013.011 1.095c.365.182.639.456.913.639l.09.09-1.55 1.552c-.092 0-.092-.091-.183-.091-.091-.091-.274-.183-.456-.274-1.825-1.095-3.742-1.277-5.75-.639-1.46.457-2.646 1.37-3.558 2.647-1.187 1.734-1.552 3.559-1.187 5.566.457 1.734 1.37 3.194 2.83 4.198 1.642 1.186 3.558 1.551 5.566 1.186 1.277-.273 2.372-.912 3.376-1.825 1.37-1.369 2.1-3.102 2.19-5.019 0-.547-.365-.912-.912-1.004-.456 0-.913.365-1.004.913v.821c-.456 2.83-3.011 4.745-5.84 4.38-1.369-.182-2.555-.912-3.377-2.007a5.224 5.224 0 01-1.003-3.833c.182-1.46 1.003-2.646 2.098-3.467 1.187-.822 2.556-1.096 4.016-.822.73.183 1.277.365 1.825.822l-1.552 1.55c-1.46-.73-3.011-.09-3.741.913-.821 1.095-.73 2.738.182 3.742.913 1.095 2.464 1.277 3.742.639.639-.365 1.004-.822 1.277-1.552.274-.821.274-1.642-.09-2.372l5.11-5.11c.273-.366.364-.548.364-.822a.829.829 0 00-.273-.639 10.64 10.64 0 00-5.84-3.011c-.274-.091-.548-.091-.913-.091l-.365-.092h-1.369l-.365-.09c-.274 0-.547.09-.821.09a11.404 11.404 0 00-6.023 3.194C1.643 5.684.639 7.692.183 9.882c-.092.273-.092.547-.183.73v2.463c.274 2.373 1.186 4.472 2.646 6.114 2.282 2.555 5.02 3.924 8.122 3.924 1.004 0 2.008-.09 3.011-.365 4.198-1.095 6.936-3.832 8.122-8.304.183-.73.274-1.551.274-2.464 0-.547-.456-1.004-.913-1.004zm-9.125 1.004c0 .548-.456 1.095-1.095 1.095-.548 0-1.095-.456-1.095-1.095 0-.274.091-.547.274-.73.182-.182.456-.274.73-.274.73-.09 1.186.365 1.186 1.004z"
        fill="#0036B3"
      />
    </svg>
  );
}

export default RadarIcon;
