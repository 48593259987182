import { useCallback, useContext, useEffect, useRef } from "react";
import { GlobalContext } from "../../../contexts/GlobalContext";
import { useAuth } from "../../../contexts/crm/Auth";
import { getRoom } from "../../../repositories/integrations/crm/AttendanceRepository";
import {
  getAttendantsChatConected,
  postChatStatus,
} from "../../../repositories/integrations/crm/ChatRepository";
import { notify } from "../../../utils/notify";
import { useChatContext } from "../contexts/ChatContext";
import { useMonitor } from "../contexts/ChatMonitorContext";
import {
  getCustomerConversation,
  getStageAttendance,
  setLastUpdateClientInAttendance,
} from "../services/attendance";
import { setLastUpdateClientInQueue } from "../services/queue";
import {
  infoAttendantInChat,
  productsOfAttendance,
  reasonChat,
  statusRoom,
} from "../services/settings";
import { changeAccessTokenStorage } from "../utils";
import ContactController from "./ContactController";
import ConversationController from "./ConversationController";
import useWebSocketClient from "./WebSocketClient";

export default function ChatController() {
  const { companyUser, userLogged } = useContext(GlobalContext);
  let {
    SCREEN_ENUM,
    socket,
    chat,
    setChat,
    settings,
    setSettings,
    getCurrentConversation,
    setLoading,
  } = useChatContext();

  const { user, company } = useAuth();

  const webSocketClient = useWebSocketClient();
  const {
    subscribeOnConversationList,
    fetchCurrentConversations,
    addConversations,
    onReceiveMessage,
    subscribeOnAttendanceStart,
    subscribeOnNewsInteration,
    openConversation,
  } = ConversationController();
  const { subscribeOnQueueList, fetchQueue } = ContactController();

  const { setAttendants, setCurrentConversations, setCompanyValidation } =
    useMonitor();

  const chatRef = useRef(chat);
  useEffect(() => {
    chatRef.current = chat;
  }, [chat]);

  const userRef = useRef(user);
  useEffect(() => {
    userRef.current = user;
  }, [user]);

  const companyRef = useRef(company);
  useEffect(() => {
    companyRef.current = company;
  }, [company]);

  let fnIntervalLastUpdateClientQueue;

  const fetchData = useCallback(
    async ({ company, user } = {}) => {
      if (!company) company = companyRef.current;
      if (!user?.id) user = userRef.current;
      subscribeOnConversationList({ company, user });
      subscribeOnQueueList({ roomId: 1 });
      fetchQueue();
      if (
        chatRef.current.conversations.length === 0 &&
        companyUser.isAccountant === "true"
      ) {
        await fetchCurrentConversations({ company, user }, (data) => {
          if (!data || !data.conversations || data.conversations.length === 0)
            return;
          addConversations(data.conversations, onReceiveMessage);
        });
      }
    },
    [
      addConversations,
      companyUser.isAccountant,
      fetchCurrentConversations,
      fetchQueue,
      onReceiveMessage,
      subscribeOnConversationList,
      subscribeOnQueueList,
    ]
  );

  const getConversation = useCallback(
    (sessionId) => {
      const { conversations, currentConversation } = chatRef.current;

      if (sessionId)
        return conversations.find((c) => c.sessionId === sessionId);
      if (companyUser?.isAccountant !== "true" && conversations.length > 0)
        return conversations[0];
      if (!sessionId && currentConversation)
        return conversations.find((c) => c.sessionId === currentConversation);
      return null;
    },
    [companyUser?.isAccountant]
  );

  const sendConnectCustomer = useCallback(() => {
    const topic = webSocketClient.TOPICS.emit.connect;
    if (!socket.startedTopics[topic.replace("/", "")]) {
      webSocketClient.onConnect(() => {
        webSocketClient.send({
          topic,
          message: {
            identifiers: {
              sessionId: chat.sessionId,
              customerSocketClientId: webSocketClient.socket.id,
            },
            company_code: company.id,
            company_alias: company.alias,
            client_code: user.id,
            hash: webSocketClient.socket.id,
          },
          unique: true,
        });
      });
    }
  }, [
    chat.sessionId,
    company.alias,
    company.id,
    socket.startedTopics,
    user.id,
    webSocketClient,
  ]);

  const subscribeOnConnection = useCallback(
    (callback) => {
      const topic = webSocketClient.TOPICS.subscriptions.connectionResponse(
        webSocketClient.socket.id
      );
      if (!webSocketClient.isSubscribedOn(topic)) {
        webSocketClient.onConnect(() => {
          webSocketClient.subscribe(topic, (data) => {
            if (data && data.length > 1) {
              //MAP com as salas de chat
            } else if (data && data.length === 1) {
              let { code, messageOff, products } = data[0];
              products = products?.map((prod) => {
                return {
                  id: prod.code,
                  description: prod.description,
                };
              });
              let roomState = !messageOff ? "0" : "1";
              if (chat.roomId !== code) setChat({ roomId: code });
              setSettings({
                roomState,
                departments: products,
              });
            }
            if (callback) callback(data);
          });
        });
      }
    },
    [chat.roomId, setChat, setSettings, webSocketClient]
  );

  const handleOnStart = useCallback(
    ({ sessionId, infoStage, product_code }) => {
      const newQueueArray = chat.queue.filter((data) => {
        return data.sessionId !== sessionId;
      });

      let fnIntervalLastUpdateClientAttendance;
      newQueueArray.push({ sessionId });
      if (companyUser?.isAccountant !== "true") {
        setChat({
          currentScreen: SCREEN_ENUM.customerLobbyWaiting,
          currentConversation: sessionId,
          queue: newQueueArray,
        });
      }
      subscribeOnQueue({ sessionId });
      subscribeOnAttendance({ sessionId });
      subscribeOnAttendanceStart({ sessionId }, (data) => {
        if (data) onReceiveMessage(data);
      });
      subscribeOnNewsInteration({ roomId: chat.roomId, sessionId }, (data) => {
        if (data) onReceiveMessage(data);
      });

      if (infoStage === "attendance") {
        openConversation(sessionId);
      } else {
        sendToQueue({ sessionId, product_code });
      }

      if (
        companyUser?.isAccountant !== "true" &&
        sessionId &&
        !fnIntervalLastUpdateClientAttendance
      ) {
        fnIntervalLastUpdateClientAttendance = setInterval(() => {
          setLastUpdateClientInAttendance({ webSocketClient, sessionId });
        }, 60000);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const subscribeOnStart = useCallback(
    (callback) => {
      webSocketClient.onConnect((data) => {
        const topic = webSocketClient.TOPICS.subscriptions.chatStartResponse(
          data.socket.id
        );
        if (!webSocketClient.isSubscribedOn(topic)) {
          webSocketClient.subscribe(topic, async (data) => {
            let {
              error,
              session_id: queue_session,
              sessionId: chat_session,
              message,
              insert_ticket,
              hasntProduct,
              products,
              productId,
            } = data;

            const hasSessionId = queue_session || chat_session;
            const socketId = sessionStorage.getItem("SOCKET_ID");
            const handleStartProps = {
              product_code: productId,
              sessionId: queue_session,
            };

            try {
              if (error) {
                console.error("Response chatStart", error);
              }
              if (hasSessionId) {
                const sessionId = queue_session ?? chat_session;
                const conversation = getConversation();
                conversation.sessionId = sessionId;
                sessionStorage.setItem("SESSION_ID", sessionId);

                if (chat_session) {
                  const attendanceInfo = await validadeStageAttendance({
                    sessionId,
                    socketId,
                  });
                  handleStartProps.sessionId = chat_session;
                  handleStartProps.product_code = attendanceInfo.product_code;
                  handleStartProps.infoStage = attendanceInfo.info_stage.stage;
                }

                handleOnStart(handleStartProps);
                chat_session &&
                  setChat({ currentScreen: SCREEN_ENUM.conversation });
              } else if (message) {
                setChat({
                  isLoading: false,
                  loadingMessage: message,
                  currentScreen: SCREEN_ENUM.loading,
                });
                if (insert_ticket) {
                  console.debug("TODO: Enviar para abrir ticket");
                }
                if (hasntProduct && products) {
                  if (1 == 2) {
                    products = products.map((prod) => {
                      return {
                        id: prod.code,
                        description: prod.description,
                      };
                    });
                    setSettings({
                      departments: products,
                    });
                  }
                }
              }
              if (callback) callback(data);
            } catch (err) {
              console.error("chat start error", err);
              notify(
                "Ops! Algo deu errado ao iniciar o atendimento!",
                true,
                "error"
              );
            }
          });
        }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      SCREEN_ENUM.loading,
      getConversation,
      handleOnStart,
      setChat,
      setSettings,
      webSocketClient,
    ]
  );

  const subscribeOnQueue = ({ sessionId }, callback) => {
    const topic =
      webSocketClient.TOPICS.subscriptions.chatEnterQueue(sessionId);
    if (!webSocketClient.isSubscribedOn(topic)) {
      webSocketClient.onConnect(() => {
        webSocketClient.subscribe(topic, (data) => {
          let { error, queue_id } = data;
          try {
            if (error) {
              console.error("Error: ", error);
            } else {
              if (
                !webSocketClient.isSubscribedOn(
                  webSocketClient.TOPICS.subscriptions.queue(queue_id)
                )
              ) {
                webSocketClient.subscribe(
                  webSocketClient.TOPICS.subscriptions.queue(queue_id),
                  (data) => {}
                );
              }
              if (
                companyUser?.isAccountant !== "true" &&
                sessionId &&
                !fnIntervalLastUpdateClientQueue
              ) {
                fnIntervalLastUpdateClientQueue = setInterval(() => {
                  setLastUpdateClientInQueue({
                    webSocketClient,
                    sessionId,
                    queueId: queue_id,
                  });
                }, 60000);
              }
            }
          } catch (error) {
            console.error("error when sending to queue", error);
            notify("Erro ao iniciar atendimento", true, "error");
          }
        });
      });
    }
  };

  const sendToQueue = ({ sessionId, product_code }) => {
    const topic = webSocketClient.TOPICS.emit.queue;
    const customerId = user.id || userRef.current.id;
    const companyId = companyRef?.current?.id || company.id;
    const companyAlias = companyRef?.current?.alias || company.alias;

    if (!socket.startedTopics[topic.replace("/", "")]) {
      webSocketClient.send({
        topic,
        message: {
          identifiers: {
            sessionId: sessionId,
            customerSocketClientId: webSocketClient.socket.id,
          },
          company_code: companyId,
          company_alias: companyAlias,
          email: userLogged.email,
          username: `${userLogged.firstName} ${userLogged.lastName}`,
          room_code: chat.roomId,
          contactId: user.contactId || userRef.current.contactId,
          product_code,
          session_id: sessionId,
          customer_code: customerId,
        },
        unique: true,
      });
      sessionStorage.setItem("SESSION_ID", sessionId);
    }
  };

  const subscribeOnAttendance = ({ sessionId }, callback) => {
    const topic = webSocketClient.TOPICS.subscriptions.chatAttendanceResponse(
      chat.roomId,
      sessionId
    );
    if (!webSocketClient.isSubscribedOn(topic)) {
      webSocketClient.onConnect(() => {
        webSocketClient.subscribe(topic, (data) => {
          if (data.ticket_code) {
            getConversation(sessionId);
            getCurrentConversation(sessionId);
            const newQueueArray = chat.queue.filter((data) => {
              return data.sessionId !== sessionId;
            });
            if (data.ticket_code) {
              setChat({
                currentScreen: SCREEN_ENUM.conversation,
                queue: newQueueArray,
              });
            }
          }

          if (callback) callback(data);
        });
      });
    }
  };

  const fetchRoom = useCallback(async ({ id }) => {
    let responseRoom = await getRoom({ roomId: id });

    if (!responseRoom && !responseRoom?.items)
      return console.error(`Room ${id} not found!`);

    responseRoom = responseRoom.items ? responseRoom.items[0] : responseRoom;

    if (responseRoom.extraData) {
      const token = responseRoom.extraData.newAccessToken
        ? responseRoom.extraData.newAccessToken
        : "";
      changeAccessTokenStorage(token);
    }
    return {
      roomId: responseRoom.id || responseRoom.roomId,
      distributionProduct: responseRoom.productParam,
      roomName: responseRoom.roomName || "",
      roomDescription: responseRoom.roomDescription || "",
      initialMessage: responseRoom.initialMessage || "",
      initialMessageIntegration: responseRoom.initialMessageIntegration || "",
      roomOffMessage: responseRoom.roomOffMessage || "",
      messageRoomInactivity: responseRoom.messageRoomInactivity || "",
      messagemRoomDowntime: responseRoom.messagemRoomDowntime || "",
      productParam: responseRoom.productParam || false,
      alertTimeInactivity: responseRoom.alertTimeInactivity || "",
      downtime: responseRoom.downtime || "",
      alertNewCustomer: responseRoom.alertNewCustomer || "",
      tokenWhats: responseRoom.tokenWhats || "",
      integrationWppNumberId: responseRoom.integrationWppNumberId || "",
      whatsappFlagIntegration: responseRoom.whatsappFlagIntegration || "",
      whatsappAccountId: responseRoom.whatsappAccountId || "",
      roomAttendanceAmount: (responseRoom.maxChatCli || 0).toString(),
    };
  }, []);

  useEffect(() => {
    async function connectSocket() {
      try {
        if (companyUser?.isAccountant === "true") {
          webSocketClient.onConnect(() => {
            const topic = webSocketClient.TOPICS.subscriptions.roomStatus(
              chat.roomId
            );
            if (!webSocketClient.isSubscribedOn(topic)) {
              webSocketClient.subscribe(topic, (data) => {
                setSettings({
                  ...settings,
                  roomState: data.online === 1 ? "0" : "1",
                });
              });
            }
          });

          const responseRoom = await fetchRoom({ id: 1 });
          setChat(responseRoom);

          webSocketClient.onConnect((data) => {
            webSocketClient.send({
              topic: webSocketClient.TOPICS.emit.loginAttendant,
              message: {
                identifiers: {
                  sessionId: null,
                  attendantSocketClientId: data.socket.id,
                },
                company_code: company.id,
                company_alias: company.alias,
                attendantCode: user.id,
                action: "login",
                hash: data.socket.id,
                roomCode: chat.roomId,
                attendantName: user.name,
                statusAttendant: 0,
                lastUpdate: new Date().toISOString(),
              },
              unique: true,
            });
          });

          await fetchData({ company, user });
        }
      } catch (e) {
        console.error(e);
      }
    }

    if (company?.id && user?.id) {
      connectSocket();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company, user]);

  const getRoomStatus = useCallback(async () => {
    const findRoom = await statusRoom({
      roomId: chat.roomId,
      tokenCognito: localStorage.getItem("tokenCognitoPanel"),
      companyId: company.id,
      refreshToken: userLogged.cognito.RefreshToken,
    });

    if (findRoom?.rooms) {
      const { online, maxChatCli } = findRoom.rooms.find(
        (c) => c.id === chat.roomId
      );

      if (settings.attendanceAmount === null) {
        setSettings({ ...settings, attendanceAmount: maxChatCli.toString() });
      }

      return online ? "0" : "1";
    } else {
      return "0";
    }
  }, [chat.roomId, company.id, setSettings, settings, userLogged]);

  const getStateAttendant = useCallback(async () => {
    const findAttendantInfos = await infoAttendantInChat({
      roomId: chat.roomId,
      companyId: company.id,
      tokenCognito: localStorage.getItem("tokenCognitoPanel"),
      userId: user.id,
      refreshToken: userLogged.cognito.RefreshToken,
    });
    if (findAttendantInfos) {
      const { online, reason, attendanceAmount } = findAttendantInfos;
      return { myState: online ? "0" : reason.id, attendanceAmount };
    }
    return { myState: "0", attendanceAmount: 1 };
  }, [chat.roomId, company.id, user.id, userLogged.cognito.RefreshToken]);

  const saveStatus = useCallback(
    async ({ state }) => {
      if (settings?.myState) {
        const jsonBody = {
          room_id: chat.roomId,
          attendant_status: state === "0" ? 1 : 0,
          reason: parseInt(state),
          company_code: company.id,
        };
        const saveStatus = await postChatStatus({
          body: jsonBody,
          userId: user?.id,
          path: company?.urlSocket,
        });
        return saveStatus;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [chat.roomId, company.id, user?.id]
  );

  const getReasonsChat = useCallback(async () => {
    let arrReasons =
      (await reasonChat({
        companyId: company.id,
        tokenCognito: localStorage.getItem("tokenCognitoPanel"),
        refreshToken: userLogged.cognito.RefreshToken,
      })) || [];

    if (arrReasons) {
      arrReasons = arrReasons.map((reason) => {
        if (reason.active) {
          return {
            description: reason.description,
            state: reason.id,
          };
        } else {
          return false;
        }
      });
      arrReasons.push({
        description: "Disponível",
        state: "0",
      });
      arrReasons.sort((a, b) => {
        return a.state - b.state;
      });
      return arrReasons;
    }
    return [];
  }, [company.id, userLogged]);

  const openSettings = useCallback(
    async (screen) => {
      setChat({
        currentScreen: SCREEN_ENUM[screen],
        loadingMessage: "Carregando",
        isLoading: true,
      });

      const getDepartments = async () => {
        const departments = await productsOfAttendance({
          companyId: company.id,
          tokenCognito: localStorage.getItem("tokenCognitoPanel"),
          userId: user.id,
          refreshToken: userLogged.cognito.RefreshToken,
        });
        if (departments) {
          return departments;
        }
        return [];
      };

      const getRoomStatus = async () => {
        const findRoom = await statusRoom({
          roomId: chat.roomId,
          tokenCognito: localStorage.getItem("tokenCognitoPanel"),
          companyId: company.id,
          refreshToken: userLogged.cognito.RefreshToken,
        });
        if (findRoom) {
          const { online, maxChatCli } = findRoom?.rooms?.find(
            (c) => c.id === chat.roomId
          );

          if (settings.attendanceAmount === null) {
            setSettings({
              ...settings,
              attendanceAmount: maxChatCli.toString(),
            });
          }

          return online ? "0" : "1";
        } else {
          return "0";
        }
      };

      const getNotification = async () => {
        const allow = sessionStorage.getItem("AllowNotifyChat");
        const intensity = sessionStorage.getItem("IntensityNotifyChat");
        if (allow || intensity) {
          return {
            notificationState: allow === "0" ? 1 : 0,
            notificationIntensity: intensity || 1,
          };
        }
        return { notificationState: 0, notificationIntensity: 1 };
      };

      const departments = await getDepartments();
      const roomState = await getRoomStatus();
      const reason = await getReasonsChat();
      const { myState, attendanceAmount } = await getStateAttendant();
      const { notificationIntensity, notificationState } =
        await getNotification();

      Notification.requestPermission();
      let jsonSettings = {
        departments,
        roomState,
        reason,
        myState,
        notificationIntensity,
        notificationState,
      };
      if (attendanceAmount !== null) {
        jsonSettings.attendanceAmount = attendanceAmount;
      }

      setSettings(jsonSettings);

      setChat({ isLoading: false });
    },
    [
      SCREEN_ENUM,
      chat.roomId,
      company.id,
      getReasonsChat,
      setChat,
      setSettings,
      settings,
      user.id,
      userLogged,
      getStateAttendant,
    ]
  );

  const getAllAttendantsChatConected = useCallback(
    async ({ company: companyData, roomId }) => {
      const result = await getAttendantsChatConected({
        companyId: companyData?.id,
        roomId,
        path: company?.urlSocket,
      });

      if (result?.hasError || !result?.success) {
        notify("Não foi possível iniciar o Monitor", true, "warning");
        return {};
      }

      return result;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const subscribeOnAttendantResponse = useCallback(async () => {
    const topic = webSocketClient.TOPICS.subscriptions.onOffAtendantsResponse(
      company.id,
      chat.roomId,
      user.teamId
    );
    webSocketClient.onConnect(() => {
      if (!webSocketClient.isSubscribedOn(topic)) {
        webSocketClient.subscribe(topic, (data) => {
          if (
            Number.isInteger(data?.statusAttendant) &&
            +data?.attendant === +user.id
          ) {
            setSettings({ myState: data?.statusAttendant });
          }
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const subscribeOnChangeDataChatMonitor = useCallback(
    async ({ companyId, roomId }, setAttendants, setCurrentConversations) => {
      const topic = webSocketClient.TOPICS.subscriptions.chatMonitorResponse(
        companyId,
        roomId
      );
      webSocketClient.onConnect(() => {
        if (!webSocketClient.isSubscribedOn(topic)) {
          console.info(`Listening Chat Monitor...`);
          webSocketClient.subscribe(topic, (data) => {
            if (data) {
              if (data?.attendants) setAttendants(data?.attendants);
              if (data?.currentConversations)
                setCurrentConversations(data?.currentConversations);
            }
          });
        }
      });
    },
    [webSocketClient]
  );

  const subscribeOnCallCustumerToConversation = async (
    { customerId, companyId, roomId, contactId },
    callback
  ) => {
    const topic =
      webSocketClient.TOPICS.subscriptions.callCustumerToConversation({
        companyId,
        roomId,
        customerId,
        contactId,
      });

    webSocketClient.onConnect(() => {
      if (!webSocketClient.isSubscribedOn(topic)) {
        webSocketClient.subscribe(topic, async (data) => {
          const { sessionId, attendantId, productId } = data;
          if (sessionId) {
            if (companyUser?.isAccountant !== "true") {
              const conversation = {
                departmentId: productId,
                sessionId,
                status: "attendance",
                attendant_code: attendantId,
              };
              setChat({
                currentScreen: SCREEN_ENUM.customerLobbyWaiting,
                currentConversation: sessionId,
                conversations: [conversation],
              });

              conversation.sessionId = sessionId;
              subscribeOnAttendanceStart({ sessionId }, (data) => {
                if (data) onReceiveMessage(data);
              });
            }
            await fetchData({ company, user });

            sessionStorage.setItem("SESSION_ID", data?.sessionId);
            sessionStorage.setItem("SOCKET_ID", webSocketClient.socket.id);
          }
          if (callback) callback(data);
        });
      }
    }, [
      SCREEN_ENUM.customerLobbyWaiting,
      companyUser?.isAccountant,
      fetchData,
      getConversation,
      onReceiveMessage,
      setChat,
      subscribeOnAttendanceStart,
      webSocketClient,
    ]);
  };

  const validadeStageAttendance = useCallback(
    async ({ sessionId, socketId }) => {
      if (!sessionId || !socketId) return { success: false };
      return await getStageAttendance({
        sessionId,
        socketId,
        webSocketClient,
      });
    },
    [webSocketClient]
  );

  const verifyIfExistConversation = async () => {
    const { customerInAttendance } = await getCustomerConversation({
      webSocketClient,
      companyId: company.id,
      email: userLogged.email,
    });

    if (customerInAttendance[0]) {
      sessionStorage.setItem("SESSION_ID", customerInAttendance[0].session_id);
    }
  };

  const changeOpen = useCallback(
    async ({ isChatOpen: openChat }) => {
      if (!user.id || user.id === "")
        return notify("Não foi possível se conectar ao chat");

      if (!openChat) {
        return setChat({
          open: false,
        });
      }

      await verifyIfExistConversation();

      setChat({
        open: true,
        currentScreen: SCREEN_ENUM.loading,
        loadingMessage: "Conectando...",
        isLoading: true,
      });

      const sessionId = sessionStorage.getItem("SESSION_ID");
      const socketId = sessionStorage.getItem("SOCKET_ID");
      const isAccountant = companyUser?.isAccountant === "true";

      webSocketClient.onConnect(async (data) => {
        const topic = webSocketClient.TOPICS.subscriptions.chatStartResponse(
          data.socket.id
        );

        if (isAccountant) {
          await fetchData({ company: company.id, user: user?.id });
          if (chatRef.current.currentConversation) {
            openConversation(chatRef.current.currentConversation);
          }
          return setChat({ currentScreen: SCREEN_ENUM.conversation });
        }

        const response = await validadeStageAttendance({
          sessionId,
          socketId,
        });

        if (response?.success) {
          const {
            attendant_code,
            info_stage: { stage, queue },
            product_code,
          } = response;

          const newQueueArray = chat.queue.filter((data) => {
            return data.sessionId !== sessionId;
          });

          setChat({
            currentConversation: sessionId,
            conversations: [
              {
                departmentId: product_code,
                sessionId,
                status: "aguardando_atendimento",
                attendant_code,
              },
            ],
            queue: newQueueArray,
          });

          if (stage || queue) {
            const startProps = { sessionId, product_code };
            if (stage === "attendance") {
              startProps.infoStage = "attendance";
            }

            handleOnStart(startProps);
          }
        } else {
          sessionStorage.removeItem("SESSION_ID");
          setChat({ currentScreen: SCREEN_ENUM.customerLobby });
        }
        if (!webSocketClient.isSubscribedOn(topic)) {
          sendConnectCustomer();
          subscribeOnConnection();
          subscribeOnStart();
        }
      });
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      SCREEN_ENUM.conversation,
      SCREEN_ENUM.customerLobby,
      SCREEN_ENUM.loading,
      validadeStageAttendance,
      chat.queue,
      companyUser?.isAccountant,
      fetchData,
      handleOnStart,
      openConversation,
      sendConnectCustomer,
      setChat,
      subscribeOnConnection,
      subscribeOnStart,
      user.id,
      webSocketClient,
    ]
  );

  const renderInfosMonitor = useCallback(() => {
    setCompanyValidation(company?.id);
    setLoading(true);

    (async () => {
      try {
        subscribeOnChangeDataChatMonitor(
          {
            companyId: company?.id,
            roomId: 1,
          },
          setAttendants,
          setCurrentConversations
        );

        const data = await getAllAttendantsChatConected({
          company,
          roomId: 1,
        });
        if (data?.success) {
          setAttendants(data?.attendants);
          setCurrentConversations(data?.currentConversations);
        }
        if (!settings.departments)
          notify(
            "Não foi possível recuperar os departamentos",
            true,
            "warning"
          );
        if (!settings.reason)
          notify("Não foi possível recuperar os status", true, "warning");
      } catch (error) {
        notify(
          "Não foi possível recuperar os dados do monitor",
          true,
          "warning"
        );
        console.error(error);
      } finally {
        setLoading(false);
      }
    })();
  }, [
    company,
    getAllAttendantsChatConected,
    setAttendants,
    setCompanyValidation,
    setCurrentConversations,
    setLoading,
    settings.departments,
    settings.reason,
    subscribeOnChangeDataChatMonitor,
  ]);

  return {
    changeOpen,
    getConversation,
    openSettings,
    sendConnectCustomer,
    subscribeOnConnection,
    subscribeOnStart,
    getRoomStatus,
    subscribeOnCallCustumerToConversation,
    subscribeOnAttendantResponse,
    getAllAttendantsChatConected,
    subscribeOnChangeDataChatMonitor,
    saveStatus,
    getStateAttendant,
    getReasonsChat,
    renderInfosMonitor,
  };
}
