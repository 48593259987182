import { useContext, useEffect } from "react";

import { useHistory } from "react-router";
import { GlobalVariables } from "../../GlobalVariables";
import { usePermissions } from "../../utils/permissionsHook";

import { GlobalContext } from "../../contexts/GlobalContext";
import { notify } from "../../utils/notify";
import CustomerManagementWithBPO from "./children/CustomerManagementWithBPO";
import { DefaultPageContainer } from "../../components/layoutContainer/styles/LayoutContainer";
import { GlobalSubheader } from "../../components/subHeader/GlobalSubheader";
// import AccordionCard from "../attendance/childrens/AccordionCard";

export default function CustomerManagement({ location }) {
  const { companyUser, setBreadcrumbs } = useContext(GlobalContext);
  const { push } = useHistory();
  const { checkIfUserHasPermission } = usePermissions();

  useEffect(() => {
    if (companyUser.isAccountant !== "true") {
      return push("/dashboard/view");
    }
  }, [companyUser.isAccountant, push]);

  const handleClickAdd = () => {
    checkIfUserHasPermission(GlobalVariables.PERMISSIONS.CLIENTES_MANAGEMENT)
      ? push("create")
      : notify(
          `Acesso negado! Permissão 'Menus - Gestão de clientes - Gerenciamento' necessária`
        );
  };

  useEffect(() => {
    setBreadcrumbs([]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <GlobalSubheader title={"Gestão de Clientes"}></GlobalSubheader>
      <DefaultPageContainer
        style={{
          height: "calc(100% - 60px)",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <CustomerManagementWithBPO
          location={location}
          handleClickAdd={handleClickAdd}
        />
      </DefaultPageContainer>
    </>
  );
}
