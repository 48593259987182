import styled from "styled-components";
import { Typography, Box, Grid, Chip } from "@material-ui/core";

export const Description = styled.div`
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  margin-left: 20px;
  margin-right: 20px;
`;

// border-top: 1px solid rgba(0, 0, 0, 0.23);
export const Certificate = styled.div`
  padding: 24px;

  gap: 16px;
`;

export const AttachmentName = styled.div`
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  margin-bottom: 20px;
`;

export const CertificateModalTitle = styled(Typography)`
    font-weight: bold;
    background: rgb(19, 142, 247);
    border-top-left=radius: 10px
    border-top-right-radius: 10px;
    padding: 20px;
    color: white;
    margin-bottom: 10px
`;

export const DialogBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  background-color: #fff;
  box-shadow: 24px;
  border-radius: 10px;
`;

export const BodyBox = styled(Box)`
    max-height: 400px;
    min-height: ${
      localStorage.getItem("isAccountantPanel") === "true" ? "180px" : "130px"
    };
    overflow-y: auto;
    overflow-x: hidden;
    margin-bottom: 10px
    margin-top: 10px;
`;

export const QuestionIcon = styled.span`
  border: 1px solid rgb(19, 142, 247);
  color: rgb(19, 142, 247);
  border-radius: 15px;
  font-weight: bold;
  font-size: 10px;
  padding: 0px 4px;
`;

export const DivBodyDescription = styled.div`
  padding-right: 24px;
  padding-left: 24px;
  padding-bottom: 10px;
`;

export const GridStyled = styled(Grid)`
  float: right;
  padding-right: 24px;
  padding-bottom: 24px;
  padding-top: 10px;
`;

export const ChipStyled = styled(Chip)`
  background-color: ${(props) =>
    props.selected ? "#0036B3" : "#F0F1F3"} !important;
  color: ${(props) => (props.selected ? "#FBFBFD" : "#364461")} !important;
  transition: all 0.3s;
  ${(props) => {
    return props.selected
      ? `
    &.hover{
      background-color: ##E7EEFE !important;
      color: ##0036B3 !important;
    }
  `
      : ``;
  }}
`;
