import { search } from "../BaseRepositoryComponents";

const newPath = `${process.env.REACT_APP_NEW_PAINEL_API_URL}`;

export async function getWidgets(idUser, idEntity) {
  return search({
    path: `${newPath}/v1/widgets/${idUser}/${idEntity}/list`,
    method: "GET",
  });
}
