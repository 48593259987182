import { MD5 } from "crypto-js";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { boletoExists, createBoletoRegister } from "../../../repositories/Bot";
import {
  getDescription,
  isContabil,
  isFiscal,
  isFolha,
} from "../../../utils/DocumentUtil";
import { notify } from "../../../utils/notify";
import { isAccountant, isCompany } from "../../../utils/panelType";
import { createDocumentEmitted } from "./DocumentEmitted";
import { getEnvironment } from "../../../utils/environment";
import { sendHubDocuments } from "./DocumentModal";
import { useContext } from "react";
import { GlobalContext } from "../../../contexts/GlobalContext";
import { getUsersByEntityId } from "../../../repositories/portal_api/Users";

export default function useDocumentModalController() {
  const { companyUser } = useContext(GlobalContext);

  let mainPayload = {};

  const uploadDocument = (object) => {
    let accountantCnpj =
      localStorage.getItem("isAccountantPanel") === "true"
        ? localStorage.getItem("documentPanel")
        : localStorage.getItem("accountantCnpjPanel");

    if (isCompany() === true)
      accountantCnpj = localStorage.getItem("documentPanel");

    let environment = getEnvironment();

    let extraData = {
      uiMessage: `Upload de ${getDocumentTypeExibition(
        object.data.document_type
      )} ${object.name} sendo realizado!`,
      mainCompanyCnpj: localStorage.getItem("mainEntityCnpjPanel"),
      parentCnpj: accountantCnpj,
      accountantCnpj: accountantCnpj,
      sub: localStorage.getItem("subCognitoPanel"),
      createAccess: isCompany() === true ? false : true,
      env: environment,
    };

    let payload = {
      cnpj: localStorage.getItem("documentPanel"),
      fileName:
        object.use_suggestion === true ? object.name_suggestion : object.name,
    };

    if (object.data.document_type === "BOLETO") {
      delete object.data.error;

      object.data.base64Md5 = MD5(object.data.base64).toString();
      object.data.data.base64 = object.data.base64;
      extraData.sendToPay = object.sendToPay;

      extraData.clientCnpj =
        object.data.data.drawee.taxIdentifier.taxId.replace(/\D/g, "");
      extraData.cnpj = object.data.data.drawee.taxIdentifier.taxId.replace(
        /\D/g,
        ""
      );

      payload.data = object.data.data;
      payload.data.document_type = object.data.document_type;
    } else {
      if (object.data.document_type === "NOT_IDENTIFIED") {
        extraData.clientCnpj = object.data.govid;
        payload.base64 = object.base64;
      } else {
        extraData.clientCnpj = object.data.govid;
        extraData.sendToPay = object.sendToPay;
      }

      extraData.cnpj = object.data.govid;
      payload.data = object.data;
    }

    return {
      cnpj: localStorage.getItem("documentPanel"),
      fileName:
        object.use_suggestion === true ? object.name_suggestion : object.name,
      docData: payload.data,
      sub: localStorage.getItem("subCognitoPanel"),
      fileUploadMessage: `Upload de ${getDocumentTypeExibition(
        object.data.document_type
      )} ${object.name} sendo realizado!`,
      sendToPay: extraData.sendToPay,
    };
  };

  const getDocumentTypeExibition = (type) => {
    if (type === "DCTF_RETURN") return "Retorno DCTF";
    else if (type === "NOT_IDENTIFIED") return "Arquivo";
    else return type;
  };

  const getData = async (
    documentList,
    setValue,
    setUsers,
    companyUserDocument
  ) => {
    let values = [];
    setTimeout(() => {
      for (let i = 0; i < documentList.length; i++) {
        values.push(1);
      }

      setValue(values);
    }, 1);

    const entityUsers = await getUsersByEntityId(companyUser.document);

    let users = [];
    if (entityUsers.code === 200) {
      users = entityUsers.data;
      setUsers(users);
    }

    for (let document of documentList) {
      if (users.length !== 0) {
        document.generateTicketEmail = {
          label: users[0].email,
          email: users[0].email,
        };
      }

      if (
        document.data.document_type === "NOT_IDENTIFIED" &&
        companyUserDocument.isAccountant === ""
      ) {
        document.client = {
          document: companyUserDocument.document,
        };
      }

      document.boletoExists = await boletoExists(document.data.typeable_line);
    }
  };

  const hasAreaChecked = (document) => {
    return (
      document.contabil ||
      document.fiscal ||
      document.folha ||
      document.outros ||
      document.contratoSocial ||
      document.contratoTrabalho
    );
  };

  const getAreasChecked = (document) => {
    const areas = [];

    if (document.contabil === true) {
      areas.push("contabil");
    }

    if (document.fiscal === true) {
      areas.push("fiscal");
    }

    if (document.folha === true) {
      areas.push("folha");
    }

    if (document.outros === true) {
      areas.push("outros");
    }

    if (document.contratoSocial === true) {
      areas.push("contratoSocial");
    }

    if (document.contratoTrabalho === true) {
      areas.push("contratoTrabalho");
    }

    return areas;
  };

  const cleanCheckBoxesArea = (document) => {
    document.contabil = false;
    document.fiscal = false;
    document.folha = false;
    document.outros = false;
    document.contratoSocial = false;
    document.contratoTrabalho = false;
  };

  const handleUploadDocument = async (
    document,
    props,
    index,
    setDisabled,
    setKey
  ) => {
    setDisabled(true);

    if (document.data.document_type === "DARF") {
      document.data.total_amount = document.data.total_amount.replaceAll(
        "R$ ",
        ""
      );
      document.data.penalty_amount = document.data.penalty_amount.replaceAll(
        "R$ ",
        ""
      );
      document.data.principal_amount =
        document.data.principal_amount.replaceAll("R$ ", "");
      document.data.tax_amount = document.data.tax_amount.replaceAll("R$ ", "");

      document.data.govid = document.data.govid.replace(/[^\d]/g, "");

      mainPayload.documentUpload = uploadDocument(document);
    }

    if (document.data.document_type === "BOLETO") {
      let val = document.data.data.paymentDetails.totalAmount
        .toString()
        .replaceAll("R$ ", "");
      document.data.data.paymentDetails.totalAmount = val;
      document.data.data.paymentDetails.consolidatedAmount = val;

      await createBoletoRegister({
        boleto_upload_id: uuidv4(),
        typeable_line: document.data.typeable_line,
        govid: localStorage.getItem("documentPanel"),
      });

      mainPayload.documentUpload = uploadDocument(document);
    }

    if (document.data.document_type === "DAS") {
      document.data.total_amount = document.data.total_amount.replaceAll(
        "R$ ",
        ""
      );
      document.data.govid = document.data.govid.replace(/[^\d]/g, "");

      mainPayload.documentUpload = uploadDocument(document);
    }

    if (document.data.document_type === "FGTS") {
      if (document.data.period.length < 7) {
        setDisabled(false);
        notify("Por favor, insira um período válido para o documento");
        return;
      }

      mainPayload.documentUpload = uploadDocument(document);
    }

    if (document.data.document_type === "DCTF_RETURN") {
      if (document.data.period.length < 7) {
        setDisabled(false);
        notify("Por favor, insira um período válido para o documento");
        return;
      }
      try {
        document.data.debit_credit_list.forEach((element, i) => {
          if (element.period.trim() !== "") {
            if (element.period.length < 7) {
              throw i;
            }
          }
        });
      } catch (err) {
        setDisabled(false);
        notify(
          `Por favor, insira um período válido para o item ${
            err + 1
          } da lista de débitos apurados`
        );
        return;
      }

      mainPayload.documentUpload = uploadDocument(document);
    }

    if (document.data.document_type === "NOT_IDENTIFIED") {
      if (document.client === undefined) {
        notify(
          "Antes de realizar o envio deste documento você deve selecionar o cliente que receberá este PDF!"
        );

        setDisabled(false);
        return;
      }

      if (
        isAccountant() === true &&
        document.data?.govid?.length !== 11 &&
        document.data?.govid?.length !== 14
      ) {
        notify(
          "CPF/CNPJ do cliente inválido! Verifique se o documento foi digitado corretamente"
        );

        setDisabled(false);
        return;
      }
    }

    if (document.data.document_type === "NOT_IDENTIFIED" && document.client) {
      mainPayload.documentUpload = uploadDocument(document);
    }

    if (
      document.data.document_type === "NOT_IDENTIFIED" &&
      hasAreaChecked(document) &&
      document.client
    ) {
      let areas = getAreasChecked(document);
      mainPayload.foldersCheckbox = areas;

      for (let area of areas) {
        let body = {
          departament: area, // contabil, fiscal, folha
          docTypeFile: document.doc_type,
          type: "nao_reconhecido", // diario, razao, das, etc...
          documentName: document.name,
          period: null,
          expirationDate: null,
          amount: null,
          pdfBase64: document.base64,
          imageBase64: document.data.base64,
          keyDocument: false,
        };

        let formatedBody = createDocumentEmitted(
          body,
          isAccountant() === true
            ? document.data.govid
            : localStorage.getItem("documentPanel"),
          document.openTicketInCRM,
          document.generateTicketEmail ? document.generateTicketEmail.email : ""
        );
        mainPayload.documentEmited = formatedBody;
      }
    }

    if (
      document.data.document_type !== "NOT_IDENTIFIED" &&
      hasAreaChecked(document)
    ) {
      let areas = getAreasChecked(document);
      mainPayload.foldersCheckbox = areas;

      for (let area of areas) {
        let body = {};

        if (document.data.document_type !== "BOLETO") {
          body = {
            departament: area, // contabil, fiscal, folha
            docTypeFile: document.doc_type,
            type: document.data.document_type.toLowerCase(), // diario, razao, das, etc...
            documentName:
              document.use_suggestion === true
                ? document.name_suggestion
                : document.name,
            period: document.data.period,
            expirationDate: document.data.expire_date,
            amount: document.data.total_amount,
            pdfBase64: document.base64,
            imageBase64: document.data.base64,
            keyDocument: document.data.document_type,
          };

          if (document.data.document_type === "DCTF_RETURN") {
            body.amount = null;
            body.expirationDate = null;
          }

          if (body.amount) {
            if (typeof body.amount !== "number")
              body.amount.replace(".", "").replace(",", ".");
          }

          if (body.expirationDate !== null) {
            if (!body.expirationDate.includes("-")) {
              body.expirationDate = moment(
                body.expirationDate,
                "DD/MM/YYYY"
              ).format("YYYY-MM-DD");
            }
          }

          if (body.period.length > 7)
            body.period = moment(body.period, "DD/MM/YYYY").format("YYYY-MM");
        } else {
          body = {
            departament: area, // contabil, fiscal, folha
            docTypeFile: document.doc_type,
            type: "boleto", // diario, razao, das, etc...
            documentName: document.name,
            typeableLine: document.data.typeable_line,
            expirationDate: document.data.data.paymentDetails.dueDate,
            amount: document.data.data.paymentDetails.consolidatedAmount,
            pdfBase64: document.base64,
            imageBase64: document.data.base64,
            keyDocument: document.data.document_type,
          };
        }

        let formatedBody = createDocumentEmitted(
          body,
          isAccountant() === true
            ? document.data.govid
            : localStorage.getItem("documentPanel"),
          document.openTicketInCRM,
          document.generateTicketEmail ? document.generateTicketEmail.email : ""
        );

        mainPayload.documentEmited = formatedBody;
      }
    }

    let responseDocument = await sendHubDocuments(mainPayload);

    if (responseDocument?.success) {
      notify("Estamos processando esse documento!", true, "success");

      setDisabled(false);

      props.documentList.splice(index, 1);

      if (props.documentList.length === 0) {
        props.closeDocumentModal();
      }

      setKey(Math.random());
    } else {
      notify(
        "Ocorreu um erro ao enviar o documento, tente novamente!",
        true,
        "error"
      );
      setDisabled(false);
    }
  };

  const handleSetupDocument = (
    documentList,
    setValue,
    setUsers,
    companyUser,
    setTypeFileState
  ) => {
    getData(documentList, setValue, setUsers, companyUser);
    for (let document of documentList) {
      let isFirstElement = false;

      if (document === documentList[0]) {
        isFirstElement = true;
      }

      document.contabil = false;
      document.folha = false;
      document.fiscal = false;
      document.outros = true;
      document.contratoTrabalho = false;
      document.contratoSocial = false;
      document.openTicketInCRM = false;

      document.doc_type = "doc";
      document.use_suggestion = true;

      let docType =
        document.data.document_type === "NOT_IDENTIFIED"
          ? "DOCUMENTO"
          : document.data.document_type;
      let descriptor = "";
      let date = "";

      if (document.data.document_type === "DARF") {
        document.outros = false;
        document.doc_type = "guide";

        descriptor = getDescription(document.data.code_receita).slice(0, 20);
        date = document.data.expire_date;

        try {
          if (date === "") {
            date = document.data.period;
          }

          if (date === "") {
            date = moment().format("DD/MM/YYYY");
          }
        } catch (err) {
          console.error(err);
        }

        try {
          document.data.total_amount =
            document.data.total_amount.toLocaleString("pt-br", {
              minimumFractionDigits: 2,
            });
          document.data.tax_amount = document.data.tax_amount.toLocaleString(
            "pt-br",
            { minimumFractionDigits: 2 }
          );
          document.data.principal_amount =
            document.data.principal_amount.toLocaleString("pt-br", {
              minimumFractionDigits: 2,
            });
          document.data.penalty_amount =
            document.data.penalty_amount.toLocaleString("pt-br", {
              minimumFractionDigits: 2,
            });
        } catch (err) {
          console.error(err);
        }
      }

      if (document.data.document_type === "DAS") {
        document.outros = false;
        document.doc_type = "guide";
        date = document.data.expire_date;

        try {
          if (date === "") {
            date = document.data.period;
          }

          if (date === "") {
            date = moment().format("DD/MM/YYYY");
          }
        } catch (err) {
          console.error(err);
        }

        try {
          document.data.total_amount =
            document.data.total_amount.toLocaleString("pt-br", {
              minimumFractionDigits: 2,
            });
        } catch (err) {
          console.error(err);
        }
      }

      if (document.data.document_type === "BOLETO") {
        document.outros = false;
        document.doc_type = "guide";
        document.data.data.drawee.taxIdentifier.taxId =
          document.data.data.drawee.taxIdentifier.taxId.replace(/\D/g, "");
        document.data.data.beneficiary.taxIdentifier.taxId =
          document.data.data.beneficiary.taxIdentifier.taxId.replace(/\D/g, "");

        if (!document.data.data.paymentDetails.dueDate) {
          date = moment().format("DD/MM/YYYY");
        } else {
          date = moment(document.data.data.paymentDetails.dueDate).format(
            "DD/MM/YYYY"
          );
        }

        descriptor = document.data.data.beneficiary.name;

        try {
          document.data.data.paymentDetails.totalAmount =
            document.data.data.paymentDetails.totalAmount.toLocaleString(
              "pt-br",
              { minimumFractionDigits: 2 }
            );
          document.data.data.paymentDetails.consolidatedAmount =
            document.data.data.paymentDetails.consolidatedAmount.toLocaleString(
              "pt-br",
              { minimumFractionDigits: 2 }
            );
        } catch (err) {
          console.error(err);
        }
      }

      if (document.data.document_type === "FGTS") {
        document.outros = false;
        document.doc_type = "guide";
        date = moment(document.data.expire_date).format("DD/MM/YYYY");

        if (date === undefined || date === null || date.trim() === "")
          date = moment().format("DD/MM/YYYY");
        descriptor = document.data.company_name;
      }

      if (document.data.document_type === "DCTF_RETURN") {
        document.outros = false;
        document.fiscal = true;
        document.doc_type = "report";
        if (
          document.data.date_transmission === undefined ||
          document.data.date_transmission === null ||
          document.data.date_transmission.trim() === ""
        ) {
          date = moment().format("DD/MM/YYYY");
        } else {
          date = moment(document.data.date_transmission).format("DD/MM/YYYY");
        }
      }
      document.name_suggestion =
        date + " - " + docType + " " + descriptor.trim() + ".pdf";

      if (document.data.document_type === "DAS") {
        document.fiscal = true;
      }

      if (document.data.code_receita) {
        document.contabil = isContabil(document.data.code_receita);

        document.folha = isFolha(document.data.code_receita);

        document.fiscal = isFiscal(document.data.code_receita);
      }
      if (isFirstElement) {
        setTypeFileState(document.doc_type);
      }
    }
  };

  const verifyAndChangeGovidMask = (value, setMask) => {
    if (
      value.replace(/[^\d]/g, "").length <= 11 &&
      value.replace(/[^\d]/g, "").length > 0
    ) {
      setMask("999.999.999-999");
    } else {
      setMask("99.999.999/9999-99");
    }
  };

  return {
    uploadDocument,
    cleanCheckBoxesArea,
    getAreasChecked,
    hasAreaChecked,
    handleUploadDocument,
    handleSetupDocument,
    getDocumentTypeExibition,
    verifyAndChangeGovidMask,
  };
}
