import { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router";
import Grid from "@material-ui/core/Grid";
import { LabelStyled, Pulse } from "../../styles/GlobalStyle";
import { notify } from "../../utils/notify";
import IconsMenu from "./IconsMenu";
import {
  Beta,
  BetaBadge,
  IconContainer,
  LabelStyledSubMenu,
  ListItemCustom,
  ListItemIconCustom,
  SideLabel,
} from "./styles/Sidebar";
import {
  ExpandLessStyled,
  ExpandMoreStyled,
  BetaChip,
} from "../sidebarDrawer/styles/SidebarDrawer";
import { TooltipContainer } from "./TooltipContainer";
import { Menu, MenuList, makeStyles } from "@material-ui/core";

export const MenuItemPortal = ({
  sidebar,
  companyAccess,
  label,
  beta,
  icon: Icon,
  path,
  pathExternal,
  SubMenu,
  functionMenu,
  baseUrl,
  forceRedirect,
  blur,
  optionSubMenus,
  standard,
  customTooltip = false,
  value,
  hasASubMenu,
  isBeta,
  setSidebar,
  shouldPulse = false,
  ...props
}) => {
  const useStyles = makeStyles({
    popOverRoot: {
      pointerEvents: "none",
    },
  });

  const [isPulsing, setIsPulsing] = useState(shouldPulse);

  const location = useLocation();

  const { push } = useHistory();
  const [dropDownMenu, setDropDownMenu] = useState(true);

  const handleRedirect = (path, pathExternal) => {
    if (path) {
      push(path);
    } else if (pathExternal) {
      window.open(pathExternal, "_blank");
    }
  };

  useEffect(() => {
    if (!sidebar.open) {
      setDropDownMenu(false);
    }
  }, [sidebar.open]);

  const clickDropdownMenu = (baseUrl, pathExternal, path) => {
    if (!dropDownMenu) {
      setSidebar((current) => ({ ...current, open: true }));
    }

    setDropDownMenu(!dropDownMenu);
  };

  const ableToAccess = (companyAccess || standard || forceRedirect) && !blur;

  const [anchorEl, setAnchorEl] = useState(null);
  const styles = useStyles();
  let currentlyHovering = false;

  function handleHoverParent(event) {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  }

  function handleHover() {
    currentlyHovering = true;
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleCloseHover() {
    currentlyHovering = false;
    setTimeout(() => {
      if (!currentlyHovering) {
        handleClose();
      }
    }, 50);
  }

  const clickMenu = location.pathname === path;
  return (
    <>
      <div
        onMouseEnter={() => {
          setIsPulsing(false);
        }}
      >
        <TooltipContainer
          open={Boolean(anchorEl) && !hasASubMenu}
          key={label}
          title={customTooltip ? customTooltip : label}
          sidebar={sidebar}
          customTooltip={!!customTooltip}
          beta={beta}
        >
          <div style={{ marginLeft: 5, marginRight: 5 }}>
            <Pulse active={isPulsing}>
              <ListItemCustom
                style={{
                  backgroundColor: "",
                  color:
                    !value?.hasPermission && ableToAccess
                      ? "#424c5d"
                      : "#caced1",
                }}
                onMouseOver={handleHoverParent}
                onMouseLeave={handleCloseHover}
                button
                onClick={() => {
                  if (
                    value === "CRM" ||
                    value === "ACCOUNTING" ||
                    value === "RADAR"
                  ) {
                    clickDropdownMenu(baseUrl, pathExternal, path);
                  } else {
                    if (functionMenu) {
                      functionMenu(`${baseUrl}${pathExternal}`);
                      return;
                    }
                    if (ableToAccess) {
                      if (forceRedirect)
                        handleRedirect(path, `${pathExternal}`);
                      else
                        handleRedirect(path, `${baseUrl || ""}${pathExternal}`);
                    } else
                      return notify(
                        !customTooltip
                          ? "Entre em contato com o nosso comercial: 0800 591 0486."
                          : "Acesso não liberado."
                      );
                  }
                }}
                active={ableToAccess}
              >
                <ListItemIconCustom
                  style={{
                    width: sidebar.open ? "auto" : "100%",
                  }}
                >
                  <IconContainer
                    item
                    container
                    justifyContent="center"
                    style={{ padding: !sidebar.open ? 10 : 0 }}
                  >
                    <IconsMenu
                      color={ableToAccess ? "#063bb5" : "#c3c8cb"}
                      icon={Icon}
                    />
                    {!sidebar.open && beta && <BetaBadge children="BETA" />}
                  </IconContainer>
                </ListItemIconCustom>

                {sidebar.open && (
                  <SideLabel
                    primary={
                      <Grid container alignItems="center">
                        <Grid item flex={1}>
                          <Beta
                            color="secondary"
                            badgeContent={beta && "HOMOLOGAÇÃO"}
                          >
                            <LabelStyled fontSize="13" cursor="pointer">
                              {label}
                            </LabelStyled>
                          </Beta>
                        </Grid>
                      </Grid>
                    }
                  />
                )}
                {isBeta && (
                  <BetaChip
                    label="Beta"
                    style={{ position: "relative", left: 20 }}
                  />
                )}

                {hasASubMenu &&
                  (dropDownMenu ? (
                    <ExpandLessStyled clickMenu={clickMenu} />
                  ) : (
                    <ExpandMoreStyled clickMenu={clickMenu} />
                  ))}
              </ListItemCustom>
            </Pulse>

            {hasASubMenu && !sidebar?.open && (
              <Menu
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "center", horizontal: "right" }}
                MenuListProps={{
                  onMouseEnter: handleHover,
                  onMouseLeave: handleCloseHover,
                  style: { pointerEvents: "auto" },
                }}
                PopoverClasses={{
                  root: styles.popOverRoot,
                }}
              >
                <MenuList>
                  <div
                    style={{
                      margin: 10,
                      minWidth: 200,
                    }}
                  >
                    <div
                      style={{
                        color: "#0036B3",
                        fontWeight: 700,
                        fontSize: 14,
                      }}
                    >
                      {value === "ACCOUNTING"
                        ? "Solução contábil"
                        : value === "RADAR"
                        ? "Radar"
                        : value === "CLIENT_MANAGEMENT"
                        ? "Gestão de clientes"
                        : ""}
                    </div>
                    <hr />
                    {value === "ACCOUNTING" &&
                      optionSubMenus["ACCOUNTING"].map((value) => (
                        <ListItemCustom
                          subitem
                          style={{
                            marginLeft: 0,
                            padding: "1px 0px 1px 0px",
                            marginBottom: 5,
                          }}
                          button
                          onClick={() => {
                            if (value.companyAccess) {
                              if (value.forceRedirect) {
                                value.functionMenu(
                                  `${value.baseUrl}${value.pathExternal}`
                                );
                              } else {
                                value.functionMenu(
                                  `${value.baseUrl}${value.pathExternal}`
                                );
                              }
                            } else {
                              return notify(
                                !value.customTooltip
                                  ? "Entre em contato com o nosso comercial: 0800 591 0486."
                                  : "Acesso não liberado."
                              );
                            }
                          }}
                          active={ableToAccess}
                        >
                          <SideLabel
                            style={{
                              marginLeft: 5,
                              // display: sidebar.open ? "flex" : "none",
                            }}
                            primary={
                              <Grid container alignItems="center">
                                <Grid item flex={1}>
                                  <Beta
                                    color="secondary"
                                    badgeContent={value.beta && "HOMOLOGAÇÃO"}
                                  >
                                    <LabelStyledSubMenu
                                      fontSize="13"
                                      cursor="pointer"
                                      color={
                                        !value.blur ? "#424c5d" : "#c3c8cb"
                                      }
                                    >
                                      {value.label}
                                    </LabelStyledSubMenu>
                                  </Beta>
                                </Grid>
                              </Grid>
                            }
                          />

                          {value.isBeta && <BetaChip label="Beta" />}
                        </ListItemCustom>
                      ))}

                    {value === "RADAR" &&
                      optionSubMenus["RADAR"].map((value) => (
                        <ListItemCustom
                          subitem
                          style={{
                            marginLeft: 0,
                            padding: "1px 0px 1px 0px",
                            marginBottom: 5,
                            display: value.hide ? "none" : "flex",
                          }}
                          button
                          onClick={() => {
                            if (value?.hasPermission) {
                              value.functionMenu();
                            } else {
                              return notify(
                                !value.customTooltip
                                  ? "Entre em contato com o nosso comercial: 0800 591 0486."
                                  : "Acesso não liberado."
                              );
                            }
                          }}
                        >
                          <SideLabel
                            style={{ marginLeft: 5 }}
                            primary={
                              <Grid container alignItems="center">
                                <Grid item flex={1}>
                                  <Beta
                                    color="secondary"
                                    badgeContent={value.beta && "HOMOLOGAÇÃO"}
                                  >
                                    <LabelStyledSubMenu
                                      fontSize="13"
                                      cursor="pointer"
                                      color={
                                        !value.blur ? "#424c5d" : "#c3c8cb"
                                      }
                                    >
                                      {value.label}
                                    </LabelStyledSubMenu>
                                  </Beta>
                                </Grid>
                              </Grid>
                            }
                          />

                          {value.isBeta && <BetaChip label="Beta" />}
                        </ListItemCustom>
                      ))}
                  </div>
                </MenuList>
              </Menu>
            )}
          </div>
        </TooltipContainer>

        {value === "ACCOUNTING" &&
          optionSubMenus["ACCOUNTING"].map((subValue) => (
            <TooltipContainer
              key={subValue.label}
              title={
                subValue.customTooltip ? subValue.customTooltip : subValue.label
              }
              sidebar={value.sidebar}
              style={{ display: sidebar.open ? "block" : "none" }}
              customTooltip={subValue.customTooltip}
              beta={subValue.beta}
            >
              <ListItemCustom
                subitem
                style={{
                  marginLeft: 0,
                  padding: "1px 0px 1px 0px",
                  marginBottom: 5,
                  display: subValue.hide
                    ? "none"
                    : dropDownMenu
                    ? "flex"
                    : "none",
                }}
                button
                onClick={() => {
                  if (subValue.companyAccess) {
                    if (subValue.forceRedirect) {
                      subValue.functionMenu(
                        `${subValue.baseUrl}${subValue.pathExternal}`
                      );
                    } else {
                      subValue.functionMenu(
                        `${subValue.baseUrl}${subValue.pathExternal}`
                      );
                    }
                  } else {
                    return notify(
                      !subValue.customTooltip
                        ? "Entre em contato com o nosso comercial: 0800 591 0486."
                        : "Acesso não liberado."
                    );
                  }
                }}
                active={ableToAccess}
              >
                <SideLabel
                  style={{
                    display: sidebar.open ? "flex" : "none",
                    marginLeft: 61,
                  }}
                  primary={
                    <Grid container alignItems="center">
                      <Grid item flex={1}>
                        <Beta
                          color="secondary"
                          badgeContent={subValue.beta && "HOMOLOGAÇÃO"}
                        >
                          <LabelStyledSubMenu
                            fontSize="13"
                            cursor="pointer"
                            color={!subValue.blur ? "#424c5d" : "#c3c8cb"}
                          >
                            {subValue.label}
                          </LabelStyledSubMenu>
                        </Beta>
                      </Grid>
                    </Grid>
                  }
                />

                {subValue.isBeta && <BetaChip label="Beta" />}
              </ListItemCustom>
            </TooltipContainer>
          ))}

        {value === "RADAR" &&
          optionSubMenus["RADAR"].map((value) => (
            <TooltipContainer
              key={value.label}
              title={value.customTooltip ? value.customTooltip : value.label}
              sidebar={value.sidebar}
              style={{ display: sidebar.open ? "block" : "none" }}
              customTooltip={!!value.customTooltip}
              beta={value.beta}
            >
              <ListItemCustom
                subitem
                style={{
                  marginLeft: 0,
                  padding: "1px 0px 1px 0px",
                  marginBottom: 5,
                  display: value.hide ? "none" : dropDownMenu ? "flex" : "none",
                }}
                button
                onClick={() => {
                  if (value?.hasPermission) {
                    value.functionMenu();
                  } else {
                    return notify(
                      !value.customTooltip
                        ? "Entre em contato com o nosso comercial: 0800 591 0486."
                        : "Acesso não liberado."
                    );
                  }
                }}
              >
                <SideLabel
                  style={{
                    display: sidebar.open ? "flex" : "none",
                    marginLeft: 61,
                  }}
                  primary={
                    <Grid container alignItems="center">
                      <Grid item flex={1}>
                        <Beta
                          color="secondary"
                          badgeContent={value.beta && "HOMOLOGAÇÃO"}
                        >
                          <LabelStyledSubMenu
                            fontSize="13"
                            cursor="pointer"
                            color={!value.blur ? "#424c5d" : "#c3c8cb"}
                          >
                            {value.label}
                          </LabelStyledSubMenu>
                        </Beta>
                      </Grid>
                    </Grid>
                  }
                />

                {value.isBeta && <BetaChip label="Beta" />}
              </ListItemCustom>
            </TooltipContainer>
          ))}
      </div>
    </>
  );
};
