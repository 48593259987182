import filesize from "filesize";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
// import HTMLEditor from 'mui-rte';
// Import Icons from material ui
import Skeleton from "@material-ui/lab/Skeleton";
import Loading from "../../../components/basics/Loading";

import InteractionHistoric from "./InteractionHistoric";

// Import components from material ui
import { Box, Divider, Grid, TextField } from "@material-ui/core";

import HTMLEditor from "../childrens/HTMLEditor";

import Button from "../../../components/buttons/Btn";
import ModalAttachment from "../../../utils/ModalAttachment";
import { notify } from "../../../utils/notify";
import AutoCompleteCRM from "../childrens/AutoCompleteCRM";
import "../styles/Attendance.css";
import AccordionCard from "./../childrens/AccordionCard";
import GridCard from "./../childrens/GridCard";
import ModalSave from "./../childrens/ModalSave";
import ModalRoomOn from "./ModalRoomOn";
import ModalTransfer from "./ModalTransfer";

import { useAuth } from "../../../contexts/crm/Auth";
import { GlobalContext } from "../../../contexts/GlobalContext";
import {
  getAttendance,
  getClassification,
  getMessages,
  getSituation,
} from "../../../repositories/integrations/crm/AttendanceRepository";
import { getCustomers } from "../../../repositories/integrations/crm/CustomerRepository";
import { getFilesS3 } from "../../../repositories/integrations/crm/FilesRepository";
import { getUser } from "../../../repositories/integrations/crm/UserRepository";
import { Margin, Title } from "../../../styles/GlobalStyle";
import { useAttendance } from "../contexts/AttendanceContext";
import useAttendanceController from "../controllers/AttendanceController";
import ListFiles from "./ListFiles";
// Import external lib

// Import styles

export default function AttendanceData({ typeScreen, attendanceId, ...props }) {
  const {
    situations,
    customers,
    departaments,
    classifications,
    errors,
    users,
    setUsersState,
    setClassificationsState,
    setDepartamentsState,
    setCustomersState,
    setSituationsState,
    loading,
    setLoading,
  } = useAttendance();

  const { user, company } = useAuth();

  const { handleSave, handleFieldValue, fetchDepartaments } =
    useAttendanceController();

  const { companyUser } = useContext(GlobalContext);

  const [departament, setDepartamentState] = useState("");
  const [subject, setSubjectState] = useState("");
  const [request, setRequestState] = useState("");
  const [customer, setCustomerState] = useState("");
  const [classification, setClassificationState] = useState("");
  const [situation, setSituationState] = useState("");
  const [switchAccordion, setSwitchAccordion] = useState(false);
  const [stateModalGravar, setStateModalGravar] = useState(false);
  const [stateModalTransfer, setStateModalTransfer] = useState(false);
  const [stateCheckBoxEmail, setStateCheckBoxEmail] = useState({
    typeEmail: "",
    active: false,
  });
  const [finishState, setFinishState] = useState(false);
  const [modalAction, setModalAction] = useState("cancel");
  const [idTransfer, setIdTransfer] = useState(null);
  const [flagMessagePrivate, setFlagMessagePrivate] = useState(0);
  const [messages, setMessages] = useState([]);
  const [loadingSave, setLoadingSave] = useState(false);
  const [statusAttendance, setStatusAttendance] = useState();
  const [openModalAttachment, setOpenModalAttachment] = useState(false);
  const [openModalRoomOn, setOpenModalRoomOn] = useState(false);
  const [urlFileState, setUrlFileState] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [currentAttendance, setAttendanceCurrent] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [finalDate, setFinalDate] = useState("");
  const [validationSendEmail, setValidationSendEmail] = useState("");
  const disableField =
    (companyUser.isAccountant !== "true" && typeScreen === "edit") ||
    (companyUser.isAccountant === "true" && typeScreen === "edit") ||
    (companyUser.isAccountant !== "true" && typeScreen !== "edit")
      ? true
      : false;
  const disableFieldCustomer =
    (companyUser.isAccountant !== "true" && statusAttendance) ||
    (companyUser.isAccountant === "true" && statusAttendance)
      ? true
      : false;
  const renderComponent =
    (companyUser.isAccountant === "true" && statusAttendance) ||
    (companyUser.isAccountant !== "true" && statusAttendance) ||
    companyUser.isAccountant !== "true"
      ? false
      : true;

  const listRoons = [];

  const components = {
    generalData: [
      {
        field: "customer",
        value: customer,
        setValue: setCustomerState,
        label: "Cliente",
        id: "customer",
        disabled: disableField ? disableField : false,
        render: true,
        list: customers,
        xs: companyUser.isAccountant === "true" ? 6 : 12,
        error: errors.customer,
      },
      {
        field: "departament",
        value: departament,
        setValue: setDepartamentState,
        label: "Departamento",
        id: "departament",
        disabled: disableFieldCustomer,
        render: true,
        list: departaments,
        xs: 6,
        error: errors.departament,
      },
      {
        field: "classification",
        value: classification,
        setValue: setClassificationState,
        label: "Classificação",
        id: "classification",
        disabled: disableFieldCustomer,
        render: true,
        list: classifications,
        xs: 6,
        error: errors.classification,
      },
      {
        field: "situation",
        value: situation,
        setValue: setSituationState,
        label: "Situação",
        id: "situation",
        disabled: disableFieldCustomer,
        render: companyUser.isAccountant === "true" ? true : false,
        list: situations,
        xs: 6,
        error: errors.situation,
      },
      {
        field: "subject",
        value: subject,
        setValue: setSubjectState,
        label: "Assunto",
        id: "subject",
        disabled: disableFieldCustomer,
        render: true,
        xs: 12,
        error: errors.subject,
      },
      {
        field: "request",
        value: request,
        setValue: setRequestState,
        label: "Solicitação",
        id: "request",
        disabled: disableFieldCustomer,
        render: true,
        xs: 12,
        multiline: true,
        maxRow: 4,
        minRow: 4,
        error: errors.request,
      },
    ],
    actionsButtons: [
      {
        label: "Finalizar",
        onClick:
          companyUser.isAccountant === "true"
            ? () => {
                setValidationSendEmail(true);
                setFinishState(true);
                setStateModalGravar(true);
              }
            : () => {
                setFinishState(true);
                setModalAction("save");
              },
        field: "finish",
        render: true,
      },
      {
        label: "Transferir",
        onClick: () => {
          setStateCheckBoxEmail({
            typeEmail: "transferAttendant",
            active: true,
          });
          setFinishState(false);
          setStateModalTransfer(true);
        },
        field: "transfer",
        render: renderComponent,
      },
      {
        label: "Salvar",
        onClick:
          companyUser.isAccountant === "true"
            ? () => {
                setValidationSendEmail(
                  document
                    .getElementById("publicInteraction")
                    .firstChild.value.trim()
                    ? true
                    : false
                );
                setFinishState(false);
                setStateModalGravar(true);
              }
            : () => setModalAction("save"),
        field: "save",
        render: true,
      },
    ],
  };
  const { push } = useHistory();

  const heightBoxEditor = () => {
    const publicInteraction = document.getElementById("publicInteraction");
    const privateInteraction = document.getElementById("privateInteraction");

    if (publicInteraction) {
      publicInteraction.style.height = "auto";
    }

    if (privateInteraction) {
      privateInteraction.style.height = "auto";
    }
  };

  useEffect(() => {
    heightBoxEditor();
  }, []);

  const [accountantCnpj, setAccountantCnpj] = useState(
    localStorage.getItem("accountantCnpjPanel")
  );
  const [tokenCognito, setTokenCognito] = useState(
    localStorage.getItem("tokenPanel")
  );

  const optionsList = (arr) => {
    try {
      let json = [];
      arr?.items.forEach((item) => {
        json.push({
          title:
            item?.description ||
            item?.socialName ||
            item?.fantasyName ||
            item?.name,
          value: item?.attendanceSituationId || item?.id || item?.productId,
          color: item?.color || null,
        });
      });
      return json;
    } catch (error) {
      console.error("Error:", error);
      return [];
    }
  };

  const renderButton = (jsonButton) => {
    let element = "";
    const button = () => (
      <Button label={jsonButton.label} onClick={jsonButton.onClick} />
    );
    const buttonsComponent = [
      { buttonToHidden: "finish" },
      { buttonToHidden: "transfer" },
    ];

    buttonsComponent.map((fieldButton) => {
      if (companyUser.isAccountant !== true) {
        if (
          (fieldButton.buttonToHidden !== jsonButton.field ? true : false) ===
          true
        ) {
          if (jsonButton.render) element = button();
          return element;
        }
      } else {
        if (jsonButton.render) element = button();
        return element;
      }

      return <></>;
    });

    return { element: element };
  };

  const renderFields = (jsonField) => {
    let element = "";
    const autocompleteComponent = () => (
      <AutoCompleteCRM
        error={jsonField.error}
        value={jsonField.value || ""}
        setValue={jsonField.setValue}
        optionsList={optionsList(jsonField.list)}
        label={`${jsonField.label}${typeScreen === "insert" ? "*" : ""}`}
        descriptionField="title"
        disabled={jsonField.disabled}
      />
    );
    const textFieldComponent = () => (
      <TextField
        error={jsonField.error}
        fullWidth={jsonField.maxWidth || true}
        multiline={jsonField.multiline}
        maxRows={jsonField.maxRow}
        minRows={jsonField.minRow}
        size="small"
        id={jsonField.id || ""}
        value={jsonField.value || ""}
        onChange={(e) => handleFieldValue(e.target.value, jsonField.setValue)}
        name={jsonField.field || ""}
        label={`${jsonField.label}${typeScreen === "insert" ? "*" : ""}`}
        variant="outlined"
        disabled={jsonField.disabled || false}
      />
    );
    const fieldComponents = [
      { textFields: "subject" },
      { textFields: "request" },
      { autoCompleteFields: "customer" },
      { autoCompleteFields: "situation" },
      { autoCompleteFields: "departament" },
      { autoCompleteFields: "classification" },
    ];

    fieldComponents.map((fieldComp) => {
      if ((fieldComp.textFields === jsonField.field ? true : false) === true) {
        if (jsonField.render) element = textFieldComponent();
        return element;
      }
      if (
        (fieldComp.autoCompleteFields === jsonField.field ? true : false) ===
        true
      ) {
        if (jsonField.render) element = autocompleteComponent();
        return element;
      }

      return <></>;
    });

    return { element: element };
  };

  const htmlEditorFunc = (id) => {
    return (
      <>
        <HTMLEditor id={id} />
      </>
    );
  };

  async function convertBlob(blobUrl) {
    if (blobUrl && blobUrl.includes("blob:")) {
      let blobElement = await fetch(blobUrl).then((res) => res.blob());

      const result = await new Promise((resolve, _) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blobElement);
      });

      return result;
    }
  }

  const objMessages = async () => {
    let arrMessages = [];

    if (document.getElementById("publicInteraction").firstChild.value.trim())
      arrMessages.push({
        description: document
          .getElementById("publicInteraction")
          .firstChild.value.trim(),
        privateFlag: 0,
        blobImage: await getBlobImage("publicInteraction_rte-edit-view"),
      });

    if (companyUser.isAccountant === "true")
      if (document.getElementById("privateInteraction").firstChild.value.trim())
        arrMessages.push({
          description: document
            .getElementById("privateInteraction")
            .firstChild.value.trim(),
          privateFlag: 1,
          blobImage: await getBlobImage("privateInteraction_rte-edit-view"),
        });
    if (companyUser.isAccountant === "true")
      if (idTransfer?.value) {
        if (users?.items) {
          const nameTransfer = users?.items.find(
            (user) => user.id === idTransfer?.value
          );
          arrMessages.push({
            description: `Transferência para ${nameTransfer["name"]}`,
            privateFlag: flagMessagePrivate ? 1 : 0,
          });
        }
      }
    return arrMessages;
  };

  const getBlobImage = async (elementId) => {
    var imageMessage = [
      ...document.getElementById(elementId).getElementsByTagName("img"),
    ];
    const imagesBlob = [];
    await Promise.all(
      imageMessage.map(async (value) => {
        const src = await convertBlob(value.currentSrc);
        imagesBlob.push({ element: value.outerHTML, base64: src });
      })
    );
    return imagesBlob;
  };

  const getFileWithBase64 = async () => {
    if (!uploadedFiles) return [];
    const files = [];
    for await (let attachment of uploadedFiles) {
      files.push({
        id: attachment.id,
        name: attachment.name,
        size: attachment.readableSize,
        base64: await convertBlob(attachment.preview),
        file: attachment.file,
        uploaded: attachment.uploaded,
        deleted: attachment.deleted,
      });
    }
    return files;
  };

  useEffect(() => {
    const saveData = async () => {
      if (modalAction && modalAction !== "cancel") {
        setLoadingSave(true);
        const interactions = await objMessages();
        if (!interactions || interactions?.length === 0) {
          setModalAction(null);
          notify("Nenhuma interação informada", true, "warning");
          setLoadingSave(false);
          return;
        }
        setAccountantCnpj(localStorage.getItem("accountantCnpjPanel"));
        setTokenCognito(localStorage.getItem("tokenPanel"));
        await handleSave({
          value: {
            typeScreen: typeScreen,
            attendantTransferId: idTransfer?.value,
            attendanceId: attendanceId || null,
            customer: customer ? customer["value"] : null,
            situation: situation ? situation["value"] : null,
            situationDescription: situation ? situation["title"] : null,
            department: departament ? departament["value"] : null,
            departmentDescription: departament ? departament["title"] : null,
            classification: classification ? classification["value"] : null,
            classificationDescription: classification
              ? classification["title"]
              : null,
            subject: subject,
            request: request,
            solucionDate: finishState,
            interaction: interactions,
            attachaments: await getFileWithBase64(),
            typeEmail: stateCheckBoxEmail,
            tokenPanel: tokenCognito,
            accountantCnpj: accountantCnpj,
          },
        });
      }
      setLoading(false);
      setLoadingSave(false);
      setModalAction(null);
    };
    try {
      saveData();
    } catch (error) {
      console.error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalAction]);

  useEffect(() => {
    if (company?.alias && company?.id) {
      setAccountantCnpj(localStorage.getItem("accountantCnpjPanel"));
      setTokenCognito(localStorage.getItem("tokenPanel"));

      const validationNumber = (number) => {
        return number > 9 ? number : `0${number}`;
      };

      setLoading(true);
      const load = async () => {
        const attendanceFetch = await getAttendance({
          attendanceId: attendanceId,
          customerId: companyUser.isAccountant !== "true" ? user.id : null,
        });

        if (attendanceFetch?.items?.length > 0) {
          const attendanceStartDate = new Date(
            attendanceFetch.items[0].startDate.replace(/[a-zA-Z]+/gm, " ")
          );

          const attendanceFinalDate = attendanceFetch.items[0].solucionDate
            ? new Date(
                attendanceFetch.items[0].solucionDate.replace(
                  /[a-zA-Z]+/gm,
                  " "
                )
              )
            : false;
          setStartDate(
            `${validationNumber(
              attendanceStartDate.getDate()
            )}/${validationNumber(
              attendanceStartDate.getMonth() + 1
            )}/${attendanceStartDate.getFullYear()}
             às ${validationNumber(
               attendanceStartDate.getHours()
             )}:${validationNumber(
              attendanceStartDate.getMinutes()
            )}:${validationNumber(attendanceStartDate.getSeconds())}`
          );
          if (attendanceFinalDate !== false) {
            setFinalDate(
              `${validationNumber(
                attendanceStartDate.getDate() + 1
              )}/${validationNumber(
                attendanceFinalDate.getMonth() + 1
              )}/${attendanceFinalDate.getFullYear()}
              às ${validationNumber(
                attendanceStartDate.getHours()
              )}:${validationNumber(
                attendanceStartDate.getMinutes()
              )}:${validationNumber(attendanceStartDate.getSeconds())}`
            );
          }
          const temp = attendanceFetch.items[0];
          setAttendanceCurrent(temp);
        }
        reloadData();
      };
      const reloadData = async () => {
        if (company?.alias || company?.id) {
          if (!situations?.length) {
            const situationsFetch = await getSituation();
            setSituationsState(situationsFetch);
          }

          if (companyUser.isAccountant === "true") {
            if (!customers?.length) {
              const customersFetch = await getCustomers({ id: false });
              setCustomersState(customersFetch);
            }
          } else {
            setCustomersState({
              items: [{ socialName: user.name, id: user.id }],
            });
            setCustomerState({ title: user.name, value: user.id });
          }

          if (!departaments?.length) {
            const departamantsFetch = await fetchDepartaments();
            setDepartamentsState(departamantsFetch);
          }

          if (!classifications?.length) {
            const classificationFetch = await getClassification();
            setClassificationsState(classificationFetch);
          }
        }
        setLoading(false);
      };

      try {
        load();
      } catch (error) {
        console.error(error);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company.alias, company.id, user.id]);

  useEffect(() => {
    const loadData = async () => {
      if (typeScreen === "edit" && currentAttendance?.attendanceId) {
        setLoading(true);
        try {
          setSituationState({
            value: currentAttendance?.situation?.situationAttendanceId,
            title: currentAttendance?.situation?.situationDescription,
          });

          setCustomerState({
            value: currentAttendance?.customer?.id,
            title:
              currentAttendance?.customer?.socialName ||
              currentAttendance?.customer?.fantasyName,
          });

          setClassificationState({
            value: currentAttendance?.attendanceClassificationId,
            title: currentAttendance?.classificationDescription,
          });

          setDepartamentState({
            value: currentAttendance?.productId,
            title: currentAttendance?.product,
          });
          setSubjectState(
            `${typeScreen === "edit" ? currentAttendance?.title || " " : ""}`
          );

          setRequestState(currentAttendance?.solucion);

          setStatusAttendance(currentAttendance?.solucionDate);

          const messagesFetch = await getMessages({
            attendanceId: +currentAttendance?.attendanceId,
          });

          setMessages(messagesFetch.items);

          const filesData = await filesAttendaceS3();
          setUploadedFiles(filesData);

          const usersFetch = await getUser({ id: false, roomId: 1 });
          setUsersState(usersFetch);
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      }
    };
    try {
      setLoading(true);
      loadData();
    } catch (error) {
      console.error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAttendance?.attendanceId]);

  const filesAttendaceS3 = async () => {
    try {
      const dataFetch = await getFilesS3({
        attendanceId: currentAttendance?.attendanceId,
      });
      const result = dataFetch?.items.map((file) => {
        return {
          id: file?.file?.id,
          preview: file?.file?.url,
          name: file?.file?.name,
          readableSize: filesize(file?.file?.size),
          elementBase64: file?.file?.base64,
          file: {
            type: file?.file?.mimeType,
            extension: "." + file?.file?.extension,
          },
          uploaded: true,
          deleted: false,
          isInteractionAttendance: file?.file?.isInteractionAttendance,
        };
      });

      return result;
    } catch (error) {
      console.error(error);
    }
  };

  const ActionsButton = () => {
    if (statusAttendance) {
      return (
        <>
          <Grid item flex={1}>
            <Button
              label="Reabrir"
              onClick={() => {
                setLoadingSave(true);
                setAccountantCnpj(localStorage.getItem("accountantCnpjPanel"));
                setTokenCognito(localStorage.getItem("tokenPanel"));
                handleSave({
                  value: {
                    typeScreen: typeScreen,
                    attendantTransferId: idTransfer?.value,
                    attendanceId: currentAttendance?.attendanceId || null,
                    customer: customer ? customer["value"] : null,
                    situation: situation ? situation["value"] : null,
                    department: departament ? departament["value"] : null,
                    classification: classification
                      ? classification["value"]
                      : null,
                    subject: subject,
                    request: request,
                    solucionDate: false,
                    typeEmail: stateCheckBoxEmail,
                    tokenPanel: tokenCognito,
                    accountantCnpj: accountantCnpj,
                    reopenAttendance: true,
                  },
                });
              }}
            />
          </Grid>
        </>
      );
    } else {
      return (
        <>
          {components.actionsButtons.map((button) => {
            const objRenderButton = renderButton(button);
            let { element } = objRenderButton;
            if (element) {
              return (
                <Grid item flex={1}>
                  {element}
                </Grid>
              );
            } else {
              return <></>;
            }
          })}
        </>
      );
    }
  };

  return (
    <>
      <Loading open={loading} />
      <Loading open={loadingSave} />
      {currentAttendance?.attendanceId || typeScreen === "insert" ? (
        <>
          {stateModalGravar && (
            <ModalSave
              open={stateModalGravar}
              setOpen={setStateModalGravar}
              finish={finishState}
              checkboxEmail={stateCheckBoxEmail}
              setCheckboxEmail={setStateCheckBoxEmail}
              modalAction={setModalAction}
              validationSendEmail={validationSendEmail}
            />
          )}
          {stateModalTransfer && (
            <ModalTransfer
              open={stateModalTransfer}
              setIdTransfer={setIdTransfer}
              idTransfer={idTransfer}
              setFlagMessagePrivate={setFlagMessagePrivate}
              setStateCheckBoxEmail={setStateCheckBoxEmail}
              flagMessagePrivate={flagMessagePrivate}
              setOpen={setStateModalTransfer}
              modalAction={setModalAction}
            />
          )}
          {openModalAttachment && (
            <ModalAttachment
              setOpen={setOpenModalAttachment}
              open={openModalAttachment}
              urlFile={urlFileState}
            />
          )}
          {openModalRoomOn && (
            <ModalRoomOn
              open={openModalRoomOn}
              setOpen={setOpenModalRoomOn}
              listRoons={listRoons}
              attendanceId={currentAttendance?.attendanceId}
              customerId={currentAttendance?.customer?.id}
            />
          )}
          <Grid item xs={12} container justifyContent="space-between">
            <Title color="blue">
              Atendimento {typeScreen === "edit" && `N° ${attendanceId}`}
            </Title>
          </Grid>
          <AccordionCard title="Dados Gerais" defaultExpanded={true}>
            {typeScreen === "edit" && (
              <Grid
                item
                xs={12}
                style={{
                  textAlign: "center",
                  paddingTop: "10px",
                  width: "100%",
                  color: "#4b4c4e",
                }}
              >
                <strong>
                  ABERTO EM {startDate}
                  {statusAttendance && ` - FECHADO EM ${finalDate}`}
                </strong>
              </Grid>
            )}
            {components.generalData.map((field) => {
              const objRenderField = renderFields(field);
              const { element } = objRenderField;
              if (element) {
                return (
                  <Grid item xs={field.xs}>
                    {element}
                  </Grid>
                );
              } else {
                return <></>;
              }
            })}
          </AccordionCard>

          {typeScreen === "edit" ? (
            loading ? (
              <>
                <Margin top="12" bottom="18">
                  <Grid>
                    <Skeleton
                      variant="rectangular"
                      animation="wave"
                      width="100%"
                    >
                      <div style={{ paddingTop: "150px" }} />
                    </Skeleton>
                  </Grid>
                </Margin>
                <Margin top="12" bottom="18">
                  <Grid>
                    <Skeleton
                      variant="rectangular"
                      animation="wave"
                      width="100%"
                    >
                      <div style={{ paddingTop: "400px" }} />
                    </Skeleton>
                  </Grid>
                </Margin>
              </>
            ) : (
              <>
                <AccordionCard
                  title="Anexos"
                  defaultExpanded={true}
                  help={true}
                  helpText={
                    <React.Fragment>
                      <center>Ajuda</center>
                      <ul>
                        <li>
                          Arraste arquivos e/ou imagens ou clique no campo para
                          abrir o Explorador de Arquivos do seu computador
                        </li>
                      </ul>
                      <center>
                        Importante: Só é permitido anexar arquivos e imagens que
                        tenham menos de 8Mb(800.000Kb)
                      </center>
                    </React.Fragment>
                  }
                >
                  <Grid
                    xs={12}
                    style={{
                      color: "#000",
                      alignContent: "center",
                      textAlign: "center",
                    }}
                  >
                    <ListFiles
                      uploadedFiles={uploadedFiles}
                      statusAttendance={statusAttendance}
                      setUploadedFiles={setUploadedFiles}
                      setUrlFileState={setUrlFileState}
                      setOpenModal={setOpenModalAttachment}
                      typeScreen={typeScreen}
                    />
                  </Grid>
                </AccordionCard>
                <AccordionCard
                  title="Histórico de Interações"
                  defaultExpanded={true}
                >
                  <Grid xs={12} style={{ textAlign: "center" }}>
                    <InteractionHistoric
                      loading={loading}
                      messages={messages}
                      uploadedFiles={uploadedFiles}
                      originRender="accordion"
                    />
                  </Grid>
                </AccordionCard>
              </>
            )
          ) : (
            <>
              <AccordionCard
                title="Anexos"
                defaultExpanded={true}
                help={true}
                helpText={
                  <React.Fragment>
                    <center>Dicas</center>
                    <ul>
                      <li>
                        Arraste um arquivo e/ou imagem ou clique no campo para
                        abrir o Explorador de Arquivos do seu computador
                      </li>
                    </ul>
                    <center>Importante</center>
                    <ul>
                      <li>
                        Só é permitido anexar arquivos e imagens que tenham no
                        máximo 8MB (8.192KB)
                      </li>
                    </ul>
                  </React.Fragment>
                }
              >
                <Grid
                  xs={12}
                  style={{
                    color: "#000",
                    alignContent: "center",
                    textAlign: "center",
                  }}
                >
                  <ListFiles
                    uploadedFiles={uploadedFiles}
                    statusAttendance={statusAttendance}
                    setUploadedFiles={setUploadedFiles}
                    setUrlFileState={setUrlFileState}
                    setOpenModal={setOpenModalAttachment}
                    typeScreen={typeScreen}
                  />
                </Grid>
              </AccordionCard>
            </>
          )}
          {!statusAttendance && (
            <>
              <GridCard
                title="Interação"
                help={true}
                helpText={
                  <React.Fragment>
                    <center>Dicas</center>
                    <ul>
                      <li>
                        Cole imagens utilizando os atalhos Ctrl+C e Ctrl+V
                      </li>
                      <li>
                        Estilize seu texto da forma que preferir, utilizando as
                        ferramentas disponiveis, como: Negrito, Itálico,
                        Sublinhado, dentre outras
                      </li>
                      {companyUser.isAccountant === "true" && (
                        <li>
                          Interações públicas são exibidas para o cliente, é
                          possível enviar uma notificação por email e no Portal
                          do Cliente, ao salvar você poderá escolher se deseja
                          enviar ou não
                        </li>
                      )}
                    </ul>
                  </React.Fragment>
                }
              >
                <Grid xs={12} style={{ height: "auto" }}>
                  {htmlEditorFunc("publicInteraction")}
                </Grid>
              </GridCard>
              {companyUser.isAccountant === "true" && (
                <AccordionCard
                  title="Interação Privada"
                  value={switchAccordion}
                  setValue={setSwitchAccordion}
                  icon={true}
                  help={true}
                  helpText={
                    <React.Fragment>
                      <center>Dicas</center>
                      <ul>
                        <li>
                          As interações privadas não são exibidas para o
                          cliente. Utilize esta área para adicionar observações
                          internas.
                        </li>
                      </ul>
                    </React.Fragment>
                  }
                >
                  <Grid xs={12}>{htmlEditorFunc("privateInteraction")}</Grid>
                </AccordionCard>
              )}
            </>
          )}
          <Grid item container justifyContent="flex-end" spacing={1}>
            <Grid item flex={1}>
              <Button
                label="Cancelar"
                bg-color="tertiary"
                onClick={() =>
                  typeScreen === "edit" ? push("../search") : push("./search")
                }
              />
            </Grid>
            <ActionsButton />
          </Grid>
          <Grid container xs={12}></Grid>
        </>
      ) : (
        <>
          <Box>
            <Box>
              <Skeleton
                animation="wave"
                variant="text"
                width="20%"
                height={40}
              />
            </Box>
            <Margin top="8" bottom="18">
              <Divider />
            </Margin>
            <Margin top="12" bottom="18">
              <Box>
                <Skeleton variant="rect" animation="wave" width="100%">
                  <div style={{ paddingTop: "250px" }} />
                </Skeleton>
              </Box>
            </Margin>
            <Margin top="12" bottom="18">
              <Grid>
                <Skeleton variant="rect" animation="wave" width="100%">
                  <div style={{ paddingTop: "350px" }} />
                </Skeleton>
              </Grid>
            </Margin>
            <Margin top="12" bottom="18">
              <Grid>
                <Skeleton variant="rect" animation="wave" width="100%">
                  <div style={{ paddingTop: "100px" }} />
                </Skeleton>
              </Grid>
            </Margin>
            <Margin top="12" bottom="18">
              <Grid>
                <Skeleton variant="rect" animation="wave" width="100%">
                  <div style={{ paddingTop: "400px" }} />
                </Skeleton>
              </Grid>
            </Margin>
          </Box>
        </>
      )}
    </>
  );
}
