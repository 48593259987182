import React, { useContext, useEffect, useRef, useState } from "react";
import { GlobalContext } from "../../contexts/GlobalContext";
import { DefaultPageContainer } from "../../components/layoutContainer/styles/LayoutContainer";
import { GlobalSubheader } from "../../components/subHeader/GlobalSubheader";
import { ButtonTogleStyle, DivStyle } from "../../components/modal/Modal";
import { ConfigureERP } from "./children/ConfigureERP";
import { ConfigureERPContextProvider } from "./contexts/ConfigureERPContext";
import { CustumerManagementContext } from "./contexts/CustomerManagementContext";
import CertificateListView from "../certificate/CertificateList";
import { CustomerEditData } from "./children/CustomerEditData";
import DocumentsTab from "./children/DocumentsTab";
import { DocumentGuideContextProvider } from "../GuideReports/contexts/DocumentGuideContext";
import { PermissionsAndUsers } from "../userAccess/components/PermissionsAndUsers";

export const CustomerManagementEdit = ({ location }) => {
  const { setBreadcrumbs } = useContext(GlobalContext);

  const { documentCustomer, showTabs, setShowTabs } = useContext(
    CustumerManagementContext
  );

  const [currentTab, setCurrentTab] = useState(location?.state?.initalTab);
  const [currentTabName, setCurrentTabName] = useState(
    location?.state?.initalTabName || ""
  );

  const currentBreadCrumbs = useRef([]);

  useEffect(() => {
    let currentBreadCrumbsCopy = [...currentBreadCrumbs.current];

    if (
      location.pathname === "/customermanagement/edit" &&
      location.state.customerId !== undefined
    ) {
      setShowTabs(true);
      currentBreadCrumbsCopy.push(
        { name: location.state?.companyName, href: "" },
        { name: "Editar", href: "" },
        {
          name: currentTabName,
          href: "",
        }
      );
    } else if (
      location.pathname === "/customermanagement/edit" &&
      location?.state?.customerId === undefined
    ) {
      setShowTabs(false);
      currentBreadCrumbsCopy.push({ name: "Cadastro de Cliente", href: "" });
    }
    setBreadcrumbs(currentBreadCrumbsCopy);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab, currentBreadCrumbs]);

  const tabList = [
    {
      id: 1,
      name: "Dados da Empresa",
    },
    {
      id: 2,
      name: "Gestão Financeira",
    },
    {
      id: 3,
      name: "Usuários e Acesso ao Portal",
    },
    {
      id: 4,
      name: "Certificado",
    },
    {
      id: 5,
      name: "Documentos e Guias",
    },
    // {
    //   id: 6,
    //   name: "Atendimentos",
    // },
  ];

  const RecursiveTabs = ({ list }) => {
    return (
      <DivStyle style={{ marginBottom: "16px" }}>
        {list.map((item) => (
          <>
            <ButtonTogleStyle
              key={item.id}
              active={currentTab === item.id}
              onClick={() => {
                setCurrentTab(item.id);
                setCurrentTabName(item.name);
              }}
            >
              <p style={{ textTransform: "none", lineHeight: 1 }}>
                {item.name}
              </p>
            </ButtonTogleStyle>
            &nbsp;
          </>
        ))}
      </DivStyle>
    );
  };

  return (
    <>
      <GlobalSubheader
        title={
          location.pathname === "/customermanagement/create"
            ? "Configurações"
            : "Gestão de Clientes"
        }
        titleLocation={
          location.pathname === "/customermanagement/create"
            ? "/setting"
            : "/customermanagement/list"
        }
      ></GlobalSubheader>
      <DefaultPageContainer>
        <div
          style={{
            background: "#fff",
            border: "1px solid #E0E0E0",
            borderRadius: 15,
            padding: 15,
          }}
        >
          {location?.pathname === "/customermanagement/edit" && showTabs ? (
            <RecursiveTabs list={tabList} />
          ) : null}

          {currentTab === 1 ? (
            <CustomerEditData
              pathname={location?.pathname}
              customerId={location?.state?.customerId}
              from={"customerManagement"}
              location={location}
            />
          ) : currentTab === 2 ? (
            <ConfigureERPContextProvider>
              <ConfigureERP location={location} />
            </ConfigureERPContextProvider>
          ) : currentTab === 3 ? (
            <PermissionsAndUsers location={location} />
          ) : currentTab === 4 ? (
            <CertificateListView
              location={location}
              companyDocument={documentCustomer}
            />
          ) : currentTab === 5 ? (
            <DocumentGuideContextProvider>
              <DocumentsTab />
            </DocumentGuideContextProvider>
          ) : null}
        </div>
      </DefaultPageContainer>
    </>
  );
};
