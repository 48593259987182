import {
  Badge,
  Box,
  CircularProgress,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
// import Autocomplete from '@mui/material/Autocomplete';
import { GridOverlay } from "@material-ui/data-grid";
import { CheckCircleOutline as CheckCircleOutlineIcon } from "@material-ui/icons";
import AnnouncementIcon from "@material-ui/icons/Announcement";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import SearchIcon from "@material-ui/icons/Search";
import { useHistory } from "react-router";
import { GlobalContext } from "../../../contexts/GlobalContext";
import {
  getAttendance,
  getClassification,
  getSituation,
} from "../../../repositories/integrations/crm/AttendanceRepository";
import { getCustomers } from "../../../repositories/integrations/crm/CustomerRepository";
import { useAttendance } from "../contexts/AttendanceContext";
import useAttendanceController from "../controllers/AttendanceController";
import AutoCompleteCRM from "./AutoCompleteCRM";

import { useAuth } from "../../../contexts/crm/Auth";
import {
  ContainerIcon,
  DataGridDefault,
  LightTooltip,
  Margin,
} from "../../../styles/GlobalStyle";
import ModalPreviewMessages from "./ModalPreviewMessages";

const renderFieldsRadioFilter = [
  {
    value: "My",
    label: "Meus",
  },
  {
    value: "Team",
    label: "Equipe",
  },
  {
    value: "All",
    label: "Todos",
  },
];

const BodySearch = () => {
  const {
    situations,
    setSituationsState,
    customers,
    setCustomersState,
    departaments,
    setDepartamentsState,
    classifications,
    setClassificationsState,
    setParametersState,
  } = useAttendance();

  const { user, company } = useAuth();

  const { companyUser } = useContext(GlobalContext);

  const [attendanceNumberField, setAttendanceNumberField] = useState();
  const [attendanceContentField, setAattendanceContentField] = useState();

  const filterValues = {
    attendances: {
      items: null,
      rowsPerPage: 10,
      page: 1,
      pageCount: 1,
      currentItemsCount: 0,
      totalItemsCount: 0,
    },
    filter: {
      subjectAttendance: attendanceContentField || null,
      teamId: null,
      userRegisterId: null,
      situationId: null,
      customerId: null,
      attendanceId: attendanceNumberField || null,
    },
  };

  const { fetchDepartaments } = useAttendanceController();

  const [loading, setLoading] = useState(false);
  const [loadingSelect, setLoadingSelect] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [optionsFilterField, setOptionsFilterField] = useState();
  const [selectCustomerState, setSelectCustomerState] = useState();
  const [selectSituationState, setSelectSituationState] = useState();
  const [attendanceList, setAttendancelist] = useState({
    ...filterValues.attendances,
  });
  const [attendanceListFilter, setAttendancelistFilter] = useState([]);
  const [selectDepartamentState, setSelectDepartamentState] = useState();
  const [openModalPreviewMessages, setOpenModalPreviewMessages] =
    useState(false);
  const [attendanceIdPreviewMessages, setAttendanceIdPreviewMessages] =
    useState();
  const { push } = useHistory();

  const fetchAttendanceList = async ({ page } = {}) => {
    try {
      if (companyUser.isAccountant !== "true" && !user.id) return;
      setLoading(true);
      const attendancesFetch = await getAttendance({
        attendanceId: attendanceNumberField,
        customerId:
          companyUser.isAccountant === "true"
            ? selectCustomerState
              ? selectCustomerState.value
              : null
            : user.id,
        situationId: selectSituationState ? selectSituationState.value : null,
        subjectAttendance: attendanceContentField,
        departamentId: selectDepartamentState
          ? selectDepartamentState.value
          : null,
        teamId: optionsFilterField === "Team" ? user.teamId : null,
        userRegisterId: optionsFilterField === "My" ? user.id : null,
        page: page ? page : attendanceList.page,
        rowsPerPage: attendanceList.rowsPerPage,
      });

      if (!attendancesFetch?.hasError) {
        const arrAttendances = attendancesFetch.items.map(
          (attendance, index) => {
            return {
              attendanceId: attendance?.attendanceId,
              situationId: attendance?.situation.situationAttendanceId,
              situation: attendance?.situation.situationDescription,
              attendanceTime: attendance?.attendanceTime,
              situationColor: attendance?.situation.situationColor,
              customerId: attendance?.customer.id,
              userRegisterId: attendance?.userRegisterId,
              customer:
                attendance?.customer.socialName ||
                attendance?.customer.fantasyName,
              teamId: attendance?.teamId,
              teamDescription: attendance?.teamDescription,
              title: attendance?.title || "",
              haveAttachment: attendance?.haveAttachment,
              acoes: index,
              solucionDate: attendance?.solucionDate,
              haveNewInteration:
                !attendance?.lastMessage?.privateFlag &&
                attendance?.lastMessage?.originCss === "ATENDIMENTO" &&
                ((attendance?.emailInteractionFlag &&
                  companyUser.isAccountant === "true") ||
                  (!attendance?.emailInteractionFlag &&
                    companyUser.isAccountant !== "true")),
            };
          }
        );
        setParametersState(attendancesFetch.parameters);
        setAttendancelist(attendancesFetch);
        setAttendancelistFilter(arrAttendances);
        setLoadingData(false);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const pulseButtonAdd = async () => {
    const pulse = setTimeout(() => {}, 5000);

    return () => clearTimeout(pulse);
  };

  const optionsList = (arr, aditionalObj) => {
    try {
      let finalArray = [];
      if (!arr) return [];

      arr.items.forEach((item) => {
        finalArray.push({
          title:
            item.description ||
            item.socialName ||
            item.fantasyName ||
            item.name,
          value: item.attendanceSituationId || item.id || item.productId,
          color: item?.color || null,
        });
      });

      if (aditionalObj) {
        finalArray.push(aditionalObj);
      }

      return finalArray;
    } catch (error) {
      console.error("Error:", error);
      return [];
    }
  };

  const onPageChange = async ({ page }) => {
    try {
      if (!loading) {
        if (page) page = page + 1;
        if (attendanceList.page !== page) {
          setLoading(true);
          attendanceList.page = page ? page : 1;
          await fetchAttendanceList();
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        setLoadingSelect(true);
        setLoading(true);
        if (company?.alias || company?.id) {
          if (!situations?.length) {
            const situationsFetch = await getSituation();
            setSituationsState(situationsFetch);
          }

          if (companyUser.isAccountant === "true") {
            if (!customers?.length) {
              const customersFetch = await getCustomers({ id: false });
              setCustomersState(customersFetch);
            }
          } else {
            setCustomersState({
              items: [{ socialName: user.name, id: user.id }],
            });
          }

          if (!departaments?.length) {
            const departamantsFetch = await fetchDepartaments();
            setDepartamentsState(departamantsFetch);
          }

          if (!classifications?.length) {
            const classificationFetch = await getClassification();
            setClassificationsState(classificationFetch);
          }
          setLoadingSelect(false);
        }
      } catch (error) {
        console.error(error);
      } finally {
        pulseButtonAdd();
        setLoading(false);
      }
      setLoadingSelect(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company?.id]);

  useEffect(() => {
    const timeoutId = setTimeout(async () => {
      try {
        if (company?.alias || company?.id) {
          await fetchAttendanceList({ page: 1 });
        }
      } catch (error) {
        console.error(error);
      }
    }, 500);
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectSituationState,
    selectCustomerState,
    optionsFilterField,
    attendanceContentField,
    attendanceNumberField,
    selectDepartamentState,
    user.id,
    user.teamId,
  ]);

  const optionSelected = (value) => {
    setOptionsFilterField(value);
  };

  const columns = [
    {
      field: "actions",
      width: 100,
      renderHeader: () => (
        <Grid container justifyContent="center">
          <div className="MuiDataGrid-columnHeaderTitle">Ações</div>
        </Grid>
      ),
      renderCell: ({ row }) => (
        <>
          <Grid container justifyContent="center" spacing={10}>
            {loadingSelect ? (
              <>
                <CircularProgress />
              </>
            ) : (
              <>
                {/* <LightTooltip title={"Excluir Atendimento"} placement="top">
                  <IconButtonStyled
                      bg-color="transparent"
                      bghovercolor="grayPrimary"
                      iconColor="red"
                      padding="2px"
                  >
                    <DeleteIcon/>
                  </IconButtonStyled>
                </LightTooltip> */}
                <LightTooltip
                  title="Editar"
                  placement="top"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <ContainerIcon
                    onClick={async () => {
                      push(`edit/${row.attendanceId}`);
                    }}
                    bg-color="transparent"
                    iconColor="gray"
                    padding="2px"
                    onMouseOver={(e) => (e.target.style.color = "#4c9ceb")}
                    onMouseOut={(e) => (e.target.style.color = "#006FDE")}
                  >
                    <EditOutlinedIcon
                      className="buttonDelete"
                      style={{ color: "#006FDE" }}
                      size={10}
                    />
                  </ContainerIcon>
                </LightTooltip>
                {companyUser.isAccountant === "true" && (
                  <LightTooltip
                    title="Visualizar mensagens"
                    placement="top"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <ContainerIcon
                      onClick={async () => {
                        setAttendanceIdPreviewMessages(row.attendanceId);
                        setOpenModalPreviewMessages(true);
                      }}
                      bg-color="transparent"
                      iconColor="gray"
                      padding="2px"
                      onMouseOver={(e) => (e.target.style.color = "#4c9ceb")}
                      onMouseOut={(e) => (e.target.style.color = "#006FDE")}
                    >
                      <SearchIcon
                        className="buttonDelete"
                        style={{ color: "#006FDE" }}
                        size={10}
                      />
                    </ContainerIcon>
                  </LightTooltip>
                )}
              </>
            )}
          </Grid>
        </>
      ),
    },
    {
      field: "attendanceId",
      width: 50,
      align: "center",
      renderHeader: () => (
        <Grid container justifyContent="center">
          <div className="MuiDataGrid-columnHeaderTitle">Nº</div>
        </Grid>
      ),
      renderCell: ({ row }) => (
        <Grid container>
          {row?.haveNewInteration && !row.solucionDate ? (
            <b>{row?.attendanceId}</b>
          ) : (
            row?.attendanceId
          )}
        </Grid>
      ),
    },
    {
      field: "attachment",
      width: 140,
      align: "center",
      renderHeader: () => <Grid container justifyContent="center"></Grid>,
      renderCell: ({ row }) => (
        <>
          {
            <Grid container xs={3} justifyContent="center">
              <LightTooltip
                title={`${
                  row.situation ? row.situation : "Situação não definida"
                }`}
                placement="top"
                style={{ display: "flex", alignContent: "center" }}
              >
                <Box
                  component="span"
                  style={{
                    borderRadius: 25,
                    backgroundColor: `${
                      row.situationColor ? row.situationColor : "transparent"
                    }`,
                    padding: 10,
                    boxShadow: `${
                      row.situation ? "" : "0px 0px 0px 1px black inset"
                    }`,
                  }}
                />
              </LightTooltip>
            </Grid>
          }
          <Grid container xs={3} justifyContent="center">
            <LightTooltip
              title={row.haveAttachment ? "Possui anexos" : "Sem anexos"}
              placement="top"
              style={{ display: "flex", alignContent: "center" }}
            >
              <ContainerIcon
                bg-color="transparent"
                iconColor="gray"
                style={{ font: "icon" }}
              >
                <AttachFileIcon
                  className="buttonDelete"
                  style={{
                    color: row.haveAttachment ? "#006FDE" : "gray",
                    cursor: "default",
                  }}
                  size={10}
                />
              </ContainerIcon>
            </LightTooltip>
          </Grid>
          <Grid container xs={3} justifyContent="center">
            <LightTooltip
              style={{ display: "flex", alignContent: "center" }}
              title={
                row.solucionDate
                  ? "Atendimento finalizado"
                  : "Atendimento em aberto"
              }
              placement="right"
            >
              <ContainerIcon iconColor={row.solucionDate ? "blue" : "gray"}>
                <CheckCircleOutlineIcon style={{ cursor: "default" }} />
              </ContainerIcon>
            </LightTooltip>
          </Grid>
          <Grid container xs={3} justifyContent="center">
            {!row.solucionDate && row.haveNewInteration && (
              <LightTooltip
                title={`Há nova interação do ${
                  companyUser.isAccountant === "true" ? "Cliente" : "Escritório"
                }`}
                placement="top"
              >
                <Badge badgeContent={1} color="error">
                  <AnnouncementIcon />
                </Badge>
              </LightTooltip>
            )}
          </Grid>
        </>
      ),
    },
    companyUser.isAccountant === "true" && {
      field: "customer",
      width: 225,
      align: "left",
      renderHeader: () => (
        <Grid container justifyContent="center">
          <div className="MuiDataGrid-columnHeaderTitle">Cliente</div>
        </Grid>
      ),
      renderCell: ({ row }) => (
        <Grid
          container
          style={{
            textOverflow: "ellipsis",
            display: "block",
            overflow: "hidden",
          }}
        >
          <LightTooltip title={`${row.customer}`} placement="top">
            <div>
              {row?.haveNewInteration && !row.solucionDate ? (
                <b>{row?.customer}</b>
              ) : (
                row?.customer
              )}
            </div>
          </LightTooltip>
        </Grid>
      ),
    },
    {
      field: "title",
      minWidth: 350,
      maxWidth: companyUser.isAccountant === "true" ? 350 : 770,
      align: "left",
      renderHeader: () => (
        <Grid container justifyContent="center">
          <div className="MuiDataGrid-columnHeaderTitle">Assunto</div>
        </Grid>
      ),
      renderCell: ({ row }) => (
        <Grid
          container
          style={{
            textOverflow: "ellipsis",
            display: "block",
            overflow: "hidden",
          }}
        >
          <LightTooltip title={`${row.title}`} placement="top">
            <div>
              {row?.haveNewInteration && !row.solucionDate ? (
                <b>{row?.title}</b>
              ) : (
                row?.title
              )}
            </div>
          </LightTooltip>
        </Grid>
      ),
    },
    {
      field: "attendanceTime",
      width: 100,
      maxWidth: 100,
      align: "center",
      renderHeader: () => (
        <Grid container justifyContent="center">
          <div className="MuiDataGrid-columnHeaderTitle">Tempo</div>
        </Grid>
      ),
      renderCell: ({ row }) => (
        <Grid container justifyContent="center">
          <div>
            {row?.haveNewInteration && !row.solucionDate ? (
              <b>{row?.attendanceTime}</b>
            ) : (
              row?.attendanceTime
            )}
          </div>
        </Grid>
      ),
    },
  ];

  return (
    <>
      {
        attendanceIdPreviewMessages && (
          <ModalPreviewMessages
            open={openModalPreviewMessages}
            setOpen={setOpenModalPreviewMessages}
            attendanceId={attendanceIdPreviewMessages}
          />
        )
      }

      <Margin top="8" bottom="18">
        <Grid container spacing={1} sx={12}>
          <Grid item xs={2} style={{ minWidth: "165px" }}>
            <TextField
              id="attendanceId"
              label="Nº Atendimento"
              type="number"
              variant="filled"
              size="small"
              onChange={(e) => {
                setAttendanceNumberField(e.target.value);
              }}
              value={attendanceNumberField}
              style={{ width: "100%" }}
              InputLabelProps={{
                shrink: attendanceNumberField ? true : null,
              }}
              // InputProps={{
              //   startAdornment: attendanceNumberField ? (
              //     <InputAdornment position="start">Nº</InputAdornment>
              //   ) : null,
              // }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="contentAttendance"
              label="Assunto"
              type="text"
              size="small"
              variant="filled"
              onChange={(e) => {
                setAattendanceContentField(e.target.value);
              }}
              value={attendanceContentField}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={3}>
            <AutoCompleteCRM
              label="Situação"
              loading={loadingSelect}
              descriptionField="title"
              lg={2}
              value={selectSituationState}
              setValue={setSelectSituationState}
              optionsList={
                loadingSelect
                  ? []
                  : optionsList(situations, {
                      value: "0",
                      title: "Situação não definida",
                      color: "#80800000",
                      hasBoxShadow: true,
                    })
              }
              onChange={setSelectSituationState}
              variantAutocomplete="filled"
              iconInfo=""
            />
          </Grid>
          <Grid item xs={3} style={{ minWidth: "230px" }}>
            <AutoCompleteCRM
              label="Departamentos"
              loading={loadingSelect}
              descriptionField="title"
              lg={2}
              value={selectDepartamentState}
              setValue={setSelectDepartamentState}
              optionsList={loadingSelect ? [] : optionsList(departaments)}
              onChange={setSelectDepartamentState}
              variantAutocomplete="filled"
            />
          </Grid>
          <Grid item xs={4}>
            {companyUser.isAccountant === "true" && (
              <AutoCompleteCRM
                label="Cliente"
                loading={loadingSelect}
                descriptionField="title"
                lg={2}
                value={selectCustomerState}
                setValue={setSelectCustomerState}
                optionsList={loadingSelect ? [] : optionsList(customers)}
                onChange={setSelectCustomerState}
                variantAutocomplete="filled"
              />
            )}
          </Grid>
        </Grid>
        {companyUser.isAccountant === "true" && (
          <Margin top="8" bottom="18">
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              defaultValue={"All"}
            >
              {renderFieldsRadioFilter.map((radioFilterItem) => {
                return (
                  <FormControlLabel
                    value={radioFilterItem.value}
                    control={<Radio color="primary" />}
                    label={radioFilterItem.label}
                    onClick={(e) => {
                      optionSelected(e.target.value);
                    }}
                  />
                );
              })}
            </RadioGroup>
          </Margin>
        )}
      </Margin>

      <Grid xs={12}>
        <Grid item xs={12}>
          <Margin top="20">
            <DataGridDefault
              getRowId={(row) => row?.attendanceId}
              rows={loading ? [] : attendanceListFilter}
              columns={columns}
              pageSize={attendanceList?.rowsPerPage}
              rowCount={attendanceList?.totalItemsCount}
              paginationMode="server"
              onPageChange={(page) => onPageChange({ page: page })}
              loading={loading || loadingData}
              hideFooter={attendanceListFilter.length === 0}
              autoHeight
              disableSelectionOnClick
              disableColumnMenu
              components={{
                NoRowsOverlay: () =>
                  !loading &&
                  !loadingSelect && (
                    <GridOverlay>
                      <h4>Nenhum registro encontrado!</h4>
                    </GridOverlay>
                  ),
              }}
            />
          </Margin>
        </Grid>
      </Grid>
    </>
  );
};

export default BodySearch;
