import { Button, Chip, Container, Grid } from "@material-ui/core";
import styled, { css } from "styled-components";

export const LogoBot = styled.img`
  max-width: 100%;
  max-height: 50px;
  margin-left: 20px;
  margin-top: 20px;
`;

export const LiSecondary = styled.li`
  border: 1px solid ${(props) => props.$color};
  color: ${(props) => props.$color};

  ${(props) =>
    props.$border &&
    css`
      border: ${(props) => props.$border} !important;
    `};

  ${(props) =>
    props.$backgroundColor &&
    css`
      background-color: ${(props) => props.$backgroundColor} !important;
    `};

  :hover {
    color: black;
    cursor: pointer;

    ${(props) =>
      props.$hoverBackgroundColor &&
      css`
        background-color: ${(props) => props.$hoverBackgroundColor} !important;
      `};

    ${(props) =>
      props.$hoverColor &&
      css`
        color: ${(props) => props.$hoverColor} !important;
      `};
  }
`;

export const NavSecondary = styled.nav`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  margin-left: 80px;

  @media only screen and (max-width: 768px) {
    margin-left: 0px;
    padding: 20px;
  }

  ul {
    display: flex;
    list-style: none;
    padding: 0;
    justify-content: space-between;
    width: 100%;
    margin-top: 50px;

    @media only screen and (max-width: 768px) {
      display: block;
      margin-left: 0px;
    }

    ${LiSecondary} {
      margin-top: 20px;
      padding: 8px;
      margin: 0;
      width: 42%;
      border-radius: 9vh;
      background-color: transparent;
      font-size: 1.3rem;
      transition: 0.825s;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;

      @media only screen and (max-width: 768px) {
        width: 100%;
        padding: 0;
        margin: 0;
        margin-bottom: 25px;
        height: 50px;
      }
    }
  }
`;

export const Footer = styled.footer`
  position: absolute;
  bottom: 0;
  background-color: blue;
  color: #fff;
  width: 100%;
  height: 100px;
  text-align: center;
  line-height: 100px;
`;

export const ContentSecondary = styled(Container)`
  padding: 20px 10px;
  height: auto;
  @media only screen and (max-width: 768px) {
    padding: 20px;
  }
`;

export const LogoImage = styled.img`
  max-width: 100%;
  max-height: 200px;
  margin-left: 680px;
  @media only screen and (max-width: 768px) {
    width: auto;
    max-width: 85%;
  }
`;

export const ChipLegend = styled(Chip)`
  ${(props) =>
    props["bg-color"] &&
    css`
      background-color: ${(props) => props.theme.palette[props["bg-color"]]};
    `};
  position: relative;
  width: 70px;
  bottom: 3px;
  height: 20px;
  margin-top: 8px;
  font-size: 12px;
  margin-right: 20px;
  margin-left: 10px;
  font-weight: bold;
`;

export const Circle = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(65, 157, 211);
  clip-path: circle(40% at bottom right);

  .Span {
    font-size: 400px;
    font-weight: bold;
    position: absolute;
    color: black;
  }
`;

export const DivContent = styled.div`
  top: 45px;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TextQyon = styled.div`
  top: 105px;
  position: relative;
  max-width: 600px;
  margin-left: 750px;
`;

export const Span = styled.span`
  font-size: 200px;
  font-weight: bold;
  position: absolute;
  color: black;
`;

export const divStyleLogo = styled.div`
  margin-left: 50px;
  position: relative;
`;

export const LogoQyon = styled.img`
  max-width: 100%;
  max-height: 50px;
  margin-left: 10px;
  margin-top: 0px;
`;

export const HeaderButtons = styled(Grid)`
  justify-content: left;
  align-items: left;
`;

export const DivText = styled.div`
  font-size: 22px;
  margin-top: 40px;
  margin-left: 80px;
  width: 50%;
  color: grey;
`;

export const LineDesing = styled.div`
  width: 100%;
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(65, 157, 211, 1) 35%,
    rgba(0, 212, 255, 1) 100%
  );
  padding: 5px;
`;

export const ButtonPortal = styled(Button)`
  position: absolute;
  left: 25px;
`;

export const LogoQyonNfse = styled.img`
  display: block;
  margin: auto;
  max-width: 100px;
  margin-top: 50px;
`;

export const DivDataGrid = styled.div`
  height: 500px;
  width: 100%;
  margin-top: 20px;
  margin-left: 50px;
  margin-right: 50px;
`;

export const Headerstyled = styled.header`
  color: black;
  height: 50px;
  padding: 10px;
`;

export const Navstyled = styled.nav`
  position: relative;
  float: right;
  right: 0;
`;

export const ULtyled = styled.ul`
  list-style: none;
  display: flex;
`;

export const Listyled = styled.li`
  margin-right: 100px;
  font-weight: bold;
`;
export const Astyled = styled.a`
  color: black;
  text-decoration: none;
  color: #8f8f8f;
  font-size: 20px;
  font-weight: 400;
  transition: all 0.5s ease-in-out;
  position: relative;
  text-transform: uppercase;
  &::before {
    content: attr(data-item);
    transition: 0.5s;
    color: #1976d2;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 0;
    overflow: hidden;
  }
  &:hover {
    &::before {
      width: 100%;
      transition: all 0.5s ease-in-out;
    }
  }
`;

export const H1Styled = styled.h1`
  margin-top: 70px;
  margin-left: 70px;
  font-weight: 900;
  font-size: 3.5rem;
  text-transform: uppercase;
  color: black;
  font-family: "Source Sans Pro", sans-serif;
`;
