import axios from "axios";

export const getListPrefectureNFSe = async () => {
  const url = process.env.REACT_APP_XML_API_URL;

  return await axios
    .get(url + "/v1/fiscal-intelligence/nfse/prefecture/list", {})
    .then((response) => {
      if (response.status !== 200) {
        return null;
      } else {
        return response;
      }
    })
    .catch((err) => {
      if (err.response) {
        return err.response;
      }
      return null;
    });
};

export const getListCND = async () => {
  return await axios
    .get(`${process.env.REACT_APP_CND_API_URL}/v1/public/cnd`)
    .then((response) => {
      if (response.status !== 200) {
        return null;
      } else {
        return response;
      }
    })
    .catch((err) => {
      if (err.response) {
        return err.response;
      }
      return null;
    });
};
