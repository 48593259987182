import { useContext } from "react";
import { GlobalContext } from "../../../contexts/GlobalContext";
import { notify } from "../../../utils/notify";
import { validNotNull } from "../../../utils/validations";
import { ModalCurrentUserContext } from "../contexts/ModalCurrentUserContext";
import { updateUserName } from "../../../repositories/portal_api/Users";

export default function useCurrentUserController() {
  const {
    //Users
    setCurrentUserModalOpen,
    setCurrentUserModalIndex,
    id,
    firstName,
    lastName,
    setLoading,
    setExpanded,
    setErrors,
  } = useContext(ModalCurrentUserContext);

  const { setUserLogged, userLogged } = useContext(GlobalContext);

  const handleCloseUser = () => {
    setCurrentUserModalOpen(false);
    setCurrentUserModalIndex(-1);
  };

  const handleSaveUser = async () => {
    try {
      setLoading(true);
      const valid = validNotNull([
        { value: firstName, key: "firstName" },
        { value: lastName, key: "lastName" },
      ]);

      setErrors(valid.errorsValidation);
      if (valid.error) return;

      if (
        firstName === userLogged.firstName &&
        lastName === userLogged.lastName
      ) {
        setCurrentUserModalOpen(false);
      }

      const respUser = await updateUserName(id, firstName, lastName);

      if (respUser?.success) {
        setUserLogged({
          firstName,
          lastName,
        });
        notify("Perfil atualizado com sucesso", true, "success");
        setCurrentUserModalOpen(false);
      }
      return;
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleChangeOpenAcordion = (accordion) => {
    setExpanded((current) => ({
      ...current,
      [accordion]: !current[accordion],
    }));
  };

  return {
    //Functions
    handleSaveUser,
    handleCloseUser,
    handleChangeOpenAcordion,
  };
}
