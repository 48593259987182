import { search } from "../../BaseRepositoryComponents";

const newPath = `${process.env.REACT_APP_NEW_PAINEL_API_URL}/v1`;

//TODO MÉTODO NÃO CHAMADO
// export async function getCertificatesFromAccountant() {
//   const url = `${newPath}/certificate`;

//   return search({
//     path: url,
//     method: "GET",
//   });
// }

export async function getCertificatesByDocument(document) {
  const url = `${newPath}/certificate/${document}`;

  return search({
    path: url,
    method: "GET",
  });
}

export function a1UploadAndProcuration(body) {
  return search({
    path: `${newPath}/certificate/a1-upload-and-procuration`,
    method: "POST",
    myBody: body,
  });
}
