import { Grid } from "@material-ui/core";
import React, { useContext } from "react";
import { TemplateTypeChips } from "../styles/GuideReport";
import { ButtonTogleStyle, DivStyle } from "../../../components/modal/Modal";
import { DocumentGuideContext } from "../contexts/DocumentGuideContext";

const DocumentFolderTabsPage = ({
  folders,
  handleTabChange,
  handleChipChange,
  ...props
}) => {
  const { tabValue, chipValue } = useContext(DocumentGuideContext);

  return (
    <>
      <DivStyle style={{ marginLeft: 15, marginTop: 10 }}>
        {folders.current.map((item) => (
          <>
            {item.parent_id === null ? (
              <>
                <ButtonTogleStyle
                  key={item.folder_id}
                  active={tabValue === item.folder_id}
                  onClick={() => {
                    handleTabChange(item.folder_id);
                  }}
                >
                  {item.name}
                </ButtonTogleStyle>
                &nbsp;
              </>
            ) : null}
          </>
        ))}
        <br />
      </DivStyle>
      {tabValue !== "COMMS_CONFIG" && (
        <Grid
          container
          justifyContent="flex-start"
          xs={12}
          style={{
            gap: "30px",
            marginLeft: 30,
            marginBottom: 16,
          }}
        >
          <>
            {folders.current.map((item) => {
              if (item.parent_id === tabValue) {
                return (
                  <TemplateTypeChips
                    onClick={() => {
                      handleChipChange(item.folder_id);
                    }}
                    active={chipValue === item.folder_id}
                  >
                    {item.name}
                  </TemplateTypeChips>
                );
              } else {
                return null;
              }
            })}
          </>
        </Grid>
      )}
    </>
  );
};

export default DocumentFolderTabsPage;
