import { notify } from "../../../utils/notify";
import { search } from "../../../repositories/BaseRepositoryComponents";

const newPath = `${process.env.REACT_APP_NEW_PAINEL_API_URL}`;

export async function sendHubDocuments(body) {
  if (!body?.foldersCheckbox) {
    notify("Selecione ao menos uma pasta destino para o documento!");
    return false;
  }

  const url = `${newPath}/v1/document/upload-emmit`;

  return search({
    path: url,
    method: "POST",
    myBody: body,
  });
}
