import React from "react";
import { Switch } from "react-router-dom";
import MiddlewareRoute from "../Middleware";
import { GlobalVariables } from "../../GlobalVariables";
import ESocialMigrations from "../../pages/ESocialMigrations/ESocialMigrations";

const ESocialMigrationModule = () => {
  return (
    <Switch>
      <MiddlewareRoute
        routePermissionId={GlobalVariables.PERMISSIONS.CLIENTES_VISUALIZATION}
        path="/esocial/migrations"
        sidebar
        component={ESocialMigrations}
        noPaddingSidebar
      />
    </Switch>
  );
};

export default ESocialMigrationModule;
