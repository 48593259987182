import React, { useContext } from "react";
import { CustomAccordion } from "../../../../pages/GuideReports/styles/CommsConfig";
import {
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
  Grid,
  MenuItem,
  RadioGroup,
  Select,
  Tooltip,
} from "@material-ui/core";
import {
  ActiveCheckbox,
  FrequencyGrid,
  FrequencyTitle,
  IconButtonTemplate,
  SingleFrequencyGrid,
} from "../../styles/ModalServicesConfig";
import { Settings, ExpandMore as ExpandMoreIcon } from "@material-ui/icons";
import { EmailTemplate } from "../../../icons/EmailTemplate";
import InfoIcon from "@material-ui/icons/Info";
import { v4 as uuidv4 } from "uuid";
import {
  generateHoursMenuItems,
  generateMonthDaysMenuItems,
} from "../utils/ModalCommsConfigUtils";
import { GlobalContext } from "../../../../contexts/GlobalContext";

const ModalCommsConfigBody = ({
  listServices,
  selectedEntityId,
  selectedServices,
  setSelectedServices,
  handleOpenPublicationChannels,
  setScreen,
  handleOpenTemplate,
  DEFAULT_INSTANT_TEMPLATE,
  DEFAULT_SCHEDULED_TEMPLATE,
  systemId,
  ...props
}) => {
  const { userLogged, companyUser } = useContext(GlobalContext);

  function handleSelectServiceNotification(service, checked) {
    let modifiedServices = { ...selectedServices };

    if (!modifiedServices[service.id] && checked) {
      modifiedServices[service.id] = createCommConfigBody(service);
    } else if (!checked) {
      modifiedServices[service.id].enabled = checked;
      // delete modifiedServices[service.id];
    } else if (checked) {
      modifiedServices[service.id].enabled = checked;
    }

    if (checked && !modifiedServices[service.id]?.documents) {
      modifiedServices[service.id].documents = [];
    }

    setSelectedServices(modifiedServices);
  }

  function handleChangeTypeNotification(serviceId, type) {
    let newServices = { ...selectedServices };
    newServices[serviceId].enabled = true;
    newServices[serviceId].type_notification = type;

    if (type === 0) {
      newServices[serviceId].template_id = DEFAULT_INSTANT_TEMPLATE;
    } else {
      newServices[serviceId].template_id = DEFAULT_SCHEDULED_TEMPLATE;
    }

    setSelectedServices(newServices);
  }

  function handleChangeTimeNotification(serviceId, time) {
    let newServices = { ...selectedServices };
    newServices[serviceId].time_notification = time;
    setSelectedServices(newServices);
  }

  function handleChangeDayWeekNotification(serviceId, dayWeek) {
    let newServices = { ...selectedServices };
    newServices[serviceId].day_week_notification = dayWeek;
    setSelectedServices(newServices);
  }

  function handleChangeDayMonthNotification(serviceId, dayMonth) {
    let newServices = { ...selectedServices };
    newServices[serviceId].day_month_notification = dayMonth;
    setSelectedServices(newServices);
  }

  function getAccountantId() {
    if (companyUser?.isAccountant) {
      return companyUser?.companyId;
    } else {
      return companyUser?.accountantId;
    }
  }

  function createCommConfigBody(service) {
    const accountantId = getAccountantId();

    let config = {
      id: uuidv4(),
      service_id: service.id,
      enabled: true,
      type_notification: 2,
      time_notification: "06:00",
      day_week_notification: "Segunda",
      day_month_notification: null,
      user_id: userLogged?.userId,
      accountant_id: accountantId,
      entity_id: selectedEntityId || null,
      documents: [],
      template_id: DEFAULT_SCHEDULED_TEMPLATE,
      system_id: systemId,
    };

    return config;
  }

  return (
    <>
      {listServices.map((item) => {
        return (
          <CustomAccordion
            key={item.id}
            item
            expanded={selectedServices[item.id]?.enabled || false}
            onChange={(ev) => {
              ev.stopPropagation();
              handleSelectServiceNotification(
                item,
                !selectedServices[item.id]?.enabled
              );
            }}
          >
            <AccordionSummary
              disabled={item.disabled}
              expandIcon={
                <ExpandMoreIcon style={{ width: "30px", height: "30px" }} />
              }
              style={{
                flexDirection: "row-reverse",
                paddingLeft: "5px",
                paddingBottom: "-22px",
              }}
            >
              <FormControlLabel
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "10px",
                }}
                control={
                  <>
                    <ActiveCheckbox
                      checked={selectedServices[item.id]?.enabled || false}
                      color="primary"
                    />
                  </>
                }
                label={item.service}
              />

              {(item.service === "Email" || item.service === "Sms") && (
                <>
                  {selectedEntityId && (
                    <Tooltip title="Configure aqui quem será notificado">
                      <IconButtonTemplate
                        disabled={
                          !selectedServices[item.id]?.enabled &&
                          !selectedServices[item.id]?.type_notification
                        }
                        onClick={(event) => {
                          event.stopPropagation();
                          handleOpenPublicationChannels(item.id);
                        }}
                      >
                        <Settings />
                      </IconButtonTemplate>
                    </Tooltip>
                  )}

                  {item.service === "Email" && (
                    <>
                      <Tooltip title="Configure Aqui os Templates dos Emails">
                        <IconButtonTemplate
                          disabled={
                            !selectedServices[item.id]?.enabled &&
                            !selectedServices[item.id]?.type_notification
                          }
                          style={{
                            position: "absolute",
                            right: 0,
                            alignContent: "end",
                            marginRight: "50px",
                          }}
                          onClick={(ev) => {
                            ev.stopPropagation();
                            handleOpenTemplate(item.id);
                          }}
                        >
                          <EmailTemplate
                            disabled={
                              !selectedServices[item.id]?.enabled &&
                              !selectedServices[item.id]?.type_notification
                            }
                          />
                        </IconButtonTemplate>
                      </Tooltip>
                    </>
                  )}
                </>
              )}
            </AccordionSummary>
            <AccordionDetails>
              <RadioGroup>
                <Grid container xs={12}>
                  <SingleFrequencyGrid>
                    <ActiveCheckbox
                      checked={
                        selectedServices[item.id]?.type_notification === 0
                      }
                      onChange={() => {
                        handleChangeTypeNotification(item.id, 0);
                      }}
                      color="primary"
                    />
                    <FrequencyTitle>Notificar Individualmente</FrequencyTitle>
                    <Tooltip title="Notifica individualmente cada documento">
                      <InfoIcon style={{ color: "#BFC2C7" }} />
                    </Tooltip>
                  </SingleFrequencyGrid>

                  <FrequencyGrid container>
                    <ActiveCheckbox
                      checked={
                        selectedServices[item.id]?.type_notification === 1
                      }
                      onChange={() => {
                        handleChangeTypeNotification(item.id, 1);
                      }}
                      color="primary"
                    />
                    <FrequencyTitle>Notificar 1 Vez ao Dia</FrequencyTitle>
                    <Tooltip title="Notifica os documentos 1 vez ao dia, no horário especificado ">
                      <InfoIcon style={{ color: "#BFC2C7" }} />
                    </Tooltip>
                    <Select
                      onChange={(event) => {
                        handleChangeTimeNotification(
                          item.id,
                          event.target.value
                        );
                      }}
                      value={
                        selectedServices[item.id]?.type_notification === 1
                          ? selectedServices[item.id]?.time_notification
                          : ""
                      }
                      displayEmpty
                      variant="outlined"
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 200,
                          },
                        },
                      }}
                      style={{
                        marginLeft: "8px",
                        height: "40px",
                      }}
                      disabled={
                        selectedServices[item.id]?.type_notification !== 1
                      }
                    >
                      {generateHoursMenuItems().map((name) => (
                        <MenuItem key={name + ":00"} value={name}>
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FrequencyGrid>

                  <FrequencyGrid container>
                    <ActiveCheckbox
                      checked={
                        selectedServices[item.id]?.type_notification === 2
                      }
                      onChange={() => {
                        handleChangeTypeNotification(item.id, 2);
                      }}
                      color="primary"
                    />
                    <FrequencyTitle>Notificar 1 Vez na Semana</FrequencyTitle>
                    <Tooltip title="Notifica os documentos 1 vez na semana, no dia da semana e horário especificado">
                      <InfoIcon style={{ color: "#BFC2C7" }} />
                    </Tooltip>
                    <Select
                      value={
                        selectedServices[item.id]?.type_notification === 2
                          ? selectedServices[item.id]?.day_week_notification
                          : ""
                      }
                      onChange={(event) => {
                        handleChangeDayWeekNotification(
                          item.id,
                          event.target.value
                        );
                      }}
                      displayEmpty
                      variant="outlined"
                      style={{
                        marginLeft: "8px",
                        height: "40px",
                      }}
                      disabled={
                        selectedServices[item.id]?.type_notification !== 2
                      }
                    >
                      <MenuItem value={"Domingo"}>Domingo</MenuItem>
                      <MenuItem value={"Segunda"}>Segunda</MenuItem>
                      <MenuItem value={"Terca"}>Terça</MenuItem>
                      <MenuItem value={"Quarta"}>Quarta</MenuItem>
                      <MenuItem value={"Quinta"}>Quinta</MenuItem>
                      <MenuItem value={"Sexta"}>Sexta</MenuItem>
                      <MenuItem value={"Sábado"}>Sábado</MenuItem>
                    </Select>

                    <Select
                      value={
                        selectedServices[item.id]?.type_notification === 2
                          ? selectedServices[item.id]?.time_notification
                          : ""
                      }
                      onChange={(event) => {
                        handleChangeTimeNotification(
                          item.id,
                          event.target.value
                        );
                      }}
                      displayEmpty
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 200,
                          },
                        },
                      }}
                      variant="outlined"
                      style={{
                        marginLeft: "8px",
                        height: "40px",
                      }}
                      disabled={
                        selectedServices[item.id]?.type_notification !== 2
                      }
                    >
                      {generateHoursMenuItems().map((name) => (
                        <MenuItem key={name + ":00"} value={name}>
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FrequencyGrid>

                  <FrequencyGrid container>
                    <ActiveCheckbox
                      onChange={() => {
                        handleChangeTypeNotification(item.id, 3);
                      }}
                      checked={
                        selectedServices[item.id]?.type_notification === 3
                      }
                      color="primary"
                    />
                    <FrequencyTitle>Notificar 1 vez ao Mês</FrequencyTitle>
                    <Tooltip title="Notifica os documentos 1 vez no mês, no dia e horário expecificado">
                      <InfoIcon style={{ color: "#BFC2C7" }} />
                    </Tooltip>
                    <Select
                      value={
                        selectedServices[item.id]?.type_notification === 3
                          ? selectedServices[item.id]?.day_month_notification
                          : ""
                      }
                      onChange={(event) => {
                        handleChangeDayMonthNotification(
                          item.id,
                          event.target.value
                        );
                      }}
                      displayEmpty
                      variant="outlined"
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 200,
                          },
                        },
                      }}
                      style={{
                        marginLeft: "8px",
                        height: "40px",
                      }}
                      disabled={
                        selectedServices[item.id]?.type_notification !== 3
                      }
                    >
                      {generateMonthDaysMenuItems().map((name) => (
                        <MenuItem key={name} value={name}>
                          {name}
                        </MenuItem>
                      ))}
                    </Select>

                    <Select
                      value={
                        selectedServices[item.id]?.type_notification === 3
                          ? selectedServices[item.id]?.time_notification
                          : ""
                      }
                      onChange={(event) => {
                        handleChangeTimeNotification(
                          item.id,
                          event.target.value
                        );
                      }}
                      displayEmpty
                      variant="outlined"
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 200,
                          },
                        },
                      }}
                      style={{
                        marginLeft: "8px",
                        height: "40px",
                      }}
                      disabled={
                        selectedServices[item.id]?.type_notification !== 3
                      }
                    >
                      {generateHoursMenuItems().map((name) => (
                        <MenuItem key={name + ":00"} value={name}>
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FrequencyGrid>
                </Grid>
              </RadioGroup>
            </AccordionDetails>
          </CustomAccordion>
        );
      })}
    </>
  );
};

export default ModalCommsConfigBody;
