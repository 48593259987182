import React, { useEffect, useState } from "react";

import { Grid, IconButton } from "@material-ui/core";
import CachedOutlinedIcon from "@material-ui/icons/CachedOutlined";
import CloseIcon from "@material-ui/icons/Close";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";

import GridLayout from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";

import { Helmet } from "react-helmet";

import ItemGridDragDrop from "./ItemGridDragDrop";
import { GridDragDropStyledComponent } from "./GridDragDropStyledComponent";
import { ItemGridDragDropTitleStyle } from "./ItemGridDragDropTitleStyle";
import Colors from "../../constansts/Colors";
import { LightTooltip, Margin } from "../../styles/GlobalStyle";
import { DividerStyled } from "../chat/styles/GeneralStyle";
import { WidgetList } from "../../widgetVersion/WidgetList";

const GridDragDrop = ({
  layout = [],
  gridStyle = {},
  columns = 12,
  rowHeight = 30,
  width,
  onLayoutChange = () => {},
  ...props
}) => {
  // Caso não seja informado, consome 100% da tela
  width = width ?? window.innerWidth;

  const [conteudo, setConteudo] = useState([]);
  const [layoutGrid, setLayoutGrid] = useState([]);
  const [intervalValues, setIntervalValues] = useState([]);

  // Criação dos widgets
  useEffect(() => {
    (async () => {
      let conteudo = [];
      await ordenarItens(layout);
      layout.forEach((item) => {
        WidgetList.forEach((widget) => {
          if (item.visualizar && item.i === widget.id)
            conteudo.push(CreateItemGridDragDropNew(item, widget.component));
        });
      });
      setConteudo(conteudo);
      setLayoutGrid(layout);
    })();
  }, [layout]);

  // executa o refreshOnLoad
  useEffect(() => {
    if (!layoutGrid) return;

    // Apaga todos os  intervals antigos
    while (intervalValues.length > 0) {
      clearInterval(intervalValues.pop());
    }

    layoutGrid.forEach((item) => {
      if (
        item?.onRefresh &&
        item?.refreshInterval &&
        item?.refreshInterval > 0
      ) {
        let interval = setInterval(() => {
          try {
            // eslint-disable-next-line no-eval
            eval("try{" + item.onRefresh + "}catch(err){}");
            clearInterval(interval);

            // Em caso de erro, cancela o interval
          } catch (err) {
            console.error("RefreshInterval parado:", err);
            clearInterval(interval);
          }
        }, item.refreshInterval);

        setIntervalValues((intervals) => {
          intervals.push(interval);
          return intervals;
        });
      }
    });

    return () => {
      while (intervalValues.length > 0) {
        clearInterval(intervalValues.pop());
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layoutGrid]);

  return (
    <GridLayout
      className="layout"
      layout={layoutGrid}
      cols={columns}
      rowHeight={rowHeight}
      width={width}
      style={{
        margin: "0 auto",
        width: width,
        ...gridStyle,
      }}
      onLayoutChange={(itensAlterados) => {
        const itens = itensAlterados.map((itemAlterado) => {
          // Encontra o itemGridDragDrop
          let itemLayout = layoutGrid.find((itemLayoutFind) => {
            return itemAlterado.i === itemLayoutFind.i;
          });
          // Não foi possível encontrar o item
          if (!itemLayout) return null;

          itemLayout.x = itemAlterado.x;
          itemLayout.y = itemAlterado.y;
          itemLayout.h = itemAlterado.h;
          itemLayout.w = itemAlterado.w;

          return itemLayout;
        });

        // Devolve o item igual ao que foi mandado, só alterando o x, y, h e w
        onLayoutChange(itens);
      }}
    >
      {conteudo}
    </GridLayout>
  );
};

export const CreateItemGridDragDropNew = (item, jsx) => {
  if (!item.w) item.w = 4;
  if (!item.h) item.h = 4;

  return (
    <div key={item.i}>
      <GridDragDropStyledComponent
        backgroundColor={Colors.backgroundDragDropItem}
      >
        {jsx}
      </GridDragDropStyledComponent>
    </div>
  );
};

export const CreateItemGridDragDrop = (
  item = new ItemGridDragDrop(),
  handleDeleteWidgets = () => {}
) => {
  if (!item.w) item.w = 4;
  if (!item.h) item.h = 4;

  return (
    <div key={item.i}>
      <GridDragDropStyledComponent
        backgroundColor={Colors.backgroundDragDropItem}
      >
        {item.importScripts &&
          item.importScripts.map((script, index) => {
            return (
              <div key={`import_${index}`}>
                <Helmet>
                  <script async src={script}></script>
                </Helmet>
              </div>
            );
          })}
        {item.titulo && !item.hideHeader && (
          <ItemGridDragDropTitleStyle customStyle={item.headerStyle ?? {}}>
            <Grid
              item
              container
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item flex={1}>
                <IconButton size="small">
                  <DragIndicatorIcon style={{ fontSize: 18, marginRight: 5 }} />
                </IconButton>
                {item.titulo}
              </Grid>
              <Grid item flex={1}>
                <Grid item container alignItems="center">
                  <Margin top="7" />
                  {item.onRefresh &&
                    (() => {
                      if (item?.refreshOnLoad) {
                        setTimeout(() => {
                          try {
                            // eslint-disable-next-line no-eval
                            eval("try{" + item.onRefresh + "}catch(err){}");
                          } catch (error) {
                            console.error(error);
                          }
                        }, 1500);
                      }

                      return (
                        <Grid item flex={1}>
                          <LightTooltip title="Atualizar">
                            <CachedOutlinedIcon
                              style={{
                                fontSize: 18,
                                marginRight: 10,
                                cursor: "pointer",
                                color: "#636363",
                              }}
                              onClick={() => {
                                // Muito cuidado, o eval é perigoso - https://developer.mozilla.org/pt-BR/docs/Web/JavaScript/Reference/Global_Objects/eval
                                try {
                                  // eslint-disable-next-line no-eval
                                  eval(
                                    "try{" + item.onRefresh + "}catch(err){}"
                                  );
                                } catch (error) {
                                  console.error(error);
                                }
                              }}
                            />
                          </LightTooltip>
                        </Grid>
                      );
                    })()}
                  <Grid item flex={1}>
                    <LightTooltip title="Remover">
                      <CloseIcon
                        style={{
                          fontSize: 18,
                          cursor: "pointer",
                          color: "#636363",
                          marginRight: 10,
                        }}
                        onClick={() => handleDeleteWidgets(item)}
                      />
                    </LightTooltip>
                  </Grid>
                </Grid>
              </Grid>
              <DividerStyled style={{ width: "100%" }} />
            </Grid>
          </ItemGridDragDropTitleStyle>
        )}
        {item.stringChildren && (
          <div
            dangerouslySetInnerHTML={{ __html: item.stringChildren }}
            style={{
              height: `${
                item.titulo && !item.hideHeader ? "calc(100% - 65px)" : "100%"
              }`,
              width: "100%",
            }}
          />
        )}
        {item.stringScripts &&
          item.stringScripts.map((script, index) => {
            return (
              <div key={`script_${index}`}>
                <Helmet>
                  <script async>{script}</script>
                </Helmet>
              </div>
            );
          })}
      </GridDragDropStyledComponent>
    </div>
  );
};

export const ordenarItens = (layoutGrid) => {
  let layoutOrdenado = [];
  layoutOrdenado = ordenarPorArea(layoutGrid);
  layoutOrdenado.forEach((item) => {
    // Ignora os que já vieram preenchidos anteriormente
    if (
      !(item.x === undefined || item.x === null) &&
      !(item.y === undefined || item.y === null)
    )
      return;

    let posicao = 0;
    while (true) {
      let posicaoX = Math.floor(posicao % 12);
      let posicaoY = Math.floor(posicao / 12);
      if (
        validarPosicao(layoutOrdenado, {
          i: item.i,
          x: posicaoX,
          y: posicaoY,
          w: item.w,
          h: item.h,
        })
      ) {
        item.x = posicaoX;
        item.y = posicaoY;
        break;
      }
      posicao++;
    }
  });

  return layoutOrdenado;
};

const validarPosicao = (layoutGrid = [], itemNovo = {}) => {
  let posicaoValida = true;

  // Tem que vir preenchido para validar a posição
  if (!itemNovo.w || !itemNovo.h)
    throw Error("É necessário que os campos do item novo sejam preenchidos");

  // Verifica se já existe algum item fixo interferindo no local atual
  layoutGrid.every((adicionado) => {
    // Item ainda não adicionado no local
    if (
      adicionado.x === undefined ||
      adicionado.x === null ||
      adicionado.y === undefined ||
      adicionado.y === null
    )
      return true;

    if (adicionado.i === itemNovo.i) return true;

    // Encontra os 4 pontos onde o item se encontra
    let adicionadoXFinal = adicionado.x + adicionado.w;
    let adicionadoYFinal = adicionado.y + adicionado.h;

    let novoXFinal = itemNovo.x + itemNovo.w;
    let novoYFinal = itemNovo.y + itemNovo.h;

    if (novoXFinal > 12) {
      posicaoValida = false;
      return false;
    }

    // Validação se está dentro do outro
    // X inicial e final está dentro de um item já adicionado
    // if (itemNovo.i === "Cotacao"
    if (
      (itemNovo.x > adicionado.x && itemNovo.x < adicionadoXFinal) ||
      (novoXFinal > adicionado.x && novoXFinal < adicionadoXFinal) ||
      (itemNovo.x <= adicionado.x && novoXFinal >= adicionadoXFinal)
    ) {
      // Valida se o Y também está dentro do componente
      if (
        (itemNovo.y > adicionado.y && itemNovo.y < adicionadoYFinal) ||
        (novoYFinal > adicionado.y && novoYFinal < adicionadoYFinal) ||
        (itemNovo.y <= adicionado.y && novoYFinal >= adicionadoYFinal)
      ) {
        posicaoValida = false;
        return false;
      }
    }
    return true;
  });

  // Posição válida
  return posicaoValida;
};

const ordenarPorArea = (layoutGrid) => {
  layoutGrid.sort((a, b) => {
    let areaA = a.w * a.h;
    let areaB = b.w * b.h;
    if (areaA > areaB) return -1;
    if (areaA < areaB) return 1;
    return 0;
  });
  return layoutGrid;
};

export default GridDragDrop;
