import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import { getMP3Audio } from "../../../repositories/AudioHandler";
import { useEffect, useState } from "react";
import CloudDoneOutlinedIcon from "@material-ui/icons/CloudDoneOutlined";
import SubdirectoryArrowLeftIcon from "@material-ui/icons/SubdirectoryArrowLeft";

import { notify } from "../../../utils/notify";
import { trainBot } from "../../../repositories/Bot";
import {
  ButtonCancel,
  ButtonSave,
  ButtonText,
  ButtonTogleStyle,
  CloseIconStyle,
  DialogHeader,
  DialogStyle,
  DivFooter,
  DivStyle,
  TextFieldStyle,
} from "../../modal/Modal";

export default function BotConfiguration({ setOpen, propsUser, propsCompany }) {
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");

  const [disabled, setDisabled] = useState(false);
  const [training, setTraining] = useState(0);

  useEffect(() => {
    const title =
      training === 0
        ? "O que você quer que eu saiba responder?"
        : "Personalize uma pergunta e uma resposta";

    getMP3Audio(title).then((result) => {
      var snd = new Audio("data:audio/wav;base64," + result);
      snd.play();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveQuestion = async () => {
    setDisabled(true);

    if (question.trim() === "") {
      notify("Informe sua pergunta!");
      return;
    }

    let govid = "";
    if (training === 1) {
      govid = localStorage.getItem("documentPanel");
    }

    let response = await trainBot(question, answer, govid);

    if (response === null) {
      notify(
        "Ocorreu um problema ao tentar realizar o cadasto de sua pergunta, tente novamente mais tarde!"
      );
      setDisabled(false);
    } else {
      let message =
        "Obrigado, estou estudando para aprender o que me perguntou, logo logo irei saber responder sua pergunta!";
      getMP3Audio(message).then((result) => {
        var snd = new Audio("data:audio/wav;base64," + result);
        snd.play();
      });

      notify(message, true, "success");
      setDisabled(false);
    }
  };

  return (
    <Modal
      open={true}
      onClose={() => {
        setOpen("");
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <DialogStyle>
        <DialogHeader>
          <div className="row">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginBottom: 20,
              }}
            >
              Treinar Qyon Bot
            </div>

            <CloseIconStyle
              onClick={() => {
                setOpen("");
              }}
            />
          </div>
        </DialogHeader>

        <div
          style={{
            paddingLeft: 24,
            paddingRight: 24,
          }}
        >
          <Typography style={{ fontSize: 16 }}>
            O Bot Qyon está pronto para aprender com você! Você pode ensiná-lo a
            responder a perguntas específicas.
          </Typography>
        </div>

        <br />

        <div style={{ paddingLeft: "24px", paddingRight: "24px" }}>
          <DivStyle>
            <ButtonTogleStyle
              active={training === 0}
              onClick={() => {
                setQuestion("");
                setAnswer("");
                setTraining(0);
              }}
            >
              Aprender
            </ButtonTogleStyle>
            &nbsp;
            <ButtonTogleStyle
              active={training === 1}
              onClick={() => {
                setQuestion("");
                setAnswer("");
                setTraining(1);
              }}
            >
              Ensinar Bot
            </ButtonTogleStyle>
            &nbsp;
          </DivStyle>

          <br />

          <Typography style={{ fontSize: 14 }}>
            {training === 0
              ? "Digite uma Pergunta que você gostaria que o Bot aprendesse a responder"
              : "Diga ao Bot a Pergunta e a Resposta que você deseja que ele aprenda"}
          </Typography>
        </div>

        <div style={{ padding: "24px", marginTop: "5px" }}>
          <Typography
            style={{ fontSize: 12, fontWeight: 500, color: "#364461" }}
          >
            Pergunta a ser Aprendida
          </Typography>

          <TextFieldStyle
            placeholder={"Escreva uma Pergunta"}
            size="small"
            variant="outlined"
            fullWidth
            value={question}
            onChange={(ev) => {
              setQuestion(ev.target.value);
            }}
          />

          {training === 1 && (
            <>
              <Typography
                style={{
                  fontSize: 12,
                  fontWeight: 500,
                  color: "#364461",
                  marginTop: 24,
                }}
              >
                Resposta Correspondente
              </Typography>

              <TextFieldStyle
                placeholder={"Escreva uma Resposta"}
                size="small"
                variant="outlined"
                fullWidth
                value={answer}
                onChange={(ev) => {
                  setAnswer(ev.target.value);
                }}
              />
            </>
          )}
        </div>

        <DivFooter>
          <ButtonCancel
            onClick={() => {
              setOpen("");
            }}
            startIcon={<SubdirectoryArrowLeftIcon />}
          >
            Cancelar
          </ButtonCancel>
          &nbsp;
          <ButtonText
            variant="text"
            onClick={async () => {
              saveQuestion();
              setQuestion("");
              setAnswer("");
            }}
          >
            Salvar e Adicionar um Novo
          </ButtonText>
          &nbsp;
          <ButtonSave
            onClick={async () => {
              saveQuestion();
              if (question !== "") {
                setOpen(false);
              }
            }}
            startIcon={<CloudDoneOutlinedIcon />}
            background={disabled}
          >
            {disabled ? "Carregando..." : "Salvar"}
          </ButtonSave>
        </DivFooter>
      </DialogStyle>
    </Modal>
  );
}
