import { BaseObjectDocument } from "./BaseObjectDocument";

export const FgtsObject = (props) => {
  let mainObject = BaseObjectDocument();

  if (props) {
    mainObject.base64 = props?.base64;
    mainObject.name = props?.name;

    mainObject.data = {
      base64: props?.data?.base64,
      company_name: "",
      document_type: "FGTS",
      expire_date: "",
      govid: props?.data?.govid || "",
      period: "",
      total_amount: "0,00",
    };

    mainObject.sendToPay = true;
    mainObject.doc_type = "guide";
    mainObject.use_suggestion = true;
    mainObject.generateTicketEmail = props?.generateTicketEmail;
  }
  return mainObject;
};
