import { Accordion, Grid, TextField, Typography } from "@material-ui/core";
import styled from "styled-components";

export const SearchFilter = styled(Grid)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
`;

export const SearchField = styled(TextField)`
  display: flex;
  height: 40px;
  padding-left: 20px;
  align-items: center;
  flex-shrink: 0;
  align-self: stretch;
  margin-left: 16px;
`;

export const OutsideText = styled.p({
  color: "#364461",
  fontFamily: "Roboto",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: "500",
  lineHeight: "22px",
  display: "flex",
  gap: "10px",
});

export const MainAccordionText = styled(Typography)`
  color: #000000de;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 171.429% */
  letter-spacing: 0.15px;
  margin-left: 16px;
`;

export const CustomAccordion = styled(Accordion)(({ theme }) => {
  return {
    boxShadow: "none", // this styles directly apply to accordion

    ".MuiAccordion-root": {},
    ".MuiAccordion-root:before": {
      backgroundColor: "white",
    },

    ".MuiAccordionDetails-root": {
      padding: "8px 16px 16px 0",
    },
    ".MuiAccordionSummary-root": {}, // this apply to Summary
  };
});

export const VersionButton = styled.p((props) => ({
  display: "flex",
  padding: "8px 24px",
  justifyContent: "center",
  alignItems: "center",
  gap: "8px",
  borderRadius: "24px",
  background: "#F0F1F3",
  cursor: "pointer",

  ...(props?.active && {
    display: "flex",
    padding: "8px 24px",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",

    borderRadius: "24px",
    background: "#0036b3",
    color: "#fbfbfd",
    cursor: "pointer",
    fontFamily: "Roboto",
    fontWeight: "700",
  }),

  "&:hover": {
    display: "flex",
    padding: "8px 24px",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    borderRadius: "24px",
    background: "#0036b3",
    color: "#fbfbfd ",
    cursor: "pointer",
    fontFamily: "Roboto",
  },
}));
