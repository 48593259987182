import { search } from "../../../BaseRepositoryComponents";

const newPath = `${process.env.REACT_APP_NEW_PAINEL_API_URL}`;

export async function postInvite(body) {
  try {
    const url = `${newPath}/v1/email/invite-client`;

    return search({
      path: url,
      method: "POST",
      myBody: body,
    });
  } catch (error) {
    return error || null;
  }
}
