import { WidgetVersion65 } from "./GENERAL/WidgetVersion65";
import { WidgetVersion66 } from "./GENERAL/WidgetVersion66";
import { WidgetVersion67 } from "./GENERAL/WidgetVersion67";
import { WidgetVersion68 } from "./GENERAL/WidgetVersion68";
import { WidgetVersion69 } from "./GENERAL/WidgetVersion69";
import { WidgetVersion71 } from "./CRM/WidgetVersion71";
import { WidgetVersion72 } from "./CRM/WidgetVersion72";
import { WidgetVersion73 } from "./CRM/WidgetVersion73";
import { WidgetVersion74 } from "./CRM/WidgetVersion74";
import { WidgetVersion77 } from "./CRM/WidgetVersion77";
import { WidgetVersion78 } from "./CRM/WidgetVersion78";
import { WidgetVersion49 } from "./ERP/WidgetVersion49";
import { WidgetVersion50 } from "./ERP/WidgetVersion50";
import { WidgetVersion51 } from "./ERP/WidgetVersion51";
import { WidgetVersion52 } from "./ERP/WidgetVersion52";
import { WidgetVersion53 } from "./ERP/WidgetVersion53";
import { WidgetVersion54 } from "./ERP/WidgetVersion54";
import { WidgetVersion55 } from "./ERP/WidgetVersion55";
import { WidgetVersion56 } from "./ERP/WidgetVersion56";
import { WidgetVersion99 } from "./ERP/WidgetVersion99";
import { WidgetVersion91 } from "./XML/WidgetVersion91";
import { WidgetVersion92 } from "./XML/WidgetVersion92";
import { WidgetVersion93 } from "./XML/WidgetVersion93";
import { WidgetVersion94 } from "./XML/WidgetVersion94";
import { WidgetVersion95 } from "./XML/WidgetVersion95";
import { WidgetVersion97 } from "./XML/WidgetVersion97";

export const WidgetList = [
  //ERP
  { id: "49", name: "Contas a Pagar", component: <WidgetVersion49 /> },
  { id: "50", name: "Contas a Receber", component: <WidgetVersion50 /> },
  { id: "51", name: "Contratos", component: <WidgetVersion51 /> },
  { id: "52", name: "Fluxo de caixa", component: <WidgetVersion52 /> },
  { id: "53", name: "NF-e Entrada Pendente", component: <WidgetVersion53 /> },
  { id: "54", name: "Certificado Digital", component: <WidgetVersion54 /> },
  { id: "55", name: "Vendas", component: <WidgetVersion55 /> },
  { id: "56", name: "CND", component: <WidgetVersion56 /> },
  { id: "99", name: "Boletos Qyon - 2ª Via", component: <WidgetVersion99 /> },

  //Geral
  { id: "65", name: "Previsão do Tempo", component: <WidgetVersion65 /> },
  { id: "66", name: "Criptomoedas", component: <WidgetVersion66 /> },
  { id: "67", name: "Bitcoin", component: <WidgetVersion67 /> },
  { id: "68", name: "Cotações (comercial)", component: <WidgetVersion68 /> },
  { id: "69", name: "Jornal Contábil", component: <WidgetVersion69 /> },

  //CRM
  { id: "71", name: "Notícias Importantes", component: <WidgetVersion71 /> },
  { id: "72", name: "Acesso ao App CRM", component: <WidgetVersion72 /> },
  {
    id: "73",
    name: "Qualidade do Atendimento",
    component: <WidgetVersion73 />,
  },
  {
    id: "74",
    name: "Relatório (Tickets x Situação)",
    component: <WidgetVersion74 />,
  },
  { id: "77", name: "Atendimentos", component: <WidgetVersion77 /> },
  { id: "78", name: "Tickets", component: <WidgetVersion78 /> },

  //XML
  { id: "91", name: "Certificados", component: <WidgetVersion91 /> },
  { id: "92", name: "NF-e Entradas", component: <WidgetVersion92 /> },
  { id: "93", name: "NF-e Saídas", component: <WidgetVersion93 /> },
  { id: "94", name: "CT-e Entradas", component: <WidgetVersion94 /> },
  { id: "95", name: "CT-e Saídas", component: <WidgetVersion95 /> },
  { id: "97", name: "Atividades do QYON Bot", component: <WidgetVersion97 /> },
];
