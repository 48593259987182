import React, { useContext } from "react";
import {
  ButtonCancel,
  CloseIconStyle,
  DialogHeader,
  DialogStyle,
} from "../../../components/modal/Modal";
import {
  CircularProgress,
  DialogActions,
  DialogContent,
  Modal,
} from "@material-ui/core";
import { DocumentGuideContext } from "../contexts/DocumentGuideContext";

const ModalPreviewPdf = ({ open, handleClose, ...props }) => {
  const { pdf, setPdf, clickedRow } = useContext(DocumentGuideContext);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <DialogStyle style={{ maxHeight: "650px" }}>
        <DialogHeader>
          <div className="row">
            <div style={{ display: "flex", flexDirection: "column" }}>
              {clickedRow.formatedName}
            </div>

            <CloseIconStyle
              onClick={() => {
                handleClose();
              }}
            />
          </div>
        </DialogHeader>

        <DialogContent
          style={{
            width: "100%",
            maxHeight: "500px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {pdf === "" || pdf === undefined ? (
            <CircularProgress />
          ) : pdf ? (
            <iframe
              width="500"
              height="750"
              id="iframepdf"
              src={pdf}
              title="iframePdfDocument"
            ></iframe>
          ) : (
            <CircularProgress />
          )}
        </DialogContent>
        <DialogActions>
          <ButtonCancel
            onClick={() => {
              setPdf("");
              handleClose();
            }}
          >
            Fechar
          </ButtonCancel>
        </DialogActions>
      </DialogStyle>
    </Modal>
  );
};

export default ModalPreviewPdf;
