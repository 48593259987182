import { useEffect, useState } from "react";

import { createTheme } from "@material-ui/core";
import { StylesProvider, ThemeProvider } from "@material-ui/core/styles";

import IdleTimer from "react-idle-timer";

import { QueryClient, QueryClientProvider } from "react-query";
import Loading from "./components/basics/Loading";
import { ChatContextProvider } from "./components/chat/contexts/ChatContext";

import { UseChatMonitorProvider } from "./components/chat/contexts/ChatMonitorContext";
import { UseAuthProvider } from "./contexts/crm/Auth";
import { GlobalContextProvider } from "./contexts/GlobalContext";
import { usePusher } from "./events/pusherHook";
import { UseAttendanceProvider } from "./pages/attendance/contexts/AttendanceContext";
import { NewBotContextProvider } from "./components/newBot/context/NewBotContext";
import { getExistsBySubdomain } from "./repositories/panel/v1/EntityRepository";

import RoutesModules from "./routes/Index";
import Theme from "./styles/Theme";
import { LoginContextProvider } from "./pages/authentications/contexts/LoginContext";
import { HomeContextProvider } from "./pages/default/contexts/HomeContext";
import { DashboardViewContextProvider } from "./pages/dashboard/contexts/DashboardViewContext";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const theme = createTheme({
  palette: {
    primary: {
      main: "#0036b3",
    },
    secondary: {
      main: "#d6e5ff",
    },
    components: {},
  },
});

const notRedirectLogout = [
  "notfound",
  "login",
  "firstlogin",
  "recoverpassword",
];

const allowedHosts = [
  "portal",
  "portal1",
  "portal2",
  "portal3",
  "localhost:3000",
  "localhost:3001",
];

function App() {
  const { permissionSocketConect } = usePusher();

  const [loading, seLoading] = useState(false);

  const handleOnIdle = () => {
    const routeCurrent = window.location.href.split("/").pop().split("?")[0];
    if (!notRedirectLogout.includes(routeCurrent)) {
      window.location.href = `${
        process.env.REACT_APP_ENV_DEV ? "http" : "https"
      }://${window.location.host}/login?logout=true`;
    }
  };

  useEffect(() => {
    const entityDocument = localStorage.getItem("documentPanel");
    const userId = localStorage.getItem("userIdPanel");

    if (entityDocument && userId) {
      const pusher = permissionSocketConect(entityDocument, userId);

      return () => {
        pusher.disconnect();
      };
    }
  }, [permissionSocketConect]);

  useEffect(() => {
    (async () => {
      try {
        seLoading(true);

        const subdomain =
          localStorage.getItem("subdomain") ||
          window.location.host.substring(
            0,
            window.location.host.indexOf(".qyon.")
          ) ||
          window.location.host;

        if (allowedHosts.includes(subdomain)) {
          return;
        }

        const routeCurrent = window.location.href.split("/");
        if (
          subdomain === sessionStorage.getItem("hostPanel") ||
          routeCurrent[routeCurrent.length - 1] === "notfound"
        ) {
          return;
        }

        const domainValid = await getExistsBySubdomain(subdomain);

        sessionStorage.clear();
        if (!domainValid?.success) {
          window.location.href = window.location.host.includes("portal3")
            ? "https://portal3.qyon.com/login?logout=true"
            : "https://portal.qyon.com/login?logout=true";
        } else {
          sessionStorage.setItem("hostPanel", subdomain);
        }
      } catch (error) {
        console.error(error);
      } finally {
        seLoading(false);
      }
    })();
  }, []);

  return (
    <>
      {loading ? (
        <Loading open={loading} />
      ) : (
        <>
          <IdleTimer
            timeout={1000 * 60 * 150}
            onIdle={handleOnIdle}
            debounce={100}
          />

          <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={theme}>
              <Theme>
                <StylesProvider injectFirst>
                  <GlobalContextProvider>
                    <UseAuthProvider>
                      <UseAttendanceProvider>
                        <UseChatMonitorProvider>
                          <ChatContextProvider>
                            <NewBotContextProvider>
                              <LoginContextProvider>
                                <HomeContextProvider>
                                  <DashboardViewContextProvider>
                                    <RoutesModules />
                                  </DashboardViewContextProvider>
                                </HomeContextProvider>
                              </LoginContextProvider>
                            </NewBotContextProvider>
                          </ChatContextProvider>
                        </UseChatMonitorProvider>
                      </UseAttendanceProvider>
                    </UseAuthProvider>
                  </GlobalContextProvider>
                </StylesProvider>
              </Theme>
            </ThemeProvider>
          </QueryClientProvider>
        </>
      )}
    </>
  );
}

export default App;
