import moment from "moment/moment";

export const isExpired = (row) => {
  const yourDate = moment(row.valid_to);
  const isBeforeToday = yourDate.isBefore(moment(), "day");
  return isBeforeToday;
};

export const isWarningInXDays = (row, day) => {
  const yourDate = moment(row.valid_to);

  const daysDifference = yourDate.diff(moment(), "days");

  if (daysDifference <= day) {
    return true;
  }

  return false;
};

export const getExpirationInDays = (date) => {
  const date_certificate = Date.parse(date);
  const date_now = Date.now();
  const difference = date_certificate - date_now;
  const days = Math.ceil(difference / (1000 * 60 * 60 * 24));

  return days;
};
