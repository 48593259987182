import React from "react";
import { Helmet } from "react-helmet";
import { ItemGridDragDropTitleStyle } from "../../components/grid-drag-drop/ItemGridDragDropTitleStyle";
import { Grid, IconButton } from "@material-ui/core";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import { LightTooltip, Margin } from "../../styles/GlobalStyle";
import CachedOutlinedIcon from "@material-ui/icons/CachedOutlined";
import CloseIcon from "@material-ui/icons/Close";
import { DividerStyled } from "../../components/chat/styles/GeneralStyle";
import useDashboardViewController from "../../pages/dashboard/controllers/DashboardViewController";

export const WidgetVersion97 = () => {
  const { handleDeleteWidgets } = useDashboardViewController();

  const item = {
    i: "97",
    importScripts: ["https://app2.weatherwidget.org/js/?id=ww_ef72f3d7b4d4d"],
    stringScripts: [""],
    onRefresh: `
  try {
    let date = (new Date()).getFullYear() + '-' + ('0' + ((new Date()).getMonth() + 1)).slice(-2) + '-' + ('0' + (new Date()).getDate()).slice(-2);
    let field = localStorage.getItem('isAccountantPanel') == 'true' ? 'parent' : 'cnpj';
    let value = localStorage.getItem('isAccountantPanel') == 'true' ? localStorage.getItem('documentPanel') : localStorage.getItem('accountantCnpjPanel');
    
    let show = (error) => {
      document.getElementById('AtvBotfetchError').style.display = error ? 'flex' : 'none';
      document.getElementById('AtvBotwidGet').style.display = !error ? 'flex' : 'none';
    };

    let api = async () => await fetch(\`https://meter.qyon.info/v1/meter?\${field}=\${value}&startDate=\${date}T00:00:00&endDate=\${date}T23:59:59\`)
      .then(e => e.json())
      .catch(t => {
        console.log('[error][widget][GR][important-notices] new interacts', t);
        show(true);
      });

    try {
      (async () => {
        let e = await api();
        if (e.list == null) {
          show(true);
          return;
        }
        let list = e.list.sort((a, b) => b.total - a.total);
        let n = '';
        for (let t of list) {
          n += \`<div style='display: flex; border-bottom: #E5E5E5 solid 1px; overflow: auto;'>
                   <div style='color: #ffffff; font: normal normal bold 14px/16px Ubuntu; text-align: center; width: 40px; height: 40px; line-height: 40px; background: #0A73DB; border-radius: 5px;'>\${t.total}</div>
                   <div style='padding: 20px; text-align: initial;'>\${t.name}</div>
                 </div>\`;
        }
        document.getElementById('AtvBotwidGet').innerHTML = n;
        show(false);
      })();
    } catch (t) {
      console.log('[error][widget][GR][important-notices] new interacts', t);
      show(true);
    }
  } catch (e) {
    console.log('[error][widget][GR][important-notices] new interacts', e);
  }
`,
    refreshOnLoad: true,
  };

  return (
    <>
      {item.importScripts.map((script, index) => {
        return (
          <div key={`import_${index}`}>
            <Helmet>
              <script async src={script}></script>
            </Helmet>
          </div>
        );
      })}

      <ItemGridDragDropTitleStyle customStyle={item.headerStyle ?? {}}>
        <Grid item container justifyContent="space-between" alignItems="center">
          <Grid item flex={1}>
            <IconButton size="small">
              <DragIndicatorIcon style={{ fontSize: 18, marginRight: 5 }} />
            </IconButton>
            Atividades do QYON Bot
          </Grid>
          <Grid item flex={1}>
            <Grid item container alignItems="center">
              <Margin top="7" />
              {item.onRefresh &&
                (() => {
                  if (item?.refreshOnLoad) {
                    setTimeout(() => {
                      try {
                        // eslint-disable-next-line no-eval
                        eval("try{" + item.onRefresh + "}catch(err){}");
                      } catch (error) {
                        console.error(error);
                      }
                    }, 1500);
                  }

                  return (
                    <Grid item flex={1}>
                      <LightTooltip title="Atualizar">
                        <CachedOutlinedIcon
                          style={{
                            fontSize: 18,
                            marginRight: 10,
                            cursor: "pointer",
                            color: "#636363",
                          }}
                          onClick={() => {
                            // Muito cuidado, o eval é perigoso - https://developer.mozilla.org/pt-BR/docs/Web/JavaScript/Reference/Global_Objects/eval
                            try {
                              // eslint-disable-next-line no-eval
                              eval("try{" + item.onRefresh + "}catch(err){}");
                            } catch (error) {
                              console.error(error);
                            }
                          }}
                        />
                      </LightTooltip>
                    </Grid>
                  );
                })()}
              <Grid item flex={1}>
                <LightTooltip title="Remover">
                  <CloseIcon
                    style={{
                      fontSize: 18,
                      cursor: "pointer",
                      color: "#636363",
                      marginRight: 10,
                    }}
                    onClick={() => handleDeleteWidgets(item)}
                  />
                </LightTooltip>
              </Grid>
            </Grid>
          </Grid>
          <DividerStyled style={{ width: "100%" }} />
        </Grid>
      </ItemGridDragDropTitleStyle>

      <div
        style={{
          height: `${"calc(100% - 65px)"}`,
          width: "100%",
          overflowY: "scroll",
        }}
      >
        <head>
          <meta charset="UTF-8" />
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link
            rel="preconnect"
            href="https://fonts.gstatic.com"
            crossOrigin="anonymous"
          />
          <link
            href="https://fonts.googleapis.com/css2?family=Roboto&display=swap"
            rel="stylesheet"
          />
        </head>
        <body style={{ fontFamily: "'Roboto', sans-serif" }}>
          <div
            id="AtvBot_widget"
            style={{
              overflow: "auto",
              height: "100%",
              padding: "0 10px 10px 10px",
              position: "relative",
              backgroundColor: "white",
            }}
          >
            <div
              id="AtvBotfetchError"
              style={{
                position: "absolute",
                width: "calc(100% - 20px)",
                height: "100%",
                display: "none",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span
                className="material-icons-round"
                style={{ fontSize: "3em", margin: "8px" }}
              >
                error_outline
              </span>
              <p>
                Não foi possível carregar os dados
                <br />
                Tente novamente
              </p>
            </div>
            <div
              id="AtvBotwidGet"
              style={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
                textAlign: "center",
                margin: "auto",
                overflowY: "auto",
              }}
            >
              Carregando...
            </div>
          </div>
        </body>
      </div>

      {item.stringScripts &&
        item.stringScripts.map((script, index) => {
          return (
            <div key={`script_${index}`}>
              <Helmet>
                <script async>{script}</script>
              </Helmet>
            </div>
          );
        })}
    </>
  );
};
