import * as React from "react";

function GFIcon(props) {
  return (
    <svg
      width={20}
      height={22}
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.429 16.438l-3.712-3.712a.858.858 0 00-.09-.08l-.012-.01a1.088 1.088 0 00-.687-.245H7.522a1.084 1.084 0 000 2.166h6.958l.89.89.145.14 1.617 1.617-1.708 1.708-.107-.141a3.532 3.532 0 00-2.842-1.417H5.03c-.769 0-1.395-.612-1.395-1.364l-.001-4.993a1.08 1.08 0 00-2.16 0v4.993a3.53 3.53 0 001.05 2.49 3.538 3.538 0 002.505 1.03h7.444c.754 0 1.38.6 1.396 1.336v.14l.002.014c.041.497.425 1 1.08 1 .29 0 .561-.113.766-.317l3.712-3.713c.204-.205.317-.477.317-.766 0-.29-.113-.562-.317-.766zM4.374 9.368l.01.01c.192.157.438.245.688.245h7.406a1.084 1.084 0 000-2.166h-6.96l-.89-.89-.144-.14L2.867 4.81l1.706-1.707.108.14a3.542 3.542 0 002.832 1.404h5.507l.31.002 3.026-.002v6.36a1.08 1.08 0 001.077 1.077 1.08 1.08 0 001.078-1.078V2.953a.464.464 0 00-.463-.463H7.514c-.723 0-1.32-.532-1.389-1.238l-.001-.016v-.007l.006-.026v-.122a.64.64 0 00-.001-.038v-.014l-.002-.015A1.122 1.122 0 005.922.46 1.05 1.05 0 005.038 0c-.29 0-.561.113-.766.317L3.96.629v.025L.571 4.044a1.076 1.076 0 00-.317.766c0 .29.113.561.317.766l3.712 3.712a.86.86 0 00.09.08z"
        fill="#0036B3"
      />
    </svg>
  );
}

export default GFIcon;
