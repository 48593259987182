import { DialogActions, DialogContent, Grid, Modal } from "@material-ui/core";
import React from "react";
import {
  ButtonCancel,
  ButtonSave,
  CloseIconStyle,
  DialogHeader,
  DialogStyle,
} from "../../../components/modal/Modal";
import SubdirectoryArrowLeftIcon from "@material-ui/icons/SubdirectoryArrowLeft";

export const DialogConfirm = ({
  open,
  title,
  description,
  handleClose,
  handleConfirm,
  confirmMessage = "Sim",
  cancelMessage = "Cancelar",
  ...props
}) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogStyle>
        <DialogHeader id="alert-dialog-title">
          <div className="row">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginBottom: 20,
              }}
            >
              {title}
            </div>
            <CloseIconStyle
              style={{ marginRight: "6px" }}
              onClick={() => {
                handleClose();
              }}
            />
          </div>
        </DialogHeader>
        <DialogContent>
          <Grid
            container
            spacing={1}
            style={{ fontSize: "16px" }}
            justifyContent="center"
          >
            <Grid item xs={12}>
              {description}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ paddingTop: 24, paddingBottom: 24 }}>
          <ButtonCancel
            style={{
              marginRight: 24,
              float: "right",
            }}
            onClick={handleClose}
            startIcon={<SubdirectoryArrowLeftIcon />}
          >
            {cancelMessage}
          </ButtonCancel>
          <ButtonSave
            style={{
              float: "right",
              right: 14,
            }}
            onClick={() => {
              handleConfirm();
            }}
          >
            {confirmMessage}
          </ButtonSave>
        </DialogActions>
      </DialogStyle>
    </Modal>
  );
};
