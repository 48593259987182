import React, { useContext, useEffect, useState } from "react";
import {
  BlueRectangleContainer,
  Copyright,
  EndDotRectangle,
  IconsRectangle,
  LoginContainer,
  PrincipalContainer,
  QyonText,
  SideContainer,
  StartDotRectangle,
} from "./styles/Login";
import { BlueRectangle, DotRectangle, LoginIcons } from "./LoginIcon";
import Loading from "../../components/basics/Loading";
import { LoginContext } from "./contexts/LoginContext";
import { GlobalVariables } from "../../GlobalVariables";

const LoginLayout = ({ children, props }) => {
  const { loading } = useContext(LoginContext);
  const [screenSize, setScreenSize] = useState("small");

  useEffect(() => {
    if (window.innerHeight < 620) {
      setScreenSize("small");
    } else if (window.innerHeight < 960) {
      setScreenSize("medium");
    } else {
      setScreenSize("large");
    }
  }, []);

  window.addEventListener("resize", () => {
    if (window.innerHeight < 620) {
      setScreenSize("small");
    } else if (window.innerHeight < 960) {
      setScreenSize("medium");
    } else {
      setScreenSize("large");
    }
  });

  function getIconSize() {
    if (screenSize === "small") {
      return 300;
    }
    if (screenSize === "medium") {
      return 400;
    }
    if (screenSize === "large") {
      return 500;
    }
  }

  return (
    <>
      <Loading open={loading} />
      <PrincipalContainer>
        <SideContainer>
          <StartDotRectangle>
            <DotRectangle />
          </StartDotRectangle>
          <IconsRectangle>
            <LoginIcons width={getIconSize()} height={getIconSize()} />
          </IconsRectangle>
          <QyonText>
            Potencializando a contabilidade do futuro com Inteligência
            Artificial
          </QyonText>

          <BlueRectangleContainer>
            <BlueRectangle />
          </BlueRectangleContainer>

          <EndDotRectangle>
            {screenSize !== "small" && screenSize !== "medium" && (
              <DotRectangle />
            )}
          </EndDotRectangle>
        </SideContainer>

        <LoginContainer>
          <div>{children}</div>
          <Copyright>
            Todos os direitos reservados a QYON Sistemas Inteligentes © Versão{" "}
            {GlobalVariables.SISTEM_VERSION_DATE}
          </Copyright>
        </LoginContainer>
      </PrincipalContainer>
    </>
  );
};

export default LoginLayout;
