import axios from "axios";
import { search } from "../../../BaseRepositoryComponents";

const path = `${process.env.REACT_APP_NEW_PAINEL_API_URL}/v1/letter`;

export const ListSystemVersion = async (startDate, endDate) => {
  let url = `${path}`;

  if (startDate && endDate) {
    url += `?start_date=${startDate}&end_date=${endDate}`;
  }

  return search({
    path: url,
    method: "GET",
  });
};

//FIXME MODAL NOT WORKING PROPERLY
export const getSystemVersionProduct = async (product) => {
  const queryParams = {};

  queryParams.product = product;

  const url = `${path}?product=${product}`;

  return search({
    path: url,
    method: "GET",
  });
};

export const IDSystem = async (id) => {
  return await axios
    .get(`${path}/${id}/pdf`, {
      headers: {
        Authorization: JSON.parse(localStorage.getItem("cognitoPanel"))[
          "AccessToken"
        ],
      },
    })
    .then((response) => {
      if (response.status !== 200) {
        return null;
      } else {
        return response;
      }
    })
    .catch((err) => {
      if (err.response) {
        return err.response;
      }
      return null;
    });
};

export const downloadPdfFromHtmlById = async (id) => {
  return await axios
    .get(`${path}/${id}/pdf`, { responseType: "blob" })
    .then((response) => {
      if (response.status === 200) return response.data;
      return null;
    })
    .catch((err) => {
      return null;
    });
};

export const downloadPdfById = async (id) => {
  return await axios
    .get(`${path}/pdf/${id}`, { responseType: "blob" })
    .then((response) => {
      if (response.status === 200) return response.data;
      return null;
    })
    .catch((err) => {
      return null;
    });
};
