import { search } from "../../BaseRepositoryComponents";

const newPath = `${process.env.REACT_APP_NEW_PAINEL_API_URL}/v1`;

export async function getProcurations({ grantedDocument }) {
  const url = `${newPath}/certificate/procuration?granted_document=${grantedDocument}`;

  return search({
    path: url,
    method: "GET",
  });
}

export async function getProcurationPermission({
  grantorDocument,
  grantedDocument,
}) {
  const url = `${newPath}/certificate/procuration/permission?grantor_document=${grantorDocument}&granted_document=${grantedDocument}`;

  return search({
    path: url,
    method: "GET",
  });
}
