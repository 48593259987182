import * as React from "react";

function VideoKnowledgeBaseIcon(props) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.5 3a2 2 0 012-2h5.672a2 2 0 011.414.586l5.328 5.328a2 2 0 01.586 1.414V21a2 2 0 01-2 2h-11a2 2 0 01-2-2V3z"
        fill="#B71C1C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5 1.027a2 2 0 011.086.559l5.328 5.328A2 2 0 0119.473 8H13.5a1 1 0 01-1-1V1.027z"
        fill="#EF5350"
      />
      <path
        d="M9.5 16.586V9.414a.4.4 0 01.622-.333l5.379 3.586a.4.4 0 010 .666l-5.38 3.586a.4.4 0 01-.621-.333z"
        fill="#fff"
      />
    </svg>
  );
}

export default VideoKnowledgeBaseIcon;
