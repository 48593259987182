import { search } from "../../../src/repositories/BaseRepositoryComponents";

const baseUrl = process.env.REACT_APP_NEW_PAINEL_API_URL;

export function getEntityDataById(accountantClientId) {
  return search({
    path: `${baseUrl}/v1/entity?id=${accountantClientId}`,
    method: "GET",
  });
}

export function getAccountantClientDataByDocument(document) {
  return search({
    path: `${baseUrl}/v1/entity?document=${document}`,
    method: "GET",
  });
}

export function getAccountantClients() {
  return search({
    path: `${baseUrl}/v1/entity`,
    method: "GET",
  });
}

export function registerAccountantClient(body, automatically_filled = false) {
  return search({
    path: `${baseUrl}/v1/entity`,
    method: "POST",
    myBody: body,
    myHeaders: new Headers({
      Authorization: "Bearer " + localStorage.getItem("tokenPanel"),
      "Entity-Id": localStorage.getItem("companyIdPanel"),
      "Content-Type": "application/json",
      "Content-Language": "pt-BR",
      Automatically_Filled: automatically_filled,
    }),
  });
}

export function updateAccountantClientData(accountantClientId, body) {
  return search({
    path: `${baseUrl}/v1/entity/${accountantClientId}`,
    method: "PUT",
    myBody: body,
  });
}

export function deleteAccountantClient(accountantClientId) {
  return search({
    path: `${baseUrl}/v1/entity/${accountantClientId}`,
    method: "DELETE",
  });
}

export function getAccountantClientPermissionGroups(accountantClientId) {
  return search({
    path: `${baseUrl}/v1/entity/${accountantClientId}/permission-groups`,
    method: "GET",
  });
}

export function postAccountantClientUsers(accountantClientId, body) {
  return search({
    path: `${baseUrl}/v1/entity/${accountantClientId}/users`,
    method: "POST",
    myBody: body,
  });
}

export function requestComercialContact(accountantClientId, body) {
  return search({
    path: `${baseUrl}/v1/entity/${accountantClientId}/comercial-contact`,
    method: "POST",
    myBody: body,
    showMessage: true,
  });
}

export function isComercialContactAlreadyRequested(accountantClientId) {
  return search({
    path: `${baseUrl}/v1/entity/${accountantClientId}/comercial-contact`,
    method: "GET",
    showMessage: false,
  });
}

export function updateBpoActive(accountantClientId, bpoActive) {
  const body = {
    bpo_active: bpoActive,
  };

  return search({
    path: `${baseUrl}/v1/entity/${accountantClientId}/bpo`,
    method: "PUT",
    myBody: body,
  });
}

export function getDataClientView(accountantClientId) {
  return search({
    path: `${baseUrl}/v1/entity/${accountantClientId}/client-view`,
    method: "GET",
  });
}

export function getAccountantClientPlans() {
  return search({
    path: `${baseUrl}/v1/entity/plans`,
    method: "GET",
  });
}

export function verifyUpdateBpo(entityId) {
  return search({
    path: `${baseUrl}/v1/entity/${entityId}/access_bpo`,
    method: "GET",
  });
}
