import { BaseObjectDocument } from "./BaseObjectDocument";

export const DctfObject = (props) => {
  let mainObject = BaseObjectDocument();

  if (props) {
    mainObject.base64 = props.base64;
    mainObject.name = props.name;

    mainObject.data = {
      abscence_triggering_facts: "Não",
      base64: props.data.base64,
      company_name: "",
      date_transmission: "",
      debit_credit_list: "",
      diplomatic_mission_consular_office: "",
      document_type: "DCTF_RETURN",
      govid: props.data.govid || "",
      period: "",
      receipt_number: "",
      rectified_declaration_receipt_number: "",
      tributary_class: "",
    };

    mainObject.sendToPay = true;
    mainObject.fiscal = true;
    mainObject.doc_type = "report";
    mainObject.use_suggestion = true;
    mainObject.generateTicketEmail = props.generateTicketEmail;
  }
  return mainObject;
};
