import { Chip, Grid, Button } from "@material-ui/core";
import styled from "styled-components";

export const HeaderGrid = styled(Grid)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  padding-bottom: -15px;
  border-bottom: 1px solid #cfd8dc;
`;

export const ConfigDocText = styled.div({
  color: "#162238",
  fontFamily: "Roboto",
  fontSize: "24px",
  fontStyle: "normal",
  fontWeight: "700",
});

export const EntityName = styled.div({
  color: "#607D8B",
  fontFamily: "Roboto",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "28px",
  letterSpacing: "0.15px",
});

export const CommDocs = styled(Chip)({
  display: "flex",
  padding: "8px 24px",
  justifyContent: "center",
  alignItems: "center",
  gap: "8px",
  flex: "1 0 0",
  borderRadius: "24px",
  border: "1px solid #0036B3",
  background: "#0036B3",
});

export const DoccCom = styled.button((props) => ({
  display: "flex",
  padding: "8px 24px",
  justifyContent: "center",
  alignItems: "center",
  gap: "8px",
  flex: "1 0 0",
  borderRadius: "24px",
  background: "#F0F1F3",
  color: "#607D8B",
  fontFamily: "Roboto",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: "500",
  lineHeight: "24px",
  cursor: "pointer",
  border: "none",
  marginTop: "12px",
  marginBottom: "12px",

  ...(props?.active && {
    background: "#0036B3",
    color: "#FBFBFD",
    fontWeight: "700",
  }),

  "&:hover": {
    background: "#004AF7",
    color: "#FBFBFD",
    fontWeight: "700",
  },
  "&:disabled": {
    background: "#D3D3D3",
    color: "#A9A9A9",
    cursor: "not-allowed",
    fontWeight: "700",
  },
}));

export const CommDocsGrid = styled(Grid)`
  display: flex;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
`;

export const BlueButton = styled(Button)`
  display: flex;
  height: 40px;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  align-self: stretch;
  border-radius: 8px;
  background: #0036b3;
  color: #fbfbfd;
  text-transform: none;
`;

export const Separator = styled.div({
  color: "#BDBDBD",
  fontFamily: "Roboto",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "20.02px",
  letterSpacing: "0.15px",
});

export const MultipleEmailsText = styled.div({
  color: "#63708A",
  fontFamily: "Roboto",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: "400",
  marginBottom: "8px",
});

export const BackButton = styled(Button)`
  display: flex;
  height: 40px;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  align-self: stretch;
  border-radius: 8px;
  background: #f0f1f3;
  margin-top: 24px;
  text-transform: none;
`;
