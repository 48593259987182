import { useContext } from "react";

import Swal from "sweetalert2";

import { notify } from "../../../utils/notify";
import { DashboardViewContext } from "../contexts/DashboardViewContext";
import {
  deletePanelWidget,
  putPanelPositions,
} from "../../../repositories/portal_api/PanelWidgets";

export default function useDashboardViewController() {
  const { setLoading, panelSelected, layoutDashboard, setLayoutDashboard } =
    useContext(DashboardViewContext);

  const handleSavePositions = async () => {
    try {
      setLoading((current) => ({ ...current, savePosition: true }));

      const positions = JSON.parse(
        localStorage.getItem(`widgesPositionsPanel_${panelSelected?.id}`) ||
          "[]"
      );

      //positions = positions.filter((e) => e !== null);

      const body = positions.map((item) => {
        const json = {
          widget_version_id: item.i,
          postion_x: item.x,
          position_y: item.y,
          height: item.h,
          width: item.w,
        };

        if (item.id) json.id = item.id;

        return json;
      });

      await putPanelPositions({
        body,
      });
      //if (savePosition?.success) notify("Posições salvas.", true, "success");
    } catch (error) {
      console.error(error);
    } finally {
      setLoading((current) => ({ ...current, savePosition: false }));
    }
  };

  const handleDeleteWidgets = async (item) => {
    Swal.fire({
      icon: "question",
      text: "Tem certeza que deseja remover este widget?",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Sim",
      showConfirmButton: true,
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          setLoading((current) => ({ ...current, savePosition: true }));

          const mainResponse = await deletePanelWidget(
            panelSelected?.id,
            item?.i
          );

          if (!mainResponse?.success || !mainResponse?.data) {
            return notify("Não foi possível remover o widget.");
          }

          const widgetsDisplay = layoutDashboard.filter(
            (widget) => widget.i !== item?.i
          );

          localStorage.setItem(
            `widgesPositionsPanel_${panelSelected?.id}`,
            widgetsDisplay
          );

          setLayoutDashboard(widgetsDisplay);
        } catch (error) {
          console.error(error);
        } finally {
          setLoading((current) => ({ ...current, savePosition: false }));
        }
      }
    });
  };

  return {
    handleSavePositions,
    handleDeleteWidgets,
  };
}
