import {
  Backdrop,
  CircularProgress,
  Fade,
  Grid,
  Modal,
  makeStyles,
} from "@material-ui/core";
import React from "react";
import {
  ConfigDocText,
  HeaderGrid,
} from "../styles/ModalPublicationChannelFrame";
import { DeleteText } from "../styles/ModalEmailTemplates";
import { CancelButton } from "../styles/ModalServicesConfig";
import KeyboardReturnIcon from "@material-ui/icons/KeyboardReturn";
import { BlueButton } from "../styles/ModalPublicationChannelFrame";
import CloudDoneOutlinedIcon from "@material-ui/icons/CloudDoneOutlined";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "24px",
    flexDirection: "column",
    gap: "24px",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    width: "800px",
    borderRadius: "16px",
  },
  MuiAccordionroot: {
    "&.MuiAccordion-root:before": {},
  },
}));

export const ModalDeleteTemplate = ({
  open,
  handleClose,
  selectedTemplate,
  document,
  handleConfirmDeleteTemplate,
  ...props
}) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const classes = useStyles();

  return (
    <Modal
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <HeaderGrid container justify="space-between">
            <ConfigDocText style={{ marginBottom: "16px" }}>
              Deletar Template
            </ConfigDocText>

            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              onClick={handleClose}
              style={{ width: "24px", height: "24px", cursor: "pointer" }}
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.29289 3.29289C3.68342 2.90237 4.31658 2.90237 4.70711 3.29289L12 10.5858L19.2929 3.29289C19.6834 2.90237 20.3166 2.90237 20.7071 3.29289C21.0976 3.68342 21.0976 4.31658 20.7071 4.70711L13.4142 12L20.7071 19.2929C21.0976 19.6834 21.0976 20.3166 20.7071 20.7071C20.3166 21.0976 19.6834 21.0976 19.2929 20.7071L12 13.4142L4.70711 20.7071C4.31658 21.0976 3.68342 21.0976 3.29289 20.7071C2.90237 20.3166 2.90237 19.6834 3.29289 19.2929L10.5858 12L3.29289 4.70711C2.90237 4.31658 2.90237 3.68342 3.29289 3.29289Z"
                fill="#7E899E"
              />
            </svg>
          </HeaderGrid>

          {isLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                padding: "30px",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <>
              <div style={{ marginTop: "24px" }}>
                <DeleteText>
                  Tem certeza que deseja deletar "{selectedTemplate?.name}" ?
                </DeleteText>
              </div>

              <Grid
                container
                xs={12}
                style={{ justifyContent: "end", gap: "10px" }}
              >
                <CancelButton onClick={() => handleClose()} item>
                  <KeyboardReturnIcon style={{ marginRight: "4px" }} />
                  Cancelar
                </CancelButton>

                <BlueButton
                  item
                  onClick={async () => {
                    setIsLoading(true);
                    await handleConfirmDeleteTemplate();
                    setIsLoading(false);

                    handleClose();
                  }}
                  style={{
                    background: "#0036B3",
                    marginTop: "24px",
                    display: "flex",
                    justifyContent: "normal",
                  }}
                >
                  <CloudDoneOutlinedIcon style={{ marginRight: "8px" }} />
                  Salvar
                </BlueButton>
              </Grid>
            </>
          )}
        </div>
      </Fade>
    </Modal>
  );
};
