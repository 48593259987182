import React from "react";
import { Helmet } from "react-helmet";
import { ItemGridDragDropTitleStyle } from "../../components/grid-drag-drop/ItemGridDragDropTitleStyle";
import { Grid, IconButton } from "@material-ui/core";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import { LightTooltip, Margin } from "../../styles/GlobalStyle";
import CachedOutlinedIcon from "@material-ui/icons/CachedOutlined";
import CloseIcon from "@material-ui/icons/Close";
import { DividerStyled } from "../../components/chat/styles/GeneralStyle";
import useDashboardViewController from "../../pages/dashboard/controllers/DashboardViewController";

export const WidgetVersion52 = () => {
  const { handleDeleteWidgets } = useDashboardViewController();

  const item = {
    i: "52",
    importScripts: ["https://cdn.jsdelivr.net/npm/chart.js"],
    stringScripts: [""],
    onRefresh: `
    try {
        const GEFC_ENVIRONMENT = localStorage.getItem("environment") ?? "test";
        const GEFC_ERP_API_URL = GEFC_ENVIRONMENT === "prod" ? "https://qerpapi.qyon.com" :
            GEFC_ENVIRONMENT === "beta" ? "https://beta-qerpapi.qyon.com" :
            GEFC_ENVIRONMENT === "dev" ? "http://localhost:85" :
            "https://test-qerpapi.qyon.com";
    
        const GEFC_ERP_API_TOKEN = GEFC_ENVIRONMENT === "prod" ? "eVmDI3J6dCqvxDUyZv16Sr1KBDW0TTNKvZA4LCkY" : "eyJ0ZXN0ZSI6InJhZmFvbGluZG9zejEyMyJ9";

        const GEFC_documentPanel = localStorage.getItem("documentPanel");
    
        const GEFC_getChashFlowStatus = async () => {
            const GEFC_apiCashFlowReturn = await fetch(
                \`\${GEFC_ERP_API_URL}/api/erp/financeiro/fluxocaixa/v1/status?\${new URLSearchParams({empresa_cnpj: GEFC_documentPanel})}\`,
                {
                    method: "GET",
                    headers: new Headers({ Authorization: GEFC_ERP_API_TOKEN })
                }
            )
            .then((res) => {
                if (res.ok) {
                    return res.json();
                }
                throw res.statusText;
            })
            .catch((error) => {
                console.error("Widget GE - Fluxo Caixa, fetch error:", error);
                if (document.getElementById("GEFC_fetchError")) {
                    document.getElementById("GEFC_fetchError").style.display = "flex";
                    document.getElementById("GEFC_loaderAnimation").style.display = "none";
                }
            });
            return GEFC_apiCashFlowReturn?.success ? GEFC_apiCashFlowReturn.data : false;
        };
    
        const GEFC_populateCashFlow = async () => {
            try {
                let GEFC_cashFlowData = await GEFC_getChashFlowStatus();
                if (GEFC_cashFlowData && document.getElementById("GEFC_content")) {
                    GEFC_cashFlowData = sortObj(GEFC_cashFlowData);
                    GEFC_chartBuild(GEFC_cashFlowData);
                    document.getElementById("GEFC_content").style.display = "flex";
                }
            } catch (error) {
                console.error("Widget GE - Fluxo Caixa, populate error:", error);
                document.getElementById("GEFC_fetchError").style.display = "flex";
                document.getElementById("GEFC_content").style.display = "none";
            } finally {
                if (document.getElementById("GEFC_loaderAnimation")) {
                    document.getElementById("GEFC_loaderAnimation").style.display = "none";
                }
            }
        };
    
        function sortObj(obj) {
            return Object.keys(obj)
                .sort()
                .reduce(function (result, key) {
                    result[key] = obj[key];
                    return result;
                }, {});
        }
    
        const GEFC_chartBuild = (GEFC_cashFlowData) => {
            document.getElementById("GEFC_content").innerHTML = "";
            document.getElementById("GEFC_content").innerHTML = '<canvas id="GEFC_todayChartCanvas" style="max-height: 100%"></canvas>';
    
            const GEFC_todayConfig = {
                type: "bar",
                Chart: { defaults: { font: { family: "'Roboto', sans-serif" } } },
                data: {
                    labels: [],
                    datasets: [
                        { label: "C. Pagar Previsto", data: [], borderColor: "rgb(255, 99, 132)", backgroundColor: "rgb(255, 99, 132, 0.5)", type: "bar", order: 4, stack: "C. Pagar" },
                        { label: "C. Pagar Realizado", data: [], borderColor: "rgb(255, 99, 132)", backgroundColor: "rgb(255, 99, 132)", type: "bar", order: 5, stack: "C. Pagar" },
                        { label: "C. Receber Previsto", data: [], borderColor: "rgb(54, 162, 235)", backgroundColor: "rgb(54, 162, 235, 0.5)", type: "bar", order: 2, stack: "C. Receber" },
                        { label: "C. Receber Realizado", data: [], borderColor: "rgb(54, 162, 235)", backgroundColor: "rgb(54, 162, 235)", type: "bar", order: 3, stack: "C. Receber" },
                        { label: "Saldo Previsto", data: [], borderColor: "rgb(255, 205, 86)", backgroundColor: "rgb(255, 205, 86)", type: "line", order: 1 },
                        { label: "Saldo Realizado", data: [], borderColor: "rgb(75, 192, 192)", backgroundColor: "rgb(75, 192, 192)", type: "line", order: 0 }
                    ]
                },
                options: {
                    responsive: true,
                    plugins: {
                        legend: { position: "top", labels: { boxWidth: 8, boxHeight: 8, usePointStyle: true, font: { size: 10 } } },
                        tooltip: { callbacks: { label: function (context) { return \`\${context.dataset.label} R$ \${GEFC_numberUStoBR(context.parsed.y)}\`; } } }
                    },
                    scales: {
                        x: { stacked: true, ticks: { font: { size: 10 } } },
                        y: { stacked: false, beginAtZero: true, ticks: { font: { size: 8 } } }
                    }
                }
            };
    
            const GEFC_todayChart = new Chart(document.getElementById("GEFC_todayChartCanvas"), GEFC_todayConfig);
    
            for (const [GEFC_day, GEFC_dayData] of Object.entries(GEFC_cashFlowData)) {
                GEFC_addTodayChartData(GEFC_dayData, GEFC_todayChart);
            }
    
            GEFC_todayChart.render();
        };
    
        const GEFC_addTodayChartData = (dayData, GEFC_todayChart) => {
            const GEFC_todayConfig = GEFC_todayChart.data;
    
            GEFC_todayChart.data.labels.push(\`\${GEFC_capitalizeFirstLetter(dayData.shortWeekDay)} \${dayData.formatedDate}\`);
            GEFC_todayConfig.datasets[0].data.push(dayData.toPay.expected);
            GEFC_todayConfig.datasets[2].data.push(dayData.toReceive.expected);
            GEFC_todayConfig.datasets[4].data.push(dayData.finalBalance.expected);
    
            if (dayData.time !== "future") {
                GEFC_todayConfig.datasets[1].data.push(dayData.toPay.realized);
                GEFC_todayConfig.datasets[3].data.push(dayData.toReceive.realized);
                GEFC_todayConfig.datasets[5].data.push(dayData.finalBalance.realized);
            }
        };
    
        function GEFC_capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        }
    
        function GEFC_numberUStoBR(number) {
            return number.toLocaleString("pt-br", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        }
    
        document.getElementById("GEFC_loaderAnimation").style.display = "block";
        document.getElementById("GEFC_fetchError").style.display = "none";
        document.getElementById("GEFC_content").style.display = "none";
    
        try {
            GEFC_populateCashFlow();
        } catch (error) {
            console.error("Widget GE - Fluxo Caixa, error:", error);
            document.getElementById("GEFC_fetchError").style.display = "flex";
            document.getElementById("GEFC_loaderAnimation").style.display = "none";
        }
    } catch (err) {}
    `,
    refreshOnLoad: true,
  };

  return (
    <>
      {item.importScripts.map((script, index) => {
        return (
          <div key={`import_${index}`}>
            <Helmet>
              <script async src={script}></script>
            </Helmet>
          </div>
        );
      })}

      <ItemGridDragDropTitleStyle customStyle={item.headerStyle ?? {}}>
        <Grid item container justifyContent="space-between" alignItems="center">
          <Grid item flex={1}>
            <IconButton size="small">
              <DragIndicatorIcon style={{ fontSize: 18, marginRight: 5 }} />
            </IconButton>
            Fluxo de caixa
          </Grid>
          <Grid item flex={1}>
            <Grid item container alignItems="center">
              <Margin top="7" />
              {item.onRefresh &&
                (() => {
                  if (item?.refreshOnLoad) {
                    setTimeout(() => {
                      try {
                        // eslint-disable-next-line no-eval
                        eval("try{" + item.onRefresh + "}catch(err){}");
                      } catch (error) {
                        console.error(error);
                      }
                    }, 1500);
                  }

                  return (
                    <Grid item flex={1}>
                      <LightTooltip title="Atualizar">
                        <CachedOutlinedIcon
                          style={{
                            fontSize: 18,
                            marginRight: 10,
                            cursor: "pointer",
                            color: "#636363",
                          }}
                          onClick={() => {
                            // Muito cuidado, o eval é perigoso - https://developer.mozilla.org/pt-BR/docs/Web/JavaScript/Reference/Global_Objects/eval
                            try {
                              // eslint-disable-next-line no-eval
                              eval("try{" + item.onRefresh + "}catch(err){}");
                            } catch (error) {
                              console.error(error);
                            }
                          }}
                        />
                      </LightTooltip>
                    </Grid>
                  );
                })()}
              <Grid item flex={1}>
                <LightTooltip title="Remover">
                  <CloseIcon
                    style={{
                      fontSize: 18,
                      cursor: "pointer",
                      color: "#636363",
                      marginRight: 10,
                    }}
                    onClick={() => handleDeleteWidgets(item)}
                  />
                </LightTooltip>
              </Grid>
            </Grid>
          </Grid>
          <DividerStyled style={{ width: "100%" }} />
        </Grid>
      </ItemGridDragDropTitleStyle>

      <div
        style={{
          height: `${"calc(100% - 65px)"}`,
          width: "100%",
        }}
      >
        <>
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link
            rel="preconnect"
            href="https://fonts.gstatic.com"
            crossOrigin="true"
          />
          <link
            href="https://fonts.googleapis.com/css2?family=Roboto:wght@100;400&display=swap"
            rel="stylesheet"
          />
          <link
            href="https://fonts.googleapis.com/icon?family=Material+Icons+Round"
            rel="stylesheet"
          />

          <style>
            {`@keyframes GEFC_spinner-grow {
      0% {
        transform: scale(0);
      }
      50% {
        opacity: 1;
        transform: none;
      }
    }`}
          </style>

          <div
            id="GEFC_widget"
            style={{
              height: "100%",
              fontFamily: "'Roboto', sans-serif",
              position: "relative",
            }}
          >
            <div
              id="GEFC_loaderAnimation"
              style={{
                color: "#0d6efd",
                display: "none",
                width: "5rem",
                height: "5rem",
                verticalAlign: "-0.125em",
                backgroundColor: "currentColor",
                borderRadius: "50%",
                opacity: 0,
                animation: "0.75s linear infinite GEFC_spinner-grow",
                animationDuration: "1.5s",
                position: "absolute",
                top: "calc(50% - 2.5rem)",
                left: "calc(50% - 2.5rem)",
              }}
            ></div>
            <div
              id="GEFC_fetchError"
              style={{
                position: "absolute",
                width: "calc(100% - 20px)",
                height: "100%",
                display: "none",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span
                className="material-icons-round"
                style={{ fontSize: "3em", margin: "8px" }}
              >
                error_outline
              </span>
              <p>
                Não foi possível carregar os dados
                <br />
                Tente novamente
              </p>
            </div>
            <div
              id="GEFC_content"
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <canvas
                id="GEFC_todayChartCanvas"
                style={{ maxHeight: "100%" }}
              ></canvas>
            </div>
          </div>
        </>
      </div>

      {item.stringScripts &&
        item.stringScripts.map((script, index) => {
          return (
            <div key={`script_${index}`}>
              <Helmet>
                <script async>{script}</script>
              </Helmet>
            </div>
          );
        })}
    </>
  );
};
