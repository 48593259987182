import { useEffect, useRef, useState } from "react";

import {
  Button,
  ButtonGroup,
  CircularProgress,
  ClickAwayListener,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Grow,
  makeStyles,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import filesize from "filesize";
import { useHistory } from "react-router";
import Btn from "../../../components/buttons/Btn";
import {
  getAttendance,
  getMessages,
} from "../../../repositories/integrations/crm/AttendanceRepository";
import { getFilesS3 } from "../../../repositories/integrations/crm/FilesRepository";
import { Margin, Title } from "../../../styles/GlobalStyle";
import InteractionHistoric from "./InteractionHistoric";

const useStyles = makeStyles((theme) => ({
  dialog: {
    width: "60%",
    minHeight: "65vh",
  },
  popperTest: { position: "fixed", bottom: "11%", right: "20.5%" },
}));

const ModalPreviewMessages = ({ setOpen, open, attendanceId }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [attendance, setAttedance] = useState();
  const [messages, setMessages] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const descriptionElementRef = useRef(null);
  const [openToogleButton, setOpenToggleButton] = useState(false);
  const anchorRef = useRef(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const { push } = useHistory();

  const optionButtonOpenAttendance = [
    "Abrir atendimento",
    "Abrir atendimento em uma nova guia",
  ];

  const handleClick = () => {
    if (selectedIndex === 0) push(`edit/${attendanceId}`);
    else window.open(`./edit/${attendanceId}`);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpenToggleButton(false);
  };

  const handleToggle = () => {
    setOpenToggleButton((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpenToggleButton(false);
  };

  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      const attendanceData = await getAttendance({
        attendanceId: attendanceId,
      });
      if (attendanceData?.items) setAttedance(attendanceData.items[0]);

      const messagesData = await getMessages({ attendanceId: attendanceId });
      const newMessages = messagesData?.items;

      let newMessagesArray = [...messages];
      newMessagesArray.concat(newMessages);

      setMessages(newMessagesArray);

      const filesData = await filesAttendaceS3();
      setUploadedFiles(filesData);

      setLoading(false);
    };
    try {
      loadData();
    } catch (error) {
      console.error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attendanceId]);

  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const myModal = useRef();

  const closeOpenModal = (e) => {
    if (myModal.current && open && !myModal.current.contains(e.target)) {
      setOpen(false);
    }
  };

  document.addEventListener("mousedown", closeOpenModal);

  const PaperComponent = ({ myModal, ...other }) => {
    return <div ref={myModal} {...other} />;
  };

  const filesAttendaceS3 = async () => {
    try {
      const dataFetch = await getFilesS3({
        attendanceId: attendanceId,
      });
      const result = dataFetch?.items?.map((file) => {
        return {
          id: file?.file?.id,
          preview: file?.file?.url,
          name: file?.file?.name,
          readableSize: filesize(file?.file?.size),
          elementBase64: file?.file?.base64,
          file: {
            type: file?.file?.mimeType,
            extension: "." + file?.file?.extension,
          },
          uploaded: true,
          deleted: false,
          isInteractionAttendance: file?.file?.isInteractionAttendance,
        };
      });

      return result;
    } catch (error) {
      console.error(error);
    }
  };
  const CancelButton = () => (
    <>
      <Btn
        label="Cancelar"
        bg-color="tertiary"
        onClick={() => {
          setOpen(false);
        }}
      />
    </>
  );

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="lg"
      classes={{
        paper: classes.dialog,
      }}
      PaperProps={{ component: PaperComponent, myModal: myModal }}
    >
      <DialogTitle id="alert-dialog-title">
        <Title>Atendimento Nº{attendanceId}</Title>
        <br />
        {attendance ? (
          <>
            <Title subtitle>
              Aberto há {attendance?.attendanceTime || "-----"}| Última
              interação há {attendance?.lastMessage?.timeLastMessage || "-----"}
            </Title>
          </>
        ) : (
          ""
        )}
      </DialogTitle>
      <DialogContent ref={descriptionElementRef} style={{ flex: "1" }}>
        {loading ? (
          <>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              style={{ heigth: "100%" }}
            >
              <CircularProgress color="primary" size={40} />
            </Grid>
          </>
        ) : (
          <Grid>
            <Grid>
              <Margin top="10" bottom="10">
                <InteractionHistoric
                  loading={loading}
                  messages={messages}
                  uploadedFiles={uploadedFiles}
                  originRender="previewMessages"
                />
              </Margin>
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <CancelButton />
        {!loading && (
          <>
            <ButtonGroup
              variant="contained"
              aria-label="split button"
              color="Primary"
            >
              <Button onClick={handleClick}>
                {optionButtonOpenAttendance[selectedIndex]}
              </Button>
              <Button
                size="small"
                aria-controls={
                  openToogleButton ? "split-button-menu" : undefined
                }
                aria={openToogleButton ? "true" : undefined}
                aria-label="select merge strategy"
                aria-haspopup="menu"
                onClick={handleToggle}
              >
                <ArrowDropDownIcon />
              </Button>
            </ButtonGroup>
            <Popper
              open={openToogleButton}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    position: "fixed",
                    bottom: "11%",
                    right: "20.5%",
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList id="split-button-menu" autoFocusItem>
                        {optionButtonOpenAttendance.map((option, index) => (
                          <MenuItem
                            key={option}
                            disabled={index === 2}
                            selected={index === selectedIndex}
                            onClick={(event) =>
                              handleMenuItemClick(event, index)
                            }
                          >
                            {option}
                          </MenuItem>
                        ))}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ModalPreviewMessages;
