import { search } from "../BaseRepositoryComponents";

const baseUrl = process.env.REACT_APP_NEW_PAINEL_API_URL;

export function syncEntityData(document) {
  return search({
    path: `${baseUrl}/v1/entity/sync`,
    method: "POST",
    myBody: { document: document },
  });
}
