import { Button, Grid, ListItemText } from "@material-ui/core";
import styled from "styled-components";

export const TemplateTypeChips = styled.p((props) => ({
  display: "flex",
  padding: "8px 24px",
  justifyContent: "center",
  alignItems: "center",
  gap: "8px",
  borderRadius: "24px",
  background: "#F0F1F3",
  cursor: "pointer",

  ...(props?.active && {
    display: "flex",
    padding: "8px 24px",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",

    borderRadius: "24px",
    background: "var(--Primary-Blue-08, #0036b3)",
    color: "var(--Grey-01, #fbfbfd)",
    cursor: "pointer",
    fontFamily: "Roboto",
  }),

  "&:hover": {
    display: "flex",
    padding: "8px 24px",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    borderRadius: "24px",
    background: "#0036b3",
    color: "#fbfbfd ",
    cursor: "pointer",
    fontFamily: "Roboto",
  },
}));

export const CreateTemplateFrame = styled(Grid)`
  display: flex;
  align-items: flex-start;
`;

export const CreateTemplateButtonFrame = styled.div({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",

  flex: "1 0 0",
  alignSelf: "stretch",
});

export const CreateTemplateButton = styled(Button)`
  display: flex;
  padding: 8px 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 8px;
  border: 1px dashed #0036b3;
  background: #fff;
  text-transform: none;
  color: #0036b3;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
`;

export const TemplateFrame = styled(Grid)`
  border-radius: 8px;
  border: 1px solid #cecfd0;
  background: #f5f5f5;
`;

export const TemplateFrameFooter = styled.div({
  display: "flex",
  width: "100%",
  padding: "8px 16px",
  justifyContent: "center",
  alignItems: "center",
  gap: "8px",
  borderRadius: "0px 0px 8px 8px",
  background: "rgba(0, 0, 0, 0.54)",
  color: "#FFF",
  textAlign: "center",
  fontFamily: "Roboto",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "24px",
  letterSpacing: "0.15px",
  position: "absolute",
  bottom: 0,
  left: 0,
});

export const MoreOptionsButtonTemplate = styled.div({
  boxShadow: "0px 2px 2px 0px rgba(0, 0, 0, 0.08)",
  background: "#FFF",
  borderRadius: "4px",
  gap: "8px",
  alignItems: "center",
  justifyContent: "center",
  height: "40px",
  width: "40px",
  display: "flex",
  right: 0,
  position: "absolute",
  marginRight: "16px",
  cursor: "pointer",
});

export const TemplateMenuText = styled(ListItemText)`
  color: #162238;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20.02px;
  letter-spacing: 0.15px;
`;

export const DeleteText = styled.div({
  letterSpacing: "0.15px",
  lineHeight: "32px",
  fontWeight: "500",
  fontStyle: "normal",
  fontSize: "20px",
  fontFamily: "Roboto",
  color: "var(--Quasar-Grey-09, #364461)",
  alignSelf: "stretch",
});

export const ViewTemplateImgDiv = styled.div({
  background: "#F5F5F5",
  border: "1px solid var(--Quasar-Grey-03, #CECFD0)",
  borderRadius: "16px",
  gap: "8px",
  alignItems: "center",
  justifyContent: "center",
  padding: "16px",
  width: "35vh",
  display: "flex",
  marginRight: "16px",
});

export const ViewTemplateTextDiv = styled.div({
  flex: "1 0 0",
  gap: "16px",
  alignItems: "flex-start",
  flexDirection: "column",
  display: "flex",
});

export const TemplateNameDiv = styled.div({
  alignSelf: "stretch",
  gap: "4px",
  alignItems: "flex-start",
  flexDirection: "column",
  height: "66px",
  display: "flex",
  marginBottom: "12px",
});

export const TemplateNameText = styled.p({
  lineHeight: "22px",
  fontWeight: "700",
  fontStyle: "normal",
  fontSize: "14px",
  fontFamily: "Roboto",
  color: "#63708A",
  marginBottom: "-1px",
});

export const TemplateMessageDiv = styled.div({
  alignSelf: "stretch",
  gap: "4px",
  alignItems: "flex-start",
  flexDirection: "column",
  height: "156px",
  display: "flex",
});

export const TemplateMessageText = styled.p({
  lineHeight: "22px",
  fontWeight: "500",
  fontStyle: "normal",
  fontSize: "14px",
  fontFamily: "Roboto",
  color: "#364461",
  marginBottom: "-1px",
});

export const FieldPlaceholdersDiv = styled.div({
  gap: "8px",
  alignItems: "center",
  border: "1px solid #BFC2C7",
  borderRadius: "8px",
  alignSelf: "stretch",
  flexShrink: 0,
  justifyContent: "center",
  flexDirection: "column",
  padding: "8px 24px",
  display: "flex",
});

export const FieldPlaceholdersText = styled.p({
  lineHeight: "24px",
  fontWeight: "500",
  fontStyle: "normal",
  fontSize: "16px",
  fontFamily: "Roboto",
  color: "#364461",
  // marginBottom: "8px",
});

export const TooltipText = styled.p({
  letterSpacing: "0.15px",
  lineHeight: "20.02px",
  fontWeight: "400",
  fontStyle: "normal",
  fontSize: "14px",
  fontFamily: "Roboto",
  color: "#212121",
});

export const TemplateHeaderDiv = styled.div({
  alignSelf: "stretch",
  gap: "16px",
  alignItems: "flex-end",
  display: "flex",
  marginBottom: "8px",
});

export const NameTxtFieldTemplate = styled.div({
  flex: "1 0 0",
  gap: "4px",
  alignItems: "flex-start",
  flexDirection: "column",
  height: "66px",
  display: "flex",
});

export const DuplicateDeleteIcons = styled.div({
  background: "#EEE",
  borderRadius: "80px",
  alignItems: "center",
  justifyContent: "center",
  padding: "7.99px 22px 7.98px 21.99px",
  height: "48px",
  width: "48px",
  display: "flex",
  cursor: "pointer",
  marginBottom: "-14px",
});
