import React from "react";
import { Helmet } from "react-helmet";
import { ItemGridDragDropTitleStyle } from "../../components/grid-drag-drop/ItemGridDragDropTitleStyle";
import { Grid, IconButton } from "@material-ui/core";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import { LightTooltip, Margin } from "../../styles/GlobalStyle";
import CachedOutlinedIcon from "@material-ui/icons/CachedOutlined";
import CloseIcon from "@material-ui/icons/Close";
import { DividerStyled } from "../../components/chat/styles/GeneralStyle";
import useDashboardViewController from "../../pages/dashboard/controllers/DashboardViewController";

export const WidgetVersion73 = () => {
  const { handleDeleteWidgets } = useDashboardViewController();

  const item = {
    i: "73",
    importScripts: [""],
    stringScripts: [
      `try {
        let GRQAtoken = localStorage.getItem('tokenCognitoPanel'),
            GRQAdocument = localStorage.getItem('documentPanel');
  
        const GRQAShowError = () => {
          const errorElement = document.getElementById('GRQAfetchError');
          if (errorElement) {
            errorElement.style.display = 'flex';
            document.getElementById('GRQAloaderAnimation').style.display = 'none';
            document.getElementById('GRQAwidGet').style.display = 'none';
          }
        };
  
        const GRQAgetUserByToken = async () => {
          const response = await fetch('https://crmcont.qyon.com/api-be/v1/auth', {
            headers: {
              'qyon-customer-cnpj': GRQAdocument,
              Authorization: GRQAtoken
            }
          }).then(res => res.json())
            .catch(err => {
              console.log('[error][widget][quality-attendances][GR] get user', err);
              GRQAShowError();
            });
  
          return {
            companyId: response.company.id,
            attendantId: response.identifiers.userId,
            customerId: response.identifiers.customerId,
            customerContactId: response.identifiers.customerContactId
          };
        };
  
        const GRQAgetQualityOfAttendance = async () => {
          let user = await GRQAgetUserByToken();
          let attendanceData = await fetch(\`https://crmcont.qyon.com/api-be/v1/attendant/\${user.attendantId}/attendance/summary\`, {
            headers: {
              'qyon-customer-cnpj': GRQAdocument,
              Authorization: GRQAtoken
            }
          }).then(res => res.json())
            .catch(err => {
              console.log('[error][widget][quality-attendances][GR] get quality attendance', err);
              GRQAShowError();
            });
  
          document.getElementById('GRQAwidGet').innerHTML = GRQArenderContent(attendanceData.items[0].score[0].average);
  
          var graph = document.getElementById('graph'),
              config = {
                score: graph.getAttribute('score') || 25,
                size: graph.getAttribute('data-size') || 220,
                lineWidth: graph.getAttribute('data-line') || 23,
                rotate: graph.getAttribute('data-rotate') || 0
              },
              canvas = document.createElement('canvas');
  
          canvas.setAttribute('id', 'canva-grqa');
  
          var scoreSpan = document.createElement('span');
          scoreSpan.setAttribute('id', 'span-grqa');
          scoreSpan.textContent = config.score;
  
          if (typeof G_vmlCanvasManager !== 'undefined') {
            G_vmlCanvasManager.initElement(canvas);
          }
  
          var context = canvas.getContext('2d');
          canvas.width = canvas.height = config.size;
          graph.appendChild(scoreSpan);
          graph.appendChild(canvas);
  
          context.translate(config.size / 2, config.size / 2);
          context.rotate((config.rotate / 180 - 0.5) * Math.PI);
  
          var radius = (config.size - config.lineWidth) / 2,
              drawArc = function (color, lineWidth, percent) {
                percent = Math.min(Math.max(0, percent || 1), 1);
                context.beginPath();
                context.arc(0, 0, radius, 0, 2 * Math.PI * percent, false);
                context.strokeStyle = color;
                context.lineCap = 'round';
                context.lineWidth = lineWidth;
                context.stroke();
              };
  
          drawArc('#C4C4C4', config.lineWidth, 1);
          drawArc('#008000', config.lineWidth, config.score / 10);
        };
  
        const GRQArenderContent = (score) => {
          return \`<div class='chart' id='graph' score='\${score}'></div>\`;
        };
  
        try {
          (async () => {
            await GRQAgetQualityOfAttendance()
              .catch(err => {
                console.log('[error][widget][quality-attendances][GR] main function', err);
                GRQAShowError();
              });
            document.getElementById('GRQAloaderAnimation').style.display = 'none';
          })();
        } catch (err) {
          console.log('[error][widget][quality-attendances][GR] main function', err);
          GRQAShowError();
        }
      } catch (err) {}`,
    ],
    onRefresh: "",
    refreshOnLoad: true,
  };

  return (
    <>
      {item.importScripts.map((script, index) => {
        return (
          <div key={`import_${index}`}>
            <Helmet>
              <script async src={script}></script>
            </Helmet>
          </div>
        );
      })}

      <ItemGridDragDropTitleStyle customStyle={item.headerStyle ?? {}}>
        <Grid item container justifyContent="space-between" alignItems="center">
          <Grid item flex={1}>
            <IconButton size="small">
              <DragIndicatorIcon style={{ fontSize: 18, marginRight: 5 }} />
            </IconButton>
            Qualidade do Atendimento
          </Grid>
          <Grid item flex={1}>
            <Grid item container alignItems="center">
              <Margin top="7" />
              {item.onRefresh &&
                (() => {
                  if (item?.refreshOnLoad) {
                    setTimeout(() => {
                      try {
                        // eslint-disable-next-line no-eval
                        eval("try{" + item.onRefresh + "}catch(err){}");
                      } catch (error) {
                        console.error(error);
                      }
                    }, 1500);
                  }

                  return (
                    <Grid item flex={1}>
                      <LightTooltip title="Atualizar">
                        <CachedOutlinedIcon
                          style={{
                            fontSize: 18,
                            marginRight: 10,
                            cursor: "pointer",
                            color: "#636363",
                          }}
                          onClick={() => {
                            // Muito cuidado, o eval é perigoso - https://developer.mozilla.org/pt-BR/docs/Web/JavaScript/Reference/Global_Objects/eval
                            try {
                              // eslint-disable-next-line no-eval
                              eval("try{" + item.onRefresh + "}catch(err){}");
                            } catch (error) {
                              console.error(error);
                            }
                          }}
                        />
                      </LightTooltip>
                    </Grid>
                  );
                })()}
              <Grid item flex={1}>
                <LightTooltip title="Remover">
                  <CloseIcon
                    style={{
                      fontSize: 18,
                      cursor: "pointer",
                      color: "#636363",
                      marginRight: 10,
                    }}
                    onClick={() => handleDeleteWidgets(item)}
                  />
                </LightTooltip>
              </Grid>
            </Grid>
          </Grid>
          <DividerStyled style={{ width: "100%" }} />
        </Grid>
      </ItemGridDragDropTitleStyle>

      <div
        style={{
          height: `${"calc(100% - 65px)"}`,
          width: "100%",
        }}
      >
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link
          rel="preconnect"
          href="https://fonts.gstatic.com"
          crossOrigin="true"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Ubuntu&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/icon?family=Material+Icons+Round"
          rel="stylesheet"
        />

        <style>
          {`@keyframes GRQCspinner-grow {
          0% { transform: scale(0); }
          50% { opacity: 1; transform: none; }
        }
        #canva-grqa {
          display: block;
          position: absolute;
          top: 0;
          left: 0;
        }
        #span-grqa {
          color: #008000;
          display: block;
          line-height: 220px;
          text-align: center;
          width: 220px;
          font-family: sans-serif;
          font-size: 30px;
          font-weight: 100;
        }`}
        </style>

        <div
          id="GRQA_widget"
          style={{
            height: "calc(100% - 58.33px)",
            fontFamily: "'Ubuntu', sans-serif",
            padding: "0 10px 10px 10px",
            position: "relative",
          }}
        >
          <div
            id="GRQAloaderAnimation"
            style={{
              color: "#0d6efd",
              display: "block",
              width: "5rem",
              height: "5rem",
              verticalAlign: "-0.125em",
              backgroundColor: "currentColor",
              borderRadius: "50%",
              opacity: 0,
              WebkitAnimation: "0.75s linear infinite GRQCspinner-grow",
              animation: "0.75s linear infinite GRQCspinner-grow",
              animationDuration: "1.5s",
              position: "absolute",
              top: "calc(50% - 2.5rem)",
              left: "calc(50% - 2.5rem)",
            }}
          ></div>

          <div
            id="GRQAfetchError"
            style={{
              position: "absolute",
              width: "calc(100% - 20px)",
              height: "100%",
              display: "none",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <span
              className="material-icons-round"
              style={{ fontSize: "3em", margin: "8px" }}
            >
              error_outline
            </span>
            <p>
              Não foi possível carregar os dados
              <br />
              Tente novamente
            </p>
          </div>

          <div
            id="GRQAwidGet"
            style={{
              position: "relative",
              margin: "auto",
              width: "fit-content",
              top: "70%",
              transform: "translateY(-50%)",
            }}
          ></div>
        </div>
      </div>

      {item.stringScripts &&
        item.stringScripts.map((script, index) => {
          return (
            <div key={`script_${index}`}>
              <Helmet>
                <script async>{script}</script>
              </Helmet>
            </div>
          );
        })}
    </>
  );
};
