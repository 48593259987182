import { useContext, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Chip,
  Button,
  Grid,
} from "@material-ui/core";

import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";

import { HomeContext } from "../contexts/HomeContext";
import { HeaderDialog } from "../styles/Home";

const steps = [
  "Google Maps",
  "Buscar endereço",
  "Compartilhar",
  "Incorporar",
  "Copiar HTML",
];

const contents = [
  <>
    <Grid container justifyContent="center" alignItems="center">
      <p>Abra o Google Maps</p>
    </Grid>
    <Grid container justifyContent="center" alignItems="center">
      <img
        alt=""
        src="https://qerp-public.s3.sa-east-1.amazonaws.com/fixos/1+(1).png"
      />
    </Grid>
  </>,
  <>
    <Grid container justifyContent="center" alignItems="center">
      <p>Procure pela localidade da sua empresa</p>
    </Grid>
    <Grid container justifyContent="center" alignItems="center">
      <img
        alt=""
        src="https://qerp-public.s3.sa-east-1.amazonaws.com/fixos/MicrosoftTeams-image+(11).png"
      />
    </Grid>
  </>,
  <>
    <Grid container justifyContent="center" alignItems="center">
      <p>Ao encontrar, clique no botão circular escrito "Compartilhar".</p>
    </Grid>
    <Grid container justifyContent="center" alignItems="center">
      <img
        alt=""
        src="https://qerp-public.s3.sa-east-1.amazonaws.com/fixos/3+(1).png"
      />
    </Grid>
  </>,
  <>
    <Grid container justifyContent="center" alignItems="center">
      <p>Em seguida, selecione a aba "Incorporar um Mapa"</p>
    </Grid>
    <Grid container justifyContent="center" alignItems="center">
      <img
        alt=""
        src="https://qerp-public.s3.sa-east-1.amazonaws.com/fixos/4+(1).png"
      />
    </Grid>
  </>,
  <>
    <Grid container justifyContent="center" alignItems="center">
      <p>Por fim, clique em "Copiar HTML" e cole no campo de configuração!</p>
    </Grid>
    <Grid container justifyContent="center" alignItems="center">
      <img
        alt=""
        src="https://qerp-public.s3.sa-east-1.amazonaws.com/fixos/MicrosoftTeams-image+(9).png"
      />
    </Grid>
  </>,
];

const DialogHelperMap = ({ open, setOpen }) => {
  const { landTheme } = useContext(HomeContext);

  const [activeStep, setActiveStep] = useState(0);

  const handleClose = () => {
    setOpen(false);
  };

  const handleNext = () => {
    if (activeStep === steps.length - 1) setOpen(false);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      open={open}
      onClose={handleClose}
      aria-labelledby="dialog-helper-map"
    >
      <HeaderDialog
        $backgroundColor={landTheme.color_pallete.tertiary}
        $color={landTheme.color_pallete.secondary}
      >
        <DialogTitle id="dialog-helper-map">
          <Chip label="Assistente" />
        </DialogTitle>
      </HeaderDialog>
      <DialogContent>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label, key) => (
            <Step key={key}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {contents[activeStep]}
      </DialogContent>
      <DialogActions>
        <Button disabled={activeStep === 0} onClick={handleBack}>
          Voltar
        </Button>
        <Button variant="contained" color="primary" onClick={handleNext}>
          {activeStep === steps.length - 1 ? "Terminar" : "Próximo"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogHelperMap;
