export const BaseObjectDocument = () => {
  return {
    base64: "",
    name: "",
    tab: "ONE",
    data: {},
    sendToPay: false,
    contabil: false,
    folha: false,
    fiscal: false,
    outros: false,
    contratoTrabalho: false,
    contratoSocial: false,
    openTicketInCRM: false,
    doc_type: "",
    use_suggestion: false,
    name_suggestion: "",
    generateTicketEmail: {
      label: "",
      email: "",
    },
    boletoExists: false,
  };
};
