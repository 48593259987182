import { BaseObjectDocument } from "./BaseObjectDocument";

export const NotIdentifiedObject = (props) => {
  let mainObject = BaseObjectDocument();

  if (props) {
    mainObject.base64 = props.base64;
    mainObject.name = props.name;

    mainObject.data = {
      base64: props.data.base64,
      document_type: "NOT_IDENTIFIED",
    };

    mainObject.sendToPay = true;
    mainObject.outros = true;
    mainObject.doc_type = "doc";
    mainObject.use_suggestion = true;
    mainObject.generateTicketEmail = props.generateTicketEmail;
  }
  return mainObject;
};
