import * as React from "react";

function OfxIcon(props) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M5.944 2.959c.18-.094.37-.17.569-.244-.155.517.028 1.041.495 1.326.264.161.568.212.856.142.31-.076.582-.283.765-.585.175-.287.35-.58.519-.862.179-.3.365-.61.55-.914.32-.528.206-.963.052-1.235C9.595.31 9.28-.015 8.655 0 5.93.067 3.677 1.153 1.961 3.23.674 4.788.014 6.634 0 8.716c-.004.656.458 1.154 1.099 1.186l.06.002c.589 0 1.065-.43 1.144-1.044.01-.074.011-.143.012-.204l.003-.078c.066-1.278.406-2.398 1.01-3.33.6-.926 1.48-1.696 2.616-2.29zM23.987 15.094c-.042-.578-.577-1.02-1.196-.986-.606.034-1.046.481-1.097 1.113a8.444 8.444 0 00-.017.327c-.007.17-.014.331-.037.484-.194 1.302-.655 2.408-1.37 3.289-.68.838-1.612 1.5-2.773 1.968a1.141 1.141 0 00-.513-1.328c-.558-.33-1.24-.15-1.589.417a89.086 89.086 0 00-1.118 1.864c-.228.395-.231.866-.007 1.23a1.123 1.123 0 001.054.526c.676-.042 1.394-.096 2.088-.266 3.491-.856 6.117-3.844 6.533-7.434.054-.469.068-.851.042-1.204zM7.691 8.364h-.993c-.839 0-1.485.222-1.923.659-.437.437-.659 1.086-.659 1.928v1.992c0 .838.22 1.483.654 1.917.437.438 1.079.66 1.906.66h1.026c.84 0 1.486-.227 1.92-.674.435-.448.656-1.106.656-1.957v-1.944c0-.838-.222-1.485-.66-1.922-.436-.437-1.085-.659-1.927-.659zm.438 2.56v1.998c0 .335-.107.442-.438.442h-1.01c-.27 0-.415-.07-.415-.442v-1.977c0-.319.1-.42.415-.42h1.01c.29 0 .438.06.438.399zM14.506 11.231c.187-.186.281-.45.281-.783 0-.543-.263-.903-.734-1.024v-.056h-1.27v-.41c0-.331.107-.439.44-.443h.506c.676 0 1.063-.393 1.063-1.08 0-.676-.391-1.064-1.074-1.064h-.513c-.84 0-1.487.227-1.92.674-.436.448-.656 1.106-.656 1.956v5.454c0 .683.392 1.075 1.075 1.075h.01c.337 0 .602-.095.788-.281.187-.187.281-.452.281-.788v-2.943h.902c.341 0 .623-.098.815-.281l.006-.006z" />
      <path d="M14.916 10.177l.03.034 1.344 1.712-1.449 1.844v.01c-.348.477-.294.988.157 1.418l.011.01c.238.209.483.314.728.314.27 0 .554-.147.782-.404l1.14-1.451 1.118 1.424.01.012c.294.342.592.414.79.414.321 0 .555-.163.723-.304l.014-.013c.458-.43.513-.944.16-1.426v-.01l-1.45-1.844 1.444-1.839v-.009c.168-.227.245-.459.229-.69-.019-.26-.155-.503-.403-.718-.241-.21-.48-.313-.728-.313-.287 0-.555.138-.797.41l-1.125 1.424-1.102-1.398-.01-.012c-.303-.35-.605-.424-.805-.424-.333 0-.567.167-.736.316-.498.444-.525.995-.075 1.513z" />
    </svg>
  );
}

export default OfxIcon;
