import React, { useContext, useEffect, useState } from "react";
import { CircularProgress, Grid, RadioGroup } from "@material-ui/core";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { DefaultPageContainer } from "../../../components/layoutContainer/styles/LayoutContainer";
import { ConfigureERPRadioButton } from "./ConfigureERPRadioButton";
import { ComercialContactTab } from "./ComercialContactTab";
import { InactiveERPTab } from "./InactiveERPTab";
import { ConfigureERPContext } from "../contexts/ConfigureERPContext";
import {
  getEntityDataById,
  isComercialContactAlreadyRequested,
} from "../../../repositories/portal_api/AccountantClient";
import MaskService from "../../../utils/mask/maskService";
import { ERPPlansTab } from "./ERPPlansTab";
import { ReactComponent as HandbagIcon } from "../../../assets/icons/handbag.svg";
import { ReactComponent as GFIcon } from "../../../assets/icons/GF.svg";
import { notify } from "../../../utils/notify";
import { getUsersByEntityId } from "../../../repositories/portal_api/Users";
import { getSystemsAccountantCanEnable } from "../../../repositories/portal_api/Systems";

export const ConfigureERP = ({ location }) => {
  const [selectedValue, setSelectedValue] = useState("get_erp");
  const [loading, setLoading] = useState(true);
  const [lockedGetErp, setLockedGetErp] = useState(false);
  const { push } = useHistory();
  const [configured, setConfigured] = useState(false);
  const [gfPlan, setGfPlan] = useState(null);
  const [gfPayer, setGfPayer] = useState(null);

  const {
    setComercialContactDocument,
    setComercialContactPhone,
    setComercialContactEmail,
    setComercialContactName,
    setErpPlansList,
    setComercialContactAlreadyRequested,
  } = useContext(ConfigureERPContext);

  useEffect(() => {
    if (location?.state?.customerId) {
      setLoading(true);

      setComercialContactName("");

      Promise.all([
        getEntityDataById(location.state.customerId),
        getSystemsAccountantCanEnable(),
        getUsersByEntityId(location.state.customerId),
        isComercialContactAlreadyRequested(location.state.customerId),
      ])
        .then((responseArray) => {
          const responseClientData = responseArray[0];
          const responseErpPlans = responseArray[1];
          const responseUsers = responseArray[2];
          const responseComercialContact = responseArray[3];

          if (responseClientData?.success) {
            if (responseClientData.data.document.length <= 11) {
              setComercialContactDocument(
                MaskService.toMask("cpf", responseClientData.data.document, "")
              );
            } else {
              setComercialContactDocument(
                MaskService.toMask("cnpj", responseClientData.data.document, "")
              );
            }

            if (responseClientData?.data?.phone) {
              setComercialContactPhone(
                MaskService.toMask(
                  "cel-phone",
                  responseClientData.data.phone,
                  ""
                )
              );
            }

            setConfigured(responseClientData?.data?.configured);
            setGfPlan(responseClientData?.data?.gf_plan);
            setGfPayer(responseClientData?.data?.gf_payer);
          } else {
            notify("Erro ao carregar dados do cliente");
            push("/customermanagement/list");
          }

          if (responseErpPlans?.success) {
            setErpPlansList(responseErpPlans?.data);
          } else {
            setLockedGetErp(true);
          }

          if (responseUsers?.success) {
            if (responseUsers?.data?.length > 0) {
              responseUsers?.data.forEach((user) => {
                if (user?.is_master) {
                  setComercialContactEmail(user?.email);
                }
              });
            }
          }

          if (responseComercialContact.success) {
            setComercialContactAlreadyRequested(
              responseComercialContact.data.comercial_contact_requested
            );
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "30px",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <DefaultPageContainer>
          <RadioGroup defaultValue="get_erp" name="radio-buttons-group">
            <Grid container justifyContent="space-between">
              <ConfigureERPRadioButton
                title="Solicitar contato comercial da Qyon para o Cliente"
                subtitle="A Qyon entra em contato com o seu cliente para oferecer o Gestão Fácil ERP"
                name="comercial_contact"
                selectValue={setSelectedValue}
                selected={selectedValue}
                icon={<HandbagIcon />}
                xs={configured ? 6 : 6}
              />
              <ConfigureERPRadioButton
                title="Adquira o Portal para uso do Cliente"
                subtitle="Comprar o Gestão Fácil para seu cliente utilizar."
                name="get_erp"
                selectValue={(name) => {
                  setSelectedValue(name);
                }}
                selected={selectedValue}
                icon={<GFIcon />}
                xs={configured ? 6 : 6}
              />
            </Grid>
          </RadioGroup>
          {selectedValue === "comercial_contact" && (
            <ComercialContactTab
              setLoading={setLoading}
              accountantClientId={location?.state?.customerId}
            />
          )}
          {selectedValue === "get_erp" && (
            <ERPPlansTab
              setLoading={setLoading}
              accountantClientId={location?.state?.customerId}
              configured={configured}
              gfPlan={gfPlan}
              gfPayer={gfPayer}
              lockedGetErp={lockedGetErp}
            />
          )}

          {selectedValue === "inactive_erp" && (
            <InactiveERPTab setLoading={setLoading} />
          )}
        </DefaultPageContainer>
      )}
    </>
  );
};
