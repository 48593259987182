import { BaseObjectDocument } from "./BaseObjectDocument";

export const BoletoObject = (props) => {
  let mainObject = BaseObjectDocument();

  if (props) {
    mainObject.base64 = props.base64;
    mainObject.name = props.name;

    mainObject.data = {
      base64: props.data.base64,
      document_type: "BOLETO",
      typeable_line: "",
      data: {
        beneficiary: {
          name: "",
          taxIdentifier: {
            taxId: "",
          },
        },
        drawee: {
          name: "",
          taxIdentifier: {
            taxId: "",
          },
        },
        paymentDetails: {
          barcode: "",
          consolidatedAmount: "0,00",
          discount: 0,
          documentType: "Boleto",
          dueDate: "",
          fine: 0,
          interest: 0,
          maximumAmount: 0,
          minimumAmount: 0,
          totalAmount: "0,00",
          typeableLine: "",
        },
        settings: {
          endTime: "20:00:00",
          startTime: "07:00:00",
          timezone: "America/Fortaleza",
        },
        status: "",
        unmatchingValueAuthCode: "",
      },
    };

    mainObject.sendToPay = true;
    mainObject.doc_type = "guide";
    mainObject.use_suggestion = true;
    mainObject.generateTicketEmail = props.generateTicketEmail;
  }
  return mainObject;
};
