import axios from "axios";

const newPath = `${process.env.REACT_APP_NEW_PAINEL_API_URL}`;

const myHeaders = new Headers({
  Authorization: "Bearer " + localStorage.getItem("tokenPanel"),
  "Entity-Id": localStorage.getItem("companyIdPanel"),
  "Content-Type": "application/json",
  "Content-Language": "pt-BR",
});

export async function postCodeRecover({ body }) {
  try {
    const response = await axios.post(
      `${newPath}/v1/public/account/send-code-reset-password`,
      body,
      {
        headers: myHeaders,
      }
    );

    return response?.data || null;
  } catch (error) {
    return error?.response?.data || null;
  }
}

export async function postChangePassRecover({ body }) {
  try {
    const response = await axios.post(
      `${newPath}/v1/public/account/confirm-code-reset-password`,
      body,
      {
        headers: myHeaders,
      }
    );
    return response?.data || null;
  } catch (error) {
    return error?.response.data || null;
  }
}
