import React from "react";

import { GridStyled, Title, ContainerIcon } from "../../../styles/GlobalStyle";
import { Grid, IconButton, Zoom, Tooltip } from "@material-ui/core";
import { HelpOutline as HelpIcon } from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";

export default function GridCard({
  title,
  children,
  buttonIcon = [],
  titleWidth = 9,
  help,
  helpText,
  ...props
}) {
  const DarkTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.black,
      color: "#fff",
      boxshadow: theme.shadows[2],
      placement: "right",
      fontSize: 13,
    },
  }))(Tooltip);
  return (
    <GridStyled
      item
      style={{ marginBottom: "25px" }}
      xs={12}
      container
      alignItems="center"
      bg-color="gray"
      border-radius="4"
      box-shadow="0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)"
      padding="10"
    >
      <GridStyled
        container
        alignItems="center"
        margin="10px 0px 15px 0"
        style={{ display: "flex" }}
      >
        <Grid xs={buttonIcon ? titleWidth : 12}>
          <Title
            subtitle
            color="white"
            cursor="pointer"
            style={{
              marginRight: props?.icon && "64%",
              display: "flex",
              alignItems: "center",
            }}
          >
            {title}{" "}
            {help && (
              <DarkTooltip
                title={helpText}
                placement="right"
                TransitionComponent={Zoom}
                TransitionProps={{ timeout: 100 }}
              >
                <ContainerIcon
                  iconColor="white"
                  style={{
                    marginLeft: "5px",
                    display: "flex",
                    alignSelf: "center",
                  }}
                >
                  <HelpIcon />
                </ContainerIcon>
              </DarkTooltip>
            )}
          </Title>
        </Grid>
        <Grid xs={3} style={{ textAlign: "right" }}>
          {buttonIcon &&
            buttonIcon.map((value) => (
              <ContainerIcon
                fontSize="25"
                iconColor="white"
                style={{ display: "inline-block" }}
              >
                <IconButton>{value}</IconButton>
              </ContainerIcon>
            ))}
        </Grid>
      </GridStyled>
      <GridStyled
        container
        alignItems="center"
        bg-color="white"
        border-radius="4"
        padding="10"
      >
        {children}
      </GridStyled>
    </GridStyled>
  );
}
