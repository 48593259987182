import * as React from "react";

function LogoPortalHeader(props) {
  const width = props?.width || 58;
  const height = props?.height || 19;

  return (
    <svg
      width={width}
      height={height}
      viewBox={"0 0 58 19"}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M34.554 0a7.849 7.849 0 10.023 15.698A7.849 7.849 0 0034.554 0zm.017 12.126a4.277 4.277 0 11-.012-8.555 4.277 4.277 0 01.012 8.555zM15.697 7.837a7.849 7.849 0 10-4.761 7.228l2.336 3.158 2.912-2.154-2.34-3.16a7.815 7.815 0 001.853-5.072zM3.57 7.854a4.277 4.277 0 118.555-.012 4.277 4.277 0 01-8.555.012z"
        fill="#fff"
      />
      <path
        d="M14.056.547l5.402 7.302v7.294h3.577V7.848l5.402-7.3h-4.5l-2.691 3.634-2.69-3.635h-4.5zM53.853.547v8.594L47.494.55V.547h-3.653V15.15l3.578-.001v-8.62l6.378 8.621h3.652V.547h-3.596z"
        fill="#fff"
      />
    </svg>
  );
}

export default LogoPortalHeader;
