import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Collapse,
  FormControlLabel,
  Grid,
  IconButton,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { CustomTooltip, PermissionDetail } from "../styles/UserAccess";
import { ReactComponent as ExpandIcon } from "../../../assets/icons/expand.svg";
import { ManagementContext } from "../../accessProfile/contexts/ManagementContext";
import { PermissionFilter } from "./PermissionFilter";
import { GlobalContext } from "../../../contexts/GlobalContext";

export const RecursivePermissionList = ({
  permissionsTree,
  expecifyUser = {},
  groupPermissionsList = [],
}) => {
  const [expandedAccordions, setExpandedAccordions] = useState({});
  const [expandAll, setExpandAll] = useState(false);
  const masterPermission = useRef("");
  const [modulesPermissions, setModulesPermissions] = useState([]);
  const [widgetsPermissions, setWidgetsPermissions] = useState([]);

  const {
    permissionsList,
    setPermissionsList,
    deletedPermissionsListChildrens,
    setDeletedPermissionsListChildrens,
    customized,
    permissionNameFilter,
  } = useContext(ManagementContext);
  const { userLogged } = useContext(GlobalContext);

  useEffect(() => {
    extractPermissionsLists();

    if (expecifyUser?.id && groupPermissionsList) {
      const userPermission = groupPermissionsList.find(
        (item) => item.id === expecifyUser.permission_group_id
      );

      if (
        userPermission?.name !== "Padrão" &&
        userPermission?.name !== "Customizado" &&
        userPermission?.name !== "Default"
      ) {
        setPermissionsList(userPermission?.permissions);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const extractPermissionsLists = () => {
    let permissionModule = [];
    let permissionWidget = [];

    masterPermission.current = permissionsTree.find(
      (item) => (item.description = "Master")
    );

    permissionsTree[0].children_recursive.forEach((element) => {
      if (element.description === "Módulos") {
        permissionModule = element;
      } else if (element.description === "Widgets") {
        permissionWidget = element;
      }
    });

    setModulesPermissions(permissionModule.children_recursive);
    setWidgetsPermissions(permissionWidget.children_recursive);
  };

  const handleAccordionChange = (accordionId) => {
    setExpandedAccordions((prevState) => ({
      ...prevState,
      [accordionId]: !prevState[accordionId],
    }));
  };

  const checkIfMasterIsChecked = () => {
    return permissionsList?.find(
      (permission) => permission === masterPermission.current.id
    );
  };

  const handleExpandAll = () => {
    let expandCopy = expandAll;
    setExpandAll(!expandCopy);
  };

  function searchIdInList(list, id) {
    for (let item of list) {
      if (item.id === id) {
        return true; // ID encontrado na lista principal
      }
      // Se houver filhos, realiza a busca recursiva
      if (item.children_recursive) {
        const foundInChildren = searchIdInList(item.children_recursive, id);
        if (foundInChildren) {
          return true; // ID encontrado nos filhos
        }
      }
    }
    return false;
  }

  // Função para determinar a categoria (Modulos ou Widgets)
  function findCategoryById(id) {
    if (searchIdInList(modulesPermissions, id)) {
      return "Modulos";
    }
    if (searchIdInList(widgetsPermissions, id)) {
      return "Widgets";
    }
    return null;
  }

  const handleChange = (event, parent) => {
    let permissionListCopy = [];
    let deletedPermissionsListChildrenCopy = [
      ...deletedPermissionsListChildrens,
    ];

    //Se a permissão master é marcada, adiciona apenas ela na lista, e trava as outras, até a desseleção da mesma
    if (parent.description !== "Master") {
      if (permissionsList && permissionsList.length !== 0) {
        permissionListCopy = [...permissionsList];
      }
    }

    if (parent.description === "Master") {
      setDeletedPermissionsListChildrens([]);
    }
    //Caso checked true
    if (event.target.checked) {
      permissionListCopy.push(event.target.name);
      //Ve o pai da permissão checkada se tiver filhos, se não olha a própria permissão
      //Ve se o todos os filhos estão checked, tras a lista dos filhos, e qual o parent usado
      const childrenObject = checkIfAllChildrenAreChecked(
        parent,
        permissionListCopy
      );

      addToDeleteListChildren(childrenObject, permissionListCopy);
      setPermissionsList(permissionListCopy);
    } else {
      // Caso o pai seja desmarcado, desmarca os filhos também
      if (parent.children_recursive.length !== 0) {
        const parentIdIndex = permissionListCopy.indexOf(parent.id);
        permissionListCopy.splice(parentIdIndex, 1);
        // Itere sobre cada filho e remove o ID correspondente
        parent.children_recursive.forEach((child) => {
          const childIdIndex = permissionListCopy.indexOf(child.id);
          if (childIdIndex !== -1) {
            permissionListCopy.splice(childIdIndex, 1);
            deletedPermissionsListChildrenCopy.splice(childIdIndex, 1);
          }
        });
      }

      const childrenObject = checkIfAllChildrenAreChecked(
        parent,
        permissionListCopy
      );

      //Caso a permissão seja adicionada diretamente pelo pai
      //Procura se o pai está na lista
      const parentInPermissionList = permissionListCopy.find(
        (item) => item === childrenObject.parentUsed.id
      );
      //Procura se o item está na lista de deleção
      const itemInDeleteList = deletedPermissionsListChildrenCopy.find(
        (item) => item === parent.id
      );
      //Caso o pai esteja na lista e o item não esteja na lista de deleção
      //Tira a permissão do pai da lista de permissões, e adiciona ou outros filhos(exceto o item) a lista de permissões
      if (parentInPermissionList && !itemInDeleteList) {
        const parentIdIndex = permissionListCopy.indexOf(
          parentInPermissionList
        );
        permissionListCopy.splice(parentIdIndex, 1);

        childrenObject.childrenInList.forEach((item) => {
          if (item !== parent.id) {
            permissionListCopy.push(item);
          }
        });
      }

      //Se desmarca a permissão com o pai marcado, tira a permissão do pai e a permissão clicada
      if (
        parent.children_recursive.length === 0 &&
        permissionListCopy.find((item) => item === parent.parent_id)
      ) {
        const parentIdIndex = permissionListCopy.indexOf(parent.parent_id);
        permissionListCopy.splice(parentIdIndex, 1);
      }

      //Caso: Caso o usuário marque a permissão pai, apenas ela será adicionada na lista
      //Depois se o usuario descelecionar um dos filhos, o pai sairá da lista de permissão,
      //e todos os filhos menos o descelecionado, serão adicionados na lista de permissões,
      //e todos os filhos da permissão sairão da lista de delete

      //Verifica se o pai está na lista de permissão, depois se todos os filhos estão na lista de deleção
      if (
        parentInPermissionList &&
        childrenObject.childrenInList.every((item) =>
          permissionListCopy.includes(item)
        )
      ) {
        const listaFiltrada = deletedPermissionsListChildrenCopy.filter(
          (item) => !childrenObject.childrenInList.includes(item)
        );
        setDeletedPermissionsListChildrens(listaFiltrada);
      }

      const permissionListCopyFiltered = permissionListCopy.filter(
        (item) => item !== parent.id
      );
      setPermissionsList(permissionListCopyFiltered);
    }
  };

  const addToDeleteListChildren = (object, listCopy) => {
    let permissionListCopy = [];

    let deletedPermissionsListChildrenCopy = [
      ...deletedPermissionsListChildrens,
    ];
    if (!customized && permissionsList.length === 0) {
      permissionListCopy = [...permissionsList];
    }

    //Ve se todos os filhos estão checked para adiciona-los na lista de deleção e adiciona pai na lista de permissão
    if (object.allChecked) {
      object.childrenInList.forEach((permission) => {
        deletedPermissionsListChildrenCopy.push(permission);
      });
      setDeletedPermissionsListChildrens(deletedPermissionsListChildrenCopy);
      permissionListCopy.push(object.parentUsed.id);
      listCopy.push(object.parentUsed.id);
    }
  };

  const checkedOrNot = (parent) => {
    const haveCheckedChildren = findChildrenPermissionId(parent);
    const founded = permissionsList?.find(
      (item) =>
        item === parent.id ||
        item === masterPermission.current.id ||
        item === parent.parent_id ||
        haveCheckedChildren
    );
    return founded;
  };

  //Ve se tem algum filho na lista de permissões
  const findChildrenPermissionId = (permission) => {
    let childrenPermissionList = [];
    permission.children_recursive?.forEach((item) =>
      childrenPermissionList.push(item.id)
    );
    const findedChildrenPermission = permissionsList?.some((permission) =>
      childrenPermissionList.includes(permission)
    );
    return findedChildrenPermission;
  };

  //Ve se todos os filhos estão marcados e retorna um objeto
  const checkIfAllChildrenAreChecked = (parent, permissionList) => {
    let object = {};
    let permissionListUsed = [];
    const permissionListOrigin = findCategoryById(parent.id);

    if (permissionListOrigin === "Modulos") {
      permissionListUsed = [...modulesPermissions];
    } else {
      permissionListUsed = [...widgetsPermissions];
    }
    //Busca a permissão pai
    let parentUsed = findParentConfig(parent.parent_id, permissionListUsed);

    //Sempre usa o pai mais exterior, se houver usa ele, se não usa a propria permissão
    if (parentUsed === undefined) {
      parentUsed = parent;
    }

    //Caso a permissão não tenha filhos retorna allChecked como false, lista de filhos vazias, e parentUsed usado
    if (parentUsed.children_recursive.length === 0) {
      object = {
        parentUsed: parentUsed,
        childrenInList: [],
        allChecked: false,
      };
    } else {
      //Caso a permissão tenha filhos, retorna se todos os filhos estão marcados, a lista de permissões desses filhos, e o parentUsed usado
      const parentChildrenList = parentUsed.children_recursive.map(
        (child) => child.id
      );

      object = {
        parentUsed: parentUsed,
        childrenInList: parentChildrenList,
        allChecked: parentChildrenList.every((id) =>
          permissionList.includes(id)
        ),
      };
    }

    return object;
  };

  //Acha a permissão pai
  const findParentConfig = (parent_id, permissionGroupList) => {
    return permissionGroupList.find((item) => item.id === parent_id);
  };

  const filterTree = (tree, searchText) => {
    return tree.reduce((acc, parent) => {
      const parentMatches = parent.description
        .toLowerCase()
        .includes(searchText.toLowerCase());

      const filteredChildren = filterTree(
        parent.children_recursive,
        searchText
      );

      if (parentMatches || filteredChildren.length > 0) {
        acc.push({
          ...parent,
          children_recursive: parentMatches
            ? parent.children_recursive
            : filteredChildren,
        });
      }

      return acc;
    }, []);
  };

  const RecursiveComponentAccordion = ({ tree, time = 0 }) => {
    const filteredTree = filterTree(tree, permissionNameFilter);
    return (
      <div style={{ width: "100%" }}>
        {filteredTree?.map((parent) => {
          parent.checked = false;
          const isAccordionExpanded = expandedAccordions[parent.id];
          const hasChildren =
            parent.children_recursive && parent.children_recursive.length > 0;

          const haveCheckedChildren = findChildrenPermissionId(parent);
          const founded = permissionsList?.find(
            (item) =>
              item === parent.id ||
              item === masterPermission.current.id ||
              item === parent.parent_id ||
              haveCheckedChildren
          );

          return (
            <div key={parent.id} style={{ width: "100%" }}>
              <Accordion
                container
                style={{
                  width: "100%",
                  boxShadow: "none",
                }}
                expanded={
                  (isAccordionExpanded && hasChildren) ||
                  (hasChildren && expandAll)
                }
                onChange={() => handleAccordionChange(parent.id)}
                TransitionComponent={Collapse}
              >
                {}
                <AccordionSummary
                  expandIcon={
                    parent.children_recursive &&
                    parent.children_recursive.length > 0 ? (
                      <ExpandMoreIcon
                        style={{ width: "30px", height: "30px" }}
                      />
                    ) : (
                      <div style={{ width: "30px", height: "30px" }} />
                    )
                  }
                  id={parent.id}
                  style={{
                    flexDirection: "row-reverse",
                    paddingLeft: time * 30,
                    borderBottom: "1px solid #e0e0e0",
                    width: "auto",
                    height: "50px",
                  }}
                  aria-controls={parent.description}
                >
                  <FormControlLabel
                    aria-label="Acknowledge"
                    labelPlacement="start"
                    style={{ flexDirection: "row" }}
                    onClick={(event) => event.stopPropagation()}
                    label={
                      <p
                        className={"2"}
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {parent.description}
                      </p>
                    }
                    control={
                      <Checkbox
                        onChange={(event) => {
                          handleChange(event, parent);
                        }}
                        disabled={
                          checkIfMasterIsChecked() ===
                            masterPermission.current.id ||
                          (Object.keys(expecifyUser).length !== 0 &&
                            !customized) ||
                          (Object.keys(expecifyUser).length === 0 &&
                            groupPermissionsList.length !== 0 &&
                            !customized) ||
                          userLogged.userId === expecifyUser?.id?.toString()
                        }
                        color="primary"
                        checked={founded}
                        name={parent.id}
                      />
                    }
                  />
                </AccordionSummary>
                <AccordionDetails style={{ width: "100%", transition: "0.3s" }}>
                  <div style={{ width: "100%" }}>
                    {parent.children_recursive && (
                      <RecursiveComponentAccordion
                        tree={parent.children_recursive}
                        time={time + 1}
                      />
                    )}
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <Grid item container xs={12}>
      <PermissionDetail>Detalhes da Permissão</PermissionDetail>
      <Grid
        justifyContent="space-between"
        style={{
          marginLeft: "3px",
          borderBottom: "1px solid #e0e0e0",
          width: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Grid item xs={5} style={{ display: "flex", alignItems: "center" }}>
          <CustomTooltip title="Expandir Tudo">
            <IconButton onClick={() => handleExpandAll()}>
              <ExpandIcon />
            </IconButton>
          </CustomTooltip>
          <Checkbox
            onChange={(event) => handleChange(event, masterPermission.current)}
            color="primary"
            name={masterPermission.current.id}
            style={{ marginLeft: "7px" }}
            checked={
              checkedOrNot(masterPermission.current) ||
              checkIfMasterIsChecked() === masterPermission.current.id
            }
            disabled={
              (Object.keys(expecifyUser).length !== 0 && !customized) ||
              (Object.keys(expecifyUser).length === 0 &&
                groupPermissionsList.length !== 0 &&
                !customized) ||
              userLogged.userId === expecifyUser?.id?.toString()
            }
          />
          <p style={{ fontSize: "16px" }}>Admin</p>
        </Grid>
        <PermissionFilter />
      </Grid>

      <div key={0} style={{ width: "100%" }}>
        <Accordion
          container
          style={{
            width: "100%",
            boxShadow: "none",
          }}
        >
          <AccordionSummary
            id={0}
            style={{
              flexDirection: "row-reverse",
              width: "auto",
              marginLeft: "-18px",
            }}
            className="2"
            expandIcon={
              <ExpandMoreIcon
                style={{
                  width: "30px",
                  height: "30px",
                }}
              />
            }
          >
            Módulos
          </AccordionSummary>
          <AccordionDetails style={{ width: "100%", transition: "0.3s" }}>
            <div style={{ width: "100%" }}>
              <RecursiveComponentAccordion tree={modulesPermissions} />
            </div>
          </AccordionDetails>
        </Accordion>
      </div>

      <div key={1} style={{ width: "100%" }}>
        <Accordion
          container
          style={{
            width: "100%",
            boxShadow: "none",
          }}
        >
          <AccordionSummary
            id={1}
            style={{
              flexDirection: "row-reverse",
              marginLeft: "-18px",
              width: "auto",
            }}
            className="2"
            expandIcon={
              <ExpandMoreIcon style={{ width: "30px", height: "30px" }} />
            }
          >
            Widgets
          </AccordionSummary>
          <AccordionDetails style={{ width: "100%", transition: "0.3s" }}>
            <div style={{ width: "100%" }}>
              <RecursiveComponentAccordion tree={widgetsPermissions} />
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    </Grid>
  );
};
