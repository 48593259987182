import {
  Backdrop,
  Fade,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Modal,
  makeStyles,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import {
  BackButton,
  BlueButton,
  ConfigDocText,
  HeaderGrid,
} from "../styles/ModalPublicationChannelFrame";

import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";

export const ModalProcessingEmails = ({
  open,
  handleClose,
  successEmails,
  duplicateEmails,
  errorEmails,
}) => {
  const useStyles = makeStyles((theme) => ({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "24px",
      flexDirection: "column",
      gap: "24px",
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(2, 4, 3),
      width: "580px",
      borderRadius: "16px",
    },
    MuiAccordionroot: {
      "&.MuiAccordion-root:before": {},
    },
    root: {
      "& .MuiDataGrid-root": {
        // Cor de fundo do DataGrid
        border: "none",
      },
      "& .MuiDataGrid-columnsContainer": {
        // Cor de fundo do cabeçalho
      },
      "& .MuiDataGrid-columnHeader, & .MuiDataGrid-cell": {
        // Borda inferior das células e cabeçalhos
      },
      "& .MuiDataGrid-columnSeparator": {
        display: "none",
      },
    },
  }));

  const classes = useStyles();

  const [tab, setTab] = useState(0);

  useEffect(() => {
    setTab(0);
  }, [handleClose]);

  const ResponseEmailsList = ({ objects }) => {
    return (
      <>
        {objects.map((email, index) => (
          <ListItem key={index} style={{ borderBottom: "1px solid #CFD8DC" }}>
            <ListItemText style={{ marginLeft: "-12px" }}>{email}</ListItemText>
          </ListItem>
        ))}
      </>
    );
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <Grid direction="row">
            <HeaderGrid container justify="space-between">
              <Grid
                direction="row"
                style={{ display: "flex", alignItems: "center" }}
              >
                {tab !== 0 ? (
                  <KeyboardBackspaceIcon
                    onClick={() => setTab(0)}
                    style={{
                      marginRight: "20px",
                      color: "#616161",
                      width: "24px",
                      height: "24px",
                      cursor: "pointer",
                    }}
                  />
                ) : (
                  ""
                )}
                <ConfigDocText style={{ marginBottom: "22px" }}>
                  {tab !== 0
                    ? "Detalhes de  Processamento de E-mails"
                    : "Status do Processamento de E-mails"}
                </ConfigDocText>
              </Grid>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                onClick={handleClose}
                style={{ width: "24px", height: "24px", cursor: "pointer" }}
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M3.29289 3.29289C3.68342 2.90237 4.31658 2.90237 4.70711 3.29289L12 10.5858L19.2929 3.29289C19.6834 2.90237 20.3166 2.90237 20.7071 3.29289C21.0976 3.68342 21.0976 4.31658 20.7071 4.70711L13.4142 12L20.7071 19.2929C21.0976 19.6834 21.0976 20.3166 20.7071 20.7071C20.3166 21.0976 19.6834 21.0976 19.2929 20.7071L12 13.4142L4.70711 20.7071C4.31658 21.0976 3.68342 21.0976 3.29289 20.7071C2.90237 20.3166 2.90237 19.6834 3.29289 19.2929L10.5858 12L3.29289 4.70711C2.90237 4.31658 2.90237 3.68342 3.29289 3.29289Z"
                  fill="#7E899E"
                />
              </svg>
            </HeaderGrid>
            <List>
              {tab === 0 || tab === 1 ? (
                <>
                  <ListItem style={{ borderBottom: "1px solid #CFD8DC" }}>
                    <ListItemIcon
                      style={{
                        width: "24px",
                        height: "24px",
                        marginLeft: "-12px",
                      }}
                    >
                      <CheckIcon
                        style={{
                          fill: "#22B07D",
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText style={{ marginLeft: "-24px" }}>
                      E-mails inseridos com sucesso:{" "}
                      <b> {successEmails.length}</b>
                    </ListItemText>
                    {tab === 0 ? (
                      <ListItemSecondaryAction>
                        <VisibilityOutlinedIcon
                          style={{ fill: "#616161", cursor: "pointer" }}
                          onClick={() => setTab(1)}
                        />
                      </ListItemSecondaryAction>
                    ) : (
                      <></>
                    )}
                  </ListItem>

                  {tab === 1 ? (
                    <ResponseEmailsList objects={successEmails} />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}

              {tab === 0 || tab === 2 ? (
                <>
                  <ListItem style={{ borderBottom: "1px solid #CFD8DC" }}>
                    <ListItemIcon
                      style={{
                        width: "24px",
                        height: "24px",
                        marginLeft: "-12px",
                      }}
                    >
                      <CloseIcon style={{ fill: "#FA2D1E" }} />
                    </ListItemIcon>

                    <ListItemText style={{ marginLeft: "-24px" }}>
                      E-mails Duplicados: <b> {duplicateEmails.length}</b>
                    </ListItemText>
                    {tab === 0 ? (
                      <ListItemSecondaryAction>
                        <VisibilityOutlinedIcon
                          style={{ fill: "#616161", cursor: "pointer" }}
                          onClick={() => setTab(2)}
                        />
                      </ListItemSecondaryAction>
                    ) : (
                      <></>
                    )}
                  </ListItem>
                  {tab === 2 ? (
                    <ResponseEmailsList objects={duplicateEmails} />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                ""
              )}
              {tab === 0 || tab === 3 ? (
                <>
                  <ListItem style={{ borderBottom: "1px solid #CFD8DC" }}>
                    <ListItemIcon
                      style={{
                        width: "24px",
                        height: "24px",
                        marginLeft: "-12px",
                      }}
                    >
                      <CloseIcon style={{ fill: "#FA2D1E" }} />
                    </ListItemIcon>
                    <ListItemText style={{ marginLeft: "-24px" }}>
                      E-mails com erro: <b> {errorEmails.length}</b>
                    </ListItemText>
                    {tab === 0 ? (
                      <ListItemSecondaryAction>
                        <VisibilityOutlinedIcon
                          style={{ fill: "#616161", cursor: "pointer" }}
                          onClick={() => setTab(3)}
                        />
                      </ListItemSecondaryAction>
                    ) : (
                      <></>
                    )}
                  </ListItem>
                  {tab === 3 ? (
                    <ResponseEmailsList objects={errorEmails} />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                ""
              )}
              {tab === 0 ? (
                <p
                  style={{
                    color: "#424242",
                    fontFamily: "Roboto",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: "600",
                    lineHeight: "16px",
                    letterSpacing: "0.15px",
                    marginTop: "24px",
                  }}
                >
                  {" "}
                  Total de E-mails processados:{" "}
                  {successEmails.length +
                    duplicateEmails.length +
                    errorEmails.length}
                </p>
              ) : (
                <></>
              )}
            </List>

            <Grid container justifyContent="flex-end">
              {tab === 0 ? (
                <BackButton
                  style={{ marginTop: "-8px" }}
                  item
                  onClick={handleClose}
                >
                  Fechar
                </BackButton>
              ) : (
                <>
                  <BackButton
                    item
                    onClick={() => {
                      setTab(0);
                    }}
                  >
                    <svg
                      style={{ marginRight: "4px" }}
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M20 5C20.5523 5 21 5.44772 21 6V12.5C21 13.8807 19.8807 15 18.5 15H6.41421L8.70711 17.2929C9.09763 17.6834 9.09763 18.3166 8.70711 18.7071C8.31658 19.0976 7.68342 19.0976 7.29289 18.7071L3.29289 14.7071C2.90237 14.3166 2.90237 13.6834 3.29289 13.2929L7.29289 9.29289C7.68342 8.90237 8.31658 8.90237 8.70711 9.29289C9.09763 9.68342 9.09763 10.3166 8.70711 10.7071L6.41421 13H18.5C18.7762 13 19 12.7762 19 12.5V6C19 5.44772 19.4477 5 20 5Z"
                        fill="#364461"
                      />
                    </svg>
                    Voltar
                  </BackButton>
                  <BlueButton
                    style={{
                      background: "#0036B3",
                      marginTop: "24px",
                      marginLeft: "16px",
                    }}
                    item
                    onClick={handleClose}
                  >
                    Fechar
                  </BlueButton>
                </>
              )}
            </Grid>
          </Grid>
        </div>
      </Fade>
    </Modal>
  );
};
