import * as React from "react";

function QSCIcon(props) {
  return (
    <svg
      width={22}
      height={22}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.584.015H7.673c-.78 0-1.6.327-2.325.932-.601.6-.932 1.426-.932 2.325v3.367h-1.16c-.778 0-1.6.327-2.324.932C.33 8.17 0 8.997 0 9.896v8.832c0 .79.319 1.59.932 2.326.6.6 1.426.931 2.325.931h8.832c.78 0 1.601-.327 2.325-.931.601-.602.932-1.427.932-2.326V17.57h3.367c.78 0 1.601-.328 2.326-.931.6-.602.931-1.427.931-2.326V.563a.296.296 0 00.005-.25c-.06-.141-.233-.298-.39-.298zM14.96 6.639H6.624V3.272c0-.683.477-1.16 1.16-1.16h12.198v12.2c0 .683-.475 1.16-1.158 1.16h-3.367V7.135c0-.2-.298-.497-.497-.497zm-9.502 9.999c.6.6 1.427.931 2.325.931h5.575v1.16c0 .682-.477 1.158-1.159 1.158H3.367c-.682 0-1.159-.476-1.159-1.159V9.896c0-.683.477-1.16 1.16-1.16h1.159v5.576c0 .79.318 1.59.931 2.326zm7.9-7.902v6.735H7.783c-.682 0-1.159-.476-1.159-1.159V8.736h6.734z"
        fill="#0036B3"
      />
    </svg>
  );
}

export default QSCIcon;
