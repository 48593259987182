import React from "react";
import { Helmet } from "react-helmet";
import { ItemGridDragDropTitleStyle } from "../../components/grid-drag-drop/ItemGridDragDropTitleStyle";
import { Grid, IconButton } from "@material-ui/core";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import { LightTooltip, Margin } from "../../styles/GlobalStyle";
import CachedOutlinedIcon from "@material-ui/icons/CachedOutlined";
import CloseIcon from "@material-ui/icons/Close";
import { DividerStyled } from "../../components/chat/styles/GeneralStyle";
import useDashboardViewController from "../../pages/dashboard/controllers/DashboardViewController";

export const WidgetVersion95 = () => {
  const { handleDeleteWidgets } = useDashboardViewController();

  const item = {
    i: "95",
    importScripts: ["https://app2.weatherwidget.org/js/?id=ww_ef72f3d7b4d4d"],
    stringScripts: [""],
    onRefresh:
      "try { var url = 'https://api-microservice.qyon.info/v1/fiscal-intelligence/dashboard/cteSaidas?cnpj=' + localStorage.getItem('documentPanel'); var requestOptions = { method: 'GET', headers: { 'Authorization': 'Bearer ' + localStorage.getItem('tokenCognitoPanel')  }, redirect: 'follow' }; fetch(url, requestOptions) .then(response => response.text()) .then(result => printResult(result)) .catch(error => console.log('error', error)); function printResult(result) { let obj = JSON.parse(result); obj.list.forEach(item => { let element = document.getElementById(item.id); if (element) element.innerHTML = item.value; }); } } catch (e) { console.log(e) }",
    refreshOnLoad: true,
  };

  return (
    <>
      {item.importScripts.map((script, index) => {
        return (
          <div key={`import_${index}`}>
            <Helmet>
              <script async src={script}></script>
            </Helmet>
          </div>
        );
      })}

      <ItemGridDragDropTitleStyle customStyle={item.headerStyle ?? {}}>
        <Grid item container justifyContent="space-between" alignItems="center">
          <Grid item flex={1}>
            <IconButton size="small">
              <DragIndicatorIcon style={{ fontSize: 18, marginRight: 5 }} />
            </IconButton>
            CT-e Saídas
          </Grid>
          <Grid item flex={1}>
            <Grid item container alignItems="center">
              <Margin top="7" />
              {item.onRefresh &&
                (() => {
                  if (item?.refreshOnLoad) {
                    setTimeout(() => {
                      try {
                        // eslint-disable-next-line no-eval
                        eval("try{" + item.onRefresh + "}catch(err){}");
                      } catch (error) {
                        console.error(error);
                      }
                    }, 1500);
                  }

                  return (
                    <Grid item flex={1}>
                      <LightTooltip title="Atualizar">
                        <CachedOutlinedIcon
                          style={{
                            fontSize: 18,
                            marginRight: 10,
                            cursor: "pointer",
                            color: "#636363",
                          }}
                          onClick={() => {
                            // Muito cuidado, o eval é perigoso - https://developer.mozilla.org/pt-BR/docs/Web/JavaScript/Reference/Global_Objects/eval
                            try {
                              // eslint-disable-next-line no-eval
                              eval("try{" + item.onRefresh + "}catch(err){}");
                            } catch (error) {
                              console.error(error);
                            }
                          }}
                        />
                      </LightTooltip>
                    </Grid>
                  );
                })()}
              <Grid item flex={1}>
                <LightTooltip title="Remover">
                  <CloseIcon
                    style={{
                      fontSize: 18,
                      cursor: "pointer",
                      color: "#636363",
                      marginRight: 10,
                    }}
                    onClick={() => handleDeleteWidgets(item)}
                  />
                </LightTooltip>
              </Grid>
            </Grid>
          </Grid>
          <DividerStyled style={{ width: "100%" }} />
        </Grid>
      </ItemGridDragDropTitleStyle>

      <div
        style={{
          height: `${"calc(100% - 65px)"}`,
          width: "100%",
        }}
      >
        <head>
          <meta charSet="UTF-8" />
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link
            rel="preconnect"
            href="https://fonts.gstatic.com"
            crossOrigin="anonymous"
          />
          <link
            href="https://fonts.googleapis.com/css2?family=Roboto&display=swap"
            rel="stylesheet"
          />
        </head>
        <div
          style={{
            fontFamily: "'Roboto', sans-serif",
            fontSize: "14px",
          }}
        >
          <p
            style={{
              fontSize: "15px",
              fontWeight: "bold",
              paddingBottom: 0,
              marginTop: 0,
            }}
          >
            Saídas
          </p>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                width: "4px",
                height: "73px",
                background: "#0A73DB",
                borderRadius: "2px",
              }}
            />
            <div style={{ width: "100%", paddingLeft: "10px" }}>
              <div style={{ display: "flex", paddingBottom: "2px" }}>
                <span style={{ width: "100%", color: "#7B7B7B" }}>Hoje</span>
                <span id="CTeSaidasHoje" style={{ fontWeight: "bold" }}>
                  0
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  padding: "2px 0",
                  borderBottom: "1px solid #E5E5E5",
                  borderTop: "1px solid #E5E5E5",
                }}
              >
                <span style={{ width: "100%", color: "#7B7B7B" }}>
                  Mês Atual
                </span>
                <span id="CTeSaidasMesAtual" style={{ fontWeight: "bold" }}>
                  0
                </span>
              </div>
              <div style={{ display: "flex", paddingTop: "2px" }}>
                <span style={{ width: "100%", color: "#7B7B7B" }}>
                  Mês Anterior
                </span>
                <span id="CTeSaidasMesAnterior" style={{ fontWeight: "bold" }}>
                  0
                </span>
              </div>
            </div>
          </div>
          <p style={{ fontSize: "15px", fontWeight: "bold", paddingBottom: 0 }}>
            Canceladas
          </p>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                width: "4px",
                height: "73px",
                background: "#FA3A3A",
                borderRadius: "2px",
              }}
            />
            <div style={{ width: "100%", paddingLeft: "10px" }}>
              <div style={{ display: "flex", paddingBottom: "2px" }}>
                <span style={{ width: "100%", color: "#7B7B7B" }}>Hoje</span>
                <span id="CTeCanceladassHoje" style={{ fontWeight: "bold" }}>
                  0
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  padding: "2px 0",
                  borderBottom: "1px solid #E5E5E5",
                  borderTop: "1px solid #E5E5E5",
                }}
              >
                <span style={{ width: "100%", color: "#7B7B7B" }}>
                  Mês Atual
                </span>
                <span id="CTeCanceladasMesAtual" style={{ fontWeight: "bold" }}>
                  0
                </span>
              </div>
              <div style={{ display: "flex", paddingTop: "2px" }}>
                <span style={{ width: "100%", color: "#7B7B7B" }}>
                  Mês Anterior
                </span>
                <span
                  id="CTeCanceladasMesAnterior"
                  style={{ fontWeight: "bold" }}
                >
                  0
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {item.stringScripts &&
        item.stringScripts.map((script, index) => {
          return (
            <div key={`script_${index}`}>
              <Helmet>
                <script async>{script}</script>
              </Helmet>
            </div>
          );
        })}
    </>
  );
};
