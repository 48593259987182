import axios from "axios";

const BOT_API = process.env.REACT_APP_BOT_API_URL;

export const getXMLData = async (base64) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "c799c146-6264-4bc6-94d7-56b4cac8deb0",
  };

  let response = await axios.post(
    BOT_API + "/v1/bot/scan/xml",
    {
      base64,
    },
    {
      headers: headers,
    }
  );
  return response.status === 200 ? response.data : null;
};
