import * as React from "react";

function AtendimentoIcon(props) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M23.038 11.2c-.528-.602-1.21-.97-2.083-1.122-.175-1.264-.505-2.384-1.009-3.423a8.757 8.757 0 00-5.69-4.673 8.725 8.725 0 00-7.222 1.313C5.149 4.62 3.954 6.477 3.382 8.973c-.07.304-.123.615-.178.93l-.036.21c-.795.095-1.295.274-1.766.63-.99.746-1.459 1.813-1.397 3.17.09 1.926 1.434 3.276 3.346 3.36l.03.001a.782.782 0 01.015.046c.775 2.705 2.525 4.34 5.2 4.857.678.132 1.406.125 1.99.116h.122c.243-.003.485-.002.742.006a.809.809 0 00.327-.083 1.11 1.11 0 00.636-1.253c-.105-.504-.557-.862-1.102-.87-.316-.006-.632-.003-.95-.004a36.91 36.91 0 01-.835-.004 3.569 3.569 0 01-1.836-.514c-1.489-.897-2.28-2.29-2.35-4.14-.052-1.384-.03-2.798-.007-4.181v-.056c.02-1.253.28-2.402.77-3.415 1.158-2.394 3.039-3.681 5.592-3.827 2.494-.153 4.474.86 5.877 2.994.812 1.233 1.215 2.685 1.232 4.439.01 1.007.008 2.03.006 3.025l-.002 1.618c.002.705.458 1.167 1.162 1.178l.098.001c.141.005.295.005.448-.005 1.533-.096 2.6-.831 3.172-2.182.576-1.358.352-2.679-.65-3.82z"
        fill="#0036B3"
      />
      <path
        d="M8.696 12.46a1.19 1.19 0 00-.838.362 1.128 1.128 0 00-.326.816c.016.614.55 1.136 1.176 1.14.302 0 .604-.127.826-.348.222-.223.349-.52.348-.817 0-.303-.122-.59-.343-.809a1.198 1.198 0 00-.835-.345h-.008zM12.067 12.445h-.003a1.155 1.155 0 00-1.153 1.146 1.172 1.172 0 001.156 1.197c.3 0 .585-.117.803-.331.225-.222.35-.52.352-.835a1.169 1.169 0 00-.341-.838 1.143 1.143 0 00-.814-.339zM15.41 12.447h-.004a1.156 1.156 0 00-1.135 1.164 1.171 1.171 0 001.16 1.177 1.17 1.17 0 001.15-1.184 1.166 1.166 0 00-.352-.832 1.178 1.178 0 00-.818-.325z"
        fill="#0036B3"
      />
    </svg>
  );
}

export default AtendimentoIcon;
