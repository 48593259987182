import { search } from "../../BaseRepositoryComponents";

const newPath = `${process.env.REACT_APP_NEW_PAINEL_API_URL}/v1`;

export async function getSystemsEntity({ id }) {
  return search({ path: `${newPath}/entity/${id}/systems`, method: "GET" });
}

export async function getCustomersEntityNew({ id }) {
  return search({
    path: `${newPath}/${id}/client`,
    method: "GET",
  });
}

export async function getUser(idEntity) {
  return search({
    path: `${newPath}/entity_user/${idEntity}/users`,
    method: "GET",
  });
}

export async function getLogoBySubdomain(subdomain) {
  return search({
    path: `${newPath}/entity/logo-by-subdomain/${subdomain}`,
    method: "GET",
  });
}

export async function getPromoVideoBySubdomain(subdomain) {
  return search({
    path: `${newPath}/entity/promo-video-by-subdomain/${subdomain}`,
    method: "GET",
  });
}

export async function getExistsBySubdomain(subdomain) {
  return search({
    path: `${newPath}/entity/exists-by-subdomain/${subdomain}`,
    method: "GET",
  });
}

export async function getWebsiteBySubdomain(subdomain) {
  return search({
    path: `${newPath}/website/get-website/by/subdomain/${subdomain}`,
    method: "GET",
  });
}

export async function postAccountantWebsite(body, idEntity) {
  return search({
    path: `${newPath}/website/post-website/entity/${idEntity}`,
    method: "POST",
    myBody: body,
  });
}

export async function putAccountantWebsite(body, idEntity) {
  return search({
    path: `${newPath}/website/put-website/entity/${idEntity}`,
    method: "PUT",
    myBody: body,
  });
}

export async function putEntityWebsiteInfo(body, idEntity) {
  return search({
    path: `${newPath}/website/change-website-info/entity/${idEntity}`,
    method: "PUT",
    myBody: body,
  });
}

export async function getPermissionTree({ document }) {
  return search({
    path: `${newPath}/entity/${document}/permissions`,
  });
}

export async function getPermissionGroup({ entityId }) {
  return search({
    path: `${newPath}/entity/${entityId}/permission-group`,
    method: "GET",
  });
}

export async function postPermissionGroup({ userId, body }) {
  return search({
    path: `${newPath}/entity/${userId}/permission-group`,
    method: "POST",
    myBody: body,
  });
}
export async function updateOrLinkUserPermissionGroup({
  entityId,
  userId,
  body,
}) {
  return search({
    path: `${newPath}/entity/${entityId}/user/${userId}/permission-group`,
    method: "PUT",
    myBody: body,
  });
}

export async function putPermissionGroup({ userId, groupId, body }) {
  return search({
    path: `${newPath}/entity/${userId}/permission-group/${groupId}`,
    method: "PUT",
    myBody: body,
  });
}

export async function getUserPermission({
  document,
  userId,
  withDetails = false,
}) {
  return search({
    path: `${newPath}/entity/${document}/user/${userId}/permissions${
      withDetails ? "?withDetails=true" : ""
    }`,
    method: "GET",
  });
}

export async function postUserPermission({ userId, user_post_id, body }) {
  return search({
    path: `${newPath}/entity/${userId}/user/${user_post_id}/permissions`,
    method: "POST",
    myBody: body,
  });
}

export async function deletePermissionGroup({ userId, groupId, entityId }) {
  return search({
    path: `${newPath}/entity/${entityId}/user/${userId}/permission-group/${groupId}`,
    method: "DELETE",
  });
}

export async function getSystemsInPreviewFromEntity() {
  const url = `${newPath}/entity/${localStorage.getItem(
    "companyIdPanel"
  )}/beta`;

  return search({
    path: url,
    method: "GET",
  });
}
