import { Grid, InputAdornment, TextField } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { SearchIcon } from "../../../components/icons/SearchIcon";
import { ManagementContext } from "../../accessProfile/contexts/ManagementContext";

export const PermissionFilter = () => {
  const [searchNamePermission, setSearchNamePermission] = useState("");
  const { setPermissionNameFilter } = useContext(ManagementContext);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    setPermissionNameFilter("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      setPermissionNameFilter(searchNamePermission);
    }, 160);

    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchNamePermission]);

  return (
    <>
      <Grid item xs={7}>
        <TextField
          variant="outlined"
          size="small"
          fullWidth
          placeholder="Pesquisar por nome da permissão"
          value={searchNamePermission}
          onChange={(e) => setSearchNamePermission(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
    </>
  );
};
