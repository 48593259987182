import * as React from "react";

function EditIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill={props?.fill || "#616161"}
        fillRule="evenodd"
        d="M15.847 2a1 1 0 01.708.293l3.652 3.654a1 1 0 010 1.414L9.866 17.707a1 1 0 01-.707.293H5.5a1 1 0 01-1-1v-3.64a1 1 0 01.292-.707L15.14 2.293A1 1 0 0115.847 2zm0 2.415L6.5 13.774V16h2.244l9.342-9.346-2.238-2.24zM2.5 21a1 1 0 011-1h18a1 1 0 110 2h-18a1 1 0 01-1-1z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default EditIcon;
