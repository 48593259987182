import {
  CircularProgress,
  DialogActions,
  DialogContent,
  Modal,
} from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import {
  ButtonCancel,
  ButtonSave,
  CloseIconStyle,
  DialogHeader,
  DialogStyle,
} from "../Modal";
import { ConfigTitle } from "../styles/ModalServicesConfig";
import ModalCommsConfigBody from "./components/ModalCommsConfigBody";
import ModalCommsConfigChannelBody from "./components/ModalCommsConfigChannelBody";
import { Pagination } from "@material-ui/lab";
import { EmailTemplateContext } from "../contexts/EmailTemplateContext";
import ModalCommsConfigTemplateBody from "./components/ModalCommsConfigTemplateBody";
import { exportEmailTemplateHtml } from "../controllers/EmailTemplateController";
import { notify } from "../../../utils/notify";
import { GlobalContext } from "../../../contexts/GlobalContext";
import CloudDoneOutlinedIcon from "@material-ui/icons/CloudDoneOutlined";
import KeyboardReturnIcon from "@material-ui/icons/KeyboardReturn";

import {
  getSystemsServicesBySystemId,
  getSystemsServicesBySystemIdAccountant,
  postConfiguration,
} from "../../../repositories/CommsConfig";

const ModalCommsConfig = ({
  open,
  handleClose,
  onSave,
  listSystemServices,
  listServices,
  selectedEntityId,
  selectedServiceId,
  systemId,
  ...props
}) => {
  const DEFAULT_INSTANT_TEMPLATE = "3aa92767-a2f8-424b-ba98-46cff1a0df51";
  const DEFAULT_SCHEDULED_TEMPLATE = "0cc22533-12b6-4204-8530-d77d32f43f41";

  const [titleDialog, setTitleDialog] = useState("");
  const [helpText, setHelpText] = useState("");
  const [screen, setScreen] = useState("config");

  const [selectedServices, setSelectedServices] = useState({});

  const [currentServiceId, setCurrentServiceId] = useState({});

  const [loadingSave, setLoadingSave] = useState(false);

  const [loading, setLoading] = useState(false);

  const TEMPLATES_PER_PAGE = 5;

  const {
    listTemplates,
    setPageTemplateList,
    openEditorTemplate,
    setOpenEditorTemplate,
    emailEditorRef,
    templateId,
    templateName,
    templateTag,
    templateType,
    editorType,
    typeNotification,
  } = useContext(EmailTemplateContext);

  const { companyUser } = useContext(GlobalContext);

  //Só troca o titulo e subtitulo do modal
  useEffect(() => {
    setTitleDialog("Automação de Notificação");

    if (
      Object.keys(selectedServices).length > 0 &&
      selectedEntityId !== undefined
    ) {
      setHelpText(
        "Configure a frequência de envio, template de comunicação e os recipientes."
      );
    } else if (Object.keys(selectedServices).length > 0) {
      setHelpText(
        "Configure a frequência de envio e o template de comunicação."
      );
    }

    if (Object.keys(selectedServices).length === 0) {
      setHelpText(
        "Selecione os serviços de comunicação que deseja configurar."
      );
    }
  }, [selectedServices, selectedEntityId]);

  useEffect(() => {
    if (open) {
      getSystemConfig();
    }
    // eslint-disable-next-line
  }, [open]);

  const getSystemConfig = async () => {
    setLoading(true);
    if (selectedEntityId === null || selectedEntityId === undefined) {
      let response = await getSystemsServicesBySystemIdAccountant(
        selectedServiceId
      );

      if (response?.success) {
        if (response?.data?.length > 0) {
          let services = {};
          response.data.forEach((service) => {
            if (service?.time_notification) {
              service.time_notification = service.time_notification.substring(
                0,
                5
              );
            }

            if (!service?.documents) {
              service.documents = [];
            }

            services[service.service_id] = {
              ...service,
            };
          });

          setSelectedServices(services);
        }
      }
    } else {
      let response = await getSystemsServicesBySystemId(
        selectedServiceId,
        selectedEntityId
      );
      if (response?.success) {
        if (response?.data?.length > 0) {
          let services = {};
          response.data.forEach((service) => {
            if (service?.time_notification) {
              service.time_notification = service.time_notification.substring(
                0,
                5
              );
            }

            if (!service?.documents) {
              service.documents = [];
            }

            services[service.service_id] = {
              ...service,
            };
          });

          setSelectedServices(services);
        } else {
          setSelectedServices({});
        }
      }
    }
    setLoading(false);
  };

  const handleOpenPublicationChannels = (serviceId) => {
    setCurrentServiceId(serviceId);

    setScreen("channel");
  };

  const handleOpenTemplate = (serviceId) => {
    setCurrentServiceId(serviceId);
    setScreen("template");
  };

  const saveTemplate = async () => {
    if (templateName) {
      await exportEmailTemplateHtml(
        emailEditorRef,
        templateId,
        companyUser.document,
        templateName,
        templateTag,
        templateType,
        editorType,
        typeNotification
      );

      setOpenEditorTemplate(false);
    } else {
      notify("Preencha o nome do template", true, "error");
    }
  };

  const addConfigurations = async () => {
    if (checkIfHasEmptyDocumentsOnInsert()) {
      let objectKeys = Object.keys(selectedServices);

      let bodyRequest = objectKeys.map((key) => {
        let service = selectedServices[key];
        let body = {
          ...service,
          service_id: key,
        };

        return body;
      });

      let responseConfiguration = await postConfiguration(bodyRequest);
      if (responseConfiguration?.success) {
        return notify("Configuração Realizada Com Sucesso!", true, "success");
      } else {
        notify("Erro ao realizar a configuração", true, "error");
      }
    }

    return false;
  };

  const checkIfHasEmptyDocumentsOnInsert = () => {
    for (const serviceId of Object.keys(selectedServices)) {
      if (
        selectedServices[serviceId].documents?.length === 0 &&
        selectedServices[serviceId].entity_id !== null
      ) {
        notify(
          "Selecione pelo menos um destinatário para receber as notificações"
        );
        return false;
      }
    }
    return true;
  };

  const handleSubmit = async () => {
    setLoadingSave(true);
    try {
      if (screen === "template" && openEditorTemplate) {
        await saveTemplate();
      } else {
        if (screen !== "config") {
          setScreen("config");
        } else {
          if (await addConfigurations()) {
            handleClose();
          }
        }
      }
    } catch (e) {
      notify("Erro ao realizar a configuração", true, "error");
    }
    setLoadingSave(false);
  };

  return (
    <Modal
      open={open}
      onClose={(event, reason) => {
        if (reason === "backdropClick" && screen !== "template") {
          handleClose();
          setSelectedServices({});
        }
      }}
    >
      <DialogStyle style={{ width: 800 }}>
        <DialogHeader style={{ paddingBottom: 0 }}>
          <div className="row">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <ConfigTitle>{titleDialog}</ConfigTitle>
              <div style={{ color: "#b8b8b8", fontSize: "18px" }}>
                {helpText}
              </div>
            </div>

            <CloseIconStyle
              onClick={() => {
                if (screen === "template" && openEditorTemplate) {
                  setOpenEditorTemplate(false);
                }
                if (screen !== "config") {
                  setScreen("config");
                }
                handleClose();
              }}
            />
          </div>
        </DialogHeader>

        <DialogContent style={{ width: 800, maxHeight: "70vh" }}>
          {loading ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress />
            </div>
          ) : screen === "config" ? (
            <ModalCommsConfigBody
              systemId={systemId}
              listServices={listServices}
              selectedServices={selectedServices}
              setSelectedServices={setSelectedServices}
              selectedEntityId={selectedEntityId}
              handleOpenPublicationChannels={handleOpenPublicationChannels}
              handleOpenTemplate={handleOpenTemplate}
              setScreen={setScreen}
              DEFAULT_INSTANT_TEMPLATE={DEFAULT_INSTANT_TEMPLATE}
              DEFAULT_SCHEDULED_TEMPLATE={DEFAULT_SCHEDULED_TEMPLATE}
            />
          ) : screen === "template" ? (
            <ModalCommsConfigTemplateBody
              selectedServices={selectedServices}
              setSelectedServices={setSelectedServices}
              serviceId={currentServiceId}
              DEFAULT_INSTANT_TEMPLATE={DEFAULT_INSTANT_TEMPLATE}
              DEFAULT_SCHEDULED_TEMPLATE={DEFAULT_SCHEDULED_TEMPLATE}
              // handleCreateNewTemplateClick={}
            />
          ) : (
            screen === "channel" && (
              <ModalCommsConfigChannelBody
                entityId={selectedEntityId}
                selectedServices={selectedServices}
                setSelectedServices={setSelectedServices}
                serviceId={currentServiceId}
                listServices={listServices}
              />
            )
          )}
        </DialogContent>

        <DialogActions>
          {screen === "template" && !openEditorTemplate && (
            <Pagination
              style={{ marginTop: "24px", marginRight: "125px" }}
              count={Math.ceil(listTemplates.length / TEMPLATES_PER_PAGE)}
              onChange={(ev, value) => {
                setPageTemplateList(value - 1);
              }}
            />
          )}
          <ButtonCancel
            onClick={() => {
              if (screen === "template" && openEditorTemplate) {
                setOpenEditorTemplate(false);
              } else {
                if (screen !== "config") {
                  setScreen("config");
                } else {
                  handleClose();
                }
              }
            }}
            color="primary"
          >
            <KeyboardReturnIcon style={{ marginRight: "4px" }} />
            {screen === "config" ? "Cancelar" : "Voltar"}
          </ButtonCancel>
          <ButtonSave disabled={loadingSave} onClick={handleSubmit}>
            <CloudDoneOutlinedIcon style={{ marginRight: "8px" }} />
            {loadingSave ? "Salvando..." : "Salvar"}
          </ButtonSave>
        </DialogActions>
      </DialogStyle>
    </Modal>
  );
};

export default ModalCommsConfig;
