import React, { useEffect, useState } from "react";
import { Header } from "../header/Header";
import { SidebarDrawer } from "../sidebarDrawer/SidebarDrawer";
import {
  Button,
  CssBaseline,
  Drawer,
  Grid,
  Modal,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { DialogGenial4Body } from "../sidebar/styles/Sidebar";
import Genial4Image from "../../assets/icons/alert_client_genial4.jpeg";
import { GlobalContext } from "../../contexts/GlobalContext";
import { useContext } from "react";
import { BotActionBar } from "../botActionBar/BotActionBar.js";
import {
  StyledContainer,
  StyledLayoutContainerBase,
} from "./styles/LayoutContainer";
import { NewBot } from "../newBot/NewBot";
import { getAllActions } from "../../repositories/Bot";
import { AccoutantInClientViewFooter } from "../sidebar/children/styles/AccountantInClientViewFooter";
import { Warning } from "@material-ui/icons";
import { usePortalAsClient } from "../../utils/viewPortalAsClientHook";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import { getSystemVersionProduct } from "../../repositories/panel/v1/integrations/VersionPortal.js";
import ModalNewLetterVersion from "../../pages/dashboard/children/ModalNewLetterVersion.js";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min.js";

export const LayoutContainer = ({ children, noPadding = false }) => {
  const isInClientView = sessionStorage.getItem("isInClientView");
  const { backToAccountantView, clientViewed } = usePortalAsClient();
  const { push } = useHistory();

  const actionsEnum = [
    {
      product: "QYON_DASH",
      total: 0,
    },
    {
      product: "QYON_XML",
      total: 0,
    },
    {
      product: "QYON_ERP",
      total: 0,
    },
    {
      product: "QYON_ERP_ADM",
      total: 0,
    },
    {
      product: "QYON_ACCOUNTANT",
      total: 0,
    },
    {
      product: "QYON_CRM",
      total: 0,
    },
    {
      product: "QYON_BANK",
      total: 0,
    },
  ];

  const [openGenial4Alert, setOpenGenial4Alert] = useState(false);
  const [key, setKey] = useState(Math.random());
  const [actionTotal, setActionTotal] = useState(0);
  const [actions, setActions] = useState(actionsEnum);
  const [panelType, setPanelType] = useState("");
  const [isFeedPage, setIsFeedPage] = useState(false);
  const location = useLocation();

  const [modalNewLetterVersion, setModalNewLetterVersion] = useState(false);
  const [newLetter, setNewLetter] = useState(null);

  const { sidebaraction, setSidebarAction, animation, overlap, companyUser } =
    useContext(GlobalContext);

  const refresh = async () => {
    let response = await getAllActions();

    setActionTotal(response.headers.count);

    if (response != null && response.status === 200 && response.data != null) {
      let QYON_DASH = response.data.filter(
        (e) => e.product === "QYON_DASH" && e.seen === 0
      );
      let QYON_XML = response.data.filter(
        (e) => e.product === "QYON_XML" && e.seen === 0
      );
      let QYON_ERP = response.data.filter(
        (e) => e.product === "QYON_ERP" && e.seen === 0
      );
      let QYON_ERP_ADM = response.data.filter(
        (e) => e.product === "QYON_ERP_ADM" && e.seen === 0
      );
      let QYON_ACCOUNTANT = response.data.filter(
        (e) => e.product === "QYON_ACCOUNTANT" && e.seen === 0
      );
      let QYON_CRM = response.data.filter(
        (e) => e.product === "QYON_CRM" && e.seen === 0
      );
      let QYON_BANK = response.data.filter(
        (e) => e.product === "QYON_BANK" && e.seen === 0
      );

      let statistics = [
        {
          product: "QYON_DASH",
          total: QYON_DASH.length,
        },
        {
          product: "QYON_XML",
          total: QYON_XML.length,
        },
        {
          product: "QYON_ERP",
          total: QYON_ERP.length,
        },
        {
          product: "QYON_ERP_ADM",
          total: QYON_ERP_ADM.length,
        },
        {
          product: "QYON_ACCOUNTANT",
          total: QYON_ACCOUNTANT.length,
        },
        {
          product: "QYON_CRM",
          total: QYON_CRM.length,
        },
        {
          product: "QYON_BANK",
          total: QYON_BANK.length,
        },
      ];

      setActions(statistics);
    }
  };

  const useStyles = makeStyles((theme) => ({
    content: {
      backgroundColor: "#F7F7F7",
      minHeight: "100vh",
      height: "auto",
      overflow: isFeedPage ? "" : "hidden",
    },
    tooltipBot: {
      zIndex: overlap
        ? animation === 6
          ? "1 !important"
          : "0 !important"
        : "1 !important",
    },

    drawerBot: {
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
      paddingTop: 65,
      width: 300,
      flexShrink: 0,
      backgroundColor: "#f0f1f3",
    },
    drawerOpenBot: {
      top: 65,
      width: 300,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      backgroundColor: "#f0f1f3",
    },
    drawerCloseBot: {
      top: 65,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
      width: 55,
      [theme.breakpoints.up("sm")]: {
        width: 55,
      },
      backgroundColor: "#f0f1f3",
    },
  }));

  const classes = useStyles({});
  const [botActive, setBotActive] = useState(false);

  const handleDrawerClose = () => {
    setSidebarAction((current) => ({ ...current, open: false }));
    setTimeout(() => {
      dispatchEvent(new Event("resize"));
    }, 0);
  };

  const handleDrawerOpen = () => {
    if (window.innerWidth < 700) return;

    setSidebarAction((current) => ({ ...current, open: true }));
    setTimeout(() => {
      dispatchEvent(new Event("resize"));
    }, 0);
  };

  useEffect(() => {
    window.addEventListener("message", function (e) {
      if (e.data === "OPEN") {
        if (
          document.getElementById("bot-webapp").classList.contains("bot-webapp")
        ) {
          return;
        }
        document.getElementById("bot-webapp").classList.add("bot-webapp");
        setBotActive(true);
      }
      if (e.data === "CLOSE") {
        document.getElementById("bot-webapp").classList.remove("bot-webapp");
        setBotActive(false);
      }
    });
  }, []);

  useEffect(() => {
    if (companyUser.isAccountant === "true") {
      setPanelType("accountant");
    } else if (
      companyUser.accountantCnpj !== null &&
      companyUser.accountantCnpj !== undefined &&
      companyUser.accountantCnpj !== ""
    ) {
      setPanelType("client");
    } else {
      setPanelType("standalone");
    }
  }, [companyUser.accountantCnpj, companyUser.isAccountant]);

  const handleBackToAccountantView = () => {
    backToAccountantView();
    push("/dashboard/view");
  };

  useEffect(() => {
    if (!localStorage.getItem("redirectedFromLogin")) return;

    getLastLetterVersion();

    localStorage.removeItem("redirectedFromLogin");
  }, []);

  useEffect(() => {
    if (location.pathname === "/feed") {
      setIsFeedPage(true);
    } else {
      setIsFeedPage(false);
    }
  }, [location]);

  const getLastLetterVersion = async () => {
    if (!localStorage.getItem("dontShowNewLetters")) {
      let response = await getSystemVersionProduct("Portal");

      if (response?.success && response?.data?.length > 0) {
        if (
          localStorage.getItem("lastViewedVersion") === response.data[0].version
        ) {
          return;
        }

        setNewLetter(response.data[0]);
        setModalNewLetterVersion(true);
        localStorage.setItem("lastViewedVersion", response?.data[0].version);
      }
    }
  };

  return (
    <StyledLayoutContainerBase>
      <CssBaseline />
      <Header
        type={panelType}
        customKey={key}
        setKey={setKey}
        actionTotal={actionTotal}
        actions={actions}
        setActionTotal={setActionTotal}
        setActions={setActions}
        refresh={refresh}
      />
      <SidebarDrawer notificationOpen={false} />
      <main
        className={classes.content}
        style={{ width: botActive ? "calc(100% - 540px)" : "100%" }}
      >
        <StyledContainer
          style={{
            overflowY: "auto",
            overflowX: "hidden",
            marginTop: 65,
            height: isFeedPage ? "" : "calc(100vh - 62px)",
            paddingLeft: noPadding ? 0 : 30,
            paddingTop: noPadding ? 0 : 20,
            paddingRight: noPadding ? 0 : 30,
            paddingBottom: noPadding ? 0 : 20,
          }}
        >
          {children}
        </StyledContainer>
      </main>
      {isInClientView && (
        <AccoutantInClientViewFooter container>
          <Grid item>
            <Warning />
          </Grid>
          <Grid item xs>
            <Typography>
              Atenção, você está visualizando o Portal como{" "}
              <strong>
                {clientViewed?.name} [{clientViewed?.clientCompanyName}]
              </strong>
              !
            </Typography>
          </Grid>
          <Grid item>
            <Button
              onClick={handleBackToAccountantView}
              variant="outlined"
              color="secondary"
            >
              Voltar à visão de contador
            </Button>
          </Grid>
        </AccoutantInClientViewFooter>
      )}

      <Drawer
        variant="permanent"
        anchor="right"
        className={clsx(classes.drawerBot, {
          [classes.drawerOpenBot]: sidebaraction.open,
          [classes.drawerCloseBot]: !sidebaraction.open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpenBot]: sidebaraction.open,
            [classes.drawerCloseBot]: !sidebaraction.open,
          }),
        }}
      >
        <div style={{ display: "flex" }}>
          <BotActionBar
            style={{ zIndex: overlap ? -1 : 1200 }}
            actions={actions}
            customKey={key}
            setCustomKey={setKey}
            actionTotal={actionTotal}
            setActionTotal={setActionTotal}
            setActions={setActions}
            handleCloseDrawer={handleDrawerClose}
            handleOpenDrawer={handleDrawerOpen}
          ></BotActionBar>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              top: 65,
              height: "100%",
            }}
          >
            {sidebaraction.open && (
              <div id="NEW_BOT">
                <NewBot notificationOpen={false} />
              </div>
            )}
          </div>
        </div>
      </Drawer>
      <Modal
        open={openGenial4Alert}
        onClose={() => {
          setOpenGenial4Alert(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <DialogGenial4Body>
          <img src={Genial4Image} alt="Genial4" width="100%" />
        </DialogGenial4Body>
      </Modal>

      {modalNewLetterVersion && (
        <ModalNewLetterVersion
          newLetter={newLetter}
          open={modalNewLetterVersion}
          setOpen={setModalNewLetterVersion}
        />
      )}
    </StyledLayoutContainerBase>
  );
};
