import { Switch } from "react-router-dom";

import MiddlewareRoute from "../Middleware";
import { BackupPage } from "../../pages/backup/BackupPage";

export default function Backup() {
  return (
    <Switch>
      <MiddlewareRoute
        path="/backup"
        sidebar
        type="backup"
        component={BackupPage}
        noPaddingSidebar
      />
    </Switch>
  );
}
