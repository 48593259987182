import { search } from "../../../src/repositories/BaseRepositoryComponents";

const baseUrl = process.env.REACT_APP_NEW_PAINEL_API_URL;

export function getEntityBackups(govId) {
  return search({
    path: `${baseUrl}/v1/backup/${govId}`,
    method: "GET",
  });
}
