import { search } from "../../BaseRepositoryComponents";

const newPath = `${process.env.REACT_APP_NEW_PAINEL_API_URL}/v1`;

export async function getRPAExecutions({ document }) {
  const url = `${newPath}/certificate/${document}/rpa/list`;

  return search({
    path: url,
    method: "GET",
  });
}

export async function startRPAExecution({ document }) {
  const url = `${newPath}/certificate/${document}/rpa/start`;

  return search({
    path: url,
    method: "POST",
    myBody: {},
  });
}

export async function getLastProcuration(document) {
  const url = `${newPath}/certificate/last-procuration-searched/${document}`;

  return search({
    path: url,
    method: "GET",
  });
}
