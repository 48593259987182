import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TextFieldErp from "../../components/basics/TextFieldErp";
import { GridStyled, Margin } from "../../styles/GlobalStyle";
import { LoginContext } from "./contexts/LoginContext";
import useLoginController from "./controllers/LoginController";
import { PasswordIcon, QyonLogo } from "./LoginIcon";

import {
  RecoveryPasswordHeader,
  RecoveryPasswordHeaderSecondary,
  FormRecoveryPassword,
  RecoveryLabel,
  HeaderTextField,
  RecoverySendButton,
  RecoveryCancelButton,
  LogoQyonContainer,
} from "./styles/RecoveryPassword";
import PasswordRequirements from "./PasswordRequirements";
import { IconButton, InputAdornment } from "@material-ui/core";
import LoginLayout from "./LoginLayout";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%",
  },
}));

export default function RecoverPassword({ location }) {
  const {
    handleSendCodeVerif,
    handleChangeEmail,
    handleChangePasswordRecover,
  } = useLoginController();
  const {
    email,
    setEmail,
    errors,
    sendCodeVerif,
    setSendCodeVerif,
    newPassword,
    setNewPassword,
    confirmNewPassword,
    setConfirmNewPassword,
    verificationCode,
    setVerificationCode,
  } = useContext(LoginContext);
  const { push } = useHistory();
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

  useEffect(() => {
    setSendCodeVerif(false);
    setVerificationCode("");
    setNewPassword("");
    setConfirmNewPassword("");
    setVerificationCode("");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (verificationCode && !verificationCode.replace(/[^0-9]+/g, "")) {
      setVerificationCode("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verificationCode]);

  useEffect(() => {
    if (location.state && location.state.email) setEmail(location.state.email);
  }, [location, setEmail]);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowPasswordConfirm = () =>
    setShowPasswordConfirm((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <LoginLayout>
      <FormRecoveryPassword style={{ maxHeight: "100vh", overflow: "auto" }}>
        <GridStyled>
          <Margin top="16" />
          <LogoQyonContainer>
            <QyonLogo />
          </LogoQyonContainer>

          <Margin top="16" />
          <div className={classes.paper}>
            {sendCodeVerif ? (
              <>
                <RecoveryPasswordHeader>
                  Precisamos verificar sua identidade
                </RecoveryPasswordHeader>
                <Margin top="8" />
                <RecoveryPasswordHeaderSecondary>
                  Sua senha deve conter no mínimo 8 caracteres
                </RecoveryPasswordHeaderSecondary>
              </>
            ) : (
              <>
                <RecoveryPasswordHeader>
                  Cadastrar Nova Senha
                </RecoveryPasswordHeader>
                <Margin top="8" />
                <RecoveryPasswordHeaderSecondary>
                  Digite seu e-mail para recuperar a senha
                </RecoveryPasswordHeaderSecondary>
              </>
            )}

            {sendCodeVerif ? (
              <>
                <GridStyled justifyContent="flex" container>
                  <RecoveryLabel>Código de Verificação</RecoveryLabel>
                  <TextFieldErp
                    autocapitalize="off"
                    autocomplete="off"
                    autocorrect="off"
                    error={errors.verificationCode ? true : false}
                    helperText={errors.verificationCode || false}
                    variant="outlined"
                    fullWidth
                    placeholder="Digite o código de Verificação"
                    value={verificationCode}
                    setValue={setVerificationCode}
                  />
                </GridStyled>

                <GridStyled justifyContent="flex" container>
                  <RecoveryLabel>Nova Senha</RecoveryLabel>

                  <TextFieldErp
                    error={errors.newPassword ? true : false}
                    helperText={errors.newPassword || ""}
                    variant="outlined"
                    fullWidth
                    placeholder="Digite a nova senha"
                    type={showPasswordConfirm ? "text" : "password"}
                    value={newPassword}
                    setValue={setNewPassword}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowPasswordConfirm}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          <PasswordIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </GridStyled>

                <GridStyled justifyContent="flex" container>
                  <RecoveryLabel>Confirme a Senha</RecoveryLabel>
                  <TextFieldErp
                    error={errors.confirmNewPassword ? true : false}
                    helperText={errors.confirmNewPassword || ""}
                    variant="outlined"
                    fullWidth
                    placeholder="Repita a nova senha"
                    type={showPassword ? "text" : "password"}
                    value={confirmNewPassword}
                    setValue={setConfirmNewPassword}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          <PasswordIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </GridStyled>

                <Margin top="8" />
                <PasswordRequirements password={newPassword} />
              </>
            ) : (
              <GridStyled justifyContent="space-between" container>
                <RecoveryLabel>E-mail</RecoveryLabel>
                <HeaderTextField
                  error={errors.email ? true : false}
                  helperText={errors.email || false}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  placeholder="nomeusuario@exemplo.com"
                  autoComplete="email"
                  autoFocus
                  value={email}
                  onChange={handleChangeEmail}
                />
              </GridStyled>
            )}

            <Margin top="16" />
            <Grid
              container
              spacing={3}
              rowSpacing={10}
              columnSpacing={{ xs: 1, sm: 3, md: 3 }}
            >
              <Grid item xs={6}>
                <RecoveryCancelButton
                  type="submit"
                  full
                  variant="contained"
                  color="#364461"
                  label="Cancelar"
                  onClick={() => push("/login", { email, logout: true })}
                >
                  Cancelar
                </RecoveryCancelButton>
              </Grid>
              <Grid item xs={6}>
                <RecoverySendButton
                  type="submit"
                  full
                  variant="contained"
                  color="primary"
                  label={sendCodeVerif ? "Salvar" : "Enviar"}
                  onClick={() => {
                    if (sendCodeVerif) {
                      handleChangePasswordRecover();
                      push("/login", { logout: true });
                    } else {
                      handleSendCodeVerif();
                    }
                  }}
                >
                  {sendCodeVerif ? "Salvar" : "Enviar"}
                </RecoverySendButton>
              </Grid>
            </Grid>
          </div>
        </GridStyled>
      </FormRecoveryPassword>
    </LoginLayout>
  );
}
