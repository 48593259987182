import { Switch } from "react-router-dom";

import MiddlewareRoute from "../Middleware";
import { AppStore } from "../../pages/appStore/AppStore";

export default function AppStoreModule() {
  return (
    <Switch>
      <MiddlewareRoute
        path="/apps-store"
        sidebar
        component={AppStore}
        noPaddingSidebar
      />
    </Switch>
  );
}
