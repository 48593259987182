import React, { createContext, useState } from "react";

const CustumerManagementContext = createContext();

const CustumerManagementContextProvider = ({ children }) => {
  //Clientes
  const [customers, setCustomers] = useState([]);
  const [customersBpo, setCustomersBpo] = useState([]);

  //Dados do Cliente
  const [customerId, setCustomerId] = useState(null);
  const [documentCustomer, setDocumentCustomer] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [tradingName, setTradingName] = useState("");
  const [taxRegime, setTaxRegime] = useState(null);
  const [urlAccess, setUrlAccess] = useState("");
  const [mainEntity, setMainEntity] = useState(null);
  const [logoUrl, setLogoUrl] = useState([]);
  const [auxTaxRegimeId, setAuxTaxRegimeId] = useState(0);
  const [entityEmail, setEntityEmail] = useState("");
  const [entityPhone, setEntityPhone] = useState("");
  const [entityIm, setEntityIm] = useState("");
  const [entityIe, setEntityIe] = useState("");
  const [entityCcm, setEntityCcm] = useState("");
  const [responsibleCpf, setResponsibleCpf] = useState("");

  //Endereços
  const [addressesCostumer, setAddressesCostumer] = useState([]);
  const [addressModalOpen, setAddressModalOpen] = useState(false);
  const [addressModalIndex, setAddressModalIndex] = useState(-1);

  //Usuários
  const [usersCostumer, setUsersCostumer] = useState([]);
  const [userModalOpen, setUserModalOpen] = useState(false);
  const [userModalIndex, setUserModalIndex] = useState(-1);
  const [saveUser, setSaveUser] = useState(false);
  const [userFullName, setUserFullName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userCellphone, setUserCellphone] = useState("");

  //Tabela Pesonalizada
  const [customTableModalOpen, setCustomTableModalOpen] = useState(false);
  const [checkColumns, setCheckColumns] = useState(
    JSON.parse(localStorage.getItem("checkColumns") || "{}").checkColumns
  );
  const [orderColumns, setOrderColumns] = useState(
    JSON.parse(localStorage.getItem("orderColumns") || '{"orderColumns":[]}')
      .orderColumns
  );
  const [copyOrderColumns, setCopyOrderColumns] = useState([]);
  const [copyCheckColumns, setCopyCheckColumns] = useState({});

  //Config Acesso
  const [configAccessModalOpen, setConfigAccessModalOpen] = useState(false);
  const [configAccessModalIndex, setConfigAccessModalIndex] = useState(-1);

  //Usuários Qyon ERP
  const [tableUsersErp, setTableUsersErp] = useState([]);

  const [expanded, setExpanded] = useState({ general: true });
  const [listTaxRegime, setListTaxRegime] = useState([]);

  const [listMainEntity, setListMainEntity] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingInputs, setLoadingInputs] = useState({});
  const [errors, setErrors] = useState({});

  //Controle Tabs/Páginas
  const [showTabs, setShowTabs] = useState();

  return (
    <CustumerManagementContext.Provider
      value={{
        //Clientes
        customers,
        setCustomers,
        customersBpo,
        setCustomersBpo,

        //Dados do Cliente
        customerId,
        setCustomerId,
        documentCustomer,
        setDocumentCustomer,
        companyName,
        setCompanyName,
        tradingName,
        setTradingName,
        taxRegime,
        setTaxRegime,
        urlAccess,
        setUrlAccess,
        mainEntity,
        setMainEntity,
        logoUrl,
        setLogoUrl,
        auxTaxRegimeId,
        setAuxTaxRegimeId,
        entityEmail,
        setEntityEmail,
        entityPhone,
        setEntityPhone,
        entityIm,
        setEntityIm,
        entityIe,
        setEntityIe,
        entityCcm,
        setEntityCcm,
        responsibleCpf,
        setResponsibleCpf,

        //Endereços
        addressesCostumer,
        setAddressesCostumer,
        addressModalOpen,
        setAddressModalOpen,
        addressModalIndex,
        setAddressModalIndex,

        //Usuários
        usersCostumer,
        setUsersCostumer,
        userModalOpen,
        setUserModalOpen,
        userModalIndex,
        setUserModalIndex,
        saveUser,
        setSaveUser,
        userFullName,
        setUserFullName,
        userEmail,
        setUserEmail,
        userCellphone,
        setUserCellphone,

        //Tabela Pesonalizada
        customTableModalOpen,
        setCustomTableModalOpen,
        checkColumns,
        setCheckColumns,
        orderColumns,
        setOrderColumns,
        copyOrderColumns,
        setCopyOrderColumns,
        copyCheckColumns,
        setCopyCheckColumns,

        //Config Acesso
        configAccessModalOpen,
        setConfigAccessModalOpen,
        configAccessModalIndex,
        setConfigAccessModalIndex,

        //Usuários Qyon ERP
        tableUsersErp,
        setTableUsersErp,

        listTaxRegime,
        setListTaxRegime,
        listMainEntity,
        setListMainEntity,
        loading,
        setLoading,
        loadingInputs,
        setLoadingInputs,
        errors,
        setErrors,
        expanded,
        setExpanded,

        //Controle Tabs/Páginas
        showTabs,
        setShowTabs,
      }}
    >
      {children}
    </CustumerManagementContext.Provider>
  );
};

export { CustumerManagementContextProvider, CustumerManagementContext };
