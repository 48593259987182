import { search } from "./BaseRepositoryComponents";

const newPath = `${process.env.REACT_APP_NEW_PAINEL_API_URL}`;

export async function getTemplateFiles(document, typeNotification) {
  const url = `${newPath}/v1/email-template/get-template-files/${document}?type_notification=${typeNotification}`;

  return search({
    path: url,
    method: "GET",
  });
}

export async function sendTemplateFiles(body) {
  const url = `${newPath}/v1/email-template/send-template-files`;

  return search({
    path: url,
    method: "POST",
    myBody: body,
  });
}

export async function sendPreviewEmail(email, document, type, template_id) {
  const url = `${newPath}/v1/email-template/send-preview-email/${email}/${document}/${type}/${template_id}`;

  return search({
    path: url,
    method: "GET",
  });
}

// FIXME not used
// export async function changeAdditionalMessage(body) {
//   const url = `${newPath}/v1/email-template/change-additional-message`;

//   return search({
//     path: url,
//     method: "POST",
//     myBody: body,
//   });
// }

export async function deleteTemplate(template_id, document, type) {
  const url = `${newPath}/v1/email-template/delete-template/${template_id}/${document}/${type}`;

  return search({
    path: url,
    method: "DELETE",
  });
}

export async function getTemplateImage(document, type, template_id) {
  const url = `${newPath}/v1/email-template/get-template-image/${document}/${type}/${template_id}`;

  return search({
    path: url,
    method: "GET",
  });
}

export async function getTemplateJson(document, type, template_id) {
  const url = `${newPath}/v1/email-template/get-template-json/${document}/${type}/${template_id}`;

  return search({
    path: url,
    method: "GET",
  });
}

export async function getEmailPlaceholdersByTypeAndSystem(
  systemPlaceholder,
  typeNotification
) {
  return search({
    path: `${newPath}/v1/email-template/placeholders?system_placeholder=${systemPlaceholder}&type_notification=${typeNotification}`,
    method: "GET",
  });
}
