import { search } from "./BaseRepositoryComponents";

const newPath = `${process.env.REACT_APP_NEW_PAINEL_API_URL}`;

export async function feedUserPortalAuth(body) {
  const url = `${newPath}/v1/feed/feed_auth`;

  return search({
    path: url,
    method: "POST",
    myBody: body,
  });
}
