import {
  Chip,
  CircularProgress,
  DialogActions,
  DialogContent,
  Grid,
  Modal,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import {
  ButtonSave,
  CloseIconStyle,
  DialogHeader,
  DialogStyle,
} from "../../../components/modal/Modal";
import { OutsideText, SearchFilter } from "../styles/GuideReport";
import { notify } from "../../../utils/notify";
import { validEmail } from "../../../utils/validations";
import { v4 as uuidv4 } from "uuid";
import { Close as CloseIcon } from "@material-ui/icons";
import {
  CommDocsGrid,
  DoccCom,
} from "../../../components/modal/styles/ModalPublicationChannelFrame";
import { DocumentTypes } from "../utils/DocumentTypes";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import { sendEmailDocumentById } from "../../../repositories/GuideReportList";
import { isInteger } from "formik";

const ModalSendDocs = ({ open, handleClose, documentId }) => {
  const [isLoadingModalSendDocs, setIsLoadingModalSendDocs] = useState(false);
  const [commType, setCommType] = useState(DocumentTypes.email);

  const [email, setEmail] = useState("");
  const [emailList, setEmailList] = useState([]);

  const [whatsappNumber, setWhatsappNumber] = useState("");
  const [whatsappList, setWhatsappList] = useState([]);

  const handleKeyDownEmail = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      const formatedEmail = email?.replace(";", "");
      if (validEmail(formatedEmail.trim())) {
        const newMultipleEmailList = [...emailList];
        newMultipleEmailList.push({
          id: uuidv4(),
          document: formatedEmail.trim(),
        });
        setEmailList(newMultipleEmailList);
        setEmail("");
      } else {
        notify("Email inválido, tente novamente.", true, "warning");
      }
    }
  };

  const handleKeyDownWhatsapp = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      const whatsappNumberFormated = whatsappNumber?.replace(/[-()/ ]/g, "");

      if (
        whatsappNumberFormated?.length === 11 &&
        isInteger(whatsappNumberFormated)
      ) {
        const whatsappListCopy = [...whatsappList];
        whatsappListCopy.push({
          id: uuidv4(),
          document: whatsappNumberFormated,
        });
        setWhatsappList(whatsappListCopy);
        setWhatsappNumber("");
      } else {
        notify("Número inválido, tente novamente.", true, "warning");
      }
    }
  };

  const handleSaveEmail = async () => {
    setIsLoadingModalSendDocs(true);

    const responseSendDocs = await sendEmailDocumentById(documentId, emailList);

    if (responseSendDocs?.success) {
      notify("Documento enviado com sucesso!", true, "success");
      handleClose();
    } else {
      notify("Ocorreu um erro ao enviar o documento!", true, "warning");
    }

    setIsLoadingModalSendDocs(false);
  };

  const handleDeleteEmail = (id) => {
    const multipleEmailsListCopy = [...emailList];

    const updatedEmailsList = multipleEmailsListCopy.filter(
      (email) => email.id !== id
    );
    setEmailList(updatedEmailsList);
  };

  const handleDeleteWhatsapp = (id) => {
    const whatsappListCopy = [...whatsappList];

    const updatedWhatsappList = whatsappListCopy.filter(
      (number) => number.id !== id
    );
    setWhatsappList(updatedWhatsappList);
  };

  const DocumentChips = ({ objects, handleDelete }) => {
    return objects.map((item) => (
      <Chip
        key={item.id}
        label={item.document}
        onDelete={() => handleDelete(item.id)}
        style={{
          marginRight: "8px",
          background: "#EEEEEE",
          color: "#63708A",
          paddingRight: "3px",
          marginBottom: "8px",
          marginTop: "10px",
        }}
        deleteIcon={
          <CloseIcon
            style={{ height: "15px", width: "15px", color: "#63708A" }}
          />
        }
      />
    ));
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        setEmailList([]);
        setWhatsappList([]);
        handleClose();
      }}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <DialogStyle>
        {isLoadingModalSendDocs ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "30px",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <>
            <DialogHeader>
              <div className="row">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "baseline",
                  }}
                >
                  Encaminhar Documentos por {commType}
                </div>

                <CloseIconStyle
                  onClick={() => {
                    handleClose();
                  }}
                />
              </div>
            </DialogHeader>
            <DialogContent>
              <Grid container item xs={12}>
                <CommDocsGrid
                  item
                  container
                  justifyContent="space-between"
                  xs={12}
                >
                  <DoccCom
                    onClick={() => setCommType(DocumentTypes.email)}
                    active={commType === DocumentTypes.email}
                  >
                    <MailOutlineIcon
                      style={{ height: "24px", width: "24px" }}
                    />
                    Email
                  </DoccCom>

                  <DoccCom
                    onClick={() => setCommType(DocumentTypes.whatsapp)}
                    active={commType === DocumentTypes.whatsapp}
                    disabled={true}
                  >
                    <WhatsAppIcon />
                    Whatsapp
                  </DoccCom>
                </CommDocsGrid>
              </Grid>

              {commType === DocumentTypes.email ? (
                <SearchFilter item container style={{ marginBottom: "8px" }}>
                  <OutsideText item>Email</OutsideText>

                  <TextField
                    onKeyDown={handleKeyDownEmail}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    minRows={1}
                    size="small"
                    variant="outlined"
                    placeholder="ex: email1@hotmail.com"
                    fullWidth
                    InputProps={{
                      style: { display: "block", flexDirection: "row" },
                      startAdornment: (
                        <DocumentChips
                          objects={emailList}
                          handleDelete={handleDeleteEmail}
                        />
                      ),
                    }}
                  />
                  <Typography variant="subtitle2" color="textSecondary">
                    * Aperte a tecla "Enter" para inserir os Emails
                  </Typography>
                </SearchFilter>
              ) : (
                commType === DocumentTypes.whatsapp && (
                  <SearchFilter item container style={{ marginBottom: "8px" }}>
                    <OutsideText item>Whatsapp</OutsideText>

                    <TextField
                      onKeyDown={handleKeyDownWhatsapp}
                      value={whatsappNumber}
                      onChange={(e) => setWhatsappNumber(e.target.value)}
                      minRows={1}
                      size="small"
                      variant="outlined"
                      placeholder="ex: 19999769131"
                      fullWidth
                      InputProps={{
                        style: { display: "block", flexDirection: "row" },
                        startAdornment: (
                          <DocumentChips
                            objects={whatsappList}
                            handleDelete={handleDeleteWhatsapp}
                          />
                        ),
                      }}
                    />
                    <Typography variant="subtitle2" color="textSecondary">
                      * Aperte a tecla "Enter" para inserir os Números
                    </Typography>
                  </SearchFilter>
                )
              )}

              <DialogActions>
                <ButtonSave
                  disabled={
                    (commType === "Email" && emailList?.length === 0) ||
                    (commType === "Whatsapp" && whatsappList?.length === 0)
                  }
                  onClick={async () => {
                    commType === "Email" && (await handleSaveEmail());
                  }}
                >
                  Enviar
                </ButtonSave>
              </DialogActions>
            </DialogContent>
          </>
        )}
      </DialogStyle>
    </Modal>
  );
};

export default ModalSendDocs;
