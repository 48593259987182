import { search } from "../BaseRepositoryComponents";

const baseUrl = process.env.REACT_APP_NEW_PAINEL_API_URL;

export function deleteEntityUser(entityId, userId) {
  return search({
    path: `${baseUrl}/v1/entity_user/entity/${entityId}/user/${userId}`,
    method: "DELETE",
  });
}

export async function createEntityUser(entityId, body) {
  return search({
    path: `${baseUrl}/v1/entity_user/entity/${entityId}/create_user`,
    method: "POST",
    myBody: body,
  });
}

export async function updateEntityUser(entityId, body) {
  return search({
    path: `${baseUrl}/v1/entity_user/entity/${entityId}/create_user`,
    method: "PUT",
    myBody: body,
  });
}
