import { Grid, TextField } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { CustumerManagementContext } from "../contexts/CustomerManagementContext";
import ReactInputMask from "react-input-mask";
import { GlobalContext } from "../../../contexts/GlobalContext";

export const DialogAddUserUserInfo = ({
  user,
  emailError,
  setEmailError,
  fullNameError,
  setFullNameError,
  location,
  mainUser,
}) => {
  const [userFullNameInternal, setUserFullNameInternal] = useState("");
  const [userEmailInternal, setUserEmailInternal] = useState("");
  const [userCellphoneInternal, setUserCellphoneInternal] = useState("");

  const { setUserFullName, setUserEmail, setUserCellphone } = useContext(
    CustumerManagementContext
  );
  const { userLogged } = useContext(GlobalContext);

  useEffect(() => {
    setUserFullNameInternal(user?.first_name?.concat(" " + user?.last_name));
    setUserEmailInternal(user?.email);
    setUserCellphoneInternal(user?.cellphone);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      setUserFullName(userFullNameInternal);
      setUserEmail(userEmailInternal);
      setUserCellphone(userCellphoneInternal?.replace(/[-()/]/g, ""));
    }, 300);

    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userFullNameInternal, userEmailInternal, userCellphoneInternal]);

  return (
    <>
      <Grid item xs={5}>
        <div>Nome do Usuário*</div>
        <TextField
          variant="outlined"
          size="small"
          fullWidth
          onFocus={() => {
            setFullNameError(false);
          }}
          disabled={user?.id}
          error={fullNameError}
          placeholder="Nome do usuário"
          value={userFullNameInternal}
          onChange={(e) => {
            setUserFullNameInternal(e.target.value);
          }}
        />
      </Grid>

      <Grid item xs={5}>
        <div>Email*</div>
        <TextField
          variant="outlined"
          size="small"
          onFocus={() => {
            setEmailError(false);
          }}
          disabled={user?.id}
          fullWidth
          error={emailError}
          placeholder="acesso@email.com"
          value={userEmailInternal}
          onChange={(e) => {
            setUserEmailInternal(e.target.value.toLowerCase());
          }}
        />
      </Grid>

      <Grid item xs={2}>
        <div>Celular</div>
        <ReactInputMask
          mask="+99 (99)99999-9999"
          value={userCellphoneInternal}
          onChange={(e) => setUserCellphoneInternal(e.target.value)}
          disabled={
            location?.pathname !== "/customermanagement/edit" &&
            user?.is_master &&
            user?.id !== userLogged?.userId &&
            mainUser
          }
        >
          {(inputProps) => (
            <TextField
              {...inputProps}
              variant="outlined"
              size="small"
              fullWidth
              placeholder="+55 (99)99999-9999"
            />
          )}
        </ReactInputMask>
      </Grid>
    </>
  );
};
